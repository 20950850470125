import { ActionIcon, Badge, Button, Center, Group, Input, Stack, Text, TextInput, useMantineColorScheme } from '@mantine/core';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconTag as Tag, IconTags as Tags, IconTrash as Trash } from '@tabler/icons-react';
import { TagItem } from 'src/stores/skills';
import { none, State, useHookstate } from '@hookstate/core';
import CollapsibleCard from './collapsible-card';
import AppContext from 'src/services/app-context';
import classes from 'src/pages/index.module.css';

const TagsCard: FC<{
  cardKey: string;
  mode: 'view' | 'edit';
  state: State<TagItem[]>;
}> = ({ cardKey, mode, state }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <CollapsibleCard
      title={
        <Group justify='flex-start'>
          <Text fw={500}>{t('Tags')}</Text>
          {mode === 'edit' &&
            <ActionIcon variant='subtle' color="gray" onClick={() => { scopedState[scopedState.length].set({ name: '', value: '' } as TagItem); setIsDirty(true); }}>
              <IconPlus />
            </ActionIcon>
          }
        </Group>
      }
      cardKey={cardKey}
      icon={<Tags />}
      collapseInfoRender={
        <Badge variant='light' color={colorScheme === 'dark' ? 'dark' : 'gray'}>{scopedState?.value.length} {t("tags")}</Badge>
      }>
      <>
        {mode === 'edit' &&
          <form>
            <Stack gap="xs" justify="flex-start">
              <Input.Wrapper description={<Text size='xs'>{t('To add new tags click on the Add tag button.')}</Text>}>
                {scopedState?.map((item, index) => (
                  <Group key={index} mt="xs">
                    <TextInput
                      required
                      placeholder={t('Name') as string}
                      style={{ flex: 1 }}
                      value={item.value.name}
                      onChange={(event) => { item.name.set(event.currentTarget.value); setIsDirty(true); }}
                    />
                    <TextInput
                      placeholder={t('Value') as string}
                      style={{ flex: 1 }}
                      value={item.value.value}
                      onChange={(event) => { item.merge(i => ({ value: event.currentTarget.value })); setIsDirty(true); }}
                    />
                    <ActionIcon
                      color="red"
                      variant='subtle'
                      onClick={() => { item.set(none); setIsDirty(true); }}>
                      <Trash />
                    </ActionIcon>
                  </Group>
                ))}
              </Input.Wrapper>
            </Stack>
          </form>
        }
        {mode === 'view' &&
          <>
            {scopedState?.value && scopedState?.value?.length > 0 &&
              <Group gap="xs" align="center">
                {scopedState.value.map((t, index) => (
                  <Badge size="xs" radius="sm" p="xs" key={`tags-${index}`} className={classes.tag}>{t.name}: {t.value}</Badge>
                ))}
              </Group>
            }
            {(scopedState?.value?.length === 0) &&
              <Center>
                <Text c="dimmed" size="sm">{t('No tags')}</Text>
              </Center>
            }
          </>
        }
      </>
    </CollapsibleCard>
  );
};

export default TagsCard;

import { SkillContentStep, SkillContentStepType, SkillTypeEnum } from "src/stores/skills";
import StepSayNode from "./step-say";
import StepAskNode from "./step-ask";
import StepCallApiNode from "./step-callapi";
import StepExternalSkillNode from "./step-externalskill";
import StepAssignNode from "./step-assign";
import StepLlmCallNode from "./step-llmcall";
import StepEventNode from "./step-event";
import StepGotoNode from "./step-goto";
import StepOptionsEdge from "./step-options-edge";
import StepStartNode from "./step-start";
import StepEndNode from "./step-end";

export interface StepNodeProps {
    id: string;
    data: StepDataProps
}

export interface StepDataProps {
    step: SkillContentStep;
    edit?: boolean;
    index: number;
    leftToolbar?: React.ReactNode;
    rightToolbar?: React.ReactNode;
    steps?: string[];
    updateNode: (id: string, data: StepDataProps) => void;
}

export const stepTypes = {
    Say: StepSayNode,
    Ask: StepAskNode,
    CallApi: StepCallApiNode,
    CallSkill: StepExternalSkillNode,
    Assign: StepAssignNode,
    CallLlm: StepLlmCallNode,
    Event: StepEventNode,
    GoTo: StepGotoNode,
    Start: StepStartNode,
    End: StepEndNode
};

export interface StepOptionsEdgeDataProps {
    edit?: boolean;
    index: number;
    skillType: SkillTypeEnum;
    onAddStep?: (type: SkillContentStepType, index?: number) => void;
}

export const edgeTypes = {
    'stepOptionsEdge': StepOptionsEdge
}

export type StepsRef = {
    getSteps: () => SkillContentStep[],
  }
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { IconDotsVertical as DotsVertical, IconEdit as Edit, IconTrash as Trash, IconAlertCircle as AlertCircle, IconInfoSquare as InfoSquare, IconHistory as History, IconFileInfo as FileInfo, IconClock as Clock, IconUserCircle as UserCircle, IconCloudUp as CloudUpload, IconNotes as Notes, IconMessageChatbot as MessageChatbot, IconHome2 as Home2, IconWand as Wand, IconBinaryTree2 as BinaryTree2, IconMessage2 as Message2, IconMessages as Messages, IconPlugConnected as PlugConnected, IconFileCode as FileCode, IconPrompt as Prompt, IconCloudOff as CloudOff, IconBrain, IconUserCog } from '@tabler/icons-react';
import { Stack, Tabs, Grid, Card, Group, Menu, ActionIcon, Text, Badge, Alert, SimpleGrid, Button, Title, Box, Tooltip, useMantineColorScheme, Loader } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import CollapsibleCard from 'src/components/collapsible-card';
import TagsCard from 'src/components/tags-card';
import { formatMessage, truncateText } from 'src/core/utils/object';
import { tagsToArray } from 'src/utils/tags-utils';
import { State, useHookstate } from '@hookstate/core';
import moment from 'moment';
import ListItem from 'src/components/listitem-component';
import StatusBadge from 'src/components/status-badge';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import useBus, { dispatch } from 'use-bus';
import VariablesCard from 'src/components/variables-card';
import MDEditor from '@uiw/react-md-editor';
import { UserCanContribute } from 'src/utils/permissions';
import { MemberEditorState, MemberItem } from 'src/stores/identities';
import { BotContent, BotContentLlm, BotContentSkill, BotContentUserPreferences, BotItem, BotItemStore, BotSnapshotItem, BotSnapshotSelectStore, GetBotDefaultContent } from 'src/stores/bots';
import { TagItem, ChatBotStore } from 'src/stores/skills';
import BotHistory from './bot-history';
import BotSkillAssignedList from './bot-skill-assigned-list';
import BotSkillAssignedDetail from './bot-skill-assigned-detail';
import { useViewportSize } from '@mantine/hooks';
import BotContentOptionsCard from './bot-content-options';
import BotApiDetails from './bot-api-details';
import jsyaml from "js-yaml";
import TrainingStatusBadge from 'src/components/training-status-badge';
import { LanguageSegmentedControl } from 'src/components/language-segmented-control';
import TrainButton from 'src/components/train-button';
import BotContentMrcCard from './bot-content-mrc';
import BotContentSearchCard from './bot-content-search';
import BotContentLlmCard from './bot-content-llm';
import ContentWrapper from 'src/components/content-wrapper';
import BotContentDisambiguationCard from './bot-content-disambiguation';
import BotContentUserPreferencesCard from './bot-content-user-preferences';
import { AppConfiguration, authStatus } from 'src/core/services/authentication-service';
import classes from 'src/pages/index.module.css';
import ChatComponent from 'src/components/chat';
import MembersCard from 'src/components/members-card';
import { Editor } from '@monaco-editor/react';
import { monacoOptions } from 'src/configurations/editor-config-jinja';
import { NotFoundInfo } from 'src/core/ui/not-found-component';

const BotDescriptionCard: FC<{
  state: State<BotItem>;
}> = ({ state }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState.description?.value ? Object.keys(scopedState.description?.value)[0] : defaultAllowedLanguages[0]);

  return (
    <CollapsibleCard
      title={<Text fw={500}>{t('Description')}</Text>}
      cardKey='bot-description-card'
      icon={<Notes />}
      rightToolbar={
        <LanguageSegmentedControl
          data={scopedState.description?.value ? Object.keys(scopedState.description?.value) : undefined}
          language={selectedLanguage}
          onChange={setSelectedLanguage}
        />
      }>
      <div data-color-mode={colorScheme}>
        <MDEditor.Markdown source={scopedState.description?.[selectedLanguage]?.value ?? t('(No description)')} />
      </div>
    </CollapsibleCard>
  );
}

const BotDetail: React.FC = () => {
  let navigate = useNavigate();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const params = useParams();
  const [botId, setBotId] = useState<string>();
  const [botSnapshotId, setBotSnapshotId] = useState<string>();
  const modals = useModals();
  const [activeTab, setActiveTab] = useState("home");
  const store = useMemo(() => container.get(BotItemStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const tagsScopedState = useHookstate([] as TagItem[]);
  const variablesScopedState = useHookstate([] as TagItem[]);
  const contentScopedState = useHookstate({} as BotContent);
  const { height } = useViewportSize();
  const [selectedSkill, setSelectedSkill] = useState<string | undefined>(undefined);
  const [yamlContent, setYamlContent] = useState<string>('');
  const isOwner = authStatus?.user.value?.isOwner;
  const canContribute = isOwner || UserCanContribute(state?.item?.members?.value as MemberItem[]);
  const testBotStore = useMemo(() => container.get(ChatBotStore), []);
  const snapShotSelectStore = useMemo(() => container.get(BotSnapshotSelectStore), []);

  let customMonacoOptions = { ...monacoOptions };
  customMonacoOptions.readOnly = true;

  useEffect(() => {
    setBotSnapshotId(undefined);
    setBotId(params.botId);
  }, [params.botId])

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(`${t("Bot detail")}`, "Bots", <MessageChatbot size={34} />, `${t("Bot detail")} | ${t("Bots")}`, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/bots`, breadcrumbName: t('Bots') },
    ]);
    load(botId as string);
    snapShotSelectStore.setBotId(botId as string);
  }, [botId]); // eslint-disable-line react-hooks/exhaustive-deps

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);

  const load = async (botId: string) => {
    if (botId) {
      await store.load(botId);
      setBotSnapshotId(state.item.currentSnapshot?.id?.value);
    }
  };

  useBus(
    '@@ui/BOT_DETAIL_REFRESH',
    () => load(botId as string),
    [botId],
  );

  useEffect(() => {
    if (state.item.value) {
      setHeader(`${state.item.title.value}`, t("Bots"), <MessageChatbot size={34} />, `${state.item.title.value} | ${t("Bots")}`, true, true);
      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      const variables = tagsToArray(state.item.currentSnapshot?.variables?.value ?? []);
      variablesScopedState.set(variables);

      const copy = JSON.parse(JSON.stringify(state.item.value)) as MemberEditorState;
      membersState.set({
        accessMode: copy.accessMode,
        members: copy.members
      });

      if (!state.item.currentSnapshot?.value) {
        state.item.currentSnapshot.set({
          content: '', // template
          botId: botId,
          variables: {}
        } as BotSnapshotItem);
      }
      if (state.item.currentSnapshot?.content?.value) {
        contentScopedState.set(JSON.parse(state.item.currentSnapshot.content.value));
        if (!contentScopedState.userPreferences || !contentScopedState.userPreferences.value) {
          contentScopedState.userPreferences.set({
            temperature: 0.5,
            allowUserChangeTemperature: false,
            searchSkills: [],
            allowUserChangeSearchSkills: false,
            searchLanguages: [],
            allowUserChangeSearchLanguages: false,
            allowInternetAccess: false,
            allowUserChangeInternetAccess: false,
            allowEnableOCRInDocuments: false,
            enableOCRInDocuments: false
          });
        }
        setYamlContent(jsyaml.dump(JSON.parse(state.item.currentSnapshot.content.value)));
      }
      else {
        const defaultContent = GetBotDefaultContent(state.item.strategy.value, t);
        contentScopedState.set(defaultContent);
        setYamlContent(jsyaml.dump(defaultContent));
      }
    }
  }, [state.item])

  const navigateToEdit = () => {
    navigate(`/admin/bots/${botId}/edit`);
  };

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const openTrainModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Train snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to train this item?')}
          <br></br>
          {t('The training process might take a while.')}
        </Text>
      ),
      labels: { confirm: t('Train snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmTrain(snapshotId),
    });

  const onConfirmTrain = async (snapshotId: string) => {
    await store.trainSnapshot(botId as string, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(botId as string);
      dispatch('@@ui/BOT_LIST_REFRESH');
    }
  };

  const openConfirmPublish = () => modals.openConfirmModal({
    title: t("Publish"),
    children: (
      <Text size="sm">
        {t('Are you sure you want to publish this bot?')}
      </Text>
    ),
    labels: { confirm: t('Confirm'), cancel: t('Cancel') },
    onConfirm: async () => {
      await store.publish(botId as string);
      load(botId as string);
      dispatch('@@ui/BOT_LIST_REFRESH');
      dispatch('@@ui/BOT_HISTORY_LIST_REFRESH');
      state.errorMessage.value ? showKoNotification() : showOkNotification();
    },
  });

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Delete bot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete bot'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(),
    });

  const onConfirmDelete = async () => {
    await store.delete(botId as string);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      navigate(`/admin/bots`);
      dispatch('@@ui/BOT_LIST_REFRESH');
    }
  };

  const openRetireModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Retire bot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to retire this bot?')}
          <br></br>
          {t('All published snapshots will also be retired')}
        </Text>
      ),
      labels: { confirm: t('Retire bot'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmRetire(),
    });

  const onConfirmRetire = async () => {
    await store.retire(botId as string);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(botId as string);
      dispatch('@@ui/BOT_LIST_REFRESH');
      dispatch('@@ui/BOT_HISTORY_LIST_REFRESH');
    }
  };

  const notFound = !isBusy && state.status?.value && state.status.value === 404;

  return (
    <ContentWrapper>
      <div style={{ position: 'relative' }} className={classes.fullHeightContainer}>
        {isBusy ?
          <Stack my="xl" justify='center' align='center'>
            <Loader size="xl" />
            <Text c="dimmed">{t("Please wait")}</Text>
          </Stack>
          :
          <>
            {notFound &&
              <NotFoundInfo
                title={t('No item found')}
                description={t('No item found or you do not have permissions') as string} />
            }
            {state?.item?.value && state?.item?.currentSnapshot?.value && !notFound &&
              contentScopedState?.value && contentScopedState.options?.value && contentScopedState.disambiguation?.value && (
                <>
                  {errorMessage && (
                    <Alert p="md" mb="xs" icon={<AlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
                      <Text>{formatMessage(errorMessage)}</Text>
                    </Alert>
                  )}
                  <Grid align="stretch">
                    <Grid.Col span="auto">
                      <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
                        <Tabs.List>
                          <Tabs.Tab value="home" leftSection={<Home2 size={20} />}>{t('Home')}</Tabs.Tab>
                          <Tabs.Tab value="skills" leftSection={<Wand size={20} />}>{t('Skills')}</Tabs.Tab>
                          {state.item.strategy.value === 'Llm' &&
                            <Tabs.Tab value="llm" leftSection={<IconBrain size={20} />}>{t('LLM')}</Tabs.Tab>
                          }
                          <Tabs.Tab value="userpreferences" leftSection={<IconUserCog size={20} />}>{t('User preferences')}</Tabs.Tab>
                          <Tabs.Tab value="api" leftSection={<PlugConnected size={20} />}>{t('API details')}</Tabs.Tab>
                          <Tabs.Tab value="source" leftSection={<FileCode size={20} />}>{t('Source code')}</Tabs.Tab>
                          <Tabs.Tab value="test" leftSection={<Prompt size={20} />}>{t('Test bot')}</Tabs.Tab>
                          <Tabs.Tab value="history" leftSection={<History size={20} />}>{t('History')}</Tabs.Tab>
                        </Tabs.List>
                      </Tabs>
                    </Grid.Col>
                    <Grid.Col span="content">
                      <Group justify='flex-end'>
                        <StatusBadge status={state.item.value.currentSnapshot?.status} />
                        <TrainingStatusBadge status={state.item.trainingStatus.value} jobReference={state.item.jobReference.value} />
                        {canContribute &&
                          <TrainButton
                            jobReference={state.item.jobReference.value}
                            status={state.item.trainingStatus.value}
                            onFinished={() => load(botId as string)}
                            onClick={() => openTrainModal(botSnapshotId as string)}
                          />
                        }
                        {state.item.value.currentSnapshot?.trainingStatus === 'Trained' && state.item.value.currentSnapshot?.status !== 'Published' && canContribute &&
                          <Button
                            loading={isBusy}
                            onClick={openConfirmPublish}
                            leftSection={<CloudUpload />}>
                            {t("Publish")}
                          </Button>
                        }
                        {canContribute &&
                          <Menu withinPortal withArrow width={250} shadow="md" position="bottom-end">
                            <Menu.Target>
                              <Tooltip label={t("More options")}>
                                <ActionIcon variant='subtle' color="gray">
                                  <DotsVertical />
                                </ActionIcon>
                              </Tooltip>
                            </Menu.Target>
                            <Menu.Dropdown>
                              <Menu.Label>{t("Bot options")}</Menu.Label>
                              <Menu.Item leftSection={<Edit size={20} />} onClick={navigateToEdit}>{t("Edit bot")}</Menu.Item>
                              <Menu.Divider />
                              <Menu.Label>{t("Danger zone")}</Menu.Label>
                              <Menu.Item disabled={state.item.currentSnapshot.status.value !== 'Published'} color="red" leftSection={<CloudOff size={20} />} onClick={openRetireModal}>{t('Retire bot')}</Menu.Item>
                              <Menu.Item color="red" leftSection={<Trash size={20} />} onClick={openDeleteModal}>{t('Delete bot')}</Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        }
                      </Group>
                    </Grid.Col>
                  </Grid>
                  <Box px={0} py={0} mt="xs" style={{ flexGrow: 1 }}>
                    {activeTab === "home" && (
                      <Grid gutter={'xs'}>
                        <Grid.Col span={{ base: 12, lg: 8 }}>
                          <Stack gap="xs">
                            <BotContentOptionsCard
                              cardkey='bot-content-options'
                              state={contentScopedState.options}
                              key={`bot-content-options-${botId}`}
                            />

                            <BotContentDisambiguationCard
                              cardkey='bot-content-disambiguation'
                              state={contentScopedState.disambiguation}
                              key={`bot-content-disambiguation-${botId}`}
                            />

                            <SimpleGrid
                              cols={{ base: 1, xl: 2 }}
                              spacing="sm">
                              {contentScopedState.search && contentScopedState.search.value && <BotContentSearchCard
                                cardkey='skill-content-steps'
                                key={`bot-content-search-${botId}`}
                                state={contentScopedState.search}
                              />}

                              {contentScopedState.mrc && contentScopedState.mrc.value && <BotContentMrcCard
                                cardkey='skill-content-steps'
                                key={`bot-content-mrc-${botId}`}
                                state={contentScopedState.mrc}
                              />}
                            </SimpleGrid>
                          </Stack>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, lg: 4 }}>
                          <Stack gap="xs">
                            <CollapsibleCard
                              title={<Text fw={500}>{t('Info')}</Text>}
                              cardKey='bot-info-card'
                              icon={<InfoSquare />}
                              collapseInfoRender={
                                <Badge variant='light' color="gray">{truncateText(state.item.title.value, 20)}</Badge>
                              }>
                              <Stack align='stretch'>
                                <SimpleGrid
                                  cols={{ base: 1, sm: 2 }}>
                                  <ListItem title={t("Title")} icon={<FileInfo size={20} />} description={state.item.title.value} />
                                  {state?.item?.currentSnapshot?.createdOn &&
                                    <>
                                      <ListItem title={t("Modified on")} icon={<Clock size={20} />} description={moment(state.item.currentSnapshot.createdOn.value).fromNow()} />
                                      <ListItem title={t("Modified by")} icon={<UserCircle size={20} />} description={state.item.currentSnapshot.createdBy.value} />
                                    </>
                                  }
                                  <ListItem title={t("Strategy")} icon={<BinaryTree2 size={20} />} description={t(state.item.strategy.value) as string} />
                                </SimpleGrid>
                              </Stack>
                            </CollapsibleCard>

                            <BotDescriptionCard state={state.item} />

                            <MembersCard
                              cardKey='bot-members-card'
                              mode="view"
                              state={membersState}
                              key={`bot-members-${botId}`}
                              isBot
                            />

                            <TagsCard
                              cardKey='bot-tags-card'
                              mode="view"
                              state={tagsScopedState}
                              key={`bot-tags-${botId}`}
                            />

                            <VariablesCard
                              cardKey='bot-variables-card'
                              mode="view"
                              state={variablesScopedState}
                              key={`bot-variables-${botId}`}
                            />
                          </Stack>
                        </Grid.Col>
                      </Grid>
                    )}
                    {activeTab === "skills" && (
                      <Grid gutter={'xs'}>
                        <Grid.Col span={{ base: 12, lg: 3 }}>
                          <Card withBorder>
                            <BotSkillAssignedList
                              state={contentScopedState}
                              scrollAreaHeight={height - 210}
                              onSelectedItem={setSelectedSkill}
                              key={`bot-skillassigned-list-${botId}`}
                            />
                          </Card>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, lg: 9 }}>
                          {selectedSkill &&
                            <BotSkillAssignedDetail
                              value={selectedSkill}
                              state={contentScopedState.skills[contentScopedState.skills.findIndex(x => x.skillId.value === selectedSkill)]}
                              key={`bot-skillassigned-detail-${botId}`}
                            />
                          }
                          {!selectedSkill &&
                            <Card withBorder>
                              <Stack p="md">
                                <Group>
                                  <Wand size={32} />
                                  <Title order={2}>{t('Associated skill details')}</Title>
                                </Group>
                                <Text size="lg" c="dimmed">
                                  {t('Select an associated skill or add a new one to see the detail.')}
                                </Text>
                              </Stack>
                            </Card>
                          }
                        </Grid.Col>
                      </Grid>
                    )}
                    {activeTab === "llm" && contentScopedState.llm?.value && (state.item.strategy.value === 'Llm' || (contentScopedState.mrc?.value && contentScopedState.mrc.algorithm.value === 'llm')) && (
                      <BotContentLlmCard
                        cardkey='bot-content-llm'
                        state={contentScopedState.llm as State<BotContentLlm>}
                        botStrategy={state.item.strategy.value}
                        key={`bot-content-llm-${botId}`}
                      />
                    )}

                    {activeTab === "userpreferences" && (
                      <BotContentUserPreferencesCard
                        cardkey='bot-userpreferences'
                        state={contentScopedState.userPreferences as State<BotContentUserPreferences>}
                        assignedSkills={contentScopedState.skills.value as BotContentSkill[]}
                        key={`bot-userpreferences-${botId}`}
                      />
                    )}

                    {activeTab === "api" && (
                      <BotApiDetails
                        cardkey='bot-api-details'
                        state={state.item}
                        contentState={contentScopedState}
                        key={`bot-api-details-${botId}`}
                      />
                    )}
                    {activeTab === "source" && (
                      <Card withBorder h='100%'>
                        {yamlContent &&
                          <Editor
                            width="100%"
                            height="100%"
                            options={customMonacoOptions}
                            language="yaml"
                            value={yamlContent}
                            theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                          />
                        }
                      </Card>
                    )}
                    {activeTab === "test" && botId && botSnapshotId && (
                      <ChatComponent
                        store={testBotStore}
                        id={botId}
                        snapshotId={botSnapshotId}
                        snapshotSelectStore={snapShotSelectStore}
                        key={`bot-testchat-${botId}-${botSnapshotId}`}
                        hideTokens
                      />
                    )}
                    {activeTab === "history" && (
                      <Stack gap='sm'>
                        <BotHistory botId={botId as string} canContribute={canContribute} key={`bot-history-${botId}`} />
                      </Stack>
                    )}
                  </Box>
                </>
              )}
          </>
        }
      </div>
    </ContentWrapper>
  );
};

export default BotDetail;

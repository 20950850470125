import { Group, Stack, TextInput, Select, PasswordInput, Table, ActionIcon, Input, ScrollArea, Text, Badge, Button, ComboboxItem } from '@mantine/core';
import React, { FC, useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { State, none, useHookstate } from '@hookstate/core';
import AppContext from 'src/services/app-context';
import { CrawlerRef } from './crawlers';
import { GetServiceNowCrawlerDefaultContent, KnowledgeBaseItem, ServiceNowCrawlerContent } from 'src/stores/crawlers';
import { IconBooks, IconTrash } from '@tabler/icons-react';
import { LanguageSeletor } from 'src/components/language-selector';
import { BasicMultiselect } from 'src/components/basic-multiselect';
import classes from 'src/pages/index.module.css';

const CrawlerKnowledgeBaseItem: FC<{
  state: State<KnowledgeBaseItem>;
  edit?: boolean;
}> = ({ state, edit }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const getAudiencesData = (kb: KnowledgeBaseItem) => {
    let data = [] as ComboboxItem[];
    if (kb.audiences) {
      data = kb.audiences.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  return (
    <Group mt="xs" align="flex-start">
      <TextInput
        required
        placeholder={t('KB id') as string}
        style={{ flex: 1 }}
        width="100%"
        value={scopedState.id.value}
        onChange={(event) => { scopedState.id.set(event.target.value); setIsDirty(true); }}
      />
      <TextInput
        required
        placeholder={t('KB name') as string}
        style={{ flex: 1 }}
        width="100%"
        value={scopedState.name.value}
        onChange={(event) => { scopedState.name.set(event.target.value); setIsDirty(true); }}
      />
      <Select
        allowDeselect={false}
        required
        placeholder={t("Split method") as string}
        defaultValue='none'
        data={[
          { value: 'Auto', label: t("Auto") as string },
          { value: 'None', label: t("None") as string },
          { value: 'Paragraph', label: t("Paragraph") as string },
          { value: 'Block', label: t("Block") as string }
        ]}
        value={scopedState.splitMethod.value}
        onChange={(value) => { scopedState.splitMethod.set(value as any); setIsDirty(true); }}
      />
      <BasicMultiselect
        data={getAudiencesData(scopedState.value as KnowledgeBaseItem)}
        value={scopedState.audiences.value as string[]}
        onChange={(value) => { scopedState.audiences.set(value); setIsDirty(true); }}
        placeholder={t("Select audiences") as string}
        creatable
      />
      <ActionIcon
        variant='subtle'
        color="red"
        onClick={() => { scopedState.set(none); setIsDirty(true); }}>
        <IconTrash />
      </ActionIcon>
    </Group>
  );
}


const CrawlerKnowledgeBases: FC<{
  state: State<KnowledgeBaseItem[]>;
  edit?: boolean;
}> = ({ state, edit }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const onAddKnowledgeBase = () => {
    if (scopedState?.value) {
      scopedState[scopedState.length].set({ id: '', name: '', splitMethod: 'None', audiences: [] } as KnowledgeBaseItem);
    }
    else {
      scopedState.merge([{ id: '', name: '', splitMethod: 'None', audiences: [] } as KnowledgeBaseItem]);
    }
    setIsDirty(true);
  }

  return (
    <>
      {edit ?
        <>
          <Input.Wrapper label={t("Knowledge bases to crawl")} description={<Text size='xs'>{t('To add new item click on the Add item button')}</Text>}>
            <div style={{ position: 'relative' }}>
              <ScrollArea style={{ minHeight: 150, height: '25svh' }} offsetScrollbars>
                {scopedState?.value && scopedState.map((kb, index) => (
                  <CrawlerKnowledgeBaseItem
                    key={index}
                    state={kb}
                    edit={edit}
                  />
                ))}
              </ScrollArea>
            </div>
          </Input.Wrapper>
          <Group justify="center">
            <Button
              variant='default'
              size='sm'
              onClick={onAddKnowledgeBase}
              leftSection={<IconBooks />}>
              {t("Add item")}
            </Button>
          </Group>
        </>
        :
        <Input.Wrapper label={t("Knowledge bases to crawl")}>
          <Table striped highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: '30%' }}>{t('KB id')}</Table.Th>
                <Table.Th style={{ width: '30%' }}>{t('KB name')}</Table.Th>
                <Table.Th style={{ width: '40%' }}>{t('Audiences')}</Table.Th>
              </Table.Tr>
            </Table.Thead>
          </Table>
          <div style={{ position: 'relative' }}>
            <ScrollArea style={{ minHeight: 150, height: '25svh' }} offsetScrollbars>
              <Table striped highlightOnHover>
                <Table.Tbody>
                  {scopedState?.value && scopedState.map((kb, index) =>
                    <Table.Tr key={kb.id.value}>
                      <Table.Td style={{ width: '30%' }}>{kb.id.value}</Table.Td>
                      <Table.Td style={{ width: '30%' }}>{kb.name.value}</Table.Td>
                      <Table.Td style={{ width: '40%' }}>
                        <Group gap="xs" align="center">
                          {kb.audiences.value.map((a, index) => (
                            <Badge size="xs" radius="sm" p="xs" key={index} className={classes.tag}>{a}</Badge>
                          ))}
                        </Group>
                      </Table.Td>
                    </Table.Tr>
                  )}
                </Table.Tbody>
              </Table>
            </ScrollArea>
          </div>
        </Input.Wrapper>
      }
    </>
  );
}

const CrawlerServiceNowEditInput: FC<{
  state: State<string>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <TextInput
      required
      readOnly={!edit}
      variant={edit ? 'default' : 'unstyled'}
      label={label}
      value={scopedState.value}
      onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
    />
  );
}

const CrawlerServiceNowEditPass: FC<{
  state: State<string>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <PasswordInput
      style={{ flex: 1 }}
      required
      readOnly={!edit}
      variant={edit ? 'default' : 'unstyled'}
      label={label}
      value={scopedState.value}
      onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
    />
  );
}

const CrawlerServiceNowEditLanguages: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <LanguageSeletor
      required
      readOnly={!edit}
      variant={edit ? undefined : 'unstyled'}
      multiselect
      label={label}
      value={scopedState.value as string[]}
      onChange={(value) => { scopedState.set(value as string[]); setIsDirty(true); }}
    />
  );
}

export const ServiceNowCrawler = React.forwardRef<CrawlerRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as ServiceNowCrawlerContent);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set(GetServiceNowCrawlerDefaultContent());
    }
  }, [content])


  const isValid = () => {
    return scopedState.serviceUri?.value.length > 0 && scopedState.username?.value.length > 0 &&
      scopedState.password?.value.length > 0 && scopedState.languages?.value?.length > 0;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Service uri")}</Table.Td>
          <Table.Td>{scopedState.serviceUri.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Languages")}</Table.Td>
          <Table.Td>{scopedState.languages.value.toString()}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Knowledge bases to crawl")}</Table.Td>
          <Table.Td>{scopedState.knowledgeBases.length}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Stack>
      <CrawlerServiceNowEditInput
        label={t('Service uri')}
        state={scopedState.serviceUri}
        edit={canContribute}
        key={'servicenow-serviceUri'}
      />

      <Group gap="md" justify="center">
        <CrawlerServiceNowEditInput
          label={t('Username')}
          state={scopedState.username}
          edit={canContribute}
          key={'servicenow-username'}
        />
        <CrawlerServiceNowEditPass
          label={t('Password')}
          state={scopedState.password}
          edit={canContribute}
          key={'servicenow-password'}
        />
      </Group>

      <CrawlerServiceNowEditLanguages
        label={t('Languages')}
        state={scopedState.languages}
        edit={canContribute}
        key={'servicenow-languages'}
      />

      <CrawlerKnowledgeBases
        state={scopedState.knowledgeBases}
        edit={canContribute}
        key={'servicenow-knowledgeBases'}
      />
    </Stack>
  );
});

export default ServiceNowCrawler;

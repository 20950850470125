import { ActionIcon, Button, Card, Group, LoadingOverlay, Stack, Text, TextInput, Tooltip, SimpleGrid, useMantineTheme, useMantineColorScheme } from '@mantine/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderContext from 'src/services/header-context';
import { IconArrowBackUp, IconDeviceDesktopAnalytics, IconDeviceFloppy, IconEdit, IconShieldLock, IconUserCircle } from '@tabler/icons-react';
import ContentWrapper from 'src/components/content-wrapper';
import CollapsibleCard from 'src/components/collapsible-card';
import { LanguageSeletor } from 'src/components/language-selector';
import { container } from 'src/inversify.config';
import { AppConfiguration } from 'src/core/services/authentication-service';
import { showNotification } from '@mantine/notifications';
import { formatBytes, formatMessage, formatNumber } from 'src/core/utils/object';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip as RechartTooltip } from 'recharts';
import moment from 'moment';
import { UserMetricsStore } from 'src/stores/metrics';
import ProfileIdentities from 'src/components/profile-identities';
import { TimezoneSelector } from 'src/core/ui/timezone-selector';
import { ProfileStore } from 'src/stores/profile';

export default function ProfilePage() {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const [edit, setEdit] = useState<boolean>(false);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const store = useMemo(() => container.get(ProfileStore), []);
  const state = store.state;

  const operationsStore = useMemo(() => container.get(UserMetricsStore), []);
  const operationsState = operationsStore.state;

  useEffect(() => {
    setHeader(t('User profile'), 'profile', <IconUserCircle size={34} />, t('User profile') as string);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/profile`, breadcrumbName: t('User profile') },
    ]);
  }, []);

  useEffect(() => {
    store.load("me");
    operationsStore.load("operations");
  }, []);

  const validate = () => {
    return state.item && state.item.value && state.item.language.value && state.item.language.value.length > 0 && state.item.displayName.value && state.item.displayName.value.length > 0;
  }

  const onSubmit = async () => {
    if (validate()) {
      await store.save('me', state.item.value as any);
      state.errorMessage.value ? showKoNotification() : showOkNotification();
      setEdit(false);
      await store.load("me");
    }
  }

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const onCancelChanges = async () => {
    setEdit(false);
    await store.load("me");
  }

  return (
    <ContentWrapper>
      <SimpleGrid cols={2}>
        <Stack>
          <CollapsibleCard
            title={<Text fw={500}>{t("User profile")}</Text>}
            cardKey={'user_profile'}
            icon={<IconUserCircle />}
            rightToolbar={!edit &&
              <Tooltip label={t("Edit")}>
                <ActionIcon size="sm" onClick={() => setEdit(true)} variant='subtle' color="gray">
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
            }>
            <Stack>
              <LoadingOverlay visible={state.isBusy.value} />
              {state.item && state.item.value && <>
                <Group grow align='flex-start'>
                  <TextInput
                    label={t("Identity")}
                    width='100%'
                    value={state.item.name.value}
                    readOnly
                  />
                </Group>

                <Group grow align='flex-start'>
                  <TextInput
                    label={t("Display name")}
                    value={state.item.displayName.value}
                    onChange={(e) => state.item.displayName.set(e.currentTarget.value)}
                    required
                    width='100%'
                    readOnly={!edit}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <LanguageSeletor
                    label={t("Language") as string}
                    allowedLanguages={defaultAllowedLanguages}
                    value={state.item.language.value}
                    onChange={(value) => state.item.language.set(value as string)}
                    required
                    readOnly={!edit}
                    placeholder={t("Select your language") as string}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <TextInput
                    label={t("Company")}
                    value={state.item.company?.value}
                    onChange={(e) => state.item.company.set(e.currentTarget.value)}
                    width='100%'
                    readOnly={!edit}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <TimezoneSelector
                    label={t("Time zone") as string}
                    value={state.item.timezone?.value}
                    onChange={(value) => state.item.timezone.set(value)}
                    width='100%'
                    readOnly={!edit}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <TextInput
                    label={t("Location")}
                    value={state.item.location?.value}
                    onChange={(e) => state.item.location.set(e.currentTarget.value)}
                    width='100%'
                    readOnly={!edit}
                  />
                </Group>

                {edit &&
                  <Group justify='flex-end'>
                    <Button
                      loading={state.isBusy.value}
                      variant='outline'
                      onClick={onCancelChanges}
                      leftSection={<IconArrowBackUp />}>
                      {t("Cancel")}
                    </Button>
                    <Button
                      loading={state.isBusy.value}
                      disabled={!validate()}
                      onClick={onSubmit}
                      leftSection={<IconDeviceFloppy />}>
                      {t("Save")}
                    </Button>
                  </Group>
                }
              </>}
            </Stack>
          </CollapsibleCard>
          <Card withBorder>
            <Stack>
              <Group justify="space-between">
                <Group gap="xs" wrap='nowrap'>
                  <IconDeviceDesktopAnalytics />
                  <Text fw={500}>{t("Metrics")}</Text>
                </Group>
              </Group>
              <LoadingOverlay visible={operationsState.isBusy.value} />
              {operationsState.item && operationsState.item.value && <SimpleGrid cols={3}>
                <ResponsiveContainer width={'100%'} height={150} className={`recharts-${colorScheme}`}>
                  <BarChart
                    data={operationsState.item.queries.value.map(o => ({ key: `${o.day}`, value: o.value }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="key" tickFormatter={value => moment(value).format("ll")} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]}>
                    </XAxis>
                    <YAxis domain={[0, 1]} tickFormatter={value => formatNumber(value)} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]} />
                    <RechartTooltip labelFormatter={(value: any) => moment(value).format('ll')} formatter={(value: any) => formatNumber(value)} wrapperClassName={colorScheme === 'dark' ? 'dark' : ''} />
                    <Legend verticalAlign="top" height={36} color={colorScheme === 'dark' ? 'white' : 'gray'} />
                    <Bar dataKey="value" name={t("User queries") as string} fill={theme.colors[theme.primaryColor][6]} />
                  </BarChart>
                </ResponsiveContainer>
                <ResponsiveContainer width={'100%'} height={150} className={`recharts-${colorScheme}`}>
                  <BarChart
                    data={operationsState.item.operations.value.map(o => ({ key: `${o.day}`, value: o.value }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="key" tickFormatter={value => moment(value).format("ll")} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]}>
                    </XAxis>
                    <YAxis domain={[0, 1]} tickFormatter={value => formatNumber(value)} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]} />
                    <RechartTooltip labelFormatter={(value: any) => moment(value).format('ll')} formatter={(value: any) => formatNumber(value)} wrapperClassName={colorScheme === 'dark' ? 'dark' : ''} />
                    <Legend verticalAlign="top" height={36} color={colorScheme === 'dark' ? 'white' : 'gray'} />
                    <Bar dataKey="value" name={t("AI Operations") as string} fill={theme.colors[theme.primaryColor][6]} />
                  </BarChart>
                </ResponsiveContainer>
                <ResponsiveContainer width={'100%'} height={150} className={`recharts-${colorScheme}`}>
                  <BarChart
                    data={operationsState.item.llmTokens.value.map(o => ({ key: `${o.day}`, value: o.value }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="key" tickFormatter={value => moment(value).format("ll")} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]}>
                    </XAxis>
                    <YAxis domain={[0, 1]} tickFormatter={value => formatNumber(value)} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]} />
                    <RechartTooltip labelFormatter={(value: any) => moment(value).format('ll')} formatter={(value: any) => formatNumber(value)} wrapperClassName={colorScheme === 'dark' ? 'dark' : ''} />
                    <Legend verticalAlign="top" height={36} color={colorScheme === 'dark' ? 'white' : 'gray'} />
                    <Bar dataKey="value" name={t("LLM tokens") as string} fill={theme.colors[theme.primaryColor][6]} />
                  </BarChart>
                </ResponsiveContainer>
              </SimpleGrid>}

              {operationsState.item && operationsState.item.value && <Group align='flex-start' grow>
                <TextInput
                  label={t("Documents")}
                  value={formatNumber(operationsState.item.documents.value)}
                  width='100%'
                  readOnly={true}
                />
                <TextInput
                  label={t("Chunks")}
                  value={formatNumber(operationsState.item.fragments.value)}
                  width='100%'
                  readOnly={true}
                />
                <TextInput
                  label={t("Documents storage size")}
                  value={formatBytes(operationsState.item.documentsSize.value)}
                  width='100%'
                  readOnly={true}
                />
              </Group>}
            </Stack>
          </Card>
        </Stack>
        <Card withBorder>
          <Stack>
            <Group justify="space-between">
              <Group gap="xs" wrap='nowrap'>
                <IconShieldLock />
                <Text fw={500}>{t("Connect with external identity providers")}</Text>
              </Group>
            </Group>
            <ProfileIdentities />
          </Stack>
        </Card>
      </SimpleGrid>
    </ContentWrapper>
  );
}
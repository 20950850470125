import { Container } from 'inversify';
import React from 'react';
import { AppConfiguration } from 'src/core/services/authentication-service';
import HttpService from 'src/core/services/http-service';
import { BotBasicInfoItemStore, BotDocumentSummaryStore, BotItemStore, BotSnapshotCompareStore, BotSnapshotHistoryStore, BotSnapshotSelectStore, BotSummaryStore } from './stores/bots';
import { BotCollectionSummaryStore, CollectionEntityItemStore, CollectionEntitySummaryStore, CollectionItemStore, CollectionSummaryStore } from './stores/collections';
import { DocumentItemStore, DocumentSnapshotHistoryStore, DocumentSnapshotPassageItemStore, DocumentSnapshotPassageSummaryStore, DocumentSummaryStore, DocumentViewerStore, SearchDocumentEntitiesStore, AskDocumentStore } from './stores/documents';
import { IdentityItemStore, IdentitySummaryStore } from './stores/identities';
import { SearchSkillEntitiesStore, SkillItemStore, SkillSnapshotCompareStore, SkillSnapshotHistoryStore, SkillSnapshotSelectStore, SkillSummaryStore, ChatBotStore, ChatSkillStore } from './stores/skills';
import { CrawlerItemStore, CrawlerLogSummaryStore, CrawlerPatchesItemStore, CrawlerPatchesSummaryStore, CrawlerSummaryStore, TestCrawlerConnectionStore } from './stores/crawlers';
import { EntityItemStore, EntitySummaryStore } from './stores/entities';
import { JobsSummaryStore, JobsStore } from './stores/jobs';
import { ServiceConnectionItemStore, ServiceConnectionSummaryStore, TestServiceConnectionStore } from './stores/serviceconnections';
import { ConfigStore, AskProfileStore } from './stores/options';
import { SystemMetricsStore, TenantQuotasStore, UserMetricsStore } from './stores/metrics';
import { ProfileIdentitiesStore, ProfileStore } from './stores/profile';

// Initialize DI/IoC container
const container = new Container();

const AppConfig = React.createContext<AppConfiguration>({} as any);

function initialize(config?: any) {
  const port = window.location.port == "80" || window.location.port == "443" ? "" : `:${window.location.port}`;
  let baseUri = config ? config.serviceUrl || config.ServiceUrl : `${window.location.protocol}//${window.location.host}${port}`;

  const appConfiguration = {
    brandName: config.brandName || "Ainhoa platform",
    serviceUrl: baseUri,
    clientId: config.clientId,
    authority: config.authority,
    authorizationEndpoint: config.authorizationEndpoint,
    tokenEndpoint: config.tokenEndpoint,
    logoutEndpoint: config.logoutEndpoint,
    scopes: config.scopes,
    theme: config.theme,
    allowedLanguages: config.allowedLanguages,
    defaultAudience: config.defaultAudience,
    version: config.version,
    allowPersonalCollection: config.allowPersonalCollection,
    sharingAccessMode: config.sharingAccessMode,
    personalCollectionLevel: config.personalCollectionLevel,
    supportDocs: config.supportDocs,
    supportNewIssueLink: config.supportNewIssueLink
  } as AppConfiguration;

  container
    .bind("AppConfiguration")
    .toConstantValue(appConfiguration);

  if (!container.isBound(HttpService)) {
    // Initialize services if container is not configured before
    container
      .bind(HttpService)
      .toSelf()
      .inSingletonScope()
      .onActivation((context: any, instance: any) => {
        instance.setup(baseUri);
        return instance;
      });
  }

  container.bind('baseUri').toConstantValue(baseUri);

  container.bind(IdentitySummaryStore).toDynamicValue(() => new IdentitySummaryStore(baseUri));
  container.bind(IdentityItemStore).toDynamicValue(() => new IdentityItemStore(baseUri));
  container.bind(SkillSummaryStore).toDynamicValue(() => new SkillSummaryStore(baseUri));
  container.bind(SkillItemStore).toDynamicValue(() => new SkillItemStore(baseUri));
  container.bind(SkillSnapshotHistoryStore).toDynamicValue(() => new SkillSnapshotHistoryStore(baseUri));
  container.bind(SkillSnapshotCompareStore).toDynamicValue(() => new SkillSnapshotCompareStore(baseUri));
  container.bind(CollectionSummaryStore).toDynamicValue(() => new CollectionSummaryStore(baseUri));
  container.bind(CollectionItemStore).toDynamicValue(() => new CollectionItemStore(baseUri));
  container.bind(CollectionEntitySummaryStore).toDynamicValue(() => new CollectionEntitySummaryStore(baseUri));
  container.bind(CollectionEntityItemStore).toDynamicValue(() => new CollectionEntityItemStore(baseUri));
  container.bind(DocumentSummaryStore).toDynamicValue(() => new DocumentSummaryStore(baseUri));
  container.bind(DocumentItemStore).toDynamicValue(() => new DocumentItemStore(baseUri));
  container.bind(DocumentSnapshotHistoryStore).toDynamicValue(() => new DocumentSnapshotHistoryStore(baseUri));
  container.bind(BotSummaryStore).toDynamicValue(() => new BotSummaryStore(baseUri));
  container.bind(BotItemStore).toDynamicValue(() => new BotItemStore(baseUri));
  container.bind(BotSnapshotHistoryStore).toDynamicValue(() => new BotSnapshotHistoryStore(baseUri));
  container.bind(BotSnapshotCompareStore).toDynamicValue(() => new BotSnapshotCompareStore(baseUri));
  container.bind(DocumentSnapshotPassageSummaryStore).toDynamicValue(() => new DocumentSnapshotPassageSummaryStore(baseUri));
  container.bind(DocumentSnapshotPassageItemStore).toDynamicValue(() => new DocumentSnapshotPassageItemStore(baseUri));
  container.bind(ChatSkillStore).toDynamicValue(() => new ChatSkillStore(baseUri));
  container.bind(ChatBotStore).toDynamicValue(() => new ChatBotStore(baseUri));
  container.bind(AskDocumentStore).toDynamicValue(() => new AskDocumentStore(baseUri));
  container.bind(EntitySummaryStore).toDynamicValue(() => new EntitySummaryStore(baseUri));
  container.bind(EntityItemStore).toDynamicValue(() => new EntityItemStore(baseUri));
  container.bind(CrawlerSummaryStore).toDynamicValue(() => new CrawlerSummaryStore(baseUri));
  container.bind(CrawlerItemStore).toDynamicValue(() => new CrawlerItemStore(baseUri));
  container.bind(CrawlerLogSummaryStore).toDynamicValue(() => new CrawlerLogSummaryStore(baseUri));
  container.bind(CrawlerPatchesSummaryStore).toDynamicValue(() => new CrawlerPatchesSummaryStore(baseUri));
  container.bind(CrawlerPatchesItemStore).toDynamicValue(() => new CrawlerPatchesItemStore(baseUri));
  container.bind(DocumentViewerStore).toDynamicValue(() => new DocumentViewerStore(baseUri));
  container.bind(SearchSkillEntitiesStore).toDynamicValue(() => new SearchSkillEntitiesStore(baseUri));
  container.bind(SearchDocumentEntitiesStore).toDynamicValue(() => new SearchDocumentEntitiesStore(baseUri));
  container.bind(SkillSnapshotSelectStore).toDynamicValue(() => new SkillSnapshotSelectStore(baseUri));
  container.bind(BotSnapshotSelectStore).toDynamicValue(() => new BotSnapshotSelectStore(baseUri));
  container.bind(JobsStore).toDynamicValue(() => new JobsStore(baseUri));
  container.bind(JobsSummaryStore).toDynamicValue(() => new JobsSummaryStore(baseUri));
  container.bind(ServiceConnectionSummaryStore).toDynamicValue(() => new ServiceConnectionSummaryStore(baseUri));
  container.bind(ServiceConnectionItemStore).toDynamicValue(() => new ServiceConnectionItemStore(baseUri));
  container.bind(ConfigStore).toDynamicValue(() => new ConfigStore(baseUri));
  container.bind(AskProfileStore).toDynamicValue(() => new AskProfileStore(baseUri));
  container.bind(BotCollectionSummaryStore).toDynamicValue(() => new BotCollectionSummaryStore(baseUri));
  container.bind(TestServiceConnectionStore).toDynamicValue(() => new TestServiceConnectionStore(baseUri));
  container.bind(BotBasicInfoItemStore).toDynamicValue(() => new BotBasicInfoItemStore(baseUri));
  container.bind(SystemMetricsStore).toDynamicValue(() => new SystemMetricsStore(baseUri));
  container.bind(ProfileStore).toDynamicValue(() => new ProfileStore(baseUri));
  container.bind(ProfileIdentitiesStore).toDynamicValue(() => new ProfileIdentitiesStore(baseUri));
  container.bind(UserMetricsStore).toDynamicValue(() => new UserMetricsStore(baseUri));
  container.bind(TenantQuotasStore).toDynamicValue(() => new TenantQuotasStore(baseUri));
  container.bind(TestCrawlerConnectionStore).toDynamicValue(() => new TestCrawlerConnectionStore(baseUri));
  container.bind(BotDocumentSummaryStore).toDynamicValue(() => new BotDocumentSummaryStore(baseUri));
}
export { container, initialize, AppConfig };

import { FC, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFileShredder as FileShredder } from '@tabler/icons-react';
import { container } from '../inversify.config';
import { BotTrainingStatus } from '../stores/bots';
import { DocumentTrainingStatus } from '../stores/documents';
import { JobsStore } from '../stores/jobs';
import { SkillTrainingStatus } from '../stores/skills';
import { CollectionStatus } from 'src/stores/collections';
import ButtonWithOptions, { ButtonOption } from './button-with-options';

const TrainButton: FC<{
  jobReference: string;
  status: SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus | CollectionStatus,
  onClick: MouseEventHandler<any> | undefined;
  onFinished?: () => void;
  options?: ButtonOption[];
}> = ({ jobReference, status, onClick, onFinished, options }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(JobsStore), []);
  const state = store.state;
  const [internalStatus, setInternalStatus] = useState<SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus | CollectionStatus>(status)
  const internalStatusRef = useRef(status);

  useEffect(() => {
    setInternalStatus(status)
    const interval = setInterval(async () => {
      if (jobReference && jobReference !== "" && (status == 'Training' || status == 'Pending') && internalStatusRef.current != 'Trained' && internalStatusRef.current != 'Error') {
        await store.load(jobReference)
        let newState: SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus | CollectionStatus = 'Pending'
        switch (state.item?.status?.value) {
          case 'Waiting':
            newState = 'Pending';
            break;
          case 'Running':
            newState = 'Training';
            break;
          case 'Succeeded':
            newState = 'Trained';
            break;
          case 'Failed':
          default:
            newState = 'Error';
            break;
        }
        setInternalStatus(newState)
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [jobReference, status])

  useEffect(() => {
    internalStatusRef.current = internalStatus
    if (status != internalStatus && (internalStatus == 'Error' || internalStatus == 'Trained')) {
      if (onFinished) {
        onFinished();
      }
    }
  }, [internalStatus])

  return (
    <ButtonWithOptions
      variant='outline'
      loading={internalStatus == 'Training'}
      onClick={onClick}
      leftSection={<FileShredder />}
      options={options}>
      {(internalStatus == 'Training') ? t("Training") : t("Train")}
    </ButtonWithOptions>
  );
};

export default TrainButton;

import { Text, Container, Group, Title, Card } from '@mantine/core';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconMessageChatbot as MessageChatbot } from '@tabler/icons-react';
import HeaderContext from 'src/services/header-context';
import ContentWrapper from 'src/components/content-wrapper';

const BotLanding: FC<{}> = () => {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);

  useEffect(() => {
    setHeader(t('Bots'), 'Bots', <MessageChatbot size={34} />, t('Bots') as string, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/bots`, breadcrumbName: t('Bots') },
    ]);
  }, []);

  return (
    <ContentWrapper>
      <Card withBorder>
        <Container size={700} p="lg">
          <Group>
            <MessageChatbot size={48} />
            <Title order={1}>
              {t('You are in')}{' '}
              <Text component="span" inherit>
                {t('Bots')}
              </Text>
            </Title>
          </Group>

          <Text size="lg" c="dimmed" mt="lg">
            {t('You can browse the different bots in the sidebar on the left, make filters and select an element to see its details')}
          </Text>
        </Container>
      </Card>
    </ContentWrapper>
  );
};

export default BotLanding;

import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderContext from 'src/services/header-context';
import { Card, Text, Container, Stack, SimpleGrid, useMantineTheme } from '@mantine/core';
import { container } from 'src/inversify.config';
import { AppConfiguration, useAuthentication } from 'src/core/services/authentication-service';
import { useNavigate } from 'react-router-dom';
import { IconBook2, IconFileText, IconWand, IconMessageChatbot, IconHome2 } from '@tabler/icons-react';
import ContentWrapper from 'src/components/content-wrapper';
import classes from 'src/pages/index.module.css';

function HomePage() {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  let navigate = useNavigate();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const brandName = container.get<AppConfiguration>("AppConfiguration").brandName;
  const [authStatus] = useAuthentication();

  useEffect(() => {
    setHeader(t('Home'), 'Home', <IconHome2 size={34} />, t('Home') as string);
    setRoutes([{ breadcrumbName: t('Home'), path: `/admin` }]);
  }, []);

  return (
    <ContentWrapper>
      <Card withBorder style={{ height: '100%' }}>
        <Container size="xl">
          <Stack align='center' justify='center'>
            <h1 style={{ textAlign: 'center' }}>
              {t("Welcome to")} {' '}
              <Text className={classes.title} component="span" variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][6], to: 'cyan' }} span inherit>
                {brandName}
              </Text>{' '}
            </h1>
            <Text size="lg" c="dimmed">
              <Text span>{t("Hello")}</Text> <Text span fw={500} c={theme.colors[theme.primaryColor][6]}>{authStatus?.user.value?.sub || t("Anonymous")}</Text>
              <Text span>, {t("we welcome you to the document management platform through chat bots.")}</Text>
            </Text>
            <Text size="lg" c="dimmed">{t("We facilitate the management of information in an efficient and collaborative way. You will be able to create collections of your documents, create dialog flows and take advantage of the power of bots to automate tasks and answer questions about your documents.")}</Text>

            <SimpleGrid cols={{ base: 1, md: 4 }} spacing="xl" m={20}>
              <Card key='collections' shadow="md" radius="md" p="xl" withBorder onClick={() => navigate('/admin/collections')} className={classes.homeFeatureCard}>
                <IconBook2 size={48} color={theme.colors[theme.primaryColor][6]} />
                <Text fz="lg" fw={500} mt="md" className={classes.homeFeatureCardTitle}>
                  {t("Collections")}
                </Text>
                <Text fz="sm" c="dimmed" mt="sm">
                  {t("Manage your documents in an organized way using collections. You will be able to train the complete content of the collection.")}
                </Text>
              </Card>

              <Card key='documents' shadow="md" radius="md" p="xl" withBorder onClick={() => navigate('/admin/documents')} className={classes.homeFeatureCard}>
                <IconFileText size={48} color={theme.colors[theme.primaryColor][6]} />
                <Text fz="lg" fw={500} mt="md" className={classes.homeFeatureCardTitle}>
                  {t("Documents")}
                </Text>
                <Text fz="sm" c="dimmed" mt="sm">
                  {t("Upload your documents in PDF or directly add the text you want to later train on them.")}
                </Text>
              </Card>

              <Card key='skills' shadow="md" radius="md" p="xl" withBorder onClick={() => navigate('/admin/skills')} className={classes.homeFeatureCard}>
                <IconWand size={48} color={theme.colors[theme.primaryColor][6]} />
                <Text fz="lg" fw={500} mt="md" className={classes.homeFeatureCardTitle}>
                  {t("Skills")}
                </Text>
                <Text fz="sm" c="dimmed" mt="sm">
                  {t("Create and configure your skills to search your documents or create complex workflows.")}
                </Text>
              </Card>

              <Card key='bots' shadow="md" radius="md" p="xl" withBorder onClick={() => navigate('/admin/bots')} className={classes.homeFeatureCard}>
                <IconMessageChatbot size={48} color={theme.colors[theme.primaryColor][6]} />
                <Text fz="lg" fw={500} mt="md" className={classes.homeFeatureCardTitle}>
                  {t("Bots")}
                </Text>
                <Text fz="sm" c="dimmed" mt="sm">
                  {t("Create and manage bots harness the power of bots to automate tasks and answer questions about your documents.")}
                </Text>
              </Card>
            </SimpleGrid>
          </Stack>
        </Container>
      </Card>
    </ContentWrapper>
  );
}

export default HomePage;

import { FC, useContext, useEffect, useState } from "react";
import { StepNodeProps } from "./steps";
import { useHookstate, none } from "@hookstate/core";
import { Group, Stack, Textarea, Text, Fieldset, Code, TextInput, Divider } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import StepWrapper from "./step-wrapper";
import { ServiceConnectionSelector } from "src/components/service-selector";
import NumberInputWithControl from "src/components/number-input-with-control";
import { truncateText } from "src/core/utils/object";
import HeaderContext from "src/services/header-context";
import LanguageSegmentedWrapper, { LanguageSegmentedWrapperChildrenProps } from "src/components/language-segmented-wrapper";

const StepLlmCallNode: FC<StepNodeProps> = ({ id, data }) => {
  const { step, edit, index, rightToolbar, updateNode } = data;
  const scopedState = useHookstate(step.action);
  const { t } = useTranslation();
  const { header } = useContext(HeaderContext);
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState.prompt?.value ? Object.keys(scopedState.prompt?.value) : defaultAllowedLanguages);

  // useEffect(() => {
  //   const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStepAction;
  //   const newAction = { ...step.action, ...changes };
  //   const newStep = { ...step, action: newAction };
  //   const newData = { index: index, step: newStep } as StepDataProps;
  //   updateNode(id, newData);
  // }, [JSON.stringify(scopedState.value)])

  useEffect(() => {
    setAllowedLanguages(scopedState.prompt?.value ? Object.keys(scopedState.prompt?.value) : defaultAllowedLanguages);
  }, [scopedState.prompt])

  const onDeleleLanguage = (lang: string) => {
    scopedState.prompt[lang].set(none);
    setIsDirty(true);
  }

  const onAddLanguage = (lang: string) => {
    scopedState.prompt[lang].set('');
    setIsDirty(true);
  }

  const resumeLang = allowedLanguages.includes(header.language) ? header.language : allowedLanguages[0];
  const resume = (
    <Stack gap="xs">
      <Text title={scopedState?.prompt?.[resumeLang]?.value}>{truncateText(scopedState?.prompt?.[resumeLang]?.value)}</Text>

      <Divider />
      <span>{t("Assign to")}&nbsp;
        <span><Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState?.entity?.value}</Code></span>
      </span>
    </Stack>
  )

  if (!scopedState?.value) return (<></>);

  return (
    <StepWrapper
      id={id}
      index={index}
      step={step}
      updateNode={updateNode}
      edit={edit}
      rightToolbar={rightToolbar}
      resume={resume}>
      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Prompt")}</Text>
          </Group>
        }>
        <Stack gap="xs">
          <LanguageSegmentedWrapper
            edit={edit}
            data={scopedState.prompt?.value ? Object.keys(scopedState.prompt?.value) : []}
            onDelete={onDeleleLanguage}
            onAdd={onAddLanguage}
          >
            {(props: LanguageSegmentedWrapperChildrenProps) =>
              <>
                {edit ?
                  <Stack gap="xs">
                    <Textarea
                      autosize
                      minRows={3}
                      maxRows={10}
                      required
                      style={{ flex: 1, minWidth: 400 }}
                      value={scopedState.prompt[props.selectedLanguage]?.value ?? ''}
                      onChange={(event) => { scopedState.prompt[props.selectedLanguage].set(event.target.value); setIsDirty(true); }}
                    />
                  </Stack>
                  :
                  <Stack gap="xs">
                    <Code style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{scopedState.prompt[props.selectedLanguage]?.value ?? ''}</Code>
                  </Stack>
                }
              </>
            }
          </LanguageSegmentedWrapper>
        </Stack>
      </Fieldset>

      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("LLM config.")}</Text>
          </Group>
        }>
        <Stack gap="xs">
          {edit ?
            <Stack gap="xs">
              <Group align="flex-start" grow>
                <ServiceConnectionSelector
                  label={t("Service connection") as string}
                  required={edit}
                  width='100%'
                  readOnly={!edit}
                  categoryFilter='Llm'
                  value={scopedState.service.value}
                  onChange={(value) => { scopedState.service.set(value as string); setIsDirty(true); }}
                />
                <NumberInputWithControl
                  required={edit}
                  width="100%"
                  label={t("Maximum tokens per response")}
                  description={t("The maximum number of tokens to generate in the response.") as string}
                  min={50}
                  step={512}
                  max={32000}
                  value={scopedState.maxTokens.value ?? 4096}
                  onChange={(value) => { scopedState.maxTokens.set(value as number); setIsDirty(true); }}
                  readOnly={!edit}
                />
                <NumberInputWithControl
                  required={edit}
                  width="100%"
                  label={t("Temperature")}
                  description={t("Controls the creativity or randomness of the text generated.") as string}
                  min={0}
                  step={0.1}
                  precision={1}
                  max={1}
                  value={scopedState.temperature.value ?? 0.5}
                  onChange={(value) => { scopedState.temperature.set(value as number); setIsDirty(true); }}
                  readOnly={!edit}
                />
              </Group>
            </Stack>
            :
            <Group align="flex-start">
              <Text span fw={500}>{t("Set")} </Text><Text span fs="italic">{scopedState.entity.value}</Text>
              <Text span fw={500}>{t("Temperature")} </Text><Text span fs="italic">{scopedState.temperature.value}</Text>
              <Text span fw={500}>{t("Max tokens")} </Text><Text span fs="italic">{scopedState.maxTokens.value}</Text>
            </Group>
          }
        </Stack>
      </Fieldset>

      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Assign to")}</Text>
          </Group>
        }>
        <TextInput
          required
          style={{ flex: 1 }}
          value={scopedState.entity.value}
          onChange={(event) => { scopedState.entity.set(event.target.value); setIsDirty(true); }}
        />
      </Fieldset>
    </StepWrapper>
  );
};

export default StepLlmCallNode;
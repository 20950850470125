import { Group, Stack, TextInput, Select, PasswordInput, Table } from '@mantine/core';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceConnectionRef } from './serviceconnection';
import { useHookstate } from '@hookstate/core';
import { GptServiceConnectionContent, ServiceConnectionGptProvider } from 'src/stores/serviceconnections';
import AppContext from 'src/services/app-context';
import { isNullOrWhitespace } from 'src/core/utils/object';
import NumberInputWithControl from 'src/components/number-input-with-control';
import SwitchButton from 'src/components/switch-button';
import classes from 'src/pages/index.module.css';

export const GptServiceConnection = React.forwardRef<ServiceConnectionRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as GptServiceConnectionContent);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set({
        apiKey: '',
        endpoint: '',
        model: 'gpt-3.5-turbo',
        provider: 'Azure',
        version: '2023-07-01-preview',
        maxTokenLength: 16385,
        parallelTools: true,
        multimodal: false
      } as GptServiceConnectionContent);
    }
  }, [content])

  useEffect(() => {
    switch (scopedState.provider.value) {
      case 'OpenAI':
        if (isNullOrWhitespace(scopedState.endpoint.value)) {
          scopedState.endpoint.set("https://api.openai.com/")
        }
        break;
    }
  }, [scopedState.provider.value])

  const isValid = () => {
    return /*scopedState.apiKey.value.length > 0 && */scopedState.endpoint.value.length > 0 &&
      scopedState.model.value.length > 0 && scopedState.provider.value.length > 0 && (scopedState.maxTokenLength?.value ?? 0) > 0 &&
      (scopedState.provider.value === 'OpenAI' || (scopedState.provider.value === 'Azure' && (scopedState.deployment?.value?.length ?? 0) > 0));
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Provider")}</Table.Td>
          <Table.Td>{scopedState.provider.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Model")}</Table.Td>
          <Table.Td>{scopedState.model.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Maximum token length")}</Table.Td>
          <Table.Td>{scopedState.maxTokenLength.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Endpoint")}</Table.Td>
          <Table.Td>{scopedState.endpoint.value}</Table.Td>
        </Table.Tr>
        {scopedState.provider.value === 'Azure' &&
          <Table.Tr>
            <Table.Td>{t("Deployment")}</Table.Td>
            <Table.Td>{scopedState.deployment.value}</Table.Td>
          </Table.Tr>
        }
        {scopedState.provider.value === 'OpenAI' &&
          <Table.Tr>
            <Table.Td>{t("Organization")}</Table.Td>
            <Table.Td>{scopedState.organization.value}</Table.Td>
          </Table.Tr>
        }
        <Table.Tr>
          <Table.Td>{t("Enable parallel function calling")}</Table.Td>
          <Table.Td>{scopedState.parallelTools.value.toString()}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Enable multimodal support")}</Table.Td>
          <Table.Td>{scopedState.multimodal.value.toString()}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Stack gap={10} justify="flex-start">
      <Group grow justify='space-between' align='flex-start'>
        <Select
          required
          allowDeselect={false}
          style={{ flex: 1 }}
          label={t("Provider")}
          placeholder={t("GPT provider") as string}
          data={[
            { value: 'Azure', label: t('Azure') as string },
            { value: 'OpenAI', label: t('OpenAI') as string },
          ]}
          value={scopedState.provider.value}
          onChange={(value) => { scopedState.provider.set(value as ServiceConnectionGptProvider); setIsDirty(true); }}
          readOnly={!canContribute}
        />

        <TextInput
          required
          style={{ flex: 1 }}
          label={t('Model')}
          value={scopedState.model.value}
          onChange={(event) => { scopedState.model.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />


        <NumberInputWithControl
          required
          width="100%"
          label={t("Maximum token length")}
          description={t("Maximum number of tokens used in the request.") as string}
          min={1024}
          step={512}
          max={128000}
          value={scopedState.maxTokenLength.value ?? 128000}
          onChange={(value) => { scopedState.maxTokenLength.set(value as number); setIsDirty(true); }}
          readOnly={!canContribute}
        />
      </Group>

      <TextInput
        required
        data-autofocus
        style={{ flex: 1 }}
        label={t('Endpoint')}
        value={scopedState.endpoint.value}
        onChange={(event) => { scopedState.endpoint.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

      <PasswordInput
        style={{ flex: 1 }}
        required
        label={t('API key')}
        value={scopedState.apiKey.value}
        onChange={(event) => { scopedState.apiKey.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

      {scopedState.provider.value == "Azure" && <TextInput
        required
        style={{ flex: 1 }}
        label={t('Deployment')}
        value={scopedState.deployment.value}
        onChange={(event) => { scopedState.deployment.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />}

      {scopedState.provider.value == "OpenAI" && <TextInput
        style={{ flex: 1 }}
        label={t('Organization')}
        value={scopedState.organization.value}
        onChange={(event) => { scopedState.organization.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />}

      <SwitchButton
        className={classes.switchButton}
        label={t("Enable parallel function calling")}
        description={t("If possible, functions will be called and executed in parallel.") as string}
        checked={scopedState.parallelTools.value}
        onChange={value => { scopedState.parallelTools.set(value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

      <SwitchButton
        className={classes.switchButton}
        label={t("Enable multimodal support")}
        description={t("Allows image analysis if model supports it.") as string}
        checked={scopedState.multimodal.value}
        onChange={value => { scopedState.multimodal.set(value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

    </Stack>
  );
});

export default GptServiceConnection;

import { ActionIcon, Tooltip, useMantineColorScheme } from '@mantine/core';
import React, { FC, useState } from 'react';
import { sleep } from 'src/core/utils/object';

const ChatMessageButton: FC<{
  tooltip?: React.ReactNode;
  timeout?: number;
  onClick?: () => void;
  icon: JSX.Element;
  clickedIcon?: JSX.Element;
  isBusy?: boolean;
}> = ({ tooltip, timeout, onClick, icon, clickedIcon, isBusy }) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const { colorScheme } = useMantineColorScheme();

  const handleOnClick = async () => {
    setClicked(prev => !prev);
    onClick?.();
    if (timeout) {
      await sleep(timeout);
      setClicked(prev => !prev);
    }
  }

  return (
    <Tooltip label={tooltip} withArrow>
      <ActionIcon variant='subtle' size={20} color={clicked ? (colorScheme === 'dark' ? 'white' : 'dark') : 'gray'} onClick={handleOnClick} disabled={isBusy}>
        {clicked && clickedIcon ? clickedIcon : icon}
      </ActionIcon>
    </Tooltip>
  );
};

export default ChatMessageButton;

import DataStore from "src/core/stores/data-store";
import FormStore from "src/core/stores/form-store";

export type JobStatus = 'Waiting' | 'Running' | 'Succeeded' | 'Failed';

export interface JobInfo {
  id: number,
  taskName: string,
  description: string,
  workerId: string,
  progress: number,
  status: JobStatus,
  statusMessage: string;
  createdOn?: Date,
  modifiedOn: Date,
  durationInSeconds: number,
  createdBy: string
}

export interface JobSummary {
  id: number,
  taskName: string,
  description: string,
  workerId: string,
  progress: number,
  status: JobStatus,
  statusMessage: string;
  createdOn?: Date,
  modifiedOn: Date,
  durationInSeconds: number,
  createdBy: string
}

export class JobsStore extends FormStore<JobInfo, JobInfo> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/jobs`);
  }
}

export class JobsSummaryStore extends DataStore<JobSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/jobs`, []);
  }

  public async clearCompletedJobs(days?: number, succedeedOnly?: boolean) {
    const succedeedOnlyParam = `succedeedOnly=${succedeedOnly ?? false}`;
    const params = days ? `?days=${days}&${succedeedOnlyParam}` : `?${succedeedOnlyParam}`;
    await this.handleCallAsync(async () => {
      await this.httpService.delete(`${this.baseUrl}/completed${params}`, null);
    });
  }

  public async restartJob(id: number) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/requeue`, null);
    });
  }
}
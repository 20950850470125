import { Stack, ScrollArea, useMantineColorScheme, Box, Tooltip, ActionIcon } from "@mantine/core";
import { FC, useEffect } from "react";
import { ChatItem, ChatRequest, ChatResponse } from "src/stores/chat";
import { none, useHookstate } from "@hookstate/core";
import classes from 'src/pages/index.module.css';
import { isNullOrWhitespace } from "src/core/utils/object";
import { AsEnumerable } from "linq-es5";
import { IconDots } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { isInitialEvent, isLastEvent } from "src/components/chat/chat-utils";
import ChatMessageEvent from "src/components/chat/chat-message-event";
import ChatMessageUser from "src/components/chat/chat-message-user";
import ChatMessageAssistant from "src/components/chat/chat-message-assistant";

const DocumentChatHistory: FC<{
  content: string;
  scrollAreaHeight?: string | number;
}> = ({ content, scrollAreaHeight = 'calc(100svh - 180px)' }) => {
  const { colorScheme } = useMantineColorScheme();
  const { t } = useTranslation();
  const conversation = useHookstate({} as ChatItem);
  const eventGroupState = useHookstate({
    showEvents: [] as number[],
    groupId: 0
  });

  useEffect(() => {
    if (content) {
      const chatHistory = JSON.parse(content) as ChatItem;
      conversation.set(chatHistory);

      eventGroupState.set({
        showEvents: [],
        groupId: 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  let gId = eventGroupState.groupId.value;

  const toggleEventGroup = (groupId: number | undefined) => {
    if (groupId !== undefined) {
      const index = eventGroupState.showEvents.value.indexOf(groupId);
      if (index >= 0) {
        eventGroupState.showEvents[index].set(none);
      }
      else {
        eventGroupState.showEvents.merge([groupId]);
      }
    }
  }

  return (
    <ScrollArea style={{ height: scrollAreaHeight }} className={classes.customPrismContainer} offsetScrollbars>
      <div data-color-mode={colorScheme}>
        <Stack>
          {conversation?.messages?.value?.length > 0 && conversation.messages.map((msg, index) => {
            if (index > 0 && msg.role && msg.role.value === 'Event' && conversation.messages.value[index - 1].role !== 'Event'
              && !isNullOrWhitespace(conversation.messages.value[index - 1].text)) {
              gId += 1;
            }
            else {
              gId = conversation.messages.value[index - 1]?.groupId ?? gId;
            }

            msg.groupId.set(msg.role.value === 'Event' ? gId : undefined);
            const lastEvent = isLastEvent(conversation.messages.value as ((ChatRequest | ChatResponse)[]), index);
            const initialEvent = isInitialEvent(conversation.messages.value as ((ChatRequest | ChatResponse)[]), index);

            if (!msg.text?.value) return; // for empty responses
            if (initialEvent) return; // hide initial events

            return (
              <Box
                key={`messagebox-${index}`}
                mod={{
                  groupId: msg.groupId?.value ?? '',
                  role: msg.role.value,
                  timestamp: msg.timestamp?.value ? new Date(msg.timestamp.value).getTime() : '',
                  messageId: msg.id.value
                }}
                style={msg.role.value === 'Event' && !lastEvent &&
                  msg.groupId?.value !== undefined && eventGroupState.showEvents.value.indexOf(msg.groupId.value) < 0
                  ? { display: 'none' }
                  : {}}>
                <Stack gap="xs"
                  key={`message-wrap-${index}`}>
                  {msg.role.value === 'Event' &&
                    <ChatMessageEvent
                      state={msg as any}
                      moreOptions={msg.role.value === 'Event' && lastEvent && AsEnumerable(conversation.messages.value).Count(o => o.role === 'Event' && o.groupId === msg.groupId.value) > 1 &&
                        <Tooltip label={t("Show/hide all events")}>
                          <ActionIcon variant="subtle" radius="xl" size="sm" onClick={() => toggleEventGroup(msg.groupId.value)}>
                            <IconDots />
                          </ActionIcon>
                        </Tooltip>}
                    />
                  }

                  {msg.role.value === 'User' &&
                    <ChatMessageUser state={msg as any} />
                  }

                  {msg.role.value === 'Assistant' &&
                    <ChatMessageAssistant messageIndex={index} state={msg as any} />
                  }
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </div>
    </ScrollArea>
  );
};

export default DocumentChatHistory;
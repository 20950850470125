import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Anchor, Badge, Group, Stack, Text, Title, Tooltip, useMantineTheme, Box, Select } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import HeaderContext from 'src/services/header-context';
import { SkillSummary, SkillSummaryStore } from 'src/stores/skills';
import moment from 'moment';
import useBus from 'use-bus';
import StatusBadge from 'src/components/status-badge';
import JobStatus from 'src/components/job-status';
import { IconWand, IconMessages, IconFileSearch, IconBinaryTree2, IconLock, IconLockOpen, IconMathFunction, IconClock } from '@tabler/icons-react';
import classes from 'src/pages/index.module.css';
import SimpleListV2, { SimpleListV2Model } from 'src/core/ui/simple-list/simple-list-v2';

const SkillList: React.FC<{ showFilters: boolean }> = ({ showFilters }) => {
  const { t } = useTranslation();
  const { header, setHeader, setRoutes } = useContext(HeaderContext);
  const summaryStore = useMemo(() => container.get(SkillSummaryStore), []);
  const summaryState = summaryStore.state;
  const theme = useMantineTheme();
  const { height } = useViewportSize();
  const [query, setQuery] = useState<Query>(undefined as any);
  const { skillId } = useParams();
  const [skillTypeSelected, setSkillTypeSelected] = useState<string>('');

  useEffect(() => {
    if (skillId) {
      setHeader(`${skillId}`, 'Skills', <IconWand size={34} />, `${skillId} | ${t("Skills")}`, true, true);
    }
    else {
      setHeader(t('Skills'), 'Skills', <IconWand size={34} />, t('Skills') as string, true, true);
    }
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/skills`, breadcrumbName: t('Skills') },
    ]);

    const newQuery = {
      ...query,
      orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
      skip: 0,
      take: 20,
    } as Query;
    setQuery(newQuery);
  }, []);

  useEffect(() => {
    if (query) {
      load(query);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [JSON.stringify(query)]);

  const load = async (query: Query) => {
    summaryStore.load(query);
  };

  const onReload = () => {
    load(query);
  };

  useEffect(() => {
    const newQuery = {
      ...query,
      orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
      skip: 0,
      take: 20,
      parameters: {
        typeFilter: skillTypeSelected,
      },
    } as Query;
    setQuery(newQuery);
  }, [skillTypeSelected]);


  useBus(
    '@@ui/SKILL_LIST_REFRESH',
    () => onReload(),
    [query],
  );

  const getIconType = (item: SkillSummary) => {
    let icon = <></>;
    switch (item.type) {
      case 'Function':
        icon = <IconMathFunction color="gray" size={16} />;
        break;
      case 'Dialog':
        icon = <IconMessages color="gray" size={16} />;
        break;
      case 'Search':
        icon = <IconFileSearch color="gray" size={16} />;
        break;
      default:
        icon = <IconBinaryTree2 color="gray" size={16} />;
        break;
    }

    return (
      <Tooltip label={t(item.type.valueOf())}>
        <ActionIcon variant='transparent'>
          {icon}
        </ActionIcon>
      </Tooltip>
    );
  }

  const model: SimpleListV2Model<SkillSummary> = {
    data: summaryStore.toListState(summaryState.value),
    query,
    columns: [
      {
        accessor: 'title',
        title: t('Title'),

        render: (item: SkillSummary) => (
          <Anchor component={Link} to={`/admin/skills/${item.id}`} underline="never" variant="text">
            <Stack gap="xs" style={{ minHeight: 60 }}>
              <Group gap="xs" justify='space-between' wrap='nowrap' align='flex-start'>
                <Title order={5} className={classes.titleList}>{item.title}</Title>

                <Group gap={0}>
                  {getIconType(item)}
                  <Tooltip label={item.accessMode === 'Everyone' ? t("Public access") as string : t("Members only") as string}>
                    <ActionIcon variant='transparent' color="gray">
                      {item.accessMode === 'Everyone' ? <IconLockOpen size={16} /> : <IconLock size={16} />}
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </Group>

              <Group gap="xs">
                {item.currentSnapshotDateTime &&
                  <Tooltip label={<Text size='sm'>{moment(item.currentSnapshotDateTime).toString()}</Text>}>
                    <Badge variant='light' leftSection={<IconClock size={16} />}>{moment(item.currentSnapshotDateTime).fromNow()}</Badge>
                  </Tooltip>
                }
                <StatusBadge status={item.status} />
                <JobStatus status={item.trainingStatus} jobReference={item.jobReference} />
              </Group>

              <Text c="dimmed" size="xs">
                {!item.description && t('(No description)')}
                {item.description[header.language]}
              </Text>
            </Stack>
          </Anchor>
        )
      }
    ]
  };

  const getScrollAreaHeight = () => {
    return showFilters ? height - 210 : height - 90;
  }

  return (
    <Stack gap="xs">
      {showFilters &&
        <>
          <Text size="sm" fw={500}>
            {t('Filter by type')}
          </Text>
          <Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flexGrow: 1 }}>
                <Select
                  allowDeselect
                  clearable
                  placeholder={t("Skill type") as string}
                  w='100%'
                  data={[
                    { value: 'Dialog', label: t('Dialog') as string },
                    { value: 'Search', label: t('Search') as string },
                    { value: 'Function', label: t('Function') as string },
                  ]}
                  value={skillTypeSelected}
                  onChange={(value) => setSkillTypeSelected(value as any)}
                />
              </div>
            </div>
          </Box>
        </>
      }
      <SimpleListV2
        idAccessor={'id'}
        rowStyle={({ id }) => (id === skillId ? { backgroundColor: 'var(--mantine-primary-color-light)' } : undefined)}
        model={model}
        onQueryChanged={setQuery}
        onRefresh={onReload}
        height={getScrollAreaHeight()}
        showFilters={showFilters}
      />
    </Stack>
  );
};

export default SkillList;

import { ActionIcon, Anchor, Card, Group, Select, Stack, Text, Tooltip } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrawlerAction, CrawlerSummary, CrawlerSummaryStore } from "src/stores/crawlers";
import { container } from "src/inversify.config";
import { Query } from "src/core/stores/data-store";
import CrawlerStatusBadge from "src/components/crawler-status-badge";
import { formatMessage, sleep } from "src/core/utils/object";
import EditCrawler from "./crawler-edit";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { dispatch } from "use-bus";
import TableListV2, { TableListV2Model } from "src/core/ui/table-list/table-list-v2";
import { DataTableSortStatus } from "mantine-datatable";
import { IconPlayerPlay, IconPlayerStop, IconFileStack, IconAdjustments } from "@tabler/icons-react";
import CreateCrawlerWizard from "./create-crawler-wizard";

const CollectionCrawlersCard: FC<{
  collectionId: string;
  canContribute: boolean;
  edit?: boolean;
}> = ({ collectionId, canContribute, edit = false }) => {
  const { t } = useTranslation();
  const modals = useModals();
  const store = useMemo(() => container.get(CrawlerSummaryStore), []);
  const state = store.state;
  const [query, setQuery] = useState<Query>(undefined as any);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [typeFilter, setTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'title', direction: 'asc' });

  useEffect(() => {
    if (collectionId) {
      store.setCollection(collectionId);
      const newQuery = {
        ...query,
        //orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        parameters: {
          collectionId: collectionId,
          typeFilter: typeFilter,
          statusFilter: statusFilter,
          orderByField: sortStatus.columnAccessor,
          orderByDirection: sortStatus.direction
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, typeFilter, statusFilter]);

  useEffect(() => {
    if (query) {
      store.load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onReload = () => {
    store.load(query)
  };

  const openRunActionModal = (crawlerId: string, action: CrawlerAction) =>
    modals.openConfirmModal({
      title: <Text>{t('Run action')}</Text>,
      children: (
        <Text size="sm">{t('Are you sure you want to perform the following action?')} <Text span fw={500}>{action.toUpperCase()}</Text></Text>
      ),
      labels: { confirm: t('Run action'), cancel: t('Cancel') },
      onConfirm: () => onConfirmAction(crawlerId, action)
    });

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const onConfirmAction = async (crawlerId: string, action: CrawlerAction) => {
    await store.runCrawlerAction(crawlerId, action);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      onReload();
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
    }
  };

  const onConfirmDelete = async (selectedKeys: CrawlerSummary[]) => {
    if (selectedKeys && selectedKeys.length > 0) {
      selectedKeys.forEach((key) => { store.delete(key.id); });
      await sleep(500);
      onReload();
    }
  };

  const model: TableListV2Model<CrawlerSummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'title',
        title: t('Title'),
        sortable: true,
        render: (item: CrawlerSummary) => (
          <Tooltip label={edit ? t("Edit crawler") : t("View crawler")}>
            <Anchor onClick={() => { setSelectedItem(item.id); setShowEditModal(true); }}>{item.title}</Anchor>
          </Tooltip>
        )
      },
      {
        accessor: 'type',
        title: t('Type'),
        sortable: true,
        render: (item: CrawlerSummary) => (
          <Text>{item.type}</Text>
        )
      },
      {
        accessor: 'status',
        title: t('Status'),
        render: (item: CrawlerSummary) => (
          <CrawlerStatusBadge status={item.status} />
        )
      },
      {
        accessor: 'schedule',
        title: t('Schedule'),
        render: (item: CrawlerSummary) => (
          <Text>{item.schedule}</Text>
        )
      },
      {
        accessor: 'actions',
        title: t('Actions'),
        render: (item: CrawlerSummary) => (
          <Tooltip label={item.status === 'Idle' ? t("Start crawler") : t("Stop crawler")}>
            <ActionIcon disabled={!canContribute} onClick={() => openRunActionModal(item.id, item.status === 'Idle' ? 'start' : 'stop')}>
              {item.status === 'Idle' ? <IconPlayerPlay size={16} /> : <IconPlayerStop size={16} />}
            </ActionIcon>
          </Tooltip>
        )
      }
    ]
  };

  return (
    <Stack gap="md">
      <Card withBorder>
        <TableListV2
          model={model}
          onQueryChanged={setQuery}
          onRefresh={onReload}
          striped
          highlightOnHover
          sortable
          sortStatus={sortStatus}
          onSortStatusChanged={setSortStatus}
          leftToolBarRender={
            <Group justify='space-between'>
              <IconFileStack />
              <Text fw={500}>{t('Crawlers')}</Text>
            </Group>
          }
          rightToolBarRender={
            <Group gap="xs" align="center">
              <Select
                clearable
                style={{ width: 200 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Status') as string}
                data={[
                  { value: 'Idle', label: t('Idle') },
                  { value: 'Running', label: t('Running') }
                ]}
                onChange={(value) => setStatusFilter(value as string)}
                value={statusFilter}
              />
              <Select
                clearable
                style={{ width: 200 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Type') as string}
                data={[
                  { value: 'ServiceNow', label: t('ServiceNow') },
                  { value: 'Web', label: t('Web') },
                  { value: 'SharePoint', label: t('Microsoft SharePoint') }
                ]}
                onChange={(value) => setTypeFilter(value as string)}
                value={typeFilter}
              />
            </Group>
          }
          showNewIcon={canContribute && edit}
          onNewItemClick={() => setShowCreateModal(true)}
          selectable={canContribute && edit}
          onDeleteSelectedClick={(selectedKeys: CrawlerSummary[]) => onConfirmDelete(selectedKeys)}
        />
        {showCreateModal &&
          <CreateCrawlerWizard
            opened={showCreateModal}
            onClose={() => { setShowCreateModal(false); onReload(); }}
            collectionId={collectionId} />

        }
        {showEditModal && selectedItem &&
          <EditCrawler
            key={selectedItem as string}
            opened={showEditModal}
            onClose={() => { setShowEditModal(false); setSelectedItem(undefined); onReload(); }}
            edit={edit}
            collectionId={collectionId}
            crawlerId={selectedItem as string}
            canContribute={canContribute}
          />
        }
      </Card>
    </Stack>
  );
};

export default CollectionCrawlersCard;
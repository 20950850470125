import { Button, Group, Paper, Text, Stack, useMantineTheme, LoadingOverlay, TextInput, Loader } from '@mantine/core';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { useNavigate } from 'react-router-dom';
import classes from 'src/pages/start.module.css';
import { ConfigStore, GlobalConfigOptions, ProfileConfigOption } from 'src/stores/options';
import { useHookstate } from '@hookstate/core';
import { LanguageSeletor } from 'src/components/language-selector';
import SwitchButton from 'src/components/switch-button';
import { ThemeSelector } from 'src/components/theme-selector';
import { showNotification } from '@mantine/notifications';
import { AppConfiguration, useAuthentication } from 'src/core/services/authentication-service';
import { formatMessage } from 'src/core/utils/object';

const StartSetup: FC<{}> = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const config = container.get<AppConfiguration>("AppConfiguration");
  const brandName = config.brandName;
  const navigate = useNavigate();
  const [authStatus] = useAuthentication();
  const isOwner = authStatus?.user.value?.isOwner;
  const configStore = useMemo(() => container.get(ConfigStore), []);
  const configState = configStore.state;
  const isBusy = configState.isBusy.value;
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const initialConfigState = {
    theme: "default",
    allowPersonalCollection: false,
    brandName: brandName,
    allowedLanguages: defaultAllowedLanguages
  } as GlobalConfigOptions;
  const scopedConfigState = useHookstate(initialConfigState);
  const initialProfileState = {
    includeGroups: true,
    uri: '',
    headers: [],
    method: 'GET',
    transformer: '',
    defaultAudience: 'global'
  } as ProfileConfigOption;
  const scopedProfileState = useHookstate(initialProfileState);

  useEffect(() => {
    if (isOwner) {
      loadConfig();
    }
  }, [])

  const loadConfig = async () => {
    await configStore.load('global');
    //const configItem = JSON.parse(JSON.stringify(configState?.item?.value));
    //setShowConfigWizard(!isBusy && (!hasConfig || (hasConfig && !!configState.errorMessage.value)));
  }

  const onStart = async () => {
    await configStore.save('global', scopedConfigState.value);
    await configStore.save('profile', scopedProfileState.value);
    if (!configState.errorMessage.value) {
      navigate(`/admin`);
      window.location.reload();
    }
    else {
      showNotification({
        title: t('Error'),
        message: <Text>{formatMessage(configState.errorMessage.value)}.</Text>,
        color: 'red'
      });
    }
  }

  const configWizardIsValid = scopedConfigState.brandName.value.length > 0 && scopedConfigState.theme.value.length > 0 &&
    scopedConfigState.allowedLanguages.value.length > 0;

  return (
    <Paper withBorder shadow="md" p={30} radius="md" className='center' mih={350} pos="relative">
      <Stack gap="sm">
        <h1 className={classes.subtitle}>
          {t("Welcome to")} {' '}<br />
          <Text className={classes.title} component="span" variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][6], to: 'cyan' }} span inherit>
            {brandName}
          </Text>{' '}
        </h1>

        {isBusy ?
          <Stack my="xl" justify='center' align='center'>
            <Loader size="xl" />
            <Text c="dimmed">{t("Please wait")}</Text>
          </Stack>
          :
          <>
            <Text>{t("Please fill out the following information to get started")}:</Text>

            <TextInput
              label={t("Brand name")}
              value={scopedConfigState.brandName.value}
              onChange={(e) => scopedConfigState.brandName.set(e.currentTarget.value)}
              required
              width='100%'
              error={scopedConfigState.brandName?.value?.length === 0 ? t("Required") : undefined}
            />

            <Group grow align='flex-start' justify='space-between'>
              <ThemeSelector
                label={t("Theme color") as string}
                value={scopedConfigState.theme.value}
                onChange={(value) => scopedConfigState.theme.set(value)}
                required
                width='100%'
              />

              <TextInput
                label={t("Default audience")}
                value={scopedProfileState.defaultAudience?.value}
                onChange={(event) => scopedProfileState.defaultAudience.set(event.target.value)}
                width='100%'
              />
            </Group>

            <LanguageSeletor
              label={t("Allowed languages") as string}
              value={scopedConfigState.allowedLanguages.value as string[]}
              onChange={(value) => scopedConfigState.allowedLanguages.set(value as string[])}
              multiselect
              showAll
              required
              width='100%'
              error={scopedConfigState.allowedLanguages.value.length < 1 ? t("Pick at least one item") : undefined}
            />

            <SwitchButton
              className={classes.switchButton}
              label={t("Allow personal collection")}
              checked={scopedConfigState.allowPersonalCollection.value ?? false}
              onChange={value => scopedConfigState.allowPersonalCollection.set(value)}
              description={t("Enables the option for users to create personal document collections. Only one collection will be created per user.") as string}
            />

            <Group mt="md" grow align="flex-end">
              <Button onClick={onStart} loading={isBusy} disabled={!configWizardIsValid}>{t("Start")}</Button>
            </Group>
          </>
        }
      </Stack>
    </Paper>
  );
}

export default StartSetup;

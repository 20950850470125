import { useEffect, useState, FC } from 'react';
import { ListState, Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Badge, Group, Input, Loader, MantineStyleProp, Text, Tooltip } from '@mantine/core';
import { GenericSelector } from 'src/core/ui/generic-selector';
import { ISnapshotSelectStore, SnapshotBasic } from 'src/stores/skills';
import moment from 'moment';
import { IconAlertTriangle, IconEdit, IconVersions as Versions } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { AsEnumerable } from 'linq-es5';

export const SnapshotSelector: FC<{
  value?: string;
  onChange?: (value: string) => void;
  store: ISnapshotSelectStore;
  width?: string | number;
  label?: string;
  required?: boolean;
  clearable?: boolean;
  creatable?: boolean;
  exclude?: string[];
  readOnly?: boolean;
  style?: MantineStyleProp;
  onOpen?: () => void;
  showWarningModal?: boolean;
}> = ({ value, onChange, store, width, label, required, clearable, creatable, exclude, readOnly = false, style, onOpen, showWarningModal = false }) => {
  const state = store.state;
  const isBusy = state.isBusy.value;
  const { t } = useTranslation();
  const [query, setQuery] = useState<Query>(undefined as any);
  const [showSnapshotSelector, setShowSnapshotSelector] = useState<boolean>(false);

  useEffect(() => {
    if (store) {
      const newQuery = {
        ...query,
        searchQuery: '',
        orderBy: [{ field: 'createdOn', direction: 'Descending', useProfile: false }],
        skip: 0,
        take: 50000,
      } as Query;
      setQuery(newQuery);
    }
  }, [store])

  useEffect(() => {
    if (query) {
      load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  const load = async (query: Query) => {
    if (store) {
      store.get(query);
    }
  };

  const renderDataItem = (item: SnapshotBasic) => {
    return {
      label: moment(item.createdOn).format('lll'),
      value: item.id,
      isCurrent: item.isCurrent
    } as any;
  }

  const renderOption = (item: SnapshotBasic) => (
    <Group gap="xs" wrap='nowrap'>
      <Versions size={20} color="gray" /><Text size='sm'>{moment(item.createdOn).format('lll')}</Text>
      {item.isCurrent &&
        <Badge size="xs">{t("Current")}</Badge>
      }
    </Group>
  );

  const getRightSection = () => {
    if (isBusy) return <Loader size={20} color="gray" />;
    if (value && !isBusy && state?.items?.value) {
      const item = state.items.value.find((i) => i.id === value);
      if (item && item.isCurrent) {
        return <Badge variant='light' size="xs">{t("Current")}</Badge>;
      }
    }
    return null;
  }

  const openChangeSnapshotModal = () => {
    showWarningModal ?
      modals.openConfirmModal({
        title: <Text>{t('Change snapshot version')}</Text>,
        children: (
          <Text size="sm">
            {t('Are you sure you want to change the snapshot version?')}
            <br></br>
            {t('The conversation will be restarted')}
          </Text>
        ),
        labels: { confirm: t('Change snapshot'), cancel: t('Cancel') },
        confirmProps: { color: 'yellow', leftSection: <IconAlertTriangle /> },
        onConfirm: () => {
          setShowSnapshotSelector(true);
        }
      })
      :
      setShowSnapshotSelector(true);
  }

  const renderLabel = () => {
    if (value && state?.items?.value) {
      const item = AsEnumerable(state.items.value).FirstOrDefault(x => x.id === value);
      if (item && !item.isCurrent) {
        return moment(item.createdOn).format('lll');
      }
    }

    return t("Current");
  }

  return (
    <>
      {store && !showSnapshotSelector &&
        <Input.Wrapper label={t("Snapshot version")}>
          <Group>
            <Badge mt={10} variant='light'
              rightSection={!readOnly ?
                <Tooltip label={t("Select different version")}
                  style={{
                    fontSize: 'var(--mantine-font-size-xs)',
                    textTransform: 'none',
                    fontWeight: 'normal'
                  }}>
                  <ActionIcon onClick={() => openChangeSnapshotModal()} variant='subtle' color="gray">
                    <IconEdit size={16} />
                  </ActionIcon>
                </Tooltip> : <></>}>
              {renderLabel()}
            </Badge>
          </Group>
        </Input.Wrapper>
      }
      {store && showSnapshotSelector &&
        <GenericSelector
          label={label}
          data={state.value as ListState<SnapshotBasic>}
          renderDataItem={(item) => renderDataItem(item)}
          query={query}
          onQueryChanged={setQuery}
          value={value}
          onChange={onChange}
          width={width || 300}
          placeholder={t('Select a snapshot') as string}
          // searchable
          // searchField='name'
          clearable={clearable}
          renderOption={renderOption}
          required={required}
          creatable={creatable}
          exclude={exclude}
          withinPortal
          rightSection={getRightSection()}
          rightSectionWidth={70}
          readOnly={readOnly}
          style={style}
          onOpen={onOpen}
        />
      }
    </>
  );
};

import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance, AxiosError, AxiosProgressEvent } from 'axios';
import { injectable } from 'inversify';
import queryString from 'query-string';
import { authenticationService } from './authentication-service';
import autobind from 'autobind-decorator';
import { formatMessage } from 'src/core/utils/object';

@injectable()
export default class HttpService {
  protected static timeout: number = 600000;
  public serviceUrl: string = '';
  private failureCount: number = 0;
  public static language: string;

  public async accessToken() {
    try {
      const tokenInfo = await authenticationService!.getAccessToken();

      if (tokenInfo && tokenInfo.token) {
        return tokenInfo.token.value;
      }
      return null;
    } catch {
      return null;
    }
  }

  public online(): Promise<AxiosResponse> {
    return this.get('api/v1/online');
  }

  @autobind
  public async get<TResponse>(url: string, data?: any, anonymous?: boolean): Promise<AxiosResponse<TResponse>> {
    try {
      if (data) {
        const params = {} as any;
        for (const prop of Object.getOwnPropertyNames(data)) {
          const type = typeof data[prop];
          if (type !== 'function' && type !== 'object') {
            params[prop] = data[prop];
          }
        }
        url += (url.search(/\?/) !== -1 ? '&' : '?') + queryString.stringify(params);
      }
      const config = await this.getConfig(url, data, anonymous);
      var result = await this.httpClient(url).get(url, config);
      this.failureCount = 0;
      return result;
    } catch (reason) {
      //alert("Network error\n" + reason);
      return Promise.reject(this.handleError(reason as any));
    }
  }

  @autobind
  public async post<TRequest, TResponse>(url: string, data: TRequest, dataconfig?: any, anonymous?: boolean): Promise<AxiosResponse<TResponse>> {
    try {
      if (dataconfig) {
        const params = {} as any;
        for (const prop of Object.getOwnPropertyNames(dataconfig)) {
          const type = typeof dataconfig[prop];
          if (type !== 'function' && type !== 'object') {
            params[prop] = dataconfig[prop];
          }
        }
        url += (url.search(/\?/) !== -1 ? '&' : '?') + queryString.stringify(params);
      }
      const config = await this.getConfig(url, dataconfig, anonymous);
      var result = await this.httpClient(url).post(url, data, config);
      this.failureCount = 0;
      return result;
    } catch (reason) {
      return Promise.reject(this.handleError(reason as any));
    }
  }

  @autobind
  public async *post_streamed<TRequest, TResponse>(url: string, data: TRequest, signal: AbortSignal, dataconfig?: any, anonymous?: boolean): AsyncGenerator<TResponse> {
    let processed = 0;
    let received = 0;
    let messages: TResponse[] = [];
    let done = false;
    let errorMessage: string | undefined = undefined;

    if (dataconfig) {
      const params = {} as any;
      for (const prop of Object.getOwnPropertyNames(dataconfig)) {
        const type = typeof dataconfig[prop];
        if (type !== 'function' && type !== 'object') {
          params[prop] = dataconfig[prop];
        }
      }
      url += (url.search(/\?/) !== -1 ? '&' : '?') + queryString.stringify(params);
    }
    const config = await this.getConfig(url, dataconfig, anonymous);
    config.signal = signal;
    config.onDownloadProgress = (progressEvent: AxiosProgressEvent) => {
      if (signal.aborted) {
        done = true;
        return;
      }
      let eventObj: XMLHttpRequest | undefined = undefined;
      if (progressEvent.event?.currentTarget) {
        eventObj = progressEvent.event?.currentTarget;
      } else if (progressEvent.event?.srcElement) {
        eventObj = progressEvent.event?.srcElement;
      } else if (progressEvent.event?.target) {
        eventObj = progressEvent.event?.target;
      }
      if (!eventObj) {
        done = true;
        return;
      }
      if (eventObj.status === 200) {
        if (eventObj.response.endsWith('\n')) {
          // Split events 
          var crLfIdx = eventObj.response.lastIndexOf('\r\n');
          if (crLfIdx > received) {
            const content = eventObj.response.slice(received, crLfIdx) as string;
            var deltas = content.split('\r\n').filter(o => o.startsWith('data: ')).map(o => o.slice(6)).filter(o => o !== 'null').map(o => JSON.parse(o));
            messages = [...messages, ...deltas];
            received = crLfIdx;
          }
        }
        else {
          // Wait for CRLF
        }
      }
      else {
        done = true;
        try {
          errorMessage = formatMessage(JSON.parse(eventObj.response))
        } catch {
          errorMessage = formatMessage(eventObj.response)
        }
      }
    }

    this.httpClient(url)
      .post(url, data, config)
      .then(() => { done = true })
      .catch(() => { done = true; });

    while (processed < messages.length || !done) {
      if (processed < messages.length) {
        yield messages[processed];
        processed += 1;
      } else {
        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });
      }
    }
    if (errorMessage)
      throw errorMessage;
    this.failureCount = 0;
  }

  @autobind
  public async patch<TRequest, TResponse>(url: string, data: TRequest, dataconfig?: any, anonymous?: boolean): Promise<AxiosResponse<TResponse>> {
    try {
      if (dataconfig) {
        const params = {} as any;
        for (const prop of Object.getOwnPropertyNames(dataconfig)) {
          const type = typeof dataconfig[prop];
          if (type !== 'function' && type !== 'object') {
            params[prop] = dataconfig[prop];
          }
        }
        url += (url.search(/\?/) !== -1 ? '&' : '?') + queryString.stringify(params);
      }
      const config = await this.getConfig(url, dataconfig, anonymous);
      var result = await this.httpClient(url).patch(url, data, config);
      this.failureCount = 0;
      return result;
    } catch (reason) {
      return Promise.reject(this.handleError(reason as any));
    }
  }

  @autobind
  public async put<TRequest, TResponse>(url: string, data: TRequest, dataconfig?: any, anonymous?: boolean): Promise<AxiosResponse<TResponse>> {
    try {
      if (dataconfig) {
        const params = {} as any;
        for (const prop of Object.getOwnPropertyNames(dataconfig)) {
          const type = typeof dataconfig[prop];
          if (type !== 'function' && type !== 'object') {
            params[prop] = dataconfig[prop];
          }
        }
        url += (url.search(/\?/) !== -1 ? '&' : '?') + queryString.stringify(params);
      }
      const config = await this.getConfig(url, dataconfig, anonymous);
      var result = await this.httpClient(url).put(url, data, config);
      this.failureCount = 0;
      return result;
    } catch (reason) {
      return Promise.reject(this.handleError(reason as any));
    }
  }

  @autobind
  public async delete<TRequest, TResponse>(url: string, data?: TRequest, dataconfig?: any, anonymous?: boolean): Promise<AxiosResponse<TResponse>> {
    try {
      if (dataconfig) {
        const params = {} as any;
        for (const prop of Object.getOwnPropertyNames(dataconfig)) {
          const type = typeof dataconfig[prop];
          if (type !== 'function' && type !== 'object') {
            params[prop] = dataconfig[prop];
          }
        }
        url += (url.search(/\?/) !== -1 ? '&' : '?') + queryString.stringify(params);
      }
      const config: AxiosRequestConfig = await this.getConfig(url, dataconfig, anonymous);
      config.data = data;
      //const response = this.httpClient(url).delete(url, config);
      // see: https://github.com/axios/axios/issues/3220#issuecomment-688566578
      var result = await this.httpClient(url).request({
        ...config,
        method: 'delete',
        url,
      });
      this.failureCount = 0;
      return result;
    } catch (reason) {
      return Promise.reject(this.handleError(reason as any));
    }
  }

  public setup(serviceUrl: string) {
    this.serviceUrl = serviceUrl;
  }

  protected handleError(error: AxiosError) {
    let msg;
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          if (error.response.data && error.response.data != '' && error.response.data != ' ') {
            msg = { message: formatMessage(error.response.data), status: error.response.status };
          } else {
            msg = { message: 'Not found', status: error.response.status };
          }
          break;
        case 401:
          msg = { message: 'Access is denied', status: error.response.status };
          if (this.failureCount < 3) {
            this.failureCount++;
          }
          break;
        default:
          msg = formatMessage(error.response.data);
          break;
      }
    } else {
      msg = error;
    }

    return msg;
  }

  private httpClient(url: string): AxiosInstance {
    let newUrl = this.serviceUrl;
    if (url.startsWith('/')) {
      var parser = document.createElement('a');
      parser.href = this.serviceUrl;
      newUrl = `${parser.protocol}//${parser.host}`;
    } else {
      newUrl = this.serviceUrl;
    }
    return axios.create({
      baseURL: newUrl,
      timeout: HttpService.timeout,
    });
  }

  protected async getConfig(url: string, data?: any, anonymous?: boolean) {
    let headers = (data ? data.headers || {} : {}) as any;

    var antiForgeryToken = this.getCookie('XSRF-TOKEN');
    if (antiForgeryToken) {
      headers['X-XSRF-TOKEN'] = antiForgeryToken;
    }

    if (!anonymous) {
      const accessToken = await this.accessToken();

      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    if (HttpService.language) {
      headers['language'] = HttpService.language;
    }

    return Object.assign(data || {}, {
      headers: headers,
    });
  }

  private getCookie(name: string) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }
}

import { FC } from 'react';
import { ColorSwatch, Group, Select, Text, ComboboxItem, MantineSize } from '@mantine/core';
import { globalPallettes } from 'src';

export const ThemeSelector: FC<{
  value: string;
  label?: string;
  onChange: (value: string) => void;
  required?: boolean;
  width?: string | number;
  readOnly?: boolean;
  size?: MantineSize;
}> = ({ value, onChange, label, required = false, width, readOnly, size }) => {
  const getData = () => {
    let data = [] as ComboboxItem[];

    // Object.keys(globalPallettes || {}).map((p) => {
    //   data.push({ label: p, value: p, color: globalPallettes[p][5] });
    // });

    Object.keys(globalPallettes || {}).map((p) => {
      data.push({ label: p, value: p });
    });

    return data;
  }

  const getColor = (value: string) => {
    if (value) {
      return globalPallettes[value][5];
    }

    return null;
  }

  // const renderOption = (item: SnapshotBasic) => (
  //   <Group gap="xs">
  //     <ColorSwatch key={color} color={color} />
  //     <Text size='sm'>{label}</Text>
  //   </Group>
  // );

  return (
    <Select
      required={required}
      label={label}
      w={width ?? 240}
      data={getData()}
      value={value as string}
      onChange={(value) => onChange(value as string)}
      readOnly={readOnly}
      rightSection={
        <ColorSwatch key={value} color={getColor(value as string)} />
      }
      size={size}
    />
  );
};

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import { Link } from 'react-router-dom';
import { IconHome2 } from '@tabler/icons-react';
import { NotFoundInfo } from 'src/core/ui/not-found-component';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);

  useEffect(() => {
    setHeader(t('Home'), 'Projects', <IconHome2 size={34} />, t('Home') as string);
    setRoutes([
      { path: ``, breadcrumbName: t('Home') },
      { path: `/404`, breadcrumbName: t('Not Found') },
    ]);
  }, [])

  return (
    <NotFoundInfo
      height="100svh"
      title={t('You have found a secret place')}
      description={t('Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.') as string}>
      <Group justify="center">
        <Button component={Link} to={'/'} size="md">
          {t('Take me back to home page')}
        </Button>
      </Group>
    </NotFoundInfo>
  );
};

export default NotFoundPage;

import { none, useHookstate } from '@hookstate/core';
import { ActionIcon, Alert, Badge, Button, Group, Input, LoadingOverlay, Select, Stack, Text, TextInput, Textarea, Tooltip, Grid, Switch, Divider } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleCard from 'src/components/collapsible-card';
import { formatMessage } from 'src/core/utils/object';
import { container } from 'src/inversify.config';
import AppContext from 'src/services/app-context';
import { AskProfileStore, ConfigStore, ProfileConfigOption } from 'src/stores/options';
import { ContentKeyValue } from 'src/stores/skills';
import { useModals } from '@mantine/modals';
import { IconSettings, IconEdit, IconAlertTriangle, IconFileSettings, IconPlus, IconTrash, IconArrowBackUp, IconDeviceFloppy, IconUserSearch } from '@tabler/icons-react';
import ChatComponent from 'src/components/chat';

const ProfileConfig: FC<{}> = () => {
  const { t } = useTranslation();
  const modals = useModals();
  const store = useMemo(() => container.get(ConfigStore), []);
  const state = store.state;
  const { setIsDirty } = useContext(AppContext);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [externalService, setExternalService] = useState<boolean>(false);
  const initialState = {
    includeGroups: true,
    uri: '',
    headers: [],
    method: 'GET',
    transformer: '',
    defaultAudience: 'global'
  } as ProfileConfigOption;
  const scopedState = useHookstate(initialState);
  const askStore = useMemo(() => container.get(AskProfileStore), []);
  const showWarningInfo = !state.isBusy.value && ((!state?.item?.value && !initialized) || (state?.item?.value && state.errorMessage.value));

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (initialized && !externalService) {
      scopedState.uri.set("")
      scopedState.headers.set([])
      scopedState.transformer.set("")
    }
  }, [externalService]);

  const load = async () => {
    await store.load('profile');
    if (state?.item?.value && !state.errorMessage.value) {
      setInitialized(true);
      scopedState.set(JSON.parse(JSON.stringify(state.item.value)));
      if (scopedState.uri.value) {
        setExternalService(true);
      }
    }
    else {
      setInitialized(false);
      scopedState.set(initialState);
    }
  };

  const onAddHeader = () => {
    if (scopedState.headers?.value) {
      scopedState.headers[scopedState.headers.length].set({ key: '', value: '' } as ContentKeyValue);
    }
    else {
      scopedState.merge({ headers: [{ key: '', value: '' } as ContentKeyValue] });
    }
  }

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const onSubmit = async () => {
    await store.save('profile', scopedState.value);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    setIsDirty(false);
    setEdit(false);
    await load();
  }

  const onCancelChanges = async () => {
    setIsDirty(false);
    setEdit(false);
    await load();
  }

  const onCreateProfile = () => {
    setInitialized(true);
    setEdit(true);
    store.clearError();
    store.clear();
  }

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Delete profile')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete profile'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(),
    });

  const onConfirmDelete = async () => {
    await store.delete('profile');
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      await load();
      setIsDirty(false);
      setEdit(false);
    }
  };

  return (
    <>
      <CollapsibleCard
        title={<Text fw={500}>{t('External profile options')}</Text>}
        cardKey={'external_profile'}
        icon={<IconSettings />}
        rightToolbar={!edit &&
          <Tooltip label={t("Edit")}>
            <ActionIcon size="sm" onClick={() => setEdit(true)} variant='subtle' color="gray">
              <IconEdit />
            </ActionIcon>
          </Tooltip>
        }
        collapseInfoRender={
          initialized ?
            <Badge variant='light' color='gray'>{t("Configured")}</Badge>
            :
            <Badge variant='light' color='gray' leftSection={<IconAlertTriangle size={10} />}>{t("Not configured")}</Badge>
        }>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={state.isBusy.value} />
          {showWarningInfo &&
            <Alert icon={<IconAlertTriangle />} title={t("External profile options not found")} color="yellow">
              <Stack>
                <Text>{t("No external profile has been configured. Click on the button below to create it.")}</Text>
                <Group align='center' justify='left'>
                  <Button variant="outline" color="yellow" mb="xs"
                    leftSection={<IconFileSettings />}
                    onClick={onCreateProfile}>
                    {t("Configure external profile")}
                  </Button>
                </Group>
              </Stack>
            </Alert>
          }
          {initialized && scopedState.value &&
            <Stack gap="md">
              <Group>
                <Input.Wrapper label={t("Default audience")} required={edit}>
                  <Input
                    required={edit}
                    readOnly={!edit}
                    variant={edit ? 'default' : 'unstyled'}
                    value={scopedState.defaultAudience?.value}
                    onChange={(event) => { scopedState.defaultAudience.set(event.target.value); setIsDirty(true) }}
                  />
                </Input.Wrapper>
                <Switch
                  mt="lg"
                  label={t("Include user groups in the audience")}
                  checked={scopedState.includeGroups.value ?? false}
                  onChange={e => edit ? scopedState.includeGroups.set(e.target.checked) : null}
                  readOnly={!edit}
                />
                <Switch
                  mt="lg"
                  label={t("Enable external profile")}
                  checked={externalService ?? false}
                  onChange={e => edit ? setExternalService(e.target.checked) : null}
                  readOnly={!edit}
                />
              </Group>
              {externalService && <>
                <Group align="flex-start" grow>
                  <Input.Wrapper label={t("URL")}>
                    <Group align="flex-start">
                      <Select
                        allowDeselect={false}
                        w={100}
                        readOnly={!edit}
                        variant={edit ? 'default' : 'unstyled'}
                        data={[
                          { value: 'GET', label: t('GET') as string },
                          { value: 'POST', label: t('POST') as string },
                          { value: 'PUT', label: t('PUT') as string }
                        ]}
                        value={scopedState.method.value}
                        onChange={(value) => { scopedState.method.set(value as any); setIsDirty(true) }}
                      />
                      <Input
                        required={edit}
                        readOnly={!edit}
                        variant={edit ? 'default' : 'unstyled'}
                        style={{ flex: 1 }}
                        value={scopedState.uri.value}
                        onChange={(event) => { scopedState.uri.set(event.target.value); setIsDirty(true) }}
                      />
                    </Group>
                  </Input.Wrapper>
                </Group>
                <Group align="center">
                  <Input.Label w={120}>{t("Headers")}:</Input.Label>
                  <Group align="center" gap={5}>
                    <Text size="sm">{scopedState.headers && scopedState.headers?.value?.length > 0 ? t("With headers") : t("Without headers")}</Text>
                    {edit &&
                      <Tooltip withinPortal label={t("Add header")}>
                        <ActionIcon onClick={onAddHeader} variant='subtle' color="gray">
                          <IconPlus size={16} />
                        </ActionIcon>
                      </Tooltip>
                    }
                  </Group>
                </Group>
                {scopedState.headers?.value && scopedState.headers?.map((header, index) =>
                  <Group key={index} align="center">
                    <Input.Label w={120}></Input.Label>
                    {edit ?
                      <>
                        <TextInput
                          required={edit}
                          readOnly={!edit}
                          variant={edit ? 'default' : 'unstyled'}
                          style={{ flex: 1 }}
                          value={header.key.value}
                          placeholder={t("<your header name>") as string}
                          onChange={(event) => { header.key.set(event.target.value); setIsDirty(true); }}
                        />
                        :
                        <TextInput
                          required={edit}
                          readOnly={!edit}
                          variant={edit ? 'default' : 'unstyled'}
                          style={{ flex: 1 }}
                          value={header.value.value}
                          placeholder={t("<your header value>") as string}
                          onChange={(event) => { header.merge(i => ({ value: event.currentTarget.value })); setIsDirty(true); }}
                        />
                      </>
                      :
                      <Group gap={5} align='center'>
                        <Text>{header.key.value}</Text>
                        <Text>:</Text>
                        <Text>{header.value.value}</Text>
                      </Group>
                    }
                    {edit &&
                      <ActionIcon variant='subtle' color="red" onClick={() => { header.set(none); setIsDirty(true); }}>
                        <IconTrash />
                      </ActionIcon>
                    }
                  </Group>
                )}
                <Input.Wrapper label={t("Transformer") as string}>
                  <Textarea
                    autosize
                    minRows={2}
                    maxRows={3}
                    required={edit}
                    readOnly={!edit}
                    variant={edit ? 'default' : 'unstyled'}
                    style={{ flex: 1 }}
                    value={scopedState.transformer.value}
                    onChange={(event) => { scopedState.transformer.set(event.target.value); setIsDirty(true) }}
                  />
                </Input.Wrapper>
              </>}
              {edit &&
                <Group justify='flex-end'>
                  <Button
                    disabled={!state?.item?.value}
                    loading={state.isBusy.value}
                    onClick={openDeleteModal}
                    color="red"
                    leftSection={<IconTrash />}>
                    {t("Delete")}
                  </Button>
                  <Button
                    loading={state.isBusy.value}
                    variant='outline'
                    onClick={onCancelChanges}
                    leftSection={<IconArrowBackUp />}>
                    {t("Cancel")}
                  </Button>
                  <Button
                    loading={state.isBusy.value}
                    onClick={onSubmit}
                    leftSection={<IconDeviceFloppy />}>
                    {t("Save")}
                  </Button>
                </Group>
              }
            </Stack>}
        </div>
      </CollapsibleCard >
      <br />
      <CollapsibleCard
        title={<Text fw={500}>{t('Test user profile')}</Text>}
        cardKey={'test_external_profile'}
        icon={<IconUserSearch />}>
        <Grid gutter="xs">
          <Grid.Col span={'auto'}>
            <ChatComponent
              id={'profile'}
              language={"en"}
              store={askStore}
              description={t("Type any user id or email to get profile information") as string}
              hideHeader
              hideDebugPanel
              height={600}
              key={'profile'}
              hideTokens
              sendInitialMessage={false}
              hideUserPreferences
            />
          </Grid.Col>
        </Grid>
      </CollapsibleCard>
    </>
  );
};

export default ProfileConfig;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langEn from './assets/translations/locale-en.json';
import langEs from './assets/translations/locale-es.json';
import langFr from './assets/translations/locale-fr.json';
import langIt from './assets/translations/locale-it.json';
import langPt from './assets/translations/locale-pt.json';

const resources = {
  en: {
    translations: langEn,
  },
  es: {
    translations: langEs,
  },
  fr: {
    translations: langFr,
  },
  it: {
    translations: langIt,
  },
  pt: {
    translations: langPt,
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    debug: false,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

import { Stack, Group, Input } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { LanguageSegmentedControl } from "./language-segmented-control";
import { container } from "src/inversify.config";
import { AppConfiguration } from "src/core/services/authentication-service";

export interface LanguageSegmentedWrapperChildrenProps {
  selectedLanguage: string;
}

interface LanguageSegmentedWrapperProps {
  data?: string[];
  label?: string;
  edit?: boolean;
  required?: boolean;
  disabled?: boolean;
  onDelete?: (lang: string) => void;
  onAdd?: (lang: string) => void;
  children: any;
}

const LanguageSegmentedWrapper: FC<LanguageSegmentedWrapperProps> = ({ data, label = "", edit = true, required = false, disabled = false, children, onDelete, onAdd }) => {
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(data && data.length > 0 ? data[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    if (data && !data.includes(selectedLanguage)) {
      setSelectedLanguage(data.length > 0 ? data[0] : defaultAllowedLanguages[0]);
    }
  }, [data])

  const childrenNode = children({ selectedLanguage: selectedLanguage } as LanguageSegmentedWrapperChildrenProps);

  const onChangeLanguage = (lang: string) => {
    setSelectedLanguage(lang);
  }

  const onAddLanguage = (lang: string) => {
    onAdd?.(lang);
    setSelectedLanguage(lang);
  }

  const onDeleteLanguage = (lang: string) => {
    onDelete?.(lang);
  }

  return (
    <Stack gap={5}>
      <Group justify='space-between'>
        {label &&
          <Input.Label required={required}>{label}</Input.Label>
        }
        <LanguageSegmentedControl
          editable={edit && !disabled}
          data={data}
          language={selectedLanguage}
          onChange={onChangeLanguage}
          onDelete={onDeleteLanguage}
          onAdd={onAddLanguage}
        />
      </Group>

      {childrenNode}
    </Stack>
  );
};

export default LanguageSegmentedWrapper;
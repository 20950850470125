import { State, none, useHookstate } from '@hookstate/core';
import { ActionIcon, Button, Group, Input, Stack, Text, TextInput } from '@mantine/core';
import { IconTag, IconTrash } from '@tabler/icons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TagItem } from 'src/stores/skills';

const TagsEditor: FC<{
  state: State<TagItem[]>;
  readOnly?: boolean;
}> = ({ state, readOnly }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);

  return (
    <Stack gap="lg" justify="flex-start">
      <Input.Wrapper label={t("Tags")} description={!readOnly ? <Text size='xs'>{t('To add new tags click on the Add tag button')}</Text> : undefined}>
        {scopedState.map((tag: State<TagItem>, index: number) => (
          <Group key={index} mt="xs">
            <TextInput
              required
              placeholder={t('Name') as string}
              style={{ flex: 1 }}
              width="100%"
              value={tag.name.value}
              onChange={(event) => tag.name.set(event.target.value)}
              readOnly={readOnly}
            />
            <TextInput
              placeholder={t('Value') as string}
              style={{ flex: 1 }}
              width="100%"
              value={tag.value.value}
              onChange={(event) => tag.merge(t => ({ value: event.target.value }))}
              readOnly={readOnly}
            />
            {!readOnly &&
              <ActionIcon
                color="red"
                variant='subtle'
                onClick={() => tag.set(none)}>
                <IconTrash />
              </ActionIcon>
            }
          </Group>
        ))}
      </Input.Wrapper>
      {!readOnly &&
        <Group justify="center">
          <Button
            variant='default'
            size='sm'
            onClick={() => scopedState[scopedState.length].set({ name: '', value: '' })}
            leftSection={<IconTag />}>
            {t("Add tag")}
          </Button>
        </Group>
      }
    </Stack>
  );
};

export default TagsEditor;

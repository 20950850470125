import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Group, Modal, Tooltip, Text, MantineColor, MantineSize } from '@mantine/core';
import { IconRestore, IconUserCog } from '@tabler/icons-react';
import { useHookstate } from '@hookstate/core';
import { preferencesModifiedByUserState, setChatPreferencesAsDefault } from 'src/components/chat/chat-utils';
import { BotBasicInfoItem } from 'src/stores/bots';
import ChatUserPreferences from 'src/components/chat/chat-user-preferences';

const ChatUserPreferencesButton: FC<{ botInfo: BotBasicInfoItem; color?: MantineColor, hiddenFrom?: MantineSize }> = ({ botInfo, color, hiddenFrom }) => {
  const { t } = useTranslation();
  const [showUserPreferencesOptions, setShowUserPreferencesOptions] = useState<boolean>(false);
  const modifiedByUser = useHookstate(preferencesModifiedByUserState);

  return (
    <>
      <Tooltip label={t("User preferences")}>
        <ActionIcon
          hiddenFrom={hiddenFrom}
          variant="subtle"
          color={modifiedByUser.value ? 'var(--mantine-color-orange-6)' : color ?? 'gray'}
          onClick={() => setShowUserPreferencesOptions((value) => !value)}>
          <IconUserCog />
        </ActionIcon>
      </Tooltip>

      <Modal opened={showUserPreferencesOptions} onClose={() => setShowUserPreferencesOptions(false)} centered
        styles={{ title: { width: '100%' } }}
        title={
          <Group justify="space-between" align="center" wrap="nowrap" mr="xs">
            <Text style={{ flex: 1 }}>{t("User preferences")}</Text>
            <Tooltip label={t("Default values")}>
              <ActionIcon variant="subtle" color="gray" onClick={() => setChatPreferencesAsDefault(botInfo)}>
                <IconRestore size={16} />
              </ActionIcon>
            </Tooltip>
          </Group>
        }>
        <ChatUserPreferences botInfo={botInfo} />
      </Modal>
    </>
  );
};

export default ChatUserPreferencesButton;

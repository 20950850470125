import { none, useHookstate } from "@hookstate/core";
import { Group, TextInput, Stack, Tooltip, ActionIcon, Drawer, useMantineColorScheme, InputLabel, Box, useMantineTheme, Button, ScrollArea, Grid } from "@mantine/core";
import { IconPlayerPlayFilled, IconPlus, IconSquareX, IconTrash } from "@tabler/icons-react";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import { ContentKeyValue, SkillDebugJinjaItem, SkillItemStore } from "src/stores/skills";
import { Editor, useMonaco } from "@monaco-editor/react";
import { v4 as uuidv4 } from 'uuid';
import { LanguageSeletor } from "src/components/language-selector";
import HeaderContext from "src/services/header-context";
import { authStatus } from "src/core/services/authentication-service";
import { IdentitySelector } from "src/components/identity-selector";
import { container } from "src/inversify.config";
import { formatMessage } from "src/core/utils/object";
import { monacoConfiguration, monacoJinjaDef, monacoOptions } from "src/configurations/editor-config-jinja";

interface DebugJinjaOptions {
  template: string,
  output: string,
  conversationContext: {
    conversationId: string,
    identity: {
      name: string
    },
    language: string,
    container: string[],
    entities: { [key: string]: any[] }
    // variables: { [key: string]: any },
    // messages: { [key: string]: any }
  }
}

const SkillDebugJinjaModal: FC<{
  value: string;
  open: boolean;
  onSave: (value: string) => void;
  onClose: () => void;
}> = ({ value, open, onSave, onClose }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const monaco = useMonaco();
  const { header } = useContext(HeaderContext);
  const { setIsDirty } = useContext(AppContext);
  const [opened, setOpened] = useState<boolean>(false);
  const skillStore = useMemo(() => container.get(SkillItemStore), []);
  const skillState = skillStore.state;
  const isBusy = skillState.isBusy.value;

  useEffect(() => {
    if (open && value) {
      onOpenDebugJinja();
    }
  }, [value, open])

  useEffect(() => {
    if (monaco) {
      if (!monaco.languages.getLanguages().some((id: string) => id === 'jinja')) {
        // Register a new language
        monaco.languages.register({ id: 'jinja' });
        // Register a tokens provider for the language
        monaco.languages.setMonarchTokensProvider('jinja', monacoJinjaDef);
        // Set the editing configuration for the language
        monaco.languages.setLanguageConfiguration('jinja', monacoConfiguration);
      }
    }
  }, [monaco]);

  let outputMonacoOptions = { ...monacoOptions };
  outputMonacoOptions.readOnly = true;

  const debugJinjaInitialState = {
    template: "",
    output: "",
    conversationContext: {
      conversationId: uuidv4(),
      identity: { name: authStatus?.user.value?.sub ?? "" },
      language: header.language,
      container: [],
      entities: {},
    },
    // variables: {},
    // mensajes: {}
  } as DebugJinjaOptions;
  const debugJinjaState = useHookstate(debugJinjaInitialState);
  const entitiesState = useHookstate([] as ContentKeyValue[]);

  const onOpenDebugJinja = () => {
    debugJinjaState.template.set(value);
    setOpened(true);
  }

  const onCloseDebugJinja = () => {
    debugJinjaState.output.set("");
    //debugJinjaState.set(debugJinjaInitialState);
    skillStore.clearError();
    setOpened(false);
    onClose();
  }

  const onSaveDebugJinja = () => {
    onSave(debugJinjaState.template.value);
    onCloseDebugJinja();
  }

  const onAddEntity = () => {
    if (entitiesState?.value) {
      entitiesState[entitiesState.length].set({ key: '', value: '' } as ContentKeyValue);
    }
    else {
      entitiesState.merge([{ key: '', value: '' } as ContentKeyValue]);
    }
  }

  const onDebug = async () => {
    let entities = {} as { [key: string]: any[] };
    for (const item of entitiesState.value) {
      entities[item.key] = [{ label: item.key, literal: item.key, value: item.value }];
    }

    debugJinjaState.conversationContext.entities.set(entities);

    const body = {
      template: debugJinjaState.template.value,
      conversationContent: JSON.stringify(debugJinjaState.conversationContext.value, null, 2)
    } as SkillDebugJinjaItem;

    const response = await skillStore.debujJinja(body);
    if (skillState.errorMessage.value) {
      debugJinjaState.output.set(formatMessage(skillState.errorMessage.value));
    }
    else {
      debugJinjaState.output.set(response);
    }
  }

  return (
    <Drawer opened={opened} onClose={onCloseDebugJinja}
      title={t("Jinja editor")}
      closeOnEscape={false}
      closeOnClickOutside={false}
      position="right"
      size="95%">
      <Stack>
        <Grid>
          <Grid.Col span={9}>
            <Stack pr="xs" style={{ flex: 1, borderRight: `1px solid ${colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3]}`, }}>
              <Stack gap={5}>
                <Group justify="space-between">
                  <InputLabel>{t("Jinja code")}</InputLabel>
                  <ActionIcon variant="subtle" color="green" size="sm" onClick={onDebug} loading={isBusy}>
                    <IconPlayerPlayFilled />
                  </ActionIcon>
                </Group>
                <Box style={{ width: '100%', height: '39svh' }}>
                  <Editor
                    width="100%"
                    height="100%"
                    options={monacoOptions}
                    language="jinja"
                    value={debugJinjaState.template.value}
                    onChange={(value) => debugJinjaState.template.set(value as string)}
                    theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                  />
                </Box>
              </Stack>

              <Stack gap={5}>
                <Group justify="flex-start" gap="xs">
                  <InputLabel>{t("Output")}</InputLabel>
                  {skillState.errorMessage.value &&
                    <IconSquareX size={16} color="red" />
                  }
                </Group>
                <Box style={{ width: '100%', height: '39svh' }}>
                  <Editor
                    width="100%"
                    height="100%"
                    options={outputMonacoOptions}
                    language="plaintext"
                    value={debugJinjaState.output.value}
                    onChange={(value) => debugJinjaState.output.set(value as string)}
                    theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                  />
                </Box>
              </Stack>
            </Stack>
          </Grid.Col>
          <Grid.Col span={3}>
            <Stack pl="xs" style={{ width: "100%" }}>
              <Stack gap={5}>
                <Group justify="space-between">
                  <InputLabel>{t("Context")}</InputLabel>
                </Group>
                <ScrollArea.Autosize mah="39svh" style={{ width: '100%', height: '39svh' }}>
                  <Stack gap={5}>
                    <TextInput style={{ flex: 1 }} label={t("ConversationId")}
                      value={debugJinjaState.conversationContext.conversationId.value}
                      onChange={(event) => debugJinjaState.conversationContext.conversationId.set(event.target.value)} />
                    <IdentitySelector creatable clearable style={{ flex: 1 }} width="100%"
                      label={t("Identity") as string}
                      value={debugJinjaState.conversationContext.identity.name.value}
                      onChange={(value) => debugJinjaState.conversationContext.identity.name.set(value)} />
                    <LanguageSeletor allowDeselect={false}
                      label={t("Language") as string}
                      width="100%"
                      value={debugJinjaState.conversationContext.language.value}
                      onChange={(value) => debugJinjaState.conversationContext.language.set(value as string)}
                    />
                    <TextInput style={{ flex: 1 }}
                      label={t("Container") as string}
                      value={debugJinjaState.conversationContext.container.value?.[0] ?? ""}
                      onChange={(event) => debugJinjaState.conversationContext.container.set([event.target.value])} />
                  </Stack>
                </ScrollArea.Autosize>
              </Stack>

              <Stack gap={5}>
                <Group>
                  <InputLabel>{t("Entities")}</InputLabel>
                  <Tooltip withinPortal label={t("Add entity")}>
                    <ActionIcon onClick={onAddEntity} variant='subtle' color="gray">
                      <IconPlus size={16} />
                    </ActionIcon>
                  </Tooltip>
                </Group>
                <ScrollArea.Autosize mah="39svh" offsetScrollbars style={{ width: '100%', height: '39svh' }}>
                  <Stack gap={10}>
                    {entitiesState.map((entity, index) =>
                      <Group key={index} align="center">
                        <TextInput
                          required
                          style={{ flex: 1 }}
                          value={entity.key.value}
                          placeholder={t("Entity name") as string}
                          onChange={(event) => entity.key.set(event.target.value)}
                        />
                        :
                        <TextInput
                          required
                          style={{ flex: 1 }}
                          value={entity.value.value}
                          placeholder={t("Entity value") as string}
                          onChange={(event) => { entity.merge(i => ({ value: event.currentTarget.value })); setIsDirty(true); }}
                        />
                        <ActionIcon onClick={() => entity.set(none)} variant='subtle' color="gray">
                          <IconTrash size={16} />
                        </ActionIcon>
                      </Group>
                    )}
                  </Stack>
                </ScrollArea.Autosize>
              </Stack>
            </Stack>
          </Grid.Col>
        </Grid>

        <Group justify="flex-end">
          <Button onClick={onSaveDebugJinja}>{t("Save")}</Button>
        </Group>
      </Stack>
    </Drawer>
  );
};

export default SkillDebugJinjaModal;
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Grid, Card, Group, Button, Text, Alert, LoadingOverlay, Container, Tabs, TextInput, Textarea, Box } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import { container } from 'src/inversify.config';
import { formatMessage } from 'src/core/utils/object';
import MembersCard from 'src/components/members-card';
import TagsCard from 'src/components/tags-card';
import { useHookstate } from '@hookstate/core';
import { dispatch } from 'use-bus';
import { tagsToArray, tagsToDict } from 'src/utils/tags-utils';
import { CollectionItem, CollectionItemStore } from 'src/stores/collections';
import { TagItem } from 'src/stores/skills';
import { UserCanContribute } from 'src/utils/permissions';
import { MemberEditorState, MemberItem } from 'src/stores/identities';
import CollectionCrawlersCard from './collection-crawlers';
import AppContext from 'src/services/app-context';
import CollectionEntitiesCard from './collection-entites';
import CollectionDocuments from './collection-documents';
import { IconBook2, IconAlertCircle, IconHome2, IconTags, IconFileStack, IconArrowBackUp, IconDeviceFloppy } from '@tabler/icons-react';
import ContentWrapper from 'src/components/content-wrapper';
import { authStatus } from 'src/core/services/authentication-service';
import classes from 'src/pages/index.module.css';

const CollectionEdit: React.FC = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { collectionId } = useParams();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const store = useMemo(() => container.get(CollectionItemStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const tagsScopedState = useHookstate([] as TagItem[]);
  const [activeTab, setActiveTab] = useState('home');
  const isOwner = authStatus?.user.value?.isOwner;
  const canContribute = isOwner || UserCanContribute(state?.item?.members?.value as MemberItem[]);
  const { setIsDirty } = useContext(AppContext);
  const username = authStatus?.user.value?.sub;
  const [isPersonal, setIsPersonal] = useState<boolean>(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(t('Edit collection'), 'Collections', <IconBook2 size={34} />, `${t('Edit collection')} | ${t("Collections")}`, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/collections`, breadcrumbName: t('Collections') },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);
  
  useEffect(() => {
    if (collectionId) {
      load(collectionId);
    }
  }, [collectionId]);

  const load = async (collectionId: string) => {
    if (collectionId) {
      await store.load(collectionId);
    }
  };

  useEffect(() => {
    if (state.item.value) {
      setHeader(`${state.item.title.value} (${t("Edit mode")})`, 'Collections', <IconBook2 size={34} />, `${state.item.title.value} | ${t("Collections")}`, true, true);
      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      setIsPersonal(username === state.item.title.value);
      const copy = JSON.parse(JSON.stringify(state.item.value)) as MemberEditorState;
      membersState.set({
        accessMode: copy.accessMode,
        members: copy.members
      });
    }
  }, [state.item])

  const onSubmit = async () => {
    const copy = JSON.parse(JSON.stringify(membersState.value)) as MemberEditorState;
    const snapshotItem = {
      id: collectionId,
      title: state.item.title.value,
      description: state.item.description.value,
      accessMode: copy.accessMode,
      members: copy.members,
      tags: tagsToDict(tagsScopedState.value as TagItem[])
    } as CollectionItem;

    setIsDirty(false);

    let response = await store.save(collectionId as string, snapshotItem);
    if (response) {
      navigate(`/admin/collections/${collectionId}`);
      dispatch('@@ui/COLLECTION_LIST_REFRESH');
    }
  }

  const onCancel = () => {
    if (collectionId) {
      navigate(`/admin/collections/${collectionId}`);
    }
    else {
      navigate(`/admin/collections`);
    }
  }

  return (
    <ContentWrapper>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        <form>
          {errorMessage && (
            <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
              <Text>{formatMessage(errorMessage)}</Text>
            </Alert>
          )}

          {state?.item?.value &&
            <>
              <Grid align="stretch">
                <Grid.Col span="auto">
                  <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
                    <Tabs.List>
                      <Tabs.Tab value="home" leftSection={<IconHome2 size={20} />}>{t('Home')}</Tabs.Tab>
                      <Tabs.Tab value="entities" leftSection={<IconTags size={20} />}>{t('Entities')}</Tabs.Tab>
                      <Tabs.Tab value="crawlers" leftSection={<IconFileStack size={20} />}>{t('Crawlers')}</Tabs.Tab>
                    </Tabs.List>
                  </Tabs>
                </Grid.Col>
                <Grid.Col span="content">
                  <Group align="center" justify='flex-end' wrap='nowrap'>
                    <Button
                      variant='outline'
                      onClick={onCancel}
                      leftSection={<IconArrowBackUp />}>
                      {t("Cancel")}
                    </Button>
                    <Button
                      loading={isBusy}
                      onClick={onSubmit}
                      leftSection={<IconDeviceFloppy />}>
                      {t("Save")}
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
              <Container fluid px={0} py={0} mt="xs">
                {activeTab === "home" && (
                  <Grid gutter={'xs'}>
                    <Grid.Col span={{ base: 12, lg: 8 }}>
                      <Stack gap="xs">
                        <Card withBorder>
                          {collectionId &&
                            <CollectionDocuments
                              key={`collection-documents-${collectionId}`}
                              canContribute={canContribute}
                              collectionId={collectionId as string}
                            />
                          }
                        </Card>
                      </Stack>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <Stack gap="xs">
                        <Card withBorder>
                          <Stack gap="xs">
                            <TextInput
                              required
                              label={t('Title')}
                              description={t("Provide a full name for your collection to help search and understand its purpose.")}
                              value={state.item.title.value}
                              onChange={(event) => { state.item.title.set(event.target.value); setIsDirty(true); }}
                            />

                            <Textarea
                              label={t('Description')}
                              autosize
                              minRows={3}
                              maxRows={5}
                              value={state.item.description.value}
                              onChange={(event) => { state.item.description.set(event.currentTarget.value); setIsDirty(true); }}
                            />
                          </Stack>
                        </Card>

                        <MembersCard
                          cardKey='collection-members-card-edit'
                          mode={isPersonal ? "view" : "edit"}
                          state={membersState}
                        />

                        <TagsCard
                          cardKey='collection-tags-card-edit'
                          mode="edit"
                          state={tagsScopedState}
                        />
                      </Stack>
                    </Grid.Col>
                  </Grid>
                )}
                {activeTab === "entities" && (
                  <Box pt={5}>
                    <CollectionEntitiesCard
                      canContribute={canContribute}
                      collectionId={collectionId as string}
                      edit={canContribute}
                    />
                  </Box>
                )}
                {activeTab === "crawlers" && (
                  <Box pt={5}>
                    <CollectionCrawlersCard
                      canContribute={canContribute}
                      collectionId={collectionId as string}
                      edit={canContribute} />
                  </Box>
                )}
              </Container>
            </>
          }
        </form>
      </div>
    </ContentWrapper>
  );
};

export default CollectionEdit;

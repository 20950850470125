import { hookstate } from "@hookstate/core";
import { AsEnumerable } from "linq-es5";
import { getLS } from "src/core/utils/local-storage";
import { hashCode } from "src/core/utils/object";
import { BotBasicInfoItem, BotContentUserPreferences, UserPreferences } from "src/stores/bots";
import { ChatRequest, ChatResponse } from "src/stores/chat";

export const isLastEventInGroup = (messages: (ChatRequest | ChatResponse)[], index: number) => {
  try {
    const msg = messages[index];
    const notLastEvent = msg.role === 'Event' && AsEnumerable(messages).Any(o => o.role === 'Event' && o.groupId === msg.groupId &&
      (new Date(o.timestamp).getTime() > new Date(msg.timestamp).getTime() ||
        (new Date(o.timestamp).getTime() === new Date(msg.timestamp).getTime() && (o.id ? parseInt(o.id) : 0) > (msg.id ? parseInt(msg.id) : 0))
      )
    );
    return !notLastEvent;
  }
  catch {
    return false;
  }
}

export const isLastEvent = (messages: (ChatRequest | ChatResponse)[], index: number) => {
  try {
    let lastEventIndex = (messages as any).findLastIndex((m: any) => m.role === 'Event');
    return lastEventIndex === index;
  } catch {
    return false;
  }
}

export const isInitialEvent = (messages: (ChatRequest | ChatResponse)[], index: number) => {
  try {
    let previousMessages = messages.slice(0, index);
    return messages[index].role === 'Event' && previousMessages.filter(x => x.role !== 'Event' && x.role !== 'System').length === 0;
  } catch {
    return false;
  }
}

// Global state
export const chatPreferencesGlobalState = hookstate({} as UserPreferences);

export const setChatPreferences = (botInfo: BotBasicInfoItem) => {
  let userPreferences = {} as UserPreferences;

  if (botInfo) {
    const botUserPreferences = JSON.parse(botInfo.userPreferences) as BotContentUserPreferences;
    const hash = hashCode(botInfo.userPreferences).toString();

    userPreferences = {
      allowInternetAccess: botUserPreferences.allowInternetAccess,
      searchLanguages: botUserPreferences.searchLanguages,
      searchSkills: botUserPreferences.searchSkills,
      temperature: botUserPreferences.temperature,
      enableOCRInDocuments: botUserPreferences.enableOCRInDocuments,
      hash: hash
    } as UserPreferences;

    const lsValue = getLS(`chat-component-user-preferences-${botInfo.id}`);

    if (lsValue) {
      const localValues = JSON.parse(lsValue) as UserPreferences;
      if (localValues.hash && localValues.hash === hash) {
        userPreferences = localValues;
      }
    }
  }

  chatPreferencesGlobalState.set(userPreferences);
}

export const setChatPreferencesAsDefault = (botInfo: BotBasicInfoItem) => {
  let userPreferences = {} as UserPreferences;

  if (botInfo) {
    const botUserPreferences = JSON.parse(botInfo.userPreferences) as BotContentUserPreferences;
    const hash = hashCode(botInfo.userPreferences).toString();

    userPreferences = {
      allowInternetAccess: botUserPreferences.allowInternetAccess,
      searchLanguages: botUserPreferences.searchLanguages,
      searchSkills: botUserPreferences.searchSkills,
      temperature: botUserPreferences.temperature,
      enableOCRInDocuments: botUserPreferences.enableOCRInDocuments,
      hash: hash
    } as UserPreferences;
  }

  chatPreferencesGlobalState.set(userPreferences);
}
// Global state
export const preferencesModifiedByUserState = hookstate(false);

export const arePreferencesModifiedByUser = (botInfo: BotBasicInfoItem, localValues?: UserPreferences) => {
  let withChanges = false;
  if (botInfo) {
    if (localValues) {
      //Solo comparamos: searchLanguages, searchSkills y temperature porque el boton de acceso a internet esta separado
      localValues.hash = '';
      localValues.allowInternetAccess = false;
      localValues.searchLanguages = localValues.searchLanguages.slice().sort();
      localValues.searchSkills = localValues.searchSkills.slice().sort();

      const botUserPreferences = JSON.parse(botInfo.userPreferences) as BotContentUserPreferences;

      let userPreferences = {
        allowInternetAccess: false,
        searchLanguages: botUserPreferences.searchLanguages.slice().sort(),
        searchSkills: botUserPreferences.searchSkills.slice().sort(),
        temperature: botUserPreferences.temperature,
        enableOCRInDocuments: botUserPreferences.enableOCRInDocuments,
        hash: ''
      } as UserPreferences;

      withChanges = JSON.stringify(userPreferences) !== JSON.stringify(localValues);
    }
  }

  preferencesModifiedByUserState.set(withChanges);
  return withChanges;
}
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { SystemMetricsStore } from 'src/stores/metrics';
import { Card, Group, LoadingOverlay, SimpleGrid, Stack, TextInput, useMantineColorScheme, useMantineTheme, Tooltip, ActionIcon } from '@mantine/core';
import { formatBytes, formatNumber } from 'src/core/utils/object';
import { Bar, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip as RechartTooltip, BarChart } from 'recharts';
import moment from 'moment';
import TimeRangeSelector, { PredefinedTimeRanges } from 'src/components/time-range-selector';
import { useHookstate } from '@hookstate/core';
import { IconRefresh } from '@tabler/icons-react';

const MetricsConfig: FC<{}> = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const operationsStore = useMemo(() => container.get(SystemMetricsStore), []);
  const operationsState = operationsStore.state;
  const initialState = {
    timeRange: "1m" as PredefinedTimeRanges,
    customTimeRange: [] as (Date | undefined)[]
  };
  const scopedState = useHookstate(initialState);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = async () => {
    //from: scopedState.customTimeRange?.value?.[0], to: scopedState.customTimeRange?.value?.[1]
    await operationsStore.load("operations");
  };

  const onChangeTimeRange = (value: PredefinedTimeRanges, from?: Date | undefined, to?: Date | undefined) => {
    scopedState.set({
      timeRange: value,
      customTimeRange: [from, to]
    });
  }

  return (
    <Card withBorder mih={200}>
      <LoadingOverlay visible={operationsState.isBusy.value} />
      {operationsState.item && operationsState.item.value &&
        <Stack gap="md">
          <Group>
            <Tooltip label={t("Reload data")}>
              <ActionIcon onClick={load} variant='subtle' color="gray">
                <IconRefresh />
              </ActionIcon>
            </Tooltip>
            <TimeRangeSelector
              value={scopedState.timeRange.value}
              customValue={scopedState.customTimeRange.value as Date[]}
              onChange={onChangeTimeRange}
              maxDays={100}
            />
          </Group>

          <SimpleGrid cols={3}>
            <ResponsiveContainer width={'100%'} height={150} className={`recharts-${colorScheme}`}>
              <BarChart
                data={operationsState.item.queries.value.map(o => ({ key: `${o.day}`, value: o.value }))}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="key" tickFormatter={value => moment(value).format("ll")} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]}>
                </XAxis>
                <YAxis domain={[0, 1]} tickFormatter={value => formatNumber(value)} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]} />
                <RechartTooltip labelFormatter={(value: any) => moment(value).format('ll')} formatter={(value: any) => formatNumber(value)} wrapperClassName={colorScheme === 'dark' ? 'dark' : ''} />
                <Legend verticalAlign="top" height={36} color={colorScheme === 'dark' ? 'white' : 'gray'} />
                <Bar dataKey="value" name={t("User queries") as string} fill={theme.colors[theme.primaryColor][6]} />
              </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer width={'100%'} height={150} className={`recharts-${colorScheme}`}>
              <BarChart
                data={operationsState.item.operations.value.map(o => ({ key: `${o.day}`, value: o.value }))}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="key" tickFormatter={value => moment(value).format("ll")} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]}>
                </XAxis>
                <YAxis domain={[0, 1]} tickFormatter={value => formatNumber(value)} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]} />
                <RechartTooltip labelFormatter={(value: any) => moment(value).format('ll')} formatter={(value: any) => formatNumber(value)} wrapperClassName={colorScheme === 'dark' ? 'dark' : ''} />
                <Legend verticalAlign="top" height={36} color={colorScheme === 'dark' ? 'white' : 'gray'} />
                <Bar dataKey="value" name={t("AI Operations") as string} fill={theme.colors[theme.primaryColor][6]} />
              </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer width={'100%'} height={150} className={`recharts-${colorScheme}`}>
              <BarChart
                data={operationsState.item.llmTokens.value.map(o => ({ key: `${o.day}`, value: o.value }))}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="key" tickFormatter={value => moment(value).format("ll")} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]}>
                </XAxis>
                <YAxis domain={[0, 1]} tickFormatter={value => formatNumber(value)} stroke={theme.colors.gray[colorScheme === 'dark' ? 4 : 7]} />
                <RechartTooltip labelFormatter={(value: any) => moment(value).format('ll')} formatter={(value: any) => formatNumber(value)} wrapperClassName={colorScheme === 'dark' ? 'dark' : ''} />
                <Legend verticalAlign="top" height={36} color={colorScheme === 'dark' ? 'white' : 'gray'} />
                <Bar dataKey="value" name={t("LLM tokens") as string} fill={theme.colors[theme.primaryColor][6]} />
              </BarChart>
            </ResponsiveContainer>
          </SimpleGrid>

          <Group align='flex-start' grow>
            <TextInput
              label={t("Documents")}
              value={formatNumber(operationsState.item.documents.value)}
              width='100%'
              readOnly={true}
            />
            <TextInput
              label={t("Chunks")}
              value={formatNumber(operationsState.item.fragments.value)}
              width='100%'
              readOnly={true}
            />
            <TextInput
              label={t("Documents storage size")}
              value={formatBytes(operationsState.item.documentsSize.value)}
              width='100%'
              readOnly={true}
            />
            <TextInput
              label={t("Database size")}
              value={formatBytes(operationsState.item.databaseSize.value)}
              width='100%'
              readOnly={true}
            />
            <TextInput
              label={t("Total storage space used")}
              value={formatBytes(operationsState.item.databaseSize.value + operationsState.item.documentsSize.value)}
              width='100%'
              readOnly={true}
            />
          </Group>
        </Stack>}
    </Card>
  );
};

export default MetricsConfig;
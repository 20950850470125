import { Group, Stack, Tabs } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react'; import { useTranslation } from 'react-i18next';
import HeaderContext from 'src/services/header-context';
import IdentitiesList from './identities/identities-list';
import EntitiesList from './entities/entities-list';
import JobList from './jobs/jobs-list';
import ProfileConfig from './profile/profile-config';
import MetricsConfig from './metrics/metrics-config';
import TenantQuotas from './metrics/tenant-quotas';
import GlobalConfig from './global/global-config';
import { IconSettings, IconUsers, IconTags, IconHeartRateMonitor, IconAdjustments, IconUserCircle, IconDeviceDesktopAnalytics } from '@tabler/icons-react';
import ContentWrapper from 'src/components/content-wrapper';
import classes from 'src/pages/index.module.css';

const SettingsPage: FC = () => {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const [activeTab, setActiveTab] = useState("global");

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(t(`Settings`), 'Settings', <IconSettings size={34} />, t("Settings") as string);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/settings`, breadcrumbName: t('Settings') },
    ]);
  }, []);

  return (
    <ContentWrapper>
      <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
        <Tabs.List>
          <Tabs.Tab value="global" leftSection={<IconAdjustments size={20} />}>{t('Global options')}</Tabs.Tab>
          <Tabs.Tab value="identities" leftSection={<IconUsers size={20} />}>{t('Identities')}</Tabs.Tab>
          <Tabs.Tab value="entities" leftSection={<IconTags size={20} />}>{t('Entities')}</Tabs.Tab>
          <Tabs.Tab value="jobs" leftSection={<IconHeartRateMonitor size={20} />}>{t('Jobs')}</Tabs.Tab>
          <Tabs.Tab value="profile" leftSection={<IconUserCircle size={20} />}>{t('User profile')}</Tabs.Tab>
          <Tabs.Tab value="metrics" leftSection={<IconDeviceDesktopAnalytics size={20} />}>{t('Metrics')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="global" pt="xs">
          {activeTab === 'global' &&
            <GlobalConfig />
          }
        </Tabs.Panel>

        <Tabs.Panel value="identities" pt="xs">
          {activeTab === 'identities' &&
            <IdentitiesList />
          }
        </Tabs.Panel>

        <Tabs.Panel value="entities" pt="xs">
          {activeTab === 'entities' &&
            <EntitiesList />
          }
        </Tabs.Panel>

        <Tabs.Panel value="jobs" pt="xs">
          {activeTab === 'jobs' &&
            <JobList />
          }
        </Tabs.Panel>

        <Tabs.Panel value="profile" pt="xs">
          {activeTab === 'profile' &&
            <ProfileConfig />
          }
        </Tabs.Panel>

        <Tabs.Panel value="metrics" pt="xs">
          {activeTab === 'metrics' &&
            <Stack>
              <TenantQuotas />
              <MetricsConfig />
            </Stack>
          }
        </Tabs.Panel>
      </Tabs>
    </ContentWrapper>
  );
}

export default SettingsPage;
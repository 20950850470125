import { Group, Stack, TextInput, Select, PasswordInput, Table, ActionIcon, Center, Input, ScrollArea, Tooltip, Text, SimpleGrid, Textarea } from '@mantine/core';
import React, { FC, useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { State, none, useHookstate } from '@hookstate/core';
import AppContext from 'src/services/app-context';
import { CrawlerRef } from './crawlers';
import { GetMicrosoft365CrawlerDefaultContent, Microsoft365CrawlerContent } from 'src/stores/crawlers';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { LanguageSeletor } from 'src/components/language-selector';
import { ServiceConnectionSelector } from 'src/components/service-selector';
import SwitchButton from 'src/components/switch-button';
import classes from 'src/pages/index.module.css';

const CrawlerWebEditLanguages: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <LanguageSeletor
      required
      readOnly={!edit}
      variant={edit ? undefined : 'unstyled'}
      multiselect
      label={label}
      value={scopedState.value as string[]}
      onChange={(value) => { scopedState.set(value as string[]); setIsDirty(true); }}
    />
  );
}

const CrawlerWebEditList: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
  placeholder?: string;
  required?: boolean;
}> = ({ state, edit, label, placeholder, required = false }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const onAddItem = () => {
    if (scopedState?.value) {
      scopedState[scopedState.length].set('');
    }
    else {
      scopedState.merge(['']);
    }
    setIsDirty(true);
  }

  return (
    <Input.Wrapper
      label={
        <Group align="center" gap={5}>
          <Text size="sm">{label}</Text>
          {required &&
            <Text c="red">*</Text>
          }
          <Tooltip withinPortal label={t("Add item")}>
            <ActionIcon onClick={onAddItem} variant='subtle' color="gray">
              <IconPlus size={14} />
            </ActionIcon>
          </Tooltip>
        </Group>}>
      <div style={{ position: 'relative' }}>
        <ScrollArea style={{ minHeight: 150, height: '25svh' }} offsetScrollbars>
          {scopedState?.value && scopedState.map((item, index) => (
            <Group key={index} mt="xs" align="center" gap={5}>
              <TextInput
                required={edit}
                readOnly={!edit}
                variant={edit ? 'default' : 'unstyled'}
                placeholder={placeholder}
                style={{ flex: 1 }}
                width="100%"
                value={item.value}
                onChange={(event) => { item.set(event.target.value); setIsDirty(true); }}
              />
              {edit &&
                <ActionIcon
                  color="red"
                  variant='subtle'
                  onClick={() => { item.set(none); setIsDirty(true); }}>
                  <IconTrash />
                </ActionIcon>
              }
            </Group>
          ))}
          {scopedState?.value?.length === 0 &&
            <Center>
              <Text c="dimmed" size="sm">{t('By default we will crawl your account. Add any additional account you want to crawl.')}</Text>
            </Center>
          }
        </ScrollArea>
      </div>
    </Input.Wrapper>
  );
}

export const Microsoft365Crawler = React.forwardRef<CrawlerRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as Microsoft365CrawlerContent);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set(GetMicrosoft365CrawlerDefaultContent());
    }
  }, [content])


  const isValid = () => {
    let valid = scopedState.languages?.value?.length > 0;

    if (scopedState.authentication.value === 'Interactive') {
      valid = valid && scopedState.service.value.length > 0;
    }

    if (scopedState.authentication.value === 'OAuth') {
      valid = valid && scopedState.tenantId?.value?.length > 0 &&
        (scopedState.clientSecret?.value?.length > 0 || (scopedState.certificate?.value?.length > 0 && scopedState.privateKey?.value?.length > 0));
    }

    return valid;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Accounts")}</Table.Td>
          <Table.Td>{scopedState.accounts.length}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Languages")}</Table.Td>
          <Table.Td>{scopedState.languages.value.toString()}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Api and authentication")}</Table.Td>
          <Table.Td>{scopedState.authentication.value === 'Interactive' ? t('Interactive') : scopedState.authentication.value === 'OAuth' ? t('OAuth (Client credentials)') : ''}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <SimpleGrid cols={2}>
      <Stack>
        <SwitchButton
          className={classes.switchButton}
          label={t("Crawl user emails (Outlook)")}
          description={t("Crawl emails from your Microsoft 365 Outlook account") as string}
          checked={scopedState.email.value}
          onChange={value => { scopedState.email.set(value); }}
          readOnly={!canContribute}>
          {scopedState.email.value ?
            <Stack gap="xs">
              <Group align="flex-start" grow>
                <Select
                  required
                  allowDeselect={false}
                  label={t("Items to crawl")}
                  data={[
                    { value: '0', label: t('Everything') as string },
                    { value: '7', label: t('Last seven days') as string },
                    { value: '90', label: t('Last three months only') as string },
                    { value: '180', label: t('Last six months only') as string },
                    { value: '365', label: t('Last twelve months only') as string },
                  ]}
                  value={`${scopedState.emailCrawlDays.value ? scopedState.emailCrawlDays.value : 0}`}
                  onChange={(value) => { scopedState.emailCrawlDays.set(parseInt(value ?? "0")); }}
                />
                <Select
                  required
                  allowDeselect={false}
                  label={t("Automatic expiration")}
                  data={[
                    { value: '0', label: t('Never') as string },
                    { value: '90', label: t('Keep last three months only') as string },
                    { value: '180', label: t('Keep last six months only') as string },
                    { value: '365', label: t('Keep last twelve months only') as string },
                  ]}
                  value={`${scopedState.emailExpirationDays.value ? scopedState.emailExpirationDays.value : 0}`}
                  onChange={(value) => { scopedState.emailExpirationDays.set(parseInt(value ?? "0")); }}
                />
              </Group>
            </Stack>
            :
            null
          }
        </SwitchButton>
        <SwitchButton
          className={classes.switchButton}
          label={t("Crawl user files (OneDrive)")}
          description={t("Crawl files from your Microsoft 365 OneDrive account") as string}
          checked={scopedState.drive.value}
          onChange={value => { scopedState.drive.set(value); }}
          readOnly={!canContribute}>
          {scopedState.drive.value ?
            <Stack gap="xs">
              <Group align="flex-start" grow>
                <Select
                  required
                  allowDeselect={false}
                  label={t("Items to crawl")}
                  data={[
                    { value: '0', label: t('Everything') as string },
                    { value: '7', label: t('Last seven days') as string },
                    { value: '90', label: t('Last three months only') as string },
                    { value: '180', label: t('Last six months only') as string },
                    { value: '365', label: t('Last twelve months only') as string },
                  ]}
                  value={`${scopedState.driveCrawlDays.value ? scopedState.driveCrawlDays.value : 0}`}
                  onChange={(value) => { scopedState.driveCrawlDays.set(parseInt(value ?? "0")); }}
                />
                <Select
                  required
                  allowDeselect={false}
                  label={t("Automatic expiration")}
                  data={[
                    { value: '0', label: t('Never') as string },
                    { value: '90', label: t('Keep last three months only') as string },
                    { value: '180', label: t('Keep last six months only') as string },
                    { value: '365', label: t('Keep last twelve months only') as string },
                  ]}
                  value={`${scopedState.driveExpirationDays.value ? scopedState.driveExpirationDays.value : 0}`}
                  onChange={(value) => { scopedState.driveExpirationDays.set(parseInt(value ?? "0")); }}
                />
              </Group>
            </Stack>
            :
            null
          }
        </SwitchButton>
        <SwitchButton
          className={classes.switchButton}
          label={t("Crawl events (Calendar)")}
          description={t("Crawl events from your Microsoft 365 Calendar account") as string}
          checked={scopedState.events.value}
          onChange={value => { scopedState.events.set(value); }}
          readOnly={!canContribute}>
          {scopedState.events.value ?
            <Stack gap="xs">
              <Group align="flex-start" grow>
                <Select
                  required
                  allowDeselect={false}
                  label={t("Items to crawl")}
                  data={[
                    { value: '0', label: t('Everything') as string },
                    { value: '7', label: t('Last seven days') as string },
                    { value: '90', label: t('Last three months only') as string },
                    { value: '180', label: t('Last six months only') as string },
                    { value: '365', label: t('Last twelve months only') as string },
                  ]}
                  value={`${scopedState.eventsCrawlDays.value ? scopedState.eventsCrawlDays.value : 0}`}
                  onChange={(value) => { scopedState.eventsCrawlDays.set(parseInt(value ?? "0")); }}
                />
                <Select
                  required
                  allowDeselect={false}
                  label={t("Automatic expiration")}
                  data={[
                    { value: '0', label: t('Never') as string },
                    { value: '90', label: t('Keep last three months only') as string },
                    { value: '180', label: t('Keep last six months only') as string },
                    { value: '365', label: t('Keep last twelve months only') as string },
                  ]}
                  value={`${scopedState.eventsExpirationDays.value ? scopedState.eventsExpirationDays.value : 0}`}
                  onChange={(value) => { scopedState.eventsExpirationDays.set(parseInt(value ?? "0")); }}
                />
              </Group>
            </Stack>
            :
            null
          }
        </SwitchButton>
      </Stack>
      <Stack>
        <CrawlerWebEditLanguages
          label={t('Languages')}
          state={scopedState.languages}
          edit={canContribute}
          key={'web-languages'}
        />
        <Select
          required
          allowDeselect={false}
          label={t("Authentication")}
          placeholder={t("Authentication") as string}
          data={[
            { value: 'Interactive', label: t('Interactive') as string },
            { value: 'OAuth', label: t('OAuth (Client credentials)') as string },
          ]}
          value={scopedState.authentication.value}
          onChange={(value) => { scopedState.authentication.set(value as any); }}
        />

        {scopedState.authentication.value && scopedState.authentication.value === 'Interactive' &&
          <ServiceConnectionSelector
            required
            width="100%"
            label={t("Identity provider") as string}
            categoryFilter='Identities'
            value={scopedState.service.value}
            onChange={(value) => scopedState.service.set(value as string)}
          />
        }

        {scopedState.authentication.value && scopedState.authentication.value === 'OAuth' && <Stack justify="flex-start" gap={5}>
          <TextInput
            data-autofocus
            style={{ flex: 1 }}
            label={t('Client Id')}
            required
            value={scopedState.clientId.value}
            onChange={(event) => { scopedState.clientId.set(event.target.value); }}
          />
          <PasswordInput
            style={{ flex: 1 }}
            label={t('Client secret')}
            value={scopedState.clientSecret.value}
            onChange={(event) => { scopedState.clientSecret.set(event.target.value); }}
          />
        </Stack>}

        {scopedState.authentication.value && scopedState.authentication.value === 'OAuth' &&
          <TextInput
            data-autofocus
            style={{ flex: 1 }}
            label={t('Tenant Id')}
            required
            value={scopedState.tenantId.value}
            onChange={(event) => { scopedState.tenantId.set(event.target.value); }}
          />
        }

        {scopedState.authentication.value && scopedState.authentication.value === 'OAuth' &&
          <>
            <Textarea
              style={{ flex: 1 }}
              label={t('Certificate (PEM)')}
              value={scopedState.certificate.value}
              rows={6}
              placeholder='-----BEGIN CERTIFICATE----- ... -----END CERTIFICATE-----'
              onChange={(event) => { scopedState.certificate.set(event.target.value); }}
            />

            <Textarea
              style={{ flex: 1 }}
              label={t('Private key (PEM)')}
              value={scopedState.privateKey.value}
              rows={6}
              placeholder='-----BEGIN RSA PRIVATE KEY----- ... -----END RSA PRIVATE KEY-----'
              onChange={(event) => { scopedState.privateKey.set(event.target.value); }}
            />
          </>
        }
        <CrawlerWebEditList
          key={'web-targetsUris'}
          label={t("Additional accounts to crawl")}
          state={scopedState.accounts}
          placeholder={t('Url') as string}
          edit={canContribute}
        />
      </Stack>
    </SimpleGrid>
  );
});

export default Microsoft365Crawler;

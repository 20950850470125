import { FC } from 'react'; import {
  Routes,
  Route
} from "react-router-dom";
import SkillLanding from './skill-landing';
import { useLocation } from "react-router";
import SkillDetail from './skill-detail';
import SkillEdit from './skill-edit';

const SkillsIndex: FC = () => {
  const location = useLocation();
  return <Routes>
    <Route path=":skillId/edit" element={<SkillEdit />} key={location.pathname} />
    <Route path=":skillId" element={<SkillDetail />} key={location.pathname} />
    <Route index element={<SkillLanding />} />
  </Routes>
}

export default SkillsIndex;

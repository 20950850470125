import FormStore from "src/core/stores/form-store";
import { ContentKeyValue, IChatStore, MessageUpdateItem, ResponseItem } from "./skills";
import { PersonalCollectionLevel, SharingAccessMode } from "src/core/services/authentication-service";

export interface ConfigOption {

}

export interface ProfileConfigOption extends ConfigOption {
  defaultAudience: string;
  includeGroups: boolean;
  uri: string;
  method: 'GET' | 'POST';
  headers: ContentKeyValue[];
  transformer: string;
}

export interface GlobalConfigOptions extends ConfigOption {
  theme: string;
  brandName: string;
  cors: {
    origins: string;
  },
  allowIpRanges: string;
  allowPersonalCollection: boolean;
  fullTextIndex: boolean;
  userAutoEnroll: boolean;
  usernameRegex: string;
  allowedLanguages: string[];
  sharingAccessMode: SharingAccessMode;
  personalCollectionLevel: PersonalCollectionLevel;
  favicon: string;
  startBackground: string;
}

export class ConfigStore extends FormStore<ConfigOption, ConfigOption> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/options`);
  }
}

export class AskProfileStore extends FormStore<ResponseItem, ResponseItem> implements IChatStore {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/profile`);
  }

  public async ask(documentId: string, snapshotId: string, conversationId: string, body: any) {
    return await this.handleCallAsync(async () => {
      let response = await this.httpService.post(`${this.baseUrl}/ask`, body);
      return response.data;
    });
  }

  public async *ask_streamed(skillId: string, snapshotId: string | undefined, conversationId: string, body: any, signal: AbortSignal): AsyncGenerator<MessageUpdateItem> {
    this._state.set((s) => {
      s.errorMessage = undefined;
      s.isBusy = true;
      return s;
    });
    try {
      for await (const item of this.httpService.post_streamed<any, MessageUpdateItem>(`${this.baseUrl}/ask/streamed?conversationId=${conversationId}`, body, signal)) {
        yield item;
      }
    } finally {
      this._state.set((s) => {
        s.isBusy = false;
        return s;
      });
    }
  }

  public async saveConversation(skillId: string, conversationId: string) {
    throw new Error("Not implemented");
  }

  public async generateConversationInfo(skillId: string, conversationId: string) {
    throw new Error("Not implemented");
  }
}
import ReactDOM from 'react-dom/client';
import 'reflect-metadata';
import { AppConfig, initialize as initializeInversify } from './inversify.config';
import { initializeAuthentication } from 'src/core/services/authentication-service';
import i18n from './i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import '@mantine/core/styles.css';
import '@mantine/code-highlight/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import 'mantine-datatable/styles.css';
import '@mantine/dates/styles.css';
import 'reactflow/dist/style.css';
import './assets/theme.less';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/it';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import App from './pages';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import { loader } from '@monaco-editor/react';

export let globalPallettes = {} as any;
globalPallettes["green"] = ['#F4FBEA', '#E0F4C3', '#CCEC9D', '#B8E576', '#A5DD4F', '#91D629', '#74AB21', '#578019', '#3A5610', '#1D2B08'];
globalPallettes["deep_blue"] = ['#E6E5FF', '#B8B8FF', '#8A8AFF', '#5D5CFF', '#2F2EFF', '#0200FF', '#0100CC', '#010099', '#010066', '#000033'];
globalPallettes["light_blue"] = ['#E6F4FE', '#BAE1FD', '#8ECFFB', '#61BCFA', '#35A9F8', '#0996F6', '#0778C5', '#055A94', '#033C63', '#021E31'];
globalPallettes["default"] = ['#E7F5FF', '#D0EBFF', '#A5D8FF', '#74C0FC', '#4DABF7', '#339AF0', '#228BE6', '#1C7ED6', '#1971C2', '#1864AB'];

loader.config({ paths: { vs: `${process.env.PUBLIC_URL}/monaco/min/vs` } });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

fetch(`${process.env.PUBLIC_URL}/api/config`)
  .then((response) => response.json())
  .then((config) => {
    initializeInversify(config);
    initializeAuthentication();
    const urlParts = new URL(config.serviceUrl).pathname.split('/');

    root.render(
      <AppConfig.Provider value={config}>
        <I18nextProvider i18n={i18n}>
          <Router basename={`/${urlParts[urlParts.length - 1]}`}>
            <App />
          </Router>
        </I18nextProvider>
      </AppConfig.Provider>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

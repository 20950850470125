import { Group, Stack, TextInput, PasswordInput, Table, ActionIcon, Center, Input, ScrollArea, Tooltip, Text, SimpleGrid, ComboboxItem, Tabs, useMantineColorScheme } from '@mantine/core';
import React, { FC, useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { State, none, useHookstate } from '@hookstate/core';
import AppContext from 'src/services/app-context';
import { CrawlerRef } from './crawlers';
import { GetWebCrawlerDefaultContent, WebCrawlerContent } from 'src/stores/crawlers';
import { IconCode, IconList, IconPlus, IconTrash, IconWorldWww } from '@tabler/icons-react';
import { LanguageSeletor } from 'src/components/language-selector';
import { Editor } from '@monaco-editor/react';
import { BasicMultiselect } from 'src/components/basic-multiselect';
import { ContentKeyValue } from 'src/stores/skills';
import { monacoOptions } from 'src/configurations/editor-config-jinja';

const CrawlerWebEditInput: FC<{
  state: State<string>;
  edit?: boolean;
  label: string;
  required?: boolean;
}> = ({ state, edit, label, required = false }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <TextInput
      required={required}
      readOnly={!edit}
      variant={edit ? 'default' : 'unstyled'}
      label={label}
      value={scopedState.value}
      onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
    />
  );
}

const CrawlerWebEditPass: FC<{
  state: State<string>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <PasswordInput
      readOnly={!edit}
      variant={edit ? 'default' : 'unstyled'}
      label={label}
      value={scopedState.value}
      onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
    />
  );
}

const CrawlerWebEditLanguages: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <LanguageSeletor
      required
      readOnly={!edit}
      variant={edit ? undefined : 'unstyled'}
      multiselect
      label={label}
      value={scopedState.value as string[]}
      onChange={(value) => { scopedState.set(value as string[]); setIsDirty(true); }}
    />
  );
}

const CrawlerWebEditAudiences: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const getAudiencesData = () => {
    let data = [] as ComboboxItem[];
    if (scopedState.value) {
      data = scopedState.value.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  return (
    <BasicMultiselect
      label={label}
      data={getAudiencesData()}
      value={scopedState.value as string[]}
      onChange={(value) => { scopedState.set(value); setIsDirty(true); }}
      placeholder={t("Select audiences") as string}
      variant={edit ? undefined : 'unstyled'}
      creatable
    />
  );
}

const CrawlerWebEditList: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
  placeholder?: string;
  required?: boolean;
}> = ({ state, edit, label, placeholder, required = false }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const onAddItem = () => {
    if (scopedState?.value) {
      scopedState[scopedState.length].set('');
    }
    else {
      scopedState.merge(['']);
    }
    setIsDirty(true);
  }

  return (
    <Input.Wrapper
      label={
        <Group align="center" gap={5}>
          <Text size="sm">{label}</Text>
          {required &&
            <Text c="red">*</Text>
          }
          <Tooltip withinPortal label={t("Add item")}>
            <ActionIcon onClick={onAddItem} variant='subtle' color="gray">
              <IconPlus size={14} />
            </ActionIcon>
          </Tooltip>
        </Group>}>
      <div style={{ position: 'relative' }}>
        <ScrollArea style={{ minHeight: 150, height: '50svh' }} offsetScrollbars>
          {scopedState?.value && scopedState.map((item, index) => (
            <Group key={index} mt="xs" align="center" gap={5}>
              <TextInput
                required={edit}
                readOnly={!edit}
                variant={edit ? 'default' : 'unstyled'}
                placeholder={placeholder}
                style={{ flex: 1 }}
                width="100%"
                value={item.value}
                onChange={(event) => { item.set(event.target.value); setIsDirty(true); }}
              />
              {edit &&
                <ActionIcon
                  color="red"
                  variant='subtle'
                  onClick={() => { item.set(none); setIsDirty(true); }}>
                  <IconTrash />
                </ActionIcon>
              }
            </Group>
          ))}
          {scopedState?.value?.length === 0 &&
            <Center>
              <Text c="dimmed" size="sm">{t('No items')}</Text>
            </Center>
          }
        </ScrollArea>
      </div>
    </Input.Wrapper>
  );
}

const CrawlerWebEditHeaders: FC<{
  state: State<ContentKeyValue[]>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const onAddHeader = () => {
    if (scopedState.value) {
      scopedState[scopedState.length].set({ key: '', value: '' } as ContentKeyValue);
    }
    else {
      scopedState.merge([{ key: '', value: '' } as ContentKeyValue]);
    }
    setIsDirty(true);
  }

  return (
    <Input.Wrapper
      label={
        <Group align="center" gap={5}>
          <Text size="sm">{label}</Text>
          <Tooltip withinPortal label={t("Add item")}>
            <ActionIcon onClick={onAddHeader} variant='subtle' color="gray">
              <IconPlus size={14} />
            </ActionIcon>
          </Tooltip>
        </Group>}>
      <div style={{ position: 'relative' }}>
        <ScrollArea style={{ minHeight: 150, height: '25svh' }} offsetScrollbars>
          {scopedState?.value && scopedState.map((header, index) =>
            <Group key={index} mt="xs" align="center" gap={5}>
              <TextInput
                required={edit}
                readOnly={!edit}
                variant={edit ? 'default' : 'unstyled'}
                style={{ flex: 1 }}
                value={header.key.value}
                placeholder={t("<your header name>") as string}
                onChange={(event) => { header.key.set(event.target.value); setIsDirty(true); }}
              />
              :
              <TextInput
                required={edit}
                readOnly={!edit}
                variant={edit ? 'default' : 'unstyled'}
                style={{ flex: 1 }}
                value={header.value.value}
                placeholder={t("<your header value>") as string}
                onChange={(event) => { header.merge(i => ({ value: event.currentTarget.value })); setIsDirty(true); }}
              />
              {edit &&
                <ActionIcon
                  variant='subtle'
                  color="red"
                  onClick={() => { header.set(none); setIsDirty(true); }}>
                  <IconTrash />
                </ActionIcon>
              }
            </Group>
          )}
          {scopedState?.value?.length === 0 &&
            <Center>
              <Text c="dimmed" size="sm">{t('No items')}</Text>
            </Center>
          }
        </ScrollArea>
      </div>
    </Input.Wrapper>
  );
}

const CrawlerWebEditCode: FC<{
  state: State<string>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const { colorScheme } = useMantineColorScheme();

  let customMonacoOptions = { ...monacoOptions };
  customMonacoOptions.readOnly = !edit;

  return (
    <Input.Wrapper
      required={edit}
      label={label}
      style={{ width: '100%', height: '54svh' }}>
      <Editor
        width="100%"
        height="100%"
        options={customMonacoOptions}
        language="json"
        value={scopedState.value}
        onChange={(value) => { scopedState.set(value as string); setIsDirty(true); }}
        theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
      />
    </Input.Wrapper>
  );
}

export const WebCrawler = React.forwardRef<CrawlerRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as WebCrawlerContent);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set(GetWebCrawlerDefaultContent());
    }
  }, [content])


  const isValid = () => {
    return scopedState.targetUris?.value.length > 0 && scopedState.code?.value.length > 0 && scopedState.languages?.value?.length > 0;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("URLs to crawl")}</Table.Td>
          <Table.Td>{scopedState.targetUris.length}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Languages")}</Table.Td>
          <Table.Td>{scopedState.languages.value.toString()}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Lists")}</Table.Td>
          <Table.Td>{t("White list")}: {scopedState.whiteList.length}, {t("Black list")}: {scopedState.blackList.length}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Audiences")}</Table.Td>
          <Table.Td>{scopedState.audiences.value.toString()}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Headers")}</Table.Td>
          <Table.Td>{scopedState.headers.length}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Tabs variant="outline" orientation="vertical" defaultValue="web">
      <Tabs.List>
        <Tabs.Tab value="web" leftSection={<IconWorldWww size={20} />}>{t("Targets")}</Tabs.Tab>
        <Tabs.Tab value="lists" leftSection={<IconList size={20} />}>{t("Lists")}</Tabs.Tab>
        <Tabs.Tab value="code" leftSection={<IconCode size={20} />}>{t("Code")}</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="web" pl="xs">
        <SimpleGrid cols={2}>
          <Stack>
            <CrawlerWebEditList
              key={'web-targetsUris'}
              label={t("URLs to crawl")}
              state={scopedState.targetUris}
              placeholder={t('Url') as string}
              edit={canContribute}
              required={canContribute}
            />
          </Stack>
          <Stack justify="flex-start" gap={5}>
            <CrawlerWebEditLanguages
              label={t('Languages')}
              state={scopedState.languages}
              edit={canContribute}
              key={'web-languages'}
            />
            <CrawlerWebEditAudiences
              label={t('Audiences')}
              state={scopedState.audiences}
              edit={canContribute}
              key={'web-audiences'}
            />
            <CrawlerWebEditInput
              label={t('Username')}
              state={scopedState.username}
              edit={canContribute}
              key={'web-username'}
            />
            <CrawlerWebEditPass
              label={t('Password')}
              state={scopedState.password}
              edit={canContribute}
              key={'web-password'}
            />
            <CrawlerWebEditHeaders
              label={t('Headers')}
              state={scopedState.headers}
              edit={canContribute}
              key={'web-headers'}
            />
          </Stack>
        </SimpleGrid>
      </Tabs.Panel>

      <Tabs.Panel value="lists" pl="xs">
        <SimpleGrid cols={2}>
          <Stack>
            <CrawlerWebEditList
              key={'web-whiteList'}
              label={t("White list")}
              state={scopedState.whiteList}
              placeholder={t('Regex') as string}
              edit={canContribute}
            />
          </Stack>
          <Stack>
            <CrawlerWebEditList
              key={'web-blackList'}
              label={t("Black list")}
              state={scopedState.blackList}
              placeholder={t('Regex') as string}
              edit={canContribute}
            />
          </Stack>
        </SimpleGrid>
      </Tabs.Panel>

      <Tabs.Panel value="code" pl="xs">
        <CrawlerWebEditCode
          key={'web-code'}
          label={t("Crawler code")}
          state={scopedState.code}
          edit={canContribute}
        />
      </Tabs.Panel>
    </Tabs>
  );
});

export default WebCrawler;

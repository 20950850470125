import DataStore, { Query, QueryResult } from "src/core/stores/data-store";
import FormStore from "src/core/stores/form-store";
import { AccessMode, MemberItem } from "./identities";
import { TagItem } from "./skills";
import { EntityType } from "./entities";

export type CollectionStatus = 'Draft' | 'WaitingForTraining' | 'Training' | 'Published' | 'Error';

export interface CollectionSummary {
  id: string;
  title: string;
  description: string;
  jobReference?: string;
  accessMode: AccessMode;
  tags: { [key: string]: any };
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export interface CollectionItem {
  id: string;
  title: string;
  description: string;
  jobReference?: string;
  accessMode: AccessMode;
  members: MemberItem[];
  tags: { [key: string]: any };
  properties: { [key: string]: any };
  createdOn?: Date;
  createdBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
  documentCount: number;
  documentsUsedSpace: number;
}

export interface NewCollectionForm {
  title: string;
  description: string;
  accessMode: AccessMode;
  members: MemberItem[];
  tags: TagItem[];
}

export interface UpsertCollectionForm {
  id: string;
  title: string;
  description: string;
  accessMode: AccessMode;
  members: MemberItem[];
  tags: TagItem[];
}

export interface CollectionEntitySummary {
  id: string;
  collectionId: string;
  name: string;
  type: EntityType;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export interface CollectionEntityItem {
  id: string;
  collectionId: string;
  name: string;
  type: EntityType;
  samples: { [key: string]: string[] };
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export interface NewColectionEntityItem {
  collectionId: string;
  name: string;
  type: EntityType;
  samples: { [key: string]: string[] };
}

export class CollectionSummaryStore extends DataStore<CollectionSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/collections`, []);
  }
}

export class CollectionItemStore extends FormStore<CollectionItem, CollectionItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/collections`);
  }

  public async train(collectionId: string, failedOnly?: boolean) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._baseUrl}/${collectionId}/train?failedOnly=${failedOnly ?? false}`, {});
    });
  }
}

export class CollectionEntitySummaryStore extends DataStore<CollectionEntitySummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollection(collectionId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/entities`;
  }
}

export class CollectionEntityItemStore extends FormStore<CollectionEntityItem, NewColectionEntityItem> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollection(collectionId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/entities`;
  }
}

export class BotCollectionSummaryStore extends DataStore<CollectionSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`, []);
  }

  public async loadCollections(id: string, query: Partial<Query>, onlyWithWritePermissions?: boolean) {
    await this.handleCallAsync(async () => {
      const response = await this.httpService.get<QueryResult<CollectionSummary>>(`${this.baseUrl}/${encodeURIComponent(id)}/collections?${DataStore.buildUrl(query as Query)}&onlyWithWritePermissions=${onlyWithWritePermissions ?? false}`);
      this._state.set((s) => {
        s.items = response.data.items || (response.data as any).value || [];
        s.count = response.data.count || (response.data as any)['@odata.count'] || 0;
        return s;
      });
    });
  }
}
import { useMemo, useEffect, useState, FC } from 'react';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { ComboboxItem, Group, Text } from '@mantine/core';
import { IconBook2 as Book2 } from '@tabler/icons-react';
import useBus from 'use-bus';
import { CollectionSummary, CollectionSummaryStore } from 'src/stores/collections';
import { GenericMultiselect } from 'src/core/ui/generic-multiselect';

export const CollectionsSelector: FC<{
  value?: string[];
  onChange?: (value: string[]) => void;
  width?: string | number;
  label?: string;
  placeholder?: string;
  clearable?: boolean;
  required?: boolean;
  creatable?: boolean;
  readOnly?: boolean;
}> = ({ value, onChange, width, label, clearable, required, creatable, readOnly, placeholder }) => {
  const store = useMemo(() => container.get(CollectionSummaryStore), []);
  const state = store.state;
  const { t } = useTranslation();

  const [query, setQuery] = useState<Query>({
    searchQuery: '',
    orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: 100,
    parameters: {
      includeIds: value
    }
  } as Query);

  useEffect(() => {
    if (value) {
      const newQuery = {
        ...query,
        skip: 0,
        parameters: {
          includeIds: value
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    load(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  const load = async (query: Query) => {
    store.load(query);
  };

  useBus(
    '@@ui/COLLECTION_LIST_REFRESH',
    () => load(query),
    [],
  );

  const renderDataItem = (item: CollectionSummary) => {
    return {
      label: item.title,
      value: item.id,
    } as any;
  }

  const renderOption = (item: ComboboxItem) => (
    <Group gap="xs" wrap='nowrap'>
      <Book2 size={20} color='gray' /><Text size='sm'>{item.label}</Text>
    </Group>
  );

  return (
    <GenericMultiselect
      label={label}
      data={store.toListState(state.value)}
      renderDataItem={(item) => renderDataItem(item)}
      value={value}
      query={query}
      onQueryChanged={setQuery}
      onChange={(value) => {
        if (onChange) {
          onChange(value);
        }
      }}
      searchable
      searchField='title'
      required={required}
      variant={!readOnly ? undefined : 'unstyled'}
      renderOption={renderOption}
      placeholder={placeholder || t('Select a collection') as string}
      readOnly={readOnly}
    />
  );
};
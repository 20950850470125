import { State, useHookstate } from "@hookstate/core";
import { Group, Stack, Loader, Text, Flex, useMantineTheme } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { FC, ReactNode } from "react";
import { truncateText } from "src/core/utils/object";
import { ChatRequest, ChatResponse } from "src/stores/chat";

const ChatMessageEvent: FC<{
  state: State<ChatRequest | ChatResponse>;
  moreOptions?: ReactNode;
  isBusy?: boolean;
  isLastEvent?: boolean;
}> = ({ state, moreOptions, isBusy, isLastEvent }) => {
  const scopedState = useHookstate(state);
  const theme = useMantineTheme();

  let timeElapsed = '';
  if (scopedState.content && scopedState.content.finished && scopedState.content.finished.value) {
    let seconds = (new Date(scopedState.content.finished.value).getTime() - new Date(scopedState.content.started.value).getTime()) / 1000;
    if (seconds < 0)
      seconds = 0;
    timeElapsed = isNaN(seconds) || seconds <= 0 ? '' : `${seconds}s`;
  }

  return (
    <Group justify="flex-start">
      <Group gap="xs">
        <Stack gap={30}>
          <Flex gap={"xs"} justify="flex-start" align="flex-start" direction="row" p={5} ml={10}>
            <Text span size="sm">{(isLastEvent && isBusy) ? <Loader color={theme.colors[theme.primaryColor][6]} size={14} /> : <IconCheck color={theme.colors[theme.primaryColor][6]} size={16} />}</Text>
            <Text span size="sm">{truncateText((scopedState as State<ChatRequest>).text.value)}</Text>
            {timeElapsed &&
              <Text span size="sm">{timeElapsed}</Text>
            }
          </Flex>
        </Stack>
        {moreOptions}
      </Group>
    </Group>
  );
}

export default ChatMessageEvent;
import { AppShell, useMantineColorScheme } from '@mantine/core';
import { FC } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuthentication } from 'src/core/services/authentication-service';
import ChatPage from './chat-page';
import classes from '../index.module.css';

const ChatIndex: FC = () => {
  const { colorScheme } = useMantineColorScheme();
  const [authStatus] = useAuthentication();

  return (
    <AppShell
      padding="md"
      styles={(theme) => ({
        root: {
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          display: 'flex',
          boxSizing: 'border-box'
        },
        main: {
          flex: '1 1 0%',
          width: '100vw',
          boxSizing: 'border-box',
          minHeight: '100dvh',
          padding: 0,
        }
      })}>
      <AppShell.Main>
        <div className={classes.body}>
          {authStatus.status.value !== 'Success' && <Navigate to={`/login?redirect_uri=${encodeURIComponent(window.location.href)}`} />}
          <Routes>
            <Route path="*" element={<ChatPage />} />
          </Routes>
        </div>
      </AppShell.Main>
    </AppShell>
  );
}

export default ChatIndex;

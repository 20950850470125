import { ActionIcon, Card, Group, Loader, Menu, Progress, Select, Stack, Text, Tooltip } from '@mantine/core';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import moment from 'moment';
import { JobSummary, JobsSummaryStore } from 'src/stores/jobs';
import { formatDuration, formatMessage } from 'src/core/utils/object';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import TableListV2, { TableListV2Model } from 'src/core/ui/table-list/table-list-v2';
import { IconQuestionMark, IconCircleX, IconCircleCheck, IconEraser, IconAdjustments, IconRefreshAlert } from '@tabler/icons-react';

const JobList: FC<{}> = () => {
  const { t } = useTranslation();
  const modals = useModals();
  const store = useMemo(() => container.get(JobsSummaryStore), []);
  const state = store.state;
  const [jobFilter, setJobFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('');
  const [query, setQuery] = useState<Query>(undefined as any);

  useEffect(() => {
    if (query) {
      load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const load = async (query: Query) => {
    store.load(query);
  };

  useEffect(() => {
    const newQuery = {
      ...query,
      orderBy: [{ field: 'ModifiedOn', direction: 'Descending', useProfile: false }],
      skip: 0,
      parameters: {
        jobFilter: jobFilter,
        statusFilter: statusFilter
      },
    } as Query;
    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobFilter, statusFilter]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await load(query)
    }, 5000);
    return () => clearInterval(interval);
  }, [jobFilter, query])

  const getPercent = (value: number) => {
    if (!value || value < 0.05)
      return 1;
    return Math.round(1000 * value) / 10;
  }

  const getProgress = (e: JobSummary) => {
    switch (e.status) {
      case 'Failed':
        return <Progress size="lg" value={100} color="red" />;
      case 'Waiting':
        return <Loader color="yellow" type="dots" />;
      case 'Running':
        return <Tooltip label={`${getPercent(e.progress)}%`}><Progress size="lg" animated value={getPercent(e.progress)} color="blue" /></Tooltip>;
      case 'Succeeded':
        return <Progress size="lg" value={getPercent(e.progress)} color="green" />;
      default:
        return <IconQuestionMark />;
    }
  }

  const getStatus = (e: JobSummary) => {
    switch (e.status) {
      case 'Failed':
        return <IconCircleX color='red' />;
      case 'Waiting':
      case 'Running':
        return <Text>{t(e.status)}</Text>
      case 'Succeeded':
        return <IconCircleCheck color='green' />;
      default:
        return <IconQuestionMark />;
    }
  }

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const openDeleteModal = (days?: number, succedeedOnly?: boolean) =>
    modals.openConfirmModal({
      title: <Text>{t('Clear completed jobs')}</Text>,
      children: (
        <Text size="sm">
          {days ?
            t('Are you sure you want to delete the jobs older than?', { item: days })
            :
            t('Are you sure you want to delete all completed jobs?')
          }
        </Text>
      ),
      labels: { confirm: t('Clear jobs'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(days, succedeedOnly),
    });

  const onConfirmDelete = async (days?: number, succedeedOnly?: boolean) => {
    await store.clearCompletedJobs(days, succedeedOnly);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      store.load(query);
    }
  };

  const onJobRestart = async (jobId: number) => {
    await store.restartJob(jobId);
  }

  const model: TableListV2Model<JobSummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'taskName',
        title: t('Job name'),
        render: (item: JobSummary) => (
          <Text>{item.taskName} {item.description && item.description !== '' ? `"${item.description}"` : ''}</Text>
        )
      },
      //{
      //  accessor: 'entryPoint',
      //  title: t('Entry point'),
      //  render: (item: JobSummary) => (
      //    <Text style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{item.entryPoint}</Text>
      //  )
      //},
      {
        accessor: 'progress',
        title: t('Progress'),
        render: (item: JobSummary) => (
          getProgress(item)
        )
      },
      {
        accessor: 'status',
        title: t('Status'),
        render: (item: JobSummary) => (
          getStatus(item)
        )
      },
      {
        accessor: 'started',
        title: t('Started on'),
        render: (item: JobSummary) => (item.createdOn &&
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.createdOn).toString()}</Text>}>
            <Text>{moment(item.createdOn).format("DD-MM-YYYY hh:mm:ss")}</Text>
          </Tooltip>
        )
      },
      {
        accessor: 'finished',
        title: t('Finished on'),
        render: (item: JobSummary) => (item.modifiedOn && item.status !== 'Running' &&
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.modifiedOn).toString()}</Text>}>
            <Text>{moment(item.modifiedOn).format("DD-MM-YYYY hh:mm:ss")}</Text>
          </Tooltip>
        )
      },
      {
        accessor: 'durationInSeconds',
        title: t('Duration'),
        render: (item: JobSummary) => (
          <Text>{item.status !== 'Running' ? formatDuration(moment.duration(item.durationInSeconds, 'seconds')) : ''}</Text>
        )
      },
      {
        accessor: 'workerId',
        title: t('Worker'),
        render: (item: JobSummary) => (
          <Text>{item.workerId}</Text>
        )
      },
      {
        accessor: 'error',
        title: t('Error'),
        render: (item: JobSummary) => (
          <Group justify="space-between">
            <Text style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{item.statusMessage}</Text>
            {item.statusMessage && <ActionIcon variant="outline" aria-label="Settings" onClick={() => onJobRestart(item.id)}>
              <IconRefreshAlert style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>}
          </Group>
        )
      },
    ]
  };

  return (
    <Card withBorder>
      <Stack gap="md">
        <TableListV2
          model={model}
          onQueryChanged={setQuery}
          onRefresh={() => load(query)}
          striped
          highlightOnHover
          hideSearch
          leftToolBarRender={
            <Menu shadow="md" withinPortal withArrow position='bottom-start'>
              <Menu.Target>
                <Tooltip label={t("Clear completed jobs")}>
                  <ActionIcon variant='subtle' color="gray">
                    <IconEraser />
                  </ActionIcon>
                </Tooltip>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{t("Clear completed jobs")}</Menu.Label>
                <Menu.Item onClick={() => openDeleteModal(1)} leftSection={<IconEraser size={14} />}>{t("Keep 1 day")}</Menu.Item>
                <Menu.Item onClick={() => openDeleteModal(7)} leftSection={<IconEraser size={14} />}>{t("Keep 7 days")}</Menu.Item>
                <Menu.Item onClick={() => openDeleteModal(30)} leftSection={<IconEraser size={14} />}>{t("Keep 30 days")}</Menu.Item>
                <Menu.Item onClick={() => openDeleteModal()} leftSection={<IconEraser size={14} />}>{t("Remove all jobs")}</Menu.Item>
                <Menu.Item onClick={() => openDeleteModal(undefined, true)} leftSection={<IconEraser size={14} />}>{t("Remove successfully completed jobs")}</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          }
          rightToolBarRender={
            <Group gap="xs" align="center">
              {/* <Select
                withinPortal
                clearable
                style={{ width: 160 }}
                icon={<Adjustments size={20} />}
                placeholder={t('Status') as string}
                data={[
                  { value: 'Enqueued', label: 'Enqueued' },
                  { value: 'Waiting', label: 'Waiting' },
                  { value: 'Running', label: 'Running' },
                  { value: 'Succeed', label: 'Succeed' },
                  { value: 'Error', label: 'Error' }
                ]}
                onChange={(value) => setStatusFilter(value as string)}
                value={statusFilter}
              /> */}
              <Select
                allowDeselect={false}
                style={{ width: 240 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Jobs') as string}
                data={[
                  { value: 'active', label: t('Active') as string },
                  { value: 'queued', label: t('Queued') as string },
                  { value: 'completed', label: t('Completed') as string },
                  { value: 'all', label: t('All') as string }
                ]}
                onChange={(value) => setJobFilter(value as string)}
                value={jobFilter}
              />
            </Group>
          }
        />
      </Stack>
    </Card>
  );
};

export default JobList;
import { ActionIcon, Card, Collapse, Group, Text, Tooltip } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconChevronDown as ChevronDown, IconChevronUp as ChevronUp } from '@tabler/icons-react';

const CollapsibleCard: FC<{
  title: React.ReactNode;
  cardKey: string;
  icon?: React.ReactNode;
  children: JSX.Element;
  collapseInfoRender?: React.ReactNode;
  rightToolbar?: React.ReactNode;
  noCollapsible?: boolean;
}> = ({ title, cardKey, icon, children, collapseInfoRender, rightToolbar, noCollapsible = false }) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useLocalStorage<boolean>({ key: cardKey, defaultValue: false });

  return (
    <Card withBorder>
      <Card.Section inheritPadding py="xs">
        <Group justify="space-between">
          <Group gap="xs" wrap='nowrap'>
            {icon}
            {title}
          </Group>

          {collapse && collapseInfoRender &&
            <Group gap="xs" justify='center'>
              {collapseInfoRender}
            </Group>
          }

          <Group justify='flex-end' align="center" gap={5}>
            {!collapse && rightToolbar &&
              <Group gap="xs">
                {rightToolbar}
              </Group>
            }
            {!noCollapsible &&
              <Tooltip withinPortal label={collapse ? <Text size="sm">{t("Expand")}</Text> : <Text size="sm">{t("Collapse")}</Text>}>
                <ActionIcon variant='subtle' color="gray" onClick={() => setCollapse((prev) => !prev)}>
                  {collapse ? <ChevronDown /> : <ChevronUp />}
                </ActionIcon>
              </Tooltip>
            }
          </Group>
        </Group>
      </Card.Section>

      <Collapse in={!collapse}>
        {children}
      </Collapse>
    </Card>
  );
};

export default CollapsibleCard;

import { State, none, useHookstate } from "@hookstate/core";
import { ActionIcon, Group, Input, Stack, Text, Textarea, Tooltip } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import { SkillContentStep } from "src/stores/skills";
import SkillContentStepWrapper from "./skill-content-step-wrapper";

const SkillContentStepEvent: FC<{
  state: State<SkillContentStep>;
  edit?: boolean;
  index: number;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ state, edit, index, leftToolbar, rightToolbar }) => {
  const scopedState = useHookstate(state.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0])[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  }, [scopedState.text])

  const onAddText = () => {
    const defaultLang = allowedLanguages[0];
    let defaultText: { [key: string]: string } = {};
    defaultText[defaultLang] = t("<your event>", { lng: defaultLang });

    scopedState.text[scopedState.text.length].set(defaultText);
    setIsDirty(true);
  }

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(none);
    }
    setIsDirty(true);
    if (scopedState?.value?.text?.length > 0) {
      setSelectedLanguage(Object.keys(scopedState?.text?.value?.[0])[0]);
    }
  }

  const onAddLanguage = (language: string) => {
    const defaultText = t("<your message>", { lng: language });
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(defaultText);
    }
    setIsDirty(true);
  }

  return (
    <SkillContentStepWrapper
      index={index}
      state={state}
      edit={edit}
      leftToolbar={
        <Group align="center" gap={5}>
          {leftToolbar}
          <LanguageSegmentedControl
            size="sm"
            compact
            editable={edit}
            data={scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : undefined}
            language={selectedLanguage}
            onChange={setSelectedLanguage}
            onDelete={onDeleleLanguage}
            onAdd={onAddLanguage}
          />
        </Group>
      }
      rightToolbar={rightToolbar}>
      {edit ?
        <Stack gap="xs">
          <Group align="center">
            <Input.Label w={120} required={edit}>{t("Event")}:</Input.Label>
            <Group align="center" gap={5}>
              <Text size="sm">{t("Messages")}</Text>
              <Tooltip withinPortal label={t("Add text")}>
                <ActionIcon onClick={onAddText} variant='subtle' color="gray">
                  <IconPlus size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
          {scopedState.text.map((text, index) =>
            <Group align="flex-start" key={index}>
              <Input.Label w={120}></Input.Label>
              <Textarea
                autosize
                minRows={3}
                maxRows={10}
                required
                style={{ flex: 1 }}
                value={text[selectedLanguage]?.value ?? ''}
                onChange={(event) => { text[selectedLanguage].set(event.target.value); setIsDirty(true); }}
              />
              <ActionIcon onClick={() => { text.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                <IconTrash size={16} />
              </ActionIcon>
            </Group>
          )}
        </Stack>
        :
        <Stack gap="xs">
          <div>
            <Text span fw={500}>{t("Event")} </Text>
            {scopedState.text.map((t, index) =>
              <Text key={index} span fs="italic">{t[selectedLanguage]?.value ?? ''}</Text>
            )}
          </div>
        </Stack>
      }
    </SkillContentStepWrapper>
  );
};

export default SkillContentStepEvent;

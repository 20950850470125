import { FC } from 'react'; import {
  Routes,
  Route
} from "react-router-dom";
import { useLocation } from "react-router";
import DocumentLanding from './document-landing';
import DocumentDetail from './document-detail';
import DocumentEdit from './document-edit';

const DocumentsIndex: FC = () => {
  const location = useLocation();
  return <Routes>
    <Route path=":documentId/edit" element={<DocumentEdit />} key={location.pathname} />
    <Route path=":documentId" element={<DocumentDetail />} key={location.pathname} />
    <Route index element={<DocumentLanding />} />
  </Routes>
}

export default DocumentsIndex;

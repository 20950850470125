import { FC, useEffect, useState } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import { Combobox, TextInput, useCombobox, Checkbox, ActionIcon, Group, Stack, Text } from '@mantine/core';
import classes from './transfer-list.module.css';
import { useTranslation } from 'react-i18next';
import { AsEnumerable } from 'linq-es5';

export interface TransferListItem {
  value: string;
  label: string;
  // group?: string;
  // [key: string]: any;
}

export type TransferListData = [TransferListItem[], TransferListItem[]];

interface RenderListProps {
  options: TransferListItem[];
  onTransfer(options: TransferListItem[]): void;
  type: 'forward' | 'backward';
  title: string;
  searchPlaceholder?: string;
  nothingFound?: string;
  maxHeight?: number;
}

const RenderList: FC<RenderListProps> = ({ options, onTransfer, type, title, searchPlaceholder, nothingFound, maxHeight = 400 }) => {
  const { t } = useTranslation();
  const combobox = useCombobox();
  const [value, setValue] = useState<TransferListItem[]>([]);
  const [search, setSearch] = useState('');

  const handleValueSelect = (val: string) => {
    const current = [...value];
    const item = AsEnumerable(current).FirstOrDefault((x) => x.value === val);

    let newValue = [] as TransferListItem[];
    if (item) {
      newValue = current.filter((v) => v.value !== item.value);
    }
    else {
      const newItem = AsEnumerable(options).FirstOrDefault((x) => x.value === val);
      if (newItem) {
        newValue = [...current, newItem]
      }
    }

    setValue(newValue);
  }

  const items = options
    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
    .map((item) => (
      <Combobox.Option
        value={item.value}
        key={item.value}
        active={value.includes(item)}
        onMouseOver={() => combobox.resetSelectedOption()}>
        <Group gap="sm">
          <Checkbox
            checked={value.includes(item)}
            onChange={() => { }}
            aria-hidden
            tabIndex={-1}
            style={{ pointerEvents: 'none' }}
          />
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <div className={classes.renderList} data-type={type}>
      <Stack gap={0}>
        <Text mb={5}>{title}</Text>
        <Combobox store={combobox} onOptionSubmit={handleValueSelect}>
          <Combobox.EventsTarget>
            <Group wrap="nowrap" gap={0} className={classes.controls}>
              <TextInput
                placeholder={searchPlaceholder ?? t("Search...") as string}
                classNames={{ input: classes.input }}
                value={search}
                style={{ flex: 1 }}
                onChange={(event) => {
                  setSearch(event.currentTarget.value);
                  combobox.updateSelectedOptionIndex();
                }}
              />
              <ActionIcon
                radius={0}
                variant="default"
                size={36}
                className={classes.control}
                onClick={() => {
                  onTransfer(value);
                  setValue([]);
                }}>
                <IconChevronRight className={classes.icon} />
              </ActionIcon>
            </Group>
          </Combobox.EventsTarget>

          <div className={classes.list}>
            <Combobox.Options mah={maxHeight} style={{ overflowY: 'auto' }}>
              {items.length > 0 ? items : <Combobox.Empty>{nothingFound ?? t("Nothing here")}</Combobox.Empty>}
            </Combobox.Options>
          </div>
        </Combobox>
      </Stack>
    </div>
  );
}

export const TransferList: FC<{
  value: TransferListData;
  onChange?: (value: TransferListData) => void;
  titles: string[];
  searchPlaceholder?: string;
  nothingFound?: string;
}> = ({ value, onChange, titles, searchPlaceholder, nothingFound }) => {
  const [data, setData] = useState<[TransferListItem[], TransferListItem[]]>([value[0], value[1]]);

  useEffect(() => {
    if (value) {
      setData([value[0] ?? [], value[1] ?? []])
    }
  }, [value])

  const handleTransfer = (transferFrom: number, options: TransferListItem[]) =>
    setData((current) => {
      const transferTo = transferFrom === 0 ? 1 : 0;
      const transferFromData = current[transferFrom].filter((item) => options.every(o => o.value !== item.value));
      const transferToData = [...current[transferTo], ...options];

      const result = [];
      result[transferFrom] = transferFromData;
      result[transferTo] = transferToData;

      onChange?.(result as [TransferListItem[], TransferListItem[]]);
      return result as [TransferListItem[], TransferListItem[]];
    });

  return (
    <div className={classes.root}>
      <RenderList
        type="forward"
        options={data[0]}
        onTransfer={(options) => handleTransfer(0, options)}
        title={titles[0]}
        searchPlaceholder={searchPlaceholder}
        nothingFound={nothingFound}
      />
      <RenderList
        type="backward"
        options={data[1]}
        onTransfer={(options) => handleTransfer(1, options)}
        title={titles[1]}
        searchPlaceholder={searchPlaceholder}
        nothingFound={nothingFound}
      />
    </div>
  );
}
import { State, none, useHookstate } from "@hookstate/core";
import { Code, Group, Input, Stack, Text, TextInput, Textarea } from "@mantine/core";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import { SkillContentStep } from "src/stores/skills";
import { ServiceConnectionSelector } from "src/components/service-selector";
import NumberInputWithControl from "src/components/number-input-with-control";
import { container } from "src/inversify.config";
import { AppConfiguration } from "src/core/services/authentication-service";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";
import SkillContentStepWrapper from "./skill-content-step-wrapper";

const SkillContentStepLlmCall: FC<{
  state: State<SkillContentStep>;
  edit?: boolean;
  index: number;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ state, edit, index, leftToolbar, rightToolbar }) => {
  const scopedState = useHookstate(state.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(Object.keys(scopedState.prompt?.value)[0] ?? defaultAllowedLanguages[0]);

  const onDeleleLanguage = (lang: string) => {
    scopedState.prompt[lang].set(none);
    setIsDirty(true);
    setSelectedLanguage(Object.keys(scopedState.prompt?.value)[0]);
  }

  const onAddLanguage = (lang: string) => {
    scopedState.prompt[lang].set('');
    setIsDirty(true);
  }

  return (
    <SkillContentStepWrapper
      index={index}
      state={state}
      edit={edit}
      leftToolbar={
        <Group align="center" gap={5}>
          {leftToolbar}
          <LanguageSegmentedControl
            size="sm"
            compact
            editable={edit}
            data={Object.keys(scopedState.prompt?.value)}
            language={selectedLanguage}
            onChange={setSelectedLanguage}
            onDelete={onDeleleLanguage}
            onAdd={onAddLanguage}
          />
        </Group>
      }
      rightToolbar={rightToolbar}>
      {edit ?
        <Stack gap="xs">
          <Group align="flex-start" wrap="nowrap">
            <Input.Label w={120} required={edit}>{t("Config.")}:</Input.Label>
            <Group align="flex-start" grow style={{ flex: 1 }}>
              <ServiceConnectionSelector
                label={t("Service connection") as string}
                required={edit}
                width='100%'
                readOnly={!edit}
                categoryFilter='Llm'
                style={{ flex: 1 }}
                value={scopedState.service.value}
                onChange={(value) => { scopedState.service.set(value as string); setIsDirty(true); }}
              />
              <NumberInputWithControl
                required={edit}
                width="100%"
                label={t("Maximum tokens per response")}
                description={t("The maximum number of tokens to generate in the response.") as string}
                min={50}
                step={512}
                max={32000}
                value={scopedState.maxTokens.value ?? 4096}
                onChange={(value) => { scopedState.maxTokens.set(value as number); setIsDirty(true); }}
                readOnly={!edit}
              />
              <NumberInputWithControl
                required={edit}
                width="100%"
                label={t("Temperature")}
                description={t("Controls the creativity or randomness of the text generated.") as string}
                min={0}
                step={0.1}
                precision={1}
                max={1}
                value={scopedState.temperature.value ?? 0.5}
                onChange={(value) => { scopedState.temperature.set(value as number); setIsDirty(true); }}
                readOnly={!edit}
              />
            </Group>
          </Group>

          <Group align="flex-start">
            <Input.Label w={120} required={edit}>{t("Prompt")}:</Input.Label>
            <Textarea
              autosize
              minRows={3}
              maxRows={10}
              required
              style={{ flex: 1 }}
              value={scopedState.prompt[selectedLanguage]?.value ?? ''}
              onChange={(event) => { scopedState.prompt[selectedLanguage].set(event.target.value); setIsDirty(true); }}
            />
          </Group>
          <Group>
            <Input.Label w={120} required={edit}>{t("Assign to")}:</Input.Label>
            <TextInput
              required
              style={{ flex: 1 }}
              value={scopedState.entity.value}
              onChange={(event) => { scopedState.entity.set(event.target.value); setIsDirty(true); }}
            />
          </Group>
        </Stack>
        :
        <Stack gap="xs">
          <div>
            <Stack gap={0}>
              <Text span fw={500}> {t("Prompt")}:</Text>
              <Stack>
                <Code style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{scopedState.prompt[selectedLanguage]?.value ?? ''}</Code>
              </Stack>
            </Stack>
            <Group align="flex-start">
              <Text span fw={500}>{t("Set")} </Text><Text span fs="italic">{scopedState.entity.value}</Text>
              <Text span fw={500}>{t("Temperature")} </Text><Text span fs="italic">{scopedState.temperature.value}</Text>
              <Text span fw={500}>{t("Max tokens")} </Text><Text span fs="italic">{scopedState.maxTokens.value}</Text>
            </Group>
          </div>
        </Stack>
      }
    </SkillContentStepWrapper>
  );
};

export default SkillContentStepLlmCall;

import { Group, Stack, TextInput, PasswordInput, Table } from '@mantine/core';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceConnectionRef } from './serviceconnection';
import { useHookstate } from '@hookstate/core';
import { LlamaServiceConnectionContent } from 'src/stores/serviceconnections';
import AppContext from 'src/services/app-context';
import NumberInputWithControl from 'src/components/number-input-with-control';

export const LlamaServiceConnection = React.forwardRef<ServiceConnectionRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as LlamaServiceConnectionContent);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set({
        apiKey: '',
        endpoint: '',
        model: 'gpt-3.5-turbo',
        maxTokenLength: 8000,
        prompt: "{{system}} {{prompt}}"
      } as LlamaServiceConnectionContent);
    }
  }, [content])

  const isValid = () => {
    return scopedState.endpoint.value.length > 0 &&
      scopedState.model.value.length > 0 && (scopedState.maxTokenLength?.value ?? 0) > 0;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Model")}</Table.Td>
          <Table.Td>{scopedState.model.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Maximum token length")}</Table.Td>
          <Table.Td>{scopedState.maxTokenLength.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Endpoint")}</Table.Td>
          <Table.Td>{scopedState.endpoint.value}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Stack gap={10} justify="flex-start">
      <Group grow justify='space-between' align='flex-start'>
        <TextInput
          required
          style={{ flex: 1 }}
          label={t('Model')}
          value={scopedState.model.value}
          onChange={(event) => { scopedState.model.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />
        <NumberInputWithControl
          required
          width="100%"
          label={t("Maximum token length")}
          description={t("Maximum number of tokens used in the request.") as string}
          min={1024}
          step={512}
          max={128000}
          value={scopedState.maxTokenLength.value ?? 128000}
          onChange={(value) => { scopedState.maxTokenLength.set(value as number); setIsDirty(true); }}
          readOnly={!canContribute}
        />

      </Group>

      <TextInput
        required
        data-autofocus
        style={{ flex: 1 }}
        label={t('Endpoint')}
        value={scopedState.endpoint.value}
        onChange={(event) => { scopedState.endpoint.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

      <PasswordInput
        style={{ flex: 1 }}
        label={t('API key')}
        value={scopedState.apiKey.value}
        onChange={(event) => { scopedState.apiKey.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />
    </Stack>
  );
});

export default LlamaServiceConnection;

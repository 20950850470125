import { Badge } from '@mantine/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BotStatus } from 'src/stores/bots';
import { DocumentStatus } from 'src/stores/documents';
import { SkillStatus } from 'src/stores/skills';

const StatusBadge: FC<{
  status?: SkillStatus | DocumentStatus | BotStatus,
}> = ({ status }) => {
  const { t } = useTranslation();

  const getColor = () => {
    switch (status) {
      case 'Published':
        return 'green';
      case 'Retired':
        return 'yellow';
      default:
        return 'gray';
    }
  }

  return (
    <Badge variant='outline' color={getColor()}>{t(status ?? "Draft") as string}</Badge>
  );
};

export default StatusBadge;

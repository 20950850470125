import { Button, Group, Modal, Text } from "@mantine/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useCallbackPrompt } from "src/utils/useCallbackPrompt";

const LeavePageModal: FC<{
  when: boolean;
  title?: string;
  message?: string;
}> = ({ when, title, message }) => {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(when);

  return (
    <Modal
      opened={showPrompt as boolean}
      onClose={cancelNavigation as any}
      title={<Text>{t(title ?? "Leave this page")}</Text>}
      closeOnClickOutside={false}
    >
      <Text>{t(message ?? "You have pending changes. Are you sure want to leave this page? All changes will be lost.")}</Text>
      <Group align="center" justify='flex-end' wrap="nowrap" mt="lg" gap="md">
        <Button variant='outline' onClick={cancelNavigation as any}>
          {t("Cancel")}
        </Button>
        <Button onClick={confirmNavigation as any}>
          {t("Accept")}
        </Button>
      </Group>
    </Modal>
  );
};

export default LeavePageModal;
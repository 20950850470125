import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Group, Title, Text } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import { AppConfiguration, useAuthentication } from 'src/core/services/authentication-service';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IconHome2 } from '@tabler/icons-react';
import classes from './login.module.css';
import { container } from 'src/inversify.config';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);

  const [authStatus] = useAuthentication();

  let baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;
  let redirectUri = "/start";
  const localRedirect = localStorage.getItem("redirect_uri");
  if (localRedirect) {
    localStorage.removeItem("redirect_uri");
    if (baseUri && baseUri.endsWith('/')) {
      baseUri = baseUri.slice(0, baseUri.length - 1)
    }
    if (localRedirect.startsWith(baseUri)) {
      redirectUri = localRedirect.slice(baseUri.length).trim()
    }
  }

  setHeader(t('Home'), 'Projects', <IconHome2 size={34} />, t('Home') as string);
  setRoutes([
    { path: ``, breadcrumbName: t('Home') },
  ]);

  return (
    <Container className={classes.root}>
      <div className={classes.label}>...</div>
      <Title className={classes.title}>{t('Please wait while we authorize your account')}</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        {t('This might take only a couple of seconds. If this page does not change, their might be a problem with your account')}
      </Text>
      <Group justify="center">
        <Button component={Link} to={'/'} size="md">
          {t('Take me back to home page')}
        </Button>
      </Group>
      {authStatus.status.value === 'Success' && <Navigate to={redirectUri} />}
      {authStatus.status.value === 'Unauthorized' && <Navigate to="/login/401" />}
    </Container>
  );
};

export default NotFoundPage;

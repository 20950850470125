import { Group, Stack, Title, Text, Tooltip, ActionIcon, Button, SegmentedControl, useMantineTheme, useMantineColorScheme, Box, Flex, Alert } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Query } from 'src/core/stores/data-store';
import { container } from 'src/inversify.config';
import { DocumentSummary, DocumentSummaryStore } from 'src/stores/documents';
import useBus, { dispatch } from 'use-bus';
import { DataTableSortStatus } from 'mantine-datatable';
import { formatDateTime, formatMessage } from 'src/core/utils/object';
import { modals } from '@mantine/modals';
import { IconAlertCircle, IconArrowBigRight, IconArrowsSort, IconClock, IconExternalLink, IconFileText, IconMessageCircle, IconSortAscending, IconSortDescending, IconTrash } from '@tabler/icons-react';
import { BotCollectionSelector } from 'src/components/bot-collection-selector';
import { useParams } from 'react-router-dom';
import HeaderContext from 'src/services/header-context';
import { AppConfiguration } from '../../core/services/authentication-service';
import classes from '../index.module.css';
import { AsEnumerable } from 'linq-es5';
import SimpleListV2, { SimpleListV2Model } from 'src/core/ui/simple-list/simple-list-v2';
import { BotDocumentSummaryStore } from 'src/stores/bots';

const ChatDocumentList: FC<{ showFilters: boolean }> = ({ showFilters }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { height } = useViewportSize();
  const { setHeader } = useContext(HeaderContext);
  const documentSummaryStore = useMemo(() => container.get(DocumentSummaryStore), []);
  const documentSummaryState = documentSummaryStore.state;
  const summaryStore = useMemo(() => container.get(BotDocumentSummaryStore), []);
  const summaryState = summaryStore.state;
  const [query, setQuery] = useState<Query>(undefined as any);
  const [selectedItems, setSelectedItems] = useState<DocumentSummary[] | undefined>(undefined);
  const [contentTypeFilter, setContentTypeFilter] = useState<string>('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'title', direction: 'asc' });
  const params = useParams();
  const [botId, setBotId] = useState<string>();
  const [collectionSelected, setCollectionSelected] = useState<string | undefined>(undefined);
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    setHeader(t('Documents'), 'Documents', <IconFileText size={34} />, t('Documents') as string, true, true);
  }, []);

  useEffect(() => {
    setBotId(params.botId);
  }, [params.botId])

  useEffect(() => {
    if (query) {
      load(query);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (botId && collectionSelected) {
      summaryStore.setBotAndCollection(botId, collectionSelected);
      const newQuery = {
        ...query,
        skip: 0,
        take: 20,
        parameters: {
          contentTypeFilter: contentTypeFilter,
          orderByField: sortStatus.columnAccessor,
          orderByDirection: sortStatus.direction,
          botId: botId,
          collectionId: collectionSelected
        },
      } as Query;
      setQuery(newQuery);
    }
    else {
      setQuery(undefined as any);
      summaryStore.clear();
    }
  }, [contentTypeFilter, sortStatus, collectionSelected, botId]);

  const load = async (query: Query) => {
    summaryStore.load(query);
  };

  const onReload = () => {
    load(query);
  };

  useBus(
    '@@ui/CHAT_DOCUMENT_LIST_REFRESH',
    () => onReload(),
    [query],
  );

  const onCollectionSelected = (collectionId: string) => {
    setCollectionSelected(collectionId);
  };

  const deleteSelectedItem = (event: any, item: DocumentSummary) => {
    event.stopPropagation();
    if (item) {
      modals.openConfirmModal({
        title: <Text>{t('Delete item')}</Text>,
        children: (
          <Stack>
            <Text size="sm">{t('Do you want to delete this item?')}</Text>
            <Text>{item.title}</Text>
          </Stack>
        ),
        labels: { confirm: t('Delete'), cancel: t('Cancel') },
        confirmProps: { color: 'red' },
        onConfirm: async () => {
          documentSummaryStore.setCollection(item.collectionId);
          await documentSummaryStore.delete(item.id);
          documentSummaryStore.setCollection(undefined);
          if (!documentSummaryState.errorMessage.value) {
            onReload();
            dispatch('@@ui/CHAT_DOCUMENTS_DESELECTED');
          }
        }
      });
    }
  }

  const model: SimpleListV2Model<DocumentSummary> = {
    data: summaryStore.toListState(summaryState.value),
    query,
    columns: [
      {
        accessor: 'title',
        title: t('Title'),

        render: (item: DocumentSummary) => (
          <Box className="simpleListItem">
            <Stack gap="xs" style={{ minHeight: 60 }}>
              <div className="simpleListItemDeleteButton" style={{ borderBottomLeftRadius: theme.radius.md, backgroundColor: colorScheme === 'dark' ? theme.colors.gray[9] : theme.white }}>
                <Stack align='center' gap={0}>
                  <Tooltip withinPortal label={t("Go to document")}>
                    <ActionIcon variant='subtle' color="gray" size="lg" component="a" target="_blank" href={`${baseUri}/viewer/${item.id}`}>
                      <IconExternalLink size={18} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip withinPortal label={t("Delete this item")}>
                    <ActionIcon variant='subtle' size="lg" color='red' onClick={(event) => deleteSelectedItem(event, item)}>
                      <IconTrash size={18} />
                    </ActionIcon>
                  </Tooltip>
                </Stack>
              </div>

              <Flex gap={5} justify="flex-start" align="flex-start" direction="row">
                <Text span mt={3}>{item.contentType === 'text/chat' ? <IconMessageCircle size={20} /> : <IconFileText size={20} />}</Text>
                <Title order={5} className={classes.titleList}>{item.title}</Title>
              </Flex>

              <Text c="dimmed" size="xs">
                {!item.description && t('(No description)')}
                {item.description}
              </Text>

              <Group gap={5} align='center'>
                <IconClock size={12} />
                <Text c="dimmed" size="xs">{formatDateTime(item.modifiedOn)}</Text>
              </Group>
            </Stack>
          </Box>
        ),
      }
    ]
  };

  const getScrollAreaHeight = () => {
    return showFilters ? height - 280 : height - 100;
  }

  const onItemClick = (item: DocumentSummary) => {
    if (item.contentType === 'text/chat') {
      setSelectedItems([item]);
      dispatch({ type: '@@ui/CHAT_SELECTED', payload: item });
    }
    else {
      if (!AsEnumerable(selectedItems).Any(d => d.id === item.id)) {
        const selected = selectedItems ? [...selectedItems, item] : [item];
        setSelectedItems(selected);
      }
      dispatch({ type: '@@ui/CHAT_DOCUMENT_SELECTED', payload: item });
    }
  }

  useBus(
    '@@ui/CHAT_DOCUMENTS_DESELECTED',
    () => setSelectedItems(undefined),
    [selectedItems],
  );

  useBus(
    '@@ui/CHAT_DOCUMENT_SUMMARY_DESELECTED',
    (event) => onDocumentSummaryDeselected(event.payload),
    [selectedItems]
  )

  const onDocumentSummaryDeselected = (item: DocumentSummary) => {
    if (selectedItems) {
      const newValues = AsEnumerable(selectedItems).Where(x => x.id !== item.id).ToArray();
      setSelectedItems(newValues);
    }
  }

  const getSortButtons = () => {
    return (
      <Button.Group style={{ flex: 1 }}>
        <Button fullWidth variant="light" color={sortStatus.columnAccessor === 'title' ? undefined : 'gray'}
          onClick={() => setSortStatus({ columnAccessor: 'title', direction: sortStatus.direction === 'asc' ? 'desc' : 'asc' })}
          rightSection={
            <>
              {sortStatus.columnAccessor === 'title' ? sortStatus.direction === 'asc' ? <IconSortAscending /> : <IconSortDescending /> : null}
              {sortStatus.columnAccessor !== 'title' && <IconArrowsSort />}
            </>
          }>{t("Order by title")}
        </Button>
        <Button fullWidth variant="light" color={sortStatus.columnAccessor === 'lastmodified' ? undefined : 'gray'}
          onClick={() => setSortStatus({ columnAccessor: 'lastmodified', direction: sortStatus.direction === 'asc' ? 'desc' : 'asc' })}
          rightSection={
            <>
              {sortStatus.columnAccessor === 'lastmodified' ? sortStatus.direction === 'asc' ? <IconSortAscending /> : <IconSortDescending /> : null}
              {sortStatus.columnAccessor !== 'lastmodified' && <IconArrowsSort />}
            </>
          }>{t("Order by date")}
        </Button>
      </Button.Group>
    );
  }

  return (
    <Stack gap="xs">
      {showFilters &&
        <>
          <Group gap="xs">
            {getSortButtons()}
          </Group>
          {botId &&
            <Box>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flexGrow: 1 }}>
                  <BotCollectionSelector
                    width='100%'
                    botId={botId}
                    value={collectionSelected}
                    onChange={(value) => onCollectionSelected(value)}
                    selectFirstValue
                  />
                </div>
                <div style={{ flex: '0 0 35px' }}>
                  <Group gap="xs" align="center" justify="flex-end">
                    <Tooltip label={t('Go to collection')} withArrow>
                      <ActionIcon disabled={!collectionSelected} variant='subtle' color="gray" component="a" /*target="_blank"*/ href={`${baseUri}/admin/collections/${collectionSelected}`}>
                        <IconArrowBigRight />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </div>
              </div>
            </Box>
          }
          <Group grow>
            <SegmentedControl
              data={[
                { label: t("All"), value: '' },
                { label: t("Chats"), value: 'text/chat' },
                { label: t("Docs"), value: 'text/docs' },
                { label: t("Text"), value: 'text/markdown' }
              ]}
              value={contentTypeFilter}
              onChange={(value) => setContentTypeFilter(value)}
            />
          </Group>
        </>
      }

      {documentSummaryState.errorMessage.value &&
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red">
          <Text>{formatMessage(documentSummaryState.errorMessage.value)}</Text>
        </Alert>
      }

      <SimpleListV2
        idAccessor={'id'}
        rowStyle={({ id }) => (AsEnumerable(selectedItems).Any(d => d.id === id) ? { backgroundColor: 'var(--mantine-primary-color-light)', position: 'relative' } : { position: 'relative' })}
        model={model}
        onQueryChanged={setQuery}
        onRefresh={onReload}
        height={getScrollAreaHeight()}
        showFilters={showFilters}
        onItemClick={onItemClick}
      />
    </Stack>
  );
};

export default ChatDocumentList;

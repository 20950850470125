import { FC, useContext, useEffect, useState } from "react";
import { StepNodeProps } from "./steps";
import { useHookstate, none } from "@hookstate/core";
import { Group, Stack, ActionIcon, Textarea, Text, Tooltip, Fieldset } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import StepWrapper from "./step-wrapper";
import { truncateText } from "src/core/utils/object";
import HeaderContext from "src/services/header-context";
import LanguageSegmentedWrapper, { LanguageSegmentedWrapperChildrenProps } from "src/components/language-segmented-wrapper";

const StepEventNode: FC<StepNodeProps> = ({ id, data }) => {
  const { step, edit, index, rightToolbar, updateNode } = data;
  const scopedState = useHookstate(step.action);
  const { t } = useTranslation();
  const { header } = useContext(HeaderContext);
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);

  // useEffect(() => {
  //   const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStepAction;
  //   const newAction = { ...step.action, ...changes };
  //   const newStep = { ...step, action: newAction };
  //   const newData = { index: index, step: newStep } as StepDataProps;
  //   updateNode(id, newData);
  // }, [JSON.stringify(scopedState.value)])

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  }, [scopedState.text])

  const onAddText = () => {
    const defaultLang = allowedLanguages[0];
    let defaultText: { [key: string]: string } = {};
    defaultText[defaultLang] = t("<your event>", { lng: defaultLang });

    scopedState.text[scopedState.text.length].set(defaultText);
    setIsDirty(true);
  }

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(none);
    }
    setIsDirty(true);
  }

  const onAddLanguage = (language: string) => {
    const defaultText = t("<your message>", { lng: language });
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(defaultText);
    }
    setIsDirty(true);
  }

  const resumeLang = allowedLanguages.includes(header.language) ? header.language : allowedLanguages[0];
  const resume = (
    <Text title={scopedState?.text?.[0]?.[resumeLang]?.value}>{truncateText(scopedState?.text?.[0]?.[resumeLang]?.value)}</Text>
  )

  if (!scopedState?.value) return (<></>);

  return (
    <StepWrapper
      id={id}
      index={index}
      step={step}
      updateNode={updateNode}
      edit={edit}
      rightToolbar={rightToolbar}
      resume={resume}>
      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Messages")}</Text>
            {edit &&
              <Group align="center" gap={5}>
                <Tooltip withinPortal label={t("Add text")}>
                  <ActionIcon onClick={onAddText} variant='subtle' color="gray" size="sm">
                    <IconPlus size={14} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            }
          </Group>
        }>
        <Stack gap="xs">
          <LanguageSegmentedWrapper
            edit={edit}
            data={scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : undefined}
            onDelete={onDeleleLanguage}
            onAdd={onAddLanguage}
          >
            {(props: LanguageSegmentedWrapperChildrenProps) =>
              <>
                {scopedState?.text?.value && scopedState.text.map((text, index) =>
                  edit ?
                    <Group align="flex-start" key={index}>
                      <Textarea
                        autosize
                        minRows={3}
                        maxRows={10}
                        required
                        style={{ flex: 1, minWidth: 400 }}
                        value={text[props.selectedLanguage]?.value ?? ''}
                        onChange={(event) => { text[props.selectedLanguage].set(event.target.value); setIsDirty(true); }}
                      />
                      <Stack gap={5}>
                        <ActionIcon onClick={() => { text.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                          <IconTrash size={16} />
                        </ActionIcon>
                      </Stack>
                    </Group>
                    :
                    <div key={index}>
                      <Text span fs="italic">{text[props.selectedLanguage]?.value ?? ''}</Text>
                    </div>
                )}
              </>
            }
          </LanguageSegmentedWrapper>
        </Stack>
      </Fieldset>
    </StepWrapper>
  );
};

export default StepEventNode;
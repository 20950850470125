import { FC } from 'react'; import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import DocumentViewer from './document-viewer';

const ViewerIndex: FC = () => {
  const location = useLocation();

  return <Routes>
    <Route path=":documentId" element={<DocumentViewer />} key={location.pathname} />
  </Routes>
}

export default ViewerIndex;

import { FC } from "react";
import { StepOptionsEdgeDataProps } from "./steps";
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getStraightPath } from "reactflow";
import { Menu, ActionIcon } from "@mantine/core";
import { IconPlus, IconMessageCircle, IconQuestionMark, IconApi, IconExternalLink, IconStepInto, IconEqualDouble, IconBrain, IconMessageForward, IconBrandJavascript, IconFileSearch } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const StepOptionsEdge: FC<EdgeProps<StepOptionsEdgeDataProps>> = ({ id, sourceX, sourceY, targetX, targetY, data = {} as StepOptionsEdgeDataProps}) => {
  const { edit, index, skillType, onAddStep } = data;
  const { t } = useTranslation();
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      {edit && onAddStep &&
        <EdgeLabelRenderer>
            <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: 'all'
          }}
          className="nodrag nopan">
            <Menu shadow="md" width={200} withArrow withinPortal position="right-start">
              <Menu.Target>
                <ActionIcon variant='default' radius="xl">
                  <IconPlus />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{t("Select step type")}</Menu.Label>
                {skillType === 'Dialog' && <Menu.Item onClick={() => onAddStep("Say", index)} leftSection={<IconMessageCircle size={16} />}>{t("Say")}</Menu.Item>}
                {skillType === 'Dialog' && <Menu.Item onClick={() => onAddStep("Ask", index)} leftSection={<IconQuestionMark size={16} />}>{t("Ask")}</Menu.Item>}
                <Menu.Item onClick={() => onAddStep("CallApi", index)} leftSection={<IconApi size={16} />}>{t("Call API")}</Menu.Item>
                {skillType === 'Dialog' && <Menu.Item onClick={() => onAddStep("CallSkill", index)} leftSection={<IconExternalLink size={16} />}>{t("External skill")}</Menu.Item>}
                <Menu.Item onClick={() => onAddStep("GoTo", index)} leftSection={<IconStepInto size={16} />}>{t("Go to")}</Menu.Item>
                <Menu.Item onClick={() => onAddStep("Assign", index)} leftSection={<IconEqualDouble size={16} />}>{t("Assign")}</Menu.Item>
                <Menu.Item onClick={() => onAddStep("CallLlm", index)} leftSection={<IconBrain size={16} />}>{t("Call LLM")}</Menu.Item>
                <Menu.Item onClick={() => onAddStep("Event", index)} leftSection={<IconMessageForward size={16} />}>{t("Event")}</Menu.Item>
                {/* <Menu.Item onClick={() => onAddStep("ExecuteCode", index)} leftSection={<IconBrandJavascript size={16} />}>{t("Execute code")}</Menu.Item>
                <Menu.Item onClick={() => onAddStep("Search", index)} leftSection={<IconFileSearch size={16} />}>{t("Search")}</Menu.Item> */}
              </Menu.Dropdown>
            </Menu>
            </div>
        </EdgeLabelRenderer>
      }
    </>
  );
};

export default StepOptionsEdge;
import { State, useHookstate } from "@hookstate/core";
import { Group, Input, Text, Stack, Code, Select, ComboboxItem } from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import { SkillContentStep } from "src/stores/skills";
import SkillContentStepWrapper from "./skill-content-step-wrapper";
import { AsEnumerable } from "linq-es5";

const SkillContentStepGoto: FC<{
  state: State<SkillContentStep>;
  steps?: string[];
  edit?: boolean;
  index: number;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ state, edit, steps = [], index, leftToolbar, rightToolbar }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const [selectStepsData, setSelectStepsData] = useState<ComboboxItem[]>([]);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (steps?.length > 0) {
      const data = AsEnumerable(steps).Distinct().ToArray().map((s) => ({ value: s, label: s } as ComboboxItem));
      setSelectStepsData(data);
    }
    else {
      setSelectStepsData([]);
    }
  }, [steps])

  return (
    <SkillContentStepWrapper
      index={index}
      state={state}
      edit={edit}
      leftToolbar={
        <Group align="center" gap={5}>
          {leftToolbar}
        </Group>
      }
      rightToolbar={rightToolbar}>
      {edit &&
        <Stack gap="xs">
          <Group align="center">
            <Input.Label w={120} required={edit}>{t("Go to step")}:</Input.Label>
            <Select
              allowDeselect={false}
              w={'100%'}
              style={{ flex: 1 }}
              data={selectStepsData}
              value={scopedState.action.stepName.value}
              onChange={(value) => { scopedState.action.stepName.set(value as string); setIsDirty(true); }}
            />
          </Group>
        </Stack>
      }
      {!edit &&
        <Stack gap="xs">
          <div>
            <Text span fw={500}>{t("Go to step")} </Text>
            <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" >{scopedState.action.stepName.value}</Code>
          </div>
        </Stack>
      }
    </SkillContentStepWrapper>
  );
};

export default SkillContentStepGoto;
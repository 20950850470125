import { Stack, TextInput, PasswordInput, Textarea, Group, Select, Table } from '@mantine/core';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceConnectionRef } from './serviceconnection';
import { useHookstate } from '@hookstate/core';
import { SharePointServiceConnectionContent } from 'src/stores/serviceconnections';
import AppContext from 'src/services/app-context';
import { ServiceConnectionSelector } from 'src/components/service-selector';

export const SharePointServiceConnection = React.forwardRef<ServiceConnectionRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as SharePointServiceConnectionContent);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set({
        clientId: '',
        tenantId: '',
        privateKey: '',
        clientSecret: '',
        uri: ''
      } as SharePointServiceConnectionContent);
    }
  }, [content])

  const isValid = () => {
    return scopedState.uri.value.length > 0 && scopedState.api.value.length > 0 &&
      ((scopedState.authentication.value === 'Interactive' && scopedState.service.value.length > 0) ||
        (scopedState.clientId.value.length > 0 && (scopedState.authentication.value === 'Forms' && scopedState.clientSecret.value.length > 0) ||
          (scopedState.authentication.value === 'OAuth' && scopedState.tenantId.value.length > 0 && (scopedState.privateKey.value.length > 0 || scopedState.clientSecret.value.length > 0))));
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Uri")}</Table.Td>
          <Table.Td>{scopedState.uri.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Api and authentication")}</Table.Td>
          <Table.Td>{scopedState.api.value === 'GraphApi' ? t('Graph API') : scopedState.api.value === 'RestApi' ? t('SharePoint REST API') : ''}, {scopedState.authentication.value === 'Forms' ? t('Forms (Username/Password)') : scopedState.authentication.value === 'OAuth' ? t('OAuth (Client credentials)') : ''}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Stack gap={10} justify="flex-start">
      <TextInput
        data-autofocus
        style={{ flex: 1 }}
        label={t('Uri')}
        required
        value={scopedState.uri.value}
        onChange={(event) => { scopedState.uri.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

      <Group grow justify='space-between' align='flex-start'>
        <Select
          required
          allowDeselect={false}
          label={t("API")}
          placeholder={t("API") as string}
          data={[
            { value: 'RestApi', label: t('SharePoint REST API') as string },
            { value: 'GraphApi', label: t('Graph API') as string },
          ]}
          value={scopedState.api.value}
          onChange={(value) => { scopedState.api.set(value as any); setIsDirty(true); }}
          readOnly={!canContribute}
        />
        <Select
          required
          allowDeselect={false}
          label={t("Authentication")}
          placeholder={t("Authentication") as string}
          data={[
            { value: 'Forms', label: t('Forms (Username/Password)') as string },
            { value: 'OAuth', label: t('OAuth (Client credentials)') as string },
            { value: 'Interactive', label: t('Interactive') as string },
          ]}
          value={scopedState.authentication.value}
          onChange={(value) => { scopedState.authentication.set(value as any); setIsDirty(true); }}
          readOnly={!canContribute}
        />
      </Group>

      {scopedState.authentication.value && scopedState.authentication.value === 'Interactive' &&
        <ServiceConnectionSelector
          required
          width="100%"
          label={t("Identity provider") as string}
          categoryFilter='Identities'
          value={scopedState.service.value}
          onChange={(value) => scopedState.service.set(value as string)}
          readOnly={!canContribute}
        />
      }

      <Group grow justify='space-between' align='flex-start'>
        {scopedState.authentication.value && scopedState.authentication.value !== 'Interactive' &&
          <TextInput
            data-autofocus
            style={{ flex: 1 }}
            label={scopedState.authentication.value && scopedState.authentication.value === 'OAuth' ? t('Client Id') : t('Username')}
            required
            value={scopedState.clientId.value}
            onChange={(event) => { scopedState.clientId.set(event.target.value); setIsDirty(true); }}
            readOnly={!canContribute}
          />
        }

        {scopedState.authentication.value && scopedState.authentication.value === 'OAuth' &&
          <TextInput
            data-autofocus
            style={{ flex: 1 }}
            label={t('Tenant Id')}
            required
            value={scopedState.tenantId.value}
            onChange={(event) => { scopedState.tenantId.set(event.target.value); setIsDirty(true); }}
            readOnly={!canContribute}
          />
        }

        {scopedState.authentication.value && scopedState.authentication.value !== 'Interactive' &&
          <PasswordInput
            style={{ flex: 1 }}
            label={scopedState.authentication.value && scopedState.authentication.value === 'OAuth' ? t('Client secret') : t('Password')}
            value={scopedState.clientSecret.value}
            onChange={(event) => { scopedState.clientSecret.set(event.target.value); setIsDirty(true); }}
            readOnly={!canContribute}
            required={scopedState.authentication.value && scopedState.authentication.value === 'Forms'}
          />
        }
      </Group>

      {scopedState.authentication.value && scopedState.authentication.value === 'OAuth' && <>
        <Textarea
          style={{ flex: 1 }}
          label={t('Certificate (PEM)')}
          value={scopedState.certificate.value}
          rows={6}
          placeholder='-----BEGIN CERTIFICATE----- ... -----END CERTIFICATE-----'
          onChange={(event) => { scopedState.certificate.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />

        <Textarea
          style={{ flex: 1 }}
          label={t('Private key (PEM)')}
          value={scopedState.privateKey.value}
          rows={6}
          placeholder='-----BEGIN RSA PRIVATE KEY----- ... -----END RSA PRIVATE KEY-----'
          onChange={(event) => { scopedState.privateKey.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />
      </>}
    </Stack>
  );
});

export default SharePointServiceConnection;

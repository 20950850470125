import { Box, Popover, TextInput, Stack, Text, ScrollArea, Table, Divider, LoadingOverlay, Center, Group, Textarea } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISearchEntitiesStore } from 'src/stores/entities';
import { IconListSearch as ListSearch } from '@tabler/icons-react';

const IntentInput: FC<{
  label?: ReactNode;
  description?: string;
  required?: boolean;
  value: string;
  width?: string | number;
  onChange: (value: string) => void;
  creatable?: boolean;
  id?: string;
  searchEntitiesStore?: ISearchEntitiesStore;
}> = ({ label, description, required = false, width, value, onChange, creatable, id, searchEntitiesStore }) => {
  const { t } = useTranslation();
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const [intent, setIntent] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(intent, 200);
  const ref = useRef();
  const isBusy = searchEntitiesStore?.state?.isBusy.value;

  const onKeyDown = (event: any) => {
    if (event.key === '@') {
      setPopoverOpened(true);
    }
    if (event.key === 'Backspace') {
      setPopoverOpened(false);
      const start = (ref as any).current.selectionStart;
      const end = (ref as any).current.selectionEnd;
      const positions = getEntitiesPositions();
      let copy = internalValue;

      for (let index = 0; index < Object.keys(positions).length; index++) {
        const pos = positions[index];
        if ((start >= pos.start && start <= pos.end) || (end >= pos.start && end <= pos.end)) {
          const text = copy.substring(pos.start, pos.end);
          copy = copy.replace(text, '');
          break;
        }
      }

      setInternalValue(copy);
    }
  }

  const onIntentKeyDown = (event: any) => {
    if (event.key === 'Enter' && creatable) {
      setInternalValue(prev => prev + intent);
      setIntent('');
      setPopoverOpened(false);
      return;
    }
    if (event.key === 'Escape') {
      setIntent('');
      setPopoverOpened(false);
    }
  }

  const onSelectIntent = (value: string) => {
    setInternalValue(prev => prev + value);
    setIntent('');
    setPopoverOpened(false);
  }

  const getEntitiesPositions = () => {
    let positions = {} as { [key: number]: { start: number, end: number } }
    let searching = false;

    for (let index = 0; index < internalValue.length; index++) {
      const element = internalValue[index];
      if (searching) {
        if (element === ' ') {
          const i = Object.keys(positions).length - 1;
          positions[i].end = index;
          searching = false;
        }
      }
      else {
        if (element === '@') {
          positions[Object.keys(positions).length] = {
            start: index,
            end: internalValue.length
          };
          searching = true;
        }
      }
    }

    return positions;
  }

  useEffect(() => {
    if (value !== internalValue) {
      onChange(internalValue);
    }
  }, [internalValue])

  useEffect(() => {
    if (popoverOpened) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverOpened]);

  const load = async (name?: string) => {
    if (searchEntitiesStore && id) {
      searchEntitiesStore?.get(id, name ?? '');
    }
  };

  useEffect(() => {
    if (popoverOpened) {
      load(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <Box style={{ flex: 1 }}>
      <Popover
        opened={popoverOpened}
        closeOnEscape
        closeOnClickOutside
        position="bottom"
        //width="target"
        transitionProps={{ transition: 'pop' }}
        shadow="xl"
        withinPortal trapFocus withArrow returnFocus>
        <Popover.Target>
          <Textarea
            data-autofocus
            autosize
            minRows={2}
            maxRows={10}
            ref={ref as any}
            required={required}
            w={width ?? 120}
            label={label}
            description={description}
            value={internalValue}
            onChange={(event) => setInternalValue(event.currentTarget.value)}
            onKeyDown={onKeyDown}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <Stack gap={5}>
            {creatable ?
              <Text>{t("Type a new entity and press Enter or search and select one.")}</Text>
              :
              <Text>{t("Search and select one.")}</Text>
            }
            <TextInput
              data-autofocus
              value={intent}
              onChange={(event) => setIntent(event.target.value)}
              onKeyDown={onIntentKeyDown}
              placeholder={creatable ? t("New entity or search") as string : t("Search entity") as string}
            />
            {searchEntitiesStore && id &&
              <div style={{ position: 'relative' }}>
                <LoadingOverlay visible={isBusy ?? false} />
                <Divider label={t("Entities")} labelPosition="center" />

                {searchEntitiesStore && searchEntitiesStore.state && searchEntitiesStore.state.items && searchEntitiesStore.state.items.value &&
                  <ScrollArea style={{ height: '25svh' }} offsetScrollbars>
                    <Table striped highlightOnHover>
                      <Table.Tbody>
                        {searchEntitiesStore.state.items.map((e, index) =>
                          <Table.Tr key={index} onClick={() => onSelectIntent(e.name.value)} style={{ cursor: 'pointer' }} >
                            <Table.Td>{e.name.value}</Table.Td>
                          </Table.Tr>
                        )}
                        {searchEntitiesStore.state.items.value.length === 0 &&
                          <Center m="md">
                            <Group gap="xs" align="center">
                              <ListSearch />
                              <Text>{t('No items found')}</Text>
                            </Group>
                          </Center>
                        }
                      </Table.Tbody>
                    </Table>
                  </ScrollArea>
                }
              </div>
            }
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};

export default IntentInput;

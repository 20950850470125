import { Box, Text, ThemeIcon } from '@mantine/core';
import { FC } from 'react';
import classes from './listitem-component.module.css'
import cx from 'clsx';

const ListItem: FC<{ icon?: any, title: string, description?: string, className?: string }> = ({ icon, title, description, className }) => {
  return (
    <div className={cx(classes.wrapper, className)}>
      {icon &&
        <Box mr="sm">
          <ThemeIcon size={40} variant="light" radius="md">
            {icon}
          </ThemeIcon>
        </Box>
      }
      <div>
        <Text size="xs" c="dimmed">{title}</Text>
        <Text style={{ wordBreak: 'break-word' }}>{description}</Text>
      </div>
    </div>
  );
};

export default ListItem;

import { ActionIcon, Group, Select, Stack, Text, Tooltip } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrawlerLogSummary, CrawlerLogSummaryStore } from "src/stores/crawlers";
import { container } from "src/inversify.config";
import { Query } from "src/core/stores/data-store";
import moment from "moment";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { formatMessage } from "src/core/utils/object";
import TableListV2, { TableListV2Model } from "src/core/ui/table-list/table-list-v2";
import { DataTableSortStatus } from "mantine-datatable";
import { IconAlertCircle, IconAlertTriangle, IconInfoCircle, IconActivity, IconEraser, IconAdjustments } from "@tabler/icons-react";

const CrawlerLog: FC<{
  collectionId: string;
  crawlerId: string;
}> = ({ collectionId, crawlerId }) => {
  const { t } = useTranslation();
  const modals = useModals();
  const store = useMemo(() => container.get(CrawlerLogSummaryStore), []);
  const state = store.state;
  const [query, setQuery] = useState<Query>(undefined as any);
  const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'createdOn', direction: 'desc' });

  useEffect(() => {
    if (collectionId && crawlerId) {
      store.setCollectionAndCrawler(collectionId, crawlerId);
      const newQuery = {
        ...query,
        //orderBy: [{ field: 'createdOn', direction: 'Descending', useProfile: false }],
        skip: 0,
        parameters: {
          orderByField: sortStatus.columnAccessor,
          orderByDirection: sortStatus.direction
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, crawlerId]);

  useEffect(() => {
    if (query) {
      store.load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onReload = () => {
    store.load(query)
  };

  useEffect(() => {
    const newQuery = {
      ...query,
      //orderBy: [{ field: 'createdOn', direction: 'Descending', useProfile: false }],
      skip: 0,
      take: 10,
      parameters: {
        typeFilter: typeFilter,
      },
    } as Query;
    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilter]);

  const getActionIcon = (item: CrawlerLogSummary) => {
    switch (item.type) {
      case "Error":
        return <IconAlertCircle size={16} color="red" />
      case "Warning":
        return <IconAlertTriangle size={16} color="orange" />
      default:
        return <IconInfoCircle size={16} />
    }
  }

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Clear logs')}</Text>,
      children: (
        <Text size="sm">{t('Are you sure you want to delete crawler logs?')}</Text>
      ),
      labels: { confirm: t('Clear logs'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(),
    });

  const onConfirmDelete = async () => {
    store.setCollectionAndCrawler(collectionId, crawlerId);
    await store.clearLogs();
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      store.load(query);
    }
  };

  const model: TableListV2Model<CrawlerLogSummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'action',
        title: t('Action'),
        width: '15%',
        sortable: true,
        render: (item: CrawlerLogSummary) => (
          <Group align="center" gap="xs">
            {getActionIcon(item)}
            <Text>{item.action}</Text>
          </Group>
        )
      },
      {
        accessor: 'createdOn',
        title: t('Timestamp'),
        width: '15%',
        sortable: true,
        render: (item: CrawlerLogSummary) => (
          <Text>{moment(item.createdOn).format("DD-MM-YYYY hh:mm:ss")}</Text>
        )
      },
      {
        accessor: 'message',
        title: t('Message'),
        width: '75%',
        render: (item: CrawlerLogSummary) => (
          <Text style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{item.message}</Text>
        )
      }
    ]
  };

  return (
    <Stack gap="md">
      <TableListV2
        model={model}
        onQueryChanged={setQuery}
        onRefresh={onReload}
        striped
        highlightOnHover
        sortable
        sortStatus={sortStatus}
        onSortStatusChanged={setSortStatus}
        leftToolBarRender={
          <Group align="center">
            <IconActivity />
            <Text fw={500}>{t('Crawler log')}</Text>
            <Tooltip label={t("Clear logs")}>
              <ActionIcon onClick={openDeleteModal} variant='subtle' color="gray">
                <IconEraser />
              </ActionIcon>
            </Tooltip>
          </Group>
        }
        rightToolBarRender={
          <Group gap="xs" align="center">
            <Select
              clearable
              style={{ width: 240 }}
              leftSection={<IconAdjustments size={20} />}
              placeholder={t('Type') as string}
              data={[
                { value: 'Info', label: t('Info') as string },
                { value: 'Warning', label: t('Warning') as string },
                { value: 'Error', label: t('Error') as string },
              ]}
              onChange={(value) => setTypeFilter(value as string)}
              value={typeFilter}
            />
          </Group>
        }
      />
    </Stack>
  );
};

export default CrawlerLog;
import { Stack, TextInput, PasswordInput, Table } from '@mantine/core';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceConnectionRef } from './serviceconnection';
import { useHookstate } from '@hookstate/core';
import { InsightsServiceConnectionContent } from 'src/stores/serviceconnections';
import AppContext from 'src/services/app-context';

export const InsightsServiceConnection = React.forwardRef<ServiceConnectionRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as InsightsServiceConnectionContent);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set({
        apiKey: '',
        appId: '',
        connectionString: ''
      } as InsightsServiceConnectionContent);
    }
  }, [content])

  const isValid = () => {
    return scopedState.connectionString.value.length > 0;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Connection string")}</Table.Td>
          <Table.Td>{scopedState.connectionString.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Application Id")}</Table.Td>
          <Table.Td>{scopedState.appId.value}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Stack gap={10} justify="flex-start">
      <TextInput
        required
        style={{ flex: 1 }}
        label={t('Connection string')}
        value={scopedState.connectionString.value}
        onChange={(event) => { scopedState.connectionString.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

      <TextInput
        data-autofocus
        style={{ flex: 1 }}
        label={t('Application Id')}
        value={scopedState.appId.value}
        onChange={(event) => { scopedState.appId.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

      <PasswordInput
        style={{ flex: 1 }}
        label={t('API key')}
        value={scopedState.apiKey.value}
        onChange={(event) => { scopedState.apiKey.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

    </Stack>
  );
});

export default InsightsServiceConnection;

import { Alert, Anchor, Card, Group, Select, Stack, Text, Tooltip } from '@mantine/core';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { formatMessage, sleep } from 'src/core/utils/object';
import { EntityItemStore, EntitySummary, EntitySummaryStore } from 'src/stores/entities';
import moment from 'moment';
import EditEntity from './edit-entity';
import CreateEntity from './create-entity';
import TableListV2, { TableListV2Model } from 'src/core/ui/table-list/table-list-v2';
import { DataTableSortStatus } from 'mantine-datatable';
import { IconAlertCircle, IconAdjustments } from '@tabler/icons-react';

const EntitiesList: FC<{}> = () => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(EntitySummaryStore), []);
  const state = store.state;
  const itemStore = useMemo(() => container.get(EntityItemStore), []);
  const itemState = itemStore.state;
  const itemStateErrorMessage = itemState.errorMessage.value;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [typeFilter, setTypeFilter] = useState('');
  const [query, setQuery] = useState<Query>(undefined as any);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });

  useEffect(() => {
    if (query) {
      load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const load = async (query: Query) => {
    store.load(query);
  };

  useEffect(() => {
    const newQuery = {
      ...query,
      //orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
      skip: 0,
      parameters: {
        typeFilter: typeFilter,
        orderByField: sortStatus.columnAccessor,
        orderByDirection: sortStatus.direction
      },
    } as Query;
    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilter]);

  const onConfirmDelete = async (selectedItems: EntitySummary[]) => {
    if (selectedItems && selectedItems.length > 0) {
      selectedItems.forEach((item) => { itemStore.delete(item.id); });
      await sleep(500);
      load(query);
    }
  };

  const model: TableListV2Model<EntitySummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'name',
        title: t('Name'),
        sortable: true,
        render: (item: EntitySummary) => (
          <Text>
            <Tooltip label={t("Edit entity")}>
              <Anchor onClick={() => { setSelectedItem(item.id); setShowCreateModal(true); }}>{item.name}</Anchor>
            </Tooltip>
          </Text>
        )
      },
      {
        accessor: 'type',
        title: t('Type'),
        sortable: true,
        render: (item: EntitySummary) => (
          <Text>{t(item.type)}</Text>
        )
      },
      {
        accessor: 'createdBy',
        title: t('Created by'),
        sortable: true,
        render: (item: EntitySummary) => (
          <Text>{item.createdBy}</Text>
        )
      },
      {
        accessor: 'createdOn',
        title: t('Created on'),
        sortable: true,
        render: (item: EntitySummary) => (
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.createdOn).toString()}</Text>}>
            <Text>{moment(item.createdOn).fromNow()}</Text>
          </Tooltip>
        )
      },
      {
        accessor: 'modifiedBy',
        title: t('Modified by'),
        sortable: true,
        render: (item: EntitySummary) => (
          <Text>{item.modifiedBy}</Text>
        )
      },
      {
        accessor: 'modifiedOn',
        title: t('Modified on'),
        sortable: true,
        render: (item: EntitySummary) => (
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.modifiedOn).toString()}</Text>}>
            <Text>{moment(item.modifiedOn).fromNow()}</Text>
          </Tooltip>
        )
      }
    ]
  };

  return (
    <Card withBorder>
      <Stack gap="md">
        {itemStateErrorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => itemStore.clearError()}>
            <Text>{formatMessage(itemStateErrorMessage)}</Text>
          </Alert>
        )}
        <TableListV2
          model={model}
          onQueryChanged={setQuery}
          onRefresh={() => load(query)}
          striped
          highlightOnHover
          sortable
          sortStatus={sortStatus}
          onSortStatusChanged={setSortStatus}
          showNewIcon
          onNewItemClick={() => { setSelectedItem(undefined); setShowCreateModal(true); }}
          selectable
          onDeleteSelectedClick={(selectedItems: EntitySummary[]) => onConfirmDelete(selectedItems)}
          rightToolBarRender={
            <Group gap="xs" align="center">
              <Select
                clearable
                style={{ width: 240 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Type') as string}
                data={[
                  { value: 'Synonyms', label: t('Synonyms') as string },
                  { value: 'Regex', label: t('Regex') as string },
                ]}
                onChange={(value) => setTypeFilter(value as string)}
                value={typeFilter}
              />
            </Group>
          }
        />
      </Stack>
      {showCreateModal && selectedItem &&
        <EditEntity
          key="edit-entity-modal"
          opened={showCreateModal}
          onClose={() => { setShowCreateModal(false); setSelectedItem(undefined); }}
          entityId={selectedItem}
          onFinish={() => { setShowCreateModal(false); setSelectedItem(undefined); load(query); }}
        />
      }
      {showCreateModal && selectedItem === undefined &&
        <CreateEntity
          key="create-entity-modal"
          opened={showCreateModal}
          onClose={() => { setShowCreateModal(false); setSelectedItem(undefined); }}
          onFinish={() => { setShowCreateModal(false); setSelectedItem(undefined); load(query); }}
        />
      }
    </Card>
  );
};

export default EntitiesList;

import { State, useHookstate } from "@hookstate/core";
import { Alert, Anchor, Badge, Container, Grid, Group, Input, LoadingOverlay, ComboboxItem, SimpleGrid, Stack, Text, useMantineColorScheme } from "@mantine/core";
import MDEditor from "@uiw/react-md-editor";
import moment from "moment";
import { FC, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CollapsibleCard from "src/components/collapsible-card";
import ListItem from "src/components/listitem-component";
import { formatMessage, truncateText } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { BotContentSkill } from "src/stores/bots";
import { SkillItemStore, SkillSnapshotSelectStore } from "src/stores/skills";
import AppContext from "src/services/app-context";
import NumberInputWithControl from "src/components/number-input-with-control";
import { SnapshotSelector } from "src/components/snapshot-selector";
import { IconAlertCircle, IconClock, IconFileInfo, IconFriends, IconInfoSquare, IconLanguage, IconLock, IconLockOpen, IconNotes, IconUserCircle } from "@tabler/icons-react";
import HeaderContext from "src/services/header-context";
import classes from 'src/pages/index.module.css';
import { BasicMultiselect } from "src/components/basic-multiselect";

const BotSkillAssignedDetail: FC<{
  state: State<BotContentSkill>;
  value: string;
  edit?: boolean;
}> = ({ state, value, edit = false }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const { header } = useContext(HeaderContext);
  const skillItemStore = useMemo(() => container.get(SkillItemStore), []);
  const skillItemState = skillItemStore.state;
  const isBusy = skillItemState.isBusy.value;
  const errorMessage = skillItemState.errorMessage.value;
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const snapShotSelectStore = useMemo(() => container.get(SkillSnapshotSelectStore), []);

  useEffect(() => {
    if (value) {
      load(value);
      snapShotSelectStore.setSkillId(value);
    }
  }, [value]);

  const load = async (value: string) => {
    if (value) {
      await skillItemStore.load(value);
    }
  };

  const getAudiencesData = () => {
    let data = [] as ComboboxItem[];
    if (scopedState?.audiences?.value) {
      data = scopedState.audiences.value.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={isBusy} />
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => skillItemStore.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}

      {skillItemState?.item?.value && scopedState?.value &&
        <Container fluid px={0} py={0}>
          <Grid gutter={'xs'}>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <CollapsibleCard
                title={
                  <Group justify='space-between'>
                    <Text fw={500}>{t('Audiences')}</Text>
                  </Group>
                }
                cardKey="bot-skill-assigned-detail-audiences-edit"
                icon={<IconFriends />}
                collapseInfoRender={
                  <Badge variant='light' color='gray'>{scopedState.audiences.length} {t("audiences")}</Badge>
                }>
                <Stack justify="flex-start">
                  {edit ?
                    <BasicMultiselect
                      data={getAudiencesData()}
                      value={scopedState.audiences.value as string[]}
                      onChange={(value) => { scopedState.audiences.set(value); setIsDirty(true); }}
                      placeholder={t("Select audiences") as string}
                      creatable
                    />
                    :
                    <Input.Wrapper>
                      <Group gap="xs" align="center">
                        {scopedState.audiences.value.map((a, index) => (
                          <Badge size="xs" radius="sm" p="xs" key={index} className={classes.tag}>{a}</Badge>
                        ))}
                      </Group>
                    </Input.Wrapper>
                  }
                  <Group align="center" grow>
                    <NumberInputWithControl
                      required
                      label={t("Confidence")}
                      description={t("Minimum value that must be reached to return a matched document.") as string}
                      precision={6}
                      min={0}
                      step={0.1}
                      max={1}
                      value={scopedState.confidence.value ?? 0.6}
                      onChange={(value) => { scopedState.confidence.set(value as number); setIsDirty(true); }}
                      readOnly={!edit}
                    />
                  </Group>

                  <SnapshotSelector
                    width='100%'
                    store={snapShotSelectStore}
                    value={scopedState.snapshotId.value}
                    onChange={(value) => { scopedState.snapshotId.set(value); setIsDirty(true); }}
                    readOnly={!edit}
                    showWarningModal={false}
                    clearable />

                </Stack>
              </CollapsibleCard>
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <Stack gap="xs">
                <CollapsibleCard
                  title={<Text fw={500}>{t('Info')}</Text>}
                  cardKey='bot-skill-info-card'
                  icon={<IconInfoSquare />}
                  collapseInfoRender={
                    <Badge variant='light' color="gray" title={skillItemState.item.title.value}>{truncateText(skillItemState.item.title.value, 12)}</Badge>
                  }>
                  <Stack align='flex-start'>
                    <Anchor
                      component={Link}
                      to={`/admin/skills/${value}`}
                      underline='never'
                      variant="text">
                      <ListItem title={t("Title")} icon={<IconFileInfo size={20} />} description={skillItemState.item.title.value} />
                    </Anchor>
                    <SimpleGrid cols={{ base: 1, md: 2, sm: 3 }}>
                      {skillItemState?.item?.currentSnapshot?.createdOn &&
                        <>
                          <ListItem title={t("Modified on")} icon={<IconClock size={20} />} description={moment(skillItemState.item.currentSnapshot.createdOn.value).fromNow()} />
                          <ListItem title={t("Modified by")} icon={<IconUserCircle size={20} />} description={skillItemState.item.currentSnapshot.createdBy.value} />
                        </>
                      }
                      {skillItemState.item.currentSnapshot?.languages &&
                        <ListItem title={t("Languages")} icon={<IconLanguage size={20} />} description={skillItemState.item.currentSnapshot.languages.value.join(', ')} />
                      }
                      <ListItem
                        title={t("Access mode")}
                        icon={skillItemState.item.accessMode.value === 'Everyone' ? <IconLockOpen size={20} /> : <IconLock size={20} />}
                        description={skillItemState.item.accessMode.value === 'Everyone' ? t("Public access") as string : t("Members only") as string}
                      />
                    </SimpleGrid>
                  </Stack>
                </CollapsibleCard>

                <CollapsibleCard
                  title={<Text fw={500}>{t('Description')}</Text>}
                  cardKey='skill-description-card'
                  icon={<IconNotes />}>
                  <div data-color-mode={colorScheme}>
                    <MDEditor.Markdown source={skillItemState.item?.description?.[header.language]?.value ?? t('(No description)')} />
                  </div>
                </CollapsibleCard>
              </Stack>
            </Grid.Col>
          </Grid>
        </Container>
      }
    </div>
  );
};

export default BotSkillAssignedDetail;
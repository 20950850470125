import { State, useHookstate } from "@hookstate/core";
import { Group, ActionIcon, Textarea, Stack, Tooltip, Text, Code, Fieldset } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";

const StepCondition: FC<{
  edit?: boolean;
  state: State<string>;
}> = ({ edit, state }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const [hasCondition, setHasCondition] = useState<boolean>(scopedState?.value?.toString().length > 0);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    setHasCondition(scopedState?.value?.toString().length > 0);
  }, [scopedState?.value])

  const onChangeCondition = () => {
    if (hasCondition) {
      scopedState.set(''); // si se pone set(none) parece que renderiza todos los pasos
    }
    else {
      scopedState.set('<your condition>');
    }
    setHasCondition(v => !v);
    setIsDirty(true);
  }

  return (
    <Fieldset p="xs" variant="filled"
      legend={
        <Group align="center" gap={5}>
          <Text size="sm">{t("Condition")}</Text>
          {edit &&
            <Group align="center" gap={5}>
              <Tooltip withinPortal label={!hasCondition ? t("Add condition") : t("Remove condition")}>
                <ActionIcon onClick={onChangeCondition} variant='subtle' color="gray" size="sm">
                  {!hasCondition ? <IconPlus size={14} /> : <IconTrash size={14} />}
                </ActionIcon>
              </Tooltip>
            </Group>
          }
        </Group>
      }>
      {edit &&
        <Stack gap="xs">
          {hasCondition ?
            <Textarea
              autosize
              minRows={3}
              maxRows={10}
              required
              style={{ flex: 1, minWidth: 400 }}
              value={scopedState.value?.toString()}
              onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
            />
            :
            <Text fw={500}>{t("Always")}</Text>
          }
        </Stack>
      }
      {!edit &&
        <>
          {scopedState?.value?.toString().length > 0 ?
            <div>
              <Text fw={500} span>{t("When")} </Text>
              <Code style={{ fontSize: 16 }} color="var(--mantine-color-teal-light)" >{scopedState.value.toString()}</Code>
            </div>
            :
            <Text fw={500}>{t("Always")}</Text>
          }
        </>
      }
    </Fieldset>
  );
};

export default StepCondition;
import { AccessToken, OAuth2AuthCodePKCE } from './oauth';
import { container } from 'src/inversify.config';
import { hookstate, useHookstate, State } from '@hookstate/core';

export type SharingAccessMode = 'OrganizationWide' | 'MembersOnly' | 'OwnerOnly';
export type PersonalCollectionLevel = 'AllUsers' | 'SelectedUsersOnly';

export interface AppConfiguration {
  brandName: string;
  serviceUrl: string;
  authorizationEndpoint: string;
  tokenEndpoint: string;
  logoutEndpoint: string;
  authority: string;
  clientId: string;
  scopes: string;
  theme: string;
  allowedLanguages: string[];
  defaultAudience: string;
  version: string;
  allowPersonalCollection: boolean;
  sharingAccessMode: SharingAccessMode;
  personalCollectionLevel: PersonalCollectionLevel;
  supportDocs: string;
  supportNewIssueLink: string;
}

export interface IHash<T> {
  [details: string]: T;
}

export interface AuthenticationResult {
  status: 'Error' | 'Success' | 'Unauthenticated' | 'Unauthorized',
  accessToken: AccessToken | undefined,
  user: IHash<any>,
  errorMessage: string | undefined
}

export let authenticationService: AuthenticationService | undefined = undefined;

export const authStatus = hookstate<AuthenticationResult>({
  status: 'Unauthenticated',
  accessToken: undefined,
  errorMessage: undefined,
  user: {}
});

export class AuthenticationService {
  private _authService: OAuth2AuthCodePKCE;
  private _logoutUri: string;

  constructor(config: AppConfiguration) {
    var self = this;
    let existingToken = sessionStorage.getItem("_token");
    if (existingToken) {
      var authResult = JSON.parse(existingToken) as AuthenticationResult;
      if (authResult.accessToken?.expiry && new Date(authResult.accessToken?.expiry as string) >= new Date()) {
        authStatus.set(authResult);
      }
      else {
        authStatus.set({
          status: 'Error',
          errorMessage: 'Token expired',
          accessToken: undefined,
          user: {}
        });
      }
    }

    this._logoutUri = `${config.authority}${config.logoutEndpoint}?client_id=${config.clientId}`;
    this._authService = new OAuth2AuthCodePKCE({
      authorizationUrl: `${config.authority}${config.authorizationEndpoint}`,
      tokenUrl: `${config.authority}${config.tokenEndpoint}`,
      clientId: config.clientId,
      scopes: !config.scopes ? ['openid'] : config.scopes.split(' '),
      redirectUrl: `${config.serviceUrl}/login/callback`,
      onAccessTokenExpiry(refreshAccessToken) {
        console.log("Expired! Access token needs to be renewed.");
        return refreshAccessToken();
      },
      onInvalidGrant(refreshAuthCodeOrRefreshToken) {
        console.log("Expired! Auth code or refresh token needs to be renewed.");
        return refreshAuthCodeOrRefreshToken();
      }
    });
    this._authService
      .isReturningFromAuthServer()
      .then(hasAuthCode => {
        if (authStatus.accessToken.value?.value)
          return;
        if (!hasAuthCode) {
          authStatus.set({
            status: 'Error',
            errorMessage: 'Something went wrong. This might be a configuration problem',
            accessToken: undefined,
            user: {}
          });
          console.log("Unknown error");
        }
        return self._authService.getAccessToken().then((token) => {
          if (token.token?.expiry && new Date(token.token?.expiry as string) <= new Date()) {
            self.authorize(true);
            return;
          }

          fetch(`${process.env.PUBLIC_URL}/api/identity/profile`, {
            headers: { Authorization: `Bearer ${token.token?.value}` }
          })
            .then((response) => {
              if (response.status === 401 || response.status === 403) {
                throw new Error("Unauthorized");
              }
              else {
                return response.json();
              }
            })
            .then((user) => {
              const result = {
                status: 'Success',
                errorMessage: undefined,
                accessToken: token.token,
                user: user
              } as AuthenticationResult;
              authStatus.set(result);
              sessionStorage.setItem("_token", JSON.stringify(result));
              console.log("Access token obtained successfully");
            })
            .catch((potentialError) => {
              const errorCode = potentialError.toString()
              authStatus.set({
                status: errorCode.includes('Unauthorized') ? 'Unauthorized' : 'Error',
                errorMessage: potentialError,
                accessToken: undefined,
                user: {}
              });
              console.log(`Error obtaining token: ${potentialError}`);
            });

        });
      })
      .catch((potentialError) => {
        if (potentialError) {
          authStatus.set({
            status: 'Error',
            errorMessage: potentialError,
            accessToken: undefined,
            user: {}
          });
          console.log(potentialError);
        }
      });
  }

  public authorize(refresh: boolean) {
    if (refresh || !authStatus.accessToken.value?.value || (authStatus.accessToken.value.expiry && new Date(authStatus.accessToken.value.expiry as string) <= new Date())) {
      authStatus.set({
        status: 'Unauthenticated',
        errorMessage: undefined,
        accessToken: undefined,
        user: {}
      });
      sessionStorage.removeItem("_token");
      if (!refresh) {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('redirect_uri')) {
          localStorage.setItem("redirect_uri", searchParams.get('redirect_uri')!);
        }
      }
      this._authService.fetchAuthorizationCode();
    }
  }

  public getLogoutUri() {
    return this._logoutUri;
  }

  public async getAccessToken() {
    let tokenInfo = await this._authService.getAccessToken();
    if (!tokenInfo.token) {
      this.authorize(true);
      return null;
    }
    if (this._authService.isAccessTokenExpired()) {
      tokenInfo = await this._authService.exchangeRefreshTokenForAccessToken();
    }
    if (!tokenInfo.token) {
      this.authorize(true);
      return null;
    }
    return tokenInfo;
  }
}

const initializeAuthentication = () => {
  return authenticationService || (authenticationService = new AuthenticationService(container.get<AppConfiguration>("AppConfiguration")));
}

const useAuthentication = (): [State<AuthenticationResult>, () => void, () => string | undefined] => {
  return [useHookstate(authStatus), () => authenticationService?.authorize(false), () => authenticationService?.getLogoutUri()]
}

export { initializeAuthentication, useAuthentication };
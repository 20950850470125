import DataStore from "src/core/stores/data-store";
import FormStore from "src/core/stores/form-store";
import { MemberItem } from "./identities";

export type ServiceConnectionType = 'Gpt' | 'Mistral' | 'Internet' | "AppInsights" | 'Llama' | 'BuiltIn' | "SharePoint" | "OAuth";
export type ServiceConnectionCategory = 'Llm' | 'CrossEncoder' | 'SentenceEmbedding' | 'Search' | 'Insights' | "DocumentsSource" | "Identities";
export type ServiceConnectionAccessMode = 'Everyone' | 'MembersOnly';
export type ServiceConnectionGptProvider = 'Azure' | 'OpenAI';
export type ServiceConnectionSearchInternetProvider = 'SerpApi' | 'GoogleApi';

export interface ServiceConnectionSummary {
  id: string;
  name: string;
  type: ServiceConnectionType;
  category: ServiceConnectionCategory;
  accessMode: ServiceConnectionAccessMode;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export interface ServiceConnectionItem {
  id: string;
  name: string;
  type: ServiceConnectionType;
  category: ServiceConnectionCategory;
  content: string;
  accessMode: ServiceConnectionAccessMode;
  members: MemberItem[];
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export interface NewServiceConnectionItem {
  name: string;
  type: ServiceConnectionType;
  category: ServiceConnectionCategory;
  content: string;
  accessMode: ServiceConnectionAccessMode;
  members: MemberItem[];
}

export interface UpdateServiceConnectionItem {
  id: string;
  content: string;
  accessMode: ServiceConnectionAccessMode;
  members: MemberItem[];
}

export interface ServiceConnectionContent {
}

export interface InsightsServiceConnectionContent extends ServiceConnectionContent {
  connectionString: string;
  appId: string;
  apiKey: string;
}

export interface SharePointServiceConnectionContent extends ServiceConnectionContent {
  uri: string;
  authentication: 'Forms' | 'OAuth' | 'Interactive';
  api: 'RestApi' | 'GraphApi',
  service: string;
  clientId: string;
  clientSecret: string;
  certificate: string;
  privateKey: string;
  tenantId: string;
}

export interface OAuthServiceConnectionContent extends ServiceConnectionContent {
  authority: string;
  authorizationEndpoint: string;
  title: { [key: string]: string };
  provider: string;
  flow: 'Interactive' | 'ClientCredentials'
  description: { [key: string]: string };
  tokenEndpoint: string;
  logoutEndpoint: string;
  clientId: string;
  clientSecret: string;
  scopes: string;
  certificate: string;
  privateKey: string;
}

export interface GptServiceConnectionContent extends ServiceConnectionContent {
  provider: ServiceConnectionGptProvider;
  model: string;
  endpoint: string;
  apiKey: string;
  deployment?: string;
  organization?: string;
  maxTokenLength?: number;
  parallelTools: boolean;
  multimodal: boolean;
}

export interface MistralServiceConnectionContent extends ServiceConnectionContent {
  model: string;
  apiKey: string;
  maxTokenLength?: number;
}

export interface BuiltInServiceConnectionContent extends ServiceConnectionContent {
  tier: string;
}

export interface LlamaServiceConnectionContent extends ServiceConnectionContent {
  model: string;
  endpoint: string;
  apiKey: string;
  maxTokenLength?: number;
}

export interface SearchInternetServiceConnectionContent extends ServiceConnectionContent {
  provider: ServiceConnectionSearchInternetProvider;
  model: string;
  endpoint: string;
  apiKey: string;
}

export interface TestServiceConnectionItem {
  type: ServiceConnectionType;
  category: ServiceConnectionCategory;
  content: string;
}

export class ServiceConnectionSummaryStore extends DataStore<ServiceConnectionSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/serviceconnections`, []);
  }
}

export class ServiceConnectionItemStore extends FormStore<ServiceConnectionItem, NewServiceConnectionItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/serviceconnections`);
  }

  public async train(collectionId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._baseUrl}/${collectionId}/train`, {});
    });
  }
}

export class TestServiceConnectionStore extends FormStore<TestServiceConnectionItem, TestServiceConnectionItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/serviceconnections`);
  }

  public async test(item: TestServiceConnectionItem) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/test`, item);
    });
  }
}
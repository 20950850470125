import { Group, Stack, TextInput, Select, PasswordInput, Table } from '@mantine/core';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceConnectionRef } from './serviceconnection';
import { useHookstate } from '@hookstate/core';
import { SearchInternetServiceConnectionContent, ServiceConnectionSearchInternetProvider } from 'src/stores/serviceconnections';
import AppContext from 'src/services/app-context';

export const SearchInternetServiceConnection = React.forwardRef<ServiceConnectionRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as SearchInternetServiceConnectionContent);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set({
        apiKey: '',
        endpoint: 'https://serpapi.com/search.json',
        model: 'Google',
        provider: 'SerpApi'
      } as SearchInternetServiceConnectionContent);
    }
  }, [content])

  const isValid = () => {
    return scopedState.apiKey.value.length > 0 && scopedState.endpoint.value.length > 0 &&
      scopedState.model.value.length > 0 && scopedState.provider.value.length > 0;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Provider")}</Table.Td>
          <Table.Td>{scopedState.provider.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Model")}</Table.Td>
          <Table.Td>{scopedState.model.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Endpoint")}</Table.Td>
          <Table.Td>{scopedState.endpoint.value}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Stack gap={10} justify="flex-start">
      <Group>
        <Select
          required
          allowDeselect={false}
          style={{ flex: 1 }}
          label={t("Provider")}
          placeholder={t("Search provider") as string}
          data={[
            { value: 'SerpApi', label: t('SerpApi') as string },
            { value: 'GoogleApi', label: t('Google API') as string },
          ]}
          value={scopedState.provider.value}
          onChange={(value) => { scopedState.provider.set(value as ServiceConnectionSearchInternetProvider); setIsDirty(true); }}
          readOnly={!canContribute}
        />

        {scopedState.provider.value === 'SerpApi' && <TextInput
          required
          style={{ flex: 1 }}
          label={t('Model')}
          value={scopedState.model.value}
          onChange={(event) => { scopedState.model.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />}
      </Group>

      <TextInput
        required
        data-autofocus
        style={{ flex: 1 }}
        label={scopedState.provider.value === 'SerpApi' ? t('Endpoint') : t('Custom Search Engine ID')}
        value={scopedState.endpoint.value}
        onChange={(event) => { scopedState.endpoint.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />

      <PasswordInput
        style={{ flex: 1 }}
        required
        label={t('API key')}
        value={scopedState.apiKey.value}
        onChange={(event) => { scopedState.apiKey.set(event.target.value); setIsDirty(true); }}
        readOnly={!canContribute}
      />
    </Stack>
  );
});

export default SearchInternetServiceConnection;

import { Badge } from '@mantine/core';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BotTrainingStatus } from 'src/stores/bots';
import { DocumentTrainingStatus } from 'src/stores/documents';
import { SkillTrainingStatus } from 'src/stores/skills';
import { container } from '../inversify.config';
import { JobsStore } from '../stores/jobs';

const TrainingStatusBadge: FC<{
  status?: SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus,
  jobReference?: string;
}> = ({ status, jobReference }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(JobsStore), []);
  const state = store.state;
  const [internalStatus, setInternalStatus] = useState<SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus>(status || 'Pending')

  useEffect(() => {
    setInternalStatus(status || 'Pending')
    updateStatus()
  }, [status])

  const updateStatus = async () => {
    if (jobReference && jobReference !== "" && (status == 'Training' || status == 'Pending')) {
      await store.load(jobReference)
      let newState: SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus = 'Pending'
      switch (state.item?.status?.value) {
        case 'Waiting':
          newState = 'Pending';
          break;
        case 'Running':
          newState = 'Training';
          break;
        case 'Succeeded':
          newState = 'Trained';
          break;
        case 'Failed':
        default:
          newState = 'Error';
          break;
      }
      setInternalStatus(newState)
    }
  }

  const getColor = () => {
    switch (internalStatus) {
      case 'Error':
        return 'red';
      case 'Training':
        return 'yellow';
        case 'Trained':
        return 'green';
      default:
        return 'gray';
    }
  }

  return (
    <Badge variant='outline' color={getColor()}>{t(internalStatus ?? "Pending") as string}</Badge>
  );
};

export default TrainingStatusBadge;

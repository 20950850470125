import DataStore, { QueryResult } from "src/core/stores/data-store";
import FormStore from "src/core/stores/form-store";
import { ContentKeyValue } from "./skills";

export type CrawlerType = 'ServiceNow' | 'Web' | 'SharePoint' | "Microsoft365";
export type CrawlerStatus = 'Idle' | 'Running';
export type CrawlerAction = 'start' | 'stop';
export type CrawlerLogType = 'Info' | 'Error' | 'Warning';

export interface CrawlerSummary {
  id: string;
  collectionId: string;
  title: string;
  type: CrawlerType;
  schedule: string;
  status: CrawlerStatus;
}

export interface CrawlerItem {
  id: string;
  collectionId: string;
  title: string;
  type: CrawlerType;
  schedule: string;
  content: string;
  status: CrawlerStatus;
  properties: { [key: string]: any };
}

export interface NewCrawlerItem {
  collectionId: string;
  title: string;
  type: CrawlerType;
  schedule: string;
  content: string;
}

export interface UpdateCrawlerItem {
  id: string;
  collectionId: string;
  title: string;
  schedule: string;
  content: string;
  properties: { [key: string]: any };
}

export interface TestCrawlerConnectionItem {
  collectionId: string;
  type: CrawlerType;
  content: string;
}

export interface CrawlerResult {
  id: string;
  language: string;
  title: string;
  body: string;
  uri: string;
  audience: string[];
}

export interface CrawlerContent {
}

export interface ServiceNowCrawlerContent extends CrawlerContent {
  serviceUri: string;
  username: string;
  password: string;
  languages: string[];
  knowledgeBases: KnowledgeBaseItem[];
}

export interface KnowledgeBaseItem {
  id: string;
  name: string;
  splitMethod: string;
  audiences: string[];
}

export interface WebCrawlerContent extends CrawlerContent {
  targetUris: string[];
  username: string;
  password: string;
  headers: ContentKeyValue[];
  whiteList: string[];
  blackList: string[];
  audiences: string[];
  languages: string[];
  code: string;
}

export interface SharePointCrawlerContent extends CrawlerContent {
  targetUris: string[];
  whiteList: string[];
  blackList: string[];
  audience: string[];
  languages: string[];
  authentication: 'Forms' | 'OAuth';
  api: 'RestApi' | 'GraphApi',
  service?: string;
  clientId: string;
  clientSecret: string;
  certificate: string;
  privateKey: string;
  tenantId: string;
  importAudience: boolean;
  includeMembers: boolean;
}

export interface Microsoft365CrawlerContent extends CrawlerContent {
  accounts: string[];
  languages: string[];
  authentication: 'Interactive' | 'OAuth';
  service: string;
  clientId: string;
  clientSecret: string;
  certificate: string;
  privateKey: string;
  tenantId: string;
  email: boolean;
  drive: boolean;
  events: boolean;
  includeMembers: boolean;
  emailCrawlDays?: number;
  emailExpirationDays?: number;
  driveCrawlDays?: number;
  driveExpirationDays?: number;
  eventsCrawlDays?: number;
  eventsExpirationDays?: number;
}

export interface CrawlerLogSummary {
  id: string;
  crawlerId: string;
  action: string;
  type: CrawlerLogType;
  message: string;
  createdOn: Date;
}

export interface CrawlerPatchSummary {
  id: string;
  crawlerId: string;
  applyTo: string;
  title: string;
}

export interface CrawlerPatchItem {
  id: string;
  crawlerId: string;
  applyTo: string;
  title: string;
  patch: string;
}

export interface NewCrawlerPatchItem {
  crawlerId: string;
  applyTo: string;
  title: string;
  patch: string;
}

export interface UpsertCrawlerPatchesItem {
  crawlerId: string;
  patches: UpsertCrawlerPatchItem[];
}

export interface UpsertCrawlerPatchItem {
  applyTo: string;
  title: string;
  patch: string;
}

export const GetServiceNowCrawlerDefaultContent = () => {
  return {
    username: '',
    password: '',
    serviceUri: '',
    languages: [],
    knowledgeBases: []
  } as ServiceNowCrawlerContent;
}

export const GetWebCrawlerDefaultContent = () => {
  return {
    targetUris: [],
    headers: [],
    username: '',
    password: '',
    languages: [],
    audiences: [],
    whiteList: [],
    blackList: [],
    code: '{}'
  } as WebCrawlerContent;
}

export const GetSharePointCrawlerDefaultContent = () => {
  return {
    service: undefined,
    targetUris: [],
    languages: [],
    audience: [],
    whiteList: [],
    blackList: [],
    api: 'GraphApi',
    authentication: 'OAuth',
    clientId: '',
    clientSecret: '',
    certificate: '',
    privateKey: '',
    tenantId: '',
    importAudience: true,
    includeMembers: true,
  } as SharePointCrawlerContent;
}

export const GetMicrosoft365CrawlerDefaultContent = () => {
  return {
    accounts: [],
    service: '',
    languages: [],
    authentication: 'Interactive',
    clientId: '',
    clientSecret: '',
    certificate: '',
    privateKey: '',
    tenantId: '',
    email: true,
    drive: true,
    includeMembers: true,
    events: true,
    driveCrawlDays: 0,
    driveExpirationDays: 0,
    emailCrawlDays: 0,
    emailExpirationDays: 0,
    eventsCrawlDays: 0,
    eventsExpirationDays: 0
  } as Microsoft365CrawlerContent;
}

export class CrawlerSummaryStore extends DataStore<CrawlerSummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollection(collectionId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/crawlers`;
  }

  public async runCrawlerAction(id: string, action: CrawlerAction) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/${action}`, null);
    });
  }
}

export class CrawlerItemStore extends FormStore<CrawlerItem, NewCrawlerItem> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollection(collectionId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/crawlers`;
  }

  public async runCrawlerAction(id: string, action: CrawlerAction) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/${action}`, null);
    });
  }
}

export class CrawlerLogSummaryStore extends DataStore<CrawlerLogSummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollectionAndCrawler(collectionId: string, crawlerId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/crawlers/${crawlerId}/log`;
  }

  public async clearLogs() {
    await this.handleCallAsync(async () => {
      await this.httpService.delete(`${this.baseUrl}`, null);
    });
  }
}

export class CrawlerPatchesSummaryStore extends DataStore<CrawlerPatchSummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollectionAndCrawler(collectionId: string, crawlerId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/crawlers/${crawlerId}/patches`;
  }

  public async importPatches(item: UpsertCrawlerPatchesItem) {
    await this.handleCallAsync(async () => {
      await this.httpService.put(`${this.baseUrl}/import`, item);
    });
  }
}

export class CrawlerPatchesItemStore extends FormStore<CrawlerPatchItem, NewCrawlerPatchItem> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollectionAndCrawler(collectionId: string, crawlerId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/crawlers/${crawlerId}/patches`;
  }
}

export class TestCrawlerConnectionStore extends DataStore<CrawlerResult> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollection(collectionId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/crawlers`;
  }

  public async test(item: TestCrawlerConnectionItem) {
    await this.handleCallAsync(async () => {
      const response = await this.httpService.post<TestCrawlerConnectionItem, QueryResult<CrawlerResult>>(`${this.baseUrl}/test`, item);
      this._state.set((s) => {
        s.items = response.data.items || (response.data as any).value || [];
        s.count = response.data.count || (response.data as any)['@odata.count'] || 0;
        return s;
      });
    });
  }
}
import { State, useHookstate } from "@hookstate/core";
import { CopyButton, Group, Stack, Loader, Card, Tooltip, ActionIcon, Alert, Text, useMantineColorScheme, useMantineTheme, Button, Box, Avatar, Flex, Grid } from "@mantine/core";
import { IconCheck, IconCopy, IconMoodSad, IconThumbUp, IconThumbUpFilled, IconThumbDownFilled, IconThumbDown, IconFileTypePdf, IconFileTypeDoc, IconFileTypeXls, IconFileTypePpt, IconRefresh, IconHeadset } from "@tabler/icons-react";
import moment from "moment";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getEmojiNumber, isNullOrWhitespace } from "src/core/utils/object";
import { ChatRequest, ChatResponse } from "src/stores/chat";
import { container } from 'src/inversify.config';
import { AppConfiguration } from "../../core/services/authentication-service";
import ChatMessageButton from "./chat-message-button";
import { AsEnumerable } from "linq-es5";
import ChatMessageReference from "./chat-message-reference";
import { MIME_TYPES } from "@mantine/dropzone";
import { ReactPhotoCollage } from "react-photo-collage";
import ChatMessageMarkdown from "./chat-message-markdown";

const ChatMessageAssistant: FC<{
  state: State<ChatRequest | ChatResponse>;
  onSendMessage?: (message: string, signal: AbortSignal) => void,
  onRewriteAnswer?: () => void,
  onMessageClick?: () => void;
  messageIndex: number;
  enableFeedBackGathering?: boolean;
  onSendFeedback?: (messageId: string, like: boolean) => void;
  isBusy?: boolean;
  botName?: string;
  botLogo?: string;
}> = ({ state, onSendMessage, onMessageClick, enableFeedBackGathering, onSendFeedback, isBusy, botName = "#Bot", botLogo, onRewriteAnswer, messageIndex }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;
  const defaultBotLogo = `${baseUri}/api/config/images/favicon.ico`;
  const supportNewIssueLink = container.get<AppConfiguration>("AppConfiguration").supportNewIssueLink;

  (window as any).ask = (text: string) => {
    if (onSendMessage) {
      onSendMessage(text, AbortSignal.timeout(300000));
    }
  }

  const getFileIcon = (contentType: string, iconUrl: string) => {
    if (!isNullOrWhitespace(iconUrl)) {
      return <Avatar size={16} mt={3} src={iconUrl} radius="sm" />
    }
    else {
      if (contentType === MIME_TYPES.pdf)
        return <IconFileTypePdf size={16} />;
      if (contentType === MIME_TYPES.doc || contentType === MIME_TYPES.docx)
        return <IconFileTypeDoc size={16} />;
      if (contentType === MIME_TYPES.xls || contentType === MIME_TYPES.xlsx)
        return <IconFileTypeXls size={16} />;
      if (contentType === MIME_TYPES.ppt || contentType === MIME_TYPES.pptx)
        return <IconFileTypePpt size={16} />;
    }
    return undefined;
  }

  const chatResponse = scopedState as State<ChatResponse>;

  const images = useMemo(() => {
    if (chatResponse?.actions?.value) {
      return AsEnumerable(chatResponse?.actions?.value).Where(o => o.properties?.thumbnail_url).Select(o => ({
        caption: o.title,
        source: o.properties?.thumbnail_url
      })).ToArray()
    }
    return [];
  }, [chatResponse?.actions?.value])

  let assistantResponse = chatResponse.text?.value;
  if (assistantResponse) {
    assistantResponse = assistantResponse.replace(/\[\^(.*?)\^\]/g, function (_, b) { return `<sup>${getEmojiNumber(b)}</sup>`; });
  }

  if (scopedState.role.value === 'System' || scopedState.role.value === 'Function') return (<></>);
  if (scopedState.role.value === 'Assistant' && scopedState.content?.type?.value === 'FunctionCall') return (<></>);
  if (!assistantResponse) return (<></>);

  const renderReferences = () => {
    const items = AsEnumerable(chatResponse?.actions?.value).Where(x => x.sticky === true && (x.type === 'MessageBack' || x.type === 'OpenUrl')).ToArray();
    if (items?.length > 0) {
      return (
        <Group align="center" gap="xs">
          {items.map((m, index) =>
            <>
              {m.type === 'OpenUrl' &&
                <ChatMessageReference
                  key={`openurl-${index}`}
                  label={m.title}
                  icon={getFileIcon(m.properties?.content_type, m.properties?.icon_url)}
                  thumbnail={m.properties?.thumbnail_url}
                  link={m.properties.url}
                  text={m.description}
                />
              }
              {m.type === 'MessageBack' &&
                <ChatMessageReference
                  key={`messageback-${index}`}
                  label={m.title}
                  icon={getFileIcon(m.properties?.content_type, m.properties?.icon_url)}
                  thumbnail={m.properties?.thumbnail_url}
                  onSendMessage={() => onSendMessage?.(m.title, AbortSignal.timeout(300000))}
                  text={m.description}
                />
              }
            </>
          )}
        </Group>);
    }
    else {
      return null;
    }
  }

  return (
    <CopyButton value={chatResponse.text?.value}>
      {({ copied, copy }) => (
        <Stack gap={30}>
          {!assistantResponse?.startsWith("ERROR: ") &&
            <Card radius="md" padding={5} onClick={() => onMessageClick?.()}>
              <Flex gap="xs" justify="flex-start" align="flex-start" direction="row">
                <Avatar size="sm" src={botLogo && botLogo.length > 0 ? botLogo : defaultBotLogo} alt={botName} title={`${botName} - ${moment(scopedState.timestamp.value).fromNow()}`} />

                <Stack gap={5} align="flex-start" justify="flex-start">
                  <Text fw={500}>{botName}</Text>

                  <div data-color-mode={colorScheme}>
                    <Grid>
                      <Grid.Col span={{ base: 12, md: images.length > 0 ? 9 : 12 }}>
                        {assistantResponse && assistantResponse === "⏳" && <Loader color={theme.colors[theme.primaryColor][6]} size="xs" mr={5} />}
                        {assistantResponse && assistantResponse !== "⏳" && <ChatMessageMarkdown text={assistantResponse} />}
                      </Grid.Col>

                      {images.length > 0 && <Grid.Col span={{ base: 12, md: images.length > 0 ? 3 : 0 }}>
                        <ReactPhotoCollage width="220px" height={images.length === 1 ? ['150px'] : ['150px', '80px']} layout={images.length === 1 ? [1] : [1, 2]} photos={images} showNumOfRemainingPhotos={true} />
                      </Grid.Col>}
                    </Grid>

                    {chatResponse?.actions?.value && chatResponse?.actions?.value.length > 0 &&
                      <Box mt="xs">
                        {renderReferences()}
                      </Box>
                    }
                  </div>

                  <Group gap="xs">
                    {!isBusy && messageIndex > 2 && onRewriteAnswer &&
                      <Button variant='subtle' size="compact-xs" color={'gray'} ml={-5} styles={{ section: { marginInlineEnd: 5 } }}
                        onClick={onRewriteAnswer}
                        leftSection={<IconRefresh size="1rem" />}>
                        <Text size="sm">{t("Rewrite")}</Text>
                      </Button>
                    }
                    {!isBusy &&
                      <Tooltip label={copied ? t('Copied') : t('Copy')} withArrow>
                        <ActionIcon variant='subtle' size={20} color={copied ? 'teal' : 'gray'} onClick={copy}>
                          {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                        </ActionIcon>
                      </Tooltip>
                    }
                    {enableFeedBackGathering && !isBusy &&
                      <>
                        <ChatMessageButton
                          icon={<IconThumbUp size="1rem" />}
                          clickedIcon={<IconThumbUpFilled size="1rem" />}
                          timeout={2000}
                          tooltip={t('Like')}
                          onClick={() => onSendFeedback?.(scopedState.id.value as string, true)}
                        />
                        <ChatMessageButton
                          icon={<IconThumbDown size="1rem" />}
                          clickedIcon={<IconThumbDownFilled size="1rem" />}
                          timeout={2000}
                          tooltip={t("Dislike")}
                          onClick={() => onSendFeedback?.(scopedState.id.value as string, false)}
                        />
                      </>
                    }
                  </Group>
                </Stack>
              </Flex>
            </Card>
          }
          {assistantResponse?.startsWith("ERROR: ") &&
            <Stack gap={5} align="flex-start" justify="flex-start">
              <Alert icon={<IconMoodSad size="1rem" />} title={t("Bot returned an error")} color="red">
                <Stack justify="flex-start" align="flex-start">
                  <Text size="sm">{assistantResponse}</Text>

                  {supportNewIssueLink &&
                    <Button component="a" href={supportNewIssueLink} target='_blank'
                      leftSection={<IconHeadset size={20} />} variant="default">
                      {t("Get support")}
                    </Button>
                  }
                </Stack>
              </Alert>
              {!isBusy && messageIndex > 2 && onRewriteAnswer &&
                <Tooltip label={t('Rewrite answer')} withArrow>
                  <ActionIcon variant='subtle' size={20} color={'gray'} onClick={onRewriteAnswer}>
                    {<IconRefresh size="1rem" />}
                  </ActionIcon>
                </Tooltip>
              }
            </Stack>
          }
        </Stack>
      )}
    </CopyButton>
  );
}

export default ChatMessageAssistant;
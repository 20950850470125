import { useHookstate } from "@hookstate/core";
import { Alert, Button, Group, Modal, Select, SimpleGrid, Stack, Text, TextInput } from "@mantine/core";
import { IconAlertCircle, IconPlus } from "@tabler/icons-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LocalizedTextArea from "src/components/localized-textarea";
import MembersEditor from "src/components/members-editor";
import TagsEditor from "src/components/tags-editor";
import { AppConfiguration } from "src/core/services/authentication-service";
import { formatMessage } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { BotItem, BotItemStore, NewBotForm } from "src/stores/bots";
import { MemberEditorState, MemberItem } from "src/stores/identities";
import { TagItem } from "src/stores/skills";
import { tagsToDict } from "src/utils/tags-utils";
import { dispatch } from "use-bus";

const CreateBot: FC<{
  opened: boolean;
  onClose: () => void;
  onFinish?: (botId: string) => void;
}> = ({ opened, onClose, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const store = useMemo(() => container.get(BotItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const allowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const defaultDescription: { [key: string]: string } = {};
  defaultDescription[allowedLanguages[0]] = '';

  const initialState = {
    title: '',
    description: defaultDescription,
    tags: [],
    members: [],
    accessMode: "MembersOnly",
    strategy: "Router"
  } as NewBotForm;

  const formState = useHookstate(initialState);

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);

  const validateForm = () => {
    let errors = {} as { [key: string]: string };
    if (!formState.title.value && formState.title.value.length === 0) {
      errors.title = t('Invalid name');
    }
    if (!formState.strategy.value) {
      errors.strategy = t('Invalid strategy');
    }
    if (!formState.accessMode.value) {
      errors.accessMode = t('Invalid access mode');
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSubmit = async () => {
    if (validateForm()) {
      const newItem = {
        title: formState.title.value,
        description: formState.description.value,
        accessMode: membersState.accessMode.value,
        strategy: formState.strategy.value,
        tags: tagsToDict(formState.tags.value as TagItem[]),
        members: membersState.members.value,
      } as BotItem;

      let response = await store.create(newItem);
      if (response) {
        navigate(`/admin/bots/${response.id}/edit`);
        dispatch('@@ui/BOT_LIST_REFRESH');
        formState.set(initialState);
        onClose();
      }
    }
  }

  const onCloseModal = () => {
    formState.set(initialState);
    store.clearError();
    onClose();
  }

  useEffect(() => {
    if (formState.accessMode.value === 'Everyone') {
      formState.members.set([]);
    }
  }, [formState.accessMode])

  return (
    <Modal
      opened={opened}
      onClose={onCloseModal}
      title={<Text>{t("Create a bot")}</Text>}
      size="70%"
      closeOnClickOutside={false}>
      <Stack justify="flex-start">
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        <Group align="flex-start">
          <TextInput
            required
            data-autoFocus
            label={t('Title')}
            description={t("Provide a full name for your bot to help search and understand its purpose.")}
            value={formState.title.value}
            onChange={(event) => formState.title.set(event.target.value)}
            error={errors.title}
            style={{ flex: 1 }}
          />

          <Select
            required
            allowDeselect={false}
            label={t("Strategy")}
            placeholder={t("Bot strategy") as string}
            data={[
              { value: 'Router', label: t('Router') as string },
              { value: 'Sequential', label: t('Sequential') as string },
              { value: 'Llm', label: t('Large Language Models (LLMs)') as string }
            ]}
            value={formState.strategy.value}
            onChange={(value) => formState.strategy.set(value as any)}
            error={errors.strategy}
            w={300}
          />
        </Group>

        <LocalizedTextArea
          label={t("Description") as string}
          edit
          value={formState.description.value}
          onChange={(value) => formState.description.set(value)} />

        <SimpleGrid
          cols={{ base: 1, md: 2 }}
          spacing="lg">
          <TagsEditor state={formState.tags} />

          <MembersEditor state={membersState} mode="Bot" creation />
        </SimpleGrid>

        <Group align="center" justify='flex-end' wrap="nowrap">
          <Button
            onClick={onSubmit}
            loading={isBusy}
            leftSection={<IconPlus />}
            type="submit">
            {t("Create")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default CreateBot;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Group, Title, Text } from '@mantine/core';
import classes from './access-denied.module.css';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <Container className={classes.root}>
      <Title className={classes.title}>{t('Sorry, you have no access')}</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        {t('You do not have permission to access this service. Please, contact your network administrator to request access.')}
      </Text>
      <Group justify="center">
        <Button size="md">{t('Try again')}</Button>
      </Group>
    </Container>
  );
};

export default NotFoundPage;

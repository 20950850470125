import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { Stack, Tabs, Grid, Card, ScrollArea, Container, Group, Menu, ActionIcon, Text, Badge, Alert, SimpleGrid, Button, Anchor, Tooltip, useMantineColorScheme, Box, Loader , Image } from '@mantine/core';
import FileViewer from 'react-file-viewer';
import HeaderContext from 'src/services/header-context';
import CollapsibleCard from 'src/components/collapsible-card';
import TagsCard from 'src/components/tags-card';
import BootstrapIntentsCard from 'src/components/bootstrap-intents-card';
import SuggestionsCard from 'src/components/suggestions-card';
import { formatMessage, getFileNameFromUri, truncateText } from 'src/core/utils/object';
import { tagsToArray } from 'src/utils/tags-utils';
import { useHookstate } from '@hookstate/core';
import moment from 'moment';
import ListItem from 'src/components/listitem-component';
import StatusBadge from 'src/components/status-badge';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import useBus, { dispatch } from 'use-bus';
import MDEditor from '@uiw/react-md-editor';
import { DocumentItemStore } from 'src/stores/documents';
import { TagItem } from 'src/stores/skills';
import PropertiesCard from 'src/components/properties-card';
import DocumentHistory from './document-history';
import { UserCanContribute } from 'src/utils/permissions';
import { MemberItem } from 'src/stores/identities';
import SnapshotPassages from './snapshot-passages';
import TrainButton from 'src/components/train-button';
import TrainingStatusBadge from 'src/components/training-status-badge';
import DocumentChatHistory from './document-chat-history';
import { IconFileText, IconBook2, IconAlertCircle, IconFileCode, IconArticle, IconHistory, IconCloudUpload, IconDotsVertical, IconEdit, IconCloudOff, IconTrash, IconInfoSquare, IconBolt, IconLanguage, IconBellOff, IconAxe, IconClock, IconUserCircle, IconNotes, IconFriends, IconFileInfo, IconFileSymlink, IconListSearch } from '@tabler/icons-react';
import ContentWrapper from 'src/components/content-wrapper';
import classes from 'src/pages/index.module.css';
import { authStatus } from 'src/core/services/authentication-service';
import { NotFoundInfo } from 'src/core/ui/not-found-component';

const DocumentDetail: React.FC = () => {
  let navigate = useNavigate();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const { t } = useTranslation();
  const { documentId, collectionId } = useParams();
  const { colorScheme } = useMantineColorScheme();
  const modals = useModals();
  const [activeTab, setActiveTab] = useState("source");
  const store = useMemo(() => container.get(DocumentItemStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const tagsScopedState = useHookstate([] as TagItem[]);
  const propertiesScopedState = useHookstate([] as TagItem[]);
  const [downloadUri, setDownloadUri] = useState<string>();
  const [fileExtension, setFileExtension] = useState<string>();
  const isOwner = authStatus?.user.value?.isOwner;
  const canContribute = isOwner || UserCanContribute(state?.item?.members?.value as MemberItem[]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(`${t("Document detail")}`, 'Documents', <IconFileText size={34} />, `${t("Document detail")} | ${t("Documents")}`, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/documents`, breadcrumbName: t('Documents') },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
    store.setCollection(collectionId as string);
    load(documentId as string).then((item) => {
      if (item) {
        let extension = item.currentSnapshot.contentSource === 'External' ? item.currentSnapshot.fileName.split('.').pop() : 'pdf'
        if (!extension || extension === '') {
          extension = 'pdf';
        }
        setFileExtension(extension);
        store.getDownloadDataUri(documentId as string, extension as string).then(uri => setDownloadUri(uri));
      }
    })
  }, [documentId, collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = async (documentId: string) => {
    if (documentId) {
      return await store.load(documentId);
    }
  };

  useBus(
    '@@ui/DOCUMENT_DETAIL_REFRESH',
    () => load(documentId as string),
    [documentId],
  );

  useEffect(() => {
    if (state.item.value) {
      setHeader(`${state.item.collectionTitle.value}/${state.item.title.value}`, 'Documents', <IconFileText size={34} />, `${state.item.collectionTitle.value}/${state.item.title.value} | ${t("Documents")}`, true, true);
      setRoutes([
        { path: `/admin`, breadcrumbName: t('Home') },
        { path: `/admin/collections`, breadcrumbName: t('Collections') },
        {
          path: `/admin/collections/${collectionId}`, breadcrumbName:
            <Group gap={5} align="center">
              <IconBook2 size={18} />
              <Text size="xs">
                {truncateText(state.item.collectionTitle.value, 60)}
              </Text>
            </Group>
        },
        { path: `/admin/collections/${collectionId}/documents`, breadcrumbName: t('Documents') },
      ]);

      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      const properties = tagsToArray(state.item.currentSnapshot?.properties?.value ?? []);
      propertiesScopedState.set(properties);
    }
  }, [state.item])

  const navigateToEdit = () => {
    navigate(`/admin/collections/${collectionId}/documents/${documentId}/edit`);
  };

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const openTrainModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Train snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to train this item?')}
          <br></br>
          {t('The training process might take a while.')}
        </Text>
      ),
      labels: { confirm: t('Train snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmTrain(snapshotId),
    });

  const onConfirmTrain = async (snapshotId: string) => {
    await store.trainSnapshot(collectionId as string, documentId as string, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      await load(documentId as string);
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
    }
  };

  const openConfirmPublish = () => modals.openConfirmModal({
    title: t("Publish"),
    children: (
      <Text size="sm">
        {t('Are you sure you want to publish this document?')}
      </Text>
    ),
    labels: { confirm: t('Confirm'), cancel: t('Cancel') },
    onConfirm: async () => {
      await store.publish(documentId as string);
      load(documentId as string);
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
      dispatch('@@ui/DOCUMENT_HISTORY_LIST_REFRESH');
      state.errorMessage.value ? showKoNotification() : showOkNotification();
    },
  });

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Delete document')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete document'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(),
    });

  const onConfirmDelete = async () => {
    await store.delete(documentId as string);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      navigate(`/admin/collections/${collectionId}/documents`);
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
    }
  };

  const openRetireModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Retire document')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to retire this document?')}
          <br></br>
          {t('All published snapshots will also be retired')}.
        </Text>
      ),
      labels: { confirm: t('Retire document'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmRetire(),
    });

  const onConfirmRetire = async () => {
    await store.retire(documentId as string);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(documentId as string);
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
      dispatch('@@ui/DOCUMENT_HISTORY_LIST_REFRESH');
    }
  };

  ///*TODO: ONLY DEV*/
  //const goToViewer = async () => {
  //  navigate(`/viewer/${documentId}?sas_key=${await store.generateTokenForDocument(documentId as string)}`);
  //}
  ///*TODO: ONLY DEV*/

  const DocumentTitleRender = state?.item?.value?.currentSnapshot?.contentSource === 'External' && downloadUri
    ?
    <Group align="center" gap={5}>
      <IconFileSymlink />
      <Anchor href={downloadUri} target="_blank">{t("Download file")}</Anchor>
      <Text c="dimmed">{getFileNameFromUri(state.item.currentSnapshot.fileName.value)}</Text>
      {/* TODO: ONLY DEV */}
      {/*<Anchor onClick={goToViewer}>Go to Viewer</Anchor>*/}
      {/* TODO: ONLY DEV */}
    </Group>
    :
    <Group></Group>
    ;

  const notFound = !isBusy && state.status?.value && state.status.value === 404;

  return (
    <ContentWrapper>
      <div style={{ position: 'relative' }}>
        {isBusy ?
          <Stack my="xl" justify='center' align='center'>
            <Loader size="xl" />
            <Text c="dimmed">{t("Please wait")}</Text>
          </Stack>
          :
          <>
            {notFound &&
              <NotFoundInfo
                title={t('No item found')}
                description={t('No item found or you do not have permissions') as string} />
            }
            {state && state.item && state.item.value && !notFound && (
              <>
                {errorMessage && (
                  <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
                    <Text>{formatMessage(errorMessage)}</Text>
                  </Alert>
                )}
                <Grid align="stretch">
                  <Grid.Col span="auto">
                    <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
                      <Tabs.List>
                        <Tabs.Tab value="source" leftSection={<IconFileCode size={20} />}>{t('Source')}</Tabs.Tab>
                        <Tabs.Tab value="passages" leftSection={<IconArticle size={20} />}>{t('Passages')}</Tabs.Tab>
                        <Tabs.Tab value="history" leftSection={<IconHistory size={20} />}>{t('History')}</Tabs.Tab>
                      </Tabs.List>
                    </Tabs>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Group justify='flex-end' gap={10}>
                      <StatusBadge status={state.item.value.currentSnapshot?.status} />
                      <TrainingStatusBadge status={state.item.value.currentSnapshot?.trainingStatus} jobReference={state.item.value.currentSnapshot?.jobReference} />
                      {canContribute &&
                        <TrainButton
                          status={state.item.currentSnapshot?.trainingStatus.value}
                          jobReference={state.item.currentSnapshot?.jobReference.value}
                          onFinished={() => load(documentId as string)}
                          onClick={() => openTrainModal(state.item.value.currentSnapshot.id)}
                        />
                      }
                      {state.item.value.currentSnapshot?.trainingStatus === 'Trained' && state.item.value.currentSnapshot?.status !== 'Published' && canContribute &&
                        <Button
                          loading={isBusy}
                          onClick={openConfirmPublish}
                          leftSection={<IconCloudUpload />}>
                          {t("Publish")}
                        </Button>
                      }
                      {canContribute &&
                        <Menu withinPortal withArrow width={250} shadow="md" position="bottom-end">
                          <Menu.Target>
                            <Tooltip label={t("More options")}>
                              <ActionIcon variant='subtle' color="gray">
                                <IconDotsVertical />
                              </ActionIcon>
                            </Tooltip>
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Label>{t("Document options")}</Menu.Label>
                            <Menu.Item leftSection={<IconEdit size={20} />} onClick={navigateToEdit}>{t("Edit document")}</Menu.Item>
                            <Menu.Divider />
                            <Menu.Label>{t("Danger zone")}</Menu.Label>
                            <Menu.Item color="red" leftSection={<IconCloudOff size={20} />} onClick={openRetireModal}>{t('Retire document')}</Menu.Item>
                            <Menu.Item color="red" leftSection={<IconTrash size={20} />} onClick={openDeleteModal}>{t('Delete document')}</Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      }
                    </Group>
                  </Grid.Col>
                </Grid>
                <Container fluid px={0} py={0} mt="xs">
                  {activeTab === "source" && (
                    <Grid gutter={'xs'}>
                      <Grid.Col span={{ base: 12, lg: 8 }}>
                        <Card withBorder>
                          <>
                            {!state.item.value.currentSnapshot && <Alert icon={<IconAlertCircle size={16} />} title={t("Document misconfiguration!")} color="red">
                              {t('Your source code is corrupt and we cannot process it. Please edit it and correct the problems.')}
                            </Alert>}
                            {state.item.value.currentSnapshot?.contentType === "text/chat" &&
                              <DocumentChatHistory content={state.item.currentSnapshot.content.value} />
                            }
                            {state.item.value.currentSnapshot?.contentSource === 'Internal' && state.item.value.currentSnapshot?.contentType !== "text/chat" &&
                              <ScrollArea style={{ height: 'calc(100svh - 180px)' }} offsetScrollbars>
                                <div data-color-mode={colorScheme}>
                                  <MDEditor.Markdown source={state.item.currentSnapshot.content.value} />
                                </div>
                              </ScrollArea>
                            }
                            {state.item.value.currentSnapshot?.contentSource === 'External' && downloadUri &&
                              <Stack>
                                {DocumentTitleRender}
                                {state.item.currentSnapshot.trainingStatus.value !== 'Trained' && <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Document is not trained yet")} color="yellow">
                                  <Text>{t("Some functionality might not work until you train the document.")}</Text>
                                </Alert>}
                                <ScrollArea style={{ height: 'calc(100svh - 220px)' }} offsetScrollbars>
                                  <FileViewer
                                    key={downloadUri}
                                    fileType={fileExtension}
                                    errorComponent={<div>{t("Cannot preview the document at this moment")}</div>}
                                    filePath={downloadUri} />
                                </ScrollArea>

                              </Stack>
                            }
                          </>
                        </Card>
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, lg: 4 }}>
                        <Stack gap="xs">
                          <CollapsibleCard
                            title={<Text fw={500}>{t('Info')}</Text>}
                            cardKey='document-info-card'
                            icon={<IconInfoSquare />}
                            collapseInfoRender={
                              <Badge variant='light' color="gray">{truncateText(state.item.title.value, 20)}</Badge>
                            }>
                            <Stack align='stretch'>
                              <ListItem title={t("Collection")} icon={<IconBook2 size={20} />} description={state.item.collectionTitle.value} />
                              <ListItem title={t("Title")} icon={<IconFileInfo size={20} />} description={state.item.title.value} />
                              <SimpleGrid cols={{ base: 1, md: 2 }}>
                                <ListItem title={t("Boost")} icon={<IconBolt size={20} />} description={state.item.boost.value.toString()} />
                                {state.item.currentSnapshot?.language.value &&
                                  <ListItem title={t("Language")} icon={<IconLanguage size={20} />} description={state.item.currentSnapshot.language.value} />
                                }
                                <ListItem title={t("Don't advertise")} icon={<IconBellOff size={20} />} description={state.item.dontAdvertise.value ? t('Enabled') as string : t('Disabled') as string} />
                                <ListItem title={t("Split method")} icon={<IconAxe size={20} />} description={state.item.currentSnapshot.splitMethod.value.toString()} />
                                {state?.item?.currentSnapshot?.createdOn &&
                                  <>
                                    <ListItem title={t("Modified on")} icon={<IconClock size={20} />} description={moment(state.item.currentSnapshot.createdOn.value).fromNow()} />
                                    <ListItem title={t("Modified by")} icon={<IconUserCircle size={20} />} description={state.item.currentSnapshot.createdBy.value} />
                                  </>
                                }
                              </SimpleGrid>
                            </Stack>
                          </CollapsibleCard>

                          <CollapsibleCard
                            title={<Text fw={500}>{t('Description')}</Text>}
                            cardKey='document-description-card'
                            icon={<IconNotes />}>
                            <div data-color-mode={colorScheme}>
                              <MDEditor.Markdown source={state.item.description.value ?? t('(No description)')} />
                            </div>
                          </CollapsibleCard>

                          {state.item.audiences?.value?.length > 0 &&
                            <CollapsibleCard
                              title={<Text fw={500}>{t('Audiences')}</Text>}
                              cardKey='document-audiences-card'
                              icon={<IconFriends />}>
                              <Group gap="xs" align="center">
                                {state.item.audiences.value.map((a, index) => (
                                  <Badge size="xs" radius="sm" p="xs" key={index} className={classes.tag}>{a}</Badge>
                                ))}
                              </Group>
                            </CollapsibleCard>
                          }

                          <BootstrapIntentsCard
                            cardKey='document-intents-card'
                            mode="view"
                            state={state.item.currentSnapshot?.bootstrapIntents}
                          />

                          <SuggestionsCard
                            cardKey='document-suggestions-card'
                            mode="view"
                            state={state.item.currentSnapshot?.suggestions}
                          />

                          <TagsCard
                            cardKey='document-tags-card'
                            mode="view"
                            state={tagsScopedState}
                          />

                          <PropertiesCard
                            cardKey='document-properties-card'
                            mode="view"
                            state={propertiesScopedState}
                          />
                        </Stack>
                      </Grid.Col>
                    </Grid>
                  )}
                  {activeTab === "passages" && (
                    <Box pt={5}>
                      <SnapshotPassages
                        documentTitle={DocumentTitleRender}
                        collectionId={collectionId as string}
                        documentId={documentId as string}
                        snapshotId={state.item.currentSnapshot?.id?.value as string} />
                    </Box>
                  )}
                  {activeTab === "history" && (
                    <Box pt={5}>
                      <Stack gap='sm'>
                        <DocumentHistory collectionId={collectionId as string} documentId={documentId as string} canContribute={canContribute} />
                      </Stack>
                    </Box>
                  )}
                </Container>
              </>
            )
            }
          </>
        }
      </div>
    </ContentWrapper>
  );
};

export default DocumentDetail;

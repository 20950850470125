import { Box, Button, Group, Input, NavLink, Popover, SimpleGrid, Stack, rem, Text, Badge } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { IconArrowNarrowRight, IconCalendarMonth, IconClock, IconExclamationCircle } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'src/core/utils/object';

export type PredefinedTimeRanges = '1d' | '7d' | '1m' | '3m' | 'custom';

const TimeRangeSelector: FC<{
  label?: string;
  description?: string;
  value?: PredefinedTimeRanges;
  customValue?: Date[];
  onChange?: (value: PredefinedTimeRanges, from?: Date, to?: Date) => void;
  maxDays?: number;
  onClose?: () => void;
}
> = ({ label, description, value, customValue, onChange, maxDays, onClose }) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [internalValue, setInternalValue] = useState<PredefinedTimeRanges>(value ?? "1d");
  const [internalCustomValue, setInternalCustomValue] = useState<(Date | null)[]>(customValue ?? []);

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (customValue && customValue.length === 2) {
      setInternalCustomValue(customValue);
    }
  }, [customValue]);

  const getTimeRangeText = (value: PredefinedTimeRanges) => {
    switch (value) {
      case '1d':
        return t("Last day");
      case '7d':
        return t("Last 7 days");
      case '1m':
        return t("Last month");
      case '3m':
        return t("Last 3 months");
      case 'custom':
        return t("Custom");
    }
  }

  const getTimeRangeDatesSelected = (value: PredefinedTimeRanges) => {
    const today = dayjs(new Date());
    switch (value) {
      case '1d':
        return [today.add(-1, 'day').toDate(), today.toDate()];
      case '7d':
        return [today.add(-7, 'day').toDate(), today.toDate()];
      case '1m':
        return [today.add(-1, 'month').toDate(), today.toDate()];
      case '3m':
        return [today.add(-3, 'month').toDate(), today.toDate()];
      case 'custom':
        return internalCustomValue;
    }
  }

  const onSelectOption = (value: PredefinedTimeRanges) => {
    setInternalValue(value);
    const ranges = getTimeRangeDatesSelected(value);
    const from = ranges?.length == 2 && ranges[0] ? ranges[0] : undefined;
    const to = ranges?.length == 2 && ranges[1] ? ranges[1] : undefined;
    onChange?.(value, from, to);
  }

  const getOption = (value: PredefinedTimeRanges) => (
    <NavLink
      key={value}
      active={internalValue === value}
      label={getTimeRangeText(value)}
      // leftSection={internalValue === value ? <IconCheck size="1rem" /> : <Box style={{ width: '1rem' }}></Box>}
      onClick={() => onSelectOption(value)}
      variant='light'
    />
  )

  const onChangeCustomDate = (index: number, value: Date | null) => {
    let result = JSON.parse(JSON.stringify(internalCustomValue)) as (Date | null)[];
    result[index] = value;
    setInternalCustomValue(result);
    const from = result?.length == 2 && result[0] ? result[0] : undefined;
    const to = result?.length == 2 && result[1] ? result[1] : undefined;
    if (isValid(from, to)) {
      onChange?.("custom", from, to);
    }
  }

  const onChangeCustomTime = (index: number, value: string) => {
    if (value && value.split(':').length === 2) {
      let result = JSON.parse(JSON.stringify(internalCustomValue)) as (Date | null)[];
      if (result[index]) {
        const time = value.split(':');
        result[index] = dayjs(result[index]).hour(parseInt(time[0])).minute(parseInt(time[1])).toDate();
        setInternalCustomValue(result);
        const from = result?.length == 2 && result[0] ? result[0] : undefined;
        const to = result?.length == 2 && result[1] ? result[1] : undefined;
        if (isValid(from, to)) {
          onChange?.("custom", from, to);
        }
      }
    }
  }

  const isValid = (from?: Date | null, to?: Date | null) => {
    if (!from || !to) return false;
    if (dayjs(from).isAfter(dayjs(to)) || dayjs(to).isBefore(dayjs(from)) || dayjs(from).isSame(dayjs(to))) return false;
    if (maxDays && Math.abs(dayjs(from).diff(dayjs(to), 'day')) > maxDays) return false;

    return true;
  }

  const errorCustomValue = !isValid(internalCustomValue?.[0], internalCustomValue?.[1]);

  return (
    <Input.Wrapper
      label={label}
      description={description}>
      <Popover withArrow shadow="md" position='top-start' opened={opened} onChange={setOpened} onClose={() => onClose?.()}>
        <Popover.Target>
          <Group align='center' gap="xs">
            <Button
              variant='light'
              leftSection={<IconCalendarMonth size={20} />}
              onClick={() => setOpened((o) => !o)}>
              {t("Time range")}: {getTimeRangeText(internalValue)}
            </Button>
            {internalValue === 'custom' &&
              <Badge variant="outline" size='xl'>
                <Group>
                  <Text size='sm'>{internalCustomValue?.[0] ? formatDateTime(internalCustomValue?.[0]) : '-'}</Text>
                  <IconArrowNarrowRight size={20} />
                  <Text size='sm'>{internalCustomValue?.[1] ? formatDateTime(internalCustomValue?.[1]) : '-'}</Text>
                </Group>
              </Badge>
            }
          </Group>
        </Popover.Target>

        <Popover.Dropdown>
          <Group justify='flex-start' align='flex-start'>
            <Box>
              {getOption("1d")}
              {getOption("7d")}
              {getOption("1m")}
              {getOption("3m")}
              {getOption("custom")}
            </Box>
            {internalValue === 'custom' &&
              <Box>
                <SimpleGrid
                  cols={{ base: 1, md: 2 }}
                  spacing="md">
                  <Stack align="center" justify="space-between">
                    <DatePicker
                      defaultValue={dayjs(new Date()).add(-1, 'day').toDate()}
                      defaultDate={internalCustomValue?.[0] ? internalCustomValue[0] : undefined}
                      value={internalCustomValue[0]}
                      onChange={(value) => onChangeCustomDate(0, value)}
                      maxDate={new Date()}
                    />
                    <TimeInput
                      value={internalCustomValue[0] ? dayjs(internalCustomValue[0]).format('HH:mm') : '12:00'}
                      onChange={(event) => onChangeCustomTime(0, event.currentTarget.value)}
                      leftSection={<IconClock style={{ width: rem(16), height: rem(16) }} />}
                    />
                  </Stack>
                  <Stack align="center" justify="space-between">
                    <DatePicker
                      defaultValue={new Date()}
                      defaultDate={internalCustomValue?.[1] ? internalCustomValue[1] : undefined}
                      value={internalCustomValue[1]}
                      onChange={(value) => onChangeCustomDate(1, value)}
                      maxDate={new Date()}
                    />
                    <TimeInput
                      value={internalCustomValue[1] ? dayjs(internalCustomValue[1]).format('HH:mm') : '12:00'}
                      onChange={(event) => onChangeCustomTime(1, event.currentTarget.value)}
                      leftSection={<IconClock style={{ width: rem(16), height: rem(16) }} />}
                    />
                  </Stack>
                </SimpleGrid>
                {errorCustomValue &&
                  <Group gap={5} align='center' justify='center' mt="sm">
                    <IconExclamationCircle color='red' size={20} />
                    <Text ta="center" c="red.6">{t("Invalid dates")} {t("Please review the selected dates.")}</Text>
                  </Group>
                }
              </Box>
            }
          </Group>
        </Popover.Dropdown>
      </Popover>
    </Input.Wrapper>
  );
};

export default TimeRangeSelector;

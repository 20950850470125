import React, { FC } from 'react'; import {
  Routes,
  Route
} from "react-router-dom";

import AccessDeniedPage from './access-denied'

const LoginIndex: FC = () => {
  return <Routes>
      <Route index element={<AccessDeniedPage />} />
    <Route path="accessdenied" element={<AccessDeniedPage />} />
  </Routes>
}

export default LoginIndex

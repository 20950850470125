import { FC, useContext } from "react";
import { StepNodeProps } from "./steps";
import { useHookstate } from "@hookstate/core";
import { Group, Stack, ActionIcon, Textarea, Text, Tooltip, Fieldset, Code, Select, TextInput, Divider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconWindowMaximize } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import SkillDebugJinjaModal from "../skill-debug-jinja-modal";
import StepWrapper from "./step-wrapper";
import { SkillContentStepDataType } from "src/stores/skills";

const StepAssignNode: FC<StepNodeProps> = ({ id, data }) => {
  const { step, edit, index, rightToolbar, updateNode } = data;
  const scopedState = useHookstate(step.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const [opened, { open, close }] = useDisclosure(false);

  // useEffect(() => {
  //   const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStepAction;
  //   const newAction = { ...step.action, ...changes };
  //   const newStep = { ...step, action: newAction };
  //   const newData = { index: index, step: newStep } as StepDataProps;
  //   updateNode(id, newData);
  // }, [JSON.stringify(scopedState.value)])

  const resume = (
    <Stack gap="xs">
      <span>{t("Assign value")} <Code style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{scopedState?.body?.value}</Code>.</span>

      <Divider />
      <span>{t("Save in")}&nbsp;
        {scopedState?.target?.value === 'Output' &&
          <span><Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState?.target?.value}</Code> {t("as")} <Text span fs="italic">{scopedState?.targetFormat?.value}</Text></span>
        }
        {scopedState?.target?.value === 'Entity' &&
          <span><Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState?.target?.value}</Code> {t("as")} <Text span fs="italic">{scopedState?.dataType?.value}</Text> {t("with name")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState.entity.value}</Code></span>
        }
      </span>
    </Stack>
  );

  if (!scopedState?.value) return (<></>);

  return (
    <StepWrapper
      id={id}
      index={index}
      step={step}
      updateNode={updateNode}
      edit={edit}
      rightToolbar={rightToolbar}
      resume={resume}>
      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Value")}</Text>
          </Group>
        }>
        <Stack gap="xs">
          {edit ?
            <Stack gap="xs">
              <Group align="flex-start" key={index}>
                <Textarea
                  autosize
                  minRows={3}
                  maxRows={10}
                  required
                  style={{ flex: 1, minWidth: 400 }}
                  value={scopedState.body.value}
                  onChange={(event) => { scopedState.body.set(event.target.value); setIsDirty(true); }}
                />
                <Stack gap={5}>
                  <Tooltip label={t("More options")}>
                    <ActionIcon variant='subtle' color="gray" onClick={open}>
                      <IconWindowMaximize size={20} />
                    </ActionIcon>
                  </Tooltip>
                </Stack>
              </Group>
              <SkillDebugJinjaModal value={scopedState.body.value} open={opened} onClose={close} onSave={(value) => scopedState.body.set(value)} />
            </Stack>
            :
            <Stack gap="xs">
              <Code style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{scopedState.body.value}</Code>
            </Stack>
          }
        </Stack>
      </Fieldset>

      <Fieldset p="xs" variant="filled" legend={t("Save in")}>
        {edit ?
          <Group align="center">
            <Select
              w={100}
              allowDeselect={false}
              defaultValue='Entity'
              data={[
                { value: 'Output', label: t('Output') },
                { value: 'Entity', label: t('Entity') }
              ]}
              value={scopedState.target.value}
              onChange={(value) => { scopedState.target.set(value as any); setIsDirty(true); }}
            />
            {scopedState.target.value && scopedState.target.value === 'Output' &&
              <Group gap={10}>
                <Text span>{t("as")}{' '}</Text>
                <Select
                  w={200}
                  allowDeselect={false}
                  defaultValue='String'
                  data={[
                    { value: 'String', label: t('Plain text (String)') as string },
                    { value: 'Json', label: t('JSON') as string },
                    // { value: 'SearchResult', label: t('Search result array') as string }
                  ]}
                  value={scopedState.targetFormat.value}
                  onChange={(value) => { scopedState.targetFormat.set(value as any); setIsDirty(true); }}
                />
              </Group>
            }

            {(!scopedState.target.value || scopedState.target.value === 'Entity') && <Group gap={10}>
              <Text size="sm">{t("as")}</Text>
              <Select
                allowDeselect={false}
                data={[
                  { value: 'String', label: t('String') as SkillContentStepDataType },
                  { value: 'Integer', label: t('Integer') as SkillContentStepDataType },
                  { value: 'Number', label: t('Number') as SkillContentStepDataType },
                  { value: 'Date', label: t('Date') as SkillContentStepDataType }
                ]}
                value={scopedState.dataType.value}
                onChange={(value) => { scopedState.dataType.set(value as SkillContentStepDataType); setIsDirty(true); }}
              />
            </Group>}

            {scopedState.target.value === 'Entity' &&
              <Group>
                <Text size="sm">{t("with name")}</Text>
                <TextInput
                  required
                  style={{ flex: 1 }}
                  value={scopedState.entity.value}
                  onChange={(event) => { scopedState.entity.set(event.target.value); setIsDirty(true); }}
                />
              </Group>}
          </Group>
          :
          <div>
            {scopedState.target.value === 'Output' &&
              <span><Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState.target.value}</Code> {t("as")} <Text span fs="italic">{scopedState.targetFormat.value}</Text></span>
            }
            {scopedState.target.value === 'Entity' &&
              <span><Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState.target.value}</Code> {t("as")} <Text span fs="italic">{scopedState.dataType.value}</Text> {t("with name")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState.entity.value}</Code></span>
            }
          </div>
        }
      </Fieldset>
    </StepWrapper>
  );
};

export default StepAssignNode;
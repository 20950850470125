import { useHookstate } from "@hookstate/core";
import { ActionIcon, Card, Code, Drawer, Fieldset, Group, Select, Stack, Text, TextInput, ThemeIcon, Tooltip } from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SkillContentStep, SkillContentStepTurnType, SkillContentStepType } from "src/stores/skills";
import SkillContentStepCondition from "./step-condition";
import classes from 'src/pages/index.module.css';
import { IconApi, IconArrowNarrowDown, IconBrain, IconClock, IconDoorExit, IconEdit, IconEqualDouble, IconExternalLink, IconEye, IconMessageCircle, IconMessageForward, IconQuestionMark, IconStepInto } from "@tabler/icons-react";
import AppContext from "src/services/app-context";
import { Handle, Position } from "reactflow";
import { normalizeName, truncateText } from "src/core/utils/object";
import { StepDataProps } from "./steps";

const StepWrapper: FC<{
  id: string;
  children?: any;
  step: SkillContentStep;
  updateNode?: (id: string, data: StepDataProps) => void;
  index: number;
  edit?: boolean;
  rightToolbar?: React.ReactNode;
  resume?: React.ReactNode;
  hideHeader?: boolean;
  hideTarget?: boolean;
  hideSource?: boolean;
}> = ({ id, children, step, updateNode, index, edit = false, rightToolbar, resume, hideHeader = false, hideSource = false, hideTarget = false }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(step);
  const [opened, setOpened] = useState<boolean>(false);
  const { setIsDirty } = useContext(AppContext);
  const [hasCondition, setHasCondition] = useState<boolean>(scopedState?.value?.toString().length > 0);

  // useEffect(() => {
  //   if (updateNode) {
  //     const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStep;
  //     const newStep = { ...step, ...changes };
  //     const newData = { index: index, step: newStep } as StepDataProps;
  //     updateNode(id, newData);
  //   }
  // }, [JSON.stringify(scopedState.value)])

  useEffect(() => {
    setHasCondition(scopedState?.condition?.value?.toString().length > 0);
  }, [scopedState?.condition?.value])

  const getTurnIcon = (turn: SkillContentStepTurnType) => {
    switch (turn) {
      case 'Continue':
        return <IconArrowNarrowDown size={20} />;
      case 'Wait':
        return <IconClock size={20} />;
      case 'End':
        return <IconDoorExit size={20} />;
      default:
        return;
    }
  }

  const getStepIcon = (type: SkillContentStepType, withTooltip: boolean = false) => {
    let result = <></>;
    switch (type) {
      case 'Say':
        result = <IconMessageCircle size={24} />;
        break;
      case 'Ask':
        result = <IconQuestionMark size={24} />;
        break;
      case 'CallApi':
        result = <IconApi size={24} />;
        break;
      case 'CallSkill':
        result = <IconExternalLink size={24} />;
        break;
      case 'GoTo':
        result = <IconStepInto size={24} />;
        break;
      case 'Assign':
        result = <IconEqualDouble size={24} />;
        break;
      case 'CallLlm':
        result = <IconBrain size={24} />;
        break;
      case 'Event':
        result = <IconMessageForward size={24} />;
        break;
      default:
        return;
    }

    if (withTooltip) {
      result = <Tooltip label={t(type)}>
        {result}
      </Tooltip>
    }

    return result;
  }

  const onCloseDrawner = () => {
    setOpened(false);
    if (edit) {
      if (updateNode) {
        const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStep;
        const newStep = { ...step, ...changes };
        const newData = { index: index, step: newStep } as StepDataProps;
        updateNode(id, newData);
      }
    }
  }

  return (
    <div>
      {!hideTarget && <Handle type="target" position={Position.Top} />}

      {children && scopedState &&
        <Drawer opened={opened} onClose={onCloseDrawner}
          title={<Text>{edit ? t("Edit") : t("View details")}: {scopedState.step.value}</Text>}
          closeOnEscape={true}
          closeOnClickOutside={true}
          position="right"
          size="xl">
          <Stack>

            <Fieldset p="xs" variant="filled"
              legend={
                <Group align="center" gap={5}>
                  <Text size="sm">{t("Title")}</Text>
                </Group>
              }>
              <TextInput
                required={edit}
                placeholder={t("Step name") as string}
                style={{ flex: 1 }}
                value={scopedState.step.value}
                onChange={(event) => { scopedState.step.set(event.target.value); setIsDirty(true); }}
                onBlur={() => scopedState.step.set((c) => normalizeName(c, true))}
                variant={!edit ? "unstyled" : "default"}
                readOnly={!edit}
              />
            </Fieldset>

            <SkillContentStepCondition
              key={`step-condition-${index}`}
              edit={edit}
              state={scopedState.condition}
            />

            {children}

            <Fieldset legend={<Text size="sm">{t("Next step")}</Text>} variant="filled" p="xs">
              {edit ?
                <Group align="center">
                  <Select
                    style={{ flex: 1 }}
                    allowDeselect={false}
                    data={[
                      { value: 'Continue', label: t('Continue') as SkillContentStepTurnType },
                      { value: 'End', label: t('End') as SkillContentStepTurnType },
                      { value: 'Wait', label: t('Wait') as SkillContentStepTurnType }
                    ]}
                    leftSection={getTurnIcon(scopedState.turn.value)}
                    value={scopedState.turn.value}
                    onChange={(value) => { scopedState.turn.set(value as SkillContentStepTurnType); setIsDirty(true); }}
                  />
                </Group>
                :
                <>
                  {scopedState.turn.value === 'Continue' &&
                    <Group align="center" gap="xs"><IconArrowNarrowDown size={20} /><Text fs="italic">{t("Continue to next step")}</Text></Group>
                  }
                  {scopedState.turn.value === 'End' &&
                    <Group align="center" gap="xs"><IconDoorExit size={20} /><Text fs="italic">{t("End")}</Text></Group>
                  }
                  {scopedState.turn.value === 'Wait' &&
                    <Group align="center" gap="xs"><IconClock size={20} /><Text fs="italic">{t("Wait")}</Text></Group>
                  }
                </>
              }
            </Fieldset>
          </Stack>
        </Drawer>
      }

      <Card
        withBorder
        shadow="xs"
        radius="sm"
        className={classes.stepCard}
        style={{ width: 600 }}>
        {!hideHeader &&
          <Card.Section withBorder inheritPadding p={0} style={{ backgroundColor: "var(--mantine-primary-color-light)" }}>
            <Group justify="space-between" align="center">
              <Group align="center" gap="xs">
                <ThemeIcon size="lg" radius="xs">
                  {getStepIcon(scopedState.action.type.value, true)}
                </ThemeIcon>
                {/* <Text fw={500}>{t(scopedState.action.type.value)}</Text> */}
                <Text fw={500}>{t(scopedState.step.value)}</Text>
              </Group>
              <Group justify="flex-end" gap={5} align="center" className="nodrag">
                {children &&
                  <Tooltip label={edit ? t("Edit step") : t("View details")}>
                    <ActionIcon onClick={() => setOpened(true)} variant='subtle' color="gray">
                      {edit ? <IconEdit size={20} /> : <IconEye size={20} />}
                    </ActionIcon>
                  </Tooltip>
                }
                {rightToolbar}
              </Group>
            </Group>
          </Card.Section>
        }

        {hasCondition &&
          <Card.Section withBorder inheritPadding py={5}>
            <div>
              <span>{t("When")} </span>
              <Code title={scopedState.condition?.value?.toString()} style={{ fontSize: 16 }} color="var(--mantine-color-teal-light)">
                {truncateText(scopedState.condition?.value?.toString())}
              </Code>
            </div>
          </Card.Section>
        }

        <Card.Section withBorder inheritPadding pt="xs" pb="xs">
          {resume}
        </Card.Section>

        {/* <Card.Section inheritPadding py={5}>
          {scopedState.turn.value === 'Continue' &&
            <Group align="center" gap="xs"><IconArrowNarrowDown size={20} /><Text fs="italic">{t("Continue to next step")}</Text></Group>
          }
          {scopedState.turn.value === 'End' &&
            <Group align="center" gap="xs"><IconDoorExit size={20} /><Text fs="italic">{t("End")}</Text></Group>
          }
          {scopedState.turn.value === 'Wait' &&
            <Group align="center" gap="xs"><IconClock size={20} /><Text fs="italic">{t("Wait")}</Text></Group>
          }
        </Card.Section> */}
      </Card>

      {!hideSource && <Handle type="source" position={Position.Bottom} />}
    </div>
  );
};

export default StepWrapper;
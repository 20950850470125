import { State, none, useHookstate } from "@hookstate/core";
import { Group, Text, Badge, ScrollArea, Button, Table, ActionIcon, PasswordInput, CopyButton, Tooltip, ComboboxItem, Stack, Center, TextInput, Modal } from "@mantine/core";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import { IconCheck as Check, IconCopy as Copy, IconKey as Key, IconTrash as Trash } from "@tabler/icons-react";
import { v4 as uuidv4 } from 'uuid';
import { container } from "src/inversify.config";
import { AppConfiguration } from "src/core/services/authentication-service";
import { SkillItem } from "src/stores/skills";
import { BasicMultiselect } from "src/components/basic-multiselect";
import classes from 'src/pages/index.module.css';
import { LogApiKeyItem } from "src/stores/bots";

const generateApiKey = () => {
  return uuidv4().replace(/-/g, "") + uuidv4().replace(/-/g, "");
}

const SkillLogApiKeysCopyModal: FC<{
  opened: boolean;
  onClose: () => void;
  templateUri: string;
  apiKey: string;
}> = ({ opened, onClose, templateUri, apiKey }) => {
  const { t } = useTranslation();
  const intentsUri = templateUri.replace("{type}", "intents").replace("{apiKey}", apiKey);
  const feedbackUri = templateUri.replace("{type}", "feedback").replace("{apiKey}", apiKey);

  return (
    <Modal opened={opened} onClose={onClose} title={t("Select the report you want to copy")} centered size="70%">
      <Stack>
        <Group align="center">
          <Text w={150}>{t("Intents")}:</Text>
          <TextInput
            style={{ flex: 1 }}
            readOnly
            value={intentsUri}
          />
          <CopyButton value={intentsUri} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? t('Copied') : t('Copy')} withArrow position="right">
                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy} variant='subtle'>
                  {copied ? <Check /> : <Copy />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
        <Group align="center">
          <Text w={150}>{t("Feedback")}:</Text>
          <TextInput
            style={{ flex: 1 }}
            readOnly
            value={feedbackUri}
          />
          <CopyButton value={feedbackUri} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? t('Copied') : t('Copy')} withArrow position="right">
                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy} variant='subtle'>
                  {copied ? <Check /> : <Copy />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
      </Stack>
    </Modal>
  )
}

const SkillLogApiKeysItem: FC<{
  state: State<SkillItem>;
  apiKey: string;
  onShowCopyUrlModal: (apiKey: string) => void;
}> = ({ state, apiKey, onShowCopyUrlModal }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const getAudiencesData = (audiences: string[]) => {
    let data = [] as ComboboxItem[];
    if (audiences?.length > 0) {
      data = audiences.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  const item = JSON.parse(JSON.stringify(scopedState.value.logApiKeys[apiKey])) as LogApiKeyItem;

  return (
    <Group mt="xs" align="flex-start" grow>
      {item && item.audiences &&
        <BasicMultiselect
          data={getAudiencesData(item.audiences as string[])}
          value={item.audiences as string[]}
          onChange={(value) => { scopedState.logApiKeys[apiKey].audiences.set(value); setIsDirty(true); }}
          placeholder={t("Select audiences") as string}
          creatable
        />
      }
      <Group align="center">
        <PasswordInput
          style={{ flex: 1 }}
          readOnly
          value={apiKey ?? ""}
        />

        <Tooltip label={t('Copy url')} withArrow position="right">
          <ActionIcon onClick={() => onShowCopyUrlModal(apiKey)} variant='subtle' color="gray">
            <Copy />
          </ActionIcon>
        </Tooltip>

        <ActionIcon
          color="red"
          variant='subtle'
          onClick={() => { scopedState.logApiKeys[apiKey].set(none); setIsDirty(true); }}>
          <Trash />
        </ActionIcon>
      </Group>
    </Group>
  );
}

const SkillLogApiKeys: FC<{
  state: State<SkillItem>;
  edit?: boolean;
}> = ({ state, edit }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;
  const templateUri = `${baseUri}/api/skills/${state.id.value}/logs/{type}/{apiKey}`;
  const [showCopyUrlModal, setShowCopyUrlModal] = useState(false);
  const [apiKeySelected, setApiKeySelected] = useState<string | undefined>(undefined);

  const onAddLogApikey = () => {
    const newAPIkey = generateApiKey();
    if (scopedState.logApiKeys) {
      scopedState.logApiKeys.merge({ [newAPIkey]: { audiences: [] } });
    }
    else {
      scopedState.merge({ logApiKeys: { [newAPIkey]: { audiences: [] } } });
    }
    setIsDirty(true);
  }

  const onShowCopyUrlModal = (apiKey: string) => {
    setApiKeySelected(apiKey);
    setShowCopyUrlModal(true);
  }

  return (
    <Stack>
      <Group align="center">
        <Text w={90}>{t("Report URL")}:</Text>
        <TextInput
          style={{ flex: 1 }}
          readOnly
          value={templateUri}
        />
      </Group>
      {edit ?
        <Stack>
          <div style={{ position: 'relative' }}>
            <ScrollArea style={{ minHeight: 100, height: 'calc(100svh - 650px)' }} offsetScrollbars>
              {scopedState?.logApiKeys?.value && Object.keys(scopedState.logApiKeys.value).map((logApiKey, index) => (
                <SkillLogApiKeysItem
                  key={`${index}-{skilllogapikey-item}`}
                  state={scopedState}
                  apiKey={logApiKey}
                  onShowCopyUrlModal={onShowCopyUrlModal}
                />
              ))}
            </ScrollArea>
          </div>
          <Group justify="center">
            <Button
              variant='default'
              size='sm'
              onClick={onAddLogApikey}
              leftSection={<Key />}>
              {t("Add item")}
            </Button>
          </Group>
        </Stack>
        :
        <>
          {!scopedState?.logApiKeys?.value &&
            <Center>
              <Text c="dimmed" size="sm">{t('No API keys found')}</Text>
            </Center>
          }
          {scopedState?.logApiKeys?.value &&
            <>
              <Table striped highlightOnHover>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th style={{ width: '50%' }}>{t('Audiences')}</Table.Th>
                    <Table.Th style={{ width: '50%' }}>{t('API key')}</Table.Th>
                  </Table.Tr>
                </Table.Thead>
              </Table>
              <div style={{ position: 'relative' }}>
                <ScrollArea style={{ minHeight: 150, height: 'calc(100svh - 630px)' }} offsetScrollbars>
                  <Table striped highlightOnHover>
                    <Table.Tbody>
                      {Object.keys(scopedState.logApiKeys.value).map((logApiKey, index) => (
                        <Table.Tr key={`${index}-${logApiKey}`}>
                          <Table.Td style={{ width: '50%' }}>
                            <Group gap="xs" align="center">
                              {scopedState.logApiKeys.value[logApiKey].audiences.map((audience, index) => (
                                <Badge size="xs" radius="sm" p="xs" key={index} className={classes.tag}>{audience}</Badge>
                              ))}
                            </Group>
                          </Table.Td>
                          <Table.Td style={{ width: '50%' }}>
                            <Group align="center">
                              <PasswordInput
                                style={{ flex: 1 }}
                                variant="unstyled"
                                readOnly
                                value={logApiKey ?? ""}
                              />
                              <Tooltip label={t('Copy url')} withArrow position="right">
                                <ActionIcon onClick={() => onShowCopyUrlModal(logApiKey)} variant='subtle' color="gray">
                                  <Copy />
                                </ActionIcon>
                              </Tooltip>
                            </Group>
                          </Table.Td>
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  </Table>
                </ScrollArea>
              </div>
            </>
          }
        </>
      }

      {apiKeySelected &&
        <SkillLogApiKeysCopyModal
          apiKey={apiKeySelected}
          opened={showCopyUrlModal}
          templateUri={templateUri}
          onClose={() => { setShowCopyUrlModal(false); setApiKeySelected(undefined) }}
        />
      }
    </Stack>
  );
};

export default SkillLogApiKeys;
import { FC, useEffect, useState } from 'react';
import { ActionIcon, Button, Center, Group, MantineSize, Menu, Popover, Stack, Text, Tooltip } from '@mantine/core';
import { AppConfiguration } from 'src/core/services/authentication-service';
import { container } from 'src/inversify.config';
import { getLanguageIcon, getLanguageText } from './language-selector';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconX } from '@tabler/icons-react';
import { AsEnumerable } from 'linq-es5';

export const LanguageSegmentedControl: FC<{
  data?: string[];
  language: string;
  onChange?: (lang: string) => void;
  editable?: boolean;
  onDelete?: (lang: string) => void;
  onAdd?: (lang: string) => void;
  className?: string;
  size?: MantineSize;
  compact?: boolean;
}> = ({ data, language, onChange, editable, onDelete, onAdd, className, size = "xs", compact = false }) => {
  const { t } = useTranslation();
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(data ?? defaultAllowedLanguages);

  useEffect(() => {
    setAllowedLanguages(data ?? [defaultAllowedLanguages[0]]);
  }, [data])

  const onChangeLanguage = (lang: string) => {
    onChange?.(lang);
  }

  const onAddLanguage = (lang: string) => {
    onAdd?.(lang);
    onChange?.(lang);
  }

  return (
    <Group align='center' gap={5}>
      <Button.Group className={className}>
        {AsEnumerable(allowedLanguages).OrderBy(l => l).ToArray().map(lang =>
          <Button key={lang} variant={lang === language ? "outline" : "default"} size={compact ? `compact-${size}` : size} onClick={() => onChangeLanguage(lang)}>
            <Center style={{ gap: 5 }}>
              <div style={{ paddingTop: 3 }}>
                {getLanguageIcon(lang, 16, 0.8)}
              </div>
              <span>{lang.toUpperCase()}</span>

              {editable && onDelete && allowedLanguages.length > 1 &&
                <Popover position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <ActionIcon variant='subtle' color="gray" size={size} className={className}><IconX /></ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown bg="var(--mantine-color-body)">
                    <Stack>
                      <div>
                        <Text size="sm">{t('Are you sure you want to delete this item?')}: <Text size="sm" span inherit fw={500}>{lang.toUpperCase()}</Text></Text>
                        <Text size="sm">{t('All associated data will be deleted')}</Text>
                      </div>
                      <Group justify="flex-end">
                        <Button
                          size='compact-sm'
                          color="red"
                          onClick={() => onDelete?.(lang)}>
                          {t("Delete")}
                        </Button>
                      </Group>
                    </Stack>
                  </Popover.Dropdown>
                </Popover>
              }
            </Center>
          </Button>
        )}
      </Button.Group>

      {editable && onAdd && allowedLanguages.length < defaultAllowedLanguages.length ?
        <Menu shadow="md" width={200} withArrow withinPortal={false} closeOnClickOutside closeOnItemClick>
          <Menu.Target>
            <Tooltip label={t("Add language")}>
              <ActionIcon variant='subtle' color="gray" size={`input-${size}`} className={className}><IconPlus /></ActionIcon>
            </Tooltip>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>{t("Select language to add")}</Menu.Label>
            {AsEnumerable(defaultAllowedLanguages).Where(d => !AsEnumerable(allowedLanguages).Contains(d)).ToArray().map((lang) =>
              <Menu.Item closeMenuOnClick key={lang} onClick={() => onAddLanguage(lang)} leftSection={getLanguageIcon(lang, 16, 0.8)}>{getLanguageText(lang, t)}</Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
        : undefined
      }
    </Group>
  );
};

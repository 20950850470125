import { Group, ActionIcon, Text } from "@mantine/core";
import { FileWithPath, Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconFileInfo, IconTrash } from "@tabler/icons-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatBytes } from "src/core/utils/object";
import { allowedMimeTypes } from "../../../stores/bots";

const DocumentInputFileZone: FC<{ onSave: (file: FileWithPath | undefined) => void }> = ({ onSave }) => {
  const [importFile, setImportFile] = useState<FileWithPath>();
  const { t } = useTranslation();
  const [rejectFile, setRejectFile] = useState<string | undefined>(undefined);

  const onDrop = async (files: FileWithPath[]) => {
    if (files && files.length > 0) {
      const file = files[0];
      setImportFile(file);
      setRejectFile(undefined);
      onSave(file);
    }
  }

  const onDelete = () => {
    setImportFile(undefined);
    onSave(undefined);
  }

  return (
    <>
      {importFile &&
        <Group>
          <IconFileInfo />
          <Text>{importFile.name}</Text>
          <Text c="dimmed">({formatBytes(importFile.size)})</Text>
          <ActionIcon color="red" onClick={onDelete} variant='subtle'>
            <IconTrash />
          </ActionIcon>
        </Group>
      }
      {!importFile &&
        <Dropzone onDrop={onDrop}
          onReject={(files) => { console.log('rejected files', files); setRejectFile(files[0].errors[0].message) }}
          maxSize={50 * 1024 ** 2}
          accept={allowedMimeTypes}
          multiple={false}>
          <Group justify="center" gap="md" style={{ pointerEvents: 'none' }}>
            <div>
              <Text size="lg" inline ta="center">
                {t("Drag file here or click to select file")}
              </Text>
              <Text size="sm" c="dimmed" inline mt={7} ta="center">
                {t("Attach PDF or Office file without exceeding")} 50 MB
              </Text>
              {rejectFile &&
                <Text size="sm" c="red" inline mt={7}>{t("The file was rejected")}: {rejectFile}</Text>
              }
            </div>
          </Group>
        </Dropzone>
      }
    </>
  );
}

export default DocumentInputFileZone;
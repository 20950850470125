import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SideBarWrapper from 'src/components/sidebar-wrapper';
import DocumentList from './document-list';
import CreateDocumentWizard from './create-document-wizard';

const DocumentSideMenu: FC<{}> = ({ }) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = React.useState(true);
  const { collectionId } = useParams();
  const [collectionSelected, setCollectionSelected] = React.useState<string>(collectionId || '');
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  return (
    <SideBarWrapper
      title={t('Documents')}
      showFiltersIcon
      showFiltersValue={showFilters}
      onChangeShowFiltersValue={(value: boolean) => setShowFilters(value)}
      showNewIcon
      onNewItemClick={() => setShowCreateModal(true)}
    >
      <DocumentList showFilters={showFilters} setCollection={setCollectionSelected} />
      {showCreateModal && <CreateDocumentWizard opened={showCreateModal} onClose={() => setShowCreateModal(false)} collectionId={collectionSelected} />}
    </SideBarWrapper>
  );
};

export default DocumentSideMenu;

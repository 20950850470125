import { Input, Text, Stack, Flex, Switch, ScrollArea } from "@mantine/core";
import { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BotBasicInfoItem, BotContentUserPreferences, UserPreferences } from "src/stores/bots";
import TemperatureSelector from "../temperature-selector";
import { LanguageSeletor } from "../language-selector";
import { truncateText } from "src/core/utils/object";
import HeaderContext from "src/services/header-context";
import { AsEnumerable } from "linq-es5";
import { chatPreferencesGlobalState } from "./chat-utils";
import { useHookstate } from "@hookstate/core";

const ChatUserPreferences: FC<{
  botInfo: BotBasicInfoItem;
  isDebug?: boolean;
}> = ({ botInfo, isDebug = false }) => {
  const { t } = useTranslation();
  const { header } = useContext(HeaderContext);
  const [botUserPreferences, setBotUserPreferences] = useState<BotContentUserPreferences>({} as BotContentUserPreferences);
  const chatPreferences = useHookstate(chatPreferencesGlobalState);

  useEffect(() => {
    if (botInfo) {
      const botUserPreferences = JSON.parse(botInfo.userPreferences) as BotContentUserPreferences;
      setBotUserPreferences(botUserPreferences);
    }
  }, [JSON.stringify(botInfo)])

  const onChangeTemperature = (value: string) => {
    if (botUserPreferences?.allowUserChangeTemperature || isDebug) {
      let newValues = JSON.parse(JSON.stringify(chatPreferences.value)) as UserPreferences;
      newValues.temperature = parseFloat(value);
      chatPreferences.set(newValues);
    }
  }

  const onChangeSearchSkill = (skillId: string, checked: boolean) => {
    if (botUserPreferences?.allowUserChangeSearchSkills || isDebug) {
      if (checked) {
        const skills = [...chatPreferences.value.searchSkills, skillId];
        onChangeSearchSkills(skills);
      }
      else {
        let skills = [...chatPreferences.value.searchSkills];
        const index = skills.indexOf(skillId);
        if (index >= 0) {
          skills.splice(index, 1);
          onChangeSearchSkills(skills);
        }
      }
    }
  }

  const onChangeSearchSkills = (value: string[]) => {
    if (botUserPreferences?.allowUserChangeSearchSkills || isDebug) {
      let newValues = JSON.parse(JSON.stringify(chatPreferences.value)) as UserPreferences;
      newValues.searchSkills = value;
      chatPreferences.set(newValues);
    }
  }

  const onChangeSearchLanguages = (value: string[]) => {
    if (botUserPreferences?.allowUserChangeSearchLanguages || isDebug) {
      let newValues = JSON.parse(JSON.stringify(chatPreferences.value)) as UserPreferences;
      newValues.searchLanguages = value;
      chatPreferences.set(newValues);
    }
  }

  const onChangeEnableOCRInDocuments = (value: boolean) => {
    if (botUserPreferences?.allowEnableOCRInDocuments || isDebug) {
      let newValues = JSON.parse(JSON.stringify(chatPreferences.value)) as UserPreferences;
      newValues.enableOCRInDocuments = value;
      chatPreferences.set(newValues);
    }
  }


  return (
    <>
      {botUserPreferences && chatPreferences.value ?
        <Stack>
          <Input.Wrapper label={t("Temperature")} styles={{ label: { paddingBottom: "var(--mantine-spacing-xs)" } }}>
            <TemperatureSelector
              fullWidth
              size="xs"
              value={chatPreferences.value.temperature?.toString()}
              onChange={onChangeTemperature}
              edit={botUserPreferences.allowUserChangeTemperature || isDebug}
              disabled={!botUserPreferences.allowUserChangeTemperature && !isDebug}
            />
          </Input.Wrapper>

          <Input.Wrapper label={t("Search languages")} styles={{ label: { paddingBottom: "var(--mantine-spacing-xs)" } }}>
            <LanguageSeletor
              multiselect
              allowedLanguages={botUserPreferences.searchLanguages}
              value={chatPreferences.value.searchLanguages as string[]}
              onChange={(value) => onChangeSearchLanguages(value as string[])}
              readOnly={!botUserPreferences.allowUserChangeSearchLanguages && !isDebug}
              placeholder={t("Nothing here") as string}
            />
          </Input.Wrapper>

          <Switch
            label={t("Enable OCR in documents")}
            checked={chatPreferences.value.enableOCRInDocuments}
            readOnly={!botUserPreferences.allowEnableOCRInDocuments && !isDebug}
            styles={{ track: !botUserPreferences.allowEnableOCRInDocuments && !isDebug ? { cursor: 'not-allowed' } : undefined }}
            onChange={e => onChangeEnableOCRInDocuments(e.target.checked)} />

          {botInfo?.skills && botInfo?.skills.length > 0 && botUserPreferences && botUserPreferences.searchSkills?.length > 0 &&
            <Input.Wrapper label={t("Search sources")} styles={{ label: { paddingBottom: "var(--mantine-spacing-xs)" } }}>
              <Stack>
                <ScrollArea.Autosize mah={300} w="100%" mx="auto" offsetScrollbars>
                  {botUserPreferences.searchSkills.map((skillSearch) => {
                    const skillInfo = AsEnumerable(botInfo.skills).FirstOrDefault(x => x.id === skillSearch);
                    const checked = AsEnumerable(chatPreferences.value.searchSkills).Contains(skillSearch);
                    return (
                      <Flex key={skillSearch} gap="xs" justify="flex-start" align="flex-start" direction="row">
                        <Switch pt={3}
                          checked={checked}
                          readOnly={!botUserPreferences.allowUserChangeSearchSkills && !isDebug}
                          styles={{ track: !botUserPreferences.allowUserChangeSearchSkills && !isDebug ? { cursor: 'not-allowed' } : undefined }}
                          onChange={e => onChangeSearchSkill(skillSearch, e.target.checked)} />

                        <Stack gap={5} align="flex-start" justify="flex-start">
                          <Text fw={500}>{skillInfo.title ?? skillSearch}</Text>
                          {skillInfo?.description &&
                            <Text c="dimmed" title={skillInfo.description[header.language]}>{truncateText(skillInfo.description[header.language])}</Text>
                          }
                        </Stack>
                      </Flex>
                    )
                  })}
                </ScrollArea.Autosize>
              </Stack>
            </Input.Wrapper>
          }
        </Stack>
        :
        <></>
      }
    </>
  );
};

export default ChatUserPreferences;
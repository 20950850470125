import { Title, Grid, Stack, ActionIcon, Divider, Group, Menu, Tooltip, useMantineColorScheme, Text, Box, Popover, em, Loader } from '@mantine/core';
import { FC, ReactNode, useContext } from 'react';
import HeaderContext from 'src/services/header-context';
import { BreadcrumbsComponent } from 'src/core/ui/breadcrumbs';
import { truncateText } from 'src/core/utils/object';
import { IconFileAlert, IconSun, IconMoonStars, IconUser, IconPower, IconGridDots, IconHelp, IconExternalLink, IconDotsVertical, IconUserCircle, IconHeadset } from '@tabler/icons-react';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AppConfiguration, authStatus, useAuthentication } from 'src/core/services/authentication-service';
import AppContext from 'src/services/app-context';
import { container } from 'src/inversify.config';
import { getLanguageIcon, getLanguageText } from './language-selector';
import classes from 'src/pages/index.module.css';
import { useMediaQuery } from '@mantine/hooks';

const ContentWrapper: FC<{
  children: any,
  title?: ReactNode,
  subTitle?: ReactNode,
  logo?: ReactNode,
  leftIcons?: ReactNode,
  rightIcons?: ReactNode,
  withoutPadding?: boolean;
  loading?: boolean;
  loadingNode?: ReactNode
}> = ({ title, subTitle, logo, children, leftIcons, rightIcons, withoutPadding = false, loading = false, loadingNode = <Loader size="xs" /> }) => {
  const { colorScheme } = useMantineColorScheme();
  const { header, routes, setLanguage } = useContext(HeaderContext);
  const { isDirty } = useContext(AppContext);
  const { toggleColorScheme } = useMantineColorScheme();
  const allowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const supportDocs = container.get<AppConfiguration>("AppConfiguration").supportDocs;
  const supportNewIssueLink = container.get<AppConfiguration>("AppConfiguration").supportNewIssueLink;
  const navigate = useNavigate();
  const [, , getLogoutUrl] = useAuthentication();

  const rightSection =
    <Group align='flex-start' gap={'xs'} justify="flex-end">
      {isDirty &&
        <Tooltip label={t("You have pending changes to save.")}>
          <ActionIcon size="lg" variant="subtle" color="yellow">
            <IconFileAlert />
          </ActionIcon>
        </Tooltip>
      }
      <Tooltip withinPortal label={t("Start menu")}>
        <ActionIcon size="lg" onClick={() => navigate("/start?disable_auto_redirect=true")} variant='subtle' color='var(--mantine-primary-color-6)'>
          <IconGridDots size={24} />
        </ActionIcon>
      </Tooltip>
      <Tooltip withinPortal label={t("Change theme")}>
        <ActionIcon variant='subtle' size="lg" color={colorScheme === 'dark' ? 'yellow' : 'var(--mantine-primary-color-6)'} onClick={() => toggleColorScheme()}>
          {colorScheme === 'dark' ? <IconSun size={24} /> : <IconMoonStars size={24} />}
        </ActionIcon>
      </Tooltip>
      <Menu withArrow shadow="md" width={200} position="bottom-end">
        <Menu.Target>
          <Tooltip withinPortal label={t("Language options")}>
            <ActionIcon variant='subtle' size="lg" color='var(--mantine-primary-color-6)'>
              {getLanguageIcon(header.language, 24, 0.8)}
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          {allowedLanguages.map((lang) =>
            <Menu.Item key={lang} leftSection={getLanguageIcon(lang, 18)} onClick={() => setLanguage(lang)}>{getLanguageText(lang, t)}</Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <Menu withArrow shadow="md" position="bottom-end">
        <Menu.Target>
          <Tooltip withinPortal label={t("User options")}>
            <ActionIcon variant='subtle' size="lg" color='var(--mantine-primary-color-6)'>
              <IconUser />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item component="a" href={"/admin/profile"} leftSection={<IconUserCircle size={18} />}>
            {authStatus?.user.value?.sub || t("Anonymous")}
          </Menu.Item>
          {supportNewIssueLink &&
            <Menu.Item component="a" href={supportNewIssueLink} target='_blank' leftSection={<IconHeadset size={18} />}>
              <Group gap={5}>{t('Get support')} <IconExternalLink size={14} /></Group>
            </Menu.Item>
          }
          <Divider />
          <Menu.Item component="a" href={getLogoutUrl() || "/logout"} color="red" leftSection={<IconPower size={18} />}>
            {t('Logout')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {supportDocs &&
        <Tooltip withinPortal label={<Group gap={5}><Text size="sm">{t("Documentation")}</Text><IconExternalLink size={14} /></Group>}>
          <ActionIcon variant='subtle' size="lg" color='var(--mantine-primary-color-6)' component="a" href={supportDocs} target='_blank'>
            <IconHelp size={24} />
          </ActionIcon>
        </Tooltip>
      }
    </Group>;

  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  return (
    <>
      <div
        className={classes.header}
        style={{
          backgroundColor: colorScheme === 'dark' ? 'var(--mantine-color-dark-7)' : 'var(--mantine-color-white)',
          borderBottom: `1px solid ${colorScheme === 'dark' ? 'var(--mantine-color-dark-5)' : 'var(--mantine-color-gray-3)'}`,
        }}>
        <Grid align='center' justify='center' style={{ flexWrap: 'nowrap', width: '100%' }}>
          {title ?
            <>
              {leftIcons &&
                <Grid.Col span="content">
                  {leftIcons}
                </Grid.Col>
              }
              <Grid.Col span="auto">
                <Group justify={isMobile ? 'center' : 'flex-start'}>
                  {logo}
                  <Stack className={classes.headerTitleContaner} justify='center'>
                    {title}
                    {subTitle ?? undefined}
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span="content">
                <Group gap="xs" visibleFrom='md'>
                  {loading && loadingNode}
                  {rightIcons}
                  {rightSection}
                </Group>
                <Box hiddenFrom='md'>
                  {loading && loadingNode}
                  {rightIcons}
                  <Popover position="bottom" withArrow shadow="md">
                    <Popover.Target>
                      <ActionIcon variant='subtle' color='var(--mantine-primary-color-6)'>
                        <IconDotsVertical />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Group gap="xs">
                        {rightSection}
                      </Group>
                    </Popover.Dropdown>
                  </Popover>
                </Box>
              </Grid.Col>
            </>
            :
            <>
              <Grid.Col span="content">{header.icon}</Grid.Col>
              <Grid.Col span={9}>
                <Stack className={classes.headerTitleContaner}>
                  <Title order={5} title={header.title}>{truncateText(header.title)}</Title>
                  <BreadcrumbsComponent routes={routes} />
                </Stack>
              </Grid.Col>
              <Grid.Col span="auto" ta="right">
                <Group gap="xs" visibleFrom='md' justify='flex-end'>
                  {loading && loadingNode}
                  {rightIcons}
                  {rightSection}
                </Group>
                <Box hiddenFrom='md'>
                  {loading && loadingNode}
                  {rightIcons}
                  <Popover position="bottom" withArrow shadow="md">
                    <Popover.Target>
                      <ActionIcon variant='subtle' color='var(--mantine-primary-color-6)'>
                        <IconDotsVertical />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Group gap="xs" justify='flex-end'>
                        {rightSection}
                      </Group>
                    </Popover.Dropdown>
                  </Popover>
                </Box>
              </Grid.Col>
            </>
          }
        </Grid>
      </div>
      <Box className={classes.content} p={withoutPadding ? 0 : 'xs'}>
        {children}
      </Box>
    </>
  );
};

export default ContentWrapper;
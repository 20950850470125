import { FC, ReactNode, useEffect, useState } from 'react';
import { PillsInput, Pill, Combobox, CheckIcon, Group, useCombobox, ComboboxItem, InputWrapper, MantineStyleProp, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const BasicMultiselect: FC<{
  data: ComboboxItem[];
  value: string[] | undefined;
  onChange?: (value: string[]) => void;
  label?: string;
  required?: boolean;
  description?: string;
  variant?: 'filled' | 'unstyled' | undefined;
  readOnly?: boolean;
  creatable?: boolean;
  placeholder?: string;
  renderOption?: (item: ComboboxItem) => any;
  withinPortal?: boolean;
  style?: MantineStyleProp;
  width?: string | number;
  error?: ReactNode;
}> = ({ data, value, onChange, label, required, description, variant, readOnly, creatable, placeholder, renderOption, withinPortal, style, width, error }) => {
  const { t } = useTranslation();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');
  const [internalData, setInternalData] = useState<ComboboxItem[]>(data ?? []);
  const [internalValue, setInternalValue] = useState<string[]>(value ?? []);

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    setInternalData(data);
  }, [JSON.stringify(data)]);

  const exactOptionMatch = internalData.some((item) => item.label === search);

  const handleValueSelect = (val: string) => {
    setSearch('');
    const currentValues = [...internalValue];

    if (val === '$create') {
      const newValues = [...currentValues, search];
      setInternalData((current) => [...current, { value: search, label: search }]);
      setInternalValue(newValues);
      onChange?.(newValues);
    } else {
      const newValues = currentValues.includes(val) ? currentValues.filter((v) => v !== val) : [...currentValues, val];
      setInternalValue(newValues);
      onChange?.(newValues);
    }
  };

  const handleValueRemove = (val: string) => {
    const currentValues = [...internalValue];
    const newValues = currentValues.filter((v) => v !== val);
    setInternalValue(newValues);
    onChange?.(newValues);
  }

  const values = internalValue.map((value) => {
    const item = internalData.find((i) => i.value === value);
    if (item) {
      return (<Pill key={value} withRemoveButton={!readOnly} onRemove={() => handleValueRemove(value)} styles={{ label: renderOption ? { lineHeight: 1 } : {} }}>
        {renderOption ? renderOption(item) : <span>{item.label}</span>}
      </Pill>);
    }
  });

  const options = internalData
    .filter((item) => item.label.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => {
      return (<Combobox.Option value={item.value} key={item.value} active={internalValue.includes(item.value)}>
        <Group gap="sm">
          {internalValue.includes(item.value) ? <CheckIcon size={12} /> : null}
          {renderOption ? renderOption(item) : <span>{item.label}</span>}
        </Group>
      </Combobox.Option>);
    });

  return (
    <Box style={{ ...style }}>
      <InputWrapper
        label={label}
        style={{ width: width }}
        required={required && !readOnly}
        error={error}
        description={description}>
        <Combobox
          store={combobox}
          onOptionSubmit={handleValueSelect}
          withinPortal={withinPortal}
          readOnly={readOnly}>
          <Combobox.DropdownTarget>
            <PillsInput onClick={() => combobox.openDropdown()} variant={variant ? variant : !readOnly ? 'default' : 'unstyled'}>
              <Pill.Group>
                {values}

                {!readOnly &&
                  <Combobox.EventsTarget>
                    <PillsInput.Field
                      onFocus={() => combobox.openDropdown()}
                      onBlur={() => combobox.closeDropdown()}
                      value={search}
                      placeholder={values.length === 0 ? placeholder : undefined}
                      onChange={(event) => {
                        combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Backspace' && search.length === 0) {
                          event.preventDefault();
                          handleValueRemove(internalValue[internalValue.length - 1]);
                        }
                      }}
                    />
                  </Combobox.EventsTarget>
                }
              </Pill.Group>
            </PillsInput>
          </Combobox.DropdownTarget>

          {!readOnly &&
            <Combobox.Dropdown>
              <Combobox.Options>
                {options}

                {!exactOptionMatch && search.trim().length > 0 && (
                  <>
                    {creatable ?
                      <Combobox.Option value="$create">+ {t("Add")} {search}</Combobox.Option>
                      :
                      <Combobox.Empty>{t("No items found")}</Combobox.Empty>
                    }
                  </>
                )}

                {exactOptionMatch && search.trim().length > 0 && options.length === 0 && (
                  <Combobox.Empty>{t('No items found')}</Combobox.Empty>
                )}

                {options.length === 0 && creatable && !search &&
                  <Combobox.Empty>{t('Type to create new element')}</Combobox.Empty>
                }
                {options.length === 0 && !creatable && !search &&
                  <Combobox.Empty>{t('No items found')}</Combobox.Empty>
                }
              </Combobox.Options>
            </Combobox.Dropdown>
          }
        </Combobox>
      </InputWrapper>
    </Box>
  );
}
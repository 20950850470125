import { ActionIcon, Alert, Anchor, Button, Group, LoadingOverlay, Modal, Stack, Text } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { container } from "src/inversify.config";
import { formatBytes, formatMessage } from "src/core/utils/object";
import { CrawlerPatchesSummaryStore, UpsertCrawlerPatchItem, UpsertCrawlerPatchesItem } from "src/stores/crawlers";
import { read, utils, writeFile } from "xlsx";
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import jsyaml from "js-yaml";
import { IconAlertCircle, IconDownload, IconFileInfo, IconTrash, IconTableImport } from "@tabler/icons-react";

const ImportCrawlerPatches: FC<{
  opened: boolean;
  collectionId: string;
  crawlerId: string;
  onClose: () => void;
}> = ({ opened, collectionId, crawlerId, onClose }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(CrawlerPatchesSummaryStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const [importFile, setImportFile] = useState<FileWithPath>();
  const [rejectFile, setRejectFile] = useState<string | undefined>(undefined);
  const [importFileErrors, setImportFileErrors] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (collectionId && crawlerId) {
      store.setCollectionAndCrawler(collectionId, crawlerId);
    }
  }, [collectionId, crawlerId]);

  const onCloseModal = () => {
    store.clearError();
    setImportFileErrors(undefined);
    onClose();
  }

  const generateUploadPatchesTemplate = () => {
    const templateData = [
      {
        applyTo: '<your document id to patch>',
        title: '<your patch title>',
        patch: '<your patch content>'
      }];

    const worksheet = utils.json_to_sheet(templateData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, t("Patches") as string);
    utils.sheet_add_aoa(worksheet, [[t("ApplyTo"), t("Title"), t("Patch")]], { origin: "A1" });
    const max_width = templateData.reduce((w, r) => Math.max(w, r.applyTo.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];
    writeFile(workbook, "patches_template.xlsx", { compression: true });
  }

  const onDrop = async (files: FileWithPath[]) => {
    if (files && files.length > 0) {
      const file = files[0];
      setImportFile(file);
      setRejectFile(undefined);
    }
  }

  const onDelete = () => {
    setImportFile(undefined);
  }

  const onSubmit = async () => {
    if (importFile) {
      const data = await importFile.arrayBuffer();
      const wb = read(data);
      const ws = wb.Sheets[wb.SheetNames[0]];
      const sheetToJson = utils.sheet_to_json(ws);

      //validate file format
      const cols = Object.keys(sheetToJson[0] as any);
      if (cols[0].toLocaleLowerCase() !== 'applyto' || cols[1].toLocaleLowerCase() !== 'title' || cols[2].toLocaleLowerCase() !== 'patch') {
        setImportFileErrors(t("Wrong file format.") as string);
      }
      else if (sheetToJson.length === 0) {
        setImportFileErrors(t("No data found in the file.") as string);
      }
      else {
        let patches = [] as UpsertCrawlerPatchItem[];
        for (let element of sheetToJson) {
          let itemToPush = {} as UpsertCrawlerPatchItem;
          Object.keys(element as any).forEach((columnName) => {
            if (columnName.toLowerCase() === 'applyto') {
              const value = (element as any)[columnName];
              itemToPush.applyTo = value.toString();
            }
            if (columnName.toLowerCase() === 'title') {
              const value = (element as any)[columnName];
              itemToPush.title = value.toString();
            }
            if (columnName.toLowerCase() === 'patch') {
              const value = (element as any)[columnName];
              itemToPush.patch = JSON.stringify(jsyaml.load(value.toString()), null, 2);
            }
          })
          patches.push(itemToPush);
        }

        const data = {
          crawlerId: crawlerId,
          patches: patches
        } as UpsertCrawlerPatchesItem;
        //TODO: subir datos en lotes ??
        store.setCollectionAndCrawler(collectionId, crawlerId);
        await store.importPatches(data);
        onCloseModal();
      }
    }
  }

  return (
    <Modal
      title={<span>{t("Import patches")}</span>}
      withCloseButton
      closeOnClickOutside={false}
      onClose={onCloseModal}
      opened={opened}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        {importFileErrors && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => setImportFileErrors(undefined)}>
            <Text>{importFileErrors}</Text>
          </Alert>
        )}
        <Stack>
          <Group align="center" gap={10}>
            <IconDownload />
            <Anchor onClick={generateUploadPatchesTemplate}>{t("Download template")}</Anchor>
          </Group>

          {importFile &&
            <Group align="center" gap={10}>
              <IconFileInfo />
              <Text>{importFile.name}</Text>
              <Text c="dimmed">({formatBytes(importFile.size)})</Text>
              <ActionIcon color="red" variant='subtle' onClick={onDelete}>
                <IconTrash />
              </ActionIcon>
            </Group>
          }
          {!importFile &&
            <Dropzone onDrop={onDrop}
              onReject={(files) => { console.log('rejected files', files); setRejectFile(files[0].errors[0].message) }}
              maxSize={50 * 1024 ** 2}
              accept={[MIME_TYPES.xlsx, MIME_TYPES.xls]}
              multiple={false}
            >
              <Group justify="center" gap="md" style={{ pointerEvents: 'none' }}>
                <div>
                  <Text size="xl" inline>
                    {t("Drag file here or click to select file")}
                  </Text>
                  <Text size="sm" c="dimmed" inline mt={7}>
                    {t("Attach Excel file without exceeding")} 50 MB
                  </Text>
                  {rejectFile &&
                    <Text size="sm" c="red" inline mt={7}>{t("The file was rejected")}: {rejectFile}</Text>
                  }
                </div>
              </Group>
            </Dropzone>
          }

          <Group justify="flex-end" mt="xl">
            <Button
              onClick={onSubmit}
              disabled={!importFile}
              loading={isBusy}
              leftSection={<IconTableImport />}>
              {t("Import")}
            </Button>
          </Group>
        </Stack>
      </div>
    </Modal>
  );
};

export default ImportCrawlerPatches;
import { FC, useContext, useEffect, useState } from "react";
import { StepNodeProps } from "./steps";
import { useHookstate, none } from "@hookstate/core";
import { Group, Stack, ActionIcon, Textarea, Text, Tooltip, Fieldset, Input } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus, IconWindowMaximize, IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import SkillDebugJinjaModal from "../skill-debug-jinja-modal";
import StepWrapper from "./step-wrapper";
import { truncateText } from "src/core/utils/object";
import LanguageSegmentedWrapper, { LanguageSegmentedWrapperChildrenProps } from "src/components/language-segmented-wrapper";
import HeaderContext from "src/services/header-context";

const StepSayNode: FC<StepNodeProps> = ({ id, data }) => {
  const { step, edit, index, rightToolbar, updateNode } = data;
  const scopedState = useHookstate(step.action);
  const { header } = useContext(HeaderContext);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedText, setSelectedText] = useState<{ text: string, index: number } | undefined>(undefined);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);

  // useEffect(() => {
  //   const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStepAction;
  //   const newAction = { ...step.action, ...changes };
  //   const newStep = { ...step, action: newAction };
  //   const newData = { index: index, step: newStep } as StepDataProps;
  //   updateNode(id, newData);
  // }, [JSON.stringify(scopedState.value)])

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  }, [scopedState.text])

  const onAddText = () => {
    const defaultLang = allowedLanguages[0];
    let defaultText: { [key: string]: string } = {};
    defaultText[defaultLang] = t("<your message>", { lng: defaultLang });

    scopedState.text[scopedState.text.length].set(defaultText);
    setIsDirty(true);
  }

  const onOpenDebugJinja = (index: number, lang: string) => {
    const text = (scopedState.text[index])[lang].value;
    setSelectedText({ text, index });
    open();
  }

  const onCloseDebugJinja = () => {
    setSelectedText(undefined);
    close();
  }

  const onSaveDebugJinja = (value: string, lang: string) => {
    if (selectedText) {
      (scopedState.text[selectedText.index])[lang].set(value);
    }
    onCloseDebugJinja();
  }

  const onDeleleLanguageInText = (language: string) => {
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(none);
    }
    setIsDirty(true);
  }

  const onAddLanguageInText = (language: string) => {
    const defaultText = t("<your message>", { lng: language });
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(defaultText);
    }
    setIsDirty(true);
  }

  const onAddSuggestion = () => {
    const defaultLang = allowedLanguages[0];
    if (!scopedState.suggestions || !scopedState.suggestions.value) {
      scopedState.suggestions.set([])
    }
    let defaultSuggestions: { [key: string]: string } = {};
    defaultSuggestions[defaultLang] = t("<your suggestion>", { lng: defaultLang });
    scopedState.suggestions[scopedState.suggestions.length].set(defaultSuggestions);
    setIsDirty(true);
  }

  const onDeleleLanguageInSuggestions = (language: string) => {
    for (let index = 0; index < scopedState.value.suggestions.length; index++) {
      (scopedState.suggestions[index])[language].set(none);
    }
    setIsDirty(true);
  }

  const onAddLanguageInSuggestions = (language: string) => {
    const defaultSuggestion = t("<your suggestion>", { lng: language });
    for (let index = 0; index < scopedState.value.suggestions.length; index++) {
      (scopedState.suggestions[index])[language].set(defaultSuggestion);
    }
    setIsDirty(true);
  }

  const resumeLang = allowedLanguages.includes(header.language) ? header.language : allowedLanguages[0];
  const resume = (
    <Text title={scopedState?.text?.[0]?.[resumeLang]?.value}>{truncateText(scopedState?.text?.[0]?.[resumeLang]?.value)}</Text>
  )

  if (!scopedState?.value) return (<></>);

  return (
    <StepWrapper
      id={id}
      index={index}
      step={step}
      updateNode={updateNode}
      edit={edit}
      rightToolbar={rightToolbar}
      resume={resume}>
      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Messages")}</Text>
            {edit &&
              <Group align="center" gap={5}>
                <Tooltip withinPortal label={t("Add text")}>
                  <ActionIcon onClick={onAddText} variant='subtle' color="gray" size="sm">
                    <IconPlus size={14} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            }
          </Group>
        }>
        <Stack gap="xs">
          <LanguageSegmentedWrapper
            edit={edit}
            data={scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : undefined}
            onDelete={onDeleleLanguageInText}
            onAdd={onAddLanguageInText}
          >
            {(props: LanguageSegmentedWrapperChildrenProps) =>
              <>
                {scopedState?.text?.value && scopedState.text.map((text, index) =>
                  edit ?
                    <Group align="flex-start" key={index}>
                      <Textarea
                        autosize
                        minRows={3}
                        maxRows={10}
                        required
                        style={{ flex: 1, minWidth: 400 }}
                        value={text[props.selectedLanguage]?.value ?? ''}
                        onChange={(event) => { text[props.selectedLanguage].set(event.target.value); setIsDirty(true); }}
                      />
                      <Stack gap={5}>
                        <Tooltip label={t("More options")}>
                          <ActionIcon variant='subtle' color="gray" onClick={() => onOpenDebugJinja(index, props.selectedLanguage)}>
                            <IconWindowMaximize size={20} />
                          </ActionIcon>
                        </Tooltip>
                        <ActionIcon onClick={() => { text.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                          <IconTrash size={16} />
                        </ActionIcon>
                      </Stack>
                    </Group>
                    :
                    <div key={index}>
                      <Text span fs="italic">{text[props.selectedLanguage]?.value ?? ''} </Text>
                    </div>
                )}
                <SkillDebugJinjaModal value={selectedText?.text ?? ''} open={opened} onClose={onCloseDebugJinja} onSave={(value) => onSaveDebugJinja(value, props.selectedLanguage)} />
              </>
            }
          </LanguageSegmentedWrapper>
        </Stack>
      </Fieldset>
      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Suggestions")}</Text>
            {edit &&
              <Group align="center" gap={5}>
                <Tooltip withinPortal label={t("Add suggestion")}>
                  <ActionIcon onClick={onAddSuggestion} variant='subtle' color="gray" size="sm">
                    <IconPlus size={14} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            }
          </Group>
        }>
        <Stack gap="xs">
          <LanguageSegmentedWrapper
            edit={edit}
            data={scopedState?.value?.suggestions?.length > 0 ? Object.keys(scopedState?.value?.suggestions?.[0]) : undefined}
            onDelete={onDeleleLanguageInSuggestions}
            onAdd={onAddLanguageInSuggestions}
          >
            {(props: LanguageSegmentedWrapperChildrenProps) =>
              scopedState?.suggestions?.value && scopedState.suggestions.map((suggestion, index) =>
                edit ?
                  <Group align="flex-start" key={index}>
                    <Input
                      style={{ flex: 1, minWidth: 400 }}
                      value={suggestion[props.selectedLanguage]?.value ?? ''}
                      onChange={(event) => { suggestion[props.selectedLanguage].set(event.target.value); setIsDirty(true); }}
                    />
                    <Stack gap={5}>
                      <ActionIcon onClick={() => { suggestion.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Stack>
                  </Group>
                  :
                  <div key={index}>
                    <Text span fs="italic">{suggestion[props.selectedLanguage]?.value ?? ''} </Text>
                  </div>
              )
            }
          </LanguageSegmentedWrapper>
        </Stack>
      </Fieldset>
    </StepWrapper>
  );
};

export default StepSayNode;
import { Tooltip, Text, Card, Stack, Menu, ActionIcon, Group, Select } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconDotsVertical, IconFileShredder, IconCloudUpload, IconPlus, IconCloudOff, IconTrash, IconAdjustments } from "@tabler/icons-react";
import { DataTableSortStatus } from "mantine-datatable";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import StatusBadge from "src/components/status-badge";
import TrainingStatusBadge from "src/components/training-status-badge";
import { Query } from "src/core/stores/data-store";
import TableListV2, { TableListV2Model } from "src/core/ui/table-list/table-list-v2";
import { formatMessage } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { DocumentSnapshotHistoryStore, DocumentSnapshotSummary } from "src/stores/documents";
import useBus, { dispatch } from "use-bus";

const DocumentHistory: FC<{
  collectionId: string;
  documentId: string;
  canContribute: boolean;
}> = ({ collectionId, documentId, canContribute }) => {
  const { t } = useTranslation();
  const modals = useModals();
  const store = useMemo(() => container.get(DocumentSnapshotHistoryStore), []);
  const state = store.state;
  const [query, setQuery] = useState<Query>(undefined as any);
  const [statusFilter, setStatusFilter] = useState('');
  const [trainingStatusFilter, setTrainingStatusFilter] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'createdOn', direction: 'desc' });

  useEffect(() => {
    if (documentId) {
      store.setCollectionAndDocument(collectionId, documentId)
      const newQuery = {
        ...query,
        //orderBy: [{ field: 'createdOn', direction: 'Descending', useProfile: false }],
        skip: 0,
        parameters: {
          statusFilter: statusFilter,
          trainingStatusFilter: trainingStatusFilter,
          orderByField: sortStatus.columnAccessor,
          orderByDirection: sortStatus.direction
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, documentId, statusFilter, trainingStatusFilter]);

  useEffect(() => {
    if (query) {
      load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const load = async (query: Query) => {
    store.load(query);
  };

  useBus(
    '@@ui/DOCUMENT_HISTORY_LIST_REFRESH',
    () => load(query),
    [query],
  );

  const openDeleteModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Delete snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete snapshot'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(snapshotId),
    });

  const openTrainModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Train snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to train this item?')}
          <br></br>
          {t('The training process might take a while.')}
        </Text>
      ),
      labels: { confirm: t('Train snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmTrain(snapshotId),
    });

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const onConfirmDelete = async (snapshotId: string) => {
    await store.deleteSnapshot(collectionId, documentId, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
    }
  };

  const onConfirmTrain = async (snapshotId: string) => {
    await store.trainSnapshot(collectionId, documentId, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
    }
  };

  const openRetireModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Retire snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to retire this item?')}
        </Text>
      ),
      labels: { confirm: t('Retire snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmRetire(snapshotId),
    });

  const onConfirmRetire = async (snapshotId: string) => {
    await store.retireSnapshot(collectionId, documentId, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
    }
  };

  const openPublishModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Publish snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to publish this item?')}
        </Text>
      ),
      labels: { confirm: t('Publish snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmPublish(snapshotId),
    });

  const onConfirmPublish = async (snapshotId: string) => {
    await store.publishSnapshot(collectionId, documentId, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
    }
  };

  const openCreateNewVersionModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Create new version')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to create new version from this snapshot?')}
        </Text>
      ),
      labels: { confirm: t('Create new version'), cancel: t('Cancel') },
      onConfirm: () => onConfirmCreateNewVersion(snapshotId),
    });

  const onConfirmCreateNewVersion = async (snapshotId: string) => {
    await store.createNewVersionFromSnapshot(collectionId, documentId, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
      dispatch('@@ui/DOCUMENT_DETAIL_REFRESH');
    }
  };

  const model: TableListV2Model<DocumentSnapshotSummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'createdOn',
        title: t('Version'),
        sortable: true,
        render: (item: DocumentSnapshotSummary) => (
          <Text>{moment(item.createdOn).format('lll')}</Text>
        )
      },
      {
        accessor: 'hash',
        title: t('Hash'),
        render: (item: DocumentSnapshotSummary) => (
          <Text style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{item.hash}</Text>
        )
      },
      {
        accessor: 'status',
        title: t('Status'),
        render: (item: DocumentSnapshotSummary) => (
          <StatusBadge status={item.status} />
        )
      },
      {
        accessor: 'trainingStatus',
        title: t('Training status'),
        render: (item: DocumentSnapshotSummary) => (
          <TrainingStatusBadge status={item.trainingStatus} />
        )
      },
      {
        accessor: 'createdOn',
        title: t('Modified on'),
        sortable: true,
        render: (item: DocumentSnapshotSummary) => (
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.createdOn).toString()}</Text>}>
            <Text>{moment(item.createdOn).fromNow()}</Text>
          </Tooltip>
        )
      },
      {
        accessor: 'createdBy',
        title: t('Modified by'),
        sortable: true,
        render: (item: DocumentSnapshotSummary) => (
          <Text>{item.createdBy}</Text>
        )
      },
      {
        accessor: 'actions',
        title: t('Actions'),
        render: (item: DocumentSnapshotSummary) => (
          <div style={{ float: 'right' }}>
            <Menu withinPortal withArrow width={250} shadow="md" position="bottom-end">
              <Menu.Target>
                <Tooltip label={t("More options")}>
                  <ActionIcon variant='subtle' size="sm" color="gray">
                    <IconDotsVertical />
                  </ActionIcon>
                </Tooltip>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{t("Snapshot options")}</Menu.Label>
                <Menu.Item disabled={!canContribute} leftSection={<IconFileShredder size={20} />} onClick={() => openTrainModal(item.id)}>{t('Train snapshot')}</Menu.Item>
                <Menu.Item disabled={!canContribute || item.status !== 'Retired'} leftSection={<IconCloudUpload size={20} />} onClick={() => openPublishModal(item.id)}>{t('Publish snapshot')}</Menu.Item>
                <Menu.Item disabled={!canContribute || item.isCurrent} leftSection={<IconPlus size={20} />} onClick={() => openCreateNewVersionModal(item.id)}>{t('Create new version')}</Menu.Item>
                <Menu.Divider />
                <Menu.Label>{t("Danger zone")}</Menu.Label>
                <Menu.Item disabled={!canContribute} color="red" leftSection={<IconCloudOff size={20} />} onClick={() => openRetireModal(item.id)}>{t('Retire snapshot')}</Menu.Item>
                <Menu.Item disabled={!canContribute || item.isCurrent} color="red" leftSection={<IconTrash size={20} />} onClick={() => openDeleteModal(item.id)}>{t('Delete snapshot')}</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        )
      }
    ]
  };

  return (
    <Card withBorder>
      <Stack gap="md">
        <TableListV2
          idAccessor="createdOn"
          model={model}
          onQueryChanged={setQuery}
          onRefresh={() => load(query)}
          striped
          highlightOnHover
          hideSearch
          sortable
          sortStatus={sortStatus}
          onSortStatusChanged={setSortStatus}
          rightToolBarRender={
            <Group gap="xs" align="center">
              <Select
                clearable
                style={{ width: 160 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Status') as string}
                data={[
                  { value: 'Draft', label: t('Draft') as string },
                  { value: 'Published', label: t('Published') as string },
                  { value: 'Retired', label: t('Retired') as string },
                ]}
                onChange={(value) => setStatusFilter(value as string)}
                value={statusFilter}
              />
              <Select
                clearable
                style={{ width: 160 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Training status') as string}
                data={[
                  { value: 'Pending', label: t('Pending') as string },
                  { value: 'Training', label: t('Training') as string },
                  { value: 'Trained', label: t('Trained') as string },
                  { value: 'Error', label: t('Error') as string },
                ]}
                onChange={(value) => setTrainingStatusFilter(value as string)}
                value={trainingStatusFilter}
              />
            </Group>
          }
        />
      </Stack>
    </Card>
  );
};

export default DocumentHistory;
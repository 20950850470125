import { TagItem } from "src/stores/skills";

export const tagsToArray = (tagsDic: { [key: string]: any }) => {
  if (tagsDic) {
    let tagsList = [] as TagItem[];
    Object.keys(tagsDic || {}).forEach((t) => tagsList.push({ name: t, value: typeof tagsDic[t] === 'object' ? JSON.stringify(tagsDic[t]) : tagsDic[t] }));
    return tagsList;
  }
  return [];
};

export const tagsToDict = (tags: TagItem[]) => {
  let dict = {} as any;
  if (!tags)
    return dict;
  tags.forEach((t: any) => (dict[t.name] = t?.value && (`${t.value}`).indexOf(',') >= 0 ? t.value.split(',') : t.value));
  return dict;
};
import FormStore from "src/core/stores/form-store";

export interface DailyIntEntryItem {
  day: string,
  value: number
}

export interface SystemMetrics {
  operations: DailyIntEntryItem[];
  queries: DailyIntEntryItem[];
  llmTokens: DailyIntEntryItem[];
  databaseSize: number,
  documentsSize: number,
  documents: number,
  fragments: number
}

export interface TenantUsageItem {
  quota: TenantQuotaItem;
  monthlyMessagesUsed: number;
  storageUsed: number;
  fragmentsUsed: number;
}

export interface TenantQuotaItem {
  monthlyMessagesQuota: number;
  storageQuota: number;
  llmRpmQuota: number;
  fragmentsQuota: number;
}

export class SystemMetricsStore extends FormStore<SystemMetrics, SystemMetrics> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/metrics/system`);
  }
}

export class TenantQuotasStore extends FormStore<TenantUsageItem, TenantUsageItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/metrics/quotas`);
  }
}

export class UserMetricsStore extends FormStore<SystemMetrics, SystemMetrics> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/profile/me/metrics`);
  }
}
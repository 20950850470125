import React, { useState, useRef, useEffect } from 'react';
import { Query, ListState, QueryParameters } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Group, TextInput, Text, Tooltip, Box, Alert, Stack, Badge, MantineStyleProp, Popover, Button } from '@mantine/core';
import { IconAlertCircle as AlertCircle, IconFilterPlus, IconTrash, IconPlus as Plus, IconRefresh as Refresh, IconSearch as Search, IconX as X } from '@tabler/icons-react';
import { extractTagsFromText, formatMessage, isNullOrWhitespace } from 'src/core/utils/object';
import { AsEnumerable } from 'linq-es5';
import classes from './simple-list.module.css';
import { DataTable, DataTableColumn } from 'mantine-datatable';
import { modals } from '@mantine/modals';

export interface SimpleListV2Model<T> {
  data: ListState<T>;
  query: Query;
  columns: DataTableColumn<T>[];
}

export interface SimpleListV2Props<T> {
  idAccessor?: string;
  model: SimpleListV2Model<T>;
  onQueryChanged?: (query: Query) => void;
  onRefresh?: () => void;
  onItemClick?: (item: T) => void;
  searchText?: string;
  pageSize?: number;
  pageSizeOptions?: number[];
  height?: number;
  highlightOnHover?: boolean;
  withBorder?: boolean;
  withColumnBorders?: boolean;
  striped?: boolean;
  showFilters?: boolean;
  showNewIcon?: boolean;
  onNewItemClick?: () => void;
  searchByTag?: boolean;
  selectable?: boolean;
  onDeleteSelectedClick?: (selectedItems: T[]) => void;
  noShowLoading?: boolean;
  rowStyle?: (record: T, index: number) => MantineStyleProp | undefined;
  selectedItems?: T[];
  onChangeSelectedItems?: (selectedItems: T[]) => void;
}

const SimpleListV2: React.FC<SimpleListV2Props<any>> = <T,>({
  pageSize: pageSizeProps = 10,
  pageSizeOptions = [10, 30, 50, 100],
  idAccessor,
  height = undefined,
  showFilters,
  showNewIcon,
  onNewItemClick,
  searchByTag = false,
  highlightOnHover = true,
  striped = true,
  withBorder = false,
  withColumnBorders = true,
  searchText,
  selectable,
  onDeleteSelectedClick,
  noShowLoading,
  rowStyle,
  selectedItems,
  onChangeSelectedItems,
  ...props
}: SimpleListV2Props<T>) => {
  const { model } = props;
  const { t } = useTranslation();
  const timerRef = useRef<NodeJS.Timeout>();
  const [internalSelectedItems, setInternalSelectedItems] = useState<T[]>([]);
  const scrollViewportRef = useRef<HTMLDivElement>(null);
  const [searchBoxValue, setSearchBoxValue] = useState<string>('');
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const [showFilterByTagModal, setShowFilterByTagModal] = useState(false);
  const [tagNameFilter, setTagNameFilter] = useState<string>('');
  const [tagValueFilter, setTagValueFilter] = useState<string>('');

  useEffect(() => {
    if (selectedItems) {
      setInternalSelectedItems(selectedItems);
    }
  }, [selectedItems])

  if (model == null) return null;

  const query = model.query || {} as any;
  const pageSize = query && query.take ? query.take : pageSizeProps ? pageSizeProps : 10;

  const updateQuery = (searchQuery: string, tags: string[]) => {
    onSelectedItems([]);
    let newParameters = query.parameters ?? {} as QueryParameters;
    newParameters.tagsFilter = tags;
    const nextQ = { ...query, skip: 0, searchQuery: searchQuery, parameters: newParameters };
    if (props.onQueryChanged) props.onQueryChanged(nextQ as Query);
  }

  const extractTags = (text: string) => {
    const tags: string[] = query.parameters?.tagsFilter as string[] || [];
    const [newText, newTags] = extractTagsFromText(text, tags);

    setSearchBoxValue(newText as string);
    setTagsFilter(newTags as string[]);

    return [newText, newTags];
  }

  const onSearchKeyUp = (event: any) => {
    const value = event.target.value;
    setSearchBoxValue(value || '');
    if (event.key === 'Enter' || event.key === ' ') {
      if (timerRef?.current != null) clearTimeout(timerRef.current);
      const [query, tags] = extractTags(value);
      updateQuery(query as string, tags as string[]);
    }
  }

  const onKeyDown = (event: any) => {
    //FIX: prevent modal close
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  const deleteTag = (tag: string) => {
    let newTags: string[] = [];
    if (tagsFilter && tagsFilter.length > 0 && tag) {
      newTags = AsEnumerable(tagsFilter).Where(t => t !== tag).ToArray();
    }
    updateQuery(searchBoxValue, newTags as string[]);
    setTagsFilter(newTags);
  }

  const onKeyUpApplyfilterByTag = (event: any) => {
    if (event.key === 'Enter') {
      onApplyFilterByTag();
    }
  }

  const onApplyFilterByTag = () => {
    if (!isNullOrWhitespace(tagNameFilter)) {
      const [query, tags] = extractTags(`${tagNameFilter}:${tagValueFilter}`);
      updateQuery(query as string, tags as string[]);
    }

    setTagNameFilter('');
    setTagValueFilter('');
    setShowFilterByTagModal(false);
  }

  const getMoreData = () => {
    const nextQ = { ...query, skip: 0, take: query.take + pageSize };
    if (props.onQueryChanged) props.onQueryChanged?.(nextQ as Query);
  }

  const onRefresh = () => {
    updateQuery(searchBoxValue, tagsFilter);
  }

  const openDeleteModal = () => {
    if (internalSelectedItems.length > 0 && onDeleteSelectedClick) {
      modals.openConfirmModal({
        title: <Text>{t('Delete selected items')}</Text>,
        children: (
          <Text size="sm">{t('Are you sure you want to delete the selected items?')}</Text>
        ),
        labels: { confirm: t('Delete'), cancel: t('Cancel') },
        confirmProps: { color: 'red' },
        onConfirm: () => {
          onDeleteSelectedClick(internalSelectedItems);
          onSelectedItems([]);
        },
      });
    }
  }

  const onSelectedItems = (selectedItems: T[]) => {
    setInternalSelectedItems(selectedItems);
    onChangeSelectedItems?.(selectedItems);
  }

  return (
    <div>
      {model?.data?.errorMessage &&
        <Alert p="md" mb="xs" icon={<AlertCircle size={16} />} title={t("Error")} color="red">
          <Text>{formatMessage(model?.data?.errorMessage)}</Text>
        </Alert>
      }

      {showFilters &&
        <Stack gap="xs" mb="xs">
          <Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flexGrow: 1 }}>
                <TextInput
                  value={searchBoxValue}
                  onChange={(event) => setSearchBoxValue(event.target.value)}
                  style={{ width: '100%' }}
                  className={classes.searchBox}
                  leftSection={<Search size={20} />}
                  onKeyDown={onKeyDown}
                  onKeyUp={onSearchKeyUp}
                  placeholder={t('Search...') as string}
                  rightSection={
                    searchByTag ?
                      // <Tooltip
                      //   multiline
                      //   w={300}
                      //   label={<Text size="sm"><Text fw={500}>{t("Search by tags")}</Text>{t("You can search by tags by typing a tag like 'tag:value' and pressing the space bar to include it in your search.")}</Text>}>
                      //   <ActionIcon size="xs" variant='subtle' color="gray">
                      //     <InfoCircle />
                      //   </ActionIcon>
                      // </Tooltip>
                      <Popover width={300} trapFocus position="bottom" withArrow shadow="md" opened={showFilterByTagModal}>
                        <Popover.Target>
                          <Tooltip label={t("Search by tags")}>
                            <ActionIcon size="xs" variant='subtle' color="gray" onClick={() => setShowFilterByTagModal(true)}>
                              <IconFilterPlus />
                            </ActionIcon>
                          </Tooltip>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Stack gap={5}>
                            <Text fw={500}>{t("Search by tags")}</Text>
                            <TextInput label={t("Name")} placeholder="context" size="xs" value={tagNameFilter} onChange={(e) => setTagNameFilter(e.target.value)} onKeyUp={onKeyUpApplyfilterByTag} />
                            <TextInput label={t("Value")} placeholder="chat" size="xs" value={tagValueFilter} onChange={(e) => setTagValueFilter(e.target.value)} onKeyUp={onKeyUpApplyfilterByTag} />

                            <Group align='center' justify='flex-end' mt="md">
                              <Button size='xs' variant='default' onClick={() => setShowFilterByTagModal(false)}>{t("Cancel")}</Button>
                              <Button size='xs' onClick={onApplyFilterByTag}>{t("Apply")}</Button>
                            </Group>
                          </Stack>
                        </Popover.Dropdown>
                      </Popover>
                      :
                      null
                  }
                />
              </div>
              <div style={{ flex: '0 0 35px' }}>
                <Group gap="xs" align="center" justify="flex-end">
                  {showNewIcon &&
                    <Tooltip label={t("Create new item")}>
                      <ActionIcon onClick={onNewItemClick} variant='subtle' color="gray">
                        <Plus />
                      </ActionIcon>
                    </Tooltip>
                  }
                  {onDeleteSelectedClick && selectable && internalSelectedItems.length > 0 &&
                    <Tooltip label={t("Delete selected items")}>
                      <ActionIcon onClick={openDeleteModal} color="red" variant='subtle'>
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                  }
                  <Tooltip label={t("Reload data")}>
                    <ActionIcon onClick={onRefresh} variant='subtle' color="gray">
                      <Refresh />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </div>
            </div>
          </Box>
          {searchByTag && tagsFilter && tagsFilter.length > 0 &&
            <Group gap="xs">
              <Text size="sm">{t("Search by tags")}:</Text>
              {tagsFilter.map((tag) => (
                <Badge key={tag} size="xs" radius="sm" p="xs" className={classes.tag}
                  rightSection={
                    <ActionIcon size="xs" variant="transparent" color="gray" onClick={() => deleteTag(tag)}>
                      <X />
                    </ActionIcon>
                  }>{tag}</Badge>
              ))}
            </Group>
          }
        </Stack>
      }

      <DataTable
        noHeader
        rowStyle={rowStyle}
        idAccessor={idAccessor}
        withTableBorder={withBorder ?? false}
        withColumnBorders={withColumnBorders}
        height={height}
        striped={striped}
        highlightOnHover={highlightOnHover}
        columns={model.columns}
        records={model?.data?.items}
        fetching={!noShowLoading ? model?.data?.isBusy : false}
        selectedRecords={selectable ? internalSelectedItems : undefined}
        onSelectedRecordsChange={(selectable ? onSelectedItems : undefined) as any}
        onRowClick={(item) => { props.onItemClick?.(item.record) }}
        noRecordsText={t("No records") as string}
        onScrollToBottom={getMoreData}
        scrollViewportRef={scrollViewportRef}
        //scrollAreaProps={{ offsetScrollbars: true }}
        selectionTrigger="cell"
      />
    </div>
  );
};

export default SimpleListV2;

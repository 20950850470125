import { Box, Group, Input, Stack, UnstyledButton, Text } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceConnectionCategory, ServiceConnectionType } from "src/stores/serviceconnections";
import serviceConnectionClasses from './create-serviceconnection-wizard.module.css';
import cx from 'clsx';
import ServiceConnectionCategoryTypeSelector from "./serviceconnection-category-type-selector";
import { AsEnumerable } from "linq-es5";
import { ServiceConnectionItem, getServiceConnectionData } from "./services/serviceconnection";

const ServiceConnectionCategoryTypePicker: FC<{
  category: ServiceConnectionCategory,
  type: ServiceConnectionType,
  onChange?: (value: ServiceConnectionItem) => void,
  disabled?: boolean,
  required?: boolean;
}> = ({ category, type, onChange, disabled = false, required }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<ServiceConnectionItem | undefined>(undefined);
  const [categoryFilter, setCategoryFilter] = useState<string>();
  const serviceConnectionData = getServiceConnectionData(t);

  useEffect(() => {
    const service = serviceConnectionData.filter(x => x.category === category && x.type === type)?.[0];
    if (service) {
      setSelectedItem(service);
    }
  }, [category, type])

  const onSelectedItem = (item: ServiceConnectionItem) => {
    setSelectedItem(item);
    onChange?.(item);
  }

  return (
    <Stack>
      <Group justify="space-between">
        <Input.Label required={required}>
          {t("Type")}
        </Input.Label>
        <ServiceConnectionCategoryTypeSelector
          category={categoryFilter as ServiceConnectionCategory ?? ''}
          onChangeCategory={(value) => setCategoryFilter(value)}
          categoryPlaceholder={t("Filter by category") as string}
          clearable
          onlyCategory
        />
      </Group>
      <Group gap="xl">
        {(categoryFilter ? AsEnumerable(serviceConnectionData).Where(sc => sc.category === categoryFilter).ToArray() : serviceConnectionData).map((c, index) => (
          <UnstyledButton key={c.value}
            className={cx(serviceConnectionClasses.driverButton, { [serviceConnectionClasses.driverButtonSelected]: selectedItem?.value === c.value })}
            onClick={() => onSelectedItem(c)}>
            <Stack justify="center" align="center">
              <Box className={serviceConnectionClasses.iconWrapper}>
                {c.icon}
              </Box>
              <Text>{c.label}</Text>
            </Stack>
          </UnstyledButton>
        ))}
      </Group>
    </Stack>
  );
};

export default ServiceConnectionCategoryTypePicker;
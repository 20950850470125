import { FC } from 'react'; import {
  Routes,
  Route
} from "react-router-dom";
import { useLocation } from "react-router";
import CollectionLanding from './collection-landing';
import CollectionDetail from './collection-detail';
import CollectionEdit from './collection-edit';

const CollectionsIndex: FC = () => {
  const location = useLocation();
  return <Routes>
    <Route path=":collectionId/edit" element={<CollectionEdit />} key={location.pathname} />
    <Route path=":collectionId" element={<CollectionDetail />} key={location.pathname} />
    <Route index element={<CollectionLanding />} />
  </Routes>
}

export default CollectionsIndex;


import { Anchor, Button, Group, Popover, Stack, Text } from "@mantine/core";
import { FC, RefObject, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { container } from "src/inversify.config";
import { IconCircleCheck, IconCircleX, IconPlug, IconReportSearch } from "@tabler/icons-react";
import { CrawlerRef } from "./crawlers/crawlers";
import { CrawlerResult, CrawlerType, TestCrawlerConnectionItem, TestCrawlerConnectionStore } from "src/stores/crawlers";
import { DataTable } from "mantine-datatable";

const TestCrawlerConnectionButton: FC<{
  crawlerRef?: RefObject<CrawlerRef>,
  collectionId: string;
  content?: string;
  type: CrawlerType
}> = ({ crawlerRef, collectionId, content, type }) => {
  const { t } = useTranslation();
  const testStore = useMemo(() => container.get(TestCrawlerConnectionStore), []);
  const testState = testStore.state;
  const [testResult, setTestResult] = useState<boolean | undefined>(undefined);

  const onTestConnection = async () => {
    setTestResult(undefined);
    testStore.clearError();
    const contentToTest = content || (crawlerRef ? (crawlerRef.current?.getContent() as string) : '');
    const item = {
      collectionId: collectionId,
      type: type,
      content: contentToTest
    } as TestCrawlerConnectionItem;

    testStore.setCollection(collectionId);
    await testStore.test(item);
    setTestResult(!testState.errorMessage.value);
  }

  useEffect(() => {
    testStore.clearError();
  }, [collectionId, type])

  return (
    <>
      {testResult &&
        <Group align="center" gap="xs">
          <IconCircleCheck color="green" />
          <Text size="sm">{t("Connection established successfully!")}</Text>
        </Group>
      }
      {testState.errorMessage.value &&
        <Group align="center" gap="xs">
          <IconCircleX color="red" />
          <Text size="sm">{t("Connection error:")} {testState.errorMessage.value}</Text>
        </Group>
      }
      {testResult && testState.items?.value.length > 0 &&
        <Popover width="50vw" withArrow shadow="xl">
          <Popover.Target>
            <Button variant="default" leftSection={<IconReportSearch size={16} />}>{t("View first results")}</Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Stack gap="xs">
              <Group justify="space-between">
                <Text>{t("First results crawled")}</Text>
              </Group>

              <DataTable
                striped
                highlightOnHover
                height="30svh"
                columns={[
                  { accessor: 'title', title: t('Title'), width: '50%' },
                  {
                    accessor: 'audience', title: t('Audience'), width: '40%', render: (item: CrawlerResult) => (
                      <Text size="sm">{item.audience.toString()}</Text>
                    )
                  },
                  { accessor: 'language', title: t('Language'), width: '10%' }
                ]}
                records={testState.items.value as CrawlerResult[]}
                rowExpansion={{
                  content: ({ record }) => (
                    <Stack p="xs" gap="xs">
                      <Text size="sm" fw={500}>{t("Text")}:</Text>
                      <Text size="sm">{record.body}</Text>
                      {record.uri &&
                        <Text size="sm" fw={500} mt="md">{t("Uri")}: <Anchor target="_blank" href={record.uri}>{record.uri}</Anchor></Text>
                      }
                    </Stack>
                  ),
                }}
              />
            </Stack>
          </Popover.Dropdown>
        </Popover>
      }
      <Button
        loading={testState.isBusy.value}
        onClick={() => onTestConnection()}
        variant="outline"
        leftSection={<IconPlug />}>
        {t("Test connection")}
      </Button>
    </>
  );
};

export default TestCrawlerConnectionButton;
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { TenantQuotasStore } from 'src/stores/metrics';
import { Card, Group, Progress, Text } from '@mantine/core';
import { formatBytes, formatNumber } from '../../../../core/utils/object';

const MetricsConfig: FC<{}> = () => {
  const { t } = useTranslation();
  const quotasStore = useMemo(() => container.get(TenantQuotasStore), []);
  const quotasState = quotasStore.state;

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = async () => {
    await quotasStore.load("");
  };

  const messagesUsedPercent = (quotasState.item.quota?.monthlyMessagesQuota.value > 0 ? quotasState.item.monthlyMessagesUsed.value / quotasState.item.quota.monthlyMessagesQuota.value : 0) * 100;
  const storageUsedPercent = (quotasState.item.quota?.storageQuota.value > 0 ? quotasState.item.storageUsed.value / quotasState.item.quota.storageQuota.value : 0) * 100;
  const fragmentsUsedPercent = (quotasState.item.quota?.fragmentsQuota.value > 0 ? quotasState.item.fragmentsUsed.value / quotasState.item.quota.fragmentsQuota.value : 0) * 100;

  const getProgressColor = (percent: number) => {
    if (percent > 95)
      return 'red';
    else if (percent > 75)
      return 'yellow';
    else
    return 'blue'
  }

  return (
    <Group grow>
      {quotasState.item.quota?.monthlyMessagesQuota && quotasState.item.quota?.monthlyMessagesQuota.value >= 0 && <Card withBorder radius="md" padding="xl" bg="var(--mantine-color-body)">
        <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
          {t("Monthly messages used")}
        </Text>
        <Text fz="lg" fw={500}>
          {formatNumber(quotasState.item.monthlyMessagesUsed.value)} / {formatNumber(quotasState.item.quota.monthlyMessagesQuota.value)}
        </Text>
        <Progress value={messagesUsedPercent} mt="md" size="lg" radius="xl" color={getProgressColor(messagesUsedPercent)} />
      </Card>}
      {quotasState.item.quota?.storageQuota && quotasState.item.quota?.storageQuota.value >= 0 && <Card withBorder radius="md" padding="xl" bg="var(--mantine-color-body)">
        <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
          {t("Storage used")}
        </Text>
        <Text fz="lg" fw={500}>
          {formatBytes(quotasState.item.storageUsed.value)} / {formatBytes(quotasState.item.quota.storageQuota.value)}
        </Text>
        <Progress value={storageUsedPercent} mt="md" size="lg" radius="xl" color={getProgressColor(storageUsedPercent)} />
      </Card>}
      {quotasState.item.quota?.fragmentsQuota && quotasState.item.quota?.fragmentsQuota.value >= 0 && <Card withBorder radius="md" padding="xl" bg="var(--mantine-color-body)">
        <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
          {t("Fragments used")}
        </Text>
        <Text fz="lg" fw={500}>
          {formatNumber(quotasState.item.fragmentsUsed.value)} / {formatNumber(quotasState.item.quota.fragmentsQuota.value)}
        </Text>
        <Progress value={fragmentsUsedPercent} mt="md" size="lg" radius="xl" color={getProgressColor(fragmentsUsedPercent)} />
      </Card>}
    </Group>
  );
};

export default MetricsConfig;
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SideBarWrapper from 'src/components/sidebar-wrapper';
import CreateSkill from './create-skill';
import SkillList from './skill-list';
import { HasRole } from 'src/utils/permissions';

const SkillSideMenu: FC<{}> = ({ }) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = React.useState(true);
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  return (
    <SideBarWrapper
      title={t('Skills')}
      showFiltersIcon
      showFiltersValue={showFilters}
      onChangeShowFiltersValue={(value: boolean) => setShowFilters(value)}
      showNewIcon={HasRole('Creator')}
      onNewItemClick={() => setShowCreateModal(true)}>
      <SkillList showFilters={showFilters} />
      {showCreateModal && <CreateSkill opened={showCreateModal} onClose={() => setShowCreateModal(false)} />}
    </SideBarWrapper>
  );
};

export default SkillSideMenu;

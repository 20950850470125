import { Group, Input, Radio, Stack, Text } from '@mantine/core';
import { FC } from 'react';
import classes from './radio-group-button.module.css';

export interface RadioButtonItem {
  value: string;
  label: string;
  description?: string;
  disabled?: boolean;
}

const RadioGroupButton: FC<{
  label?: string;
  required?: boolean;
  data: RadioButtonItem[];
  checked: string;
  onChange: (checked: string) => void;
  readOnly?: boolean;
}> = ({ label, required, data, checked, onChange, readOnly }) => {
  return (
    <Input.Wrapper
      label={label}
      required={required}>
      <Group align='stretch' grow>
        {data?.map((r, index) =>
          <div className={classes.root} key={r.value}>
            <Stack gap="xs"
              className={classes.control}
              data-checked={r.value === checked ? true : undefined}
              onClick={() => !readOnly && !r.disabled ? onChange(r.value) : undefined}>
              <Group gap="xs" wrap='nowrap'>
                <Radio
                  checked={r.value === checked}
                  onChange={() => !readOnly && !r.disabled ? onChange(r.value) : undefined}
                  readOnly={readOnly || r.disabled}
                />
                <Text className={classes.label} size="md" fw={500}>{r.label}</Text>
              </Group>
              {r.description &&
                <Text fz="sm" c="dimmed">{r.description}</Text>
              }
            </Stack>
          </div>
        )}
      </Group>
    </Input.Wrapper>
  );
};

export default RadioGroupButton;

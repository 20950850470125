import { Button, ButtonVariant, Menu } from '@mantine/core';
import { FC, MouseEventHandler, ReactNode } from 'react';
import { IconChevronDown } from '@tabler/icons-react';

export interface ButtonOption {
  label: string;
  icon?: ReactNode;
  onClick?: () => void;
}

const ButtonWithOptions: FC<{
  leftSection?: ReactNode;
  variant?: ButtonVariant
  loading?: boolean;
  onClick: MouseEventHandler<any> | undefined;
  options?: ButtonOption[];
  children: any;
}> = ({ leftSection, variant, loading, onClick, options, children }) => {

  return (
    <Button.Group>
      <Button
        variant={variant}
        loading={loading}
        onClick={onClick}
        leftSection={leftSection}>
        {children}
      </Button>

      {options && options.length > 0 &&
        <Menu withinPortal withArrow width={250} shadow="md" position="bottom-end">
          <Menu.Target>
            <Button variant={variant} w={40} p={0} style={{ borderLeft: 'none' }}>
              <IconChevronDown size={20} />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {options.map((option, index) =>
              <Menu.Item
                key={index}
                leftSection={option.icon}
                onClick={option.onClick}>
                {option.label}
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      }
    </Button.Group>
  );
};

export default ButtonWithOptions;

import { FC, useEffect, useRef, useState } from 'react';
import { Group, Button, useMantineTheme, Stack, Image } from '@mantine/core';
import { Dropzone, FileRejection, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { IconTrash, IconUpload } from '@tabler/icons-react';
import { t } from 'i18next';
import indexClasses from 'src/pages/index.module.css';
import { notifications } from '@mantine/notifications';

export const UploadImageBase64: FC<{
  edit: boolean;
  value: string;
  onChange: (value: string) => void;
  maxWidth?: number | string;
  onDelete?: () => void;
}> = ({ edit, value, onChange, maxWidth = 400, onDelete }) => {
  const openRef = useRef<() => void>(null);
  const theme = useMantineTheme();
  const [internalValue, setInternalValue] = useState<string>(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value])

  const onDrop = async (files: FileWithPath[]) => {
    if (files && files.length > 0) {
      const file = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const content = e.target?.result ?? "";
        setInternalValue(content as string);
        onChange(content as string);
      };
    }
  }

  const onReject = (files: FileRejection[]) => {
    files.forEach(fileRejected => {
      const message = `${fileRejected.errors[0].code}: ${fileRejected.errors[0].message}`;
      notifications.show({
        title: `${t("File rejected")}: ${fileRejected.file.name}`,
        message: message,
        color: 'red',
        autoClose: 5000,
        withCloseButton: true
      });
    });
  }

  return (
    <Stack gap="xs">
      {edit &&
        <Group>
          <Dropzone
            openRef={openRef}
            activateOnClick={false}
            classNames={{ root: indexClasses.dropZone, inner: indexClasses.dropZoneInner }}
            onDrop={onDrop}
            onReject={onReject}
            maxSize={5 * 1024 ** 2} // 5 mb
            accept={["image/x-icon", MIME_TYPES.png, MIME_TYPES.jpeg]}
            multiple={false}
            style={{ flex: 1, minHeight: 40, padding: theme.spacing.xs }}>
            <Group justify="center">
              <Button onClick={() => openRef.current?.()} style={{ pointerEvents: 'all' }}
                variant="default" size="xs" leftSection={<IconUpload size="16" />}>{t("Upload image")}</Button>
            </Group>
          </Dropzone>
          {onDelete && edit && value &&
            <Button color='red' variant='subtle' leftSection={<IconTrash size="16" />} onClick={onDelete}>{t("Delete")}</Button>
          }
        </Group>
      }

      {internalValue &&
        <Image
          radius="sm"
          w={maxWidth}
          src={internalValue}
        />
      }
    </Stack>
  );
};

import { State, none, useHookstate } from "@hookstate/core";
import { Group, Stack, Text, Textarea } from "@mantine/core";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import NumberInputWithControl from "src/components/number-input-with-control";
import SwitchButton from "src/components/switch-button";
import { BotContentDisambiguation, GetBotDefaultContent } from "src/stores/bots";
import { IconArrowRampRight as ArrowRampRight } from "@tabler/icons-react";
import AppContext from "src/services/app-context";
import classes from 'src/pages/index.module.css';
import { container } from "src/inversify.config";
import { AppConfiguration } from "src/core/services/authentication-service";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";

const BotContentDisambiguationCard: FC<{
  state: State<BotContentDisambiguation>;
  cardkey: string;
  edit?: boolean;
}> = ({ state, cardkey, edit = false }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(Object.keys(scopedState.message?.value)[0] ?? defaultAllowedLanguages[0]);
  const defaultBotContent = GetBotDefaultContent('Router',  t);

  const onChangeMessage = (state: State<BotContentDisambiguation>, value: string) => {
    if (state.message[selectedLanguage]?.value) {
      state.message[selectedLanguage].body.set(value);
    }
    else {
      state.message[selectedLanguage].set({ body: value });
    }

    setIsDirty(true);
  }

  const onDeleleLanguage = (lang: string) => {
    scopedState.message[lang].set(none);
    setIsDirty(true);
    setSelectedLanguage(Object.keys(scopedState.message?.value)[0]);
  }

  const onAddLanguage = (lang: string) => {
    scopedState.message[lang].set(defaultBotContent.disambiguation.message[lang]);
    setIsDirty(true);
  }

  return (
    <CollapsibleCard
      title={<Text fw={500}>{t('Disambiguation')}</Text>}
      cardKey={cardkey}
      icon={<ArrowRampRight />}
      rightToolbar={
        <LanguageSegmentedControl
          editable={edit}
          data={Object.keys(scopedState.message?.value)}
          language={selectedLanguage}
          onChange={setSelectedLanguage}
          onDelete={onDeleleLanguage}
          onAdd={onAddLanguage}
        />
      }>
      <Stack>
        <SwitchButton
          className={classes.switchButton}
          label={t("Enable")}
          description={t("Enables the option to show the user a message so they can better clarify their intention. This refers to situations where a word or phrase can have multiple meanings or interpretations, which can cause confusion in understanding the text.") as string}
          checked={scopedState.enable.value}
          onChange={value => { scopedState.enable.set(value); setIsDirty(true); }}
          readOnly={!edit}
        />
        <Group align="flex-start" grow>
          <NumberInputWithControl
            required
            width="100%"
            label={t("Confidence")}
            description={t("Minimum value that must reach to confidently identify the user's intent.") as string}
            precision={6}
            min={0}
            step={0.1}
            max={1}
            value={scopedState.confidence.value}
            onChange={(value) => { scopedState.confidence.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />
          <NumberInputWithControl
            required
            width="100%"
            label={t("Similarity")}
            description={t("Measure of how close two words, phrases, or texts are to each other.") as string}
            precision={6}
            min={0}
            step={0.01}
            max={1}
            value={scopedState.similarity.value}
            onChange={(value) => { scopedState.similarity.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />
          <NumberInputWithControl
            required
            width="100%"
            label={t("Maximum number of suggestions")}
            description={t("Set a limit for the number of suggestions to display at once.") as string}
            min={0}
            step={1}
            max={10}
            value={scopedState.maxSuggestions.value}
            onChange={(value) => { scopedState.maxSuggestions.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />
        </Group>
        <Group align="flex-start" justify="space-between" grow>
          <Textarea
            autosize
            minRows={3}
            maxRows={6}
            required={edit}
            readOnly={!edit}
            variant={edit ? 'default' : 'unstyled'}
            style={{ flex: 1 }}
            label={t("Disambiguation message")}
            description={t("This is the message your bot will send to the customer before a list of possible options.") as string}
            value={scopedState.message[selectedLanguage]?.body?.value ?? ''}
            onChange={(event) => onChangeMessage(scopedState, event.target.value)}
          />
        </Group>
      </Stack>
    </CollapsibleCard>
  );
};

export default BotContentDisambiguationCard;
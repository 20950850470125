import { State, useHookstate } from "@hookstate/core";
import { Group, Stack, Switch, Text } from "@mantine/core";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import { BotSearchOptions } from "src/stores/bots";
import AppContext from "src/services/app-context";
import SwitchButton from "src/components/switch-button";
import NumberInputWithControl from "src/components/number-input-with-control";
import { IconSearch } from "@tabler/icons-react";
import classes from 'src/pages/index.module.css';

const BotContentSearchCard: FC<{
  state: State<BotSearchOptions>;
  cardkey: string;
  edit?: boolean;
  rightToolbar?: React.ReactNode;
}> = ({ state, cardkey, edit = false, rightToolbar }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  const onChangeEnableTruncatePassages = (value: boolean) => {
    if (edit) {
      scopedState.maxPassageLength.set(value ? 1024 : 0);
      setIsDirty(true);
    }
  }

  return (
    <CollapsibleCard
      title={
        <Group>
          <Text fw={500}>{t('Search result aggregation')}</Text>
        </Group>
      }
      cardKey={cardkey}
      rightToolbar={rightToolbar}
      icon={<IconSearch />}>

      <Stack>
        <SwitchButton
          className={classes.switchButton}
          label={t("Enable cross encoder")}
          description={t("Re-rank all items returned from all search skills using a cross encoder. Otherwise, items will be sorted by their corresponding score.") as string}
          checked={scopedState.enable.value}
          onChange={value => { scopedState.enable.set(value); setIsDirty(true); }}
          readOnly={!edit}>
          {scopedState.enable.value ?
            <Stack gap="xs">
              <Group align="flex-start" grow>
                <NumberInputWithControl
                  required
                  label={t("Cut-off")}
                  description={t("Maximum value to apply cross encoder algorithm.") as string}
                  precision={6}
                  min={0}
                  step={0.1}
                  max={1}
                  value={scopedState.cutOff.value ?? 0.95}
                  onChange={(value) => { scopedState.cutOff.set(value as number); setIsDirty(true); }}
                  readOnly={!edit}
                />
              </Group>
            </Stack>
            :
            null
          }
        </SwitchButton>
        <Group align="flex-start" grow>
          <NumberInputWithControl
            required
            width="100%"
            label={t("Confidence")}
            description={t("Minimum value that must be reached to show item in search results.") as string}
            precision={6}
            min={0}
            step={0.1}
            max={1}
            value={scopedState.confidence.value}
            onChange={(value) => { scopedState.confidence.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />
          <NumberInputWithControl
            required
            label={t("Maximum number of results")}
            description={t("Sets a limit for the number of search results to aggregate.") as string}
            min={0}
            step={1}
            max={10}
            value={scopedState.maxResults.value ?? 3}
            onChange={(value) => { scopedState.maxResults.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />
        </Group>
        <Group align="flex-start" grow>
          <Switch
            mt={5}
            label={<Text fw={500}>{t("Truncate passage length")}</Text>}
            description={t("Sets whether passages should be truncated.") as string}
            checked={scopedState.maxPassageLength.value > 0}
            onChange={(e) => onChangeEnableTruncatePassages(e.target.checked)}
            readOnly={!edit}
          />
          {scopedState.maxPassageLength.value > 0 &&
            <NumberInputWithControl
              required
              label={t("Maximum length")}
              description={t("Maximum characters per passage, if it exceeds it will be truncated.") as string}
              min={0}
              step={512}
              max={32000}
              value={scopedState.maxPassageLength.value ?? 1024}
              onChange={(value) => { scopedState.maxPassageLength.set(value as number); setIsDirty(true); }}
              readOnly={!edit}
            />
          }
        </Group>
      </Stack>
    </CollapsibleCard>
  );
};

export default BotContentSearchCard;
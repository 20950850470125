import { State, none, useHookstate } from "@hookstate/core";
import { Group, Input, Text, Stack, Code, Textarea, Switch, Box } from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SkillLabel } from "src/components/skill-label";
import { SkillSelector } from "src/components/skill-selector";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import { SkillContentStep } from "src/stores/skills";
import SkillContentStepWrapper from "./skill-content-step-wrapper";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";

const SkillContentStepExternalSkill: FC<{
  state: State<SkillContentStep>;
  edit?: boolean;
  index: number;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ state, edit, index, leftToolbar, rightToolbar }) => {
  const scopedState = useHookstate(state.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0])[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  }, [scopedState.text])

  useEffect(() => {
    const defaultLang = allowedLanguages[0];
    let defaultUserText: { [key: string]: string } = {};
    defaultUserText[defaultLang] = t("<Text for the external skill>", { lng: defaultLang });

    if (scopedState.forwardUserText?.value) {
      scopedState.text.set([]);
    }
    if (!scopedState.forwardUserText?.value) {
      scopedState.text[scopedState?.text?.length].set(defaultUserText);
    }
  }, [scopedState.forwardUserText.value])

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(none);
    }
    setIsDirty(true);
    if (scopedState?.value?.text?.length > 0) {
      setSelectedLanguage(Object.keys(scopedState?.text?.value?.[0])[0]);
    }
  }

  const onAddLanguage = (language: string) => {
    const defaultText = t("<Text for the external skill>", { lng: language });
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(defaultText);
    }
    setIsDirty(true);
  }

  return (
    <SkillContentStepWrapper
      index={index}
      state={state}
      edit={edit}
      leftToolbar={
        <Group align="center" gap={5}>
          {leftToolbar}
          <LanguageSegmentedControl
            size="sm"
            compact
            editable={edit}
            data={scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : undefined}
            language={selectedLanguage}
            onChange={setSelectedLanguage}
            onDelete={onDeleleLanguage}
            onAdd={onAddLanguage}
          />
        </Group>
      }
      rightToolbar={rightToolbar}>
      {edit &&
        <Stack gap="xs">
          <Group align="center">
            <Input.Label w={120} required={edit}>{t("Skill")}:</Input.Label>
            <Box style={{ flex: 1 }}>
              <SkillSelector
                width="100%"
                value={scopedState?.skillId?.value}
                onChange={(value) => { scopedState.skillId.set(value); setIsDirty(true); }}
              />
            </Box>
          </Group>

          <Group align="center">
            <Input.Label w={120}>{t("Forward")}:</Input.Label>
            <Group align="center">
              <Switch
                checked={scopedState.forwardUserText?.value}
                onChange={e => { scopedState.forwardUserText.set(e.target.checked); setIsDirty(true); }}
              />
              <Text size="sm">{t("Forward user text")}</Text>
            </Group>
          </Group>

          {!scopedState.forwardUserText?.value &&
            <Group align="flex-start">
              <Input.Label w={120}></Input.Label>
              <Textarea
                autosize
                minRows={3}
                maxRows={10}
                style={{ flex: 1 }}
                value={scopedState.text?.[0]?.[selectedLanguage as any]?.value as any}
                onChange={(event) => { (scopedState.text[0])[selectedLanguage as any].set(event.target.value as any); setIsDirty(true); }}
              />
            </Group>
          }
        </Stack>
      }
      {!edit &&
        <Stack gap="xs">
          <div>
            <Text span fw={500}>{t("Call skill")} </Text>
            <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" ><SkillLabel value={scopedState.skillId.value} lang={selectedLanguage} /></Code>
          </div>
          <div>
            <Text span fw={500}>{t("Forward")} </Text>
            {scopedState.forwardUserText?.value
              ?
              <Text span fs="italic">{t("user text")}</Text>
              :
              <Text span fs="italic">{scopedState.text?.[selectedLanguage as any]?.value as any}</Text>
            }
          </div>
        </Stack>
      }
    </SkillContentStepWrapper>
  );
};

export default SkillContentStepExternalSkill;
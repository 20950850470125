import { Tooltip, ActionIcon } from "@mantine/core";
import { IconWorld, IconWorldOff } from "@tabler/icons-react";
import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BotBasicInfoItem, BotContentUserPreferences, UserPreferences } from "src/stores/bots";
import { chatPreferencesGlobalState } from "./chat-utils";
import { useHookstate } from "@hookstate/core";

const ChatUserInternetAccess: FC<{
  botInfo: BotBasicInfoItem;
  isDebug?: boolean;
}> = ({ botInfo, isDebug = false }) => {
  const { t } = useTranslation();
  const [botUserPreferences, setBotUserPreferences] = useState<BotContentUserPreferences>({} as BotContentUserPreferences);
  const chatPreferences = useHookstate(chatPreferencesGlobalState); 

  useEffect(() => {
    if (botInfo) {
      const botUserPreferences = JSON.parse(botInfo.userPreferences) as BotContentUserPreferences;
      setBotUserPreferences(botUserPreferences);
    }
  }, [JSON.stringify(botInfo)])

  const onChangeInternetAccess = (value: boolean) => {
    if (botUserPreferences?.allowUserChangeInternetAccess || isDebug) {
      let newValues = JSON.parse(JSON.stringify(chatPreferences.value)) as UserPreferences;
      newValues.allowInternetAccess = value;
      chatPreferences.set(newValues);
    }
  }

  return (
    <>
      {botUserPreferences && chatPreferences.value ?
        (
          <Tooltip label={chatPreferences.value.allowInternetAccess ? t("Allows Internet access if search skills support it") : t("Does not allow Internet access to search skills.")}>
            <ActionIcon
              size={42}
              style={!botUserPreferences.allowUserChangeInternetAccess && !isDebug ? { cursor: 'not-allowed' } : undefined}
              variant={chatPreferences.value.allowInternetAccess ? "outline" : "filled"}
              color={chatPreferences.value.allowInternetAccess ? "green" : "red"}
              onClick={() => botUserPreferences.allowUserChangeInternetAccess || isDebug ? onChangeInternetAccess(!chatPreferences.value.allowInternetAccess) : undefined}>
              {chatPreferences.value.allowInternetAccess ? <IconWorld /> : <IconWorldOff />}
            </ActionIcon>
          </Tooltip>
        )
        :
        <></>}
    </>
  );
};

export default ChatUserInternetAccess;
import { Text, Container, Group, Title, Card } from '@mantine/core';
import { IconWand } from '@tabler/icons-react';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContentWrapper from 'src/components/content-wrapper';
import HeaderContext from 'src/services/header-context';

const SkillLanding: FC<{}> = () => {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);

  useEffect(() => {
    setHeader(t('Skills'), 'Skills', <IconWand size={34} />, t('Skills') as string, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/skills`, breadcrumbName: t('Skills') },
    ]);
  }, []);

  return (
    <ContentWrapper>
      <Card withBorder>
        <Container size={700} p="lg">
          <Group>
            <IconWand size={48} />
            <Title order={1}>
              {t('You are in')}{' '}
              <Text component="span" inherit>
                {t('Skills')}
              </Text>
            </Title>
          </Group>

          <Text size="lg" c="dimmed" mt="lg">
            {t('You can browse the different skills in the sidebar on the left, make filters and select an element to see its details')}
          </Text>
        </Container>
      </Card>
    </ContentWrapper>
  );
};

export default SkillLanding;

import { Badge, Progress, Tooltip } from '@mantine/core';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from '../inversify.config';
import { BotTrainingStatus } from '../stores/bots';
import { DocumentTrainingStatus } from '../stores/documents';
import { JobsStore } from '../stores/jobs';
import { SkillTrainingStatus } from '../stores/skills';

const JobStatus: FC<{
  jobReference: string;
  status: SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus,
  onFinished?: () => void
}> = ({ jobReference, status, onFinished }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(JobsStore), []);
  const state = store.state;
  const [internalStatus, setInternalStatus] = useState<SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus>(status)
  const internalStatusRef = useRef(status);

  useEffect(() => {
    setInternalStatus(status)
    const interval = setInterval(async () => {
      if (jobReference && jobReference !== "" && (status == 'Training' || status == 'Pending') && internalStatusRef.current != 'Trained' && internalStatusRef.current != 'Error') {
        await store.load(jobReference)
        let newState: SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus = 'Pending'
        switch (state.item?.status?.value) {
          case 'Waiting':
            newState = 'Pending';
            break;
          case 'Running':
            newState = 'Training';
            break;
          case 'Succeeded':
            newState = 'Trained';
            break;
          case 'Failed':
          default:
            newState = 'Error';
            break;
        }
        setInternalStatus(newState)
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [jobReference, status])

  useEffect(() => {
    internalStatusRef.current = internalStatus
    if (status != internalStatus && (internalStatus == 'Error' || internalStatus == 'Trained')) {
      if (onFinished) {
        onFinished();
      }
    }
  }, [internalStatus])

  const getColor = (state: SkillTrainingStatus | DocumentTrainingStatus | BotTrainingStatus) => {
    switch (state) {
      case 'Error':
        return 'red';
      case 'Training':
        return 'yellow';
      case 'Trained':
        return 'green';
      default:
        return 'gray';
    }
  }

  const getPercent = (value: number) => {
    if (!value || value < 0.05)
      return 1;
    return Math.round(1000 * value) / 10;
  }

  return (
    <>
      {(internalStatus == 'Training') && <Tooltip label={`${t(internalStatus ?? "Training")} - ${getPercent(state.item?.progress?.value)}%`}><Progress style={{ minWidth: 64 }} animated value={getPercent(state.item?.progress?.value)} /></Tooltip>}
      {(internalStatus != 'Training') && <Badge variant='outline' color={getColor(internalStatus)}>{t(internalStatus ?? "Pending") as string}</Badge>}
    </>
  );
};

export default JobStatus;

import { useHookstate } from "@hookstate/core";
import { Alert, Button, Group, Modal, SimpleGrid, Stack, Text, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAlertCircle, IconPlus } from "@tabler/icons-react";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MembersEditor from "src/components/members-editor";
import TagsEditor from "src/components/tags-editor";
import { formatMessage } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { CollectionItem, CollectionItemStore, NewCollectionForm } from "src/stores/collections";
import { MemberEditorState, MemberItem } from "src/stores/identities";
import { TagItem } from "src/stores/skills";
import { tagsToDict } from "src/utils/tags-utils";
import { dispatch } from "use-bus";

const CreateCollection: FC<{
  opened: boolean;
  onClose: () => void;
  onFinish?: (collectionId: string) => void;
}> = ({ opened, onClose, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const store = useMemo(() => container.get(CollectionItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;

  const form = useForm<NewCollectionForm>({
    initialValues: {
      title: '',
      description: '',
      tags: [],
      members: [],
      accessMode: "MembersOnly"
    },
    validateInputOnBlur: true,
    validate: {
      title: (value) => (!value || value.length === 0 ? t('Invalid name') : null),
      accessMode: (value) => (!value ? t('Invalid access mode') : null)
    },
  });

  const tagsState = useHookstate({
    tags: [] as TagItem[]
  });

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);

  const onSubmit = async (values: NewCollectionForm) => {
    const newItem = {
      title: values.title,
      description: values.description,
      accessMode: membersState.accessMode.value,
      tags: tagsToDict(tagsState.tags.value as TagItem[]),
      members: membersState.members.value,
    } as CollectionItem;

    let response = await store.create(newItem);
    if (response) {
      navigate(`/admin/collections/${response.id}/edit`);
      dispatch('@@ui/COLLECTION_LIST_REFRESH');
      form.reset();
      onClose();
    }
  }

  const onCloseModal = () => {
    form.reset();
    store.clearError();
    onClose();
  }

  useEffect(() => {
    if (form.values.accessMode === 'Everyone') {
      form.setFieldValue('members', []);
    }
  }, [form.values.accessMode])

  return (
    <Modal
      opened={opened}
      onClose={onCloseModal}
      title={<Text>{t("Create a collection")}</Text>}
      size="70%"
      closeOnClickOutside={false}>
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}
      <form onSubmit={form.onSubmit((values: NewCollectionForm) => onSubmit(values))}>
        <Stack>
          <TextInput
            required
            data-autoFocus
            label={t('Title')}
            description={t("Provide a full name for your collection to help search and understand its purpose.")}
            {...form.getInputProps('title')}
          />

          <Textarea
            label={t('Description')}
            rows={3}
            {...form.getInputProps('description', { type: 'input' })}
          />

          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing="lg">
            <TagsEditor state={tagsState.tags} />

            <MembersEditor state={membersState} creation />
          </SimpleGrid>

          <Group align="center" justify="flex-end" wrap="nowrap">
            <Button
              disabled={!form.isValid()}
              loading={isBusy}
              leftSection={<IconPlus />}
              type="submit">
              {t("Create")}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default CreateCollection;
import { ActionIcon, Badge, Center, Group, Input, Stack, Table, Text, TextInput, useMantineColorScheme } from '@mantine/core';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconClipboardList as ClipboardList, IconPlus, IconTrash as Trash } from '@tabler/icons-react';
import { TagItem } from 'src/stores/skills';
import { none, State, useHookstate } from '@hookstate/core';
import CollapsibleCard from './collapsible-card';
import AppContext from 'src/services/app-context';

const PropertiesCard: FC<{
  cardKey: string;
  mode: 'view' | 'edit';
  state: State<TagItem[]>;
}> = ({ cardKey, mode, state }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <CollapsibleCard
      title={
        <Group justify='flex-start'>
          <Text fw={500}>{t('Properties')}</Text>
          {mode === 'edit' &&
            <ActionIcon variant='subtle' color="gray" onClick={() => { scopedState[scopedState.length].set({ name: '', value: '' } as TagItem); setIsDirty(true); }}>
              <IconPlus />
            </ActionIcon>
          }
        </Group>
      }
      cardKey={cardKey}
      icon={<ClipboardList />}
      collapseInfoRender={
        <Badge variant='light' color={colorScheme === 'dark' ? 'dark' : 'gray'}>{scopedState?.value?.length} {t("properties")}</Badge>
      }>
      <>
        {mode === 'edit' &&
          <form>
            <Stack gap="xs" justify="flex-start">
              <Input.Wrapper description={<Text size='xs'>{t('To add new property click on the Add property button.')}</Text>}>
                {scopedState?.map((item: State<TagItem>, index) => (
                  <Group key={index} mt="xs">
                    <TextInput
                      required
                      placeholder={t('Name') as string}
                      style={{ flex: 1 }}
                      value={item.value.name}
                      onChange={(event) => { item.name.set(event.currentTarget.value); setIsDirty(true); }}
                    />
                    <TextInput
                      placeholder={t('Value') as string}
                      style={{ flex: 1 }}
                      value={item.value.value}
                      onChange={(event) => { item.merge(i => ({ value: event.currentTarget.value })); setIsDirty(true); }}
                    />
                    <ActionIcon
                      color="red"
                      variant='subtle'
                      onClick={() => { scopedState[index].set(none); setIsDirty(true); }}>
                      <Trash />
                    </ActionIcon>
                  </Group>
                ))}
              </Input.Wrapper>
            </Stack>
          </form>
        }
        {mode === 'view' &&
          <>
            {scopedState?.value && scopedState.value.length > 0 &&
              <Table striped highlightOnHover>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>{t('Name')}</Table.Th>
                    <Table.Th>{t('Value')}</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {scopedState.value.map((v, index) => (
                    <Table.Tr key={`property-${index}`}>
                      <Table.Td className="bold">{v.name}</Table.Td>
                      <Table.Td style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{v.value}</Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            }
            {(scopedState?.value?.length === 0) &&
              <Center>
                <Text c="dimmed" size="sm">{t('No properties')}</Text>
              </Center>
            }
          </>
        }
      </>
    </CollapsibleCard>
  );
};

export default PropertiesCard;

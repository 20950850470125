import { State, useHookstate } from "@hookstate/core";
import { Group, Badge, Avatar } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { IconExternalLink } from "@tabler/icons-react";
import { AsEnumerable } from "linq-es5";
import { FC } from "react";
import { ChatRequest, ChatResponse, ChatItem } from "src/stores/chat";
import { DocumentSummary } from "src/stores/documents";
import { isNullOrWhitespace } from "../../core/utils/object";

const ChatMessageSuggestionsCard: FC<{
  message: ChatRequest | ChatResponse;
  state: State<ChatItem>;
  onFinish: (text: string, signal: AbortSignal) => void;
  addNewMessage: (text: string, signal: AbortSignal, scopedState: State<ChatItem>, onFinish: (text: string, signal: AbortSignal, selectedDocuments?: DocumentSummary[], importFiles?: FileWithPath[]) => void, selectedDocuments?: DocumentSummary[], importFiles?: FileWithPath[]) => void;
}> = ({ message, state, onFinish, addNewMessage }) => {
  const scopedState = useHookstate(state);

  const chatResponse = (message as ChatResponse);

  const renderSuggestions = () => {
    const items = AsEnumerable(chatResponse?.actions).Where(x => x.sticky === false && (x.type === 'MessageBack' || x.type === 'OpenUrl')).ToArray();
    if (items?.length > 0) {
      return (
        <Group align="center" gap="xs" style={{ padding: '0 5px' }} mt="md">
          {items.map((m, index) =>
            <>
              {m.type === 'OpenUrl' &&
                <Badge key={`openurl-${index}`} variant="outline" component="a" target="_blank" href={m.properties.url} radius="sm"
                  rightSection={<IconExternalLink size={10} />} style={{ cursor: 'pointer' }} styles={{ root: { height: 'auto' }, label: { whiteSpace: 'normal' } }}>
                  <Group align="center" gap={0}>
                    {m.properties && !isNullOrWhitespace(m.properties.icon_url) && <Avatar size={12} mt={0} mr={5} src={m.properties.icon_url} radius="sm" />}
                    {m.title}
                  </Group>
                </Badge>
              }
              {m.type === 'MessageBack' &&
                <Badge key={`messageback-${index}`} variant="outline" radius="sm"
                  style={{ cursor: 'pointer' }} styles={{ root: { height: 'auto' }, label: { whiteSpace: 'normal' } }}
                  onClick={() => addNewMessage(m.title, AbortSignal.timeout(300000), scopedState, onFinish)}>
                  <Group align="center" gap={0}>
                    {m.properties && !isNullOrWhitespace(m.properties.icon_url) && <Avatar size={12} mt={0} mr={5} src={m.properties.icon_url} radius="sm" />}
                    {m.title}
                  </Group>
                </Badge>
              }
            </>
          )}
        </Group>);
    }
    else {
      return null;
    }
  }

  return (
    <>{renderSuggestions()}</>
  );
}

export default ChatMessageSuggestionsCard;
import { ActionIcon, Alert, Avatar, Badge, Box, Center, Group, Input, SegmentedControl, Select, Stack, Text, rem, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconLock, IconLockOpen, IconPlus, IconUsers, IconWorld, IconTrash as Trash, IconUsers as Users } from '@tabler/icons-react';
import { getAvatarText } from 'src/core/utils/object';
import { IdentitySelector } from './identity-selector';
import { AppConfiguration, useAuthentication } from 'src/core/services/authentication-service';
import CollapsibleCard from './collapsible-card';
import { none, State, useHookstate } from '@hookstate/core';
import { AccessMode, MemberEditorState, MemberItem, MemberPrivileges } from 'src/stores/identities';
import AppContext from 'src/services/app-context';
import { UserIsOwner } from 'src/utils/permissions';
import { BotMemberPrivileges } from 'src/stores/bots';
import { container } from 'src/inversify.config';
import { DataTable } from 'mantine-datatable';

interface MemberRecordItem {
  name: string;
  privileges: string;
  index: number;
}

const MembersCard: FC<{
  state: State<MemberEditorState>;
  mode: 'view' | 'edit';
  isBot?: boolean;
  cardKey: string;
}> = ({ cardKey, mode, state, isBot = false }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const [authStatus] = useAuthentication();
  const isOwner = authStatus?.user.value?.isOwner || UserIsOwner(state?.members?.value as MemberItem[]);
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const sharingAccessMode = container.get<AppConfiguration>("AppConfiguration").sharingAccessMode;
  const [records, setRecords] = useState<MemberRecordItem[]>(scopedState?.members?.value ? scopedState.members.value.map((i, index) => ({ name: i.identity, privileges: i.privileges, index: index })) : []);

  useEffect(() => {
    if (scopedState?.members?.value) {
      setRecords(scopedState?.members?.value?.map((i, index) => ({ name: i.identity, privileges: i.privileges, index: index })));
    }
  }, [scopedState?.members]);


  const getPrivilegesData = () => {
    if (!isBot) {
      const privileges = isOwner ? ['Owner', 'Contributor', 'Reader'] as MemberPrivileges[] : ['Contributor', 'Reader'] as MemberPrivileges[];
      return privileges.map(p => ({ value: p, label: t(`${p}`) }));
    }
    if (isBot) {
      const privileges = isOwner ? ['Owner', 'Contributor', 'User', 'Reader', 'ImpersonatedUser', 'Tester'] as BotMemberPrivileges[]
        : ['Contributor', 'User', 'Reader', 'ImpersonatedUser', 'Tester'] as BotMemberPrivileges[];
      return privileges.map(p => ({ value: p, label: t(`${p}`) }));
    }
  }

  const getTableColumns = () => {
    return [{
      accessor: 'name',
      title: t('Name'),
      render: (m: MemberRecordItem) => (
        <Group gap="xs" align='center'>
          <Avatar size="sm" color={theme.colors[theme.primaryColor][6]}>
            {getAvatarText(m.name)}
          </Avatar>
          <Text size="sm">
            {m.name} ({t(m.privileges)})
          </Text>
        </Group>
      )
    }];
  }

  return (
    <CollapsibleCard
      title={
        <Group justify='flex-start'>
          <Text fw={500}>{t('Members')}</Text>
          {mode === 'edit' && isOwner && sharingAccessMode !== 'OwnerOnly' &&
            <ActionIcon variant='subtle' color="gray" onClick={() => { scopedState.members[scopedState.members.length].set({ identity: '', privileges: 'Contributor' }); setIsDirty(true); }}>
              <IconPlus />
            </ActionIcon>
          }
        </Group>
      }
      cardKey={cardKey}
      icon={<Users />}
      collapseInfoRender={
        <Badge variant='light' color={colorScheme === 'dark' ? 'dark' : 'gray'}>{scopedState?.members?.value?.length} {t("members")}</Badge>
      }>
      <>
        {mode === 'edit' &&
          <form>
            <Stack gap="xs" justify="flex-start">
              <Input.Wrapper
                required
                label={t("Access mode")}>
                <Stack>
                  <SegmentedControl
                    data={[
                      {
                        value: 'Everyone',
                        label: (
                          <Center>
                            <IconWorld style={{ width: rem(16), height: rem(16) }} />
                            <Box ml={10}>{t("Public access")}</Box>
                          </Center>
                        ),
                        disabled: sharingAccessMode !== 'OrganizationWide'
                      },
                      {
                        value: 'MembersOnly',
                        label: (
                          <Center>
                            <IconUsers style={{ width: rem(16), height: rem(16) }} />
                            <Box ml={10}>{t("Members only")}</Box>
                          </Center>
                        ),
                      },
                    ]}
                    value={scopedState.accessMode.value}
                    onChange={(value) => scopedState.accessMode.set(value as AccessMode)}
                  />
                </Stack>
              </Input.Wrapper>

              <Input.Wrapper description={sharingAccessMode !== 'OwnerOnly' ? <Text size='xs'>{t('To add click on the Add member button, you can write to search or select it from the list.')}</Text> : undefined}>
                {scopedState?.members?.map((member, index) => (
                  <Group key={index} mt="xs">
                    {isOwner &&
                      <>
                        <IdentitySelector
                          required
                          width="100%"
                          style={{ flex: 1 }}
                          value={member.identity.value}
                          onChange={(value) => { member.identity.set(value); setIsDirty(true); }}
                        />
                        <Select
                          style={{ width: '150px' }}
                          allowDeselect={false}
                          data={getPrivilegesData()}
                          value={member.privileges.value}
                          onChange={(value) => { member.privileges.set(value as string); setIsDirty(true); }}
                        />
                        <ActionIcon
                          color="red"
                          variant='subtle'
                          onClick={() => { member.set(none); setIsDirty(true); }}>
                          <Trash />
                        </ActionIcon>
                      </>
                    }
                    {!isOwner &&
                      <Text>{member.identity.value} ({t(member.privileges.value)})</Text>
                    }
                  </Group>
                ))}
              </Input.Wrapper>
            </Stack>
          </form>
        }
        {mode === 'view' &&
          <Stack gap="xs" justify="flex-start">
            <Alert variant="light"
              title={scopedState.accessMode.value === 'Everyone' ? t("Public access") as string : t("Members only") as string}
              icon={scopedState.accessMode.value === 'Everyone' ? <IconLockOpen /> : <IconLock />} />

            {scopedState?.members?.value?.length > 0 &&
              <DataTable
                noHeader
                height={records.length > 6 ? 300 : undefined}
                striped
                highlightOnHover
                columns={getTableColumns()}
                records={records}
              />
            }
            <div>
              {(scopedState?.members?.value?.length === 0) &&
                <Center>
                  <Text c="dimmed" size="sm">{t('No members')}</Text>
                </Center>
              }
            </div>
          </Stack>
        }
      </>
    </CollapsibleCard>
  );
};

export default MembersCard;

import { ActionIcon, Anchor, Group, Stack, Text, Tooltip } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrawlerPatchSummary, CrawlerPatchesSummaryStore } from "src/stores/crawlers";
import { container } from "src/inversify.config";
import { Query } from "src/core/stores/data-store";
import EditCrawlerPatch from "./crawler-patch-edit";
import ImportCrawlerPatches from "./crawler-patch-import";
import { sleep } from "src/core/utils/object";
import TableListV2, { TableListV2Model } from "src/core/ui/table-list/table-list-v2";
import { DataTableSortStatus } from "mantine-datatable";
import { IconReplace, IconTableImport } from "@tabler/icons-react";

const CrawlerPatches: FC<{
  collectionId: string;
  crawlerId: string;
  canContribute: boolean;
  edit?: boolean;
}> = ({ collectionId, crawlerId, canContribute, edit = false }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(CrawlerPatchesSummaryStore), []);
  const state = store.state;
  const [query, setQuery] = useState<Query>(undefined as any);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'applyTo', direction: 'asc' });

  useEffect(() => {
    if (collectionId && crawlerId) {
      store.setCollectionAndCrawler(collectionId, crawlerId);
      const newQuery = {
        ...query,
        //orderBy: [{ field: 'applyTo', direction: 'Ascending', useProfile: false }],
        skip: 0,
        parameters: {
          orderByField: sortStatus.columnAccessor,
          orderByDirection: sortStatus.direction
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, crawlerId]);

  useEffect(() => {
    if (query) {
      store.load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onReload = () => {
    store.load(query)
  };

  const onConfirmDelete = async (selectedKeys: CrawlerPatchSummary[]) => {
    if (selectedKeys && selectedKeys.length > 0) {
      selectedKeys.forEach((key) => { store.delete(key.id); });
      await sleep(500);
      onReload();
    }
  };

  const model: TableListV2Model<CrawlerPatchSummary> = {
    data: store.toListState(state.value),
    query,
    columns:[
      {
        accessor: 'applyTo',
        title: t('ApplyTo'),
        sortable: true,
        render: (item: CrawlerPatchSummary) => (
          <Tooltip label={edit ? t("Edit patch") : t("View patch")}>
            <Anchor onClick={() => { setSelectedItem(item.id); setShowEditModal(true); }}>{item.applyTo}</Anchor>
          </Tooltip>
        )
      },
      {
        accessor: 'title',
        title: t('Title'),
        sortable: true,
        render: (item: CrawlerPatchSummary) => (
          <Text style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{item.title}</Text>
        )
      }
    ]
  };

  return (
    <Stack gap="md">
      <TableListV2
        model={model}
        onQueryChanged={setQuery}
        onRefresh={onReload}
        striped
        highlightOnHover
        sortable
        sortStatus={sortStatus}
        onSortStatusChanged={setSortStatus}
        leftToolBarRender={
          <Group>
            <Group align="center" gap="xs">
              <IconReplace />
              <Text fw={500}>{t('Crawler patches')}</Text>
            </Group>
            {edit &&
              <Tooltip label={t("Import patches")}>
                <ActionIcon onClick={() => setShowImportModal(true)} variant='subtle' color="gray">
                  <IconTableImport />
                </ActionIcon>
              </Tooltip>
            }
          </Group>
        }
        showNewIcon={canContribute && edit}
        onNewItemClick={() => { setSelectedItem(undefined); setShowEditModal(true); }}
        selectable={canContribute && edit}
        onDeleteSelectedClick={(selectedKeys: CrawlerPatchSummary[]) => onConfirmDelete(selectedKeys)}
      />
      {showEditModal &&
        <EditCrawlerPatch
          opened={showEditModal}
          patchId={selectedItem}
          onClose={() => { setShowEditModal(false); setSelectedItem(undefined); onReload(); }}
          edit={edit}
          collectionId={collectionId}
          crawlerId={crawlerId}
        />
      }
      {showImportModal &&
        <ImportCrawlerPatches
          opened={showImportModal}
          onClose={() => { setShowImportModal(false); onReload(); }}
          collectionId={collectionId}
          crawlerId={crawlerId}
        />
      }
    </Stack>
  );
};

export default CrawlerPatches;
/* eslint-disable quotes */

// This config defines the editor's view.
export const monacoOptions = {
  fontSize: 14,
  fontLigatures: true,
  readOnly: false,
  minimap: { enabled: false }
};

// This config defines the editor's behavior.
export const monacoConfiguration = {
  comments: {
    // symbols used for start and end a block comment. Remove this entry if your language does not support block comments
    blockComment: ['{#', '#}', '/*', '*/', '##'],
    lineComment: '--',
  },
  // symbols used as brackets
  brackets: [
    ['{', '}'],
    ['[', ']'],
    ['(', ')'],
  ],
  // symbols that are auto closed when typing
  autoClosingPairs: [
    { open: '{#', close: '#}' },
    { open: '{{', close: '}}' },
    { open: '{%', close: '%}' },
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
  ],
  // symbols that that can be used to surround a selection
  surroundingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
  ],
};

// This config defines how the language is displayed in the editor.
export const monacoJinjaDef = {
  // Set defaultToken to invalid to see what you do not tokenize yet
  defaultToken: '',
  //tokenPostfix: '.sql',
  ignoreCase: true,

  brackets: [
    { open: '[', close: ']', token: 'delimiter.square' },
    { open: '(', close: ')', token: 'delimiter.parenthesis' },
    { open: '{', close: '}', token: 'delimiter.curly' },
  ],
  jinjaKeywords: ['for', 'in', 'endfor', 'if', 'elif', 'else', 'endif'],
  tokenizer: {
    root: [
      { include: '@whitespace' },
      { include: '@comments' },
      { include: '@numbers' },
      { include: '@strings' },
      [/[;,.]/, 'delimiter'],
      [/[()]/, '@brackets'],
      [
        ///[\w@#$]+/,
        /(\w+)/,
        {
          cases: {
            '@jinjaKeywords': 'type',
            '@default': 'identifier',
          },
        },
      ],
      //[/[<>=!%&+\-*/|~^]/, 'operators'],
      { include: '@jinjaTags' },
      [/\[error.*/, "custom-error"],
    ],
    whitespace: [[/\s+/, 'white']],
    comments: [
      [/--+.*/, 'comment'],
      [/\/\*/, { token: 'comment.quote', next: '@comment' }],
      [/\{\#/, { token: 'comment.quote', next: '@commentJinja' }],
      [/\#\#/, { token: 'comment.quote', next: '@commentJinja' }],
    ],
    comment: [
      [/[^*/]+/, 'comment'],
      // Not supporting nested comments, as nested comments seem to not be standard?
      // i.e. http://stackoverflow.com/questions/728172/are-there-multiline-comment-delimiters-in-sql-that-are-vendor-agnostic
      // [/\/\*/, { token: 'comment.quote', next: '@push' }],    // nested comment not allowed :-(
      [/\*\//, { token: 'comment.quote', next: '@pop' }],
      [/./, 'comment'],
    ],
    commentJinja: [
      [/[^#}]+/, 'comment'],
      [/\#\}/, { token: 'comment.quote', next: '@pop' }],
      [/\#\#/, { token: 'comment.quote', next: '@pop' }],
      [/./, 'comment'],
    ],
    numbers: [
      [/0[xX][0-9a-fA-F]*/, 'number'],
      [/[$][+-]*\d*(\.\d*)?/, 'number'],
      [/((\d+(\.\d*)?)|(\.\d+))([eE][\-+]?\d+)?/, 'number'],
    ],
    strings: [
      [/N'/, { token: 'string', next: '@string' }],
      [/'/, { token: 'string', next: '@string' }],
      [/N"/, { token: 'string', next: '@string_double' }],
      [/"/, { token: 'string', next: '@string_double' }],
    ],
    string: [
      [/[^']+/, 'string'],
      [/''/, 'string'],
      [/'/, { token: 'string', next: '@pop' }],
    ],
    string_double: [
      [/[^"]+/, 'string'],
      [/""/, 'string'],
      [/"/, { token: 'string', next: '@pop' }],
    ],
    jinjaTags: [
      [/{{/, { token: 'regexp' }],
      [/{%/, { token: 'regexp' }],
      [/}}/, { token: 'regexp' }],
      [/%}/, { token: 'regexp' }],
    ],
    /*complexIdentifiers: [
            [/\[/, { token: 'identifier.quote', next: '@bracketedIdentifier' }],
            [/"/, { token: 'identifier.quote', next: '@quotedIdentifier' }]
        ],
        bracketedIdentifier: [
            [/[^\]]+/, 'identifier'],
            [/]]/, 'identifier'],
            [/]/, { token: 'identifier.quote', next: '@pop' }]
        ],
        quotedIdentifier: [
            [/[^"]+/, 'identifier'],
            [/""/, 'identifier'],
            [/"/, { token: 'identifier.quote', next: '@pop' }]
        ],*/
  },
};

export const monacoJinjaDef2 = {
  wordPattern: /(-?\d*\.\d\w*)|([^`~!@$^&*()=+[{]}\\|;:'",.<>\/\s]+)/g,
  comments: {
    blockComment: ["{#", "#}"],
  },
  autoClosingPairs: [
    { open: "{# ", close: " #}" },
    { open: "{% ", close: " %}" },
    { open: "{{ ", close: " }}" },
    { open: "[", close: "]" },
    { open: "(", close: ")" },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
  ],
  surroundingPairs: [
    { open: '"', close: '"' },
    { open: "'", close: "'" },
    // HTML
    { open: "<", close: ">" },
  ],
  ////////////////////
  defaultToken: "",
  tokenPostfix: "",
  ignoreCase: true,
  brackets: [
    { token: "delimiter.twig", open: "{%", close: "%}" },
    { token: "delimiter.twig", open: "{{", close: "}}" },
    { token: "delimiter.twig", open: "{#", close: "#}" },
    { token: "delimiter.square.twig", open: "[", close: "]" },
    { token: "delimiter.paren.twig", open: "(", close: ")" }
  ],
  keywords: [
    "if",
    "else",
    "elif",
    "endif",
    "for",
    "endfor",
    "while",
    "endwhile",
    "do",
    "loop",
    "break",
    "continue",
    "as",
    "with",
    "without",
    "context",
    "include",
    "import",
    "from",
    "as",
    "true",
    "false",
    "none",
  ],
  tokenizer: {
    root: [
      [/\s+/],
      [/{#/, "comment.twig", "@commentState"],
      [/{%[-~]?/, "delimiter.twig", "@blockState"],
      [/{{[-~]?/, "delimiter.twig", "@variableState"],
      [/[^{]+/, ""],
    ],
    commentState: [
      [/#}/, "comment.twig", "@pop"],
      [/./, "comment.twig"],
    ],
    blockState: [
      [/-?%}/, "delimiter.twig", "@pop"],
      [/\b(if|else|elif|endif|for|endfor|while|endwhile|do|loop|break|continue|as|with|without|context|include|import|from|true|false|none)\b/, "keyword.twig"],
      { include: "expression" },
    ],
    variableState: [
      [/-?}}/, "delimiter.twig", "@pop"],
      { include: "expression" },
    ],
    expression: [
      [/\s+/],
      [/\(|\)|\[|\]|\{|\}/, "delimiter.twig"],
      [/\d+(\.\d+)?/, "number.twig"],
      [/"([^#"\\]*(?:\\.[^#"\\]*)*)"/, "string.twig"],
      [/'([^'\\]*(?:\\.[^'\\]*)*)'/, "string.twig"],
      [/\b(and|or|not|b-and|b-xor|b-or)\b/, "operators.twig"],
      [/\b(starts with|ends with|matches)\b/, "operators.twig"],
      [/\b(in|is)\b/, "operators.twig"],
      [/\+|-|\*{1,2}|\/{1,2}|%|==|!=|<|>|>=|<=|\||~|:|\.{1,2}|\?{1,2}|=/, "operators.twig"],
      [/[^\W\d][\w]*/, {
        cases: {
          "@keywords": "keyword.twig",
          "@default": "variable.twig",
        },
      }],
    ],
  },
}

import { Alert, Button, Card, Group, LoadingOverlay, Modal, SimpleGrid, Stack, Stepper, Table, Text, TextInput, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { FC, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { container } from "src/inversify.config";
import { formatMessage, normalizeName } from "src/core/utils/object";
import { useHookstate } from "@hookstate/core";
import { NewServiceConnectionItem, ServiceConnectionItemStore } from "src/stores/serviceconnections";
import AppContext from "src/services/app-context";
import { ServiceConnectionItem, ServiceConnectionRef, getServiceTypeFields } from "./services/serviceconnection";
import { IconAlertCircle, IconArrowLeft, IconArrowRight, IconPlus } from "@tabler/icons-react";
import TestConnectionButton from "./test-connection-button";
import stepperClasses from 'src/pages/stepper.module.css';
import ServiceConnectionCategoryTypePicker from "./serviceconnection-category-type-picker";
import MembersEditor from "src/components/members-editor";
import { MemberEditorState, MemberItem } from "src/stores/identities";

const CreateServiceConnectionWizard: FC<{
  opened: boolean;
  onClose: () => void;
  onFinish?: () => void;
}> = ({ opened, onClose, onFinish }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const store = useMemo(() => container.get(ServiceConnectionItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const { setIsDirty } = useContext(AppContext);
  const serviceConnectionRef = useRef<ServiceConnectionRef>(null);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [serviceName, setServiceName] = useState<string | undefined>(undefined);

  const scopedState = useHookstate({
    name: '',
    type: 'Gpt',
    category: 'Llm',
    accessMode: 'MembersOnly',
    content: '{}',
    members: []
  } as NewServiceConnectionItem);

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);

  const validateStep1 = () => {
    return scopedState.name.value.length > 0 && scopedState.type.value.length > 0 && scopedState.category.value.length > 0;
  }

  const validateStep2 = () => {
    return serviceConnectionRef.current?.isValid() as boolean;
  }

  const goToValidateAndCreate = () => {
    if (validateStep2()) {
      scopedState.content.set(serviceConnectionRef.current?.getContent() as string);
      nextStep();
      setFormError(undefined);
    }
    else {
      setFormError(t("Review and complete all required fields") as string);
    }
  }

  const onSubmit = async () => {
    const copy = JSON.parse(JSON.stringify(membersState.value)) as MemberEditorState;
    scopedState.accessMode.set(copy.accessMode);
    scopedState.members.set(copy.members);

    await store.create(scopedState.value as NewServiceConnectionItem);

    if (!state.errorMessage.value) {
      setIsDirty(false);
      onFinish?.();
    }
  }

  const onCloseModal = () => {
    setIsDirty(false);
    setFormError(undefined);
    onClose();
  }

  const onChangeServiceConnection = (value: ServiceConnectionItem) => {
    if (value) {
      scopedState.category.set(value.category);
      scopedState.type.set(value.type);
      setServiceName(value.label);
    }
  }

  return (
    <Modal
      title={<span>{t("Create service connection")}</span>}
      size='70%'
      withCloseButton
      closeOnClickOutside={false}
      onClose={onCloseModal}
      opened={opened}
      styles={{
        content: {
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        },
        header: {
          alignItems: 'flex-start'
        }
      }}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        {formError && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => setFormError(undefined)}>
            <Text>{formError}</Text>
          </Alert>
        )}
        <form>
          <Stepper
            active={active}
            onStepClick={setActive}
            classNames={stepperClasses}>
            <Stepper.Step
              label={`${t("Type")}`}
              description={t("Select connection type")}
              allowStepSelect={active > 0}
              loading={active === 0 && isBusy}>
              <Card withBorder shadow="sm" mb="md" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
                <Stack>
                  <TextInput
                    required
                    data-autofocus
                    style={{ flex: 1 }}
                    label={t('Name')}
                    value={scopedState.name.value}
                    onChange={(event) => scopedState.name.set(event.target.value)}
                    onBlur={() => scopedState.name.set((c) => normalizeName(c, true))}
                  />

                  <ServiceConnectionCategoryTypePicker
                    category={scopedState.category.value}
                    type={scopedState.type.value}
                    onChange={onChangeServiceConnection}
                    required
                  />
                </Stack>
              </Card>

              <Group align="center" justify="space-between" mt="lg">
                <Group align="center" justify="flex-start" wrap="nowrap">
                </Group>
                <Group justify="flex-end">
                  <Button
                    disabled={!validateStep1()}
                    rightSection={<IconArrowRight />}
                    onClick={nextStep}>{t("Next")}
                  </Button>
                </Group>
              </Group>
            </Stepper.Step>

            <Stepper.Step
              label={`${t("Configure")}`}
              description={t("Configure your service")}
              allowStepSelect={active > 1}
              loading={active === 1 && isBusy}>

              <Card withBorder shadow="sm" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
                <Card.Section withBorder inheritPadding py="xs" mb="xs">
                  <Text fw={500}>{serviceName ?? t(scopedState.type.value)}</Text>
                </Card.Section>
                <SimpleGrid cols={2}>
                  <>
                    {getServiceTypeFields('<service-id>', scopedState.type.value, serviceConnectionRef, undefined, true)}
                  </>
                  <MembersEditor state={membersState} creation />
                </SimpleGrid>
              </Card>

              <Group align="center" justify="space-between" mt="lg">
                <Group align="center" justify="flex-start" wrap="nowrap">
                </Group>

                <Group justify="flex-end">
                  <Button variant="default" onClick={prevStep} leftSection={<IconArrowLeft />}>{t("Back")}</Button>
                  <Button onClick={goToValidateAndCreate} leftSection={<IconArrowRight />}>{t("Next")}</Button>
                </Group>
              </Group>
            </Stepper.Step>

            <Stepper.Step
              label={`${t("Create")}`}
              description={t("Validate and create")}
              allowStepSelect={active > 1}
              loading={active === 2 && isBusy}>
              <Card withBorder shadow="sm" mb="md" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
                <Stack>
                  <Group justify="space-between">
                    <Text mb="md">{t("This is the information provided to create the service connection. Please review before continuing.")}</Text>
                  </Group>
                  <Stack gap={0}>
                    {serviceConnectionRef.current?.getResume()}
                    <Table highlightOnHover>
                      <Table.Thead>
                        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}></Table.Th>
                        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}></Table.Th>
                      </Table.Thead>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td>{t("Access mode")}</Table.Td>
                          <Table.Td>{membersState.accessMode.value}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>{t("Members")}</Table.Td>
                          <Table.Td>{membersState.members.value.length}</Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Stack>
                </Stack>
              </Card>

              <Group align="center" justify="space-between" mt="lg">
                <Group align="center" justify="flex-start" wrap="nowrap">
                </Group>
                <Group justify="flex-end">
                  <TestConnectionButton
                    content={scopedState.content.value}
                    category={scopedState.category.value}
                    type={scopedState.type.value}
                  />
                  <Button variant="default" onClick={prevStep} leftSection={<IconArrowLeft />}>{t("Back")}</Button>
                  <Button
                    onClick={onSubmit}
                    loading={isBusy}
                    leftSection={<IconPlus />}>
                    {t("Create")}
                  </Button>
                </Group>
              </Group>
            </Stepper.Step>
          </Stepper>
        </form>
      </div>
    </Modal>
  );
};

export default CreateServiceConnectionWizard;
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { Stack, Tabs, Grid, Card, Group, Menu, ActionIcon, Text, Badge, Alert, SimpleGrid, Button, Box, Tooltip, useMantineColorScheme, Loader } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import { GetSkillDefaultContent, SkillContent, SkillDialogContent, SkillItem, SkillItemStore, SkillSearchContent, SkillSnapshotSelectStore, TagItem, ChatSkillStore } from 'src/stores/skills';
import CollapsibleCard from 'src/components/collapsible-card';
import TagsCard from 'src/components/tags-card';
import MembersCard from 'src/components/members-card';
import BootstrapIntentsCard from 'src/components/bootstrap-intents-card';
import { formatMessage, truncateText } from 'src/core/utils/object';
import { tagsToArray } from 'src/utils/tags-utils';
import { State, useHookstate } from '@hookstate/core';
import moment from 'moment';
import ListItem from 'src/components/listitem-component';
import StatusBadge from 'src/components/status-badge';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import useBus, { dispatch } from 'use-bus';
import SkillHistory from './skill-history';
import VariablesCard from 'src/components/variables-card';
import MDEditor from '@uiw/react-md-editor';
import { UserCanContribute } from 'src/utils/permissions';
import { MemberEditorState, MemberItem } from 'src/stores/identities';
import SkillContentIntentsCard from './skill-content-intents';
import SkillContentStepsCard from './skill-content-steps';
import SkillContentSearchCard from './skill-content-search';
import jsyaml from "js-yaml";
import TrainingStatusBadge from 'src/components/training-status-badge';
import { LanguageSegmentedControl } from 'src/components/language-segmented-control';
import SkillContentEntitiesCard from './skill-content-entities';
import TrainButton from 'src/components/train-button';
import { IconWand, IconMessages, IconFileSearch, IconBinaryTree2, IconAlertCircle, IconMessageCircle, IconTags, IconFileCode, IconPrompt, IconHistory, IconCloudUpload, IconDotsVertical, IconEdit, IconCloudOff, IconTrash, IconInfoSquare, IconFileInfo, IconClock, IconUserCircle, IconLanguage, IconNotes, IconCodePlus, IconPlugConnected, IconMathFunction } from '@tabler/icons-react';
import SkillContentParametersCard from './skill-content-parameters';
import ContentWrapper from 'src/components/content-wrapper';
import SkillApiDetails from './skill-api-details';
import { AppConfiguration, authStatus } from 'src/core/services/authentication-service';
import classes from 'src/pages/index.module.css';
import ChatComponent from 'src/components/chat';
import { monacoOptions } from 'src/configurations/editor-config-jinja';
import { Editor } from '@monaco-editor/react';
import { NotFoundInfo } from 'src/core/ui/not-found-component';

const SkillDescriptionCard: FC<{
  state: State<SkillItem>;
}> = ({ state }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState.description?.value ? Object.keys(scopedState.description?.value)[0] : defaultAllowedLanguages[0]);

  return (
    <CollapsibleCard
      title={<Text fw={500}>{t('Description')}</Text>}
      cardKey='skill-description-card'
      icon={<IconNotes />}
      rightToolbar={
        <LanguageSegmentedControl
          data={scopedState.description?.value ? Object.keys(scopedState.description?.value) : undefined}
          language={selectedLanguage}
          onChange={setSelectedLanguage}
        />
      }>
      <div data-color-mode={colorScheme}>
        <MDEditor.Markdown source={scopedState.description?.[selectedLanguage]?.value ?? t('(No description)')} />
      </div>
    </CollapsibleCard>
  );
}

const SkillDetail: React.FC = () => {
  let navigate = useNavigate();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const params = useParams();
  const [skillId, setSkillId] = useState<string>();
  const [skillSnapshotId, setSkillSnapshotId] = useState<string>();
  const modals = useModals();
  const [activeTab, setActiveTab] = useState("conversation");
  const store = useMemo(() => container.get(SkillItemStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const tagsScopedState = useHookstate([] as TagItem[]);
  const variablesScopedState = useHookstate([] as TagItem[]);
  const isOwner = authStatus?.user.value?.isOwner;
  const canContribute = isOwner || UserCanContribute(state?.item?.members?.value as MemberItem[]);
  const contentScopedState = useHookstate({} as SkillContent);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const [yamlContent, setYamlContent] = useState<string>('');
  const testSkillStore = useMemo(() => container.get(ChatSkillStore), []);
  const snapShotSelectStore = useMemo(() => container.get(SkillSnapshotSelectStore), []);

  let customMonacoOptions = { ...monacoOptions };
  customMonacoOptions.readOnly = true;

  useEffect(() => {
    setSkillSnapshotId(undefined);
    setSkillId(params.skillId);
  }, [params.skillId])

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(`${t("Skill detail")}`, 'Skills', <IconWand size={34} />, `${t("Skill detail")} | ${t("Skills")}`, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/skills`, breadcrumbName: t('Skills') },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);


  useEffect(() => {
    window.scrollTo(0, 0);
    load(skillId as string);
    snapShotSelectStore.setSkillId(skillId as string);
  }, [skillId]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = async (skillId: string) => {
    if (skillId) {
      await store.load(skillId);
      setSkillSnapshotId(state.item?.currentSnapshot?.id?.value);
    }
  };

  useBus(
    '@@ui/SKILL_DETAIL_REFRESH',
    () => load(skillId as string),
    [skillId],
  );

  useBus(
    '@@ui/LANGUAGE_SEGMENTED_CONTROL/SELECTED_LANGUAGE',
    (event) => setSelectedLanguage(event.payload),
    [selectedLanguage]
  )

  useEffect(() => {
    if (state.item.value) {
      setHeader(`${state.item.title.value}`, 'Skills', <IconWand size={34} />, `${state.item.title.value} | ${t("Skills")}`, true, true);
      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      const variables = tagsToArray(state.item.currentSnapshot?.variables?.value ?? []);
      variablesScopedState.set(variables);

      const copy = JSON.parse(JSON.stringify(state.item.value)) as MemberEditorState;
      membersState.set({
        accessMode: copy.accessMode,
        members: copy.members
      });

      if (state.item.currentSnapshot?.content?.value) {
        contentScopedState.set(JSON.parse(state.item.currentSnapshot.content.value));
        const doc = jsyaml.load(state.item.currentSnapshot.content.value);
        setYamlContent(jsyaml.dump(doc));
      }
      else {
        const defaultContent = GetSkillDefaultContent(state.item.type.value);
        contentScopedState.set(defaultContent);
        const doc = jsyaml.load(JSON.stringify(defaultContent, null, 2));
        setYamlContent(jsyaml.dump(doc));
      }
    }
  }, [state.item])

  const navigateToEdit = () => {
    navigate(`/admin/skills/${skillId}/edit`);
  };

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const openTrainModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Train snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to train this item?')}
          <br></br>
          {t('The training process might take a while.')}
        </Text>
      ),
      labels: { confirm: t('Train snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmTrain(snapshotId),
    });

  const onConfirmTrain = async (snapshotId: string) => {
    await store.trainSnapshot(skillId as string, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(skillId as string);
      dispatch('@@ui/SKILL_LIST_REFRESH');
      dispatch('@@ui/SKILL_HISTORY_LIST_REFRESH');
    }
  };

  const openConfirmPublish = () => modals.openConfirmModal({
    title: t("Publish"),
    children: (
      <Text size="sm">
        {t('Are you sure you want to publish this skill?')}
      </Text>
    ),
    labels: { confirm: t('Confirm'), cancel: t('Cancel') },
    onConfirm: async () => {
      await store.publish(skillId as string);
      load(skillId as string);
      dispatch('@@ui/SKILL_LIST_REFRESH');
      dispatch('@@ui/SKILL_HISTORY_LIST_REFRESH');
      state.errorMessage.value ? showKoNotification() : showOkNotification();
    },
  });

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Delete skill')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete skill'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(),
    });

  const onConfirmDelete = async () => {
    await store.delete(skillId as string);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      navigate(`/admin/skills`);
      dispatch('@@ui/SKILL_LIST_REFRESH');
    }
  };

  const openRetireModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Retire skill')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to retire this skill?')}
          <br></br>
          {t('All published snapshots will also be retired')}
        </Text>
      ),
      labels: { confirm: t('Retire skill'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmRetire(),
    });

  const onConfirmRetire = async () => {
    await store.retire(skillId as string);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(skillId as string);
      dispatch('@@ui/SKILL_LIST_REFRESH');
      dispatch('@@ui/SKILL_HISTORY_LIST_REFRESH');
    }
  };

  const getIconType = (item: SkillItem) => {
    switch (item.type) {
      case 'Function':
        return <IconMathFunction size={20} />;
      case 'Dialog':
        return <IconMessages size={20} />;
      case 'Search':
        return <IconFileSearch size={20} />;
      default:
        return <IconBinaryTree2 size={20} />;
    }
  }

  const notFound = !isBusy && state.status?.value && state.status.value === 404;

  return (
    <ContentWrapper>
      <div style={{ position: 'relative' }} className={classes.fullHeightContainer}>
        {isBusy ?
          <Stack my="xl" justify='center' align='center'>
            <Loader size="xl" />
            <Text c="dimmed">{t("Please wait")}</Text>
          </Stack>
          :
          <>
            {notFound &&
              <NotFoundInfo
                title={t('No item found')}
                description={t('No item found or you do not have permissions') as string} />
            }
            {state && state.item && state.item.value && !notFound && (
              <>
                {errorMessage && (
                  <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
                    <Text>{formatMessage(errorMessage)}</Text>
                  </Alert>
                )}
                <Grid align="stretch">
                  <Grid.Col span="auto">
                    <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
                      <Tabs.List>
                        {state.item.type.value === 'Search' && <Tabs.Tab value="conversation" leftSection={getIconType(state.item.value as SkillItem)}>{t('Search')}</Tabs.Tab>}
                        {(state.item.type.value === 'Dialog' || state.item.type.value === 'Function') && <Tabs.Tab value="conversation" leftSection={getIconType(state.item.value as SkillItem)}>{t('Conversation')}</Tabs.Tab>}
                        {(state.item.type.value === 'Dialog' || state.item.type.value === 'Function') && <Tabs.Tab value="intents" leftSection={<IconMessageCircle size={20} />}>{t('Intents')}</Tabs.Tab>}
                        {(state.item.type.value === 'Dialog' || state.item.type.value === 'Function') && <Tabs.Tab value="parameters" leftSection={<IconCodePlus size={20} />}>{t('Parameters')}</Tabs.Tab>}
                        <Tabs.Tab value="entities" leftSection={<IconTags size={20} />}>{t('Entities')}</Tabs.Tab>
                        <Tabs.Tab value="api" leftSection={<IconPlugConnected size={20} />}>{t('API details')}</Tabs.Tab>
                        <Tabs.Tab value="source" leftSection={<IconFileCode size={20} />}>{t('Source code')}</Tabs.Tab>
                        <Tabs.Tab value="test" leftSection={<IconPrompt size={20} />}>{t('Test skill')}</Tabs.Tab>
                        <Tabs.Tab value="history" leftSection={<IconHistory size={20} />}>{t('History')}</Tabs.Tab>
                      </Tabs.List>
                    </Tabs>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Group justify='right'>
                      <StatusBadge status={state.item.value.currentSnapshot?.status} />
                      <TrainingStatusBadge status={state.item.trainingStatus.value} jobReference={state.item.jobReference.value} />
                      {canContribute &&
                        <TrainButton
                          jobReference={state.item.jobReference.value}
                          status={state.item.trainingStatus.value}
                          onFinished={() => load(skillId as string)}
                          onClick={() => openTrainModal(skillSnapshotId as string)}
                        />
                      }
                      {state.item.value.currentSnapshot?.trainingStatus === 'Trained' && state.item.value.currentSnapshot?.status !== 'Published' && canContribute &&
                        <Button
                          loading={isBusy}
                          onClick={openConfirmPublish}
                          leftSection={<IconCloudUpload />}>
                          {t("Publish")}
                        </Button>
                      }
                      {canContribute &&
                        <Menu withinPortal withArrow width={250} shadow="md" position="bottom-end">
                          <Menu.Target>
                            <Tooltip label={t("More options")}>
                              <ActionIcon variant='subtle' color="gray">
                                <IconDotsVertical />
                              </ActionIcon>
                            </Tooltip>
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Label>{t("Skill options")}</Menu.Label>
                            <Menu.Item leftSection={<IconEdit size={20} />} onClick={navigateToEdit}>{t("Edit skill")}</Menu.Item>
                            <Menu.Divider />
                            <Menu.Label>{t("Danger zone")}</Menu.Label>
                            <Menu.Item disabled={state.item.currentSnapshot?.status?.value !== 'Published'} color="red" leftSection={<IconCloudOff size={20} />} onClick={openRetireModal}>{t('Retire skill')}</Menu.Item>
                            <Menu.Item color="red" leftSection={<IconTrash size={20} />} onClick={openDeleteModal}>{t('Delete skill')}</Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      }
                    </Group>
                  </Grid.Col>
                </Grid>
                <Box px={0} py={0} mt="xs" style={{ flexGrow: 1 }}>
                  {activeTab === "conversation" &&
                    <Grid gutter={'xs'}>
                      <Grid.Col span={{ base: 12, lg: 8 }}>
                        {!state.item.value.currentSnapshot && <Alert icon={<IconAlertCircle size={16} />} title={t("Skill misconfiguration!")} color="red">
                          {t('Your source code is corrupt and we cannot process it. Please edit it and correct the problems.')}
                        </Alert>}
                        <Stack gap="xs">
                          {(state.item.type.value === 'Dialog' || state.item.type.value === 'Function') && <SkillContentStepsCard
                            cardkey='skill-content-steps'
                            key={`skill-content-steps-${skillId}`}
                            skillId={skillId as string}
                            skillType={state.item.type.value}
                            state={(contentScopedState as State<SkillDialogContent>).steps}
                          />}
                          {state.item.type.value === 'Search' && <SkillContentSearchCard
                            cardkey='skill-content-steps'
                            key={`skill-content-search-${skillId}`}
                            state={(contentScopedState as State<SkillSearchContent>)}
                          />}
                        </Stack>
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, lg: 4 }}>
                        <Stack gap="xs">
                          <CollapsibleCard
                            title={<Text fw={500}>{t('Info')}</Text>}
                            cardKey='skill-info-card'
                            icon={<IconInfoSquare />}
                            collapseInfoRender={
                              <Badge variant='light' color="gray">{truncateText(state.item.title.value, 20)}</Badge>
                            }>
                            <Stack align='stretch'>
                              <SimpleGrid cols={{ base: 1, md: 2 }}>
                                <ListItem title={t("Title")} icon={<IconFileInfo size={20} />} description={state.item.title.value} />
                                {state?.item?.currentSnapshot?.createdOn &&
                                  <>
                                    <ListItem title={t("Modified on")} icon={<IconClock size={20} />} description={moment(state.item.currentSnapshot.createdOn.value).fromNow()} />
                                    <ListItem title={t("Modified by")} icon={<IconUserCircle size={20} />} description={state.item.currentSnapshot.createdBy.value} />
                                  </>
                                }
                                {state.item.currentSnapshot?.languages &&
                                  <ListItem title={t("Languages")} icon={<IconLanguage size={20} />} description={state.item.currentSnapshot.languages.value.join(', ')} />
                                }
                                <ListItem title={t("Type")} icon={<IconBinaryTree2 size={20} />} description={t(state.item.type.value) as string} />
                              </SimpleGrid>
                            </Stack>
                          </CollapsibleCard>

                          <SkillDescriptionCard state={state.item} />

                          <MembersCard
                            cardKey='skill-members-card'
                            mode="view"
                            state={membersState}
                            key={`skill-members-${skillId}`}
                          />

                          {state.item.type.value !== 'Search' && <BootstrapIntentsCard
                            cardKey='skill-intents-card'
                            mode="view"
                            state={state.item.currentSnapshot?.bootstrapIntents}
                            key={`skill-intents-${skillId}`}
                          />}

                          <TagsCard
                            cardKey='skill-tags-card'
                            mode="view"
                            state={tagsScopedState}
                            key={`skill-tags-${skillId}`}
                          />

                          <VariablesCard
                            cardKey='skill-variables-card'
                            mode="view"
                            state={variablesScopedState}
                            key={`skill-variables-${skillId}`}
                          />
                        </Stack>
                      </Grid.Col>
                    </Grid>
                  }
                  {activeTab === "intents" && (state.item.type.value === 'Dialog' || state.item.type.value === 'Function') &&
                    <SkillContentIntentsCard
                      key={`skill-content-intents-${skillId}`}
                      cardkey='skill-content-intents'
                      state={(contentScopedState as State<SkillDialogContent>).intents}
                    />
                  }
                  {activeTab === "parameters" && (state.item.type.value === 'Dialog' || state.item.type.value === 'Function') &&
                    <SkillContentParametersCard
                      key={`skill-content-parameters-${skillId}`}
                      cardkey='skill-content-parameters'
                      type={state.item.type.value}
                      state={(contentScopedState as State<SkillDialogContent>).parameters}
                      options={(contentScopedState as State<SkillDialogContent>).options}
                    />
                  }
                  {activeTab === "entities" &&
                    <SkillContentEntitiesCard
                      key={`skill-entities-${skillId}`}
                      cardkey='skill-content-entities'
                      state={(contentScopedState as State<SkillDialogContent>).entities}
                    />
                  }
                  {activeTab === "api" && (
                    <SkillApiDetails
                      cardkey='skill-api-details'
                      state={state.item}
                      key={`skill-api-details-${skillId}`}
                    />
                  )}
                  {activeTab === "source" && (
                    <Card withBorder h='100%'>
                    {yamlContent &&
                      <Editor
                        width="100%"
                        height="100%"
                        options={customMonacoOptions}
                        language="yaml"
                        value={yamlContent}
                        theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                      />
                    }
                  </Card>
                  )}
                  {activeTab === "test" && skillId && skillSnapshotId && (
                    <ChatComponent
                      store={testSkillStore}
                      id={skillId}
                      snapshotId={skillSnapshotId}
                      snapshotSelectStore={snapShotSelectStore}
                      key={`bot-testchat-${skillId}-${skillSnapshotId}`}
                      hideTokens
                      sendInitialMessage={false}
                    />
                  )}
                  {activeTab === "history" && (
                    <Stack gap='sm'>
                      <SkillHistory
                        skillId={skillId as string}
                        canContribute={canContribute}
                        key={`skill-history-${skillId}`}
                      />
                    </Stack>
                  )}
                </Box>
              </>
            )}
          </>
        }
      </div>
    </ContentWrapper>
  );
};

export default SkillDetail;

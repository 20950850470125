import { none, State, useHookstate } from "@hookstate/core";
import { ActionIcon, Badge, Card, Center, Group, Highlight, Input, Menu, Select, Stack, Switch, Text, Textarea, TextInput, Tooltip, useMantineTheme } from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import AppContext from "src/services/app-context";
import { DialogSkillOptions, ParameterType, SkillContentParameter, SkillTypeEnum } from "src/stores/skills";
import { getEntitiesInText, normalizeName } from "src/core/utils/object";
import { IconPlus, IconTrash, IconDotsVertical, IconCopy, IconCodePlus } from "@tabler/icons-react";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import classes from 'src/pages/index.module.css';
import { LanguageSegmentedControl } from "src/components/language-segmented-control";
import SwitchButton from "src/components/switch-button";

const SkillContentParameterEditText: FC<{
  state: State<string>;
  label: string;
}> = ({ state, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <Group align="center">
      <Input.Label w={120} required>{label}:</Input.Label>
      <TextInput
        required
        style={{ flex: 1 }}
        value={scopedState.value}
        onChange={(event) => { scopedState.set(event.currentTarget.value); setIsDirty(true); }}
        onBlur={() => scopedState.set((c) => normalizeName(c, true))}
      />
    </Group>
  );
}

const SkillContentParameterEditDescription: FC<{
  state: State<string>;
  label: string;
}> = ({ state, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <Group align="flex-start">
      <Input.Label w={120} required>{label}:</Input.Label>
      <Textarea
        autosize
        minRows={3}
        maxRows={10}
        required
        style={{ flex: 1 }}
        value={scopedState?.value ?? ''}
        onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
      />
    </Group>
  );
}

const SkillContentParameterEditBoolean: FC<{
  state: State<boolean>;
  label: string;
}> = ({ state, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <Group align="center">
      <Input.Label w={120} required>{label}:</Input.Label>
      <Switch
        checked={scopedState.value}
        onChange={e => { scopedState.set(e.target.checked); setIsDirty(true); }}
      />
    </Group>
  );
}

const SkillContentParameterEditType: FC<{
  state: State<ParameterType>;
  label: string;
}> = ({ state, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Group align="center">
      <Input.Label w={50} required>{label}:</Input.Label>
      <Select
        required
        allowDeselect={false}
        style={{ flex: 1 }}
        data={[
          { value: 'String', label: t('String') as ParameterType },
          { value: 'Number', label: t('Number') as ParameterType },
          { value: 'Boolean', label: t('Boolean') as ParameterType },
          { value: 'Date', label: t('Date') as ParameterType },
        ]}
        value={scopedState.value}
        onChange={(value) => { scopedState.set(value as ParameterType); setIsDirty(true); }}
      />
    </Group>
  );
}

const SkillContentParameterEditValues: FC<{
  state: State<SkillContentParameter>;
  label: string;
  lang: string;
}> = ({ state, label, lang }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const { t } = useTranslation();

  const onAddValue = () => {
    scopedState.values[lang].merge(['']);
    setIsDirty(true);
  }

  return (
    <Stack gap={5}>
      <Group align="center">
        <Input.Label w={120}>{label}:</Input.Label>
        <Group align="center" gap={5}>
          <Tooltip withinPortal label={t("Add value")}>
            <ActionIcon onClick={onAddValue} variant='subtle' color="gray">
              <IconPlus size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
      {scopedState.values[lang]?.value && scopedState.values[lang].map((v, index) =>
        <Group align="center" key={index}>
          <Input.Label w={120}></Input.Label>
          <TextInput
            style={{ flex: 1 }}
            value={v.value}
            onChange={(event) => { v.set(event.target.value); setIsDirty(true); }}
          />

          <ActionIcon onClick={() => { v.set(none); setIsDirty(true); }} variant='subtle' color="gray">
            <IconTrash size={16} />
          </ActionIcon>
        </Group>
      )}
    </Stack>
  );
}

const SkillContentParametersCard: FC<{
  type: SkillTypeEnum;
  state: State<SkillContentParameter[]>;
  options: State<DialogSkillOptions>;
  cardkey: string;
  edit?: boolean;
}> = ({ type, state, options, cardkey, edit = false }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const scopedState = useHookstate(state);
  const scopedOptionsState = useHookstate(options);
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].description) : defaultAllowedLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].description)[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].description) : defaultAllowedLanguages);
  }, [scopedState])

  const onAddParameter = () => {
    const defaultLang = allowedLanguages[0];
    let defaultDescription: { [key: string]: string } = {};
    defaultDescription[defaultLang] = t("Parameter description", { lng: defaultLang });

    let defaultSamples: { [key: string]: string[] } = {};
    defaultSamples[defaultLang] = [];

    const newIntent = {
      name: 'param1',
      description: defaultDescription,
      required: false,
      type: 'String',
      values: defaultSamples
    } as SkillContentParameter;
    scopedState.merge([newIntent]);
    setIsDirty(true);
  }

  const onClone = (item: SkillContentParameter) => {
    const copy = JSON.parse(JSON.stringify(item));
    scopedState[scopedState.length].set(copy);
    setIsDirty(true);
  }

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.length; index++) {
      scopedState[index].description[language].set(none);
      scopedState[index].values[language].set(none);
    }

    scopedOptionsState.behavior[selectedLanguage].set(none);

    setIsDirty(true);
    if (scopedState?.value?.length > 0) {
      setSelectedLanguage(Object.keys(scopedState?.value?.[0].values)[0]);
    }
  }

  const onAddLanguage = (language: string) => {
    const defaultDescription = t("Parameter description", { lng: language });

    for (let index = 0; index < scopedState.value.length; index++) {
      scopedState[index].description[language].set(defaultDescription);
      scopedState[index].values[language].set([]);
    }

    scopedOptionsState.behavior[selectedLanguage].set("");

    setIsDirty(true);
  }

  const onChangeBehavior = (value: string) => {
    if (!scopedOptionsState.behavior) {
      scopedOptionsState.set({} as DialogSkillOptions)
    }
    scopedOptionsState.behavior[selectedLanguage].set(value);
    setIsDirty(true);
  }

  return (
    <CollapsibleCard
      title={
        <Group justify='space-between'>
          <Text fw={500}>{t('Parameters')}</Text>
          {edit &&
            <ActionIcon onClick={onAddParameter} variant='subtle' color="gray">
              <IconPlus />
            </ActionIcon>
          }
        </Group>
      }
      cardKey={cardkey}
      icon={<IconCodePlus />}
      rightToolbar={
        <LanguageSegmentedControl
          editable={edit}
          data={scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].description) : undefined}
          language={selectedLanguage}
          onChange={setSelectedLanguage}
          onDelete={onDeleleLanguage}
          onAdd={onAddLanguage}
        />
      }
      collapseInfoRender={
        <Badge variant='light' color='gray'>{scopedState?.value?.length} {t("parameters")}</Badge>
      }>
      <Stack>
        {type === 'Dialog' && <Textarea
          label={t("Behavior")}
          autosize
          minRows={3}
          maxRows={10}
          required={edit}
          readOnly={!edit}
          variant={edit ? 'default' : 'unstyled'}
          style={{ flex: 1 }}
          value={scopedOptionsState.behavior?.[selectedLanguage]?.value ?? ''}
          onChange={(event) => onChangeBehavior(event.target.value)}
        />}
        {type === 'Dialog' && <SwitchButton
          className={classes.switchButton}
          label={t("Request confirmation")}
          checked={scopedOptionsState.requestConfirmation?.value ?? true}
          onChange={value => scopedOptionsState.requestConfirmation.set(value)}
          description={t("Indicates the data entered requires confirmation by the user before continuing.") as string}
          readOnly={!edit}>
        </SwitchButton>}
        {scopedState?.value?.length > 0 && scopedState.map((parameter, index) =>
          <Card
            withBorder
            shadow="xs"
            radius="sm"
            key={index}
            className={classes.stepCard}>
            <Card.Section withBorder inheritPadding py={5}>
              {edit ?
                <Group justify="space-between">
                  <Text fw={500}>{`Parameter # ${index + 1}`}</Text>
                  <Menu withinPortal position="bottom-end" shadow="sm">
                    <Menu.Target>
                      <Tooltip label={t("More options")}>
                        <ActionIcon variant='subtle' color="gray">
                          <IconDotsVertical size={16} />
                        </ActionIcon>
                      </Tooltip>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item leftSection={<IconCopy size={16} />} onClick={() => onClone(parameter.value as SkillContentParameter)}>{t("Clone")}</Menu.Item>
                      <Menu.Item leftSection={<IconTrash size={16} />} color="red" onClick={() => { parameter.set(none); setIsDirty(true); }}>{t("Delete")}</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Group>
                :
                <Group align="center">
                  <Text fw={500}>{`# ${parameter.name.value}`}</Text>
                  <Badge variant='light' size="lg" radius="xs" style={{ textTransform: 'none' }}>{parameter.type.value}</Badge>
                  {parameter.required.value &&
                    <Badge variant="outline" size="lg" radius="xs" color="red" style={{ textTransform: 'none' }}>{t("Required")}</Badge>
                  }
                </Group>
              }
            </Card.Section>

            <Card.Section inheritPadding mt="xs" pb="xs">
              <Stack gap={5}>
                {edit ?
                  <>
                    <Group align="center" grow>

                      <SkillContentParameterEditText
                        label={t("Name")}
                        state={parameter.name}
                      />

                      <SkillContentParameterEditType
                        label={t("Type")}
                        state={parameter.type}
                      />

                      <SkillContentParameterEditBoolean
                        label={t("Required")}
                        state={parameter.required}
                      />
                    </Group>

                    <SkillContentParameterEditDescription
                      label={t("Description")}
                      state={parameter.description[selectedLanguage]}
                    />

                    <SkillContentParameterEditValues
                      key={`parameter-edit-values-${selectedLanguage}`}
                      state={parameter}
                      label={t("Values")}
                      lang={selectedLanguage}
                    />
                  </>
                  :
                  <Text span fs="italic">{parameter.description[selectedLanguage]?.value ?? ''}</Text>
                }
              </Stack>
            </Card.Section>

            {!edit &&
              <Card.Section inheritPadding mt="xs" pb="xs">
                <Group align="center" gap="xs">
                  {parameter.values[selectedLanguage]?.value?.map((p, index) =>
                    <Badge size="xs" radius="sm" p="xs" key={index} className={classes.tag}>
                      <Highlight highlight={getEntitiesInText(p)} color={theme.colors[theme.primaryColor][6]}>{p}</Highlight>
                    </Badge>
                  )}
                </Group>
              </Card.Section>
            }
          </Card>
        )}
        {(!scopedState?.value || scopedState?.value?.length === 0) &&
          <Center>
            <Text c="dimmed" size="sm">{t('No parameters')}</Text>
          </Center>
        }
      </Stack>
    </CollapsibleCard>
  );
};

export default SkillContentParametersCard;
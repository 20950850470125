import { State, useHookstate } from "@hookstate/core";
import { Loader, Group, Tooltip, ActionIcon, Text, Box, Stack, useMantineTheme, useMantineColorScheme, Button, Popover, ScrollArea, Table, Divider, Anchor, Textarea, em, Container, Modal } from "@mantine/core";
import { FileWithPath, FileRejection, Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";
import { IconAlertTriangle, IconSend, IconPaperclip, IconX, IconMessagePlus, IconPlayerStop, IconFiles, IconUserCog, IconRestore } from "@tabler/icons-react";
import { AsEnumerable } from "linq-es5";
import { FC, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { formatBytes, truncateText } from "src/core/utils/object";
import { BotBasicInfoItem, allowedMimeTypes } from "src/stores/bots";
import { ChatItem } from "src/stores/chat";
import { DocumentSummary } from "src/stores/documents";
import useBus, { dispatch } from "use-bus";
import { container } from "src/inversify.config";
import { AppConfiguration } from "src/core/services/authentication-service";
import { useMediaQuery } from "@mantine/hooks";
import ChatUserInternetAccess from "./chat-user-internet-access";
import ChatUserPreferences from "./chat-user-preferences";
import { preferencesModifiedByUserState, setChatPreferencesAsDefault } from "./chat-utils";

export interface ChatFileInfo {
  title: string;
  id?: string;
  size?: number;
  onDelete?: () => void;
}

const ChatSendMessage: FC<{
  state: State<ChatItem>;
  isBusy?: boolean;
  uploadFiles?: boolean;
  showHallucinationsWarning?: boolean;
  onFinish: (text: string, signal: AbortSignal, selectedDocuments?: DocumentSummary[], importFiles?: FileWithPath[]) => void;
  onRestart: () => void;
  addNewMessage: (text: string, signal: AbortSignal, scopedState: State<ChatItem>, onFinish: (text: string, signal: AbortSignal, selectedDocuments?: DocumentSummary[], importFiles?: FileWithPath[]) => void, selectedDocuments?: DocumentSummary[], importFiles?: FileWithPath[]) => void;
  botInfo: BotBasicInfoItem;
  isDebug?: boolean;
}> = ({ state, isBusy, uploadFiles, showHallucinationsWarning, onFinish, onRestart, addNewMessage, botInfo, isDebug }) => {
  const scopedState = useHookstate(state);
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { t } = useTranslation();
  const openRef = useRef<() => void>(null);
  const [importFiles, setImportFiles] = useState<FileWithPath[] | undefined>();
  const [showDragInfo, setShowDragInfo] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [selectedItems, setSelectedItems] = useState<DocumentSummary[] | undefined>(undefined);
  //const [tokens, setTokens] = useState(0);
  const [showUserPreferencesOptions, setShowUserPreferencesOptions] = useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;
  const modifiedByUser = useHookstate(preferencesModifiedByUserState);

  // useEffect(() => {
  //   const tokenizer = new GPT3Tokenizer({ type: 'gpt3' }); // or 'codex'
  //   const encoded: { bpe: number[]; text: string[] } = tokenizer.encode(scopedState.text.value);
  //   setTokens(encoded.bpe.length);
  // }, [scopedState.text])

  useEffect(() => {
    if (AsEnumerable(scopedState.messages.value).Count(m => m.role === 'Assistant') >= 5) {
      setShowWarning(true);
    }
    else {
      setShowWarning(false);
    }
  }, [scopedState.messages])

  const onSendMessage = async () => {
    if (scopedState.text.value.length > 0 || (importFiles && importFiles.length > 0)) { //TODO: si solo esta subiendo ficheros puede enviar sin texto. Debe contestar el bot?
      abortController?.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      addNewMessage(scopedState.text.value, newAbortController.signal, scopedState, () => onFinish(scopedState.text.value, newAbortController.signal, selectedItems, importFiles), selectedItems, importFiles);
      scopedState.text.set('');
      setImportFiles(undefined);
      dispatch('@@ui/CHAT_DOCUMENTS_DESELECTED');
    }
  }

  const onRestartChat = () => {
    if (isBusy) {
      abortController?.abort();
    }
    onRestart();
  }

  const onKeyDown = (event: any) => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
    }
  }

  const onKeyUpMessage = (event: any) => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      onSendMessage();
    }
  }

  const onDrop = async (files: FileWithPath[]) => {
    if (files && files.length > 0) {
      setImportFiles(files);
    }
    setShowDragInfo(false);
  }

  const onReject = (files: FileRejection[]) => {
    files.forEach(fileRejected => {
      const message = `${fileRejected.errors[0].code}: ${fileRejected.errors[0].message}`;
      notifications.show({
        title: `${t("File rejected")}: ${fileRejected.file.name}`,
        message: message,
        color: 'red',
        autoClose: 5000,
        withCloseButton: true
      });
    });
    setShowDragInfo(false);
  }

  useBus(
    '@@ui/CHAT_DOCUMENT_SELECTED',
    (event) => onDocumentSelected(event.payload),
    [selectedItems],
  );

  const onDocumentSelected = (item: DocumentSummary) => {
    setImportFiles(undefined);
    if (!selectedItems || (selectedItems && !AsEnumerable(selectedItems).Any(d => d.id === item.id))) {
      const selected = selectedItems ? [...selectedItems, item] : [item];
      setSelectedItems(selected);
    }
  }

  useBus(
    '@@ui/CHAT_DOCUMENTS_DESELECTED',
    () => { setSelectedItems(undefined) },
    [selectedItems]
  )

  useBus(
    '@@ui/CHAT_DOCUMENT_SUMMARY_DESELECTED',
    (event) => onDocumentSummaryDeselected(event.payload),
    [selectedItems]
  )

  const onDocumentSummaryDeselected = (item: DocumentSummary) => {
    if (selectedItems) {
      const newValues = AsEnumerable(selectedItems).Where(x => x.id !== item.id).ToArray();
      setSelectedItems(newValues);
    }
  }

  useBus(
    '@@ui/CHAT_DOCUMENT_UPLOAD_DESELECTED',
    (event) => onDocumentUploadDeselected(event.payload),
    [importFiles]
  )

  const onDocumentUploadDeselected = (item: FileWithPath) => {
    if (importFiles) {
      const newValues = AsEnumerable(importFiles).Where(x => x.name !== item.name).ToArray();
      setImportFiles(newValues);
    }
  }

  useBus(
    '@@ui/CHAT_DOCUMENT_UPLOAD',
    () => { openRef?.current?.() },
    [openRef]
  )

  const showFiles = (items: ChatFileInfo[], onDeleteAll?: () => void) => (
    <Popover position="top-end" withArrow shadow="xl">
      <Popover.Target>
        <Tooltip label={t("Show all files")}>
          <Button variant="light" size="xs" leftSection={<IconFiles size={20} />} radius="lg">{items.length}</Button>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap="xs">
          <Group justify="space-between">
            <Text>{t("Selected files")}</Text>
            {onDeleteAll &&
              <Anchor component="a" c="red" onClick={onDeleteAll}>
                {t("Delete all")}
              </Anchor>
            }
          </Group>
          <Divider />

          <ScrollArea.Autosize mah={250}>
            <Table striped highlightOnHover withRowBorders={false}>
              <Table.Tbody>
                {items.map((item: ChatFileInfo, index: number) => (
                  <Table.Tr key={item.title}>
                    <Table.Td px={0}>
                      {item.id ?
                        <Anchor target="_blank" href={`${baseUri}/viewer/${item.id}`}>{truncateText(item.title, 40)}</Anchor>
                        :
                        truncateText(item.title, 40)
                      }
                    </Table.Td>
                    {item.size &&
                      <Table.Td px={0} style={{ width: 100 }}>
                        {formatBytes(item.size)}
                      </Table.Td>
                    }
                    <Table.Td px={0} style={{ width: 25 }}>
                      {item.onDelete &&
                        <ActionIcon color="red" size="xs" radius="xl" variant="transparent" onClick={item.onDelete}>
                          <IconX size={20} />
                        </ActionIcon>
                      }
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea.Autosize>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )

  const getLeftSection = () => {
    return (
      <Group gap={0} justify='flex-end' align='center'>
        {(!importFiles || importFiles.length === 0) && (!selectedItems || selectedItems.length === 0) && uploadFiles &&
          <Tooltip withinPortal label={t("Click to attach file")}>
            <ActionIcon
              size={42}
              variant="transparent"
              color="gray"
              radius="md"
              disabled={isBusy}
              style={{ backgroundColor: 'var(--input-bg)' }}
              onClick={() => dispatch('@@ui/CHAT_DOCUMENT_UPLOAD')}>
              <IconPaperclip />
            </ActionIcon>
          </Tooltip>
        }
        {importFiles && importFiles.length > 0 && (!selectedItems || selectedItems.length === 0) &&
          <Group gap={5} align="center">
            {showFiles(importFiles.map((i) => ({ title: i.name, size: i.size, onDelete: () => dispatch({ type: '@@ui/CHAT_DOCUMENT_UPLOAD_DESELECTED', payload: i }) } as ChatFileInfo)), () => setImportFiles(undefined))}
          </Group>
        }
        {(!importFiles || importFiles.length === 0) && (selectedItems && selectedItems.length > 0) &&
          <Group gap={5} align="center">
            {showFiles(selectedItems.map((i) => ({ id: i.id, title: i.title, onDelete: () => dispatch({ type: '@@ui/CHAT_DOCUMENT_SUMMARY_DESELECTED', payload: i }) } as ChatFileInfo)), () => dispatch('@@ui/CHAT_DOCUMENTS_DESELECTED'))}
          </Group>
        }
      </Group>
    );
  }

  const getRightSection = () => {
    return (
      <Group gap={0} justify='flex-end' align='center'>
        {showWarning && showHallucinationsWarning &&
          <Tooltip withinPortal label={t("The bot can have hallucinations. Restart the chat for a more accurate interaction.")}>
            <ActionIcon color="yellow" variant="transparent" size={42}>
              <IconAlertTriangle />
            </ActionIcon>
          </Tooltip>
        }
        <Tooltip withinPortal label={t("Click to send message")}>
          <ActionIcon
            size={42}
            disabled={(scopedState.text.value.length === 0 && (!importFiles || importFiles.length === 0)) || isBusy}
            style={{ backgroundColor: 'var(--input-bg)' }}
            variant="transparent"
            radius="md"
            onClick={onSendMessage}>
            <IconSend />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  }

  const chatInput =
    <Group gap={5} style={{ flex: 1, border: "1px solid var(--mantine-color-gray-filled)", backgroundColor: 'var(--mantine-color-gray-light)', borderRadius: 'var(--mantine-radius-md)' }}>
      {getLeftSection()}
      <Textarea
        autoFocus
        autosize
        minRows={1}
        maxRows={10}
        variant="transparent"
        style={{ flex: 1 }}
        styles={{ section: { justifyContent: 'right' }, input: { paddingLeft: 5, paddingRight: 5 } }}
        readOnly={isBusy}
        size="md"
        placeholder={t("Send a message") as string}
        value={scopedState.text.value}
        onChange={(event) => scopedState.text.set(event.target.value)}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUpMessage}
        rightSection={isBusy ? <Loader mr="xs" type="dots" size="sm" color={theme.colors[theme.primaryColor][6]} /> : undefined}
      />
      {getRightSection()}
    </Group>;

  const renderChatInput = (
    <>
      {uploadFiles ?
        <Dropzone
          openRef={openRef}
          onDrop={onDrop}
          onReject={onReject}
          onDragEnter={(event) => { setShowDragInfo(true) }}
          onDragLeave={(event) => { setShowDragInfo(false) }}
          activateOnClick={false}
          maxSize={50 * 1024 ** 2} // 50 mb
          maxFiles={10} // 10 archivos
          accept={allowedMimeTypes}
          multiple={true}
          styles={{ root: { border: 'none', padding: 0, flex: 1 }, inner: { pointerEvents: 'all' } }}>
          {showDragInfo && (
            <Group grow align='center'
              style={{ border: `1px solid ${theme.colors[theme.primaryColor][3]}`, height: 42 }}>
              <Text ta='center' c={theme.colors[theme.primaryColor][3]}>{t("Drag here your file")}</Text></Group>
          )}
          {chatInput}
        </Dropzone>
        :
        <>
          {chatInput}
        </>
      }
    </>
  );

  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  return (
    <Container p={0}>
      <Box
        style={{
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
          borderRadius: theme.radius.md
        }}>
        <Stack gap={3}>
          {isBusy && <Group align="center" gap="xs" justify="center">
            <Button leftSection={<IconPlayerStop />} onClick={() => abortController?.abort()} size="xs" mb={10} variant="filled" color="gray">
              {t("Stop generation")}
            </Button>
          </Group>}
          <Modal opened={showUserPreferencesOptions} onClose={() => setShowUserPreferencesOptions(false)} centered
            styles={{ title: { width: '100%' } }}
            title={
              <Group justify="space-between" align="center" wrap="nowrap" mr="xs">
                <Text style={{ flex: 1 }}>{t("User preferences")}</Text>
                <Tooltip label={t("Default values")}>
                  <ActionIcon variant="subtle" color="gray" onClick={() => setChatPreferencesAsDefault(botInfo)}>
                    <IconRestore size={16} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            }>
            <ChatUserPreferences botInfo={botInfo} isDebug={isDebug} />
          </Modal>
          <Group align="flex-end" gap="xs">
            <ChatUserInternetAccess botInfo={botInfo} isDebug={isDebug} />
            {!isMobile &&
              <>
                {botInfo?.userPreferences ?
                  <Tooltip label={t("User preferences")}>
                    <ActionIcon
                      variant='outline'
                      color={modifiedByUser.value ? 'var(--mantine-color-orange-6)' : 'gray'}
                      size={42}
                      onClick={() => setShowUserPreferencesOptions((value) => !value)}>
                      <IconUserCog />
                    </ActionIcon>
                  </Tooltip>
                  :
                  <></>
                }
                <Tooltip label={t("Starts a new conversation deleting current history")}>
                  <ActionIcon
                    size={42}
                    variant='filled'
                    color={theme.colors[theme.primaryColor][6]}
                    onClick={onRestartChat}>
                    {<IconMessagePlus />}
                  </ActionIcon>
                </Tooltip>
              </>
            }
            {renderChatInput}
          </Group>
          <Text c="dimmed" size="xs" ta="center">
            {/* {!hideTokens ? <Text span inherit>{tokens} {t("tokens")}</Text> : undefined} */}
            {botInfo?.strategy === 'Llm' ? <Text span inherit>{t("Answers generated by AI may contain errors. Always check the answer.")}</Text> : undefined}
          </Text>
        </Stack>
      </Box>
    </Container >
  );
};

export default ChatSendMessage;
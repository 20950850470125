import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconAlertCircle as AlertCircle, IconMessageChatbot as MessageChatbot, IconHome2 as Home2, IconWand as Wand, IconPlugConnected as PlugConnected, IconCode, IconArrowBackUp, IconDeviceFloppy, IconBrain, IconUserCog } from '@tabler/icons-react';
import { Stack, Grid, Card, Group, Button, Text, Alert, LoadingOverlay, Container, Tabs, Input, TextInput, Title, List, ActionIcon, Tooltip, Select, SimpleGrid, useMantineColorScheme, Box, useMantineTheme } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import { container } from 'src/inversify.config';
import { formatMessage } from 'src/core/utils/object';
import TagsCard from 'src/components/tags-card';
import { State, none, useHookstate } from '@hookstate/core';
import useBus, { dispatch } from 'use-bus';
import { tagsToArray, tagsToDict } from 'src/utils/tags-utils';
import VariablesCard from 'src/components/variables-card';
import { BotContent, BotContentLlm, BotContentSkill, BotContentUserPreferences, BotItem, BotItemStore, BotSnapshotItem, BotStrategy, GetBotDefaultContent, UpsertBotSnapshotItem } from 'src/stores/bots';
import { TagItem } from 'src/stores/skills';
import { useViewportSize } from '@mantine/hooks';
import BotSkillAssignedList from './bot-skill-assigned-list';
import BotSkillAssignedDetail from './bot-skill-assigned-detail';
import BotContentOptionsCard from './bot-content-options';
import BotContentDisambiguationCard from './bot-content-disambiguation';
import BotApiDetails from './bot-api-details';
import { JSONError } from "json-schema-library";
import jsyaml from "js-yaml";
import Editor from '@monaco-editor/react';
import AppContext from 'src/services/app-context';
import BotContentMrcCard from './bot-content-mrc';
import BotContentSearchCard from './bot-content-search';
import BotContentLlmCard from './bot-content-llm';
import ContentWrapper from 'src/components/content-wrapper';
import { AppConfiguration } from 'src/core/services/authentication-service';
import BotContentUserPreferencesCard from './bot-content-user-preferences';
import classes from 'src/pages/index.module.css';
import { MemberEditorState, MemberItem } from 'src/stores/identities';
import MembersCard from 'src/components/members-card';
import { monacoOptions } from 'src/configurations/editor-config-jinja';
import LocalizedTextArea from 'src/components/localized-textarea';

const BotEditBasicInfo: FC<{
  state: State<BotItem>;
}> = ({ state }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  return (
    <Card withBorder>
      <Stack gap="xs">
        <TextInput
          required
          label={t('Title')}
          description={t("Provide a full name for your bot to help search and understand its purpose.")}
          value={scopedState.title.value}
          onChange={(event) => { scopedState.title.set(event.target.value); setIsDirty(true); }}
        />

        <LocalizedTextArea
          label={t("Description") as string}
          edit
          value={scopedState.description.value}
          onChange={(value) => scopedState.description.set(value)} />

        <Select
          required
          allowDeselect={false}
          label={t("Strategy")}
          placeholder={t("Bot strategy") as string}
          data={[
            { value: 'Router', label: t('Router') as string },
            { value: 'Sequential', label: t('Sequential') as string },
            { value: 'Llm', label: t('Large Language Models (LLMs)') as string }
          ]}
          value={scopedState.strategy.value}
          onChange={(value) => { scopedState.strategy.set(value as BotStrategy); setIsDirty(true); }}
        />
      </Stack>
    </Card>
  );
}

const BotEdit: React.FC = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const { botId } = useParams();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const [activeTab, setActiveTab] = useState("home");
  const store = useMemo(() => container.get(BotItemStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const tagsScopedState = useHookstate([] as TagItem[]);
  const variablesScopedState = useHookstate([] as TagItem[]);
  const contentScopedState = useHookstate({} as BotContent);
  const { height } = useViewportSize();
  const [selectedSkill, setSelectedSkill] = useState<string | undefined>(undefined);
  const [contentErrors, setContentErrors] = useState<JSONError[]>([]);
  const [advancedEditor, setAdvancedEditor] = useState<boolean>(false);
  const [yamlContent, setYamlContent] = useState<string>('');
  const { setIsDirty } = useContext(AppContext);
  const defaultBotContent = GetBotDefaultContent('Router', t);
  const allowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(t('Edit bot'), 'Bots', <MessageChatbot size={34} />, `${t('Edit bot')} | ${t("Bots")}`, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/bots`, breadcrumbName: t('Bots') },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);

  useEffect(() => {
    if (botId) {
      load(botId);
    }
  }, [botId]);

  const load = async (botId: string) => {
    if (botId) {
      await store.load(botId);
      loadForm();
    }
  };

  const loadForm = () => {
    if (state?.item?.value) {
      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      const variables = tagsToArray(state.item.currentSnapshot?.variables?.value ?? []);
      variablesScopedState.set(variables);

      const copy = JSON.parse(JSON.stringify(state.item.value)) as MemberEditorState;
      membersState.set({
        accessMode: copy.accessMode,
        members: copy.members
      });

      if (!state.item.currentSnapshot?.value) {
        state.item.currentSnapshot.set({
          content: '', // template
          botId: botId,
          variables: {}
        } as BotSnapshotItem);
      }

      if (state.item.currentSnapshot?.content?.value) {
        contentScopedState.set(JSON.parse(state.item.currentSnapshot.content.value));

        if (!contentScopedState.mrc || !contentScopedState.mrc.value) {
          contentScopedState.mrc.set({
            enable: true,
            confidence: 0.1,
            algorithm: 'naive',
            similarity: 0.02,
            maxPassageLength: 1024
          });
        }

        if (!contentScopedState.search || !contentScopedState.search.value) {
          contentScopedState.search.set({
            enable: true,
            cutOff: 0.85,
            confidence: 0.65,
            maxResults: 10,
            maxPassageLength: 0
          });
        }

        if (!contentScopedState.llm || !contentScopedState.llm.value) {
          contentScopedState.llm.set({
            behavior: defaultBotContent.llm.behavior,
            maxFunctions: 4,
            service: '',
            temperature: 0.5,
            maxTokensResponse: 1024,
            messageExpiration: 300,
            enableSaveSnippet: false,
            orchestratorType: 'Functions',
            stream: true
          });
        }

        if (!contentScopedState.integrations || !contentScopedState.integrations.value) {
          contentScopedState.integrations.set({
            insights: {
              enable: false,
              anonymize: false,
              trackUsername: false
            },
            microsoft: {
              enable: false,
              stripUsernameDomain: false
            }
          });
        }

        if (!contentScopedState.integrations || !contentScopedState.integrations.insights || !contentScopedState.integrations.insights.value) {
          contentScopedState.integrations.insights.set({
            enable: false,
            anonymize: false,
            trackUsername: false
          });
        }

        if (!contentScopedState.limits || !contentScopedState.limits.rateLimit || !contentScopedState.limits.rateLimit.value) {
          contentScopedState.limits.set({
            rateLimit: 'None'
          });
        }

        if (!contentScopedState.userPreferences || !contentScopedState.userPreferences.value) {
          contentScopedState.userPreferences.set({
            temperature: 0.5,
            allowUserChangeTemperature: false,
            searchSkills: [], // setear las search skills del bot
            allowUserChangeSearchSkills: false,
            searchLanguages: allowedLanguages.map((lang) => lang), // setear los idiomas globales permitidos
            allowUserChangeSearchLanguages: false,
            allowInternetAccess: false,
            allowUserChangeInternetAccess: false,
            allowEnableOCRInDocuments: false,
            enableOCRInDocuments: false
          });
        }

        if (!contentScopedState.options.alert.value) {
          contentScopedState.options.alert.set({
            "en": ""
          })
        }

        setYamlContent(jsyaml.dump(JSON.parse(state.item.currentSnapshot.content.value)));
      }
      else {
        const defaultContent = GetBotDefaultContent(state.item.strategy.value, t);
        contentScopedState.set(defaultContent);
        setYamlContent(jsyaml.dump(defaultContent));
      }
    }
  }

  useEffect(() => {
    if (!contentScopedState.llm || !contentScopedState.llm.value) {
      // set default values
      contentScopedState.llm.set({
        behavior: defaultBotContent.llm.behavior,
        maxFunctions: 4,
        service: '',
        temperature: 0.5,
        maxTokensResponse: 1024,
        messageExpiration: 300,
        enableSaveSnippet: false,
        stream: true,
        orchestratorType: 'Functions'
      });
    }
  }, [state?.item?.strategy])

  const getSnapShotItem = () => {
    const copy = JSON.parse(JSON.stringify(membersState.value)) as MemberEditorState;
    return {
      id: botId,
      enable: state.item.enable.value,
      title: state.item.title.value,
      description: state.item.description.value,
      strategy: state.item.strategy.value,
      accessMode: copy.accessMode,
      content: advancedEditor ? JSON.stringify(jsyaml.load(yamlContent), null, 2) : JSON.stringify(contentScopedState.value, null, 2),
      members: copy.members,
      tags: tagsToDict(tagsScopedState.value as TagItem[]),
      variables: tagsToDict(variablesScopedState.value as TagItem[]),
      logApiKeys: state.item.logApiKeys.value,
      properties: state.item.properties.value
    } as UpsertBotSnapshotItem;
  }

  const onSubmit = async () => {
    const snapshotItem = getSnapShotItem();

    if (validateBotContent()) {
      setIsDirty(false);
      let response = await store.saveSnapshot(botId as string, snapshotItem);
      if (response) {
        navigate(`/admin/bots/${botId}`);
        dispatch('@@ui/BOT_LIST_REFRESH');
      }
    }
  }

  const validateBotContent = () => {
    // const content = advancedEditor ? jsyaml.load(yamlContent) : contentScopedState.value;
    // const jsonSchema = new Draft07(BotContentSchema);
    // const errors: JSONError[] = jsonSchema.validate(content);
    // setContentErrors(errors);
    // return errors.length === 0;
    //TODO: comprobar y mejorar el schema del contenido del bot
    return true;
  }

  const onCancel = () => {
    if (botId) {
      navigate(`/admin/bots/${botId}`);
    }
    else {
      navigate(`/admin/bots`);
    }
  }

  return (
    <ContentWrapper>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        <form>
          {errorMessage && (
            <Alert p="md" mb="xs" icon={<AlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
              <Text>{formatMessage(errorMessage)}</Text>
            </Alert>
          )}
          {contentErrors?.length > 0 && (
            <Alert p="md" mb="xs" icon={<AlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
              <Text>{t("The following errors have occurred:")}</Text>
              <List size="sm" withPadding>
                {contentErrors.map((e, index) =>
                  <List.Item key={index}>{e.message}</List.Item>
                )}
              </List>
            </Alert>
          )}

          {state?.item?.value && state?.item?.currentSnapshot?.value && contentScopedState?.value &&
            <>
              <Grid align="stretch">
                <Grid.Col span="auto">
                  <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
                    <Tabs.List>
                      <Tabs.Tab value="home" leftSection={<Home2 size={20} />}>{t('Home')}</Tabs.Tab>
                      {!advancedEditor &&
                        <>
                          <Tabs.Tab value="skills" leftSection={<Wand size={20} />}>{t('Skills')}</Tabs.Tab>
                          {contentScopedState.llm?.value && (state.item.strategy.value === 'Llm' || (contentScopedState.mrc.value && contentScopedState.mrc.algorithm.value === 'llm')) &&
                            <Tabs.Tab value="llm" leftSection={<IconBrain size={20} />}>{t('LLM')}</Tabs.Tab>
                          }
                          <Tabs.Tab value="userpreferences" leftSection={<IconUserCog size={20} />}>{t('User preferences')}</Tabs.Tab>
                        </>
                      }
                      <Tabs.Tab value="api" leftSection={<PlugConnected size={20} />}>{t('API details')}</Tabs.Tab>
                    </Tabs.List>
                  </Tabs>
                </Grid.Col>
                <Grid.Col span="content">
                  <Group align="center" justify='flex-end' wrap='nowrap'>
                    {!advancedEditor &&
                      <Tooltip withinPortal label={`${t("Advanced editor")} YAML`}>
                        <ActionIcon onClick={() => { setActiveTab('home'); setAdvancedEditor(true); }} variant="outline" color={theme.colors[theme.primaryColor][6]} size="lg">
                          <IconCode />
                        </ActionIcon>
                      </Tooltip>
                    }
                    <Button
                      variant='outline'
                      onClick={onCancel}
                      leftSection={<IconArrowBackUp />}>
                      {t("Cancel")}
                    </Button>
                    <Button
                      loading={isBusy}
                      onClick={onSubmit}
                      leftSection={<IconDeviceFloppy />}>
                      {t("Save")}
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
              <Container fluid px={0} py={0} mt="xs">
                {activeTab === "home" && (
                  <Grid gutter={'xs'}>
                    <Grid.Col span={{ base: 12, lg: 8 }}>
                      <Stack gap="xs">
                        {!advancedEditor ?
                          <>
                            <BotContentOptionsCard
                              cardkey='bot-content-options-edit'
                              state={contentScopedState.options}
                              edit
                              key={`bot-content-options-edit-${botId}`}
                            />

                            <BotContentDisambiguationCard
                              cardkey='bot-content-disambiguation-edit'
                              state={contentScopedState.disambiguation}
                              edit
                              key={`bot-content-disambiguation-edit-${botId}`}
                            />

                            <SimpleGrid
                              cols={{ base: 1, xl: 2 }}
                              spacing="sm">
                              <BotContentSearchCard
                                cardkey='skill-content-steps'
                                key={`bot-content-search-${botId}`}
                                state={contentScopedState.search}
                                edit
                              />

                              <BotContentMrcCard
                                cardkey='skill-content-steps'
                                key={`bot-content-mrc-${botId}`}
                                state={contentScopedState.mrc}
                                edit
                              />
                            </SimpleGrid>
                          </>
                          :
                          <Card withBorder>
                            <Input.Wrapper
                              required
                              style={{ width: '100%', height: 'calc(100svh - 180px)' }}>
                              <Editor
                                width="100%"
                                height="100%"
                                options={monacoOptions}
                                language="yaml"
                                value={yamlContent}
                                onChange={(value) => { setYamlContent(value as string); setIsDirty(true); }}
                                theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                              />
                            </Input.Wrapper>
                          </Card>
                        }
                      </Stack>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <Stack gap="xs">
                        <BotEditBasicInfo
                          state={state.item}
                        />

                        <MembersCard
                          cardKey='skill-members-card-edit'
                          mode="edit"
                          state={membersState}
                          key={`bot-members-edit-${botId}`}
                          isBot
                        />

                        <TagsCard
                          cardKey='skill-tags-card-edit'
                          mode="edit"
                          state={tagsScopedState}
                          key={`bot-tags-edit-${botId}`}
                        />

                        <VariablesCard
                          cardKey='skill-variables-card-edit'
                          mode="edit"
                          state={variablesScopedState}
                          key={`bot-variables-edit-${botId}`}
                        />
                      </Stack>
                    </Grid.Col>
                  </Grid>
                )}
                {activeTab === "skills" && !advancedEditor && (
                  <Grid gutter={'xs'}>
                    <Grid.Col span={{ base: 12, lg: 3 }}>
                      <Card withBorder>
                        <BotSkillAssignedList
                          state={contentScopedState}
                          scrollAreaHeight={height - 210}
                          onSelectedItem={setSelectedSkill}
                          edit
                          key={`bot-skillassigned-list-edit-${botId}`}
                        />
                      </Card>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 9 }}>
                      {selectedSkill &&
                        <BotSkillAssignedDetail
                          value={selectedSkill}
                          state={contentScopedState.skills[contentScopedState.skills.findIndex(x => x.skillId.value === selectedSkill)]}
                          edit
                          key={`bot-skillassigned-detail-edit-${botId}`}
                        />
                      }
                      {!selectedSkill &&
                        <Card withBorder>
                          <Stack p="md">
                            <Group>
                              <Wand size={32} />
                              <Title order={2}>{t('Associated skill details')}</Title>
                            </Group>
                            <Text size="lg" c="dimmed">
                              {t('Select an associated skill or add a new one to see the detail.')}
                            </Text>
                          </Stack>
                        </Card>
                      }
                    </Grid.Col>
                  </Grid>
                )}
                {activeTab === "llm" && !advancedEditor && ((state.item.strategy.value === 'Llm' && contentScopedState.llm?.value) || (contentScopedState.mrc.value && contentScopedState.mrc.algorithm.value === 'llm')) && (
                  <Box pt={5}>
                    <BotContentLlmCard
                      cardkey='bot-content-llm-edit'
                      state={contentScopedState.llm as State<BotContentLlm>}
                      edit
                      botStrategy={state.item.strategy.value}
                      key={`bot-content-llm-edit-${botId}`}
                    />
                  </Box>
                )}

                {activeTab === "userpreferences" && !advancedEditor && (
                  <Box pt={5}>
                    <BotContentUserPreferencesCard
                      cardkey='bot-userpreferences'
                      state={contentScopedState.userPreferences as State<BotContentUserPreferences>}
                      assignedSkills={contentScopedState.skills.value as BotContentSkill[]}
                      key={`bot-userpreferences-${botId}`}
                      edit
                    />
                  </Box>
                )}

                {activeTab === "api" && (
                  <Box pt={5}>
                    <BotApiDetails
                      cardkey='bot-api-details-edit'
                      state={state.item}
                      contentState={contentScopedState}
                      edit
                      key={`bot-api-details-edit-${botId}`}
                    />
                  </Box>
                )}
              </Container>
            </>
          }
        </form>
      </div>
    </ContentWrapper>
  );
};

export default BotEdit;

import { State, useHookstate } from "@hookstate/core";
import { Group, SimpleGrid, Stack, Text } from "@mantine/core";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import SwitchButton from "src/components/switch-button";
import { BotContentOptions } from "src/stores/bots";
import { IconSettings as Settings } from "@tabler/icons-react";
import { SkillSelector } from "src/components/skill-selector";
import AppContext from "src/services/app-context";
import classes from 'src/pages/index.module.css';
import NumberInputWithControl from "src/components/number-input-with-control";

const BotContentOptionsCard: FC<{
  state: State<BotContentOptions>;
  cardkey: string;
  edit?: boolean;
}> = ({ state, cardkey, edit = false }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <CollapsibleCard
      title={<Text fw={500}>{t('Options')}</Text>}
      cardKey={cardkey}
      icon={<Settings />}>
      <Stack gap="xs">
        <Text c="dimmed">{t("Here you can configure general parameters of your bot.")}</Text>

        <SimpleGrid
          cols={{ base: 1, xl: 2 }}
          spacing="sm">
          <SwitchButton
            className={classes.switchButton}
            label={t("Enable feedback gathering")}
            description={t("Enable the collection of comments or feedback from users who interact with the bot. It is valuable for improving the performance of the bot and optimizing its design to better meet the needs and expectations of the users.") as string}
            checked={scopedState.enableFeedbackGathering.value}
            onChange={value => { scopedState.enableFeedbackGathering.set(value); setIsDirty(true); }}
            readOnly={!edit}
          />
          {/*<SwitchButton*/}
          {/*  className={classes.switchButton}*/}
          {/*  label={t("Enable sentiment analysis")}*/}
          {/*  description={t("Enable sentiment analysis on the bot. It allows the bot to better understand the emotions and tone of user messages (value between 0 and 1), allowing it to respond more accurately and appropriately.") as string}*/}
          {/*  checked={scopedState.enableSentimentAnalysis.value}*/}
          {/*  onChange={value => { scopedState.enableSentimentAnalysis.set(value); setIsDirty(true); }}*/}
          {/*  readOnly={!edit}*/}
          {/*/>*/}
          {/*<SwitchButton*/}
          {/*  className={classes.switchButton}*/}
          {/*  label={t("Enable toxic input classification")}*/}
          {/*  description={t("Enable toxic text classification on the bot. It allows the bot to detect toxic user input (value between 0 and 1), allowing it to respond more accurately and appropriately.") as string}*/}
          {/*  checked={scopedState.toxicClassification.value}*/}
          {/*  onChange={value => { scopedState.toxicClassification.set(value); setIsDirty(true); }}*/}
          {/*  readOnly={!edit}*/}
          {/*/>*/}
          <SwitchButton
            className={classes.switchButton}
            label={t("Enable event chips")}
            description={t("Add events chips to chatbot to see real time status of what is happening while response is processed.") as string}
            checked={scopedState.enableEvents.value ?? false}
            onChange={value => { scopedState.enableEvents.set(value); setIsDirty(true); }}
            readOnly={!edit}
          />
        </SimpleGrid>

        <SwitchButton
            className={classes.switchButton}
            label={t("Enable document management in chat window")}
            description={t("Enable document management in the chat, this will allow you to list documents to which the bot has access, upload new documents (if the personal collection is enabled) and save conversations (if you have write access to the collection).") as string}
            checked={scopedState.enableDocumentManagement.value ?? false}
            onChange={value => { scopedState.enableDocumentManagement.set(value); setIsDirty(true); }}
            readOnly={!edit}
            width="100%"
          />

        <SimpleGrid
          cols={{ base: 1, xl: 2 }}
          spacing="sm">
          <SkillSelector
            label={t("Greetings skill") as string}
            value={scopedState.greetingsSkill.value}
            width="100%"
            onChange={skillId => scopedState.greetingsSkill.set(skillId)}
            readOnly={!edit}
            clearable
          />
          <SkillSelector
            label={t("Fallback skill") as string}
            value={scopedState.fallbackSkill.value}
            width="100%"
            onChange={skillId => scopedState.fallbackSkill.set(skillId)}
            readOnly={!edit}
            clearable
          />
          <Group align="center" grow>
            <NumberInputWithControl
              required
              label={t("Confidence")}
              description={t("Minimum value that must reach to confidently identify the user's intent.") as string}
              precision={6}
              min={0}
              step={0.1}
              max={1}
              value={scopedState.confidence.value ?? ''}
              onChange={(value) => { scopedState.confidence.set(value as number); setIsDirty(true); }}
              readOnly={!edit}
            />

          </Group>
          <Group align="center" grow>
            <NumberInputWithControl
              required
              label={t("Maximum number of suggestions")}
              description={t("Set a limit for the number of suggestions to display at once.") as string}
              min={0}
              step={1}
              max={10}
              value={scopedState.maxSuggestions.value ?? ''}
              onChange={(value) => { scopedState.maxSuggestions.set(value as number); setIsDirty(true); }}
              readOnly={!edit}
            />
          </Group>
        </SimpleGrid>
      </Stack>
    </CollapsibleCard>
  );
};

export default BotContentOptionsCard;
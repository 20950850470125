import { ActionIcon, Badge, Center, Group, Input, Stack, Table, Text, TextInput, useMantineColorScheme } from '@mantine/core';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconTrash as Trash, IconVariable as Variable } from '@tabler/icons-react';
import { TagItem } from 'src/stores/skills';
import { none, State, useHookstate } from '@hookstate/core';
import CollapsibleCard from './collapsible-card';
import AppContext from 'src/services/app-context';

const VariablesCard: FC<{
  cardKey: string;
  mode: 'view' | 'edit';
  state: State<TagItem[]>;
}> = ({ cardKey, mode, state }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  // const vaultStore = useMemo(() => container.get(VaultStore), []);
  // const vaultState = vaultStore.state;

  return (
    <CollapsibleCard
      title={
        <Group justify='flex-start'>
          <Text fw={500}>{t('Variables')}</Text>
          {mode === 'edit' &&
            <ActionIcon variant='subtle' color="gray" onClick={() => { scopedState[scopedState.length].set({ name: '', value: '' } as TagItem); setIsDirty(true); }}>
              <IconPlus />
            </ActionIcon>
          }
        </Group>
      }
      cardKey={cardKey}
      icon={<Variable />}
      collapseInfoRender={
        <Badge variant='light' color={colorScheme === 'dark' ? 'dark' : 'gray'}>{scopedState?.value.length} {t("variables")}</Badge>
      }>
      <>
        {mode === 'edit' &&
          <form>
            <Stack gap="xs" justify="flex-start">
              <Input.Wrapper description={<Text size='xs'>{t('To add new variable click on the Add variable button.')}</Text>}>
                {scopedState?.map((item, index) => (
                  <Group key={index} mt="xs">
                    <TextInput
                      required
                      placeholder={t('Name') as string}
                      style={{ flex: 1 }}
                      value={item.value.name}
                      onChange={(event) => { item.name.set(event.currentTarget.value); setIsDirty(true); }}
                    />
                    <TextInput
                      placeholder={t('Value') as string}
                      style={{ flex: 1 }}
                      value={item.value.value}
                      onChange={(event) => { item.merge(i => ({ value: event.currentTarget.value })); setIsDirty(true); }}
                    />
                    {/* <Tooltip label={t("Click here to protect the value")}>
                      <ActionIcon
                       variant='subtle' color="gray"
                        onClick={async () => {
                          const protectedText = await getProtectedText(form.values.variables[index].value);
                          form.setFieldValue(`variables.${index}.value`, protectedText);
                        }}
                      >
                        <ShieldLock />
                      </ActionIcon>
                    </Tooltip> */}
                    <ActionIcon
                      color="red"
                      variant='subtle'
                      onClick={() => { state[index].set(none); setIsDirty(true); }}>
                      <Trash />
                    </ActionIcon>
                  </Group>
                ))}
              </Input.Wrapper>
            </Stack>
          </form>
        }
        {mode === 'view' &&
          <>
            {scopedState?.value && scopedState?.value?.length > 0 &&
              <Table striped highlightOnHover>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>{t('Name')}</Table.Th>
                    <Table.Th>{t('Value')}</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {scopedState.value &&
                    scopedState.value.map((v) => (
                      <Table.Tr key={v.name}>
                        <Table.Td className="bold">{v.name}</Table.Td>
                        <Table.Td>{v.value}</Table.Td>
                      </Table.Tr>
                    ))}
                </Table.Tbody>
              </Table>
            }
            {(scopedState?.value?.length === 0) &&
              <Center>
                <Text c="dimmed" size="sm">{t('No variables')}</Text>
              </Center>
            }
          </>
        }
      </>
    </CollapsibleCard>
  );
};

export default VariablesCard;

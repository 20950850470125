import { Stack, Select, Alert, List, Text, Group, Table } from '@mantine/core';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceConnectionRef } from './serviceconnection';
import { useHookstate } from '@hookstate/core';
import { BuiltInServiceConnectionContent } from 'src/stores/serviceconnections';
import AppContext from 'src/services/app-context';
import { IconInfoCircle } from '@tabler/icons-react';

export const BuiltInServiceConnection = React.forwardRef<ServiceConnectionRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as BuiltInServiceConnectionContent);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set({
        tier: 's0',
      } as BuiltInServiceConnectionContent);
    }
  }, [content])

  const isValid = () => {
    return scopedState.tier.value.length > 0;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Tier")}</Table.Td>
          <Table.Td>{scopedState.tier.value}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Stack gap={10} justify="flex-start">
      <Alert icon={<IconInfoCircle size="1rem" />} title={t("Built-in LLM model")} />
      <Group grow justify='space-between' gap="xl">
        <Select
          allowDeselect={false}
          label={t("Tier")}
          description={t("Built-in service level")}
          defaultValue='naive'
          data={[
            { value: 's0', label: t("Tier 0") as string }
          ]}
          value={scopedState.tier?.value ?? 's0'}
          onChange={(value) => { scopedState.tier.set(value as string); setIsDirty(true); }}
          readOnly={!canContribute}
        />
        <Stack gap={10}>
          <Text>{t("LLM is subject to rate limits based on your purchased plan.")}</Text>
        </Stack>
      </Group>
    </Stack>
  );
});

export default BuiltInServiceConnection;

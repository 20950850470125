import { State, none, useHookstate } from "@hookstate/core";
import { ActionIcon, Group, Input, Stack, Text, Textarea, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconWindowMaximize, IconPlus, IconTrash } from "@tabler/icons-react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import { SkillContentStep } from "src/stores/skills";
import SkillDebugJinjaModal from "./skill-debug-jinja-modal";
import SkillContentStepWrapper from "./skill-content-step-wrapper";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";

const SkillContentStepSay: FC<{
  state: State<SkillContentStep>;
  edit?: boolean;
  index: number;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ state, edit, index, leftToolbar, rightToolbar }) => {
  const scopedState = useHookstate(state.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedText, setSelectedText] = useState<{ text: string, index: number } | undefined>(undefined);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0])[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  }, [scopedState.text])

  const onAddText = () => {
    const defaultLang = allowedLanguages[0];
    let defaultText: { [key: string]: string } = {};
    defaultText[defaultLang] = t("<your message>", { lng: defaultLang });

    scopedState.text[scopedState.text.length].set(defaultText);
    setIsDirty(true);
  }

  const onOpenDebugJinja = (index: number) => {
    const text = (scopedState.text[index])[selectedLanguage].value;
    setSelectedText({ text, index });
    open();
  }

  const onCloseDebugJinja = () => {
    setSelectedText(undefined);
    close();
  }

  const onSaveDebugJinja = (value: string) => {
    if (selectedText) {
      (scopedState.text[selectedText.index])[selectedLanguage].set(value);
    }
    onCloseDebugJinja();
  }

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(none);
    }
    for (let index = 0; index < scopedState.value.suggestions.length; index++) {
      (scopedState.suggestions[index])[language].set(none);
    }
    setIsDirty(true);
    if (scopedState?.value?.text?.length > 0) {
      setSelectedLanguage(Object.keys(scopedState?.text?.value?.[0])[0]);
    }
  }

  const onAddLanguage = (language: string) => {
    const defaultText = t("<your message>", { lng: language });
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(defaultText);
    }
    const defaultSuggestion = t("<your suggestion>", { lng: language });
    for (let index = 0; index < scopedState.value.suggestions.length; index++) {
      (scopedState.suggestions[index])[language].set(defaultSuggestion);
    }
    setIsDirty(true);
  }

  const onAddSuggestion = () => {
    const defaultLang = allowedLanguages[0];
    if (!scopedState.suggestions || !scopedState.suggestions.value) {
      scopedState.suggestions.set([])
    }
    let defaultSuggestions: { [key: string]: string } = {};
    defaultSuggestions[defaultLang] = t("<your suggestion>", { lng: defaultLang });
    scopedState.suggestions[scopedState.suggestions.length].set(defaultSuggestions);
    setIsDirty(true);
  }

  return (
    <SkillContentStepWrapper
      key={`step-wrapper-${index}`}
      index={index}
      state={state}
      edit={edit}
      leftToolbar={
        <Group align="center" gap={5}>
          {leftToolbar}
          <LanguageSegmentedControl
            size="sm"
            compact
            editable={edit}
            data={scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : undefined}
            language={selectedLanguage}
            onChange={setSelectedLanguage}
            onDelete={onDeleleLanguage}
            onAdd={onAddLanguage}
          />
        </Group>
      }
      rightToolbar={rightToolbar}>
      {edit ?
        <Stack gap="xs">
          <Group align="center">
            <Input.Label w={120} required={edit}>{t("Say")}:</Input.Label>
            <Group align="center" gap={5}>
              <Text size="sm">{t("Messages")}</Text>
              <Tooltip withinPortal label={t("Add text")}>
                <ActionIcon onClick={onAddText} variant='subtle' color="gray">
                  <IconPlus size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
          {scopedState.text.map((text, index) =>
            <Group align="flex-start" key={index}>
              <Input.Label w={120}></Input.Label>
              <Textarea
                autosize
                minRows={3}
                maxRows={10}
                required
                style={{ flex: 1 }}
                value={text[selectedLanguage]?.value ?? ''}
                onChange={(event) => { text[selectedLanguage].set(event.target.value); setIsDirty(true); }}
                styles={{ section: { marginRight: "var(--mantine-spacing-md)", alignItems: 'flex-start' } }}
                rightSection={<Tooltip label={t("More options")}>
                  <ActionIcon variant='subtle' color="gray" onClick={() => onOpenDebugJinja(index)}>
                    <IconWindowMaximize size={20} />
                  </ActionIcon>
                </Tooltip>}
              />
              <ActionIcon onClick={() => { text.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                <IconTrash size={16} />
              </ActionIcon>
            </Group>
          )}
          <SkillDebugJinjaModal value={selectedText?.text ?? ''} open={opened} onClose={onCloseDebugJinja} onSave={(value) => onSaveDebugJinja(value)} />
          <Stack gap="xs">
            <Group align="center">
              <Input.Label w={120}></Input.Label>
              <Group align="center" gap={5}>
                <Text size="sm">{t("Suggestions")}</Text>
                <Tooltip withinPortal label={t("Add suggestion")}>
                  <ActionIcon onClick={onAddSuggestion} variant='subtle' color="gray">
                    <IconPlus size={16} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </Group>
            {scopedState.suggestions && scopedState.suggestions.value && scopedState.suggestions.map((suggestion, index) =>
              <Group align="flex-start" key={index}>
                <Input.Label w={120}></Input.Label>
                <Input
                  style={{ flex: 1 }}
                  value={suggestion[selectedLanguage]?.value ?? ''}
                  onChange={(event) => { suggestion[selectedLanguage].set(event.target.value); setIsDirty(true); }}
                />
                <ActionIcon onClick={() => { suggestion.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            )}
          </Stack>
        </Stack>
        :
        <Stack gap="xs">
          <div>
            <Text span fw={500}>{t("Say")} </Text>
            {scopedState.text.map((t, index) =>
              <Text key={index} span fs="italic">{t[selectedLanguage]?.value ?? ''} </Text>
            )}
          </div>
        </Stack>
      }
    </SkillContentStepWrapper>
  );
};

export default SkillContentStepSay;

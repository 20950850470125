import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { Stack, Group, Text, Badge, Anchor, Title, Select } from '@mantine/core';
import useBus from 'use-bus';
import { DocumentSummary, DocumentSummaryStore } from 'src/stores/documents';
import { Query } from 'src/core/stores/data-store';
import StatusBadge from 'src/components/status-badge';
import moment from 'moment';
import JobStatus from 'src/components/job-status';
import TableListV2, { TableListV2Model } from 'src/core/ui/table-list/table-list-v2';
import { DataTableSortStatus } from 'mantine-datatable';
import { IconFileText, IconAdjustments } from '@tabler/icons-react';
import CreateDocumentWizard from '../documents/create-document-wizard';
import classes from 'src/pages/index.module.css';
import { sleep } from 'src/core/utils/object';

const CollectionDocuments: FC<{
  collectionId: string;
  canContribute: boolean;
}> = ({ collectionId, canContribute }) => {
  const { t } = useTranslation();
  const documentsStore = useMemo(() => container.get(DocumentSummaryStore), []);
  const documentsState = documentsStore.state;
  const [query, setQuery] = useState<Query>(undefined as any);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [trainingStatusFilter, setTrainingStatusFilter] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'title', direction: 'asc' });

  useEffect(() => {
    if (collectionId) {
      const newQuery = {
        ...query,
        //orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        parameters: {
          collectionId: collectionId,
          statusFilter: statusFilter,
          trainingStatusFilter: trainingStatusFilter,
          orderByField: sortStatus.columnAccessor,
          orderByDirection: sortStatus.direction
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, statusFilter, trainingStatusFilter]);

  useEffect(() => {
    if (query) {
      documentsStore.load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onReloadDocuments = () => {
    documentsStore.load(query)
  };

  useBus(
    '@@ui/DOCUMENT_LIST_REFRESH',
    () => onReloadDocuments(),
    [query],
  );

  const model: TableListV2Model<DocumentSummary> = {
    data: documentsStore.toListState(documentsState.value),
    query,
    columns: [
      {
        accessor: 'title',
        title: t('Title'),
        sortable: true,
        render: (item: DocumentSummary) => (
          <Anchor component={Link} to={`/admin/collections/${collectionId}/documents/${item.id}`} underline="never" variant="text">
            <Stack gap="xs">
              <Title className={classes.titleList} order={5}>{item.title}</Title>
              <Text c="dimmed" size="sm">
                {!item.description && t('(No description)')}
                {item.description}
              </Text>
            </Stack>
          </Anchor>
        )
      },
      {
        accessor: 'status',
        title: t('Status'),
        render: (item: DocumentSummary) => (
          <StatusBadge status={item.status} />
        )
      },
      {
        accessor: 'trainingStatus',
        title: t('Training status'),
        render: (item: DocumentSummary) => (
          <JobStatus status={item.trainingStatus} jobReference={item.jobReference} />
        )
      },
      {
        accessor: 'lastmodified',
        title: t('Last modified'),
        sortable: true,
        render: (item: DocumentSummary) => (item.currentSnapshotDateTime ?
          <Badge variant="light">{t("Updated")} {moment(item.currentSnapshotDateTime).fromNow()}</Badge>
          : t('Never')
        )
      },
      {
        accessor: 'tags',
        title: t('Tags'),
        render: (item: DocumentSummary) => (
          <Group gap="xs" align="center">
            {item.tags && Object.keys(item.tags || {}).map((t) => (
              <Badge size="xs" radius="sm" p="xs" key={t} className={classes.tag}>{t}: {item.tags[t]}</Badge>
            ))}
          </Group>
        )
      }
    ]
  };

  const onConfirmDelete = async (selectedKeys: DocumentSummary[]) => {
    if (selectedKeys && selectedKeys.length > 0) {
      documentsStore.setCollection(collectionId);
      selectedKeys.forEach((key) => { documentsStore.delete(key.id); });
      await sleep(500);
      onReloadDocuments();
    }
  };

  return (
    <>
      <TableListV2
        model={model}
        onQueryChanged={setQuery}
        onRefresh={onReloadDocuments}
        striped
        highlightOnHover
        sortable
        sortStatus={sortStatus}
        onSortStatusChanged={setSortStatus}
        searchByTag
        leftToolBarRender={
          <Group justify='space-between'>
            <IconFileText />
            <Text fw={500}>{t("Documents")}</Text>
          </Group>
        }
        rightToolBarRender={
          <Group gap="xs" align="center">
            <Select
              clearable
              style={{ width: 160 }}
              leftSection={<IconAdjustments size={20} />}
              placeholder={t('Status') as string}
              data={[
                { value: 'Draft', label: t('Draft') as string },
                { value: 'Published', label: t('Published') as string },
                { value: 'Retired', label: t('Retired') as string },
              ]}
              onChange={(value) => setStatusFilter(value as string)}
              value={statusFilter}
            />
            <Select
              clearable
              style={{ width: 160 }}
              leftSection={<IconAdjustments size={20} />}
              placeholder={t('Training status') as string}
              data={[
                { value: 'Pending', label: t('Pending') as string },
                { value: 'Training', label: ('Training') as string },
                { value: 'Trained', label: t('Trained') as string },
                { value: 'Error', label: t('Error') as string },
              ]}
              onChange={(value) => setTrainingStatusFilter(value as string)}
              value={trainingStatusFilter}
            />
          </Group>
        }
        showNewIcon={canContribute}
        onNewItemClick={() => setShowCreateModal(true)}
        selectable={canContribute}
        onDeleteSelectedClick={(selectedKeys: DocumentSummary[]) => onConfirmDelete(selectedKeys)}
      />
      {showCreateModal && <CreateDocumentWizard opened={showCreateModal} onClose={() => setShowCreateModal(false)} collectionId={collectionId} />}
    </>
  );
};

export default CollectionDocuments;

import { State, useHookstate } from "@hookstate/core";
import { Badge, Box, Card, Group, Stack } from "@mantine/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SkillContentStep } from "src/stores/skills";
import SkillContentStepCondition from "./skill-content-step-condition";
import SkillContentStepTitle from "./skill-content-step-title";
import SkillContentStepTurn from "./skill-content-step-turn";
import classes from 'src/pages/index.module.css';

const SkillContentStepWrapper: FC<{
  children: any;
  state: State<SkillContentStep>;
  index: number;
  edit?: boolean;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ children, state, index, edit = false, leftToolbar, rightToolbar }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);

  return (
    <Card
      withBorder
      shadow="xs"
      radius="sm"
      className={classes.stepCard}>
      <Card.Section withBorder inheritPadding py={5}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
          <Box style={{ flex: 1 }} mr="md">
            <SkillContentStepTitle
              index={index}
              key={`step-title-${index}`}
              state={scopedState.step}
              edit={edit}
            />
          </Box>
          <Group justify="flex-end" gap={5} align="center">
            {leftToolbar}
            <Badge variant="outline" size="lg" radius="xs" style={{ textTransform: 'none' }}>{t(scopedState.action.type.value)}</Badge>
            {rightToolbar}
          </Group>
        </Box>
      </Card.Section>

      <Card.Section inheritPadding mt="xs" pb="xs">
        <Stack gap="xs">
          <SkillContentStepCondition
            key={`step-condition-${index}`}
            edit={edit}
            state={scopedState.condition}
          />

          {children}
        </Stack>
      </Card.Section>
      <Card.Section withBorder inheritPadding py={5}>
        <SkillContentStepTurn
          key={`step-turn-${index}`}
          edit={edit}
          state={scopedState.turn}
        />
      </Card.Section>
    </Card>
  );
};

export default SkillContentStepWrapper;
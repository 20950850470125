import { none, State, useHookstate } from "@hookstate/core";
import { ActionIcon, Badge, Card, Center, Group, Input, Menu, Select, ComboboxItem, Stack, Text, TextInput, Tooltip } from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import AppContext from "src/services/app-context";
import { SkillContentEntity } from "src/stores/skills";
import { EntityType } from "src/stores/entities";
import { IconPlus, IconTags, IconDotsVertical, IconTrash, IconCopy } from "@tabler/icons-react";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import classes from 'src/pages/index.module.css';
import { BasicMultiselect } from "src/components/basic-multiselect";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";

const SkillContentEntityEditName: FC<{
  state: State<string>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <Group align="center">
      <Input.Label w={120} required={edit}>{label}:</Input.Label>
      <TextInput
        required
        style={{ flex: 1 }}
        value={scopedState.value}
        onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
      />
    </Group>
  );
}

const SkillContenEntityEditType: FC<{
  state: State<SkillContentEntity>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  return (
    <Group align="center">
      <Input.Label w={120} required={edit}>{label}:</Input.Label>
      <Select
        required
        allowDeselect={false}
        style={{ flex: 1 }}
        placeholder={t("Entity type") as string}
        data={[
          { value: 'Synonyms', label: t('Synonyms') as string },
          { value: 'Regex', label: t('Regex') as string },
        ]}
        value={scopedState.type.value}
        onChange={(value) => { scopedState.type.set(value as EntityType); setIsDirty(true); }}
      />
    </Group>
  );
}

const SkillContenEntityEditSamples: FC<{
  state: State<SkillContentEntity>;
  edit?: boolean;
  label: string;
  lang: string;
}> = ({ state, edit, label, lang }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  const getMultiselectData = (lang: string) => {
    let data = [] as ComboboxItem[];

    if (lang && scopedState?.samples && scopedState.samples[lang].value) {
      data = scopedState.samples[lang].value.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  const getMultiselectValues = (lang: string) => {
    let values = [] as string[];

    if (lang && scopedState?.samples && scopedState.samples[lang].value) {
      values = scopedState.samples[lang].value.map(item => item);
    }

    return values;
  }

  return (
    <Group align="center">
      <Input.Label w={120} required={edit}>{label}:</Input.Label>
      <BasicMultiselect
        data={getMultiselectData(lang)}
        value={getMultiselectValues(lang)}
        onChange={(value) => { scopedState.samples[lang].set(value); setIsDirty(true); }}
        creatable
        style={{ flex: 1 }}
        required
      />
    </Group>
  );
}

const SkillContentEntitiesCard: FC<{
  state: State<SkillContentEntity[]>;
  cardkey: string;
  edit?: boolean;
}> = ({ state, cardkey, edit = false }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].samples) : defaultAllowedLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].samples)[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].samples) : defaultAllowedLanguages);
  }, [scopedState])

  const onAddEntity = () => {
    let defaultSamples: { [key: string]: string[] } = {};
    defaultSamples[allowedLanguages[0]] = [];

    const newIntent = {
      name: '<your entity>',
      type: 'Synonyms',
      samples: defaultSamples
    } as SkillContentEntity;
    scopedState.merge([newIntent]);
    setIsDirty(true);
  }

  const onClone = (item: SkillContentEntity) => {
    const copy = JSON.parse(JSON.stringify(item));
    scopedState[scopedState.length].set(copy);
    setIsDirty(true);
  }

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.length; index++) {
      scopedState[index].samples[language].set(none);
    }
    setIsDirty(true);
    if (scopedState?.value?.length > 0) {
      setSelectedLanguage(Object.keys(scopedState?.value?.[0].samples)[0]);
    }
  }

  const onAddLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.length; index++) {
      scopedState[index].samples[language].set([]);
    }
    setIsDirty(true);
  }

  return (
    <CollapsibleCard
      title={
        <Group justify='space-between'>
          <Text fw={500}>{t('Entities')}</Text>
          {edit &&
            <ActionIcon onClick={onAddEntity} variant='subtle' color="gray">
              <IconPlus />
            </ActionIcon>
          }
        </Group>
      }
      cardKey={cardkey}
      icon={<IconTags />}
      rightToolbar={
        <LanguageSegmentedControl
          editable={edit}
          data={scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].samples) : undefined}
          language={selectedLanguage}
          onChange={setSelectedLanguage}
          onDelete={onDeleleLanguage}
          onAdd={onAddLanguage}
        />
      }
      collapseInfoRender={
        <Badge variant='light' color='gray'>{scopedState?.value?.length} {t("entities")}</Badge>
      }>
      <Stack>
        {scopedState?.value?.length > 0 && scopedState.map((entity, index) =>
          <Card
            withBorder
            shadow="xs"
            radius="sm"
            key={index}
            className={classes.stepCard}>
            <Card.Section withBorder inheritPadding py={5}>
              {edit ?
                <Group justify="space-between">
                  <Text fw={500}>{`Entity # ${index + 1}`}</Text>
                  <Menu withinPortal position="bottom-end" shadow="sm">
                    <Menu.Target>
                      <Tooltip label={t("More options")}>
                        <ActionIcon variant='subtle' color="gray">
                          <IconDotsVertical size={16} />
                        </ActionIcon>
                      </Tooltip>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item leftSection={<IconCopy size={16} />} onClick={() => onClone(entity.value as SkillContentEntity)}>{t("Clone")}</Menu.Item>
                      <Menu.Item leftSection={<IconTrash size={16} />} color="red" onClick={() => { entity.set(none); setIsDirty(true); }}>{t("Delete")}</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Group>
                :
                <Text fw={500}>{`# ${entity.name.value}`} ({entity.type.value})</Text>
              }
            </Card.Section>

            <Card.Section inheritPadding mt="xs" pb="xs">
              <Stack gap={5}>
                {edit ?
                  <>
                    <SkillContentEntityEditName
                      edit={edit}
                      label={t("Name")}
                      state={entity.name}
                    />

                    <SkillContenEntityEditType
                      state={entity}
                      label={t("Type")}
                      edit={edit}
                    />

                    <SkillContenEntityEditSamples
                      state={entity}
                      label={t("Values")}
                      edit={edit}
                      lang={selectedLanguage}
                    />
                  </>
                  :
                  <Group align="center" gap="xs">
                    {entity.samples[selectedLanguage]?.value?.map((i, index) =>
                      <Badge size="xs" radius="sm" p="xs" key={index} className={classes.tag}>{i}</Badge>
                    )}
                  </Group>
                }
              </Stack>
            </Card.Section>
          </Card>
        )}
        {scopedState?.value?.length === 0 &&
          <Center>
            <Text c="dimmed" size="sm">{t('No entities')}</Text>
          </Center>
        }
      </Stack>
    </CollapsibleCard>
  );
};

export default SkillContentEntitiesCard;
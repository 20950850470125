import { Text, Alert, Card, Stack, Center, Group, Pagination, ActionIcon, TextInput, Select } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Query } from "src/core/stores/data-store";
import { formatMessage } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { DocumentSnapshotPassageSummaryStore } from "src/stores/documents";
import DocumentSnapshotPassage from "./snapshot-passage";
import { IconAlertCircle, IconSearch, IconRefresh } from "@tabler/icons-react";
import classes from 'src/pages/index.module.css';

const DocumentSnapshotPassages: FC<{
  collectionId: string;
  documentId: string;
  snapshotId: string;
  edit?: boolean;
  documentTitle?: React.ReactNode;
}> = ({ collectionId, documentId, snapshotId, edit, documentTitle }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(DocumentSnapshotPassageSummaryStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const [query, setQuery] = useState<Query>(undefined as any);

  useEffect(() => {
    if (collectionId && documentId && snapshotId) {
      store.setSnapshot(collectionId, documentId, snapshotId);
      const newQuery = {
        ...query,
        searchQuery: '',
        orderBy: [{ field: 'id', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 5,
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, documentId, snapshotId]);

  useEffect(() => {
    if (query) {
      load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const load = async (query: Query) => {
    store.load(query);
  };

  const onReload = () => {
    store.load(query);
  }

  const pageSizeOptions = [5, 10, 30, 50, 100];
  const pageSize = query && query.take ? query.take : 5;
  const totalPages = state && state.items ? Math.ceil((state.items?.value == null ? 1 : state.count.value) / pageSize) : 1;
  let activePage = 1;
  const skip = query?.skip;
  const take = query?.take;
  const count = state?.count?.value;

  if (skip && take && count) {
    activePage = skip / take + 1;
  }

  if (activePage > totalPages) activePage = Math.max(1, activePage - 1);

  const onChangePage = (page: number) => {
    setQuery((q) => {
      const nextQ = { ...q, skip: (page - 1) * pageSize, take: pageSize };
      return nextQ as Query;
    });
  };

  const onSearchkeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const value = event.target.value;
      setQuery((q) => {
        const nextQ = { ...q, skip: 0, searchQuery: value };
        return nextQ as Query;
      });
    }
  };

  const getPageSizeOptionsData = () => {
    return pageSizeOptions.map(item => ({ label: `${item} / ${t("page")}`, value: item.toString() }));
  }

  const onChangePageSizeOption = (option: string | null) => {
    if (option) {
      setQuery((q) => {
        const nextQ = { ...q, skip: 0, take: parseInt(option) };
        return nextQ as Query;
      });
    }
  }

  return (
    <Stack>
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}

      <Card withBorder>
        <Group align="center" justify="space-between">
          {documentTitle}
          <Group gap="xs" align="center" justify="flex-end">
            <TextInput
              className={classes.searchBox}
              leftSection={<IconSearch size={20} />}
              onKeyDown={onSearchkeyDown}
              placeholder={t('Search...') as string}
            />
            <ActionIcon onClick={onReload} variant='subtle' color="gray">
              <IconRefresh />
            </ActionIcon>
          </Group>
        </Group>
      </Card>

      {state.items.length > 0 && state.items.map((passage) =>
        <DocumentSnapshotPassage
          key={passage.id.value}
          collectionId={collectionId}
          documentId={documentId}
          snapshotId={snapshotId}
          state={passage}
          canEdit={edit}
          onReload={onReload}
        />
      )}
      {state.items.length > 0 &&
        <Card withBorder>
          <Group gap='xs' align="center" justify="flex-start">
            <Text size="sm">
              {t('Total')}: {state.count.value}
            </Text>
            <Select
              allowDeselect={false}
              size='xs'
              style={{ width: 100 }}
              data={getPageSizeOptionsData()}
              value={pageSize.toString()}
              onChange={onChangePageSizeOption}
            />
            <Pagination
              // position="right"
              gap="xs"
              size="sm"
              total={totalPages}
              value={activePage}
              onChange={onChangePage} />
          </Group>
        </Card>
      }
      {state.items.length === 0 &&
        <Card withBorder>
          <Center>
            <Text c="dimmed" size="sm">{t('No passages')}</Text>
          </Center>
        </Card>
      }
    </Stack>
  );
};

export default DocumentSnapshotPassages;
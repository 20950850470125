import { State, useHookstate } from "@hookstate/core";
import { ScrollArea, ComboboxItem } from "@mantine/core";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BasicMultiselect } from "src/components/basic-multiselect";
import AppContext from "src/services/app-context";
import { BotItem } from "src/stores/bots";

const BotBlockedUserList: FC<{
  state: State<BotItem>;
  propertyKey: string;
  edit?: boolean;
}> = ({ state, propertyKey, edit }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const getData = () => {
    let data = [] as ComboboxItem[];

    if (scopedState?.properties?.[propertyKey as any]?.value) {
      const properties = JSON.parse(JSON.stringify(scopedState.properties[propertyKey as any].value)) as string[];
      data = properties.map((item: string) => ({ label: item, value: item }));
    }

    return data;
  }
  
  const onChangeUserBlock = (users: string[]) => {
    if (scopedState?.value?.properties && scopedState?.value?.properties[propertyKey as any]) {
      scopedState.properties[propertyKey as any].set(users);
    }
    else {
      scopedState.merge({ properties: { [propertyKey]: users } });
    }
    setIsDirty(true);
  }

  return (
    <div style={{ position: 'relative' }}>
      <ScrollArea style={{ minHeight: 100, height: 'calc(100svh - 650px)' }} offsetScrollbars>
        <BasicMultiselect
          key={`${scopedState.id.value}-blocked-user-list`}
          data={getData()}
          value={scopedState?.properties?.[propertyKey as any]?.value as string[]}
          onChange={(value) => onChangeUserBlock(value)}
          readOnly={!edit}
          variant={edit ? undefined : 'unstyled'}
          creatable
        />
      </ScrollArea>
    </div>
  );
};

export default BotBlockedUserList;
import { ActionIcon, Badge, Button, Center, Group, Highlight, Modal, Stack, Text, TextInput, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSpeakerphone as Speakerphone, IconPlus as Plus, IconSearch as Search, IconTrash as Trash } from '@tabler/icons-react';
import CollapsibleCard from './collapsible-card';
import { DataTable } from 'mantine-datatable';
import { useDebouncedValue } from '@mantine/hooks';
import { none, State, useHookstate } from '@hookstate/core';
import AppContext from 'src/services/app-context';
import { ISearchEntitiesStore } from 'src/stores/entities';
import IntentInput from './intent-input';
import { getEntitiesInText } from 'src/core/utils/object';

interface IntentItem {
  intent: string;
  index: number;
}

const SuggestionsCard: FC<{
  mode: 'view' | 'edit';
  state: State<string[]>;
  cardKey: string;
  id?: string;
  searchEntitiesStore?: ISearchEntitiesStore;
}> = ({ cardKey, mode, state, id, searchEntitiesStore }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);
  const [showAddIntentModal, setShowAddIntentModal] = useState(false);
  const [newIntent, setNewItent] = useState<string | undefined>(undefined);
  const scopedState = useHookstate(state);
  const [records, setRecords] = useState<IntentItem[]>(scopedState?.value ? scopedState.value.map((i, index) => ({ intent: i, index: index })) : []);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    if (scopedState?.value) {
      setRecords(scopedState?.value?.map((i, index) => ({ intent: i, index: index })));
    }
  }, [scopedState]);

  useEffect(() => {
    setRecords(
      scopedState?.value?.map((i, index) => ({ intent: i, index: index })).filter(({ intent }) => {
        if (debouncedSearchTerm !== '' && !`${intent}`.toLowerCase().includes(debouncedSearchTerm.trim().toLowerCase())) {
          return false;
        }
        return true;
      })
    );
  }, [debouncedSearchTerm]);

  const onCloseModal = () => {
    setNewItent(undefined);
    setShowAddIntentModal(false);
  }

  const addNewIntent = () => {
    if (newIntent) {
      let intents = newIntent.replace(/\n/g, ",").split(","); // replace newlines with comma
      if (intents && intents.length > 0) {
        scopedState.merge(intents);
      }

      onCloseModal();
      setIsDirty(true);
    }
  }

  const getTableColumns = () => {
    if (mode === 'view') {
      return [{
        accessor: 'intent',
        title: t('Intent'),
        render: (intent: IntentItem) => (
          <Highlight highlight={getEntitiesInText(intent.intent)} color={theme.colors[theme.primaryColor][6]}>{intent.intent}</Highlight>
        )
      }];
    }
    else {
      return [
        {
          accessor: 'intent',
          title: t('Intent'),
          render: (intent: IntentItem) => (
            <Highlight highlight={getEntitiesInText(intent.intent)} color={theme.colors[theme.primaryColor][6]}>{intent.intent}</Highlight>
          )
        },
        {
          accessor: 'actions',
          title: '',
          width: 20,
          render: (intent: IntentItem) => (
            <Group gap={4} justify="flex-end" wrap='nowrap'>
              <ActionIcon color="red" variant='subtle' onClick={() => { scopedState[intent.index].set(none); setIsDirty(true); }}>
                <Trash />
              </ActionIcon>
            </Group>
          ),
        }
      ];
    }
  }

  return (
    <CollapsibleCard
      title={
        <Group justify='space-between'>
          <Text fw={500}>{t('Suggestions')}</Text>
          {mode === 'edit' &&
            <ActionIcon variant='subtle' color="gray" onClick={() => setShowAddIntentModal(true)}>
              <Plus />
            </ActionIcon>
          }
        </Group>
      }
      cardKey={cardKey}
      icon={<Speakerphone />}
      collapseInfoRender={
        <Badge variant='light' color={colorScheme === 'dark' ? 'dark' : 'gray'}>{scopedState?.value?.length ?? 0} {t("suggestions")}</Badge>
      }>
      <>
        {scopedState?.value?.length > 0 &&
          <Stack gap="xs">
            <TextInput
              placeholder={t("Search suggestions...") as string}
              leftSection={<Search size={16} />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
            <DataTable
              noHeader
              height={records?.length > 6 ? 300 : undefined}
              striped
              highlightOnHover
              columns={getTableColumns()}
              records={records}
            />
          </Stack>
        }
        {(!scopedState?.value || scopedState?.value?.length === 0) &&
          <Center>
            <Text c="dimmed" size="sm">{t('Empty suggestions')}</Text>
          </Center>
        }
        {showAddIntentModal &&
          <Modal
            opened
            onClose={onCloseModal}
            title={<Text>{t("Add suggestion")}</Text>}
            closeOnClickOutside={false}>
            <Stack>
              <IntentInput
                required
                creatable
                width='100%'
                value={newIntent as string}
                onChange={(value) => setNewItent(value)}
                id={id}
                searchEntitiesStore={searchEntitiesStore}
                description={t("You can add multiple suggestions by writing them on separate lines or using commas.") as string}
              />
              <Button fullWidth onClick={addNewIntent}>
                {t("Add suggestion")}
              </Button>
            </Stack>
          </Modal>
        }
      </>
    </CollapsibleCard>
  );
};

export default SuggestionsCard;

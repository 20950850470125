import { State, useHookstate } from "@hookstate/core";
import { Card, Checkbox, Group, SimpleGrid, Stack, Text, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import { BotContentSkill, BotContentUserPreferences } from "src/stores/bots";
import { IconUserCog } from "@tabler/icons-react";
import AppContext from "src/services/app-context";
import { LanguageSeletor } from "src/components/language-selector";
import TemperatureSelector from "src/components/temperature-selector";
import SkillAssignedSelector from "src/components/skill-assigned-selector";
import SwitchButton from "src/components/switch-button";

const BotContentUserPreferencesCard: FC<{
  state: State<BotContentUserPreferences>;
  assignedSkills: BotContentSkill[];
  cardkey: string;
  edit?: boolean;
  rightToolbar?: React.ReactNode;
}> = ({ state, assignedSkills, cardkey, edit = false, rightToolbar }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const [temperatureSelector, setTemperatureSelector] = useState(scopedState?.temperature?.value?.toString() ?? '0.5');

  useEffect(() => {
    scopedState.temperature.set(parseFloat(temperatureSelector));
  }, [temperatureSelector])

  useEffect(() => {
    if (state?.temperature?.value) {
      setTemperatureSelector(state.temperature.value.toString());
    }
  }, [state])

  const onChangeSearchSkills = (value: string[]) => {
    scopedState.searchSkills.set(value);
    setIsDirty(true);
  }

  const onChangeAllowUserChangeTemperature = (value: boolean) => {
    if (edit) {
      scopedState.allowUserChangeTemperature.set(value);
      setIsDirty(true);
    }
  }

  const onChangeAllowUserChangeSearchSkills = (value: boolean) => {
    if (edit) {
      scopedState.allowUserChangeSearchSkills.set(value);
      setIsDirty(true);
    }
  }

  const onChangeAllowUserChangeSearchLanguages = (value: boolean) => {
    if (edit) {
      scopedState.allowUserChangeSearchLanguages.set(value);
      setIsDirty(true);
    }
  }

  const onChangeAllowUserChangeInternetAccess = (value: boolean) => {
    if (edit) {
      scopedState.allowUserChangeInternetAccess.set(value);
      setIsDirty(true);
    }
  }

  const onChangeAllowEnableOCRInDocuments = (value: boolean) => {
    if (edit) {
      scopedState.allowEnableOCRInDocuments.set(value);
      setIsDirty(true);
    }
  }

  return (
    <CollapsibleCard
      title={
        <Group justify='space-between'>
          <Text fw={500}>{t('User preferences')}</Text>
        </Group>
      }
      cardKey={cardkey}
      icon={<IconUserCog />}
      rightToolbar={rightToolbar}>
      <Stack>
        <Text c="dimmed">{t("Here you can set default settings for user options and whether they can be overridden.")}</Text>

        <SimpleGrid
          cols={{ base: 1, md: 2 }}
          spacing="sm">
          <Card
            withBorder
            shadow="xs"
            radius="sm"
            style={{
              backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
              border: `1px solid ${theme.colors.gray[3]}`,
              '&:hover': {
                border: `1px solid ${theme.colors[theme.primaryColor][3]}`,
              }
            }}>
            <Card.Section withBorder inheritPadding py={5}>
              <Group gap="xs" justify="space-between">
                <Text>{t("Temperature")}</Text>
                <Checkbox
                  readOnly={!edit}
                  defaultChecked={false}
                  checked={scopedState.allowUserChangeTemperature?.value ?? false}
                  onChange={e => onChangeAllowUserChangeTemperature(e.target.checked)}
                  label={t("Allow user to change")}
                />
              </Group>
            </Card.Section>
            <Card.Section inheritPadding mt="xs" pb="xs" py={5}>
              <TemperatureSelector
                fullWidth
                value={temperatureSelector}
                onChange={(value) => { setTemperatureSelector(value); setIsDirty(true); }}
                edit={edit}
              />
            </Card.Section>
          </Card>

          <Card
            withBorder
            shadow="xs"
            radius="sm"
            style={{
              backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
              border: `1px solid ${theme.colors.gray[3]}`,
              '&:hover': {
                border: `1px solid ${theme.colors[theme.primaryColor][3]}`,
              }
            }}>
            <Card.Section withBorder inheritPadding py={5}>
              <Group gap="xs" justify="space-between">
                <Text>{t("Search skills")}</Text>
                <Checkbox
                  readOnly={!edit}
                  defaultChecked={false}
                  checked={scopedState.allowUserChangeSearchSkills?.value ?? false}
                  onChange={e => onChangeAllowUserChangeSearchSkills(e.target.checked)}
                  label={t("Allow user to change")}
                />
              </Group>
            </Card.Section>
            <Card.Section inheritPadding mt="xs" py="xs">
              <SkillAssignedSelector
                assignedSkills={assignedSkills.map(skill => skill.skillId) ?? []}
                value={scopedState.searchSkills?.value as string[] ?? []}
                onChange={(value) => onChangeSearchSkills(value)}
                edit={edit}
              />
            </Card.Section>
          </Card>

          <Card
            withBorder
            shadow="xs"
            radius="sm"
            style={{
              backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
              border: `1px solid ${theme.colors.gray[3]}`,
              '&:hover': {
                border: `1px solid ${theme.colors[theme.primaryColor][3]}`,
              }
            }}>
            <Card.Section withBorder inheritPadding py={5}>
              <Group gap="xs" justify="space-between">
                <Text>{t("Search languages")}</Text>
                <Checkbox
                  readOnly={!edit}
                  defaultChecked={false}
                  checked={scopedState.allowUserChangeSearchLanguages?.value ?? false}
                  onChange={e => onChangeAllowUserChangeSearchLanguages(e.target.checked)}
                  label={t("Allow user to change")}
                />
              </Group>
            </Card.Section>
            <Card.Section inheritPadding mt="xs" py="xs">
              <LanguageSeletor
                multiselect
                value={scopedState.searchLanguages?.value as string[]}
                onChange={(value) => { scopedState.searchLanguages.set(value as string[]); setIsDirty(true); }}
                readOnly={!edit}
                placeholder={t("Nothing here") as string}
              />
            </Card.Section>
          </Card>

          <Card
            withBorder
            shadow="xs"
            radius="sm"
            style={{
              backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
              border: `1px solid ${theme.colors.gray[3]}`,
              '&:hover': {
                border: `1px solid ${theme.colors[theme.primaryColor][3]}`,
              }
            }}>
            <Card.Section withBorder inheritPadding py={5}>
              <Group gap="xs" justify="space-between">
                <Text>{t("Allow Internet access")}</Text>
                <Checkbox
                  readOnly={!edit}
                  defaultChecked={false}
                  checked={scopedState.allowUserChangeInternetAccess?.value ?? false}
                  onChange={e => onChangeAllowUserChangeInternetAccess(e.target.checked)}
                  label={t("Allow user to change")}
                />
              </Group>
            </Card.Section>
            <Card.Section inheritPadding mt="xs" py="xs">
              <SwitchButton
                label={t("Allow Internet access")}
                description={t("Allows Internet access if search skills support it") as string}
                checked={scopedState.allowInternetAccess.value ?? false}
                onChange={value => { scopedState.allowInternetAccess.set(value); setIsDirty(true); }}
                readOnly={!edit}
              />
            </Card.Section>
          </Card>

          <Card
            withBorder
            shadow="xs"
            radius="sm"
            style={{
              backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
              border: `1px solid ${theme.colors.gray[3]}`,
              '&:hover': {
                border: `1px solid ${theme.colors[theme.primaryColor][3]}`,
              }
            }}>
            <Card.Section withBorder inheritPadding py={5}>
              <Group gap="xs" justify="space-between">
                <Text>{t("Enable OCR in documents")}</Text>
                <Checkbox
                  readOnly={!edit}
                  defaultChecked={false}
                  checked={scopedState.allowEnableOCRInDocuments?.value ?? false}
                  onChange={e => onChangeAllowEnableOCRInDocuments(e.target.checked)}
                  label={t("Allow user to change")}
                />
              </Group>
            </Card.Section>
            <Card.Section inheritPadding mt="xs" py="xs">
              <SwitchButton
                label={t("Enable OCR in documents")}
                checked={scopedState.enableOCRInDocuments.value ?? false}
                onChange={value => { scopedState.enableOCRInDocuments.set(value); setIsDirty(true); }}
                readOnly={!edit}
              />
            </Card.Section>
          </Card>

        </SimpleGrid>
      </Stack>
    </CollapsibleCard>
  );
};

export default BotContentUserPreferencesCard;
import { ServiceConnectionCategory, ServiceConnectionType } from "src/stores/serviceconnections"
import BuiltInServiceConnection from "./builtin-serviceconnection"
import GptServiceConnection from "./gpt-serviceconnection"
import InsightsServiceConnection from "./insights-serviceconnection"
import LlamaServiceConnection from "./llama-serviceconnection"
import SearchInternetServiceConnection from "./search-internet-serviceconnection"
import SharePointServiceConnection from "./sharepoint-serviceconnection"
import { ReactNode, RefObject } from "react"
import OAuthServiceConnection from "./oauth-serviceconnection"
import MistralServiceConnection from "./mistral-serviceconnection"
import sharepointLogo from 'src/assets/images/sharepoint-logo.png';
import mistralLogo from 'src/assets/images/mistral-ai.png';
import aplicationInsightsLogo from 'src/assets/images/application-insights.png';
import webApiLogo from 'src/assets/images/webapi-logo.png';
import { IconBrandOpenai, IconBrandMeta, IconBrain, IconBrandOauth } from "@tabler/icons-react"

export type ServiceConnectionRef = {
  getContent: () => string,
  isValid: () => boolean,
  getResume: () => React.ReactNode
}

export interface ServiceConnectionItem {
  category: ServiceConnectionCategory,
  type: ServiceConnectionType,
  value: string,
  label: string,
  icon?: ReactNode
}

export const getServiceConnectionData = (t: any) => [
  { category: 'Llm', type: 'Gpt', value: 'LlmGpt', label: t('Gpt-like Large Language Model (LLM)') as string, icon: <IconBrandOpenai /> },
  { category: 'Llm', type: 'Llama', value: 'LlmLlama', label: t('Llama-like Large Language Model (LLM)') as string, icon: <IconBrandMeta /> },
  { category: 'Llm', type: 'BuiltIn', value: 'LlmBuiltIn', label: t('Large Language Model (LLM) Built-in') as string, icon: <IconBrain /> },
  { category: 'Llm', type: 'Mistral', value: 'LlmMistral', label: t('Mistral AI Large Language Model (LLM)') as string, icon: <img src={mistralLogo} /> },
  { category: 'Search', type: 'Internet', value: 'SearchInternet', label: t('Search Internet') as string, icon: <img src={webApiLogo} /> },
  { category: 'Search', type: 'SharePoint', value: 'SearchSharePoint', label: t('Search SharePoint') as string, icon: <img src={sharepointLogo} /> },
  { category: 'Insights', type: 'AppInsights', value: 'InsightsAppInsights', label: t('Microsoft Application Insights') as string, icon: <img src={aplicationInsightsLogo} /> },
  { category: 'Identities', type: 'OAuth', value: 'IdentitiesOAuth', label: t('OAuth Identity') as string, icon: <IconBrandOauth /> },
] as ServiceConnectionItem[];

export const getServiceTypeFields = (serviceId: string, type: ServiceConnectionType, serviceConnectionRef: RefObject<ServiceConnectionRef>, content?: string, canContribute?: boolean) => {
  switch (type) {
    case 'AppInsights':
      return <InsightsServiceConnection ref={serviceConnectionRef} key='service-connection-insights' content={content} canContribute={canContribute} />
    case 'Gpt':
      return <GptServiceConnection ref={serviceConnectionRef} key='service-connection-gpt' content={content} canContribute={canContribute} />
    case 'Mistral':
      return <MistralServiceConnection ref={serviceConnectionRef} key='service-connection-mistral' content={content} canContribute={canContribute} />
    case 'BuiltIn':
      return <BuiltInServiceConnection ref={serviceConnectionRef} key='service-connection-builtin' content={content} canContribute={canContribute} />
    case 'Llama':
      return <LlamaServiceConnection ref={serviceConnectionRef} key='service-connection-llama' content={content} canContribute={canContribute} />
    case 'Internet':
      return <SearchInternetServiceConnection ref={serviceConnectionRef} key='service-connection-internet' content={content} canContribute={canContribute} />
    case 'SharePoint':
      return <SharePointServiceConnection ref={serviceConnectionRef} key='service-connection-sharepoint' content={content} canContribute={canContribute} />
    case 'OAuth':
      return <OAuthServiceConnection ref={serviceConnectionRef} key='service-connection-oauth' serviceId={serviceId} content={content} canContribute={canContribute} />
    default:
      return <GptServiceConnection ref={serviceConnectionRef} key='service-connection-gpt' content={content} canContribute={canContribute} />
  }
}
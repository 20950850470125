import { Alert, Button, Group, LoadingOverlay, Modal, Select, ComboboxItem, Stack, Text, TextInput } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { container } from "src/inversify.config";
import { formatMessage } from "src/core/utils/object";
import { EntityItem, EntityItemStore, EntityType } from "src/stores/entities";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";
import { IconAlertCircle, IconDeviceFloppy } from "@tabler/icons-react";
import { BasicMultiselect } from "src/components/basic-multiselect";

const EditEntity: FC<{
  opened: boolean;
  entityId: string;
  onClose: () => void;
  onFinish?: () => void;
}> = ({ opened, entityId, onClose, onFinish }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(EntityItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');

  useEffect(() => {
    if (entityId) {
      load(entityId);
    }
  }, [entityId]);

  const load = async (entityId: string) => {
    await store.load(entityId);
  }

  const isValid = () => {
    return state.item.name.value && state.item.type.value && state.item.samples.value
  }

  const onSubmit = async () => {
    if (isValid()) {
      await store.save(entityId, state.item.value as EntityItem);

      if (!state.errorMessage.value) {
        onFinish?.();
      }
    }
  }

  const onCloseModal = () => {
    onClose();
  }

  const getSamples = (lang: string) => {
    let data = [] as ComboboxItem[];

    if (state?.item?.samples?.value && state.item.samples[lang].value) {
      data = state.item.samples[lang].value.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  return (
    <Modal
      title={<span>{t("Edit entity")}</span>}
      size='xl'
      withCloseButton
      closeOnClickOutside={false}
      onClose={onCloseModal}
      opened={opened}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        {state?.item?.value &&
          <form>
            <Stack>
              <Group>
                <TextInput
                  required
                  data-autofocus
                  style={{ flex: 1 }}
                  label={t('Name')}
                  value={state.item.name.value}
                  onChange={(event) => state.item.name.set(event.target.value)}
                  disabled
                />

                <Select
                  allowDeselect={false}
                  required
                  label={t("Type")}
                  placeholder={t("Entity type") as string}
                  data={[
                    { value: 'Synonyms', label: t('Synonyms') as string },
                    { value: 'Regex', label: t('Regex') as string },
                  ]}
                  value={state.item.type.value}
                  onChange={(value) => state.item.type.set(value as EntityType)}
                  disabled
                />
              </Group>

              <LanguageSegmentedControl
                language={selectedLanguage}
                onChange={setSelectedLanguage}
              />

              <BasicMultiselect
                required
                label={t("Samples") as string}
                data={getSamples(selectedLanguage)}
                value={state.item.samples[selectedLanguage].value as string[]}
                onChange={(value) => state.item.samples[selectedLanguage].set(value)}
                creatable
              />

            </Stack>

            <Group justify="flex-end" mt="xl">
              <Button
                disabled={!isValid()}
                loading={isBusy}
                leftSection={<IconDeviceFloppy />}
                onClick={onSubmit}>
                {t("Save")}
              </Button>
            </Group>
          </form>
        }
      </div>
    </Modal>
  );
};

export default EditEntity;
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Select, Text, ComboboxItem, Box } from '@mantine/core';
import { ESFlag, GBFlag, FRFlag, PTFlag, ITFlag } from 'mantine-flagpack';
import { AppConfiguration } from 'src/core/services/authentication-service';
import { container } from 'src/inversify.config';
import { BasicMultiselect } from './basic-multiselect';

export const getLanguageIcon = (lang: string, size: number = 16, opacity: number = 1) => {
  switch (lang) {
    case 'es':
      return <ESFlag w={size} opacity={opacity} />;
    case 'fr':
      return <FRFlag w={size} opacity={opacity} />
    case 'pt':
      return <PTFlag w={size} opacity={opacity} />;
    case 'it':
      return <ITFlag w={size} opacity={opacity} />;
    default:
      return <GBFlag w={size} opacity={opacity} />;
  }
}

export const getLanguageText = (lang: string, t: any) => {
  switch (lang) {
    case 'es':
      return t('Spanish');
    case 'fr':
      return t('French');
    case 'pt':
      return t('Portuguese');
    case 'it':
      return t('Italian');
    default:
      return t('English');
  }
}

export const LanguageSeletor: FC<{
  value?: string[] | string;
  onChange?: (value: string[] | string) => void;
  label?: string;
  required?: boolean;
  error?: ReactNode;
  multiselect?: boolean;
  readOnly?: boolean;
  variant?: 'filled' | 'unstyled' | undefined;
  showAll?: boolean;
  width?: string | number;
  placeholder?: string;
  allowedLanguages?: string[];
  allowDeselect?: boolean;
}>
  = ({ value, onChange, label, required, error, multiselect = false, readOnly = false, variant, showAll = false, width, placeholder, allowedLanguages, allowDeselect = false }) => {
    const { t } = useTranslation();
    const globalAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;

    let data = [] as ComboboxItem[];

    if (showAll) {
      data = [
        { value: 'en', label: t('English') as string },
        { value: 'es', label: t('Spanish') as string },
        { value: 'fr', label: t('French') as string },
        { value: 'pt', label: t('Portuguese') as string },
        { value: 'it', label: t('Italian') as string },
      ];
    }
    else {
      (allowedLanguages ?? globalAllowedLanguages).forEach(lang => {
        data.push({ value: lang, label: getLanguageText(lang, t) });
      });
    }

    const renderMultiOption = (item: ComboboxItem) => (
      <Group wrap='nowrap' justify='center' gap="xs"><Box mt={3}>{getLanguageIcon(item.value)}</Box><Text span size='sm'>{item.label}</Text></Group>
    );

    return (
      <>
        {multiselect &&
          <BasicMultiselect
            label={label}
            data={data}
            value={value as string[]}
            onChange={(value) => onChange?.(value)}
            required={required}
            variant={variant}
            readOnly={readOnly}
            width={width}
            renderOption={renderMultiOption}
            error={error}
          />
        }
        {!multiselect &&
          <Select
            required={required}
            label={label}
            data={data}
            value={value as string}
            onChange={(value) => onChange?.(value as string)}
            error={error}
            readOnly={readOnly}
            variant={variant}
            leftSection={getLanguageIcon(value as string)}
            width={width ?? 240}
            placeholder={placeholder}
            allowDeselect={allowDeselect}
          />
        }
      </>
    );
  };

import { FC, useMemo } from 'react';
import { ComboboxItem, MantineStyleProp, Select } from '@mantine/core';
import timezones from '../utils/timezones';
import spacetime from 'spacetime';

export const TimezoneSelector: FC<{
  value?: string;
  onChange?: (value: string) => void;
  width?: string | number;
  label?: string;
  clearable?: boolean;
  required?: boolean;
  readOnly?: boolean;
  style?: MantineStyleProp;
}> = ({ value, onChange, width, label, clearable, required, readOnly, style }) => {

  const options = useMemo(() => {
    return Object.entries(timezones)
      .map((zone) => {
        try {
          const displayValue = "GMT";
          const now = spacetime.now().goto(zone[0]);
          const tz = now.timezone();

          const min = tz.current.offset * 60;
          const hr = `${(min / 60) ^ 0}:${min % 60 === 0 ? "00" : Math.abs(min % 60)}`;
          const displayLabel = `(${displayValue}${hr.includes("-") ? hr : `+${hr}`}) ${zone[1]}`;

          return {
            value: zone[0],
            label: displayLabel,
            offset: tz.current.offset,
          }
        } catch {
          return null
        }
      })
      .filter(Boolean)
      .sort((a, b) => (a?.offset ?? 0) - (b?.offset ?? 1))
  }, [timezones])

  const data = options.map((o) => ({ value: o?.value, label: o?.label } as ComboboxItem));

  return (
    <Select
      required={required}
      clearable={clearable}
      label={label}
      width={width || 300}
      data={data}
      value={value as string}
      onChange={(value) => onChange?.(value as string)}
      readOnly={readOnly}
      style={style}
      searchable
    />
  );
};
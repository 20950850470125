import { State, useHookstate } from "@hookstate/core";
import { Group, Input, TextInput, Text, Badge } from "@mantine/core";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getRandomString, normalizeName } from "src/core/utils/object";
import AppContext from "src/services/app-context";

const SkillContentStepTitle: FC<{
  edit?: boolean;
  state: State<string>;
  index: number;
}> = ({ edit = false, state, index }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <>
      {edit ?
        <Group align="center">
          <Input.Label w={120} required>{`${t("Step")} #${index + 1}`}:</Input.Label>
          <TextInput
            required
            placeholder={t("Step name") as string}
            style={{ flex: 1 }}
            value={scopedState.value}
            onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
            onBlur={() => scopedState.value.length > 0 ? scopedState.set((c) => normalizeName(c, true)) : scopedState.set(`step_${index + 1}_${getRandomString(6)}`)}
          />
        </Group>
        :
        <Group>
          <Text fw={500}>{`${t("Step")} #${index + 1}`}: </Text>
          <Badge variant="light" size="lg" radius="xs" style={{ textTransform: 'none' }}>{scopedState.value}</Badge>
        </Group>
      }
    </>
  );
};

export default SkillContentStepTitle;
import FormStore from "src/core/stores/form-store";
import DataStore from "../core/stores/data-store";

export interface ClaimsProfileItem {
  name: string;
  displayName: string;
  language: string;
  audience: string[];
  groups: string[];
  company: string;
  location: string;
  timezone: string;
  quickStartWizardState: string;
  properties: { [key: string]: any };
  metadata: { [key: string]: any };
}

export interface UserProfileIdentityItem {
  active: boolean;
  expired: boolean;
  serviceId: string;
  loginHint: string;
  scope: string;
  title: string;
  description: string;
}

export class ProfileStore extends FormStore<ClaimsProfileItem, ClaimsProfileItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/profile`);
  }
}

export class ProfileIdentitiesStore extends DataStore<UserProfileIdentityItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/profile/me/identities`, []);
  }
}
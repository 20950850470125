import { useMemo, useEffect, useState, FC } from 'react';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { Group, MantineStyleProp, Text } from '@mantine/core';
import { IconBook2 as Book2 } from '@tabler/icons-react';
import useBus from 'use-bus';
import { GenericSelector } from 'src/core/ui/generic-selector';
import { CollectionSummary, CollectionSummaryStore } from 'src/stores/collections';

export const CollectionSelector: FC<{
  value?: string;
  onChange?: (value: string) => void;
  width?: string | number;
  label?: string;
  clearable?: boolean;
  required?: boolean;
  creatable?: boolean;
  readOnly?: boolean;
  style?: MantineStyleProp;
}> = ({ value, onChange, width, label, clearable, required, creatable, readOnly, style }) => {
  const store = useMemo(() => container.get(CollectionSummaryStore), []);
  const state = store.state;
  const { t } = useTranslation();

  const [query, setQuery] = useState<Query>({
    searchQuery: '',
    orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: 100,
    parameters: {
      includeIds: [value]
    }
  } as Query);

  useEffect(() => {
    if (value) {
      const newQuery = {
        ...query,
        skip: 0,
        parameters: {
          includeIds: [value]
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    load(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  const load = async (query: Query) => {
    store.load(query);
  };

  useBus(
    '@@ui/COLLECTION_LIST_REFRESH',
    () => load(query),
    [],
  );

  const renderDataItem = (item: CollectionSummary) => {
    return {
      label: item.title,
      value: item.id,
    } as any;
  }

  const renderOption = (item: CollectionSummary) => (
    <Group gap="xs">
      <Book2 color="gray" size={20} /><Text size='sm'>{item.title}</Text>
    </Group>
  );

  return (
    <GenericSelector
      label={label}
      required={required}
      data={store.toListState(state.value)}
      renderDataItem={(item) => renderDataItem(item)}
      query={query}
      onQueryChanged={setQuery}
      value={value}
      onChange={(value) => onChange?.(value)}
      width={width || 300}
      placeholder={t('Select a collection') as string}
      searchable
      searchField='title'
      clearable={clearable}
      renderOption={renderOption}
      creatable={creatable}
      withinPortal
      readOnly={readOnly}
      style={style}
    />
  );
};
import { none, State, useHookstate } from "@hookstate/core";
import { ActionIcon, Badge, Center, Group, Menu, NavLink, Popover, SegmentedControl, Stack, Text, Tooltip, useMantineColorScheme } from "@mantine/core";
import React, { useCallback, useContext, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import { ContentKeyValue, SkillContentStep, SkillContentStepType, SkillTypeEnum } from "src/stores/skills";
import SkillContentStepAsk from "./skill-content-step-ask";
import SkillContentStepCallApi from "./skill-content-step-callapi";
import SkillContentStepExternalSkill from "./skill-content-step-externalskill";
import SkillContentStepSay from "./skill-content-step-say";
import SkillContentStepGoto from "./skill-content-step-goto";
import SkillContentAssign from "./skill-content-step-assign";
import SkillContentCallLlm from "./skill-content-step-llmcall";
import AppContext from "src/services/app-context";
import { IconPlus, IconMessageCircle, IconQuestionMark, IconApi, IconExternalLink, IconStepInto, IconMessages, IconArrowUp, IconArrowDown, IconDotsVertical, IconTrash, IconCopy, IconEqualDouble, IconBrain, IconMessageForward, IconBrandJavascript, IconFileSearch, IconLayoutDashboard, IconList } from "@tabler/icons-react";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import SkillContentStepEvent from "./skill-content-step-event";
import { getRandomString } from "src/core/utils/object";
import ReactFlow, { applyEdgeChanges, applyNodeChanges, Background, BackgroundVariant, Controls, Edge, MarkerType, MiniMap, Node, ReactFlowProvider } from "reactflow";
import { edgeTypes, StepDataProps, StepOptionsEdgeDataProps, StepsRef, stepTypes } from "./steps/steps";

type StepsViewMode = "List" | "Flow";

interface SkillcontentStepsProps {
  skillId: string,
  skillType: SkillTypeEnum,
  state: State<SkillContentStep[]>,
  cardkey: string,
  edit?: boolean
};

const SkillContentStepsCard = React.forwardRef<StepsRef, SkillcontentStepsProps>(({ skillId, skillType, state, cardkey, edit = false }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const { colorScheme } = useMantineColorScheme();
  const allowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const defaultLang = allowedLanguages[0];
  const defaultText: { [key: string]: string } = {};
  const defaultQuestion: { [key: string]: string } = {};
  const defaultTextPrompt: { [key: string]: string } = {};
  defaultText[defaultLang] = t("<your message>", { lng: defaultLang });
  defaultQuestion[defaultLang] = t("<your question>", { lng: defaultLang });
  defaultTextPrompt[defaultLang] = '';
  const [viewMode, setViewMode] = useState<StepsViewMode>("Flow");

  useImperativeHandle(ref, () => ({
    getSteps: () => {
      if (viewMode === "Flow") {
        updateStateFromNodes();
      }
      return JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStep[];
    }
  }));

  useEffect(() => {
    if (scopedState?.value && viewMode === "Flow") {
      setTimeout(() => {
        rebuildFlow();
      }, 100);
    }
  }, [scopedState])

  useEffect(() => {
    if (scopedState?.value && viewMode === 'List') {
      updateStateFromNodes();
    }
  }, [viewMode])

  const initialNodes: Node[] = [];
  const initialEdges: Edge[] = [];
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);

  const onNodesChange = useCallback(
    (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const updateNode = (id: string, data: StepDataProps) => {
    setNodes(nodes => nodes.map(node => node.id === id ? { ...node, data: { ...node.data, ...data } } : node));
  };

  const updateStateFromNodes = () => {
    scopedState.set(nodes.filter(n => n.type !== 'Start' && n.type !== "End").map(node => (node.data.step)));
  }

  const rebuildFlow = () => {
    if (viewMode !== "Flow") return;

    let nodes = [] as Node[];
    let edges = [] as Edge[];
    let prevStepId = '';
    let yPos = 0;
    let lastIndex = 0;

    nodes.push({
      id: 'start',
      position: { x: 0, y: yPos },
      type: 'Start',
      data: {
        edit: edit,
        step: {},
        index: 0,
      } as StepDataProps
    } as Node);

    prevStepId = 'start';
    yPos += 200;

    const steps = (scopedState?.value ? JSON.parse(JSON.stringify(scopedState?.value)) : []) as SkillContentStep[];

    if (steps?.length > 0) {
      for (let index = 0; index < steps.length; index++) {
        const step = JSON.parse(JSON.stringify(steps[index])) as SkillContentStep;

        const stepId = `${step.action.type}-${index}`;
        lastIndex = index;

        nodes.push({
          id: stepId,
          position: { x: 0, y: yPos },
          type: step.action.type,
          data: {
            edit: edit,
            step: step,
            index: index,
            rightToolbar: rightSection(index, step),
            steps: step.action.type === 'GoTo' ? steps.map((s) => (s.step)) : undefined,
            updateNode: updateNode
          } as StepDataProps
        } as Node);

        if (prevStepId !== '') {
          edges.push({
            id: `${prevStepId}-${stepId}`,
            source: prevStepId,
            target: stepId,
            type: 'stepOptionsEdge',
            markerEnd: {
              type: MarkerType.ArrowClosed
            },
            data: {
              index: index,
              edit: edit,
              skillType: skillType,
              onAddStep: onAddStep
            } as StepOptionsEdgeDataProps
          } as Edge);
        }

        prevStepId = stepId;
        yPos += 330;
      }
    }

    nodes.push({
      id: 'end',
      position: { x: 0, y: yPos },
      type: 'End',
      data: {
        edit: edit,
        step: {},
        index: lastIndex + 1,
      } as StepDataProps
    } as Node);

    edges.push({
      id: `${prevStepId}-end`,
      source: prevStepId,
      target: 'end',
      type: 'stepOptionsEdge',
      markerEnd: {
        type: MarkerType.ArrowClosed
      },
      data: {
        index: lastIndex + 1,
        edit: edit,
        skillType: skillType,
        onAddStep: onAddStep
      } as StepOptionsEdgeDataProps
    } as Edge)

    setNodes(nodes);
    setEdges(edges);
  }

  const onAddStep = (type: SkillContentStepType, index?: number) => {
    switch (type) {
      case "Say":
        onAddStepSay(index);
        break;
      case "Ask":
        onAddStepAsk(index);
        break;
      case "CallApi":
        onAddStepCallApi(index);
        break;
      case "CallSkill":
        onAddStepExternalSkill(index);
        break;
      case "GoTo":
        onAddStepGoTo(index);
        break;
      case "Assign":
        onAddAssignStep(index);
        break;
      case "CallLlm":
        onAddCallLlmStep(index);
        break;
      case "Event":
        onAddStepEvent(index);
        break;
      // case "ExecuteCode":
      //   onAddStepExecutionCode(index);
      //   break;
      // case "Search":
      //   onAddStepSearch(index);
      //   break;
    }
  }

  const onAddStepSay = (index?: number) => {
    const newStep = {
      step: `step_${scopedState.length + 1}`,
      condition: '',
      turn: 'End',
      action: {
        type: 'Say',
        text: [defaultText],
      }
    } as SkillContentStep;
    addStep(newStep, index);
  }

  const onAddStepAsk = (index?: number) => {
    const newStep = {
      step: `step_${scopedState.length + 1}`,
      condition: '',
      turn: 'End',
      action: {
        type: 'Ask',
        questions: [defaultQuestion]
      }
    } as SkillContentStep;
    addStep(newStep, index);
  }

  const onAddStepCallApi = (index?: number) => {
    const newStep = {
      step: `step_${scopedState.length + 1}`,
      condition: '',
      turn: 'End',
      action: {
        type: 'CallApi',
        method: 'GET',
        uri: '<api url>',
        headers: [] as ContentKeyValue[]
      }
    } as SkillContentStep;
    addStep(newStep, index);
  }

  const onAddStepExternalSkill = (index?: number) => {
    const newStep = {
      step: `step_${scopedState.length + 1}`,
      condition: '',
      turn: 'End',
      action: {
        type: 'CallSkill',
        forwardUserText: true,
        headers: [] as ContentKeyValue[]
      }
    } as SkillContentStep;
    addStep(newStep, index);
  }

  const onAddStepGoTo = (index?: number) => {
    const newStep = {
      step: `step_${scopedState.length + 1}`,
      condition: '',
      turn: 'End',
      action: {
        type: 'GoTo'
      }
    } as SkillContentStep;
    addStep(newStep, index);
  }

  const onAddAssignStep = (index?: number) => {
    const newStep = {
      step: `step_${scopedState.length + 1}`,
      condition: '',
      turn: 'End',
      action: {
        type: 'Assign',
        body: '{}',
        target: 'Output'
      }
    } as SkillContentStep;
    addStep(newStep, index);
  }

  const onAddCallLlmStep = (index?: number) => {
    const newStep = {
      step: `step_${scopedState.length + 1}`,
      condition: '',
      turn: 'End',
      action: {
        type: 'CallLlm',
        entity: 'response',
        prompt: defaultTextPrompt,
        temperature: 0.5,
        maxTokens: 1024
      }
    } as SkillContentStep;
    addStep(newStep, index);
  }

  const onAddStepEvent = (index?: number) => {
    const newStep = {
      step: `step_${scopedState.length + 1}`,
      condition: '',
      turn: 'End',
      action: {
        type: 'Event',
        text: [defaultText],
      }
    } as SkillContentStep;
    addStep(newStep, index);
  }

  // const onAddStepExecutionCode = (index?: number) => {
  //   const newStep = {
  //     step: `step_${scopedState.length + 1}`,
  //     condition: '',
  //     turn: 'End',
  //     action: {
  //       type: 'ExecuteCode',
  //       body: '',
  //       executionSide: "Client"
  //     }
  //   } as SkillContentStep;
  //   addStep(newStep, index);
  // }

  // const onAddStepSearch = (index?: number) => {
  //   const defaultSeachContent = GetSkillDefaultContent("Search") as SkillSearchContent;
  //   const newStep = {
  //     step: `step_${scopedState.length + 1}`,
  //     condition: '',
  //     turn: 'End',
  //     action: {
  //       type: 'Search',
  //       body: '',
  //       collections: defaultSeachContent.collections,
  //       includePersonalCollections: defaultSeachContent.includePersonalCollections,
  //       confidence: defaultSeachContent.confidence,
  //       cutOff: defaultSeachContent.cutOff,
  //       boost: defaultSeachContent.boost,
  //       alpha: defaultSeachContent.alpha,
  //       maxResults: defaultSeachContent.maxResults,
  //       scoring: defaultSeachContent.scoring,
  //       showChatInDocumentViewer: defaultSeachContent.showChatInDocumentViewer,
  //       enableInternetSearch: defaultSeachContent.enableInternetSearch,
  //       internetSearchService: defaultSeachContent.internetSearchService,
  //       internetSearchMinScore: defaultSeachContent.internetSearchMinScore,
  //     }
  //   } as SkillContentStep;
  //   addStep(newStep, index);
  // }

  const addStep = (step: SkillContentStep, index?: number) => {
    const rnd = getRandomString(6);
    step.step = `${step.step}_${rnd}`;

    if (index == undefined) {
      scopedState.merge([step]);
    }
    else {
      scopedState.set((p: any) => {
        p.splice((index), 0, step);
        return p;
      });
    }
    setIsDirty(true);
  }

  const onClone = (index: number, item: SkillContentStep) => {
    const copy = JSON.parse(JSON.stringify(item)) as SkillContentStep;
    copy.step = `${copy.step}_copy`;
    addStep(copy, index + 1);
  }

  const onDragUp = (index: number) => {
    if (index > 0) {
      const to = (index - 1);
      const from = index;
      scopedState.merge(p => ({ [from]: p[to], [to]: p[from] }));
      setIsDirty(true);
    }
  }

  const onDragDown = (index: number) => {
    if ((index + 1) < scopedState.value.length) {
      const to = (index + 1);
      const from = index;
      scopedState.merge(p => ({ [from]: p[to], [to]: p[from] }));
      setIsDirty(true);
    }
  }

  const onDeleteStep = (index: number) => {
    scopedState[index].set(none);
    //setIsDirty(true); //BUG: cuando se muestra la vista flow provoca error HOOKSTATE-111. https://hookstate.js.org/docs/exceptions#hookstate-111
  }

  const rightSection = (index: number, step: SkillContentStep) => edit &&
    <>
      {index > 0 &&
        <ActionIcon onClick={() => onDragUp(index)} variant='subtle' color="gray" className="nodrag">
          <IconArrowUp size={20} />
        </ActionIcon>
      }
      {(index + 1) < scopedState.value.length &&
        <ActionIcon onClick={() => onDragDown(index)} variant='subtle' color="gray" className="nodrag">
          <IconArrowDown size={20} />
        </ActionIcon>
      }
      <Popover withArrow withinPortal shadow="sm">
        <Popover.Target>
          <Tooltip label={t("More options")}>
            <ActionIcon variant='subtle' color="gray" className="nodrag">
              <IconDotsVertical size={20} />
            </ActionIcon>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown p={5}>
          <NavLink
            label={t("Clone")}
            onClick={() => onClone(index, step as SkillContentStep)}
            leftSection={<IconCopy size={16} />}
          />
          <NavLink
            label={t("Add step below")}
            leftSection={<IconPlus size={16} />}
            childrenOffset={10}>
            <NavLink onClick={() => onAddStepSay(index + 1)} leftSection={<IconMessageCircle size={16} />} label={t("Say")} />
            {skillType === 'Dialog' && <NavLink onClick={() => onAddStepAsk(index + 1)} leftSection={<IconQuestionMark size={16} />} label={t("Ask")} />}
            <NavLink onClick={() => onAddStepCallApi(index + 1)} leftSection={<IconApi size={16} />} label={t("Call API")} />
            {skillType === 'Dialog' && <NavLink onClick={() => onAddStepExternalSkill(index + 1)} leftSection={<IconExternalLink size={16} />} label={t("External skill")} />}
            <NavLink onClick={() => onAddStepGoTo(index + 1)} leftSection={<IconStepInto size={16} />} label={t("Go to")} />
            <NavLink onClick={() => onAddAssignStep(index + 1)} leftSection={<IconEqualDouble size={16} />} label={t("Assign")} />
            <NavLink onClick={() => onAddCallLlmStep(index + 1)} leftSection={<IconBrain size={16} />} label={t("Call LLM")} />
            <NavLink onClick={() => onAddStepEvent(index + 1)} leftSection={<IconMessageForward size={16} />} label={t("Event")} />
            {/* <NavLink onClick={() => onAddStepExecutionCode(index + 1)} leftSection={<IconBrandJavascript size={16} />} label={t("Execute code")} />
            <NavLink onClick={() => onAddStepSearch(index + 1)} leftSection={<IconFileSearch size={16} />} label={t("Search")} /> */}
          </NavLink>
          <NavLink
            active
            color="red"
            variant="subtle"
            label={t("Delete")}
            onClick={() => onDeleteStep(index)}
            leftSection={<IconTrash size={16} />}
          />
        </Popover.Dropdown>
      </Popover>
    </>

  return (
    <CollapsibleCard
      noCollapsible
      title={
        <Group justify='space-between'>
          <Text fw={500}>{t('Steps')}</Text>
          {edit &&
            <Menu shadow="md" width={200} withArrow withinPortal>
              <Menu.Target>
                <ActionIcon variant='subtle' color="gray">
                  <IconPlus />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{t("Select step type")}</Menu.Label>
                <Menu.Item onClick={() => onAddStepSay()} leftSection={<IconMessageCircle size={16} />}>{t("Say")}</Menu.Item>
                {skillType === 'Dialog' && <Menu.Item onClick={() => onAddStepAsk()} leftSection={<IconQuestionMark size={16} />}>{t("Ask")}</Menu.Item>}
                <Menu.Item onClick={() => onAddStepCallApi()} leftSection={<IconApi size={16} />}>{t("Call API")}</Menu.Item>
                {skillType === 'Dialog' && <Menu.Item onClick={() => onAddStepExternalSkill()} leftSection={<IconExternalLink size={16} />}>{t("External skill")}</Menu.Item>}
                <Menu.Item onClick={() => onAddStepGoTo()} leftSection={<IconStepInto size={16} />}>{t("Go to")}</Menu.Item>
                <Menu.Item onClick={() => onAddAssignStep()} leftSection={<IconEqualDouble size={16} />}>{t("Assign")}</Menu.Item>
                <Menu.Item onClick={() => onAddCallLlmStep()} leftSection={<IconBrain size={16} />}>{t("Call LLM")}</Menu.Item>
                <Menu.Item onClick={() => onAddStepEvent()} leftSection={<IconMessageForward size={16} />}>{t("Event")}</Menu.Item>
                {/* <Menu.Item onClick={() => onAddStepExecutionCode()} leftSection={<IconBrandJavascript size={16} />}>{t("Execute code")}</Menu.Item>
                <Menu.Item onClick={() => onAddStepSearch()} leftSection={<IconFileSearch size={16} />}>{t("Search")}</Menu.Item> */}
              </Menu.Dropdown>
            </Menu>
          }
        </Group>
      }
      cardKey={cardkey}
      icon={<IconMessages />}
      collapseInfoRender={
        <Badge variant='light' color='gray'>{scopedState?.value?.length} {t("steps")}</Badge>
      }
      rightToolbar={
        <SegmentedControl
          size="xs"
          value={viewMode}
          data={[
            {
              value: 'Flow',
              label: <IconLayoutDashboard style={{ width: 20, height: 20, display: 'block' }} />,
            },
            {
              value: 'List',
              label: <IconList style={{ width: 20, height: 20, display: 'block' }} />,
            },
          ]}
          onChange={(value) => setViewMode(value as StepsViewMode)}
        />
      }>
      <Stack style={{ display: 'flex', height: '100%' }}>
        {viewMode === "List" ?
          <>
            {scopedState?.value?.length > 0 && scopedState.map((step, index) =>
              <>
                {step.action.type.value === "Say" &&
                  <SkillContentStepSay
                    key={`step-say-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                }
                {step.action.type.value === "Ask" &&
                  <SkillContentStepAsk
                    key={`step-ask-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                }
                {step.action.type.value === "CallApi" &&
                  <SkillContentStepCallApi
                    key={`step-callapi-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                }
                {step.action.type.value === "GoTo" &&
                  <SkillContentStepGoto
                    key={`step-goto-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                    steps={scopedState?.value.map((s) => (s.step))}
                  />
                }
                {step.action.type.value === "CallSkill" &&
                  <SkillContentStepExternalSkill
                    key={`step-external-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                }
                {step.action.type.value === "Assign" &&
                  <SkillContentAssign
                    key={`step-external-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                }
                {step.action.type.value === "CallLlm" &&
                  <SkillContentCallLlm
                    key={`step-external-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                }
                {step.action.type.value === "Event" &&
                  <SkillContentStepEvent
                    key={`step-event-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                }
                {/* {step.action.type.value === "ExecuteCode" &&
                  <SkillContentStepExecuteCode
                    key={`step-executecode-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                }
                {step.action.type.value === "Search" &&
                  <SkillContentStepSearch
                    key={`step-search-${index}`}
                    edit={edit}
                    state={step}
                    index={index}
                    rightToolbar={rightSection(index, step.value as SkillContentStep)}
                  />
                } */}
              </>
            )}
            {scopedState?.value?.length === 0 &&
              <Center>
                <Text c="dimmed" size="sm">{t('No steps')}</Text>
              </Center>
            }
          </>
          :
          <div style={{ width: '100%', height: '75svh' }} className={colorScheme === "dark" ? "stepFlowDark" : undefined}>
            {scopedState?.value && nodes && edges &&
              <ReactFlowProvider>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  nodeTypes={stepTypes}
                  edgeTypes={edgeTypes}
                  nodesConnectable={false}
                  nodesDraggable={edit}
                  fitView>
                  <Controls />
                  <MiniMap pannable />
                  <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
                </ReactFlow>
              </ReactFlowProvider>
            }
          </div>
        }
      </Stack>
    </CollapsibleCard>
  );
});

export default SkillContentStepsCard;
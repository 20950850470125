import { Center, Highlight, Stack, Text, TextInput, useMantineTheme } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'mantine-datatable';
import { useDebouncedValue } from '@mantine/hooks';
import { State, useHookstate } from '@hookstate/core';
import { getEntitiesInText } from 'src/core/utils/object';
import { IconSearch } from '@tabler/icons-react';

const PAGE_SIZE = 10;

interface IntentItem {
  intent: string;
  index: number;
}

const DocumentSnapshotPassageIntents: FC<{
  state: State<string[]>;
}> = ({ state }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);
  const scopedState = useHookstate(state);
  const [records, setRecords] = useState<IntentItem[]>(scopedState?.value ? scopedState.value.map((i, index) => ({ intent: i, index: index })).slice(0, PAGE_SIZE) : []);

  useEffect(() => {
    if (scopedState?.value) {
      const from = (page - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      setRecords(scopedState?.value?.map((i, index) => ({ intent: i, index: index })).slice(from, to));
    }
  }, [page, scopedState]);

  useEffect(() => {
    setRecords(
      scopedState?.value?.map((i, index) => ({ intent: i, index: index })).filter(({ intent }) => {
        if (debouncedSearchTerm !== '' && !`${intent}`.toLowerCase().includes(debouncedSearchTerm.trim().toLowerCase())) {
          return false;
        }
        return true;
      }).slice(0, PAGE_SIZE)
    );
  }, [debouncedSearchTerm]);

  const getTableColumns = () => {
    return [{
      accessor: 'intent',
      title: t('Intent'),
      render: (intent: IntentItem) => (
        <Highlight highlight={getEntitiesInText(intent.intent)} color={theme.colors[theme.primaryColor][6]}>{intent.intent}</Highlight>
      )
    }];
  }

  return (
    <>
      {scopedState?.value?.length > 0 &&
        <Stack gap="xs">
          <TextInput
            placeholder={t("Search intents...") as string}
            leftSection={<IconSearch size={16} />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
          <DataTable
            minHeight={200}
            striped
            highlightOnHover
            columns={getTableColumns()}
            records={records}
            totalRecords={scopedState?.value?.length}
            recordsPerPage={PAGE_SIZE}
            page={page}
            onPageChange={(p) => setPage(p)}
            paginationText={({ from, to, totalRecords }) => `${from} - ${to} | ${t("Total")}: ${totalRecords}`}
          />
        </Stack>
      }
      {(!scopedState?.value || scopedState?.value?.length === 0) &&
        <Center>
          <Text c="dimmed" size="sm">{t('No intents')}</Text>
        </Center>
      }
    </>
  );
};

export default DocumentSnapshotPassageIntents;

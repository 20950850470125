import { State, none, useHookstate } from "@hookstate/core";
import { Group, Select, Stack, Switch, Text, Input, Alert } from "@mantine/core";
import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import { BotSearchMrc } from "src/stores/bots";
import AppContext from "src/services/app-context";
import { IconBook as Book, IconInfoCircle } from "@tabler/icons-react";
import SwitchButton from "src/components/switch-button";
import NumberInputWithControl from "src/components/number-input-with-control";
import classes from 'src/pages/index.module.css';

const BotContentSearchCard: FC<{
  state: State<BotSearchMrc>;
  cardkey: string;
  edit?: boolean;
  rightToolbar?: React.ReactNode;
}> = ({ state, cardkey, edit = false, rightToolbar }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  const onChangeEnableTruncatePassages = (value: boolean) => {
    if (edit) {
      scopedState.maxPassageLength.set(value ? 1024 : 0);
      setIsDirty(true);
    }
  }

  return (
    <CollapsibleCard
      title={
        <Group>
          <Text fw={500}>{t('Machine Reading Comprehension')}</Text>
        </Group>
      }
      cardKey={cardkey}
      rightToolbar={rightToolbar}
      icon={<Book />}>

      <Stack>
        <SwitchButton
          className={classes.switchButton}
          label={t("Enable Machine Reading Comprehension")}
          description={t("Machine Reading Comprehension (MRC) enables better understanding of the passages to answer questions related to it.") as string}
          checked={scopedState.enable.value}
          onChange={value => { scopedState.enable.set(value); setIsDirty(true); }}
          readOnly={!edit}>
          {scopedState.enable.value ?
            <Stack gap="xs">
              <Group align="flex-start" grow>
                <NumberInputWithControl
                  required
                  label={t("Confidence")}
                  description={t("Minimum value that must be reached to return a matched document.") as string}
                  precision={6}
                  min={0}
                  step={0.1}
                  max={1}
                  value={scopedState.confidence.value ?? 0.6}
                  onChange={(value) => { scopedState.confidence.set(value as number); setIsDirty(true); }}
                  readOnly={!edit}
                />
                <NumberInputWithControl
                  required
                  label={t("Similarity")}
                  description={t("The reader will pick similar passages between this threshold.") as string}
                  precision={6}
                  min={0}
                  step={0.01}
                  max={1}
                  value={scopedState.similarity.value ?? 0.02}
                  onChange={(value) => { scopedState.similarity.set(value as number); setIsDirty(true); }}
                  readOnly={!edit}
                />
              </Group>
              <Group align="flex-start" grow>
                <Select
                  allowDeselect={false}
                  readOnly={!edit}
                  variant={edit ? 'default' : 'unstyled'}
                  label={t("MRC algorithm")}
                  description={t("MRC algorithm to apply")}
                  defaultValue='naive'
                  data={[
                    { value: 'naive', label: t("Naive") as string },
                    { value: 'llm', label: t("Large Language Model (LLM) like ChatGPT (External service)") as string },
                  ]}
                  value={scopedState.algorithm.value ?? 'naive'}
                  onChange={(value) => { scopedState.algorithm.set(value as string); setIsDirty(true); }}
                />
              </Group>
              {edit && <Group align="flex-start" grow>
                {scopedState.algorithm.value === 'llm' && <Alert icon={<IconInfoCircle size="1rem" />}>{t("Remember to go to the LLM tab to configure the service")}</Alert>}
              </Group>}
              <Group align="flex-start" grow>
                <Switch
                  mt={5}
                  label={<Text fw={500}>{t("Truncate passage length")}</Text>}
                  description={t("Sets whether passages should be truncated.") as string}
                  checked={scopedState.maxPassageLength.value > 0}
                  onChange={(e) => onChangeEnableTruncatePassages(e.target.checked)}
                  readOnly={!edit}
                />
                {scopedState.maxPassageLength.value > 0 &&
                  <NumberInputWithControl
                    required
                    label={t("Maximum length")}
                    description={t("Maximum characters per passage, if it exceeds it will be truncated.") as string}
                    min={0}
                    step={512}
                    max={32000}
                    value={scopedState.maxPassageLength.value ?? 1024}
                    onChange={(value) => { scopedState.maxPassageLength.set(value as number); setIsDirty(true); }}
                    readOnly={!edit}
                  />
                }
              </Group>
            </Stack>
            :
            null}
        </SwitchButton>
      </Stack>
    </CollapsibleCard>
  );
};

export default BotContentSearchCard;
import { Alert, Button, Group, LoadingOverlay, Modal, SimpleGrid, Stack, Text, TextInput } from "@mantine/core";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { container } from "src/inversify.config";
import { formatMessage } from "src/core/utils/object";
import { ServiceConnectionItem, ServiceConnectionItemStore } from "src/stores/serviceconnections";
import AppContext from "src/services/app-context";
import { ServiceConnectionRef, getServiceTypeFields } from "./services/serviceconnection";
import { IconAlertCircle, IconDeviceFloppy } from "@tabler/icons-react";
import TestConnectionButton from "./test-connection-button";
import ServiceConnectionCategoryTypeSelector from "./serviceconnection-category-type-selector";
import { authStatus } from 'src/core/services/authentication-service';
import { UserCanContribute } from "src/utils/permissions";
import { MemberEditorState, MemberItem } from "src/stores/identities";
import MembersEditor from "src/components/members-editor";
import { useHookstate } from "@hookstate/core";

const EditServiceConnection: FC<{
  opened: boolean;
  id: string;
  onClose: () => void;
  onFinish?: () => void;
}> = ({ opened, id, onClose, onFinish }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(ServiceConnectionItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const { setIsDirty } = useContext(AppContext);
  const serviceConnectionRef = useRef<ServiceConnectionRef>(null);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const isOwner = authStatus?.user.value?.isOwner;
  const canContribute = isOwner || UserCanContribute(state?.item?.members?.value as MemberItem[]);

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);

  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id]);

  const load = async (id: string) => {
    await store.load(id);
  }

  useEffect(() => {
    if (state?.item?.value) {
      const copy = JSON.parse(JSON.stringify(state.item.value)) as MemberEditorState;
      membersState.set({
        accessMode: copy.accessMode,
        members: copy.members
      });
    }
  }, [state.item])

  const isValid = () => {
    return state.item.name.value.length > 0 && state.item.type.value.length > 0 && state.item.category.value.length > 0 && serviceConnectionRef.current?.isValid() as boolean;
  }

  const onSubmit = async () => {
    if (isValid()) {
      state.item.content.set(serviceConnectionRef.current?.getContent() as string);
      const copy = JSON.parse(JSON.stringify(membersState.value)) as MemberEditorState;
      state.item.accessMode.set(copy.accessMode);
      state.item.members.set(copy.members);
  
      await store.save(id, state.item.value as ServiceConnectionItem);

      if (!state.errorMessage.value) {
        setIsDirty(false);
        onFinish?.();
      }
    }
    else {
      setFormError(t("Review and complete all required fields") as string);
    }
  }

  const onCloseModal = () => {
    setIsDirty(false);
    setFormError(undefined);
    onClose();
  }

  return (
    <Modal
      title={<span>{t("Edit service connection")}</span>}
      size='70%'
      withCloseButton
      closeOnClickOutside={false}
      onClose={onCloseModal}
      opened={opened}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        {formError && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => setFormError(undefined)}>
            <Text>{formError}</Text>
          </Alert>
        )}
        {state?.item?.value &&
          <form>
            <Stack>
              <Group>
                <TextInput
                  required
                  data-autofocus
                  style={{ flex: 1 }}
                  label={t('Name')}
                  value={state.item.name.value}
                  onChange={(event) => state.item.name.set(event.target.value)}
                  disabled
                />

                <ServiceConnectionCategoryTypeSelector
                  category={state.item.category.value}
                  onChangeCategory={(value) => state.item.category.set(value)}
                  type={state.item.type.value}
                  onChangeType={(value) => state.item.type.set(value)}
                  disabled
                  categoryLabel={t("Category") as string}
                  typelabel={t("Type") as string}
                  categoryPlaceholder={t("Service category") as string}
                  typePlaceholder={t("Service type") as string}
                />
              </Group>

              <SimpleGrid cols={2}>
                <>
                  {getServiceTypeFields(state.item.id.value, state.item.type.value, serviceConnectionRef, state.item.content.value, canContribute)}
                </>
                <MembersEditor state={membersState} />
              </SimpleGrid>
            </Stack>

            <Group justify="flex-end" mt="xl">
              <TestConnectionButton
                serviceConnectionRef={serviceConnectionRef}
                category={state.item.category.value}
                type={state.item.type.value}
              />

              {canContribute &&
                <Button
                  loading={isBusy}
                  leftSection={<IconDeviceFloppy />}
                  onClick={onSubmit}>
                  {t("Save")}
                </Button>
              }
            </Group>
          </form>
        }
      </div>
    </Modal>
  );
};

export default EditServiceConnection;
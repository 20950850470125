import { useMemo, useEffect, useState, FC } from 'react';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { Group, MantineStyleProp, Text } from '@mantine/core';
import { IconFileSearch, IconMathFunction, IconMessages, IconWand as Wand } from '@tabler/icons-react';
import useBus from 'use-bus';
import { GenericSelector } from 'src/core/ui/generic-selector';
import { useClickOutside } from '@mantine/hooks';
import { SkillSummary, SkillSummaryStore } from 'src/stores/skills';

export const SkillSelector: FC<{
  value?: string;
  onChange?: (value: string) => void;
  width?: string | number;
  label?: string;
  clearable?: boolean;
  required?: boolean;
  creatable?: boolean;
  readOnly?: boolean;
  style?: MantineStyleProp;
  className?: string;
}> = ({ value, onChange, width, label, clearable, required, creatable, readOnly, style, className }) => {
  const store = useMemo(() => container.get(SkillSummaryStore), []);
  const state = store.state;
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(!value);
  const refFocusOutside = useClickOutside(() => {
    if (value) {
      setEditMode(false);
    }
  });

  const [query, setQuery] = useState<Query>({
    searchQuery: '',
    orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: 10,
    parameters: {
      includeIds: [value]
    }
  } as Query);

  useEffect(() => {
    if (value) {
      const newQuery = {
        ...query,
        skip: 0,
        parameters: {
          includeIds: [value]
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    load(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (value)
      setEditMode(false);
  }, [value])

  const load = async (query: Query) => {
    store.load(query);
  };

  useBus(
    '@@ui/SKILL_LIST_REFRESH',
    () => load(query),
    [],
  );

  const renderDataItem = (item: SkillSummary) => {
    return {
      label: item.title,
      value: item.id,
    } as any;
  }

  const getSkillIconType = (item: SkillSummary) => {
    switch (item.type) {
      case 'Function':
        return <IconMathFunction size={20} color="gray" />;
      case 'Dialog':
        return <IconMessages size={20} color="gray" />;
      case 'Search':
        return <IconFileSearch size={20} color="gray" />;
      default:
        return <Wand size={20} color="gray" />;
    }
  }

  const renderOption = (item: SkillSummary) => (
    <Group gap="xs">
      {getSkillIconType(item)}
      <Text size='sm'>{item.title}</Text>
    </Group>
  );

  return (
    <GenericSelector
      ref={refFocusOutside}
      label={label}
      required={required}
      data={store.toListState(state.value)}
      renderDataItem={(item) => renderDataItem(item)}
      query={query}
      onQueryChanged={setQuery}
      value={value}
      onChange={(value) => {
        if (onChange) {
          onChange(value);
          if (value) { setEditMode(false) }
        }
      }}
      width={width || 300}
      placeholder={t('Select a skill') as string}
      searchable
      searchField='title'
      clearable={clearable}
      renderOption={renderOption}
      creatable={creatable}
      withinPortal
      readOnly={readOnly}
      style={style}
      className={className}
    />
  );
};
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

export function useWindowSize() : { width: number, height: number } {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth as any,
        height: window.innerHeight as any,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize as any;
}

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function navigateTo(path: string) {
  localStorage.setItem('kickoff_url', path);
  let open_link = window.open('', '_blank');
  if (open_link)
    open_link.location = `${process.env.PUBLIC_URL}${path}`;
}
import { useHookstate } from "@hookstate/core";
import { ActionIcon, Alert, Box, Button, Group, Input, Modal, Popover, Select, SimpleGrid, Stack, Text, Textarea, TextInput } from "@mantine/core";
import { IconAlertCircle, IconPlus, IconAlertTriangle } from "@tabler/icons-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";
import { LanguageSeletor } from "src/components/language-selector";
import LocalizedTextArea from "src/components/localized-textarea";
import MembersEditor from "src/components/members-editor";
import TagsEditor from "src/components/tags-editor";
import { AppConfiguration } from "src/core/services/authentication-service";
import { formatMessage, normalizeName, validateName } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { MemberEditorState, MemberItem } from "src/stores/identities";
import { NewSkillForm, NewSkillItem, SkillItemStore, TagItem } from "src/stores/skills";
import { tagsToDict } from "src/utils/tags-utils";
import { dispatch } from "use-bus";

const CreateSkill: FC<{
  opened: boolean;
  onClose: () => void;
  onFinish?: (skillId: string) => void;
}> = ({ opened, onClose, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const store = useMemo(() => container.get(SkillItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const allowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const defaultDescription: { [key: string]: string } = {};
  defaultDescription[allowedLanguages[0]] = '';

  const initialState = {
    title: '',
    description: defaultDescription,
    tags: [],
    members: [],
    accessMode: "MembersOnly",
    type: "Dialog",
    languages: ['en']
  } as NewSkillForm;

  const formState = useHookstate(initialState);

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);


  const validateForm = () => {
    let errors = {} as { [key: string]: string };
    if (!formState.title.value && formState.title.value.length === 0) {
      errors.title = t('Invalid name');
    }
    if (!formState.type.value) {
      errors.type = t('Invalid type');
    }
    if (!formState.accessMode.value) {
      errors.accessMode = t('Invalid access mode');
    }
    if (!formState.languages.value || formState.languages.value.length === 0) {
      errors.languages = t('Invalid languages');
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSubmit = async () => {
    if (validateForm()) {
      const newItem = {
        title: formState.title.value,
        description: formState.description.value,
        accessMode: membersState.accessMode.value,
        type: formState.type.value,
        tags: tagsToDict(formState.tags.value as TagItem[]),
        members: membersState.members.value,
        languages: formState.languages.value
      } as NewSkillItem;

      let response = await store.create(newItem);
      if (response) {
        navigate(`/admin/skills/${response.id}/edit`);
        dispatch('@@ui/SKILL_LIST_REFRESH');
        formState.set(initialState);
        onClose();
      }
    }
  }

  const onCloseModal = () => {
    formState.set(initialState);
    store.clearError();
    onClose();
  }

  useEffect(() => {
    if (formState.accessMode.value === 'Everyone') {
      formState.members.set([]);
    }
  }, [formState.accessMode])

  return (
    <Modal
      opened={opened}
      onClose={onCloseModal}
      title={<Text>{t("Create a skill")}</Text>}
      size="70%"
      closeOnClickOutside={false}>
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}
      <Stack>
        <TextInput
          required
          data-autoFocus
          label={t('Title')}
          description={t("Provide a full name for your skill to help search and understand its purpose.")}
          value={formState.title.value}
          onChange={(event) => formState.title.set(event.target.value)}
          error={errors.title}
          rightSection={formState.title.value.length > 0 && !validateName(formState.title.value) ?
            <Popover position="bottom" width={300} withArrow shadow="md">
              <Popover.Target>
                <ActionIcon color="yellow" variant="transparent">
                  <IconAlertTriangle />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack gap={10}>
                  <Text size="sm">
                    {t("Use lowercase names, underscores or numbers, otherwise LLM-type bots using this skill might have problems.")}
                  </Text>
                  <Button onClick={() => formState.title.set((c) => normalizeName(c, true))}>{t("Normalize")}</Button>
                </Stack>
              </Popover.Dropdown>
            </Popover>
            :
            <></>}
        />

        <LocalizedTextArea
          label={t("Description") as string}
          edit
          value={formState.description.value}
          onChange={(value) => formState.description.set(value)} />

        <Group align='flex-start'>
          <Select
            required
            allowDeselect={false}
            label={t("Type")}
            placeholder={t("Skill type") as string}
            w={200}
            data={[
              { value: 'Dialog', label: t('Dialog') as string },
              { value: 'Search', label: t('Search') as string },
              { value: 'Function', label: t('Function') as string },
            ]}
            value={formState.type.value}
            onChange={(value) => formState.type.set(value as any)}
            error={errors.type}
          />
          <Box style={{ flex: 1 }}>
            <LanguageSeletor
              required
              multiselect
              width='100%'
              label={t('Languages') as string}
              value={formState.languages.value as string[]}
              onChange={(values) => formState.languages.set(values as string[])}
              error={errors.languages}
            />
          </Box>
        </Group>

        <SimpleGrid
          cols={{ base: 1, md: 2 }}
          spacing="lg">
          <TagsEditor state={formState.tags} />

          <MembersEditor state={membersState} creation />
        </SimpleGrid>

        <Group align="center" justify='right' wrap="nowrap">
          <Button
            onClick={onSubmit}
            loading={isBusy}
            leftSection={<IconPlus />}
            type="submit">
            {t("Create")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default CreateSkill;
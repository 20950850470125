import { none, useHookstate } from '@hookstate/core';
import { ActionIcon, Alert, Badge, Button, Group, Input, LoadingOverlay, SimpleGrid, Stack, Text, TextInput, Textarea, Tooltip, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleCard from 'src/components/collapsible-card';
import { formatMessage } from 'src/core/utils/object';
import { container } from 'src/inversify.config';
import AppContext from 'src/services/app-context';
import { ConfigStore, GlobalConfigOptions } from 'src/stores/options';
import { useModals } from '@mantine/modals';
import { ThemeSelector } from 'src/components/theme-selector';
import { globalPallettes } from 'src';
import SwitchButton from 'src/components/switch-button';
import { IconSettings, IconEdit, IconAlertTriangle, IconFileSettings, IconTrash, IconArrowBackUp, IconDeviceFloppy } from '@tabler/icons-react';
import { LanguageSeletor } from 'src/components/language-selector';
import { AppConfiguration, PersonalCollectionLevel, SharingAccessMode } from 'src/core/services/authentication-service';
import classes from 'src/pages/index.module.css';
import RadioGroupButton from 'src/components/radio-group-button';
import { UploadImageBase64 } from 'src/components/upload-image-base64';

let initialColor = "default";

const GlobalConfig: FC<{}> = () => {
  const { t } = useTranslation();
  const modals = useModals();
  const theme = useMantineTheme();
  const store = useMemo(() => container.get(ConfigStore), []);
  const state = store.state;
  const { setIsDirty } = useContext(AppContext);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const defaultSharingAccessMode = container.get<AppConfiguration>("AppConfiguration").sharingAccessMode;
  const initialState = {
    theme: "default",
    allowPersonalCollection: false,
    brandName: "Ainhoa platform",
    allowedLanguages: defaultAllowedLanguages
  } as GlobalConfigOptions;
  const scopedState = useHookstate(initialState);
  const showWarningInfo = !state.isBusy.value && ((!state?.item?.value && !initialized) || (state?.item?.value && state.errorMessage.value));

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = async () => {
    await store.load('global');
    if (state?.item?.value && !state.errorMessage.value) {
      setInitialized(true);
      const item = JSON.parse(JSON.stringify(state.item.value)) as GlobalConfigOptions;
      if (!item.allowedLanguages) {
        item.allowedLanguages = defaultAllowedLanguages;
      }
      if (!item.sharingAccessMode) {
        item.sharingAccessMode = defaultSharingAccessMode;
      }
      scopedState.set(item);
      initialColor = item.theme;
    }
    else {
      setInitialized(false);
      scopedState.set(initialState);
    }
  };

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const onSubmit = async () => {
    if (validate()) {
      await store.save('global', scopedState.value);
      state.errorMessage.value ? showKoNotification() : showOkNotification();
      setIsDirty(false);
      setEdit(false);
      await load();
      window.location.reload();
    }
  }

  const onCancelChanges = async () => {
    setIsDirty(false);
    setEdit(false);
    theme.colors.brand = globalPallettes[initialColor];
    await load();
  }

  const onCreateGlobalOptions = () => {
    setInitialized(true);
    setEdit(true);
    store.clearError();
    store.clear();
  }

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Delete global options')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete global options'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(),
    });

  const onConfirmDelete = async () => {
    await store.delete('global');
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      await load();
      setIsDirty(false);
      setEdit(false);
    }
  };

  const onChangeThemeColor = (value: string) => {
    scopedState.theme.set(value);
    theme.colors.brand = globalPallettes[value];
  }

  const validate = () => {
    return scopedState.allowedLanguages.value.length >= 1;
  }

  return (
    <CollapsibleCard
      title={<Text fw={500}>{t('Global options')}</Text>}
      cardKey={'global_options'}
      icon={<IconSettings />}
      rightToolbar={!edit &&
        <Tooltip label={t("Edit")}>
          <ActionIcon size="sm" onClick={() => setEdit(true)} variant='subtle' color="gray">
            <IconEdit />
          </ActionIcon>
        </Tooltip>
      }
      collapseInfoRender={
        initialized ?
          <Badge variant='light' color='gray'>{t("Configured")}</Badge>
          :
          <Badge variant='light' color='gray' leftSection={<IconAlertTriangle size={10} />}>{t("Not configured")}</Badge>
      }>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={state.isBusy.value} />
        {showWarningInfo && scopedState.value &&
          <Alert icon={<IconAlertTriangle />} title={t("Global options not found")} color="yellow">
            <Stack>
              <Text>{t("No global options has been configured. Click on the button below to create it.")}</Text>
              <Group align='center' justify='flex-start'>
                <Button variant="outline" color="yellow" mb="xs"
                  leftSection={<IconFileSettings />}
                  onClick={onCreateGlobalOptions}>
                  {t("Configure global options")}
                </Button>
              </Group>
            </Stack>
          </Alert>
        }
        {initialized && scopedState.value &&
          <Stack>
            <SimpleGrid cols={2}>
              <Stack>
                <Group grow align='flex-start'>
                  <TextInput
                    label={t("Brand name")}
                    value={scopedState.brandName.value}
                    onChange={(e) => scopedState.brandName.set(e.currentTarget.value)}
                    required
                    width='100%'
                    readOnly={!edit}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <ThemeSelector
                    label={t("Theme color") as string}
                    value={scopedState.theme.value}
                    onChange={(value) => onChangeThemeColor(value)}
                    required
                    width='100%'
                    readOnly={!edit}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <LanguageSeletor
                    label={t("Allowed languages") as string}
                    value={scopedState.allowedLanguages.value as string[]}
                    onChange={(value) => scopedState.allowedLanguages.set(value as string[])}
                    multiselect
                    showAll
                    required
                    width='100%'
                    readOnly={!edit}
                    error={scopedState.allowedLanguages.value.length < 1 ? t("Pick at least one item") : undefined}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <TextInput
                    label={t("Authorized IP ranges")}
                    placeholder={"0.0.0.0/0"}
                    value={scopedState.allowIpRanges.value}
                    onChange={(e) => scopedState.allowIpRanges.set(e.currentTarget.value)}
                    width='100%'
                    readOnly={!edit}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <Textarea
                    label={t("CORS origins")}
                    placeholder={t("One URL per line") as string}
                    value={scopedState.cors?.origins?.value}
                    onChange={(e) => {
                      if (!scopedState.cors?.value) {
                        scopedState.cors.set({
                          origins: e.currentTarget.value
                        })
                      }
                      else {
                        scopedState.cors.origins.set(e.currentTarget.value)
                      }
                    }}
                    rows={3}
                    readOnly={!edit}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <Input.Wrapper label={t("Favicon")}>
                    <UploadImageBase64
                      edit={edit}
                      value={scopedState.favicon.value}
                      onChange={(value) => scopedState.favicon.set(value)}
                      onDelete={() => scopedState.favicon.set(none)}
                      maxWidth={200}
                    />
                  </Input.Wrapper>

                  <Input.Wrapper label={t("Start menu background")}>
                    <UploadImageBase64
                      edit={edit}
                      value={scopedState.startBackground.value}
                      onChange={(value) => scopedState.startBackground.set(value)}
                      onDelete={() => scopedState.startBackground.set(none)}
                      maxWidth="100%"
                    />
                  </Input.Wrapper>
                </Group>

              </Stack>
              <Stack>
                <Group grow align='flex-start'>
                  <SwitchButton
                    className={classes.switchButton}
                    label={t("Allow personal collection")}
                    checked={scopedState.allowPersonalCollection.value ?? false}
                    onChange={value => scopedState.allowPersonalCollection.set(value)}
                    description={t("Enables the option for users to create personal document collections. Only one collection will be created per user.") as string}
                    readOnly={!edit}>
                    {scopedState?.allowPersonalCollection?.value === true &&
                      <RadioGroupButton
                        data={[
                          {
                            value: "AllUsers" as PersonalCollectionLevel,
                            label: t("All users"),
                            description: t("Allows all users in the organization to use the personal collection.") as string
                          },
                          {
                            value: "SelectedUsersOnly" as PersonalCollectionLevel,
                            label: t("Selected users only"),
                            description: t("Only allows specific users to use the personal collection. This option must be enabled for each user in the Identities section.") as string
                          },
                        ]}
                        checked={scopedState.personalCollectionLevel.value ?? "AllUsers"}
                        onChange={(value) => scopedState.personalCollectionLevel.set(value as PersonalCollectionLevel)}
                        readOnly={!edit}
                      />
                    }
                  </SwitchButton>
                </Group>

                <RadioGroupButton
                  label={t("Sharing access mode") as string}
                  data={[
                    {
                      value: "OrganizationWide" as SharingAccessMode,
                      label: t("Organization-wide and members-only"),
                      description: t("User can share elements with everyone in the organization or with specific members only.") as string
                    },
                    {
                      value: "MembersOnly" as SharingAccessMode,
                      label: t("Members-only"),
                      description: t("User can share elements only with specific members. No organization-wide sharing is allowed.") as string
                    },
                    {
                      value: "OwnerOnly" as SharingAccessMode,
                      label: t("Owner-only"),
                      description: t("User cannot share anything with anyone.") as string
                    }
                  ]}
                  checked={scopedState.sharingAccessMode.value}
                  onChange={(value) => scopedState.sharingAccessMode.set(value as SharingAccessMode)}
                  readOnly={!edit}
                />

                <Group grow align='flex-start'>
                  <SwitchButton
                    className={classes.switchButton}
                    label={t("Enable word-based full-text index (Hybrid search)")}
                    checked={scopedState.fullTextIndex.value ?? false}
                    onChange={value => scopedState.fullTextIndex.set(value)}
                    description={t("It allows the creation of a full-text index complementary to the semantic index (empowers hybrid searches). When disabled, only semantic searches will be available.") as string}
                    readOnly={!edit}
                  />
                </Group>

                <Group grow align='flex-start'>
                  <SwitchButton
                    className={classes.switchButton}
                    label={t("Enable user automatic enrollment")}
                    checked={scopedState.userAutoEnroll.value ?? false}
                    onChange={value => scopedState.userAutoEnroll.set(value)}
                    description={t("Authenticated users will be automatically added to the identities list when they log in for the first time.") as string}
                    readOnly={!edit}>
                    {scopedState?.userAutoEnroll?.value === true &&
                      <Stack>
                        <Group align="center">
                          <TextInput
                            label={t("Allow users that match this regex expression")}
                            style={{ flex: 1 }}
                            value={scopedState.usernameRegex?.value ?? ""}
                            readOnly={!edit}
                            onChange={(e) => { scopedState.usernameRegex.set(e.target.value); setIsDirty(true); }}
                          />
                        </Group>
                      </Stack>
                    }
                  </SwitchButton>
                </Group>
              </Stack>
            </SimpleGrid>

            {edit &&
              <Group justify='flex-end'>
                <Button
                  disabled={!state?.item?.value}
                  loading={state.isBusy.value}
                  onClick={openDeleteModal}
                  color="red"
                  leftSection={<IconTrash />}>
                  {t("Delete")}
                </Button>
                <Button
                  loading={state.isBusy.value}
                  variant='outline'
                  onClick={onCancelChanges}
                  leftSection={<IconArrowBackUp />}>
                  {t("Cancel")}
                </Button>
                <Button
                  loading={state.isBusy.value}
                  disabled={!validate()}
                  onClick={onSubmit}
                  leftSection={<IconDeviceFloppy />}>
                  {t("Save")}
                </Button>
              </Group>
            }
          </Stack>}
      </div>
    </CollapsibleCard>
  );
};

export default GlobalConfig;
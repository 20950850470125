import { ComboboxItem, Select } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceConnectionCategory, ServiceConnectionType } from "src/stores/serviceconnections";
import { getServiceConnectionData } from "./services/serviceconnection";
import { AsEnumerable } from "linq-es5";

const ServiceConnectionCategoryTypeSelector: FC<{
  category?: ServiceConnectionCategory,
  type?: ServiceConnectionType,
  onChangeCategory?: (category: ServiceConnectionCategory) => void,
  onChangeType?: (type: ServiceConnectionType) => void,
  disabled?: boolean,
  categoryLabel?: string;
  typelabel?: string;
  categoryPlaceholder?: string;
  typePlaceholder?: string;
  clearable?: boolean;
  onlyCategory?: boolean;
}> = ({ category, type, onChangeCategory, onChangeType, disabled = false, categoryLabel, typelabel, categoryPlaceholder, typePlaceholder, clearable = false, onlyCategory = false }) => {
  const { t } = useTranslation();
  const [typeOptions, setTypeOptions] = useState<ComboboxItem[]>([]);
  const serviceConnectionData = getServiceConnectionData(t);

  useEffect(() => {
    let newType = type;
    if (category) {
      const data = AsEnumerable(serviceConnectionData).Where(x => x.category === category).ToArray().map((filtered) => ({ value: filtered.type, label: t(filtered.type) } as ComboboxItem));
      newType = data[0].value as ServiceConnectionType;

      setTypeOptions(data);
      if (!disabled && newType) {
        onChangeType?.(newType);
      }
    }
  }, [category])

  return (
    <>
      <Select
        w={250}
        allowDeselect={false}
        required
        label={categoryLabel}
        placeholder={categoryPlaceholder}
        data={AsEnumerable(serviceConnectionData).GroupBy(x => x.category).ToArray().map((grouped) => ({ value: grouped.key, label: t(grouped.key) } as ComboboxItem))}
        value={category}
        onChange={(value) => onChangeCategory?.(value as ServiceConnectionCategory)}
        disabled={disabled}
        clearable={clearable}
      />
      {!onlyCategory &&
        <Select
          w={260}
          required
          allowDeselect={false}
          label={typelabel}
          placeholder={typePlaceholder}
          data={typeOptions}
          value={type}
          onChange={(value) => onChangeType?.(value as ServiceConnectionType)}
          disabled={disabled}
          clearable={clearable}
        />
      }
    </>
  );
};

export default ServiceConnectionCategoryTypeSelector;
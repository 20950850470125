import { ReactNode, useEffect, useState } from 'react';
import { PillsInput, Pill, Combobox, CheckIcon, Group, useCombobox, ComboboxItem, InputWrapper, MantineStyleProp, Box, Loader, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ListState, Query } from 'src/core/stores/data-store';
import React from 'react';
import { AsEnumerable } from 'linq-es5';
import { useDebouncedValue } from '@mantine/hooks';

export interface GenericMultiselectProps<T> {
  data: ListState<T>;
  renderDataItem: (item: T) => any; //TODO: typar para que por lo menos llegue siempre label y value
  value: string[] | undefined;
  onChange?: (value: string[]) => void;
  query: Query;
  onQueryChanged?: (query: Query) => void;
  label?: string;
  required?: boolean;
  description?: string;
  variant?: 'filled' | 'unstyled' | undefined;
  searchable?: boolean;
  searchField?: string;
  readOnly?: boolean;
  creatable?: boolean;
  placeholder?: string;
  renderOption?: (item: ComboboxItem) => any;
  withinPortal?: boolean;
  style?: MantineStyleProp;
  width?: string | number;
  error?: ReactNode;
  maxHeight?: number;
}

export const GenericMultiselect = React.forwardRef<HTMLInputElement, GenericMultiselectProps<any>>(({
  data, renderDataItem, value, onChange, query, onQueryChanged, label, required, description, variant, searchable, searchField,
  readOnly, creatable, placeholder, renderOption, withinPortal, style, width, error, maxHeight = 200 }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');
  const [internalData, setInternalData] = useState([] as { value: string, label: string }[]);
  const [internalValue, setInternalValue] = useState<string[]>(value ?? []);
  const [internalItems, setInternalItems] = useState<string[]>([]);
  const [searchDebounced] = useDebouncedValue(search, 300);

  useEffect(() => {
    if (value) {
      setInternalValue(value);

      let newItems = [] as string[];
      value.forEach(v => {
        if (!internalItems.includes(v) && internalData.filter(x => (x as any).value === value).length === 0) {
          newItems.push(v);
        }
      });
      setInternalItems([...internalItems, ...newItems]);
    }
  }, [value]);

  useEffect(() => {
    onSearchChange(searchDebounced);
  },[searchDebounced])

  useEffect(() => {
    setInternalData(getData() as any);
  }, [JSON.stringify(data), internalItems]);

  const getData = () => {
    let items = (data?.items?.length > 0) ? data.items.map((item) => renderDataItem(item)) : [];
    if (internalData && internalData.length > 0) {
      items = [...items, ...internalData];
      items = AsEnumerable(items).Distinct(x => x.value).ToArray();
    }
    return items;
  };

  const exactOptionMatch = internalData.some((item) => item.label === search);

  const handleValueSelect = (val: string) => {
    setSearch('');
    const currentValues = [...internalValue];

    if (val === '$create') {
      const newValues = [...currentValues, search];
      setInternalData((current) => [...current, { value: search, label: search }]);
      setInternalValue(newValues);
      onChange?.(newValues);
    } else {
      const newValues = currentValues.includes(val) ? currentValues.filter((v) => v !== val) : [...currentValues, val];
      setInternalValue(newValues);
      onChange?.(newValues);
    }
  };

  const onSearchChange = (searchKeyword: string) => {
    if (searchable){
      if (searchField) {
        const nextP = { ...query?.parameters, $filter: `contains(${searchField},'${searchKeyword}')` };
        const nextQ = { ...query, parameters: nextP };
        if (onQueryChanged) onQueryChanged(nextQ as Query);
      } else {
        const nextQ = { ...query, searchQuery: searchKeyword };
        if (onQueryChanged) onQueryChanged(nextQ as Query);
      }
      setSearch(searchKeyword);
    }
    else {
      setSearch(searchKeyword);
    }
  }

  const handleValueRemove = (val: string) => {
    const currentValues = [...internalValue];
    const newValues = currentValues.filter((v) => v !== val);
    setInternalValue(newValues);
    onChange?.(newValues);
  }

  const options = data?.items?.map((i) => {
    const item = renderDataItem(i);
    return (<Combobox.Option value={item.value} key={item.value} active={internalValue.includes(item.value)}>
      <Group gap="sm">
        {internalValue.includes(item.value) ? <CheckIcon size={12} /> : null}
        {renderOption ? renderOption(item) : <span>{item.label}</span>}
      </Group>
    </Combobox.Option>);
  });

  const values = internalValue.map((value) => {
    const item = internalData.find((i) => i.value === value);
    if (item) {
      return (<Pill key={value} withRemoveButton={!readOnly} onRemove={() => handleValueRemove(value)} styles={{ label: renderOption ? { lineHeight: 1 } : {} }}>
        {renderOption ? renderOption(item) : <span>{item.label}</span>}
      </Pill>);
    }
  });

  return (
    <Box style={{ ...style }}>
      <InputWrapper
        label={label}
        style={{ width: width }}
        required={required && !readOnly}
        error={error}
        description={description}>
        <Combobox
          store={combobox}
          onOptionSubmit={handleValueSelect}
          withinPortal={withinPortal}
          readOnly={readOnly}>
          <Combobox.DropdownTarget>
            <PillsInput
              onClick={() => combobox.openDropdown()} variant={variant ? variant : !readOnly ? 'default' : 'unstyled'}
              rightSection={data && data.isBusy ? <Loader size={20} color={theme.colors.gray[5]} /> : undefined}>
              <Pill.Group>
                {values}

                {!readOnly &&
                  <Combobox.EventsTarget>
                    <PillsInput.Field
                      onFocus={() => combobox.openDropdown()}
                      onBlur={() => combobox.closeDropdown()}
                      value={search}
                      placeholder={values?.length === 0 ? placeholder : undefined}
                      onChange={(event) => {
                        combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Backspace' && search.length === 0) {
                          event.preventDefault();
                          handleValueRemove(internalValue[internalValue.length - 1]);
                        }
                      }}
                    />
                  </Combobox.EventsTarget>
                }
              </Pill.Group>
            </PillsInput>
          </Combobox.DropdownTarget>

          {!readOnly &&
            <Combobox.Dropdown>
              <Combobox.Options mah={maxHeight} style={{ overflowY: 'auto' }}>
                {options}

                {!exactOptionMatch && search.trim().length > 0 && (
                  <>
                    {creatable ?
                      <Combobox.Option value="$create">+ {t("Add")} {search}</Combobox.Option>
                      :
                      <Combobox.Empty>{t("No items found")}</Combobox.Empty>
                    }
                  </>
                )}

                {exactOptionMatch && search.trim().length > 0 && options?.length === 0 && (
                  <Combobox.Empty>{t('No items found')}</Combobox.Empty>
                )}

                {options?.length === 0 && creatable && !search &&
                  <Combobox.Empty>{t('Type to create new element')}</Combobox.Empty>
                }
                {options?.length === 0 && !creatable && !search &&
                  <Combobox.Empty>{t('No items found')}</Combobox.Empty>
                }
              </Combobox.Options>
            </Combobox.Dropdown>
          }
        </Combobox>
      </InputWrapper>
    </Box>
  );
});
import { State, useHookstate } from "@hookstate/core";
import { Group, Input, ActionIcon, Textarea, Stack, Tooltip, Text, Code } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";

const SkillContentStepCondition: FC<{
  edit?: boolean;
  state: State<string>;
}> = ({ edit, state }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const [hasCondition, setHasCondition] = useState<boolean>(scopedState?.value?.toString().length > 0);
  const { setIsDirty } = useContext(AppContext);

  useEffect(() => {
    setHasCondition(scopedState?.value?.toString().length > 0);
  }, [scopedState?.value])

  const onChangeCondition = () => {
    if (hasCondition) {
      scopedState.set(''); // si se pone set(none) parece que renderiza todos los pasos
    }
    else {
      scopedState.set('<your condition>');
    }
    setHasCondition(v => !v);
    setIsDirty(true);
  }

  return (
    <>
      {edit ?
        <Stack gap="xs">
          <Group align="center">
            <Input.Label w={120} required={edit}>{t("Condition")}:</Input.Label>
            <Group align="center" gap={5}>
              <Text size="sm">{!hasCondition ? t("Always") : t("With condition")}</Text>
              <Tooltip withinPortal label={!hasCondition ? t("Add condition") : t("Remove condition")}>
                <ActionIcon onClick={onChangeCondition} variant='subtle' color="gray">
                  {!hasCondition ? <IconPlus size={16} /> : <IconTrash size={16} />}
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
          {hasCondition &&
            <Group align="flex-start">
              <Input.Label w={120}></Input.Label>
              <Textarea
                autosize
                minRows={3}
                maxRows={10}
                required
                style={{ flex: 1 }}
                value={scopedState.value?.toString()}
                onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
              />
            </Group>
          }
        </Stack>
        :
        <>
          {scopedState?.value?.toString().length > 0 ?
            <div>
              <Text fw={500} span>{t("When")} </Text>
              <Code style={{ fontSize: 16 }} color="var(--mantine-color-teal-light)" >{scopedState.value.toString()}</Code>
            </div>
            :
            <Text fw={500}>{t("Always")}</Text>
          }
        </>
      }
    </>
  );
};

export default SkillContentStepCondition;
import { Group, PasswordInput, Select, Stack, Table, TextInput, Textarea } from '@mantine/core';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceConnectionRef } from './serviceconnection';
import { useHookstate } from '@hookstate/core';
import { OAuthServiceConnectionContent } from 'src/stores/serviceconnections';
import AppContext from 'src/services/app-context';
import { AppConfiguration } from 'src/core/services/authentication-service';
import { container } from 'src/inversify.config';
import LocalizedTextArea from 'src/components/localized-textarea';
import LocalizedTexInput from 'src/components/localized-textinput';

export const OAuthServiceConnection = React.forwardRef<ServiceConnectionRef, { serviceId: string, content?: string, canContribute?: boolean }>(({ serviceId, content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as OAuthServiceConnectionContent);
  const { setIsDirty } = useContext(AppContext);
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    if (content) {
      scopedState.set(JSON.parse(content));
    }
    else {
      scopedState.set({
        authority: 'https://login.microsoftonline.com/common',
        provider: 'Custom',
        clientId: '',
        authorizationEndpoint: '/oauth2/v2.0/authorize',
        tokenEndpoint: '/oauth2/v2.0/token',
        logoutEndpoint: '/oauth2/logout',
        scopes: 'offline_access',
        title: {},
        description: {},
      } as OAuthServiceConnectionContent);
    }
  }, [content])

  useEffect(() => {
    switch (scopedState.provider?.value) {
      case 'Microsoft':
        {
          scopedState.authorizationEndpoint.set('/oauth2/v2.0/authorize');
          scopedState.tokenEndpoint.set('/oauth2/v2.0/token');
          scopedState.logoutEndpoint.set('/oauth2/logout');
          break;
        }
    }
  }, [scopedState.provider?.value])

  const isValid = () => {
    return scopedState.authority.value.length > 0 && scopedState.clientId.value.length > 0 && scopedState.authorizationEndpoint.value.length > 0 &&
      scopedState.tokenEndpoint.value.length > 0 && scopedState.logoutEndpoint.value.length > 0;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("Title")}</Table.Td>
          <Table.Td>{scopedState.title.value[0]}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Authority")}</Table.Td>
          <Table.Td>{scopedState.authority.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Scopes")}</Table.Td>
          <Table.Td>{scopedState.scopes.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Client Id")}</Table.Td>
          <Table.Td>{scopedState.clientId.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Authorization Endpoint")}</Table.Td>
          <Table.Td>{scopedState.authorizationEndpoint.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Token Endpoint")}</Table.Td>
          <Table.Td>{scopedState.tokenEndpoint.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Logout Endpoint")}</Table.Td>
          <Table.Td>{scopedState.logoutEndpoint.value}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Callback Uri")}</Table.Td>
          <Table.Td>{`${baseUri}/api/oauth/${serviceId}/callback`}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Stack gap={10} justify="flex-start">
      <LocalizedTexInput 
      label={t("Title") as string}
      edit={canContribute}
      value={scopedState.title.value}
      onChange={(value) => { scopedState.title.set(value); setIsDirty(true); }}
      />

      <LocalizedTextArea
        label={t("Description") as string}
        edit={canContribute}
        value={scopedState.description.value}
        onChange={(value) => { scopedState.description.set(value); setIsDirty(true); }}
      />

      <Group grow justify='space-between' align='flex-start'>
        <Select
          label={t("Provider")}
          data={[
            { value: 'Microsoft', label: t('Microsoft') },
            { value: 'Custom', label: t('Custom') },
          ]}
          value={scopedState.provider.value ?? 'Microsoft'}
          onChange={value => scopedState.provider.set(value as any)}
        />
        <Select
          label={t("OAuth flow")}
          data={[
            { value: 'Interactive', label: t('Interactive') },
            { value: 'ClientCredentials', label: t('Client credentials') },
          ]}
          value={scopedState.flow.value ?? 'Interactive'}
          onChange={value => scopedState.flow.set(value as any)}
        />
      </Group>

      <Group grow justify='space-between' align='flex-start'>
        <TextInput
          data-autofocus
          style={{ flex: 1 }}
          label={t('Authority')}
          required
          value={scopedState.authority.value}
          onChange={(event) => { scopedState.authority.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />

        <TextInput
          data-autofocus
          style={{ flex: 1 }}
          label={t('Scopes')}
          value={scopedState.scopes.value}
          onChange={(event) => { scopedState.scopes.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />
      </Group>

      <Group grow justify='space-between' align='flex-start'>
        <TextInput
          style={{ flex: 1 }}
          label={t('Client Id')}
          required
          value={scopedState.clientId.value}
          onChange={(event) => { scopedState.clientId.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />

        <PasswordInput
          style={{ flex: 1 }}
          label={t('Client secret')}
          value={scopedState.clientSecret.value}
          onChange={(event) => { scopedState.clientSecret.set(event.target.value); setIsDirty(true); }}
          readOnly={!canContribute}
        />
      </Group>

      <Stack>
        <TextInput
          data-autofocus
          style={{ flex: 1 }}
          label={t('Callback Uri')}
          readOnly
          value={`${baseUri}/api/oauth/${serviceId}/callback`}
        />

        <Textarea
          style={{ flex: 1 }}
          label={t('Certificate (PEM)')}
          value={scopedState.certificate.value}
          rows={6}
          placeholder='-----BEGIN CERTIFICATE----- ... -----END CERTIFICATE-----'
          onChange={(event) => { scopedState.certificate.set(event.target.value); }}
        />

        <Textarea
          style={{ flex: 1 }}
          label={t('Private key (PEM)')}
          value={scopedState.privateKey.value}
          rows={6}
          placeholder='-----BEGIN RSA PRIVATE KEY----- ... -----END RSA PRIVATE KEY-----'
          onChange={(event) => { scopedState.privateKey.set(event.target.value); }}
        />
      </Stack>
      {(scopedState.provider.value === 'Custom') && <>
        <Group grow justify='space-between' align='flex-start'>
          <TextInput
            data-autofocus
            style={{ flex: 1 }}
            label={t('Authorization Endpoint')}
            required
            value={scopedState.authorizationEndpoint.value}
            onChange={(event) => { scopedState.authorizationEndpoint.set(event.target.value); setIsDirty(true); }}
            readOnly={!canContribute}
          />

          <TextInput
            data-autofocus
            style={{ flex: 1 }}
            label={t('Token Endpoint')}
            required
            value={scopedState.tokenEndpoint.value}
            onChange={(event) => { scopedState.tokenEndpoint.set(event.target.value); setIsDirty(true); }}
            readOnly={!canContribute}
          />
        </Group>

        <Group grow justify='space-between' align='flex-start'>
          <TextInput
            data-autofocus
            style={{ flex: 1 }}
            label={t('Logout Endpoint')}
            required
            value={scopedState.logoutEndpoint.value}
            onChange={(event) => { scopedState.logoutEndpoint.set(event.target.value); setIsDirty(true); }}
            readOnly={!canContribute}
          />
        </Group>
      </>}
    </Stack>
  );
});

export default OAuthServiceConnection;

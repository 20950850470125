import { Badge } from '@mantine/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CrawlerStatus } from 'src/stores/crawlers';

const CrawlerStatusBadge: FC<{
  status?: CrawlerStatus
}> = ({ status }) => {
  const { t } = useTranslation();

  const getColor = () => {
    switch (status) {
      case 'Running':
        return 'green';
      case 'Idle':
        return 'gray';
      default:
        break;
    }
  }

  return (
    <Badge color={getColor()} variant="dot">{t(status ?? "No status info")}</Badge>
  );
};

export default CrawlerStatusBadge;

import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrawlerItemStore, CrawlerType } from "src/stores/crawlers";
import { container } from "src/inversify.config";
import { ActionIcon, Alert, Anchor, Button, Group, LoadingOverlay, Modal, Popover, Stack, Switch, Tabs, Text, TextInput } from "@mantine/core";
import { formatMessage } from "src/core/utils/object";
import CrawlerLog from "./crawler-log";
import CrawlerPatches from "./crawler-patches";
import AppContext from "src/services/app-context";
import { IconInfoCircle, IconAlertCircle, IconInfoSquare, IconReplace, IconActivity, IconDeviceFloppy } from "@tabler/icons-react";
import classes from 'src/pages/index.module.css';
import CrawlerSelector from "./crawlers/crawler-selector";
import { CrawlerRef, getCrawlerTypeFields } from "./crawlers/crawlers";
import TestCrawlerConnectionButton from "./test-crawler-connection-button";

const EditCrawler: FC<{
  opened: boolean;
  onClose: () => void;
  collectionId: string;
  crawlerId: string;
  edit?: boolean;
  canContribute: boolean;
}> = ({ opened, onClose, collectionId, crawlerId, edit = false, canContribute }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('info');
  const store = useMemo(() => container.get(CrawlerItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const { setIsDirty } = useContext(AppContext);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [hasSchedule, setHasSchedule] = useState(true);
  const crawlerRef = useRef<CrawlerRef>(null);

  useEffect(() => {
    if (collectionId && crawlerId) {
      store.setCollection(collectionId);
      load(crawlerId);
    }
  }, [collectionId, crawlerId]);

  useEffect(() => {
    if (state?.item?.value && hasSchedule === false) {
      state.item.schedule.set('');
    }
  }, [hasSchedule]);

  const load = async (crawlerId: string) => {
    if (crawlerId) {
      await store.load(crawlerId);
      loadForm();
    }
  };

  const loadForm = () => {
    if (state?.item?.value) {
      setHasSchedule(state.item.schedule.value.length > 0);
    }
  }

  const formIsValid = () => {
    const basicFormValid = state.item.type.value.length > 0 && state.item.title.value.length > 0;
    const fiedlsValid = crawlerRef.current?.isValid() as boolean;

    return basicFormValid && fiedlsValid;
  }

  const onSubmit = async () => {
    if (formIsValid()) {

      state.item.content.set(crawlerRef.current?.getContent() as string);

      store.setCollection(collectionId);
      setIsDirty(false);
      const response = await store.save(crawlerId, state.item.value);

      if (response) {
        onCloseModal();
      }
    }
    else {
      setFormError(t("Review and complete all required fields") as string);
    }
  }

  const onCloseModal = () => {
    store.clearError();
    onClose();
    setIsDirty(false);
    setFormError(undefined);
  }

  const scheduleInfo = <Popover withArrow shadow="md" withinPortal>
    <Popover.Target>
      <ActionIcon variant='subtle' color="gray">
        <IconInfoCircle size={16} />
      </ActionIcon>
    </Popover.Target>
    <Popover.Dropdown>
      <Text>{t("View more info at")}: <Anchor target="_blank" href="https://en.wikipedia.org/wiki/Cron#CRON_expression">{t("Wiki Cron expression")}</Anchor></Text>
    </Popover.Dropdown>
  </Popover>;

  return (
    <Modal
      opened={opened}
      onClose={onCloseModal}
      title={<Text>{t("Crawler detail")} {edit && t("(Edit mode)")}</Text>}
      size="90%"
      closeOnClickOutside={false}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        {formError && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => setFormError(undefined)}>
            <Text>{formError}</Text>
          </Alert>
        )}
        {state?.item?.value && state?.item?.content?.value &&
          <Stack>
            <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
              <Tabs.List>
                <Tabs.Tab value="info" leftSection={<IconInfoSquare size={20} />}>{t('Info')}</Tabs.Tab>
                <Tabs.Tab value="patches" leftSection={<IconReplace size={20} />}>{t('Patches')}</Tabs.Tab>
                <Tabs.Tab value="log" leftSection={<IconActivity size={20} />}>{t('Log')}</Tabs.Tab>
              </Tabs.List>
            </Tabs>
            {activeTab === "info" && (
              <>
                <Group gap="md" justify="center">
                  <CrawlerSelector
                    label={t("Type") as string}
                    value={state.item.type.value as CrawlerType}
                    onChange={(value) => { state.item.type.set(value as CrawlerType); setIsDirty(true); }}
                    required
                    disabled
                  />

                  <TextInput
                    style={{ flex: 1 }}
                    required
                    readOnly={!edit}
                    variant={edit ? 'default' : 'unstyled'}
                    label={t('Title')}
                    value={state.item.title.value}
                    onChange={(event) => { state.item.title.set(event.target.value); setIsDirty(true); }}
                  />

                  <TextInput
                    w={200}
                    readOnly={!edit}
                    variant={edit ? 'default' : 'unstyled'}
                    label={
                      <Group align="center" gap="xs">
                        <Text>{t('Schedule')}</Text>
                        {edit && <Switch
                          offLabel={t("OFF")} onLabel={t("ON")}
                          checked={hasSchedule}
                          onChange={(event) => setHasSchedule(event.currentTarget.checked)} />
                        }
                      </Group>
                    }
                    disabled={!hasSchedule}
                    value={state.item.schedule.value}
                    onChange={(event) => { state.item.schedule.set(event.target.value); setIsDirty(true); }}
                    rightSection={scheduleInfo}
                  />
                </Group>

                {getCrawlerTypeFields(state.item.type.value, crawlerRef, state.item.content.value, edit)}

                {edit &&
                  <Group align="center" justify='flex-end' wrap="nowrap">
                    <TestCrawlerConnectionButton
                      crawlerRef={crawlerRef}
                      type={state.item.type.value}
                      collectionId={collectionId}
                    />

                    <Button
                      onClick={onSubmit}
                      // disabled={!formIsValid()}
                      loading={isBusy}
                      leftSection={<IconDeviceFloppy />}
                      type="submit">
                      {t("Save")}
                    </Button>
                  </Group>
                }
              </>
            )}
            {activeTab === "patches" && collectionId && crawlerId && (
              <CrawlerPatches
                collectionId={collectionId}
                crawlerId={crawlerId}
                canContribute={canContribute}
                edit={edit}
                key={`crawler-patches-${collectionId}-${crawlerId}`}
              />
            )}
            {activeTab === "log" && collectionId && crawlerId && (
              <CrawlerLog
                collectionId={collectionId}
                crawlerId={crawlerId}
                key={`crawler-log-${collectionId}-${crawlerId}`} />
            )}
          </Stack>
        }
      </div>
    </Modal>
  );
};

export default EditCrawler;
import { State, useHookstate } from "@hookstate/core";
import { Box, Group, Input, Select, SimpleGrid, Stack, Text } from "@mantine/core";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import { CollectionsSelector } from 'src/components/collections-selector';
import { SkillSearchContent } from "src/stores/skills";
import AppContext from "src/services/app-context";
import NumberInputWithControl from "src/components/number-input-with-control";
import SwitchButton from "src/components/switch-button";
import { IconSettings } from "@tabler/icons-react";
import { ServiceConnectionSelector } from "src/components/service-selector";
import classes from 'src/pages/index.module.css';
import { authStatus } from "src/core/services/authentication-service";

const SkillContentSearchCard: FC<{
  state: State<SkillSearchContent>;
  cardkey: string;
  edit?: boolean;
}> = ({ state, cardkey, edit = false }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const isOwner = authStatus?.user.value?.isOwner;

  return (
    <CollapsibleCard
      title={
        <Group justify='space-between'>
          <Text fw={500}>{t('Options')}</Text>
        </Group>
      }
      cardKey={cardkey}
      icon={<IconSettings />}>
      <Stack>
        <Text c="dimmed">{t("This skill allows you to search across multiple document collections or Internet.")}</Text>
        <Group align="flex-start" grow>
          <Select
            allowDeselect={false}
            readOnly={!edit}
            variant={edit ? 'default' : 'unstyled'}
            label={t("Search type")}
            description={t("Search local collections or use an external service to search the web.") as string}
            defaultValue='local'
            data={[
              { value: 'local', label: t("Search collections") as string },
              { value: 'external', label: t("Use external service (Internet search)") as string },
            ]}
            value={scopedState.enableInternetSearch.value ? 'external' : 'local'}
            onChange={(value) => { scopedState.enableInternetSearch.set(value === 'external'); setIsDirty(true); }}
          />
          <NumberInputWithControl
            required
            label={t("Maximum number of results")}
            description={t("Set a limit for the number of suggestions to display at once.") as string}
            min={0}
            step={1}
            max={500}
            value={scopedState.maxResults.value ?? 3}
            onChange={(value) => { scopedState.maxResults.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />
          <NumberInputWithControl
            required
            label={t("Boost")}
            description={t("Multiply the final score by this value.") as string}
            precision={6}
            min={0}
            step={0.1}
            max={1}
            value={scopedState.boost.value ?? 1}
            onChange={(value) => { scopedState.boost.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />
        </Group>
        {!scopedState.enableInternetSearch.value && <Stack>
          <Input.Wrapper label={t("Collections")}
            description={t("Select the collections you want to search in.") as string}>
            <Box mt='sm' />
            <CollectionsSelector
              value={scopedState?.collections?.value as string[] ?? []}
              onChange={cols => { scopedState.collections.set(cols); setIsDirty(true); }}
              readOnly={!edit}
              placeholder={t("None selected") as string}
            />
          </Input.Wrapper>
          <Group align="flex-start" grow>
            <Select
              style={{ minWidth: 350 }}
              allowDeselect={false}
              readOnly={!edit}
              variant={edit ? 'default' : 'unstyled'}
              label={t("Scoring algorithm")}
              description={t("Scores and ranks a document's relevance given input from the user")}
              defaultValue='cross'
              data={[
                { value: 'linear', label: t("Linear (Weighted Sum of Scores)") as string },
                { value: 'cross', label: t("Cross encoder (Re-ranking)") as string },
              ]}
              value={scopedState.scoring.value ?? 'cross'}
              onChange={(value) => { scopedState.scoring.set(value as string); setIsDirty(true); }}
            />
            <NumberInputWithControl
              required
              label={t("Alpha")}
              description={t("How much semantic results contribute over lexicographic ones") as string}
              precision={6}
              min={0}
              step={0.1}
              max={1}
              value={scopedState.alpha.value ?? 0.5}
              onChange={(value) => { scopedState.alpha.set(value as number); setIsDirty(true); }}
              readOnly={!edit}
            />
            <NumberInputWithControl
              required
              label={t("Confidence")}
              description={t("Minimum value that must be reached to return a matched document.") as string}
              precision={6}
              min={0}
              step={0.1}
              max={1}
              value={scopedState.confidence.value ?? 0.6}
              onChange={(value) => { scopedState.confidence.set(value as number); setIsDirty(true); }}
              readOnly={!edit}
            />
            <NumberInputWithControl
              required
              label={t("Cut-off")}
              description={t("Maximum value to apply scoring algorithm.") as string}
              precision={6}
              min={0}
              step={0.1}
              max={1}
              value={scopedState.cutOff.value ?? 0.95}
              onChange={(value) => { scopedState.cutOff.set(value as number); setIsDirty(true); }}
              readOnly={!edit}
            />
          </Group>

          {isOwner &&
            <SimpleGrid
              cols={{ base: 1, xl: 2 }}
              spacing="sm">
              <SwitchButton
                className={classes.switchButton}
                label={t("Include personal collections")}
                description={t("All personal collections will be included when searching for documents.") as string}
                checked={scopedState.includePersonalCollections.value ?? false}
                onChange={value => { scopedState.includePersonalCollections.set(value); setIsDirty(true); }}
                readOnly={!edit}
              />
              <SwitchButton
                className={classes.switchButton}
                label={t("Include non personal collections")}
                description={t("All non personal collections will be included when searching for documents.") as string}
                checked={scopedState.includeNonPersonalCollections.value ?? false}
                onChange={value => { scopedState.includeNonPersonalCollections.set(value); setIsDirty(true); }}
                readOnly={!edit}
              />
            </SimpleGrid>
          }
          <SwitchButton
            className={classes.switchButton}
            label={t("Enable chat in document viewer")}
            description={t("Enable chat in the document viewer to allow users to ask the bot questions about the document they are viewing.") as string}
            checked={scopedState.showChatInDocumentViewer.value ?? false}
            onChange={value => { scopedState.showChatInDocumentViewer.set(value); setIsDirty(true); }}
            readOnly={!edit}
          />
        </Stack>}
        {scopedState.enableInternetSearch.value &&
          <Group grow align="flex-start">
            <ServiceConnectionSelector
              required
              width="100%"
              label={t("Search service") as string}
              categoryFilter='Search'
              value={scopedState.internetSearchService.value}
              onChange={(value) => scopedState.internetSearchService.set(value as string)}
              readOnly={!edit}
            />

            <NumberInputWithControl
              required
              width="100%"
              label={t("Minimum bot response score")}
              description={t("Minimum score necessary to consider a valid response from the bot, otherwise, if enabled, the response will be searched using the Internet search service.") as string}
              precision={6}
              min={0}
              step={0.1}
              max={1}
              value={scopedState.internetSearchMinScore.value ?? 0.8}
              onChange={(value) => scopedState.internetSearchMinScore.set(value as number)}
              readOnly={!edit}
            />

          </Group>
        }

      </Stack>

    </CollapsibleCard>
  );
};

export default SkillContentSearchCard;
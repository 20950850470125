import { Anchor, Breadcrumbs } from '@mantine/core';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const BreadcrumbsComponent: FC<{ routes: { path: string; breadcrumbName: string | React.ReactNode }[] }> = ({ routes }) => {
  const items = routes.map((item, index) => (
    <Anchor size="xs" component={Link} to={item.path} key={index}>
      {item.breadcrumbName}
    </Anchor>
  ));

  return <Breadcrumbs separator=">">{items}</Breadcrumbs>;
};

import { State } from "@hookstate/core";
import DataStore, { ListState } from "src/core/stores/data-store";
import FormStore from "src/core/stores/form-store";

export type EntityType = 'Synonyms' | 'Regex';

export interface EntitySummary {
  id: string;
  name: string;
  type: EntityType;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export interface EntityItem {
  id: string;
  name: string;
  type: EntityType;
  samples: { [key: string]: string[] };
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export interface NewEntityItem {
  name: string;
  type: EntityType;
  samples: { [key: string]: string[] };
}

export interface EntityBasic {
  name: string;
  type: EntityType;
}

export class EntitySummaryStore extends DataStore<EntitySummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/entities`, []);
  }
}

export class EntityItemStore extends FormStore<EntityItem, NewEntityItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/entities`);
  }
}

export interface ISearchEntitiesStore {
  state: State<ListState<EntityBasic>>
  get: (id: string, name: string) => Promise<any>
}
import { FC, useContext, useEffect, useState } from "react";
import { StepNodeProps } from "./steps";
import { useHookstate, none } from "@hookstate/core";
import { Group, Stack, ActionIcon, Textarea, Text, Tooltip, Fieldset, TextInput, Badge, Select, Code, Divider } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import StepWrapper from "./step-wrapper";
import NumberInputWithControl from "src/components/number-input-with-control";
import { SkillContentStepDataType } from "src/stores/skills";
import { truncateText } from "src/core/utils/object";
import HeaderContext from "src/services/header-context";
import LanguageSegmentedWrapper, { LanguageSegmentedWrapperChildrenProps } from "src/components/language-segmented-wrapper";

const StepAskNode: FC<StepNodeProps> = ({ id, data }) => {
  const { step, edit, index, rightToolbar, updateNode } = data;
  const scopedState = useHookstate(step.action);
  const { t } = useTranslation();
  const { header } = useContext(HeaderContext);
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.questions?.length > 0 ? Object.keys(scopedState?.value?.questions?.[0]) : defaultAllowedLanguages);

  // useEffect(() => {
  //   const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStepAction;
  //   const newAction = { ...step.action, ...changes };
  //   const newStep = { ...step, action: newAction };
  //   const newData = { index: index, step: newStep } as StepDataProps;
  //   updateNode(id, newData);
  // }, [JSON.stringify(scopedState.value)])

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.questions?.length > 0 ? Object.keys(scopedState?.value?.questions?.[0]) : defaultAllowedLanguages);
  }, [scopedState.questions])

  const onAddQuestion = () => {
    const defaultLang = allowedLanguages[0];
    let defaultQuestions: { [key: string]: string } = {};
    defaultQuestions[defaultLang] = t("<your question>", { lng: defaultLang });
    scopedState.questions[scopedState.questions.length].set(defaultQuestions);
    setIsDirty(true);
  }

  const onAddOption = () => {
    if (scopedState.options?.value) {
      scopedState.options[scopedState.options.length].set('#<your option>');
    }
    else {
      scopedState.merge({ options: ['#<your option>'] });
    }
    setIsDirty(true);
  }

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.questions.length; index++) {
      (scopedState.questions[index])[language].set(none);
    }
    setIsDirty(true);
  }

  const onAddLanguage = (language: string) => {
    const defaultText = t("<your question>", { lng: language });
    for (let index = 0; index < scopedState.value.questions.length; index++) {
      (scopedState.questions[index])[language].set(defaultText);
    }
    setIsDirty(true);
  }

  const resumeLang = allowedLanguages.includes(header.language) ? header.language : allowedLanguages[0];
  const resume = (
    <Stack gap="xs">
      <Text title={scopedState?.questions?.[0]?.[resumeLang]?.value}>{truncateText(scopedState?.questions?.[0]?.[resumeLang]?.value)}</Text>

      {scopedState?.options?.length > 0 &&
        <>
          <Divider />
          <Group align="center" gap="xs">
            {scopedState?.options?.length > 0 && scopedState.options.map((option, index) =>
              <Badge key={index} size="md" color="indigo" variant="light" style={{ textTransform: 'none' }}>{option.value}</Badge>
            )}
          </Group>
        </>
      }

      <Divider />
      <span>{t("Save in")}&nbsp;
        <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" >{scopedState?.entity?.value}</Code>
        {scopedState?.dataType?.value && scopedState.dataType.value.length > 0 &&
          <Text span> {t("as")} <Text span fs="italic">{scopedState.dataType.value}</Text></Text>
        }
      </span>
    </Stack>
  )

  if (!scopedState?.value) return (<></>);

  return (
    <StepWrapper
      id={id}
      index={index}
      step={step}
      updateNode={updateNode}
      edit={edit}
      rightToolbar={rightToolbar}
      resume={resume}>
      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Questions")}</Text>
            {edit &&
              <Group align="center" gap={5}>
                <Tooltip withinPortal label={t("Add question")}>
                  <ActionIcon onClick={onAddQuestion} variant='subtle' color="gray" size="sm">
                    <IconPlus size={14} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            }
          </Group>
        }>
        <Stack gap="xs">
          <LanguageSegmentedWrapper
            edit={edit}
            data={scopedState?.value?.questions?.length > 0 ? Object.keys(scopedState?.value?.questions?.[0]) : undefined}
            onDelete={onDeleleLanguage}
            onAdd={onAddLanguage}
          >
            {(props: LanguageSegmentedWrapperChildrenProps) =>
              <>
                {scopedState?.questions?.value && scopedState.questions.map((question, index) =>
                  edit ?
                    <Group align="flex-start" key={index}>
                      <Textarea
                        autosize
                        minRows={3}
                        maxRows={10}
                        required
                        style={{ flex: 1, minWidth: 400 }}
                        value={question[props.selectedLanguage]?.value ?? ''}
                        onChange={(event) => { question[props.selectedLanguage].set(event.target.value); setIsDirty(true); }}
                      />
                      <Stack gap={5}>
                        <ActionIcon onClick={() => { question.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                          <IconTrash size={16} />
                        </ActionIcon>
                      </Stack>
                    </Group>
                    :
                    <div key={index}>
                      <Text span fs="italic">{question[props.selectedLanguage]?.value ?? ''} </Text>
                    </div>
                )}
              </>
            }
          </LanguageSegmentedWrapper>
        </Stack>
      </Fieldset>

      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Options")}</Text>
            {edit &&
              <Group align="center" gap={5}>
                <Tooltip withinPortal label={t("Add option")}>
                  <ActionIcon onClick={onAddOption} variant='subtle' color="gray" size="sm">
                    <IconPlus size={14} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            }
          </Group>
        }>
        <Stack gap="xs">
          {edit ?
            <Stack gap="xs">
              {scopedState?.options?.value && scopedState.options.map((option, index) =>
                <Group align="flex-start" key={index}>
                  <TextInput
                    required
                    style={{ flex: 1 }}
                    value={option.value}
                    onChange={(event) => { option.set(event.target.value); setIsDirty(true); }}
                  />
                  <ActionIcon onClick={() => { option.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                    <IconTrash size={16} />
                  </ActionIcon>
                </Group>
              )}
            </Stack>
            :
            <Group align="center" gap="xs">
              {scopedState?.options?.length > 0 && scopedState.options.map((option, index) =>
                <Badge key={index} size="md" color="indigo" variant="light" style={{ textTransform: 'none' }}>{option.value}</Badge>
              )}
            </Group>
          }
        </Stack>
      </Fieldset>

      <Fieldset p="xs" variant="filled" legend={t("MinScore")}>
        {edit ?
          <NumberInputWithControl
            precision={6}
            min={0}
            step={0.01}
            max={1}
            value={scopedState.minScore.value}
            onChange={(value) => { scopedState.minScore.set(value as number); setIsDirty(true); }}
          />
          :
          <Text span fs="italic">{scopedState.minScore.value}</Text>
        }
      </Fieldset>

      <Fieldset p="xs" variant="filled" legend={t("Save in")}>
        {edit ?
          <Group align="center">
            <TextInput
              required
              style={{ flex: 1 }}
              value={scopedState.entity.value}
              onChange={(event) => { scopedState.entity.set(event.target.value); setIsDirty(true); }}
            />
            <Group gap={10}>
              <Text size="sm">{t("as")}</Text>
              <Select
                w={100}
                allowDeselect={false}
                data={[
                  { value: 'String', label: t('String') as SkillContentStepDataType },
                  { value: 'Integer', label: t('Integer') as SkillContentStepDataType },
                  { value: 'Number', label: t('Number') as SkillContentStepDataType },
                  { value: 'Date', label: t('Date') as SkillContentStepDataType }
                ]}
                value={scopedState.dataType.value}
                onChange={(value) => { scopedState.dataType.set(value as SkillContentStepDataType); setIsDirty(true); }}
              />
            </Group>
          </Group>
          :
          <div>
            <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" >{scopedState.entity.value}</Code>
            {scopedState.dataType.value && scopedState.dataType.value.length > 0 &&
              <Text span> {t("as")} <Text span fs="italic">{scopedState.dataType.value}</Text></Text>
            }
          </div>
        }
      </Fieldset>
    </StepWrapper>
  );
};

export default StepAskNode;
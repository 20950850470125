import { Group, Stack, Text, Card, Box, ComboboxItem, Center, Grid, useMantineColorScheme, ActionIcon, Tooltip, em, Container, TextInput, Textarea, Modal, Button, Loader, Alert } from "@mantine/core";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconDots, IconUrgent, IconSend as Send } from "@tabler/icons-react";
import { IdentitySelector } from "../identity-selector";
import { State, none, useHookstate } from "@hookstate/core";
import { ChatRequest, ChatResponse, ChatItem } from "src/stores/chat";
import { capitalizeFirstLetter, delay, formatMessage, isNullOrWhitespace } from "src/core/utils/object";
import { ISnapshotSelectStore, IChatStore, MessageContent, ResponseItem, ChatBotStore, DocumentReferenceItem, ConversationInfo } from "src/stores/skills";
import { SnapshotSelector } from "../snapshot-selector";
import { useModals } from "@mantine/modals";
import { useElementSize, useMediaQuery } from "@mantine/hooks";
import { AppConfiguration, useAuthentication } from "../../core/services/authentication-service";
import { getLS, saveLS } from 'src/core/utils/local-storage';
import Editor from "@monaco-editor/react";
import ChatMessageSuggestionsCard from "./chat-message-suggestions-card";
import ChatSendMessage from "./chat-send-message";
import classes from 'src/pages/index.module.css';
import { BasicMultiselect } from "../basic-multiselect";
import { AskDocumentStore, DocumentItemStore, DocumentSummary, UpsertDocumentSnapshotItem } from "src/stores/documents";
import { FileWithPath } from "@mantine/dropzone";
import { v4 as uuidv4 } from 'uuid';
import HeaderContext from "src/services/header-context";
import useBus, { dispatch } from "use-bus";
import { container } from "src/inversify.config";
import { BotBasicInfoItem, BotBasicInfoItemStore, FeedbackMessageItem, UserPreferences } from "src/stores/bots";
import { AsEnumerable } from "linq-es5";
import { LanguageSeletor } from "../language-selector";
import { monacoOptions } from "src/configurations/editor-config-jinja";
import { CanUsePersonalCollection } from "src/utils/permissions";
import { BotCollectionSelector } from "../bot-collection-selector";
import { showNotification } from "@mantine/notifications";
import { arePreferencesModifiedByUser, chatPreferencesGlobalState, isInitialEvent, isLastEventInGroup, setChatPreferences } from "./chat-utils";
import ChatMessageEvent from "./chat-message-event";
import ChatMessageAssistant from "./chat-message-assistant";
import ChatMessageUser from "./chat-message-user";

interface ChatSnapshotLocalSettings {
  audience: string[],
  identity: string,
  language: string
}

const addNewMessage = (text: string, signal: AbortSignal, scopedState: State<ChatItem>, onFinish: (text: string, signal: AbortSignal, selectedDocuments?: DocumentSummary[], importFiles?: FileWithPath[]) => void,
  selectedDocuments?: DocumentSummary[], importFiles?: FileWithPath[]) => {

  const properties = {
    selectedDocuments: selectedDocuments ? selectedDocuments.map(sd => ({ documentId: sd.id, documentTitle: sd.title })) : [],
    importDocuments: importFiles ? importFiles.map(fi => ({ fileName: fi.name, fileSize: fi.size })) : []
  };

  if (text || (importFiles && importFiles.length > 0)) {
    scopedState.messages.merge([{
      identity: scopedState.identity.value ?? '#User',
      role: 'User',
      language: scopedState.language.value,
      audience: scopedState.audience.value?.filter(o => o),
      text: text,
      timestamp: new Date,
      contentType: 'text/markdown',
      properties: properties,
      content: {} as MessageContent
    } as ChatRequest]);

    onFinish(text, signal, selectedDocuments, importFiles);
  }
}

const getOrCreateLocalSnapshotSettings = (snapshotId: string, values: ChatItem): ChatSnapshotLocalSettings => {
  let result = {
    audience: values.audience?.filter(o => o),
    identity: values.identity,
    language: values.language
  } as ChatSnapshotLocalSettings;

  if (snapshotId && snapshotId !== "") {
    const value = getLS(`chat-component-snapshot-preferences-${snapshotId}`);
    if (value) {
      result = JSON.parse(value) as ChatSnapshotLocalSettings;
    }
    else {
      updateLocalSnapshotSettings(snapshotId, result);
    }
  }
  return result;
}

const updateLocalSnapshotSettings = (snapshotId: string, values: ChatSnapshotLocalSettings) => {
  if (snapshotId && snapshotId !== "") {
    saveLS(`chat-component-snapshot-preferences-${snapshotId}`, JSON.stringify(values));
  }
}

const ChatComponent: FC<{
  id?: string;
  language?: string;
  description?: string;
  store?: IChatStore;
  snapshotId?: string;
  hideHeader?: boolean;
  hideDebugPanel?: boolean;
  height?: number;
  snapshotSelectStore?: ISnapshotSelectStore;
  onChangeHeaderChat?: (title: string, conversationId: string, withChanges?: boolean) => void;
  onChatWithChanges?: (withChanges: boolean) => void;
  allowSaveConversation?: boolean;
  uploadFiles?: boolean;
  hideTokens?: boolean;
  sendInitialMessage?: boolean;
  hideUserPreferences?: boolean;
}> = ({ store, id, snapshotId, hideHeader = false, hideDebugPanel = false, snapshotSelectStore, language, description, height, onChangeHeaderChat, onChatWithChanges, allowSaveConversation = false, uploadFiles, hideTokens, sendInitialMessage = true, hideUserPreferences = false }) => {
  const { t } = useTranslation();
  const { header, setHeader } = useContext(HeaderContext);
  const { colorScheme } = useMantineColorScheme();
  const modals = useModals();
  const chatSize = useElementSize();
  const viewport = useRef<HTMLDivElement>(null);
  const [codeDetail, setCodeDetail] = useState<string>("{}");
  const [internalSnapshotId, setInternalSnapshotId] = useState<string | undefined>(snapshotId);
  const [chatWithChanges, setChatWithChanges] = useState<boolean>(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [authStatus] = useAuthentication();
  const allowPersonalCollection = CanUsePersonalCollection();
  const documentStore = useMemo(() => container.get(DocumentItemStore), []);
  const documentState = documentStore.state;
  const botInfoStore = useMemo(() => container.get(BotBasicInfoItemStore), []);
  const botInfoState = botInfoStore.state;
  const chatPreferences = useHookstate(chatPreferencesGlobalState);
  const defaultAudience = container.get<AppConfiguration>("AppConfiguration").defaultAudience;
  const eventGroupState = useHookstate({
    showEvents: [] as number[],
    groupId: 0
  });
  const [referralDocument, setReferralDocument] = useState<string | undefined>(undefined);

  let customMonacoOptions = { ...monacoOptions };
  customMonacoOptions.readOnly = true;

  const initialState = {
    conversationId: uuidv4(),
    title: t('New chat'),
    audience: defaultAudience ? [defaultAudience] : [],
    identity: authStatus?.user.value?.sub ?? "",
    language: language ?? header.language ?? 'en',
    text: '',
    messages: [],
    properties: {}
  } as ChatItem;

  const state = useHookstate(initialState);
  const testState = store?.state;
  const testStateIsBusy = testState?.isBusy?.value ?? false;

  let alertMessage: string | null = null;
  if (botInfoState.item?.alert?.value) {
    if (botInfoState.item?.alert?.value[initialState.language]) {
      alertMessage = botInfoState.item?.alert.value[initialState.language];
    }
    if (isNullOrWhitespace(alertMessage ?? "")) {
      alertMessage = null;
    }
  }

  useEffect(() => {
    if (!testStateIsBusy && testState && testState.errorMessage?.value) {
      const unauthorized = testState.status?.value && testState.status.value === 401;
      if (testState.errorMessage.value || unauthorized) {
        const errorMessage = unauthorized ? t("Unauthorized") : (store?.state.errorMessage.value as string ?? t("Bot returned an error"));
        showNotification({
          title: t('Error'),
          message: <Text>{formatMessage(errorMessage)}.</Text>,
          color: 'red'
        });
      }
    }
  }, [testState])

  const conversationInfoState = useHookstate({
    conversationInfo: {} as ConversationInfo,
    isSaved: false
  });

  useEffect(() => {
    setInternalSnapshotId(snapshotId);
  }, [id, snapshotId]);

  useEffect(() => {
    if (internalSnapshotId && internalSnapshotId !== "") {
      const snashotSettings = getOrCreateLocalSnapshotSettings(internalSnapshotId, state.value as ChatItem);
      state.merge(snashotSettings);
    }
  }, [internalSnapshotId]);

  useEffect(() => {
    if (internalSnapshotId && internalSnapshotId !== "") {
      updateLocalSnapshotSettings(internalSnapshotId, {
        audience: state.audience.value,
        identity: state.identity.value,
        language: state.language.value
      } as ChatSnapshotLocalSettings);
    }
  }, [state.audience, state.language, state.identity])

  useEffect(() => {
    onRestartChat();
    if (id && id !== "" && store && store instanceof ChatBotStore) {
      loadBotInfo(id);
    }
    if (id && id !== "" && store && store instanceof AskDocumentStore) {
      setReferralDocument(id);
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadBotInfo = async (botId: string) => {
    if (botId) {
      const botInfo = await botInfoStore.getInfo(botId) as BotBasicInfoItem;
      setChatPreferences(botInfo);
    }
  };

  useEffect(() => {
    if (chatPreferences?.value && botInfoState?.item?.value) {
      saveLS(`chat-component-user-preferences-${botInfoState.item.id.value}`, JSON.stringify(chatPreferences.value));
      arePreferencesModifiedByUser(botInfoState?.item?.value as BotBasicInfoItem, JSON.parse(JSON.stringify(chatPreferences.value)) as UserPreferences);
    }
  }, [JSON.stringify(chatPreferences.value)])

  useEffect(() => {
    if (state?.title?.value || state?.conversationId?.value) {
      onChangeHeaderChat?.(state?.title?.value ?? "", state?.conversationId?.value);
    }
  }, [state.title, state.conversationId])

  useEffect(() => {
    onChatWithChanges?.(chatWithChanges);
  }, [chatWithChanges])

  const onCloseChat = (onConfirm: () => void, force?: boolean) => {
    if (force && force === true) {
      onConfirm();
    }
    else {
      if (state.messages.value.length > 0 && chatWithChanges) {
        if (allowPersonalCollection && allowSaveConversation) {
          modals.openConfirmModal({
            title: <Text>{t('Save conversation')}</Text>,
            zIndex: 999,
            closeOnConfirm: true,
            closeOnCancel: true,
            children: (
              <Text size="sm">{t('Do you want to save this conversation?')}</Text>
            ),
            labels: { confirm: t('Save'), cancel: t('Discard') },
            onCancel: () => {
              onConfirm();
              modals.closeAll();
            },
            onConfirm: async () => {
              if (conversationInfoState && conversationInfoState.isSaved.value) {
                await store?.saveConversation?.(id as string, state.conversationId.value);
                dispatch('@@ui/CHAT_DOCUMENT_LIST_REFRESH');
                onConfirm();
                modals.closeAll();
              }
              else {
                modals.closeAll();
                openSaveModal();
              }
            },
          });
        }
        else {
          modals.openConfirmModal({
            title: <Text>{t('Start a new conversation')}</Text>,
            zIndex: 999,
            closeOnConfirm: true,
            closeOnCancel: true,
            children: (
              <Text size="sm">{t('Your conversation history will be lost. Are you sure you want to start a new conversation?')}</Text>
            ),
            labels: { confirm: t('New conversation'), cancel: t('Cancel') },
            onCancel: () => {
              modals.closeAll();
            },
            onConfirm: () => {
              onConfirm();
              modals.closeAll();
            },
          });
        }
      }
      else {
        onConfirm();
      }
    }
  }

  useBus(
    '@@ui/CHAT_SAVE_CHAT',
    () => openSaveModal(),
    [store, conversationInfoState, setShowSaveModal, modals],
  );

  const openSaveModal = async () => {
    if (!conversationInfoState.isSaved.value) {
      setShowSaveModal(true);
      const conversationInfo = await store?.generateConversationInfo(id as string, state.conversationId.value);
      conversationInfoState.conversationInfo.set(conversationInfo);
    }
    else {
      await store?.saveConversation?.(id as string, state.conversationId.value, conversationInfoState.conversationInfo.value);
      setChatWithChanges(false);
      conversationInfoState.isSaved.set(true);
    }
  }

  const onCloseSaveModal = () => {
    setShowSaveModal(false);
    conversationInfoState.set({
      conversationInfo: {} as ConversationInfo,
      isSaved: false
    });
  }

  const onSaveConversation = async () => {
    await store?.saveConversation?.(id as string, state.conversationId.value, conversationInfoState.conversationInfo.value);
    onChangeHeaderChat?.(conversationInfoState.conversationInfo.title.value, state.conversationId.value);
    dispatch('@@ui/CHAT_DOCUMENT_LIST_REFRESH');
    modals.closeAll();
    setChatWithChanges(false);
    setShowSaveModal(false);
    conversationInfoState.isSaved.set(true);
  }

  useBus(
    '@@ui/CHAT_RESTART_CHAT',
    () => onRestartChat(),
    [header, state, chatWithChanges, modals],
  );

  const onRestartChat = (force: boolean = false) => {
    onCloseChat(() => {
      state.set(initialState);
      if (internalSnapshotId && internalSnapshotId !== "") {
        const snashotSettings = getOrCreateLocalSnapshotSettings(internalSnapshotId, initialState);
        state.merge(snashotSettings);
      }
      setChatWithChanges(false);
      conversationInfoState.set({
        conversationInfo: {} as ConversationInfo,
        isSaved: false
      });
      dispatch('@@ui/CHAT_DOCUMENTS_DESELECTED');
      setCodeDetail("{}");
      eventGroupState.set({
        showEvents: [],
        groupId: 0
      });
      if (sendInitialMessage) {
        onSendMessage("", AbortSignal.timeout(60000));
      }
    }, force);
  }

  useBus(
    '@@ui/CHAT_SELECTED',
    (event) => loadChatHistory(event.payload),
    [id, chatWithChanges],
  );

  const loadChatHistory = async (item: DocumentSummary) => {
    if (item) {
      documentStore.setCollection(item.collectionId);
      await documentStore.loadConversation(id as string, item.id, item.collectionId);
      if (documentState?.item?.value) {
        const chatHistory = JSON.parse(documentState.item.currentSnapshot.content.value) as ResponseItem;

        if (state.conversationId.value !== item.id) {
          eventGroupState.set({
            showEvents: [],
            groupId: 0
          });
          onCloseChat(async () => {
            let messages = [];
            let gId = eventGroupState.groupId.value;

            for (let index = 0; index < chatHistory.messages.length; index++) {
              const item = chatHistory.messages[index];

              if (index > 0 && item.role === 'Event' && chatHistory.messages[index - 1].role !== 'Event') {
                gId += 1;
              }

              messages.push({
                id: `${item?.id ?? 0}`,
                role: item.role,
                identity: item?.properties?.identity as any,
                text: item.text,
                content: item.content,
                title: item.content?.title,
                score: item.score,
                properties: item.properties,
                action: item.action?.type,
                skillId: item.skillId,
                skillSnapshotId: item.skillSnapshotId,
                skillType: item.skillType,
                actions: item.actions,
                context: null,
                timestamp: item.timestamp,
                media: item.media,
                groupId: item.role === 'Event' ? gId : undefined
              } as ChatResponse);
            }

            let chatItemLoaded = {
              conversationId: item.reference,
              title: documentState.item.title.value,
              audience: documentState.item.audiences.value,
              identity: authStatus?.user.value?.sub ?? "",
              language: documentState.item.currentSnapshot.language.value,
              text: '',
              messages: messages,
              properties: {}
            } as ChatItem;

            state.set(chatItemLoaded);

            eventGroupState.groupId.set(gId);
            setChatWithChanges(false);
            conversationInfoState.set({
              conversationInfo: {
                title: documentState.item.title.value,
                description: documentState.item.description.value,
                collectionIdOrReference: documentState.item.reference.value
              },
              isSaved: true
            });
            await delay(100);
            scrollToBottom();
          });
        }
        // else {
        //   state.set(chatHistory); // No cargar historico de chat si hacemos click en el chat actual
        // }
      }
    }
  }

  const onSendMessage = async (message: string, signal: AbortSignal, selectedDocuments?: DocumentSummary[], importFiles?: FileWithPath[]) => {
    if (store) {
      let hasResponse = false;
      let media = [] as DocumentReferenceItem[];
      let gId = eventGroupState.groupId.value;
      if (selectedDocuments && selectedDocuments.length > 0) {
        selectedDocuments.forEach(doc => {
          if (doc?.id) {
            media.push({
              documentOrSnapshotId: doc?.id
            });
          }
        });
      }

      if (referralDocument) {
        media.push({
          documentOrSnapshotId: referralDocument
        });
      }

      //subir documentos si los hay, siempre a la coleccion personal
      if (importFiles && importFiles.length > 0 && allowPersonalCollection && uploadFiles) {
        const started = new Date().toString();
        if (state.messages.length > 0 && state.messages[state.messages.length - 1].role.value !== 'Event') {
          gId += 1;
        }
        else {
          gId = state.messages[state.messages.length - 1].groupId?.value ?? gId;
        }

        documentStore.setCollection(state.identity.value);
        for (const importFile of importFiles) {
          state.messages.merge([{
            role: "Event",
            identity: {} as any,
            text: `${t("Uploading document...")}`,
            content: {
              text: `${t("Uploading document...")}`,
              started: started,
              type: 'Plain'
            },
            title: "",
            score: 0,
            properties: {},
            action: "None",
            skillId: "",
            skillSnapshotId: "",
            skillType: "Dialog",
            actions: [],
            context: {},
            timestamp: new Date(),
            groupId: gId
          } as ChatResponse]);

          const docId = uuidv4();
          const body = {
            audiences: [state.identity.value],
            boost: 1,
            bootstrapIntents: [],
            dontAdvertise: false,
            suggestions: [],
            content: '',
            description: '',
            properties: {},
            tags: {},
            splitMethod: chatPreferences.value.enableOCRInDocuments ? "Block" : "Auto",
            id: docId,
            reference: '',
            language: state.language.value,
            contentSource: "External",
            title: importFile.name,
            train: true,
            publish: true
          } as UpsertDocumentSnapshotItem;
          await documentStore.saveSnapshotWithFile(docId, body, importFile as File);
          if (state.messages.length > 0 && state.messages[state.messages.length - 1].role.value === 'Event') {
            state.messages[state.messages.length - 1].set(none);
          }
          if (!documentState.errorMessage.value) {
            if (state.messages.length > 0 && state.messages[state.messages.length - 1].role.value !== 'Event') {
              gId += 1;
            }
            else {
              gId = state.messages[state.messages.length - 1].groupId?.value ?? gId;
            }
            state.messages.merge([{
              role: "Event",
              identity: {} as any,
              text: `${t("The document has been uploaded successfully")}`,
              content: {
                text: `${t("The document has been uploaded successfully")}`,
                started: started,
                finished: new Date().toString(),
                type: 'Plain'
              },
              title: "",
              score: 0,
              properties: {},
              action: "None",
              skillId: "",
              skillSnapshotId: "",
              skillType: "Dialog",
              actions: [],
              context: {},
              timestamp: new Date(),
              groupId: gId
            } as ChatResponse]);

            if (documentState.item?.id?.value) {
              media.push({
                documentOrSnapshotId: documentState.item?.id?.value
              });
            }
          }
          else {
            state.messages.merge([{
              role: "Assistant",
              identity: {} as any,
              text: `ERROR: ${documentState.errorMessage.value}`,
              content: {
                text: `ERROR: ${documentState.errorMessage.value}`,
                started: new Date().toString(),
                type: 'Plain'
              },
              title: "",
              score: 0,
              properties: {},
              action: "None",
              skillId: "",
              skillSnapshotId: "",
              skillType: "Dialog",
              actions: [],
              context: {},
              timestamp: new Date()
            } as ChatResponse]);
          }
        }
      }

      await delay(100);
      scrollToBottom();

      try {
        let scrolledLast = Date.now();
        for await (let item of store.ask_streamed(id as string, internalSnapshotId, state.conversationId.value, {
          query: message,
          language: state.language.value,
          audience: hideDebugPanel ? [] : state.audience.value?.filter(o => o),
          identity: state.identity.value,
          media: media,
          preferences: chatPreferences.value,
        }, signal)) {
          hasResponse = true;

          if (!item)
            return;

          if (item.delta?.tool_calls || (item.message?.role === 'Assistant' && !item.message.text) || (item.delta && Object.keys(item.delta).length === 0)) {
            continue;
          }

          let existing = state.messages.filter(o => o.id && (o.id.value === `${item.message?.id ?? 0}` || o.updateId.value === item.id));
          if (item.message) {
            const msg = item.message;

            if (state.messages.length > 0 && msg.role && msg.role === 'Event' && state.messages[state.messages.length - 1].role.value !== 'Event' && !isNullOrWhitespace(state.messages[state.messages.length - 1].text.value)) {
              gId += 1;
            }
            else {
              gId = state.messages[state.messages.length - 1].groupId?.value ?? gId;
            }

            const newMsg = {
              id: `${msg?.id ?? 0}`,
              updateId: item.id,
              role: msg.role,
              identity: msg?.properties?.identity as any,
              text: msg.text,
              content: msg.content,
              title: msg.content?.title,
              score: msg.score,
              properties: msg.properties,
              action: msg.action?.type,
              skillId: msg.skillId,
              skillSnapshotId: msg.skillSnapshotId,
              skillType: msg.skillType,
              actions: msg.actions,
              context: null,
              timestamp: new Date(),
              groupId: msg.role === 'Event' ? gId : undefined
            } as ChatResponse;

            if (existing.length === 0) {
              state.messages.merge([newMsg]);
            }
            else {
              const existingObj = existing[0];
              existingObj.set({ ...newMsg });
            }
          }
          else {
            if (item.delta.role) {
              item.delta.role = capitalizeFirstLetter(item.delta.role)
            }
            if (item.delta.content) {
              item.delta.text = item.delta.content;
              delete item.delta.content;
            }
            if (existing.length === 0) {
              state.messages.merge([{
                ...item.delta,
                id: item.id,
                updateId: item.id,
              }]);
            }
            else {
              const existingObj = existing[0];
              if (existingObj.text && existingObj.text.value) {
                if (!item.delta.text)
                  item.delta.text = ''
                const newContent = existingObj.text.value === "⏳" ? item.delta.text : existingObj.text.value + item.delta.text;
                existingObj.set({ ...JSON.parse(JSON.stringify(existingObj.value)), ...JSON.parse(JSON.stringify(item.delta)), text: newContent })
              }
              else {
                existingObj.set({ ...JSON.parse(JSON.stringify(existingObj.value)), ...JSON.parse(JSON.stringify(item.delta)) })
              }
            }

            state.messages.forEach(msg => {
              if (msg.role.value === 'Event') {
                if (!msg.content.finished || !msg.content.finished.value) {
                  msg.content.finished.set(new Date().toString())
                }
              }
            });
          }

          if (message.length > 0) {
            setChatWithChanges(true);
          }

          if ((Date.now() - scrolledLast) > 1000) {
            scrolledLast = Date.now();
            await delay(100);
            scrollToBottom();
          }
        }

        await delay(100);
        scrollToBottom();

        if (importFiles && importFiles.length > 0)
          dispatch('@@ui/CHAT_DOCUMENT_LIST_REFRESH');

      } catch (msg) {
        hasResponse = true;
        state.messages.merge([{
          role: "Assistant",
          identity: {} as any,
          text: `ERROR: ${msg}`,
          content: {
            text: `ERROR: ${msg}`,
            started: new Date().toString(),
            type: 'Plain'
          },
          title: "",
          score: 0,
          properties: {},
          action: "None",
          skillId: "",
          skillSnapshotId: "",
          skillType: "Dialog",
          actions: [],
          context: {},
          timestamp: new Date()
        } as ChatResponse]);
      }

      state.messages.forEach(msg => {
        if (msg.role.value === 'Event') {
          if (!msg.content.finished || !msg.content.finished.value) {
            msg.content.finished.set(new Date().toString())
          }
        }
      });

      eventGroupState.groupId.set(gId);

      if (message !== "" && !hasResponse) {
        state.messages.merge([{
          role: "Assistant",
          identity: {} as any,
          text: t("Sorry. I have not found a good answer."),
          content: {
            text: t("Sorry. I have not found a good answer."),
            started: new Date().toString(),
            type: 'Plain'
          },
          title: "",
          score: 0,
          properties: {},
          action: "None",
          skillId: "",
          skillSnapshotId: "",
          skillType: "Dialog",
          actions: [],
          context: {},
          timestamp: new Date()
        } as ChatResponse]);

        await delay(100);
        scrollToBottom();
      }
    }
  }

  const scrollToBottom = () => viewport?.current?.scrollTo({ top: viewport?.current?.scrollHeight, behavior: "smooth" });

  const onShowMessageDetail = (m: ChatRequest | ChatResponse) => {
    setCodeDetail(JSON.stringify(m, null, 2));
  }

  const getAudiencesData = () => {
    let data = [] as ComboboxItem[];
    if (state?.audience?.value) {
      data = state.audience.value.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  const toggleEventGroup = (groupId: number | undefined) => {
    if (groupId !== undefined) {
      const index = eventGroupState.showEvents.value.indexOf(groupId);
      if (index >= 0) {
        eventGroupState.showEvents[index].set(none);
      }
      else {
        eventGroupState.showEvents.merge([groupId]);
      }
    }
  }

  const sendFeedback = async (messageId: string, like: boolean) => {
    if (messageId && botInfoState?.item?.id?.value && state?.conversationId?.value) {
      const feedback = { action: like ? 'Like' : 'Dislike' } as FeedbackMessageItem;
      await botInfoStore.sendFeedback(botInfoState.item.id.value, state.conversationId.value, messageId, feedback);
    }
  }

  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  return (
    <Stack gap="xs" className={classes.fullHeightContainer} style={{ height: height }}>
      {!hideHeader &&
        <Card withBorder py="xs">
          <Box style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Group align="flex-start" style={{ flexGrow: 1 }}>
              {snapshotSelectStore &&
                <SnapshotSelector
                  label={t("Snapshot version") as string}
                  showWarningModal
                  store={snapshotSelectStore}
                  value={internalSnapshotId}
                  onChange={(value) => { setInternalSnapshotId(value); onRestartChat(true); }} />
              }
              <IdentitySelector label={t("Identity") as string} creatable clearable value={state.identity.value} onChange={(value) => state.identity.set(value)} />
              <LanguageSeletor label={t("Language") as string} value={state.language.value} onChange={(lang) => state.language.set(lang as string)} />
              <BasicMultiselect
                label={t("Audiencies") as string}
                data={getAudiencesData()}
                value={state.audience.value as string[]}
                onChange={(value) => state.audience.set(value)}
                placeholder={t("Select audiences") as string}
                withinPortal
                creatable
                style={{ flex: 1 }}
              />
            </Group>
          </Box>
        </Card>
      }
      <Grid gutter={hideDebugPanel ? 0 : "xs"} styles={{ root: { flexGrow: 1, display: 'flex' }, inner: { flexGrow: 1 } }}>
        <Grid.Col span="auto">
          <Card withBorder h='100%' ref={chatSize.ref} p={isMobile ? 5 : "xs"} style={{ position: 'relative', /*maxWidth: '99vw'*/ }}>
            <Box p={isMobile ? 5 : "xs"} style={{ overflowY: 'auto', position: 'absolute', height: '90%', minHeight: '90%', width: '100%', top: 0, left: 0 }} ref={viewport}>
              <Container w="100%" h="100%" p={0}>
                <Stack gap="xs">
                  {alertMessage && <Alert variant="light" color="yellow" title={alertMessage} icon={<IconUrgent />} />}
                  {state.messages.map((m, index) => {
                    const lastEvent = isLastEventInGroup(state.messages.value as ((ChatRequest | ChatResponse)[]), index);
                    const initialEvent = isInitialEvent(state.messages.value as ((ChatRequest | ChatResponse)[]), index);
                    const isLastVisibleEvent = state.messages.length - 1 === index || (lastEvent && isNullOrWhitespace(state.messages[state.messages.length - 1].text?.value));

                    if (!m.text?.value) return; // for empty responses
                    if (initialEvent) return; // hide initial events

                    return (
                      <Box
                        key={`messagebox-${index}`}
                        mod={{
                          groupId: m.groupId?.value ?? '',
                          role: m.role.value,
                          timestamp: m.timestamp?.value ? new Date(m.timestamp.value).getTime() : '',
                          messageId: m.id.value
                        }}
                        style={m.role.value === 'Event' && !lastEvent &&
                          m.groupId?.value !== undefined && eventGroupState.showEvents.value.indexOf(m.groupId.value) < 0
                          ? { display: 'none' }
                          : {}}>
                        <Stack gap="xs" key={`message-wrap-${index}`}>
                          {m.role.value === 'Event' &&
                            <ChatMessageEvent
                              state={m as any}
                              isBusy={testStateIsBusy}
                              isLastEvent={isLastVisibleEvent}
                              moreOptions={m.role.value === 'Event' && lastEvent && AsEnumerable(state.messages.value).Count(o => o.role === 'Event' && o.groupId === m.groupId.value) > 1 &&
                                <Tooltip label={t("Show/hide all events")}>
                                  <ActionIcon variant="subtle" radius="xl" size="sm" onClick={() => toggleEventGroup(m.groupId.value)}>
                                    <IconDots />
                                  </ActionIcon>
                                </Tooltip>}
                            />
                          }

                          {m.role.value === 'User' &&
                            <ChatMessageUser
                              state={m as any}
                              isBusy={testStateIsBusy}
                              onSendMessage={(text, signal) => {
                                addNewMessage(text, signal, state, () => { });
                                onSendMessage(text, signal)
                              }}
                              onMessageClick={hideDebugPanel ? undefined : () => onShowMessageDetail(m.value as any)}
                            />
                          }

                          {m.role.value === 'Assistant' &&
                            <ChatMessageAssistant
                              messageIndex={index}
                              state={m as any}
                              botName={botInfoState?.item?.title?.value}
                              botLogo={botInfoState?.item?.logo?.value}
                              enableFeedBackGathering={botInfoState?.item?.enableFeedBackGathering?.value ?? false}
                              isBusy={testStateIsBusy}
                              onSendMessage={(text, signal) => {
                                addNewMessage(text, signal, state, () => { });
                                onSendMessage(text, signal)
                              }}
                              onRewriteAnswer={index === (state.messages.length - 1) ? () => {
                                let lastIdx = state.messages.length - 1;
                                while (lastIdx > 0) {
                                  const lastMsg = state.messages[lastIdx]
                                  if (lastMsg.role.value === 'System' || lastMsg.role.value === 'User') {
                                    break;
                                  }
                                  state.messages[lastIdx].set(none)
                                  lastIdx = lastIdx - 1;
                                }
                                onSendMessage(">{\"action\": \"rewrite\"}", AbortSignal.timeout(300000))
                              } : undefined}
                              onMessageClick={hideDebugPanel ? undefined : () => onShowMessageDetail(m.value as any)}
                              onSendFeedback={sendFeedback}
                            />
                          }

                          {index === (state.messages.length - 1) &&
                            <ChatMessageSuggestionsCard
                              key={`suggestionscard-${index}`}
                              state={state}
                              message={m.value as any}
                              onFinish={onSendMessage}
                              addNewMessage={addNewMessage}
                            />
                          }
                        </Stack>
                      </Box>
                    )
                  })}
                  {state.messages.length === 0 &&
                    <Center h={200} mx="auto">
                      <Stack>
                        <Text size="md">{description ?? t("Send a message to start the conversation")}</Text>
                        <Center>
                          <Send color="gray" size={48} />
                        </Center>
                      </Stack>
                    </Center>
                  }

                  <div className={classes.spacingToChatInput}></div>
                </Stack>
              </Container>
            </Box>
            <div className={classes.floatingChatWrapper}>
              <ChatSendMessage
                state={state}
                onFinish={onSendMessage}
                onRestart={onRestartChat}
                addNewMessage={addNewMessage}
                isBusy={testStateIsBusy}
                uploadFiles={allowPersonalCollection && uploadFiles}
                botInfo={botInfoState?.item?.value as BotBasicInfoItem}
                isDebug={!hideDebugPanel}
              />
            </div>
          </Card>
        </Grid.Col>
        {!hideDebugPanel && !isMobile &&
          <Grid.Col span="auto">
            <Card withBorder h='100%'>
              <Editor
                width="100%"
                height="100%"
                options={customMonacoOptions}
                language="json"
                value={codeDetail}
                theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
              />
            </Card>
          </Grid.Col>
        }
      </Grid>
      {showSaveModal &&
        <Modal opened={showSaveModal} onClose={onCloseSaveModal} title={t("Save conversation")}>
          <Stack>
            <TextInput
              data-autofocus
              required
              label={t("Title")}
              value={conversationInfoState.conversationInfo.title.value}
              onChange={(event) => conversationInfoState.conversationInfo.title.set(event.currentTarget.value)}
              rightSection={testStateIsBusy ? <Loader mr="xs" type="dots" size="sm" color="var(--mantine-primary-color-6)" /> : undefined}
            />
            <BotCollectionSelector
              label={t("Collection") as string}
              width='100%'
              required
              botId={id as string}
              value={conversationInfoState.conversationInfo.collectionIdOrReference?.value}
              onChange={(value) => conversationInfoState.conversationInfo.collectionIdOrReference.set(value)}
              onlyWithWritePermissions
              selectFirstValue
            />
            <Textarea
              label={t('Description')}
              rows={3}
              value={conversationInfoState.conversationInfo.description.value}
              onChange={(event) => conversationInfoState.conversationInfo.description.set(event.currentTarget.value)}
            />

            <Group align="center" justify="flex-end" wrap="nowrap" mt="md">
              <Button variant="default" onClick={onCloseSaveModal}>
                {t("Cancel")}
              </Button>
              <Button onClick={onSaveConversation}>
                {t("Save")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      }
    </Stack>
  );
};

export default ChatComponent;

import DataStore, { QueryResult } from "src/core/stores/data-store";
import FormStore from "src/core/stores/form-store";
import { isNullOrWhitespace } from "src/core/utils/object";
import { MemberItem } from "./identities";
import { IChatStore, MessageItem, MessageUpdateItem, ResponseItem, TagItem } from "./skills";
import { EntityBasic, ISearchEntitiesStore } from "./entities";

export type DocumentStatus = 'Draft' | 'Published' | 'Retired';
export type DocumentTrainingStatus = 'Pending' | 'Training' | 'Trained' | 'Error';
export type DocumentContentSource = 'Internal' | 'External';
export type SplitMethod = 'Auto' | 'None' | 'Paragraph' | 'Block';

export interface DocumentSummary {
  id: string;
  collectionId: string;
  collectionTitle: string;
  title: string;
  description: string;
  status: DocumentStatus;
  trainingStatus: DocumentTrainingStatus;
  jobReference: string;
  currentSnapshotDateTime?: Date;
  tags: { [key: string]: any };
  properties: { [key: string]: any };
  contentType: string;
  language: string;
  fileName: string;
  modifiedOn: Date;
  reference: string;
}

export interface DocumentItem {
  id: string;
  collectionId: string;
  collectionTitle: string;
  title: string;
  description: string;
  boost: number;
  reference: string;
  audiences: string[];
  currentSnapshot: DocumentSnapshotItem;
  tags: { [key: string]: any };
  dontAdvertise: boolean;
  members: MemberItem[];
}

export interface DocumentSnapshotItem {
  id: string;
  content: string;
  hash: string;
  contentSource: DocumentContentSource;
  contentType: string;
  language: string;
  splitMethod: SplitMethod;
  bootstrapIntents: string[];
  suggestions: string[];
  fileName: string;
  status: DocumentStatus;
  trainingStatus: DocumentTrainingStatus;
  jobReference: string;
  properties: { [key: string]: any };
  createdOn: Date;
  createdBy: string;
}

export interface NewDocumentForm {
  collectionId: string;
  title: string;
  description: string;
  boost: number;
  reference: string;
  audiences: string[];
  language: string;
  tags: TagItem[];
  dontAdvertise: boolean;
  contentSource: DocumentContentSource;
  splitMethod: SplitMethod;
}

export interface NewDocumentFromWizard {
  title: string;
  description: string;
  boost: number;
  reference: string;
  audiences: string[];
  language: string;
  tags: TagItem[];
  dontAdvertise: boolean;
  splitMethod: SplitMethod;
  fileSize: number;
  operationStatus: "Pending" | "Creating" | "Success" | "Error";
  operationResult: string;
}

export interface NewDocumentsFromWizard {
  contentSource: DocumentContentSource;
  collectionId: string;
  audiences: string[];
  language: string;
  tags: TagItem[];
  dontAdvertise: boolean;
  splitMethod: SplitMethod;
  documents: NewDocumentFromWizard[];
  train: boolean;
  publish: boolean;
}

export interface CreateDocumentItem {
  collectionId: string;
  title: string;
  description: string;
  boost: number;
  reference: string;
  audiences: string[];
  language: string;
  tags: { [key: string]: any };
  dontAdvertise: boolean;
  contentSource: DocumentContentSource;
  content: string;
  splitMethod: SplitMethod;
  train: boolean;
  publish: boolean;
}

export interface DocumentFileInfo {
  url: string;
  size: number;
  FileName: string;
}

export interface UpsertDocumentSnapshotItem {
  id: string;
  title: string;
  description: string;
  boost: number;
  reference: string;
  content: string;
  contentSource: DocumentContentSource;
  contentType?: string;
  splitMethod: SplitMethod;
  language: string;
  audiences: string[];
  bootstrapIntents: string[];
  suggestions: string[];
  tags: { [key: string]: any };
  properties: { [key: string]: any };
  dontAdvertise: boolean;
  train: boolean;
  publish: boolean;
}

export interface DocumentSnapshotSummary {
  id: string;
  documentId: string;
  hash: string;
  status: DocumentStatus;
  trainingStatus: DocumentTrainingStatus;
  createdOn: Date;
  createdBy: string;
  isCurrent: boolean;
}

export interface DocumentSnapshotFragmentItem {
  id: string;
  snapshotId: string;
  usage: string;
  title: string;
  content: string;
  contentType: string;
  contentSource: string;
  intents: string[];
  parentDocuimentId: number;
  properties: { [key: string]: any };
}

export interface DownloadDocumentContentWithInfo {
  title: string;
  url: string;
  data: any;
}

export class DocumentSummaryStore extends DataStore<DocumentSummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/documents`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollection(collectionId: string | undefined) {
    if (isNullOrWhitespace(collectionId)) {
      this.baseUrl = `${this._serviceUri}/api/documents`;
    } else {
      this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/documents`;
    }
  }
}

export class DocumentItemStore extends FormStore<DocumentItem, CreateDocumentItem> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollection(collectionId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/documents`;
  }

  public async uploadData(file: File) {
    return await this.handleCallAsync(async () => {
      const formData = new FormData();
      formData.append('file', file);
      const result = await this.httpService.post<FormData, DocumentFileInfo>(`${this.baseUrl}/upload`, formData, { 'Content-Type': 'multipart/form-data' });
      return result.data;
    });
  }

  public async getDownloadDataUri(documentId: string, fileExtension: string) {
    return `${this.baseUrl}/${documentId}/download?access_token=${await this.httpService.accessToken()}&pdfVersion=${fileExtension === 'pdf'}`;
  }

  public async downloadData(documentId: string, fileExtension: string) {
    window.location.href = await this.getDownloadDataUri(documentId, fileExtension);
  }

  public async generateTokenForDocument(documentId: string) {
    return await this.handleCallAsync(async () => {
      const result = await this.httpService.post<null, string>(`${this.baseUrl}/${encodeURIComponent(documentId)}/token`, null);
      return result.data;
    });
  }

  public async createWithoutFile(body: CreateDocumentItem) {
    const response = await this.create(body);
    if (response) {
      this._state.set((s) => {
        s.item = response;
        return s;
      });
    }
    return response;
  }

  public async createWithFile(body: CreateDocumentItem, file: File) {
    return await this.handleCallAsync(async () => {
      const formData = new FormData();
      formData.append('collectionId', body.collectionId);
      formData.append('audiences', JSON.parse(JSON.stringify(body.audiences)));
      formData.append('boost', JSON.parse(JSON.stringify(body.boost)));
      formData.append('content', body.content);
      formData.append('language', body.language);
      formData.append('contentSource', body.contentSource);
      formData.append('description', body.description);
      formData.append('dontAdvertise', JSON.parse(JSON.stringify(body.dontAdvertise)));
      formData.append('reference', body.reference);
      formData.append('tags', JSON.stringify(body.tags));
      formData.append('title', body.title);
      formData.append('file', file);
      formData.append('splitMethod', body.splitMethod);
      formData.append('train', JSON.stringify(body.train || false));
      formData.append('publish', JSON.stringify(body.publish || false));
      const result = await this.httpService.post<FormData, DocumentItem>(`${this.baseUrl}`, formData, { 'Content-Type': 'multipart/form-data' });
      this._state.set((s) => {
        s.item = result.data;
        return s;
      });
      return result.data;
    });
  }

  public async saveSnapshot(id: string, item: UpsertDocumentSnapshotItem) {
    return await this.handleCallAsync(async () => {
      const result = await this.httpService.put<UpsertDocumentSnapshotItem, DocumentItem>(`${this.baseUrl}/${encodeURIComponent(id)}`, item);
      this._state.set((s) => {
        s.item = result.data;
        return s;
      });
      return result.data;
    });
  }

  public async saveSnapshotWithFile(id: string, body: UpsertDocumentSnapshotItem, file: File) {
    return await this.handleCallAsync(async () => {
      const formData = new FormData();
      formData.append('id', body.id);
      formData.append('audiences', JSON.parse(JSON.stringify(body.audiences)));
      formData.append('boost', JSON.parse(JSON.stringify(body.boost)));
      formData.append('content', body.content);
      formData.append('bootstrapIntents', JSON.parse(JSON.stringify(body.bootstrapIntents || [])));
      formData.append('suggestions', JSON.parse(JSON.stringify(body.suggestions || [])));
      formData.append('language', body.language);
      formData.append('contentSource', body.contentSource);
      if (body.contentType) {
        formData.append('contentType', body.contentType);
      }
      formData.append('description', body.description);
      formData.append('dontAdvertise', JSON.parse(JSON.stringify(body.dontAdvertise)));
      formData.append('reference', body.reference);
      formData.append('tags', JSON.stringify(body.tags));
      formData.append('title', body.title);
      formData.append('file', file);
      formData.append('splitMethod', body.splitMethod);
      formData.append('properties', JSON.stringify(body.properties));
      formData.append('train', JSON.stringify(body.train || false));
      formData.append('publish', JSON.stringify(body.publish || false));
      const result = await this.httpService.put<FormData, DocumentItem>(`${this.baseUrl}/${encodeURIComponent(id)}`, formData, { 'Content-Type': 'multipart/form-data' });
      this._state.set((s) => {
        s.item = result.data;
        return s;
      });
      return result.data;
    });
  }

  public async trainSnapshot(collectionId: string, documentId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/snapshots/${snapshotId}/train`, {});
    });
  }

  public async publish(id: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/publish`, null);
    });
  }

  public async retire(id: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/retire`, null);
    });
  }

  public async loadConversation(botId: string, conversationId: string, collectionIdOrReference?: string) {
    let urlService = `${this._serviceUri}/api/bots/${encodeURIComponent(botId)}/conversation/${conversationId}`;
    if (collectionIdOrReference) {
      urlService += `?collectionIdOrReference=${collectionIdOrReference}`;
    }
    await this.handleCallAsync(async () => {
      let response = await this.httpService.get<DocumentItem>(urlService);
      this._state.set((s) => {
        s.item = response.data;
        return s;
      });
    });
  }
}

export class DocumentSnapshotHistoryStore extends DataStore<DocumentSnapshotSummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollectionAndDocument(collectionId: string, documentId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/history`;
  }

  public async deleteSnapshot(collectionId: string, documentId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.delete<any, any>(`${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/snapshots/${snapshotId}`);
    });
  }

  public async trainSnapshot(collectionId: string, documentId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/snapshots/${snapshotId}/train`, {});
    });
  }

  public async publishSnapshot(collectionId: string, documentId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/snapshots/${snapshotId}/publish`, {});
    });
  }

  public async retireSnapshot(collectionId: string, documentId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/snapshots/${snapshotId}/retire`, {});
    });
  }

  public async createNewVersionFromSnapshot(collectionId: string, documentId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/snapshots/${snapshotId}/createversion`, {});
    });
  }
}

export class DocumentSnapshotPassageSummaryStore extends DataStore<DocumentSnapshotFragmentItem> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setSnapshot(collectionId: string, documentId: string, snapshotId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/snapshots/${snapshotId}/passages`;
  }
}

export class DocumentSnapshotPassageItemStore extends FormStore<DocumentSnapshotFragmentItem, DocumentSnapshotFragmentItem> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`);
    this._serviceUri = `${baseUrl}`;
  }

  public setSnapshot(collectionId: string, documentId: string, snapshotId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/documents/${documentId}/snapshots/${snapshotId}/passages`;
  }
}

export class DocumentViewerStore extends FormStore<null, null> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/documents`);
  }

  public async getDownloadDataUri(documentId: string, token: string) {
    if (token) {
      return `${this.baseUrl}/${documentId}/viewer/download?token=${token}`;
    }
    return `${this.baseUrl}/${documentId}/viewer/download?access_token=${await this.httpService.accessToken()}`;
  }

  public async getDownloadDataToBlob(documentId: string, token: string): Promise<DownloadDocumentContentWithInfo> {
    const uri = await this.getDownloadDataUri(documentId, token);

    return await this.handleCallAsync(async () => {
      const response = await this.httpService.get<any>(`${uri}`, { responseType: 'blob' });

      return {
        title: decodeURIComponent(response.headers["x-title"] ?? ""),
        url: decodeURIComponent(response.headers["x-url"] ?? ""),
        data: response.data
      };
    });
  }
}

export class AskDocumentStore extends FormStore<ResponseItem, ResponseItem> implements IChatStore {
  private _token: string | undefined;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/documents`);
    this._token = undefined;
  }

  public setToken(token: string) {
    this._token = token;
  }

  public async ask(documentId: string, snapshotId: string, conversationId: string, body: any) {
    return await this.handleCallAsync(async () => {
      let response = await this.httpService.post<any, ResponseItem>(`${this.baseUrl}/${encodeURIComponent(documentId)}/viewer/ask?conversationId=${conversationId}&token=${this._token as string}`, body, null, true);
      return response.data;
    });
  }

  public async *ask_streamed(documentId: string, snapshotId: string | undefined, conversationId: string, body: any, signal: AbortSignal): AsyncGenerator<MessageUpdateItem> {
    this._state.set((s) => {
      s.errorMessage = undefined;
      s.isBusy = true;
      return s;
    });
    try {
      for await (const item of this.httpService.post_streamed<any, MessageUpdateItem>(`${this.baseUrl}/${encodeURIComponent(documentId)}/viewer/ask/streamed?conversationId=${conversationId}&token=${this._token as string}`, body, signal)) {
        yield item;
      }
    } finally {
      this._state.set((s) => {
        s.isBusy = false;
        return s;
      });
    }
  }

  public async saveConversation(skillId: string, conversationId: string) {
    throw new Error("Not implemented");
  }

  public async generateConversationInfo(skillId: string, conversationId: string) {
    throw new Error("Not implemented");
  }
}

export class SearchDocumentEntitiesStore extends DataStore<EntityBasic> implements ISearchEntitiesStore {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setCollection(collectionId: string) {
    this.baseUrl = `${this._serviceUri}/api/collections/${collectionId}/documents`;
  }

  public async get(id: string, name: string) {
    await this.handleCallAsync(async () => {
      const response = await this.httpService.get<QueryResult<EntityBasic>>(`${this.baseUrl}/${encodeURIComponent(id)}/entities?name=${name}`);
      this._state.set((s) => {
        s.items = response.data.items || (response.data as any).value || [];
        s.count = response.data.count || (response.data as any)['@odata.count'] || 0;
        return s;
      });
    });
  }
}
import { State, none, useHookstate } from '@hookstate/core';
import { ActionIcon, Box, Button, Center, Group, Input, SegmentedControl, Select, Stack, Text, rem } from '@mantine/core';
import { IconTrash, IconUser, IconUsers, IconWorld } from '@tabler/icons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IdentitySelector } from './identity-selector';
import { AccessMode, MemberEditorState, MemberItem, MemberPrivileges } from 'src/stores/identities';
import { AppConfiguration, authStatus } from 'src/core/services/authentication-service';
import { BotMemberPrivileges } from 'src/stores/bots';
import { UserIsOwner } from 'src/utils/permissions';
import { container } from 'src/inversify.config';

const MembersEditor: FC<{
  state: State<MemberEditorState>;
  readOnly?: boolean;
  mode?: 'Normal' | 'Bot';
  creation?: boolean;
}> = ({ state, readOnly, mode = 'Normal', creation }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const isOwner = authStatus?.user.value?.isOwner || UserIsOwner(state?.members?.value as MemberItem[]);
  const sharingAccessMode = container.get<AppConfiguration>("AppConfiguration").sharingAccessMode;

  const getPrivilegesData = () => {
    if (mode === 'Normal') {
      const privileges = isOwner ? ['Owner', 'Contributor', 'Reader'] as MemberPrivileges[] : ['Contributor', 'Reader'] as MemberPrivileges[];
      return privileges.map(p => ({ value: p, label: t(`${p}`) }));
    }
    if (mode === 'Bot') {
      const privileges = isOwner ? ['Owner', 'Contributor', 'User', 'Reader', 'ImpersonatedUser', 'Tester'] as BotMemberPrivileges[]
        : ['Contributor', 'User', 'Reader', 'ImpersonatedUser', 'Tester'] as BotMemberPrivileges[];
      return privileges.map(p => ({ value: p, label: t(`${p}`) }));
    }
  }

  return (
    <Stack gap="lg" justify="flex-start">
      <Input.Wrapper
        required
        label={t("Access mode")}>
        <Stack>
          <SegmentedControl
            data={[
              {
                value: 'Everyone',
                label: (
                  <Center>
                    <IconWorld style={{ width: rem(16), height: rem(16) }} />
                    <Box ml={10}>{t("Public access")}</Box>
                  </Center>
                ),
                disabled: sharingAccessMode !== 'OrganizationWide'
              },
              {
                value: 'MembersOnly',
                label: (
                  <Center>
                    <IconUsers style={{ width: rem(16), height: rem(16) }} />
                    <Box ml={10}>{t("Members only")}</Box>
                  </Center>
                ),
              },
            ]}
            value={scopedState.accessMode.value}
            onChange={(value) => scopedState.accessMode.set(value as AccessMode)}
          />
        </Stack>
      </Input.Wrapper>

      <Input.Wrapper
        label={t("Members")}
        description={!readOnly && sharingAccessMode !== 'OwnerOnly' ? <Text size='xs'>{t('To add click on the Add member button, you can write to search or select it from the list')}</Text> : undefined}>
        {scopedState.members.map((member: State<MemberItem>, index: number) => (
          <>
            {(isOwner || creation) &&
              <Group key={index} mt="xs">
                <IdentitySelector
                  required
                  width="100%"
                  style={{ flex: 1 }}
                  value={member.identity.value}
                  onChange={(value) => member.identity.set(value)}
                  readOnly={readOnly}
                />
                <Select
                  style={{ width: '150px' }}
                  allowDeselect={false}
                  data={getPrivilegesData()}
                  value={member.privileges.value}
                  onChange={(value) => member.privileges.set(value as string)}
                  readOnly={readOnly}
                />
                {!readOnly &&
                  <ActionIcon
                    color="red"
                    variant='subtle'
                    onClick={() => member.set(none)}>
                    <IconTrash />
                  </ActionIcon>
                }
              </Group>
            }
            {!isOwner && !creation &&
              <Group key={index} mt="xs">
                <Text>{member.identity.value} ({t(member.privileges.value)})</Text>
              </Group>
            }
          </>
        ))}
      </Input.Wrapper>
      {!readOnly && (isOwner || creation) && sharingAccessMode !== 'OwnerOnly' &&
        <Group justify="center">
          <Button
            variant='default'
            size='sm'
            onClick={() => scopedState.members[scopedState.members.length].set({ identity: '', privileges: 'Contributor' } as never)}
            leftSection={<IconUser />}>
            {t("Add member")}
          </Button>
        </Group>
      }
    </Stack>
  );
};

export default MembersEditor;

import { useHookstate, none } from "@hookstate/core";
import { Stack, Group, Input, TextInput } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import { LanguageSegmentedControl } from "./language-segmented-control";

const LocalizedTexInput: FC<{
  value: { [key: string]: string };
  onChange?: (value: { [key: string]: string }) => void;
  label?: string;
  edit?: boolean;
  required?: boolean;
  disabled?: boolean;
}> = ({ value, onChange, label = "", edit = true, required = false, disabled = false }) => {
  const scopedState = useHookstate(value ? JSON.parse(JSON.stringify(value)) as { [key: string]: string } : {});
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value && Object.keys(scopedState?.value).length > 0 ? Object.keys(scopedState?.value)[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    scopedState.set(value ? JSON.parse(JSON.stringify(value)) as { [key: string]: string } : {});
    if (!Object.keys(scopedState?.value).includes(selectedLanguage)) {
      setSelectedLanguage(scopedState?.value && Object.keys(scopedState?.value).length > 0 ? Object.keys(scopedState?.value)[0] : defaultAllowedLanguages[0]);
    }
  }, [JSON.stringify(value)])

  const onDeleleLanguage = (lang: string) => {
    scopedState[lang].set(none);
    onChange?.(JSON.parse(JSON.stringify(scopedState.value)));
  }

  const onAddLanguage = (lang: string) => {
    scopedState[lang].set('');
    onChange?.(JSON.parse(JSON.stringify(scopedState.value)));
  }

  const onChangeText = (lang: string, value: string) => {
    scopedState[lang].set(value);
    onChange?.(JSON.parse(JSON.stringify(scopedState.value)));
  }

  return (
    <Stack gap={5}>
      <Group justify='space-between'>
        <Input.Label required={required}>{label}</Input.Label>
        <LanguageSegmentedControl
          editable={edit && !disabled}
          data={scopedState?.value ? Object.keys(scopedState?.value) : undefined}
          language={selectedLanguage}
          onChange={setSelectedLanguage}
          onDelete={onDeleleLanguage}
          onAdd={onAddLanguage}
        />
      </Group>

       <TextInput
          value={scopedState[selectedLanguage]?.value ?? ''}
          onChange={(event) => onChangeText(selectedLanguage, event.currentTarget.value)}
          readOnly={!edit}
        />
    </Stack>
  );
};

export default LocalizedTexInput;
import { ActionIcon, Alert, Anchor, Card, Group, Select, Stack, Text, Tooltip } from '@mantine/core';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import moment from 'moment';
import { IdentityItemStore, IdentitySummary, IdentitySummaryStore } from 'src/stores/identities';
import EditIdentity from './edit-identity';
import { formatMessage, sleep } from 'src/core/utils/object';
import TableListV2, { TableListV2Model } from 'src/core/ui/table-list/table-list-v2';
import { DataTableSortStatus } from 'mantine-datatable';
import { IconApi, IconUsers, IconUser, IconAlertCircle, IconAdjustments } from '@tabler/icons-react';

const IdentitiesList: FC<{}> = () => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(IdentitySummaryStore), []);
  const state = store.state;
  const itemStore = useMemo(() => container.get(IdentityItemStore), []);
  const itemState = itemStore.state;
  const itemStateErrorMessage = itemState.errorMessage.value;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedIdentityName, setSelectedIdentityName] = useState<string | undefined>(undefined);
  const [identityTypeFilter, setIdentityTypeFilter] = useState<string | undefined>(undefined);
  const [query, setQuery] = useState<Query>(undefined as any);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });

  useEffect(() => {
    if (query) {
      load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const load = async (query: Query) => {
    store.load(query);
  };

  useEffect(() => {
    const newQuery = {
      ...query,
      //orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
      skip: 0,
      parameters: {
        identityTypeFilter: identityTypeFilter,
        orderByField: sortStatus.columnAccessor,
        orderByDirection: sortStatus.direction
      },
    } as Query;
    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityTypeFilter]);

  const onConfirmDelete = async (selectedKeys: IdentitySummary[]) => {
    if (selectedKeys && selectedKeys.length > 0) {
      selectedKeys.forEach((key) => { itemStore.delete(key.name); });
      await sleep(500);
      load(query);
    }
  };

  const model: TableListV2Model<IdentitySummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'name',
        title: t('Name'),
        sortable: true,
        render: (item: IdentitySummary) => (
          <Group gap="xs" align="center">
            {item.hasApiKey ? <Tooltip label={t("Api Key")}>
              <ActionIcon variant='transparent' size="sm">
                <IconApi size={20} />
              </ActionIcon>
            </Tooltip>
              : item.isGroup ?
                <Tooltip label={t("Group")}>
                  <ActionIcon variant='transparent' size="sm">
                    <IconUsers size={20} />
                  </ActionIcon>
                </Tooltip>
                :
                <Tooltip label={t("User")}>
                  <ActionIcon variant='transparent' size="sm">
                    <IconUser size={20} />
                  </ActionIcon>
                </Tooltip>
            }
            <Text>
              <Tooltip label={t("Edit identity")}>
                <Anchor onClick={() => { setSelectedIdentityName(item.name); setShowCreateModal(true); }}>{item.name}</Anchor>
              </Tooltip>
            </Text>
          </Group>
        )
      },
      {
        accessor: 'displayName',
        title: t('Full name'),
        sortable: true,
        render: (item: IdentitySummary) => (
          <Text>{item.displayName}</Text>
        )
      },
      {
        accessor: 'role',
        title: t('Role'),
        sortable: true,
        render: (item: IdentitySummary) => (
          <Text>{t(item.role)}</Text>
        )
      },
      {
        accessor: 'modifiedOn',
        title: t('Modified on'),
        sortable: true,
        render: (item: IdentitySummary) => (
          <Tooltip label={<Text size='xs'>{moment(item.modifiedOn).toString()}</Text>}>
            <Text>{moment(item.modifiedOn).fromNow()}</Text>
          </Tooltip>
        )
      }
    ]
  };

  return (
    <Card withBorder>
      <Stack gap="md">
        {itemStateErrorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => itemStore.clearError()}>
            <Text>{formatMessage(itemStateErrorMessage)}</Text>
          </Alert>
        )}
        <TableListV2
          idAccessor='name'
          model={model}
          onQueryChanged={setQuery}
          onRefresh={() => load(query)}
          striped
          highlightOnHover
          sortable
          sortStatus={sortStatus}
          onSortStatusChanged={setSortStatus}
          showNewIcon
          onNewItemClick={() => { setSelectedIdentityName(undefined); setShowCreateModal(true); }}
          selectable
          onDeleteSelectedClick={(selectedKeys: IdentitySummary[]) => onConfirmDelete(selectedKeys)}
          rightToolBarRender={
            <Group gap="xs" align="center">
              <Select
                clearable
                style={{ width: 240 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Identity type') as string}
                data={[
                  { value: 'user', label: t('Users') as string },
                  { value: 'group', label: t('Groups') as string },
                ]}
                onChange={(value) => setIdentityTypeFilter(value as string)}
                value={identityTypeFilter}
              />
            </Group>
          }
        />
      </Stack>
      {showCreateModal &&
        <EditIdentity
          key="edit-identity-modal"
          opened={showCreateModal}
          onClose={() => { setShowCreateModal(false); setSelectedIdentityName(undefined); }}
          identityName={selectedIdentityName}
          onFinish={() => { setShowCreateModal(false); setSelectedIdentityName(undefined); load(query); }}
        />
      }
    </Card>
  );
};

export default IdentitiesList;

import { Accordion, ActionIcon, Center, useMantineColorScheme, useMantineTheme, Box, Badge, Image } from '@mantine/core';
import React, { FC } from 'react';
import classes from './chat-message-reference.module.css';
import { IconExternalLink, IconMessageForward } from '@tabler/icons-react';
import MDEditor from '@uiw/react-md-editor';
import { isNullOrWhitespace } from '../../core/utils/object';

const ChatMessageReference: FC<{
  label: string;
  icon?: React.ReactNode;
  thumbnail?: string;
  text?: string;
  link?: string;
  onSendMessage?: () => void;
}> = ({ label, icon, text, link, onSendMessage, thumbnail }) => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  const transformLink = (link: string) => {
    if (link.startsWith("#")) {
      link = link.replace(/\+/g, '%20');
      return `javascript:ask('${decodeURIComponent(link).slice(1)}')`;
    }
    return `javascript:window.open('${link}', '_blank')`;
  }

  return (
    text ?
      <Accordion chevronPosition="left" classNames={classes}>
        <Accordion.Item value={label}>
          <Center>
            <Accordion.Control>
              <Box>
                <Box mt={2} mr={5} style={{ float: 'left' }}>
                  {icon}
                </Box>
                {label}
              </Box>
            </Accordion.Control>
            {link &&
              <ActionIcon variant="subtle" color="gray" component="a" target="_blank" href={link} mr="xs">
                <IconExternalLink size="1rem" />
              </ActionIcon>
            }
            {onSendMessage &&
              <ActionIcon variant="subtle" color="gray" onClick={onSendMessage} mr="xs">
                <IconMessageForward size="1rem" />
              </ActionIcon>
            }
          </Center>
          <Accordion.Panel>
            {!isNullOrWhitespace(thumbnail) && <Image radius="md" h={80} w="auto" fit="contain" src={thumbnail} style={{ float: 'left', marginRight: 10 } } />}
            <MDEditor.Markdown
              transformLinkUri={transformLink}
              style={{ backgroundColor: 'transparent', color: colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.dark[7] }}
              source={text}
              />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      :
      <>
        {link &&
          <Badge variant="outline" component="a" target="_blank" href={link} radius="sm"
            rightSection={<IconExternalLink size={10} />} style={{ cursor: 'pointer' }} styles={{ root: { height: 'auto' }, label: { whiteSpace: 'normal' } }}>
            {label}
          </Badge>
        }
        {onSendMessage &&
          <Badge variant="outline" radius="sm"
            style={{ cursor: 'pointer' }} styles={{ root: { height: 'auto' }, label: { whiteSpace: 'normal' } }}
            onClick={onSendMessage}>
            {label}
          </Badge>
        }
      </>
  );
};

export default ChatMessageReference;

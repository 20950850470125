import { Anchor, Card, Group, Select, Stack, Text, Tooltip } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { container } from "src/inversify.config";
import { Query } from "src/core/stores/data-store";
import { sleep } from "src/core/utils/object";
import { CollectionEntitySummary, CollectionEntitySummaryStore } from "src/stores/collections";
import moment from "moment";
import CreateCollectionEntity from "./create-collection-entity";
import EditCollectionEntity from "./collection-entity-edit";
import TableListV2, { TableListV2Model } from "src/core/ui/table-list/table-list-v2";
import { DataTableSortStatus } from "mantine-datatable";
import { IconTags, IconAdjustments } from "@tabler/icons-react";

const CollectionEntitiesCard: FC<{
  collectionId: string;
  canContribute: boolean;
  edit?: boolean;
}> = ({ collectionId, canContribute, edit = false }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(CollectionEntitySummaryStore), []);
  const state = store.state;
  const [query, setQuery] = useState<Query>(undefined as any);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [typeFilter, setTypeFilter] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });

  useEffect(() => {
    if (collectionId) {
      store.setCollection(collectionId);
      const newQuery = {
        ...query,
        skip: 0,
        parameters: {
          collectionId: collectionId,
          orderByField: sortStatus.columnAccessor,
          orderByDirection: sortStatus.direction
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  useEffect(() => {
    if (query) {
      store.load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const newQuery = {
      ...query,
      skip: 0,
      parameters: {
        typeFilter: typeFilter,
      },
    } as Query;
    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilter]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onReload = () => {
    store.load(query)
  };

  const onConfirmDelete = async (selectedKeys: CollectionEntitySummary[]) => {
    if (selectedKeys && selectedKeys.length > 0) {
      selectedKeys.forEach((key) => { store.delete(key.id); });
      await sleep(500);
      onReload();
    }
  };

  const model: TableListV2Model<CollectionEntitySummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'name',
        title: t('Name'),
        sortable: true,
        render: (item: CollectionEntitySummary) => (
          <Tooltip label={edit ? t("Edit entity") : t("View entity")}>
            <Anchor onClick={() => { setSelectedItem(item.id); setShowEditModal(true); }}>{item.name}</Anchor>
          </Tooltip>
        )
      },
      {
        accessor: 'type',
        title: t('Type'),
        sortable: true,
        render: (item: CollectionEntitySummary) => (
          <Text>{t(item.type)}</Text>
        )
      },
      {
        accessor: 'createdBy',
        title: t('Created by'),
        sortable: true,
        render: (item: CollectionEntitySummary) => (
          <Text>{item.createdBy}</Text>
        )
      },
      {
        accessor: 'createdOn',
        title: t('Created on'),
        sortable: true,
        render: (item: CollectionEntitySummary) => (
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.createdOn).toString()}</Text>}>
            <Text>{moment(item.createdOn).fromNow()}</Text>
          </Tooltip>
        )
      },
      {
        accessor: 'modifiedBy',
        title: t('Modified by'),
        sortable: true,
        render: (item: CollectionEntitySummary) => (
          <Text>{item.modifiedBy}</Text>
        )
      },
      {
        accessor: 'modifiedOn',
        title: t('Modified on'),
        sortable: true,
        render: (item: CollectionEntitySummary) => (
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.modifiedOn).toString()}</Text>}>
            <Text>{moment(item.modifiedOn).fromNow()}</Text>
          </Tooltip>
        )
      }
    ]
  };

  return (
    <Stack gap="md">
      <Card withBorder>
        <TableListV2
          model={model}
          onQueryChanged={setQuery}
          onRefresh={onReload}
          striped
          highlightOnHover
          sortable
          sortStatus={sortStatus}
          onSortStatusChanged={setSortStatus}
          leftToolBarRender={
            <Group justify='space-between'>
              <IconTags />
              <Text fw={500}>{t('Entities')}</Text>
            </Group>
          }
          showNewIcon={canContribute && edit}
          onNewItemClick={() => setShowCreateModal(true)}
          selectable={canContribute && edit}
          onDeleteSelectedClick={(selectedKeys: CollectionEntitySummary[]) => onConfirmDelete(selectedKeys)}
          rightToolBarRender={
            <Group gap="xs" align="center">
              <Select
                clearable
                style={{ width: 240 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Type') as string}
                data={[
                  { value: 'Synonyms', label: t('Synonyms') as string },
                  { value: 'Regex', label: t('Regex') as string },
                ]}
                onChange={(value) => setTypeFilter(value as string)}
                value={typeFilter}
              />
            </Group>
          }
        />
        {showCreateModal &&
          <CreateCollectionEntity
            opened={showCreateModal}
            onClose={() => { setShowCreateModal(false); onReload(); }}
            collectionId={collectionId} />
        }
        {showEditModal && selectedItem &&
          <EditCollectionEntity
            opened={showEditModal}
            onClose={() => { setShowEditModal(false); setSelectedItem(undefined); onReload(); }}
            edit={edit}
            collectionId={collectionId}
            entityId={selectedItem as string}
          />
        }
      </Card>
    </Stack>
  );
};

export default CollectionEntitiesCard;
import { FC } from 'react';
import { Routes, Route } from "react-router-dom";
import ServiceConnectionList from './serviceconnections-list';

const ServiceConnectionIndex: FC = () => {
  return (
    <Routes>
      <Route index element={<ServiceConnectionList />} key={window.location.pathname} />
    </Routes>
  );
}

export default ServiceConnectionIndex;

import { Alert, Button, Group, Input, LoadingOverlay, Modal, Stack, Text, TextInput, useMantineColorScheme } from "@mantine/core";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { container } from "src/inversify.config";
import { formatMessage } from "src/core/utils/object";
import { CrawlerPatchItem, CrawlerPatchesItemStore, NewCrawlerPatchItem } from "src/stores/crawlers";
import Editor from "@monaco-editor/react";
import jsyaml from "js-yaml";
import AppContext from "src/services/app-context";
import { IconAlertCircle, IconDeviceFloppy } from "@tabler/icons-react";
import { monacoOptions } from "src/configurations/editor-config-jinja";

const EditCrawlerPatch: FC<{
  opened: boolean;
  collectionId: string;
  crawlerId: string;
  patchId?: string;
  onClose: () => void;
  edit?: boolean;
}> = ({ opened, collectionId, crawlerId, patchId, onClose, edit = false }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const store = useMemo(() => container.get(CrawlerPatchesItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const [yamlContent, setYamlContent] = useState<string>('');
  const { setIsDirty } = useContext(AppContext);

  const form = useForm<NewCrawlerPatchItem>({
    initialValues: {
      crawlerId: crawlerId,
      applyTo: '',
      title: '',
      patch: ''
    },
    validateInputOnBlur: true,
    validate: {
      applyTo: (value) => (value.length === 0 ? t('Invalid value') : null)
    },
  });

  let customMonacoOptions = { ...monacoOptions };
  customMonacoOptions.readOnly = !edit;

  useEffect(() => {
    if (collectionId && crawlerId && patchId) {
      store.setCollectionAndCrawler(collectionId, crawlerId);
      load(patchId);
    }
    else {
      form.reset();
    }
  }, [collectionId, crawlerId, patchId]);

  const load = async (patchId: string) => {
    await store.load(patchId);
    if (!state.errorMessage.value) {
      setFormValues();
    }
  }

  const setFormValues = () => {
    if (state?.item?.value) {
      form.setFieldValue('crawlerId', state.item.value.crawlerId);
      form.setFieldValue('applyTo', state.item.value.applyTo);
      form.setFieldValue('title', state.item.value.title);
      form.setFieldValue('patch', state.item.value.patch);
      setYamlContent(jsyaml.dump(JSON.parse(state.item.value.patch)));
    }
  }

  const isValid = () => {
    return form.isValid() && yamlContent.length > 0;
  }

  const onSubmit = async () => {
    if (isValid()) {
      store.setCollectionAndCrawler(collectionId, crawlerId);
      let response = null;
      if (patchId) {
        const updateItem = {
          id: patchId,
          crawlerId: crawlerId,
          applyTo: form.values.applyTo,
          title: form.values.title,
          patch: JSON.stringify(jsyaml.load(yamlContent), null, 2),
        } as CrawlerPatchItem;

        setIsDirty(false);
        response = await store.save(patchId, updateItem);
      }
      else {
        const createItem = {
          crawlerId: crawlerId,
          applyTo: form.values.applyTo,
          title: form.values.title,
          patch: JSON.stringify(jsyaml.load(yamlContent), null, 2),
        } as NewCrawlerPatchItem;
        response = await store.create(createItem);
      }

      if (response) {
        onCloseModal();
      }
    }
  }

  const onCloseModal = () => {
    store.clearError();
    form.reset();
    onClose();
    setIsDirty(false);
  }

  return (
    <Modal
      title={<span>{!patchId ? t("Create patch") : t("Patch detail")}</span>}
      size='xl'
      withCloseButton
      closeOnClickOutside={false}
      onClose={onCloseModal}
      opened={opened}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        {(!patchId || (patchId && state?.item?.value)) &&
          <form onSubmit={form.onSubmit((values: NewCrawlerPatchItem) => onSubmit())} onChange={() => { setIsDirty(true); }}>
            <Stack>
              <TextInput
                required
                readOnly={!edit}
                variant={edit ? 'default' : 'unstyled'}
                label={t('Document Id to patch')}
                {...form.getInputProps('applyTo')}
              />
              <TextInput
                readOnly={!edit}
                variant={edit ? 'default' : 'unstyled'}
                label={t('Title')}
                {...form.getInputProps('title')}
              />

              <Input.Wrapper
                required={edit}
                label={t('Patch')}
                style={{ width: '100%', height: edit ? 'calc(100svh - 420px)' : 'calc(100svh - 360px)' }}>
                <Editor
                  width="100%"
                  height="100%"
                  options={customMonacoOptions}
                  language="yaml"
                  value={yamlContent}
                  onChange={(value) => { setYamlContent(value as string); setIsDirty(true); }}
                  theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                />
              </Input.Wrapper>

              <Group justify="flex-end" mt="xl">
                {edit &&
                  <Button
                    disabled={!isValid()}
                    loading={isBusy}
                    leftSection={<IconDeviceFloppy />}
                    type="submit">
                    {t("Save")}
                  </Button>
                }
              </Group>
            </Stack>
          </form>
        }
      </div>
    </Modal>
  );
};

export default EditCrawlerPatch;
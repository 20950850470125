import { Box, Button, Group, Paper, Stack, Stepper, useMantineTheme, Title, Text, TextInput, Divider, SimpleGrid, Textarea, ActionIcon, LoadingOverlay, Alert, Anchor, Tooltip, Select, Loader, Input, Table, ComboboxItem, ScrollArea, Popover, Center, Container } from '@mantine/core';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import verticalStepperClasses from 'src/pages/vertical-stepper.module.css';
import indexClasses from 'src/pages/index.module.css';
import { LanguageSeletor } from '../language-selector';
import SwitchButton from '../switch-button';
import HeaderContext from 'src/services/header-context';
import { CrawlerItemStore, CrawlerSummary, CrawlerSummaryStore, GetMicrosoft365CrawlerDefaultContent, NewCrawlerItem } from 'src/stores/crawlers';
import { IconAdjustments, IconAlertCircle, IconCircleCheck, IconCircleX, IconFileUnknown, IconMessageChatbot, IconPlus, IconTrash, IconUpload } from '@tabler/icons-react';
import { CreateDocumentItem, DocumentItemStore, NewDocumentFromWizard, SplitMethod } from 'src/stores/documents';
import { Dropzone, FileRejection, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import ProfileIdentities from '../profile-identities';
import { BotContent, BotContentSkill, BotContentUserPreferences, BotItem, BotItemStore, BotLlmOrchestratorType, BotStrategy, GetBotDefaultContent, UpsertBotSnapshotItem, allowedMimeTypes } from 'src/stores/bots';
import { State, useHookstate } from '@hookstate/core';
import { container } from 'src/inversify.config';
import { ClaimsProfileItem, ProfileStore } from 'src/stores/profile';
import { formatBytes, formatMessage, normalizeName, sleep, truncateText } from 'src/core/utils/object';
import { BuiltInServiceConnectionContent, NewServiceConnectionItem, ServiceConnectionItem, ServiceConnectionItemStore, ServiceConnectionSummaryStore } from 'src/stores/serviceconnections';
import { Query } from 'src/core/stores/data-store';
import { CollectionItem, CollectionItemStore } from 'src/stores/collections';
import { AppConfiguration } from 'src/core/services/authentication-service';
import TableListV2, { TableListV2Model } from 'src/core/ui/table-list/table-list-v2';
import EditCrawler from 'src/pages/admin/collections/crawler-edit';
import CreateCrawlerWizard from 'src/pages/admin/collections/create-crawler-wizard';
import { AsEnumerable } from 'linq-es5';
import { BasicMultiselect } from '../basic-multiselect';
import slugify from 'slugify';
import { GetSkillDefaultContent, NewSkillItem, SkillContentStepAction, SkillDialogContent, SkillItem, SkillItemStore, SkillSearchContent, UpsertSkillSnapshotItem } from 'src/stores/skills';
import LocalizedTextArea from '../localized-textarea';

const QuickStartWizardStateMetadataKey: string = "quickStartWizardState";

interface QuickStartWizardForm {
  collectionId?: string,
  step1: {
    completed: boolean,
    botName: string,
    behavior: { [key: string]: string },
    description: { [key: string]: string },
    botId?: string
  },
  step2: {
    completed: boolean,
    enableM365: boolean,
    email: boolean;
    drive: boolean;
    events: boolean;
    emailCrawlDays?: number;
    emailExpirationDays?: number;
    driveCrawlDays?: number;
    driveExpirationDays?: number;
    eventsCrawlDays?: number;
    eventsExpirationDays?: number;
  },
  step3: {
    completed: boolean
  },
  step4: {
    completedWithWarnings: boolean,
    completed: boolean,
    documents: NewDocumentFromWizard[],
    searchSkillId?: string
    greetingsSkillId?: string;
    fallbackSkillId?: string;
  },
  step5: {
    completed: boolean
  }
}

const AudienceMultiSelect: FC<{
  value: string[] | undefined;
  onChange: (value: string[]) => void;
  label?: string;
  readOnly?: boolean;
}> = ({ value, onChange, label, readOnly = false }) => {
  const { t } = useTranslation();
  const defaultAudience = container.get<AppConfiguration>("AppConfiguration").defaultAudience;

  const getAudiencesData = () => {
    let data = [{ value: defaultAudience, label: defaultAudience }] as ComboboxItem[];
    if (value) {
      data = data.concat(value.map(item => ({ label: item, value: item })));
    }

    data = AsEnumerable(data).Distinct(data => data.value).ToArray();

    return data;
  }

  return <BasicMultiselect
    label={label}
    data={getAudiencesData()}
    value={value}
    onChange={onChange}
    placeholder={t("Select audiences") as string}
    creatable
    readOnly={readOnly}
  />
}

const EditableFileRow: FC<{
  index: number;
  state: State<NewDocumentFromWizard>;
  onDeleteFile?: (title: string) => void;
  readOnly?: boolean;
}> = ({ index, state, onDeleteFile, readOnly = false }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);

  const getStatusIcon = (doc: NewDocumentFromWizard) => {
    switch (doc.operationStatus) {
      case 'Success':
        return <IconCircleCheck color="green" size={22} />;
      case 'Error':
        return <IconCircleX color="red" size={22} />;
      // case 'Pending':
      //   return <AlertTriangle color={theme.colors.yellow[5]} size={22} />;
      case 'Creating':
        return <Loader size="sm" />;
    }
  }

  return (
    <Table.Tr key={index}>
      <Table.Td>
        <TextInput
          style={{ flex: 1 }}
          required
          rightSection={<Text size="xs">{formatBytes(scopedState.fileSize.value)}</Text>}
          rightSectionWidth={100}
          value={scopedState.title.value}
          onChange={(event) => scopedState.title.set(event.currentTarget.value)}
          readOnly={readOnly}
        />
      </Table.Td>
      <Table.Td>
        <LanguageSeletor
          value={scopedState.language.value}
          onChange={(value) => scopedState.language.set(value as string)}
          readOnly={readOnly}
        />
      </Table.Td>
      <Table.Td>
        <Select
          defaultValue='Auto'
          data={[
            { value: 'Auto', label: t("Auto") as string },
            { value: 'None', label: t("None") as string },
            { value: 'Paragraph', label: t("Paragraph") as string },
            { value: 'Block', label: t("Block") as string }
          ]}
          value={scopedState.splitMethod.value}
          onChange={(value) => scopedState.splitMethod.set(value as SplitMethod)}
          readOnly={readOnly}
          allowDeselect={false}
        />
      </Table.Td>
      <Table.Td>
        <AudienceMultiSelect
          value={scopedState.audiences.value as string[]}
          onChange={(value) => scopedState.audiences.set(value)}
          readOnly={readOnly}
        />
      </Table.Td>
      <Table.Td>
        <Group gap={4} justify="flex-end" wrap='nowrap'>
          <Tooltip label={scopedState.operationResult.value}>
            <Text>{getStatusIcon(scopedState.value as NewDocumentFromWizard)}</Text>
          </Tooltip>
          {!readOnly &&
            <ActionIcon color="red" variant='subtle' onClick={() => onDeleteFile?.(scopedState.title.value)}>
              <IconTrash />
            </ActionIcon>
          }
        </Group>
      </Table.Td>
    </Table.Tr>
  );
}

const StartQuickStart: FC<{}> = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { header } = useContext(HeaderContext);
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;
  const allowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const defaultAudience = container.get<AppConfiguration>("AppConfiguration").defaultAudience;
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [initialized, setInitialized] = useState<boolean>(false);

  const profileStore = useMemo(() => container.get(ProfileStore), []);
  const profileState = profileStore.state;
  const serviceConnectionStore = useMemo(() => container.get(ServiceConnectionSummaryStore), []);
  const serviceConnectionState = serviceConnectionStore.state;
  const serviceConnectionItemStore = useMemo(() => container.get(ServiceConnectionItemStore), []);
  const serviceConnectionItemState = serviceConnectionStore.state;
  const botStore = useMemo(() => container.get(BotItemStore), []);
  const botState = botStore.state;
  const collectionStore = useMemo(() => container.get(CollectionItemStore), []);
  const collectionState = collectionStore.state;
  const crawlerItemStore = useMemo(() => container.get(CrawlerItemStore), []);
  const crawlerItemState = crawlerItemStore.state;
  const crawlerSummaryStore = useMemo(() => container.get(CrawlerSummaryStore), []);
  const crawlerSummaryState = crawlerSummaryStore.state;
  const [crawlerQuery, setCrawlerQuery] = useState<Query>(undefined as any);
  const documentStore = useMemo(() => container.get(DocumentItemStore), []);
  const documentState = documentStore.state;
  const skillStore = useMemo(() => container.get(SkillItemStore), []);
  const skillState = skillStore.state;

  const wizardIsBusy = profileState.isBusy.value || serviceConnectionState.isBusy.value || botState.isBusy.value || collectionState.isBusy.value || crawlerItemState.isBusy.value ||
    crawlerSummaryState.isBusy.value || documentState.isBusy.value || skillState.isBusy.value;

  const [showCreateCrawlerModal, setShowCreateCrawlerModal] = useState(false);
  const [showEditCrawlerModal, setShowEditCrawlerModal] = useState(false);
  const [selectedCrawler, setSelectedCrawler] = useState<string | undefined>(undefined);

  const [importFiles, setImportFiles] = useState<FileWithPath[]>();
  const [rejectFiles, setRejectFiles] = useState<FileRejection[] | undefined>(undefined);
  const dropZoneRef = useRef<() => void>(null);

  const initialValues = {
    collectionId: undefined,
    step1: {
      completed: false,
    },
    step2: {
      completed: false,
      enableM365: false,
      drive: false,
      email: false,
      events: false,
      driveCrawlDays: 0,
      driveExpirationDays: 0,
      emailCrawlDays: 0,
      emailExpirationDays: 0,
      eventsCrawlDays: 0,
      eventsExpirationDays: 0
    },
    step3: {
      completed: false,
    },
    step4: {
      documents: [] as NewDocumentFromWizard[],
      completed: false,
      completedWithWarnings: false,
    },
    step5: {
      completed: false,
    }
  } as QuickStartWizardForm;
  const scopedState = useHookstate(initialValues);
  const botScopedState = useHookstate({} as BotItem);

  const searchLlmServices = () => {
    serviceConnectionStore.load({
      searchQuery: '',
      parameters: {
        categoryFilter: "Llm",
        typeFilter: "BuiltIn"
      },
      skip: 0,
      take: 1,
    } as Query);
  }

  const searchOauthServices = () => {
    serviceConnectionStore.load({
      searchQuery: '',
      //TODO: order by name or last created ??
      parameters: {
        categoryFilter: "",
        typeFilter: "OAuth"
      },
      skip: 0,
      take: 1,
    } as Query);
  }

  const getDefaultBehaviour = () => {
    let defaultBehaviour: { [key: string]: string } = {};
    for (const lang of allowedLanguages) {
      const defaultBotContent = GetBotDefaultContent('Router', t, lang);
      defaultBehaviour[lang] = defaultBotContent.llm.behavior[lang];
    }

    return defaultBehaviour;
  }

  const getDefaultDescription = () => {
    let defaultDescription: { [key: string]: string } = {};
    for (const lang of allowedLanguages) {
      defaultDescription[lang] = '';
    }

    return defaultDescription;
  }

  const getDefaultGreetings = (botTitle: string) => {
    let defaultGreeting: { [key: string]: string } = {};
    for (const lang of allowedLanguages) {
      defaultGreeting[lang] = `${t("Welcome to", { lng: lang })} ${botTitle}`;
    }

    return defaultGreeting;
  }

  const getDefaultFallback = () => {
    let defaultFallback: { [key: string]: string } = {};
    for (const lang of allowedLanguages) {
      defaultFallback[lang] = `${t("Sorry, I don't know. Please try to change the question and ask me again.", { lng: lang })}`;
    }

    return defaultFallback;
  }

  useEffect(() => {
    profileStore.load("me");
    searchLlmServices();
  }, []);

  useEffect(() => {
    if (profileState?.item?.value && !initialized) {
      const metadata = profileState.item.metadata.value[QuickStartWizardStateMetadataKey];
      if (metadata) {
        loadWizardState();
      }
      else {
        const userName = profileState.item.displayName?.value ?? profileState.item.name?.value;
        const botTitle = `${userName} Bot`;
        scopedState.step1.set({
          completed: false,
          botName: botTitle,
          behavior: getDefaultBehaviour(),
          description: getDefaultDescription()
        });
      }

      collectionStore.load(profileState.item.name.value);
      setInitialized(true);
    }
  }, [profileState.item])

  useEffect(() => {
    if (collectionState.errorMessage.value || collectionState.item.value) {
      createPersonalCollection();
    }
  }, [collectionState.errorMessage, collectionState.item])

  useEffect(() => {
    if (active === 1 && !scopedState.step2.completed.value) {
      searchOauthServices();
    }
  }, [active])

  useEffect(() => {
    if (scopedState.collectionId?.value) {
      crawlerSummaryStore.setCollection(scopedState.collectionId.value);
      const newQuery = {
        ...crawlerQuery,
        orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        parameters: {
          collectionId: scopedState.collectionId.value
        }
      } as Query;
      setCrawlerQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopedState.collectionId]);

  useEffect(() => {
    if (crawlerQuery) {
      crawlerSummaryStore.load(crawlerQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlerQuery]);

  useEffect(() => {
    if (crawlerSummaryState.items?.value?.length > 0 && crawlerSummaryState.items.value.filter(x => x.title === "Microsoft 365").length === 1) {
      scopedState.step2.completed.set(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlerSummaryState.items]);

  const createPersonalCollection = async () => {
    // create personal collection if not exist
    let collectionId = "";
    if (!collectionState.item?.value) {
      const collectionCreated = await collectionStore.create({
        title: profileState.item.name.value,
        description: `Personal collection for ${profileState.item.name.value}`,
        accessMode: "MembersOnly",
      } as CollectionItem) as CollectionItem;
      if (collectionCreated) {
        collectionId = collectionCreated.id;
      }
    }
    else {
      collectionId = collectionState.item.id.value;
    }
    scopedState.collectionId.set(collectionId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onReloadCrawlers = () => {
    crawlerSummaryStore.load(crawlerQuery)
  };

  const loadWizardState = async () => {
    if (profileState.item?.metadata?.value?.[QuickStartWizardStateMetadataKey]) {
      const savedState = JSON.parse(profileState.item.metadata.value[QuickStartWizardStateMetadataKey]) as QuickStartWizardForm;
      scopedState.set(savedState);
      if (savedState.step1.completed && savedState.step1.botId) {
        const bot = await botStore.load(savedState.step1.botId);
        if (bot) {
          botScopedState.set(bot);
        }
      }

      if (savedState.step5.completed) setActive(5);
      else if (savedState.step4.completed) setActive(4);
      else if (savedState.step3.completed) setActive(3);
      else if (savedState.step2.completed) setActive(2);
      else if (savedState.step1.completed) setActive(1);
    }
  }

  const saveWizardState = async () => {
    const wizardState = JSON.stringify(scopedState.value, null, 2);
    let profileItem = JSON.parse(JSON.stringify(profileState.item.value)) as ClaimsProfileItem;
    profileItem.quickStartWizardState = wizardState;
    await profileStore.save('me', profileItem);
  }

  const resetWizardState = async () => {
    let profileItem = JSON.parse(JSON.stringify(profileState.item.value)) as ClaimsProfileItem;
    profileItem.quickStartWizardState = "";
    await profileStore.save('me', profileItem);
  }

  const crawlerModel: TableListV2Model<CrawlerSummary> = {
    data: crawlerSummaryStore.toListState(crawlerSummaryState.value),
    query: crawlerQuery,
    columns: [
      {
        accessor: 'title',
        title: t('Title'),
        sortable: true,
        render: (item: CrawlerSummary) => (
          !scopedState.step3.completed.value ?
            <Tooltip label={t("Edit crawler")}>
              <Anchor onClick={() => { setSelectedCrawler(item.id); setShowEditCrawlerModal(true); }}>{item.title}</Anchor>
            </Tooltip>
            :
            <Text>{item.title}</Text>
        )
      },
      {
        accessor: 'type',
        title: t('Type'),
        sortable: true,
        render: (item: CrawlerSummary) => (
          <Text>{item.type}</Text>
        )
      },
      {
        accessor: 'schedule',
        title: t('Schedule'),
        render: (item: CrawlerSummary) => (
          <Text>{item.schedule}</Text>
        )
      }
    ]
  };

  const onConfirmDeleteCrawlers = async (selectedKeys: CrawlerSummary[]) => {
    if (selectedKeys && selectedKeys.length > 0) {
      selectedKeys.forEach((key) => { crawlerSummaryStore.delete(key.id); });
      await sleep(500);
      onReloadCrawlers();
    }
  };

  const onDrop = (files: FileWithPath[]) => {
    if (files && files.length > 0) {
      const newFiles = AsEnumerable(files).Where(f => !AsEnumerable(importFiles).Select(x => x.name).Contains(f.name)).ToArray();
      if (importFiles) {
        setImportFiles((prev) => [...prev as FileWithPath[], ...newFiles]);
      }
      else {
        setImportFiles(newFiles);
      }

      let uploadedFiles = [] as NewDocumentFromWizard[];
      newFiles.forEach(file => {
        uploadedFiles.push({
          title: file.name,
          description: '',
          audiences: [defaultAudience],
          language: header.language,
          boost: 1,
          dontAdvertise: false,
          reference: '',
          splitMethod: 'Auto',
          fileSize: file.size,
          operationStatus: "Pending",
          operationResult: ''
        } as NewDocumentFromWizard);
      });

      const copy = JSON.parse(JSON.stringify(scopedState.step4.documents.value)) as NewDocumentFromWizard[];
      scopedState.step4.documents.set([...copy, ...uploadedFiles]);
    }
  }

  const onDeleteFiles = () => {
    setImportFiles(undefined);
    setRejectFiles(undefined);
  }

  const onDeleteFile = (fileName: string) => {
    if (fileName) {
      const newFiles = importFiles?.filter(x => x.name !== fileName);
      setImportFiles(newFiles);
      const copy = JSON.parse(JSON.stringify(scopedState.step4.documents.value)) as NewDocumentFromWizard[];
      const newDocuments = copy.filter(x => x.title !== fileName);
      scopedState.step4.documents.set(newDocuments);
    }
  }

  const showRejectedFiles = (
    rejectFiles && rejectFiles?.length > 0 ?
      <Popover width={800} withArrow shadow="xl">
        <Popover.Target>
          <Tooltip label={t("Show rejected files")}>
            <ActionIcon style={{ pointerEvents: 'all' }} color="red" variant='subtle'>
              <IconFileUnknown />
            </ActionIcon>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack gap="xs">
            <Group justify="space-between">
              <Text>{t("Rejected files")}</Text>
              <Anchor component="a" c="red" onClick={() => setRejectFiles(undefined)}>
                {t("Delete all")}
              </Anchor>
            </Group>
            <Divider />

            <ScrollArea.Autosize mah={250}>
              <Table striped highlightOnHover withRowBorders={false}>
                <Table.Tbody>
                  {rejectFiles.map((item: FileRejection, index: number) => (
                    <Table.Tr key={item.file.name}>
                      <Table.Td px={0} style={{ width: '35%' }}>
                        <Text size="sm">{truncateText(item.file.name, 40)}</Text>
                      </Table.Td>
                      <Table.Td px={0} style={{ width: '15%' }}>
                        <Text size="sm">{formatBytes(item.file.size)}</Text>
                      </Table.Td>
                      <Table.Td px={0} style={{ width: '50%' }}>
                        <Text size="sm" title={item.errors[0].message}>
                          {truncateText(item.errors[0].message, 128)}
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </ScrollArea.Autosize>
          </Stack>
        </Popover.Dropdown>
      </Popover>
      :
      <></>
  );

  const getErrorMesages = () => {
    const errorMessage = profileState.errorMessage.value ?? botState.errorMessage.value ??
      serviceConnectionState.errorMessage.value ?? crawlerItemState.errorMessage.value ??
      crawlerSummaryState.errorMessage.value ?? serviceConnectionItemState.errorMessage.value ?? "";
    //TODO: añadir el resto de posibles mensajes de error: documentState.errorMessage.value, collectionState.errorMessage.value, skillState.errorMessage.value

    return (
      errorMessage ?
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red">
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
        :
        <></>
    );
  }

  const isValidStep1 = () => {
    return scopedState?.step1?.botName?.value?.length > 0 &&
      scopedState?.step1?.behavior?.value && Object.keys(scopedState.step1.behavior.value).length > 0 &&
      scopedState.step1.behavior[Object.keys(scopedState.step1.behavior.value)[0]]?.value?.length > 0;
  }

  const isValidStep4 = () => {
    if (scopedState.step4.documents.length === 0) return true;
    return AsEnumerable(scopedState.step4.documents.value).All(x => x.title.length > 0 && x.language.length > 0 && x.splitMethod.length > 0);
  }

  const canContinue = () => {
    if (active === 0) {
      return isValidStep1();
    }
    if (active === 3) {
      return isValidStep4();
    }

    return true;
  }

  const createBot = async () => {
    if (scopedState.step1.completed.value) return;

    let strategy = "Llm" as BotStrategy;
    let orchestrator = "Functions" as BotLlmOrchestratorType;
    let service = "";
    if (serviceConnectionState.items?.value?.length > 0) {
      const firstService = serviceConnectionState.items[0]?.value;
      service = firstService.id;
    }
    else {
      // create built-in service
      const builtInContent = {
        tier: 's0'
      } as BuiltInServiceConnectionContent;

      const builtInService = await serviceConnectionItemStore.create({
        accessMode: 'MembersOnly',
        category: 'Llm',
        type: 'BuiltIn',
        name: 'llm_builtin',
        content: JSON.stringify(builtInContent, null, 2)
      } as NewServiceConnectionItem) as ServiceConnectionItem;
      service = builtInService.id;
    }

    const newBot = {
      title: scopedState.step1.botName.value,
      description: scopedState.step1.description.value,
      accessMode: 'MembersOnly',
      strategy: strategy
    } as BotItem;

    let botCreated = await botStore.create(newBot) as BotItem;
    if (botCreated) {
      botScopedState.set(botCreated);
      let botContent = GetBotDefaultContent(botCreated.strategy, t, header.language);
      botContent.options.enableDocumentManagement = true;
      if (strategy === "Llm") {
        botContent.llm.behavior = scopedState.step1.behavior.value;
        botContent.llm.orchestratorType = orchestrator;
        botContent.llm.service = service;
        botContent.mrc.enable = false;
        botContent.disambiguation.enable = false;
      }

      const snapshot = {
        id: botCreated.id,
        strategy: botCreated.strategy,
        accessMode: botCreated.accessMode,
        content: JSON.stringify(botContent, null, 2),
        enable: false,
        title: botCreated.title,
        description: botCreated.description
      } as UpsertBotSnapshotItem;

      let botUpdated = await botStore.saveSnapshot(botCreated.id, snapshot);
      if (botUpdated) {
        botScopedState.set(botUpdated);
        scopedState.step1.completed.set(true);
        scopedState.step1.botId.set(botCreated.id);
      }
    }
  }

  const createStep2 = async () => {
    if (scopedState.step2.completed.value) return;

    if (scopedState.step2.enableM365.value) {
      if (serviceConnectionState.items?.value?.length > 0 && scopedState.collectionId.value) {
        const firstService = serviceConnectionState.items[0]?.value;
        if (firstService.type === "OAuth") {
          const service = firstService.id;
          let microsoft365Content = GetMicrosoft365CrawlerDefaultContent();
          microsoft365Content.email = scopedState.step2.email.value;
          microsoft365Content.emailCrawlDays = scopedState.step2.emailCrawlDays.value;
          microsoft365Content.emailExpirationDays = scopedState.step2.emailExpirationDays.value;
          microsoft365Content.drive = scopedState.step2.drive.value;
          microsoft365Content.driveCrawlDays = scopedState.step2.driveCrawlDays.value;
          microsoft365Content.driveExpirationDays = scopedState.step2.driveExpirationDays.value;
          microsoft365Content.events = scopedState.step2.events.value;
          microsoft365Content.eventsCrawlDays = scopedState.step2.eventsCrawlDays.value;
          microsoft365Content.eventsExpirationDays = scopedState.step2.eventsExpirationDays.value;
          microsoft365Content.service = service;
          microsoft365Content.languages = allowedLanguages;

          const m365Crawler = {
            collectionId: scopedState.collectionId.value,
            content: JSON.stringify(microsoft365Content, null, 2),
            title: "Microsoft 365",
            type: "Microsoft365",
            schedule: '* * * * *'
          } as NewCrawlerItem

          crawlerItemStore.setCollection(scopedState.collectionId.value);
          let crawlerCreated = await crawlerItemStore.create(m365Crawler);
          scopedState.step2.completed.set(true);
        }
      }
      //TODO: else con error si no se ha encontrado el servicio de OAuth ??
    }
    else {
      scopedState.step2.completed.set(true);
    }
  }

  const createStep3 = () => {
    scopedState.step3.completed.set(true);
  }

  const createStep4 = async () => {
    if (scopedState.step4.completed.value) return;

    if (scopedState.step4.completedWithWarnings.value) {
      scopedState.step4.completed.set(true);
    }
    else {
      if (scopedState.collectionId?.value && !scopedState.step4.searchSkillId.value) {
        // create skill to search in personal collection
        let skillTitle = `${scopedState.step1.botName.value} Search`.toLowerCase();
        let newSkill = {
          title: normalizeName(skillTitle, true),
          accessMode: 'MembersOnly',
          type: 'Search',
          tags: {},
          languages: allowedLanguages
        } as NewSkillItem

        let skillCreated = await skillStore.create(newSkill) as SkillItem;
        if (skillCreated) {
          scopedState.step4.searchSkillId.set(skillCreated.id);
          let skillContent = GetSkillDefaultContent(skillCreated.type) as SkillSearchContent;
          skillContent.collections = [scopedState.collectionId.value];

          const snapshot = {
            id: skillCreated.id,
            type: skillCreated.type,
            accessMode: skillCreated.accessMode,
            content: JSON.stringify(skillContent, null, 2),
            title: skillCreated.title,
            languages: allowedLanguages,
            tags: {}
          } as UpsertSkillSnapshotItem;

          let skillUpdated = await skillStore.saveSnapshot(skillCreated.id, snapshot) as SkillItem;
          if (skillUpdated) {
            await skillStore.trainSnapshot(skillCreated.id, skillUpdated.currentSnapshot.id);
          }
        }

        // create skill to greeting
        if (!scopedState.step4.greetingsSkillId.value) {
          skillTitle = `${scopedState.step1.botName.value} Greeting`.toLowerCase();
          newSkill = {
            title: normalizeName(skillTitle, true),
            accessMode: 'MembersOnly',
            type: 'Dialog',
            tags: {},
            languages: allowedLanguages
          } as NewSkillItem

          let skillCreated = await skillStore.create(newSkill) as SkillItem;
          if (skillCreated) {
            scopedState.step4.greetingsSkillId.set(skillCreated.id);
            let skillContent = GetSkillDefaultContent(skillCreated.type) as SkillDialogContent;

            skillContent.steps = [
              {
                step: 'step1',
                condition: '',
                action: {
                  type: 'Say',
                  text: [getDefaultGreetings(scopedState.step1.botName.value)],
                } as SkillContentStepAction,
                turn: 'End'
              }
            ]

            const snapshot = {
              id: skillCreated.id,
              type: skillCreated.type,
              accessMode: skillCreated.accessMode,
              content: JSON.stringify(skillContent, null, 2),
              title: skillCreated.title,
              languages: allowedLanguages,
              tags: {}
            } as UpsertSkillSnapshotItem;

            let skillUpdated = await skillStore.saveSnapshot(skillCreated.id, snapshot) as SkillItem;
            if (skillUpdated) {
              await skillStore.trainSnapshot(skillCreated.id, skillUpdated.currentSnapshot.id);
            }
          }
        }

        // create skill to fallback
        if (!scopedState.step4.fallbackSkillId.value) {
          skillTitle = `${scopedState.step1.botName.value} Fallback`.toLowerCase();
          newSkill = {
            title: normalizeName(skillTitle, true),
            accessMode: 'MembersOnly',
            type: 'Dialog',
            tags: {},
            languages: allowedLanguages
          } as NewSkillItem

          let skillCreated = await skillStore.create(newSkill) as SkillItem;
          if (skillCreated) {
            scopedState.step4.fallbackSkillId.set(skillCreated.id);
            let skillContent = GetSkillDefaultContent(skillCreated.type) as SkillDialogContent;

            skillContent.steps = [
              {
                step: 'step1',
                condition: '',
                action: {
                  type: 'Say',
                  text: [getDefaultFallback()],
                } as SkillContentStepAction,
                turn: 'End'
              }
            ]

            const snapshot = {
              id: skillCreated.id,
              type: skillCreated.type,
              accessMode: skillCreated.accessMode,
              content: JSON.stringify(skillContent, null, 2),
              title: skillCreated.title,
              languages: allowedLanguages,
              tags: {}
            } as UpsertSkillSnapshotItem;

            let skillUpdated = await skillStore.saveSnapshot(skillCreated.id, snapshot) as SkillItem;
            if (skillUpdated) {
              await skillStore.trainSnapshot(skillCreated.id, skillUpdated.currentSnapshot.id);
            }
          }
        }

        if (scopedState.step4.documents?.value.length > 0) {
          documentStore.setCollection(scopedState.collectionId.value);

          if (importFiles && importFiles.length > 0) {
            const requests = scopedState.step4.documents.value.map(doc => ({
              collectionId: scopedState.collectionId.value,
              title: doc.title,
              description: doc.description,
              audiences: doc.audiences,
              language: doc.language,
              boost: doc.boost,
              dontAdvertise: doc.dontAdvertise,
              reference: slugify(doc.title, { lower: true }),
              contentSource: 'External',
              content: '',
              splitMethod: doc.splitMethod,
              tags: {},
              train: true,
              publish: true
            } as CreateDocumentItem));

            for (let index = 0; index < requests.length; index++) {
              scopedState.step4.documents[index].operationStatus.set("Creating");
              const request = requests[index];
              const response = await documentStore.createWithFile(request, importFiles[index] as File);

              scopedState.step4.documents[index].operationStatus.set(response && !documentState.errorMessage.value ? "Success" : "Error");
              scopedState.step4.documents[index].operationResult.set(documentState.errorMessage.value ? documentState.errorMessage.value : t('Success') as string);
            }

            if (AsEnumerable(scopedState.step4.documents.value).All(d => d.operationStatus === 'Success')) {
              scopedState.step4.completed.set(true);
            }
            else {
              scopedState.step4.completedWithWarnings.set(true);
            }
          }
        }
        else {
          scopedState.step4.completed.set(true);
        }
      }
    }
  }

  const createStep5 = async () => {
    if (scopedState.step5.completed.value) return;

    let botContent = JSON.parse(botScopedState.currentSnapshot.content.value) as BotContent;
    botContent.skills = [{
      audiences: [],
      confidence: 0.1,
      skillId: scopedState.step4.searchSkillId.value
    } as BotContentSkill];
    botContent.userPreferences = {
      allowUserChangeSearchLanguages: true,
      allowUserChangeSearchSkills: true,
      allowUserChangeTemperature: true,
      allowUserChangeInternetAccess: true,
      allowEnableOCRInDocuments: true,
      searchLanguages: allowedLanguages,
      temperature: 0.2,
      searchSkills: [scopedState.step4.searchSkillId.value],
      allowInternetAccess: false,
      enableOCRInDocuments: false
    } as BotContentUserPreferences;
    botContent.options.greetingsSkill = scopedState.step4.greetingsSkillId.value;
    botContent.options.fallbackSkill = scopedState.step4.fallbackSkillId.value

    const snapshot = {
      id: botScopedState.id.value,
      strategy: botScopedState.strategy.value,
      accessMode: botScopedState.accessMode.value,
      content: JSON.stringify(botContent, null, 2),
      enable: true,
      title: botScopedState.title.value,
      description: botScopedState.description.value
    } as UpsertBotSnapshotItem;

    let botUpdated = await botStore.saveSnapshot(botScopedState.id.value, snapshot) as BotItem;
    if (botUpdated) {
      botScopedState.set(botUpdated);
      await botStore.trainSnapshot(botScopedState.id.value, botUpdated.currentSnapshot.id)
      scopedState.step5.completed.set(true);
    }
  }

  const onContinue = async () => {
    if (active === 0) {
      if (isValidStep1()) {
        await createBot();
        if (botScopedState.currentSnapshot?.value) {
          nextStep();
          saveWizardState();
        }
      }
    }
    else if (active === 1) {
      await createStep2();
      nextStep();
      saveWizardState();
    }
    else if (active === 2) {
      createStep3();
      nextStep();
      saveWizardState();
    }
    else if (active === 3) {
      if (isValidStep4()) {
        await createStep4();
        if (scopedState.step4.completed.value) {
          nextStep();
          saveWizardState();
        }
      }
    }
    else if (active === 4) {
      await createStep5();
      if (scopedState.step5.completed.value) {
        nextStep();
        resetWizardState();
      }
    }
    else {
      nextStep();
    }
  }

  const onBack = () => {
    prevStep();
  }

  return (
    <Container w="100%" h="100%" p="md" size="1600">
      <Paper withBorder shadow="md" p={30} radius="md" className='center' pos="relative" w="100%" mih="50svh">
        <Box style={{ flex: 1 }}>
          {getErrorMesages()}
        </Box>
        <Stepper
          orientation='vertical'
          active={active}
          onStepClick={setActive}
          classNames={verticalStepperClasses}>
          <Stepper.Step
            label={t("Bot")}
            description={t("Configure your bot")}>
            <Box pos="relative">
              <LoadingOverlay visible={profileState.isBusy.value} />
              {profileState?.item?.value && !profileState.isBusy.value &&
                <Stack>
                  <Stack gap={2}>
                    <Title order={2}>{t("Welcome to quick start tutorial")}</Title>
                    <Text>{t("With this tutorial you can create, in just a few steps, a virtual assistant to work with your data in a very simple and powerful way. Your data can be provided by directly uploading your documents or by giving access to the multiple providers available.")} {t("To start, you provide basic information about your bot.")}</Text>
                  </Stack>

                  <Divider />

                  <TextInput
                    label={t("Bot name")}
                    value={scopedState?.step1?.botName?.value}
                    onChange={(e) => scopedState.step1.botName.set(e.currentTarget.value)}
                    required
                    style={{ flex: 1 }}
                    error={scopedState?.step1?.botName?.value?.length === 0 ? t("Required") : undefined}
                    disabled={scopedState.step1.completed.value}
                  />

                  <SimpleGrid
                    cols={{ base: 1, xl: 2 }}
                    spacing="sm">

                    <LocalizedTextArea
                      label={t("Behavior") as string}
                      edit
                      required
                      disabled={scopedState.step1.completed.value}
                      value={scopedState?.step1?.behavior?.value}
                      onChange={(value) => scopedState.step1.behavior.set(value)}
                    />

                    <LocalizedTextArea
                      label={t("Description") as string}
                      edit
                      required
                      disabled={scopedState.step1.completed.value}
                      value={scopedState?.step1?.description?.value}
                      onChange={(value) => scopedState.step1.description.set(value)}
                    />
                  </SimpleGrid>
                </Stack>
              }
            </Box>
          </Stepper.Step>

          <Stepper.Step
            label={t("Connections")}
            description={t("Connect with your data providers")}
            allowStepSelect={active > 1}>
            <Stack>
              <Stack gap={2}>
                <Title order={2}>{t("Connect your bot with your data sources")}</Title>
                <Text>{t("Select the different data providers you want to connect to your bot and choose which data you want to give it access to.")}</Text>
              </Stack>

              <Divider />

              <SimpleGrid
                cols={{ base: 1, lg: 2 }}
                spacing="md">
                <SwitchButton
                  className={indexClasses.switchButton}
                  label={t("Microsoft 365")}
                  description={t("Grant access to your Microsoft 365 account and select which elements you want to add to your bot.") as string}
                  checked={scopedState.step2.enableM365.value}
                  onChange={value => scopedState.step2.enableM365.set(value)}
                  readOnly={scopedState.step2.completed.value}
                  disabled={scopedState.step2.completed.value}>
                  {scopedState.step2.enableM365.value &&
                    <Stack ml="lg">
                      <SwitchButton
                        className={indexClasses.switchButton}
                        label={t("Crawl user emails (Outlook)")}
                        description={t("Crawl emails from your Microsoft 365 Outlook account") as string}
                        checked={scopedState.step2.email.value}
                        onChange={value => scopedState.step2.email.set(value)}
                        readOnly={scopedState.step2.completed.value}>
                        {scopedState.step2.email.value ?
                          <Stack gap="xs">
                            <Group align="flex-start" grow>
                              <Select
                                required
                                allowDeselect={false}
                                label={t("Items to crawl")}
                                data={[
                                  { value: '0', label: t('Everything') as string },
                                  { value: '7', label: t('Last seven days') as string },
                                  { value: '90', label: t('Last three months only') as string },
                                  { value: '180', label: t('Last six months only') as string },
                                  { value: '365', label: t('Last twelve months only') as string },
                                ]}
                                value={`${scopedState.step2.emailCrawlDays.value ? scopedState.step2.emailCrawlDays.value : 0}`}
                                onChange={(value) => { scopedState.step2.emailCrawlDays.set(parseInt(value ?? "0")); }}
                                readOnly={scopedState.step2.completed.value}
                              />
                              <Select
                                required
                                allowDeselect={false}
                                label={t("Automatic expiration")}
                                data={[
                                  { value: '0', label: t('Never') as string },
                                  { value: '90', label: t('Keep last three months only') as string },
                                  { value: '180', label: t('Keep last six months only') as string },
                                  { value: '365', label: t('Keep last twelve months only') as string },
                                ]}
                                value={`${scopedState.step2.emailExpirationDays.value ? scopedState.step2.emailExpirationDays.value : 0}`}
                                onChange={(value) => { scopedState.step2.emailExpirationDays.set(parseInt(value ?? "0")); }}
                                readOnly={scopedState.step2.completed.value}
                              />
                            </Group>
                          </Stack>
                          :
                          null
                        }
                      </SwitchButton>
                      <SwitchButton
                        className={indexClasses.switchButton}
                        label={t("Crawl user files (OneDrive)")}
                        description={t("Crawl files from your Microsoft 365 OneDrive account") as string}
                        checked={scopedState.step2.drive.value}
                        onChange={value => scopedState.step2.drive.set(value)}
                        readOnly={scopedState.step2.completed.value}>
                        {scopedState.step2.drive.value ?
                          <Stack gap="xs">
                            <Group align="flex-start" grow>
                              <Select
                                required
                                allowDeselect={false}
                                label={t("Items to crawl")}
                                data={[
                                  { value: '0', label: t('Everything') as string },
                                  { value: '7', label: t('Last seven days') as string },
                                  { value: '90', label: t('Last three months only') as string },
                                  { value: '180', label: t('Last six months only') as string },
                                  { value: '365', label: t('Last twelve months only') as string },
                                ]}
                                value={`${scopedState.step2.value ? scopedState.step2.driveCrawlDays.value : 0}`}
                                onChange={(value) => { scopedState.step2.driveCrawlDays.set(parseInt(value ?? "0")); }}
                                readOnly={scopedState.step2.completed.value}
                              />
                              <Select
                                required
                                allowDeselect={false}
                                label={t("Automatic expiration")}
                                data={[
                                  { value: '0', label: t('Never') as string },
                                  { value: '90', label: t('Keep last three months only') as string },
                                  { value: '180', label: t('Keep last six months only') as string },
                                  { value: '365', label: t('Keep last twelve months only') as string },
                                ]}
                                value={`${scopedState.step2.driveExpirationDays.value ? scopedState.step2.driveExpirationDays.value : 0}`}
                                onChange={(value) => { scopedState.step2.driveExpirationDays.set(parseInt(value ?? "0")); }}
                                readOnly={scopedState.step2.completed.value}
                              />
                            </Group>
                          </Stack>
                          :
                          null
                        }
                      </SwitchButton>
                      <SwitchButton
                        className={indexClasses.switchButton}
                        label={t("Crawl events (Calendar)")}
                        description={t("Crawl events from your Microsoft 365 Calendar account") as string}
                        checked={scopedState.step2.events.value}
                        onChange={value => scopedState.step2.events.set(value)}
                        readOnly={scopedState.step2.completed.value}>
                        {scopedState.step2.events.value ?
                          <Stack gap="xs">
                            <Group align="flex-start" grow>
                              <Select
                                required
                                allowDeselect={false}
                                label={t("Items to crawl")}
                                data={[
                                  { value: '0', label: t('Everything') as string },
                                  { value: '7', label: t('Last seven days') as string },
                                  { value: '90', label: t('Last three months only') as string },
                                  { value: '180', label: t('Last six months only') as string },
                                  { value: '365', label: t('Last twelve months only') as string },
                                ]}
                                value={`${scopedState.step2.eventsCrawlDays.value ? scopedState.step2.eventsCrawlDays.value : 0}`}
                                onChange={(value) => { scopedState.step2.eventsCrawlDays.set(parseInt(value ?? "0")); }}
                                readOnly={scopedState.step2.completed.value}
                              />
                              <Select
                                required
                                allowDeselect={false}
                                label={t("Automatic expiration")}
                                data={[
                                  { value: '0', label: t('Never') as string },
                                  { value: '90', label: t('Keep last three months only') as string },
                                  { value: '180', label: t('Keep last six months only') as string },
                                  { value: '365', label: t('Keep last twelve months only') as string },
                                ]}
                                value={`${scopedState.step2.eventsExpirationDays.value ? scopedState.step2.eventsExpirationDays.value : 0}`}
                                onChange={(value) => { scopedState.step2.eventsExpirationDays.set(parseInt(value ?? "0")); }}
                                readOnly={scopedState.step2.completed.value}
                              />
                            </Group>
                          </Stack>
                          :
                          null
                        }
                      </SwitchButton>
                    </Stack>
                  }
                </SwitchButton>
              </SimpleGrid>
            </Stack>
          </Stepper.Step>

          <Stepper.Step
            label={t("Additional sources")}
            description={t("Add additional data sources")}
            allowStepSelect={active > 2}>
            <Stack>
              <Stack gap={2}>
                <Title order={2}>{t("Add additional data sources to further enrich your bot")}</Title>
                <Text>{t("Set up additional data providers so you can improve your bot's knowledge using crawlers. You can configure them to update the information periodically.")}</Text>
              </Stack>

              <Divider />

              <TableListV2
                model={crawlerModel}
                onQueryChanged={setCrawlerQuery}
                onRefresh={onReloadCrawlers}
                striped
                highlightOnHover
                leftToolBarRender={
                  !scopedState.step3.completed.value ?
                    <Button onClick={() => setShowCreateCrawlerModal(true)} variant='subtle' leftSection={<IconPlus />}>
                      {t("Add new data sources")}
                    </Button>
                    :
                    <></>
                }
                selectable={!scopedState.step3.completed.value}
                onDeleteSelectedClick={(selectedKeys: CrawlerSummary[]) => onConfirmDeleteCrawlers(selectedKeys)}
              />
              {showCreateCrawlerModal && scopedState.collectionId?.value &&
                <CreateCrawlerWizard
                  opened={showCreateCrawlerModal}
                  onClose={() => { setShowCreateCrawlerModal(false); onReloadCrawlers(); }}
                  collectionId={scopedState.collectionId.value} />

              }
              {showEditCrawlerModal && selectedCrawler && scopedState.collectionId?.value &&
                <EditCrawler
                  key={selectedCrawler as string}
                  opened={showEditCrawlerModal}
                  onClose={() => { setShowEditCrawlerModal(false); setSelectedCrawler(undefined); onReloadCrawlers(); }}
                  collectionId={scopedState.collectionId.value}
                  crawlerId={selectedCrawler as string}
                  canContribute
                  edit
                />
              }
            </Stack>
          </Stepper.Step>

          <Stepper.Step
            label={t("Upload documents")}
            description={t("Upload your own documents")}
            allowStepSelect={active > 3}>
            <Stack>
              <Stack gap={2}>
                <Title order={2}>{t("Upload your own documents")}</Title>
                <Text>{t("Upload your own documents from your computer to increase your bot's knowledge. You can upload Office or PDF documents.")}</Text>
              </Stack>

              <Divider />

              <Dropzone
                openRef={dropZoneRef}
                activateOnClick={false}
                classNames={{ root: indexClasses.dropZone, inner: indexClasses.dropZoneInner }}
                onDrop={onDrop}
                onReject={setRejectFiles}
                maxSize={50 * 1024 ** 2} // 50 mb
                maxFiles={10} // 10 archivos
                accept={allowedMimeTypes}
                multiple={true}
                disabled={scopedState.step4.completed.value || scopedState.step4.completedWithWarnings.value}>
                <Stack style={{ flex: 1 }} align="flex-start">
                  {scopedState.step4.documents?.value?.length === 0 ?
                    <Stack align="center" justify="flex-start" w="100%">
                      <Text size="lg" inline ta="center">
                        {t("Drag file here or click to select file")}
                      </Text>
                      <Text size="sm" c="dimmed" inline mt={7} ta="center">
                        {t("Attach PDF or Office file without exceeding")} 50 MB
                      </Text>
                      <Button onClick={() => dropZoneRef.current?.()}
                        disabled={scopedState.step4.completed.value || scopedState.step4.completedWithWarnings.value}
                        style={{ pointerEvents: 'all' }}
                        variant='subtle' leftSection={<IconUpload />}>
                        {t("Add new files or drag")}
                      </Button>
                    </Stack>
                    :
                    <>
                      {!scopedState.step4.completed.value &&
                        <Group align='center'>
                          <Button onClick={() => dropZoneRef.current?.()}
                            disabled={scopedState.step4.completed.value || scopedState.step4.completedWithWarnings.value}
                            style={{ pointerEvents: 'all' }}
                            variant='subtle' leftSection={<IconUpload />}>
                            {t("Add new files or drag")}
                          </Button>
                          <>
                            {showRejectedFiles}
                          </>
                        </Group>
                      }
                      {scopedState.step4.documents.value.length > 0 &&
                        <ScrollArea.Autosize mah={500} style={{ pointerEvents: 'all' }} w="100%">
                          <Table highlightOnHover>
                            <Table.Thead>
                              <Table.Th style={{ width: '30%', textAlign: 'left', paddingLeft: 10 }}><Input.Label required>{t("Title")}</Input.Label></Table.Th>
                              <Table.Th style={{ width: '15%', textAlign: 'left', paddingLeft: 10 }}><Input.Label>{t("Language")}</Input.Label></Table.Th>
                              <Table.Th style={{ width: '15%', textAlign: 'left', paddingLeft: 10 }}><Input.Label>{t("Split method")}</Input.Label></Table.Th>
                              <Table.Th style={{ width: '30%', textAlign: 'left', paddingLeft: 10 }}><Input.Label>{t("Audiences")}</Input.Label></Table.Th>
                              <Table.Th style={{ width: '10%', textAlign: 'left', paddingLeft: 10 }}></Table.Th>
                            </Table.Thead>
                            <Table.Tbody>
                              {scopedState.step4.documents.map((doc, index) =>
                                <EditableFileRow key={index} index={index} state={doc} onDeleteFile={(title) => onDeleteFile(title)}
                                  readOnly={scopedState.step4.completed.value || scopedState.step4.completedWithWarnings.value} />
                              )}
                            </Table.Tbody>
                          </Table>
                        </ScrollArea.Autosize>
                      }
                    </>
                  }
                </Stack>
              </Dropzone>
            </Stack>
          </Stepper.Step>

          <Stepper.Step
            label={t("Security")}
            description={t("Grant permissions")}
            allowStepSelect={active > 4}>
            <Stack>
              <Stack gap={2}>
                <Title order={2}>{t("Grant permissions")}</Title>
                <Text>{t("Give your bot the necessary permissions to access the configured providers.")}</Text>
              </Stack>

              <Divider />

              <ProfileIdentities readOnly={scopedState.step5.completed.value} />
            </Stack>
          </Stepper.Step>
          <Stepper.Completed>
            <Box px="md">
              <Center h={400}>
                <Stack align='center' justify='center'>
                  <h1 style={{ textAlign: 'center' }}>
                    {t("Congratulations")}! {' '}
                    <Text className={indexClasses.title} component="span" variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][6], to: 'cyan' }} span inherit>
                      {botScopedState.title.value}
                    </Text> {' '}
                    {t("has been created successfully")}.
                  </h1>
                  <Text size="lg" c="dimmed">
                    <Text span>{t("You can start chatting with your virtual assistant or go to the admin center and review your settings.")}</Text>
                  </Text>

                  <Group align='center' gap="lg" mt="xl">
                    <Button variant='default' component="a" href={`${baseUri}/admin/bots/${botScopedState.id.value}`}
                      leftSection={<IconAdjustments size={20} />}>{t("Administration center")}</Button>

                    <Button component="a" href={`${baseUri}/bots/${botScopedState.id.value}`}
                      leftSection={<IconMessageChatbot size={20} />}>{t("Chat with your bot")}</Button>
                  </Group>
                </Stack>
              </Center>
            </Box>
          </Stepper.Completed>
        </Stepper>

        {/* FOOTER */}
        <Box mt={30}>
          <Divider />
          <Group justify='space-between' mt={30}>
            <Button variant="default" onClick={resetWizardState}>{t("Reset saved state")}</Button>

            <Group justify="flex-end">
              {active > 0 &&
                <Button variant="default" onClick={onBack}>{t("Back")}</Button>
              }
              {active < 5 &&
                <Button
                  onClick={onContinue}
                  disabled={!canContinue()}
                  loading={wizardIsBusy}>
                  {t("Next step")}
                </Button>
              }
            </Group>
          </Group>
        </Box>
      </Paper>
    </Container>
  );
}

export default StartQuickStart;

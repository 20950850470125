import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Anchor, Badge, Box, Group, Stack, Text, Title, Tooltip } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import HeaderContext from 'src/services/header-context';
import moment from 'moment';
import useBus from 'use-bus';
import StatusBadge from 'src/components/status-badge';
import { DocumentSummary, DocumentSummaryStore } from 'src/stores/documents';
import { CollectionSelector } from 'src/components/collection-selector';
import JobStatus from 'src/components/job-status';
import { IconFileText, IconBook2, IconTags, IconArrowBigRight, IconClock } from '@tabler/icons-react';
import classes from 'src/pages/index.module.css';
import SimpleListV2, { SimpleListV2Model } from 'src/core/ui/simple-list/simple-list-v2';

const DocumentList: React.FC<{ showFilters: boolean, setCollection?: (collectionSelected: string) => void, }> = ({ showFilters, setCollection }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const summaryStore = useMemo(() => container.get(DocumentSummaryStore), []);
  const summaryState = summaryStore.state;
  const { height } = useViewportSize();
  const [query, setQuery] = useState<Query>(undefined as any);
  const { documentId, collectionId } = useParams();
  const [collectionSelected, setCollectionSelected] = useState<string>(collectionId || '');

  useEffect(() => {
    let routes = [];
    if (collectionId && documentId) {
      summaryStore.setCollection(collectionId);
      setHeader(`${collectionId}/${documentId}`, 'Documents', <IconFileText size={34} />, `${collectionId}/${documentId} | ${t("Documents")}`, true, true);
      routes.push(
        { path: `/admin/collections`, breadcrumbName: t('Collections') },
        {
          path: `/admin/collections/${collectionId}`, breadcrumbName:
            <Group gap="xs" align="center">
              <IconBook2 size={18} />
              <Text size="xs">
                {collectionId}
              </Text>
            </Group>
        },
        { path: `/admin/collections/${collectionId}/documents`, breadcrumbName: t('Documents') },
      );
    }
    else {
      setHeader(t('Documents'), 'Documents', <IconFileText size={34} />, t('Documents') as string, true, true);
      routes.push(
        { path: `/admin`, breadcrumbName: t('Home') },
        { path: `/admin/documents`, breadcrumbName: t('Documents') }
      );
    }
    setRoutes(routes);
  }, []);

  useEffect(() => {
    if (query) {
      load(query);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [JSON.stringify(query)]);

  const load = async (query: Query) => {
    summaryStore.load(query);
  };

  const onReload = () => {
    load(query);
  };

  useEffect(() => {
    summaryStore.setCollection(collectionSelected);
    const newQuery = {
      ...query,
      orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
      skip: 0,
      take: 20,
      parameters: {
        collectionId: collectionSelected,
      },
    } as Query;
    setQuery(newQuery);
  }, [collectionSelected]);

  // useEffect(() => {
  //   summaryStore.setCollection(collectionId as string);
  //   const newQuery = {
  //     ...query,
  //     orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
  //     skip: 0,
  //     take: 20,
  //     parameters: {
  //       collectionId: collectionId,
  //     },
  //   } as Query;
  //   setQuery(newQuery);

  //   setCollectionSelected(collectionId as string);
  //   if (setCollection) {
  //     setCollection(collectionId as string);
  //   }
  // }, [collectionId]);

  const onCollectionSelected = (collectionId: string) => {
    setCollectionSelected(collectionId);
    if (setCollection) {
      setCollection(collectionId);
    }
  };

  useBus(
    '@@ui/DOCUMENT_LIST_REFRESH',
    () => onReload(),
    [query],
  );

  const model: SimpleListV2Model<DocumentSummary> = {
    data: summaryStore.toListState(summaryState.value),
    query,
    columns: [
      {
        accessor: 'title',
        title: t('Title'),
        render: (item: DocumentSummary) => (
          <Anchor component={Link} to={`/admin/collections/${item.collectionId}/documents/${item.id}`} underline="never" variant="text">
            <Stack gap="xs" style={{ minHeight: 60 }}>
              <Title order={5} className={classes.titleList}>{item.title}</Title>

              <Group gap="xs">
                {item.currentSnapshotDateTime &&
                  <Tooltip label={<Text size='sm'>{moment(item.currentSnapshotDateTime).toString()}</Text>}>
                    <Badge variant='light' leftSection={<IconClock size={16} />}>{moment(item.currentSnapshotDateTime).fromNow()}</Badge>
                  </Tooltip>
                }
                <StatusBadge status={item.status} />
                <JobStatus status={item.trainingStatus} jobReference={item.jobReference} />
              </Group>

              <Box>
                <Box mt={5} mr={5} style={{ float: 'left' }}>
                  <IconBook2 size={18} />
                </Box>
                {item.collectionTitle}
              </Box>

              <Text c="dimmed" size="xs">
                {!item.description && t('(No description)')}
                {item.description}
              </Text>

              {item.tags && Object.keys(item.tags).length > 0 &&
                <Group gap="xs" align="center">
                  <IconTags size={18} />
                  {item.tags && Object.keys(item.tags || {}).map((t) => (
                    <Badge size="xs" radius="sm" p="xs" key={t} className={classes.tag}>{t}: {item.tags[t]}</Badge>
                  ))}
                </Group>
              }
            </Stack>
          </Anchor>
        )
      },
    ]
  };

  const getScrollAreaHeight = () => {
    return showFilters ? height - 210 : height - 90;
  }

  return (
    <Stack gap="xs">
      {showFilters &&
        <>
          <Text size="sm" fw={500}>
            {t('Filter by collection')}
          </Text>
          <Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flexGrow: 1 }}>
                <CollectionSelector value={collectionSelected} onChange={(value) => onCollectionSelected(value)} width='100%' clearable />
              </div>
              <div style={{ flex: '0 0 35px' }}>
                <Group gap="xs" align="center" justify="flex-end">
                  <Tooltip label={t('Go to collection')} withArrow>
                    <ActionIcon disabled={!collectionSelected} variant='subtle' color="gray" onClick={() => navigate(`/admin/collections/${collectionSelected}`)}>
                      <IconArrowBigRight />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </div>
            </div>
          </Box>
        </>
      }
      <SimpleListV2
        idAccessor={'id'}
        rowStyle={({ id }) => (id === documentId ? { backgroundColor: 'var(--mantine-primary-color-light)' } : undefined)}
        model={model}
        onQueryChanged={setQuery}
        onRefresh={onReload}
        height={getScrollAreaHeight()}
        showFilters={showFilters}
        searchByTag
      />
    </Stack>
  );
};

export default DocumentList;

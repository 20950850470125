import { Button, Group, Text } from "@mantine/core";
import { FC, RefObject, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { container } from "src/inversify.config";
import { ServiceConnectionCategory, ServiceConnectionType, TestServiceConnectionItem, TestServiceConnectionStore } from "src/stores/serviceconnections";
import { IconCircleCheck, IconCircleX, IconPlug } from "@tabler/icons-react";
import { ServiceConnectionRef } from "./services/serviceconnection";

const TestConnectionButton: FC<{
  serviceConnectionRef?: RefObject<ServiceConnectionRef>,
  content?: string;
  category: ServiceConnectionCategory,
  type: ServiceConnectionType
}> = ({ serviceConnectionRef, content, category, type }) => {
  const { t } = useTranslation();
  const testStore = useMemo(() => container.get(TestServiceConnectionStore), []);
  const testState = testStore.state;
  const [testResult, setTestResult] = useState<boolean | undefined>(undefined);

  const onTestConnection = async () => {
    setTestResult(undefined);
    testStore.clearError();
    const contentToTest = content || (serviceConnectionRef ? (serviceConnectionRef.current?.getContent() as string) : '');
    const item = {
      category: category,
      type: type,
      content: contentToTest
    } as TestServiceConnectionItem;

    await testStore.test(item);
    setTestResult(!testState.errorMessage.value);
  }

  useEffect(() => {
    testStore.clearError();
  }, [category, type])

  return (
    <>
      {testResult &&
        <Group align="center" gap="xs">
          <IconCircleCheck color="green" />
          <Text size="sm">{t("Connection established successfully!")}</Text>
        </Group>
      }
      {testState.errorMessage.value &&
        <Group align="center" gap="xs">
          <IconCircleX color="red" />
          <Text size="sm">{t("Connection error:")} {testState.errorMessage.value}</Text>
        </Group>
      }
      <Button
        loading={testState.isBusy.value}
        onClick={() => onTestConnection()}
        variant="outline"
        leftSection={<IconPlug />}>
        {t("Test connection")}
      </Button>
    </>
  );
};

export default TestConnectionButton;
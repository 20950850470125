export function getLS(key: string) {
  if (global.localStorage) {
    try {
      return global.localStorage.getItem(key);
    } catch (e) {
      /*Ignore*/
    }
  }
  return null;
}

export function saveLS(key: string, value: any) {
  if (global.localStorage) {
    global.localStorage.setItem(key, value.toString());
  }
}

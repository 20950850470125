import { useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { FC } from "react";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
//import Mermaid from "../mermaid-component";
import { useViewportSize } from '@mantine/hooks';

const ChatMessageMarkdown: FC<{ text: string; }> = ({ text }) => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const { width } = useViewportSize();

  const markdownOptions = {
    overrides: {
      p: {
        props: {
          className: 'mdparagraph',
          style: {
            "margin-block-start": "0.5em",
            "margin-block-end": "0.5em"
          }
        }
      },
      table: {
        props: {
          className: 'mdtable',
        },
      },
    },
    renderRule(next: any, node: any, renderChildren: any, state: any) {
      if (node.type === "15") {
        try {
          return <a href={transformLink(node.target)} title={node.title} style={{ textDecoration: 'none' }}>{renderChildren(node.children)}</a>
        }
        catch {
          return next()
        }
      } else if (node.type === "3" || node.type === "5") {
        try {
          if (node.lang === 'latex' || node.lang === 'math') {
            return <InlineMath math={renderChildren(node.children)} />
          }
          //if (node.lang === 'mermaid') {
          //  return <Mermaid>{node.text}</Mermaid>
          //}
          if (node.lang === 'chart') {
            let chName = 'chart';
            return <iframe id={chName} name={chName} title={chName} srcDoc={node.text} allowFullScreen scrolling="no" frameBorder={0} width={width > 600 ? 600 : width} height={450} />
          }
          if (node.text && node.text.startsWith("$$")) {
            return <InlineMath math={node.text.replace(/^\$+|\$+$/gm, '')} />
          }
          if (node.lang && node.text) {
            return <SyntaxHighlighter
              children={String(node.text).replace(/\n$/, '')}
              language={node.lang}
              style={vs}
            />
          }
          return next()
        }
        catch {
          return next()
        }
      }
      try {
        return next()
      }
      catch {
        return <></>
      }
    },
  } as MarkdownToJSX.Options;

  const parseMarkdown = (text: string) => {
    let result = text.replace(/\$[^$\r\n\s]+\$/gm, function (value: string) {
      return `$${value}$`
    })
    result = result.replace(/[\s\r\n]\$\$[^$]+\$\$/gm, function (value: string) {
      return `${value[0]}\`${value}\``
    })
    result = result.replace(/\\{1,}\[[^\]]+\\{1,}\]/gm, function (value: string) {
      return `\`$$${value.replace(/^\\{1,}\[|\\{1,}\]$/gm, '').trim()}$$\``
    })
    result = result.replace(/\\{1,}\([^)]+\\{1,}\)/gm, function (value: string) {
      return `\`$$${value.replace(/^\\{1,}\(|\\{1,}\)$/gm, '').trim()}$$\``
    })
    return result;
  }

  const transformLink = (link: string) => {
    if (link.startsWith("#")) {
      link = link.replace(/\+/g, '%20');
      return `javascript:ask('${decodeURIComponent(link).slice(1)}')`;
    }
    return `javascript:window.open('${link}', '_blank')`;
  }

  return (
    <Markdown
      className='mdchat'
      options={markdownOptions}
      style={{ backgroundColor: 'transparent', fontSize: 18, color: colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.dark[7] }}>
      {parseMarkdown(text)}
    </Markdown>
  );
}

export default ChatMessageMarkdown;
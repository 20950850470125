import { Group, Stack, TextInput, Select, PasswordInput, Table, ActionIcon, Center, Input, ScrollArea, Tooltip, Text, SimpleGrid, Switch, Textarea, Alert, ComboboxItem, Tabs, SegmentedControl } from '@mantine/core';
import React, { FC, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { State, none, useHookstate } from '@hookstate/core';
import AppContext from 'src/services/app-context';
import { CrawlerRef } from './crawlers';
import { GetSharePointCrawlerDefaultContent, SharePointCrawlerContent } from 'src/stores/crawlers';
import { IconCode, IconList, IconPlus, IconTrash, IconWorldWww } from '@tabler/icons-react';
import { LanguageSeletor } from 'src/components/language-selector';
import { BasicMultiselect } from 'src/components/basic-multiselect';
import { ServiceConnectionSelector } from '../../../../components/service-selector';

const CrawlerWebEditLanguages: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  return (
    <LanguageSeletor
      required
      readOnly={!edit}
      variant={edit ? undefined : 'unstyled'}
      multiselect
      label={label}
      value={scopedState.value as string[]}
      onChange={(value) => { scopedState.set(value as string[]); setIsDirty(true); }}
    />
  );
}

const CrawlerWebEditAudiences: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const getAudiencesData = () => {
    let data = [] as ComboboxItem[];
    if (scopedState.value) {
      data = scopedState.value.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  return (
    <BasicMultiselect
      label={label}
      data={getAudiencesData()}
      value={scopedState.value as string[]}
      onChange={(value) => { scopedState.set(value); setIsDirty(true); }}
      placeholder={t("Select audiences") as string}
      variant={edit ? undefined : 'unstyled'}
      creatable
    />
  );
}

const CrawlerWebEditList: FC<{
  state: State<string[]>;
  edit?: boolean;
  label: string;
  placeholder?: string;
  required?: boolean;
}> = ({ state, edit, label, placeholder, required = false }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  const onAddItem = () => {
    if (scopedState?.value) {
      scopedState[scopedState.length].set('');
    }
    else {
      scopedState.merge(['']);
    }
    setIsDirty(true);
  }

  return (
    <Input.Wrapper
      label={
        <Group align="center" gap={5}>
          <Text size="sm">{label}</Text>
          {required &&
            <Text c="red">*</Text>
          }
          <Tooltip withinPortal label={t("Add item")}>
            <ActionIcon onClick={onAddItem} variant='subtle' color="gray">
              <IconPlus size={14} />
            </ActionIcon>
          </Tooltip>
        </Group>}>
      <div style={{ position: 'relative' }}>
        <ScrollArea style={{ minHeight: 150, height: '50svh' }} offsetScrollbars>
          {scopedState?.value && scopedState.map((item, index) => (
            <Group key={index} mt="xs" align="center" gap={5}>
              <TextInput
                required={edit}
                readOnly={!edit}
                variant={edit ? 'default' : 'unstyled'}
                placeholder={placeholder}
                style={{ flex: 1 }}
                width="100%"
                value={item.value}
                onChange={(event) => { item.set(event.target.value); setIsDirty(true); }}
              />
              {edit &&
                <ActionIcon
                  color="red"
                  variant='subtle'
                  onClick={() => { item.set(none); setIsDirty(true); }}>
                  <IconTrash />
                </ActionIcon>
              }
            </Group>
          ))}
          {scopedState?.value?.length === 0 &&
            <Center>
              <Text c="dimmed" size="sm">{t('No items')}</Text>
            </Center>
          }
        </ScrollArea>
      </div>
    </Input.Wrapper>
  );
}

export const SharepointCrawler = React.forwardRef<CrawlerRef, { content?: string, canContribute?: boolean }>(({ content, canContribute }, ref) => {
  const { t } = useTranslation();
  const scopedState = useHookstate({} as SharePointCrawlerContent);
  const [existingAuth, setExistingAuth] = useState(false);

  useEffect(() => {
    if (content) {
      const current = JSON.parse(content) as SharePointCrawlerContent;
      scopedState.set(current);
      if (current.service && current.service !== '') {
        setExistingAuth(true)
      } else {
        if (!scopedState.clientId.value) {
          setExistingAuth(true)
        }
      }
    }
    else {
      scopedState.set(GetSharePointCrawlerDefaultContent());
      setExistingAuth(true)
    }
  }, [content])

  useEffect(() => {
    if (existingAuth && scopedState.authentication.value !== 'OAuth') {
      scopedState.authentication.set('OAuth');
    }
  }, [existingAuth])


  const isValid = () => {
    let valid = scopedState.targetUris?.value.length > 0 && scopedState.languages?.value?.length > 0 && scopedState.api?.value?.length > 0 &&
      scopedState.authentication?.value?.length > 0;

    if (existingAuth) {
      valid = valid && (scopedState.service?.value?.length ?? 0) > 0;
    }
    else {
      if (scopedState.authentication.value === 'Forms') {
        valid = valid && scopedState.clientSecret?.value?.length > 0;
      }

      if (scopedState.authentication.value === 'OAuth') {
        valid = valid && scopedState.tenantId?.value?.length > 0 &&
          (scopedState.clientSecret?.value?.length > 0 || (scopedState.certificate?.value?.length > 0 && scopedState.privateKey?.value?.length > 0));
      }
    }

    return valid;
  }

  const getResume = () => (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("URLs to crawl")}</Table.Td>
          <Table.Td>{scopedState.targetUris.length}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Languages")}</Table.Td>
          <Table.Td>{scopedState.languages.value.toString()}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Lists")}</Table.Td>
          <Table.Td>{t("White list")}: {scopedState.whiteList.length}, {t("Black list")}: {scopedState.blackList.length}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Audiences")}</Table.Td>
          <Table.Td>{scopedState.audience.value.toString()}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Populate audiences from SharePoint permissions")}</Table.Td>
          <Table.Td>{scopedState.importAudience.value.toString()}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("Api and authentication")}</Table.Td>
          <Table.Td>{scopedState.api.value === 'GraphApi' ? t('Graph API') : scopedState.api.value === 'RestApi' ? t('SharePoint REST API') : ''}, {scopedState.authentication.value === 'Forms' ? t('Forms (Username/Password)') : scopedState.authentication.value === 'OAuth' ? t('OAuth (Client credentials)') : ''}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )

  useImperativeHandle(ref, () => ({
    getContent: () => JSON.stringify(scopedState.value, null, 2),
    isValid: () => isValid(),
    getResume: () => getResume()
  }));

  return (
    <Tabs variant="outline" orientation="vertical" defaultValue="web">
      <Tabs.List>
        <Tabs.Tab value="web" leftSection={<IconWorldWww size={20} />}>{t("Targets")}</Tabs.Tab>
        <Tabs.Tab value="lists" leftSection={<IconList size={20} />}>{t("Lists")}</Tabs.Tab>
        <Tabs.Tab value="code" leftSection={<IconCode size={20} />}>{t("Code")}</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="web" pl="xs">
        <SimpleGrid cols={2}>
          <Stack>
            <CrawlerWebEditList
              key={'web-targetsUris'}
              label={t("URLs to crawl")}
              state={scopedState.targetUris}
              placeholder={t('Url') as string}
              edit={canContribute}
              required={canContribute}
            />
          </Stack>
          <Stack justify="flex-start" gap={5}>
            <CrawlerWebEditLanguages
              label={t('Languages')}
              state={scopedState.languages}
              edit={canContribute}
              key={'web-languages'}
            />
            <CrawlerWebEditAudiences
              label={scopedState.importAudience.value ? t('Default audiences') : t('Audiences')}
              state={scopedState.audience}
              edit={canContribute}
              key={'web-audiences'}
            />
            <Switch
              mt="sm"
              mb="sm"
              label={t("Populate audiences from SharePoint permissions")}
              checked={scopedState.importAudience.value}
              disabled={scopedState.api.value !== 'GraphApi'}
              onChange={e => { scopedState.importAudience.set(e.target.checked); }}
            />
            <Switch
              mt="sm"
              mb="sm"
              label={t("Include collection members in audience")}
              checked={scopedState.includeMembers.value}
              onChange={e => { scopedState.includeMembers.set(e.target.checked); }}
            />
            {scopedState.importAudience.value && scopedState.api.value !== 'GraphApi' && <Alert color='yellow'>
              {t("Currently, audiences can be populated from SharePoint only using Graph API")}
            </Alert>}
            {scopedState.importAudience.value && scopedState.api.value === 'GraphApi' && <Alert color='yellow'>
              {t("SharePoint site groups assignments are not supported and will be ignored. Only Active Directory groups will be used when populating the audiences. Explicit permissions hierarchies are not supported and will be ignored. If a folder or item inherits permissions, will be assumed that is directly inheriting permissions from the parent site.")}
            </Alert>}
            <SegmentedControl
              value={existingAuth ? 'existing' : 'custom'}
              onChange={value => { setExistingAuth(value === 'existing') }}
              data={[
                { label: t('Existing Identity provider'), value: 'existing' },
                { label: t('Custom provider'), value: 'custom' },
              ]}
            />
            {existingAuth && <Group grow>
              {existingAuth && <ServiceConnectionSelector
                required
                width="100%"
                label={t("Identity provider") as string}
                categoryFilter='Identities'
                value={scopedState.service.value}
                onChange={(value) => scopedState.service.set(value as string)}
              />}
              <Select
                required
                allowDeselect={false}
                style={{ flex: 1 }}
                label={t("API")}
                placeholder={t("API") as string}
                data={[
                  { value: 'RestApi', label: t('SharePoint REST API') as string },
                  { value: 'GraphApi', label: t('Graph API') as string },
                ]}
                value={scopedState.api.value}
                onChange={(value) => { scopedState.api.set(value as any); }}
              />
            </Group>}
            {!existingAuth && <>
              <Group>

                <Select
                  required
                  allowDeselect={false}
                  style={{ flex: 1 }}
                  label={t("Authentication")}
                  placeholder={t("Authentication") as string}
                  data={[
                    { value: 'Forms', label: t('Forms (Username/Password)') as string },
                    { value: 'OAuth', label: t('OAuth (Client credentials)') as string },
                  ]}
                  value={scopedState.authentication.value}
                  onChange={(value) => { scopedState.authentication.set(value as any); }}
                />
              </Group>

              <Stack justify="flex-start" gap={5}>
                <TextInput
                  data-autofocus
                  style={{ flex: 1 }}
                  label={scopedState.authentication.value && scopedState.authentication.value === 'OAuth' ? t('Client Id') : t('Username')}
                  required
                  value={scopedState.clientId.value}
                  onChange={(event) => { scopedState.clientId.set(event.target.value); }}
                />

                <PasswordInput
                  required={scopedState.authentication.value === 'Forms'}
                  style={{ flex: 1 }}
                  label={scopedState.authentication.value && scopedState.authentication.value === 'OAuth' ? t('Client secret') : t('Password')}
                  value={scopedState.clientSecret.value}
                  onChange={(event) => { scopedState.clientSecret.set(event.target.value); }}
                />
              </Stack>

              {scopedState.authentication.value && scopedState.authentication.value === 'OAuth' &&
                <TextInput
                  data-autofocus
                  style={{ flex: 1 }}
                  label={t('Tenant Id')}
                  required
                  value={scopedState.tenantId.value}
                  onChange={(event) => { scopedState.tenantId.set(event.target.value); }}
                />
              }

              {scopedState.authentication.value && scopedState.authentication.value === 'OAuth' &&
                <>
                  <Textarea
                    style={{ flex: 1 }}
                    label={t('Certificate (PEM)')}
                    value={scopedState.certificate.value}
                    rows={6}
                    placeholder='-----BEGIN CERTIFICATE----- ... -----END CERTIFICATE-----'
                    onChange={(event) => { scopedState.certificate.set(event.target.value); }}
                  />

                  <Textarea
                    style={{ flex: 1 }}
                    label={t('Private key (PEM)')}
                    value={scopedState.privateKey.value}
                    rows={6}
                    placeholder='-----BEGIN RSA PRIVATE KEY----- ... -----END RSA PRIVATE KEY-----'
                    onChange={(event) => { scopedState.privateKey.set(event.target.value); }}
                  />
                </>
              }
            </>}
          </Stack>
        </SimpleGrid>
      </Tabs.Panel>

      <Tabs.Panel value="lists" pl="xs">
        <SimpleGrid cols={2}>
          <Stack>
            <CrawlerWebEditList
              key={'web-whiteList'}
              label={t("White list")}
              state={scopedState.whiteList}
              placeholder={t('Regex') as string}
              edit={canContribute}
            />
          </Stack>
          <Stack>
            <CrawlerWebEditList
              key={'web-blackList'}
              label={t("Black list")}
              state={scopedState.blackList}
              placeholder={t('Regex') as string}
              edit={canContribute}
            />
          </Stack>
        </SimpleGrid>
      </Tabs.Panel>
    </Tabs>
  );
});

export default SharepointCrawler;

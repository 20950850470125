import { FC } from 'react'; import {
  Routes,
  Route
} from "react-router-dom";
import { useLocation } from "react-router";
import BotLanding from './bot-landing';
import BotDetail from './bot-detail';
import BotEdit from './bot-edit';

const BotsIndex: FC = () => {
  const location = useLocation();
  return <Routes>
    <Route path=":botId/edit" element={<BotEdit />} key={location.pathname} />
    <Route path=":botId" element={<BotDetail />} key={location.pathname} />
    <Route index element={<BotLanding />} />
  </Routes>
}

export default BotsIndex;

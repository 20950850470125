import { State, none, useHookstate } from "@hookstate/core";
import { Group, Input, Select, SimpleGrid, Stack, Text, Textarea } from "@mantine/core";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import { BotContentLlm, BotLlmOrchestratorType, BotStrategy, GetBotDefaultContent } from "src/stores/bots";
import { IconBrain } from "@tabler/icons-react";
import AppContext from "src/services/app-context";
import { ServiceConnectionSelector } from "src/components/service-selector";
import NumberInputWithControl from "src/components/number-input-with-control";
import SwitchButton from "src/components/switch-button";
import classes from 'src/pages/index.module.css';
import TemperatureSelector from "src/components/temperature-selector";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";
import { container } from "src/inversify.config";
import { AppConfiguration } from "src/core/services/authentication-service";

const BotContentLlmCard: FC<{
  state: State<BotContentLlm>;
  cardkey: string;
  edit?: boolean;
  botStrategy: BotStrategy;
}> = ({ state, cardkey, edit = false, botStrategy }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(Object.keys(scopedState.behavior?.value)[0] ?? defaultAllowedLanguages[0]);
  const defaultBotContent = GetBotDefaultContent('Llm', t);

  const onChangeBehavior = (value: string) => {
    scopedState.behavior[selectedLanguage].set(value);
    setIsDirty(true);
  }

  const onDeleleLanguage = (lang: string) => {
    scopedState.behavior[lang].set(none);
    setIsDirty(true);
    setSelectedLanguage(Object.keys(scopedState.behavior?.value)[0]);
  }

  const onAddLanguage = (lang: string) => {
    scopedState.behavior[lang].set(defaultBotContent.llm.behavior[lang]);
    setIsDirty(true);
  }

  return (
    <CollapsibleCard
      title={
        <Group justify='space-between'>
          <Text fw={500}>{t('LLM settings')}</Text>
        </Group>
      }
      cardKey={cardkey}
      icon={<IconBrain />}
      rightToolbar={
        <LanguageSegmentedControl
          editable={edit}
          data={Object.keys(scopedState.behavior?.value)}
          language={selectedLanguage}
          onChange={setSelectedLanguage}
          onDelete={onDeleleLanguage}
          onAdd={onAddLanguage}
        />
      }>
      {scopedState.value && <Stack>
        <Text c="dimmed">{t("Below are different options that you can customize in the Llm service.")}</Text>

        <Textarea
          label={t("Behavior")}
          autosize
          minRows={3}
          maxRows={20}
          required={edit}
          readOnly={!edit}
          variant={edit ? 'default' : 'unstyled'}
          style={{ flex: 1 }}
          value={scopedState.behavior[selectedLanguage]?.value ?? ''}
          onChange={(event) => onChangeBehavior(event.target.value)}
        />

        <SimpleGrid
          cols={{ base: 1, xl: 3 }}
          spacing="sm">
          <ServiceConnectionSelector
            label={t("Service connection") as string}
            required={edit}
            width='100%'
            readOnly={!edit}
            categoryFilter='Llm'
            value={scopedState.service.value}
            onChange={(value) => { scopedState.service.set(value as string); setIsDirty(true); }}
          />

          <Select
            required
            label={t("Orchestrator")}
            data={[
              { value: 'None', label: t('None') as string },
              { value: 'Functions', label: t('OpenAI Functions') as string },
              { value: 'Simple', label: t('Simple orchestrator (RAG)') as string },
              /*{ value: 'ReAct', label: t('Reason and act (ReAct)') as string },*/
            ]}
            defaultValue='None'
            value={scopedState.orchestratorType.value}
            onChange={(value) => { scopedState.orchestratorType.set(value as BotLlmOrchestratorType); setIsDirty(true); }}
            readOnly={!edit}
            variant={edit ? 'default' : 'unstyled'}
          />

          <Group align="flex-start" grow>
            <Input.Wrapper label={t("Temperature")}>
              <TemperatureSelector
                fullWidth
                size="sm"
                value={scopedState.temperature.value?.toString() ?? "0.5"}
                onChange={value => {
                  scopedState.temperature.set(parseFloat(value));
                }}
                edit={edit}
              />
            </Input.Wrapper>
          </Group>

          {botStrategy === 'Llm' && <NumberInputWithControl
            label={t("Maximum number of functions")}
            required={edit}
            width="100%"
            description={t("Maximum number of functions that will be used by the Llm service.") as string}
            min={0}
            step={1}
            max={32}
            value={scopedState.maxFunctions.value ?? 4}
            onChange={(value) => scopedState.maxFunctions.set(value as number)}
            readOnly={!edit}
          />}

          <NumberInputWithControl
            required={edit}
            width="100%"
            label={t("Maximum tokens per response")}
            description={t("The maximum number of tokens to generate in the response.") as string}
            min={50}
            step={512}
            max={32000}
            value={scopedState.maxTokensResponse.value ?? 4096}
            onChange={(value) => { scopedState.maxTokensResponse.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />

          {botStrategy === 'Llm' && <NumberInputWithControl
            required={edit}
            width="100%"
            label={t("Message expiration in seconds")}
            description={t("Time in seconds it takes for messages to expire. Once expired they will not be used in the request.") as string}
            min={60}
            step={30}
            value={scopedState.messageExpiration.value ?? 300}
            onChange={(value) => { scopedState.messageExpiration.set(value as number); setIsDirty(true); }}
            readOnly={!edit}
          />}

        </SimpleGrid>

        {botStrategy === 'Llm' && <SimpleGrid
          cols={{ base: 1, xl: 2 }}
          spacing="sm">
          <SwitchButton
            className={classes.switchButton}
            label={t("Enable response streaming")}
            description={t("Streams the response as it's being generated.") as string}
            checked={scopedState.stream.value ?? false}
            onChange={value => { scopedState.stream.set(value); setIsDirty(true); }}
            readOnly={!edit}
          />
          <SwitchButton
            className={classes.switchButton}
            label={t("Enable saving snippets")}
            description={t("Enable the ability to save and retrieve information for future reference. This feature allows you to store data for quick and efficient access when needed.") as string}
            checked={scopedState.enableSaveSnippet.value ?? false}
            onChange={value => { scopedState.enableSaveSnippet.set(value); setIsDirty(true); }}
            readOnly={!edit}
          />
        </SimpleGrid>}
      </Stack>}
    </CollapsibleCard>
  );
};

export default BotContentLlmCard;
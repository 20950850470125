import { Group, ActionIcon, Text, Stack, List, Tooltip, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { FileWithPath, Dropzone, MIME_TYPES, FileRejection } from "@mantine/dropzone";
import { IconCircleCheck, IconCircleX, IconTrash } from "@tabler/icons-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { allowedMimeTypes } from "../../../stores/bots";

const DocumentsInputFileZone: FC<{ onSave: (files: FileWithPath[] | undefined) => void, onDelete: () => void }> = ({ onSave, onDelete }) => {
  const [importFiles, setImportFiles] = useState<FileWithPath[]>();
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const [rejectFiles, setRejectFiles] = useState<FileRejection[] | undefined>(undefined);

  const onDrop = (files: FileWithPath[]) => {
    if (files && files.length > 0) {
      setImportFiles(files);
      onSave(files);
    }
  }

  const onDeleteFiles = () => {
    setImportFiles(undefined);
    setRejectFiles(undefined);
    onSave(undefined);
    onDelete();
  }

  return (
    <>
      {importFiles &&
        <Stack>
          <Group>
            <IconCircleCheck color={colorScheme === 'dark' ? theme.colors.green[9] : theme.colors.green[7]} />
            <Text>{t("Files loaded correctly")}: {importFiles.length}</Text>
            <Text>{t("Rejected files")}: {rejectFiles?.length ?? 0}</Text>
            <Tooltip label={t("Reset uploaded files")}>
              <ActionIcon color="red" variant='subtle' onClick={onDeleteFiles}>
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Group>
          {rejectFiles && rejectFiles.length > 0 &&
            <Stack>
              <Text>{t("The following files have been rejected")}:</Text>
              <List spacing="xs" size="xs" center>
                {rejectFiles.map((r, i) => (
                  <List.Item
                    key={i}
                    icon={<IconCircleX color={colorScheme === 'dark' ? theme.colors.red[9] : theme.colors.red[7]} />}>
                    <Text size="sm">{r.file.name}: {r.errors[0].message}</Text>
                  </List.Item>
                ))}
              </List>
            </Stack>
          }
        </Stack>
      }
      {!importFiles &&
        <Dropzone
          onDrop={onDrop}
          onReject={(files) => { console.log('rejected files', files); setRejectFiles(files) }}
          maxSize={50 * 1024 ** 2} // 50 mb
          maxFiles={10} // 10 archivos
          accept={allowedMimeTypes}
          multiple={true}>
          <Group justify="center" gap="md" style={{ pointerEvents: 'none' }}>
            <div>
              <Text size="xl" inline>
                {t("Drag files here or click to select files")}
              </Text>
              <Text size="sm" c="dimmed" inline mt={7}>
                {t("Attach PDF or Office files without exceeding")} 50 MB
              </Text>
            </div>
          </Group>
        </Dropzone>
      }
    </>
  );
}

export default DocumentsInputFileZone;
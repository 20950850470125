import { State, useHookstate } from "@hookstate/core";
import { Card, Group, Stack, Text } from "@mantine/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import { IconPlugConnected as PlugConnected } from "@tabler/icons-react";
import SkillLogApiKeys from "./skill-logs-apikeys";
import { SkillItem } from "src/stores/skills";
import classes from 'src/pages/index.module.css';

const SkillApiDetails: FC<{
  state: State<SkillItem>;
  cardkey: string;
  edit?: boolean;
}> = ({ state, cardkey, edit = false }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);

  return (
    <Stack>
      <CollapsibleCard
        title={<Text fw={500}>{t('API details')}</Text>}
        cardKey={cardkey}
        icon={<PlugConnected />}>
        <Stack gap="xs">
          <Card
            withBorder
            shadow="xs"
            radius="sm"
            className={classes.stepCard}>
            <Card.Section withBorder inheritPadding py={5}>
              <Group justify="space-between">
                <Text fw={500}>{t("Log API keys")}</Text>
              </Group>
            </Card.Section>
            <Card.Section inheritPadding mt="xs" pb="xs">
              <SkillLogApiKeys
                key={`skilllogapikeys-${scopedState.id.value}`}
                state={scopedState}
                edit={edit}
              />
            </Card.Section>
          </Card>
        </Stack>
      </CollapsibleCard>
    </Stack>
  );
};

export default SkillApiDetails;
import { ActionIcon, Container, Group, Loader, Title, Tooltip, Grid, Box } from '@mantine/core';
import { FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderContext from 'src/services/header-context';
import { IconFilter as Filter, IconFilterOff as FilterOff, IconX, IconPlus as Plus } from '@tabler/icons-react';
import classes from 'src/pages/index.module.css';

const SideBarWrapper: FC<{
  title?: ReactNode,
  isBusy?: boolean,
  children: any,
  showFiltersIcon?: boolean,
  showFiltersValue?: boolean,
  onChangeShowFiltersValue?: (value: boolean) => void,
  showNewIcon?: boolean,
  onNewItemClick?: () => void,
  moreIcons?: ReactNode;
  hideCloseIcon?: boolean;
  onCloseMenu?: () => void;
}> = ({ title, isBusy, children, showFiltersIcon, showFiltersValue, onChangeShowFiltersValue, showNewIcon, onNewItemClick, moreIcons, hideCloseIcon = false, onCloseMenu }) => {
  const { t } = useTranslation();
  const { setSideMenuOpened } = useContext(HeaderContext);

  const onToggleFilters = () => {
    if (onChangeShowFiltersValue) {
      onChangeShowFiltersValue(!showFiltersValue);
    }
  }

  const onCloseSideBar = () => {
    setSideMenuOpened(false);
    onCloseMenu?.();
  }

  return (
    <>
      <Box className={classes.sideMenuHeader}>
        <Grid p="xs" align='center'>
          <Grid.Col span="content"><Group gap="xs"><Title order={5}>{title}</Title>{isBusy && <Loader size="xs" />}</Group></Grid.Col>
          <Grid.Col span="auto">
            <Group gap="xs" align="center" justify='flex-end'>
              {moreIcons}
              {showNewIcon &&
                <Tooltip label={t("Create new item")}>
                  <ActionIcon variant='subtle' color='gray' onClick={onNewItemClick}>
                    <Plus />
                  </ActionIcon>
                </Tooltip>
              }
              {showFiltersIcon &&
                <Tooltip label={showFiltersValue ? t("Hide filters") : t("Show filters")}>
                  <ActionIcon variant='subtle' color='gray' onClick={onToggleFilters}>
                    {showFiltersValue ? <FilterOff /> : <Filter />}
                  </ActionIcon>
                </Tooltip>
              }
              {!hideCloseIcon &&
                <Tooltip label={t("Close sidebar")}>
                  <ActionIcon variant='subtle' color='gray' onClick={onCloseSideBar}>
                    <IconX />
                  </ActionIcon>
                </Tooltip>
              }
            </Group>
          </Grid.Col>
        </Grid>
      </Box>
      <Container size="xs" py="xs" px="xs">
        {children}
      </Container>
    </>
  );
};

export default SideBarWrapper;

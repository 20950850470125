import { none, State, useHookstate } from "@hookstate/core";
import { Group, Input, TextInput, Text, Stack, Code, Container, Select, Tooltip, ActionIcon, Textarea, useMantineColorScheme, InputWrapper } from "@mantine/core";
import { IconWindowMaximize, IconPlus, IconTrash } from "@tabler/icons-react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import { ContentKeyValue, SkillContentStep, SkillContentStepDataType } from "src/stores/skills";
import SwitchButton from "src/components/switch-button";
import classes from 'src/pages/index.module.css';
import { ServiceConnectionSelector } from "src/components/service-selector";
import { useDisclosure } from "@mantine/hooks";
import SkillDebugJinjaModal from "./skill-debug-jinja-modal";
import { Editor, useMonaco } from "@monaco-editor/react";
import { monacoConfiguration, monacoJinjaDef, monacoOptions } from "src/configurations/editor-config-jinja";
import SkillContentStepWrapper from "./skill-content-step-wrapper";

const SkillContentStepCallApi: FC<{
  state: State<SkillContentStep>;
  edit?: boolean;
  index: number;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ state, edit, index, leftToolbar, rightToolbar }) => {
  const scopedState = useHookstate(state.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const monaco = useMonaco();
  const [opened, { open, close }] = useDisclosure(false);
  const { colorScheme } = useMantineColorScheme();
  const [useOAuth, setUseOAuth] = useState(scopedState.oauthService?.value !== undefined && scopedState.oauthService?.value !== null && scopedState.oauthService?.value !== '');
  const [useResponseTransformer, setUseResponseTransformer] = useState(scopedState.transformer?.value !== undefined && scopedState.transformer?.value !== null && scopedState.transformer?.value !== '');
  const [selectedText, setSelectedText] = useState<{ text: string, field: "uri" | "body" } | undefined>(undefined);

  useEffect(() => {
    if (!useResponseTransformer) {
      scopedState.transformer.set(none);
    }
  }, [useResponseTransformer])

  useEffect(() => {
    if (!useOAuth) {
      scopedState.oauthService.set(undefined);
    }
  }, [useOAuth])

  const onAddHeader = () => {
    if (scopedState.headers?.value) {
      scopedState.headers[scopedState.headers.length].set({ key: '', value: '' } as ContentKeyValue);
    }
    else {
      scopedState.merge({ headers: [{ key: '', value: '' } as ContentKeyValue] });
    }
    setIsDirty(true);
  }

  useEffect(() => {
    if (scopedState.method?.value === 'GET') {
      scopedState.body.set('');
      scopedState.contentType.set(none);
    }
    if (scopedState.method?.value === 'POST' || scopedState.method?.value === 'PUT') {
      scopedState.contentType.set("application/json");
    }
  }, [scopedState.method?.value])

  useEffect(() => {
    if (monaco) {
      if (!monaco.languages.getLanguages().some((id: string) => id === 'jinja')) {
        // Register a new language
        monaco.languages.register({ id: 'jinja' });
        // Register a tokens provider for the language
        monaco.languages.setMonarchTokensProvider('jinja', monacoJinjaDef);
        // Set the editing configuration for the language
        monaco.languages.setLanguageConfiguration('jinja', monacoConfiguration);
      }
    }
  }, [monaco]);

  const onOpenDebugJinja = (field: "uri" | "body") => {
    const text = scopedState[field].value;
    setSelectedText({ text, field });
    open();
  }

  const onCloseDebugJinja = () => {
    setSelectedText(undefined);
    close();
  }

  const onSaveDebugJinja = (value: string) => {
    if (selectedText) {
      scopedState[selectedText.field].set(value);
    }
    onCloseDebugJinja();
  }

  return (
    <SkillContentStepWrapper
      index={index}
      state={state}
      edit={edit}
      leftToolbar={
        <Group align="center" gap={5}>
          {leftToolbar}
        </Group>
      }
      rightToolbar={rightToolbar}>
      {edit &&
        <Stack gap="xs">
          <Group align="flex-start">
            <Input.Label w={120} required={edit}>{t("URL")}:</Input.Label>
            <Select
              w={100}
              allowDeselect={false}
              data={[
                { value: 'GET', label: t('GET') as string },
                { value: 'POST', label: t('POST') as string },
                { value: 'PUT', label: t('PUT') as string }
              ]}
              value={scopedState.method.value}
              onChange={(value) => { scopedState.method.set(value as string); setIsDirty(true); }}
            />
            <Textarea
              autosize
              minRows={3}
              maxRows={10}
              required
              style={{ flex: 1 }}
              value={scopedState.uri.value}
              onChange={(event) => { scopedState.uri.set(event.target.value); setIsDirty(true); }}
              styles={{ section: { marginRight: "var(--mantine-spacing-md)", alignItems: 'flex-start' } }}
              rightSection={<Tooltip label={t("More options")}>
                <ActionIcon variant='subtle' color="gray" onClick={() => onOpenDebugJinja("uri")}>
                  <IconWindowMaximize size={20} />
                </ActionIcon>
              </Tooltip>}
            />
          </Group>

          <SwitchButton
            className={classes.switchButton}
            label={t("Enable OAuth authorization")}
            description={t("Runs OAuth authorization flow and obtains an access token") as string}
            checked={useOAuth}
            onChange={value => { setUseOAuth(value); }}>
            {useOAuth ?
              <Stack gap="xs">
                <Group align="flex-start" grow>
                  <ServiceConnectionSelector
                    required
                    width="100%"
                    label={t("Identity provider") as string}
                    categoryFilter='Identities'
                    value={scopedState.oauthService.value}
                    onChange={(value) => scopedState.oauthService.set(value as string)}
                  />
                </Group>
              </Stack>
              :
              null
            }
          </SwitchButton>

          <Group align="center">
            <Input.Label w={120}>{t("Headers")}:</Input.Label>
            <Group align="center" gap={5}>
              <Text size="sm">{scopedState.headers?.value?.length > 0 ? t("With headers") : t("Without headers")}</Text>
              <Tooltip withinPortal label={t("Add header")}>
                <ActionIcon onClick={onAddHeader} variant='subtle' color="gray">
                  <IconPlus size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
          {scopedState.headers.map((header, index) =>
            <Group key={index} align="center">
              <Input.Label w={120}></Input.Label>
              <TextInput
                required
                style={{ flex: 1 }}
                value={header.key.value}
                placeholder={t("<your header name>") as string}
                onChange={(event) => { header.key.set(event.target.value); setIsDirty(true); }}
              />
              :
              <TextInput
                required
                style={{ flex: 1 }}
                value={header.value.value}
                placeholder={t("<your header value>") as string}
                onChange={(event) => { header.merge(i => ({ value: event.currentTarget.value })); setIsDirty(true); }}
              />
              <ActionIcon onClick={() => { header.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                <IconTrash size={16} />
              </ActionIcon>
            </Group>
          )}

          {scopedState.method.value !== 'GET' &&
            <>
              <Group align="center">
                <Input.Label w={120} required={edit}>{t("Content type")}:</Input.Label>
                <TextInput
                  required
                  style={{ flex: 1 }}
                  value={scopedState.contentType.value ?? 'application/json'}
                  onChange={(event) => { scopedState.contentType.set(event.target.value); setIsDirty(true); }}
                />
              </Group>

              <Group align="flex-start">
                <Input.Label w={120} required={edit}>{t("Body")}:</Input.Label>
                <Textarea
                  autosize
                  minRows={3}
                  maxRows={10}
                  required
                  style={{ flex: 1 }}
                  value={scopedState.body.value}
                  onChange={(event) => { scopedState.body.set(event.target.value); setIsDirty(true); }}
                  styles={{ section: { marginRight: "var(--mantine-spacing-md)", alignItems: 'flex-start' } }}
                  rightSection={<Tooltip label={t("More options")}>
                    <ActionIcon variant='subtle' color="gray" onClick={() => onOpenDebugJinja("body")}>
                      <IconWindowMaximize size={20} />
                    </ActionIcon>
                  </Tooltip>}
                />
              </Group>
            </>
          }

          <SwitchButton
            className={classes.switchButton}
            label={t("Post-process API response")}
            description={t("Transforms the answer received from the API before storing it") as string}
            checked={useResponseTransformer}
            onChange={value => { setUseResponseTransformer(value); }}>
            {useResponseTransformer ?
              <Stack gap="xs">
                <Select
                  required
                  allowDeselect={false}
                  label={t("Transformer language")}
                  data={[
                    { value: 'jinja', label: t('Jinja') as string },
                    { value: 'jsonata', label: t('JSONata') as string },
                  ]}
                  value={scopedState.transformerLanguage.value ?? 'jsonata'}
                  onChange={(value) => { scopedState.transformerLanguage.set(value as string); }}
                />
                <Group align="flex-start" grow>
                  <InputWrapper label={t("Transformer code")} required>
                    <Editor
                      width="100%"
                      height="300px"
                      language="jinja"
                      options={monacoOptions}
                      value={scopedState?.transformer?.value ?? ''}
                      onChange={(value) => scopedState?.transformer.set(value as string)}
                      theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                    />
                  </InputWrapper>
                </Group>
              </Stack>
              :
              null
            }
          </SwitchButton>

          <Group align="center">
            <Input.Label w={120} required={edit}>{t("Store as")}:</Input.Label>
            <Select
              w={100}
              allowDeselect={false}
              defaultValue='Entity'
              data={[
                { value: 'Output', label: t('Output') },
                { value: 'Entity', label: t('Entity') }
              ]}
              value={scopedState.target.value}
              onChange={(value) => { scopedState.target.set(value as any); setIsDirty(true); }}
            />
            {scopedState.target.value && scopedState.target.value === 'Output' &&
              <Group gap={10}>
                <Text span>{t("as")}{' '}</Text>
                <Select
                  w={200}
                  allowDeselect={false}
                  defaultValue='String'
                  data={[
                    { value: 'String', label: t('Plain text (String)') as string },
                    { value: 'Json', label: t('JSON') as string },
                    // { value: 'SearchResult', label: t('Search result array') as string }
                  ]}
                  value={scopedState.targetFormat.value}
                  onChange={(value) => { scopedState.targetFormat.set(value as any); setIsDirty(true); }}
                />
              </Group>
            }

            {(!scopedState.target.value || scopedState.target.value === 'Entity') && <Group gap={10}>
              <Text size="sm">{t("as")}</Text>
              <Select
                allowDeselect={false}
                data={[
                  { value: 'String', label: t('String') as SkillContentStepDataType },
                  { value: 'Integer', label: t('Integer') as SkillContentStepDataType },
                  { value: 'Number', label: t('Number') as SkillContentStepDataType },
                  { value: 'Date', label: t('Date') as SkillContentStepDataType }
                ]}
                value={scopedState.dataType.value}
                onChange={(value) => { scopedState.dataType.set(value as SkillContentStepDataType); setIsDirty(true); }}
              />
            </Group>}

            {scopedState.target.value === 'Entity' &&
              <Group>
                <Text size="sm">{t("with name")}</Text>
                <TextInput
                  required
                  style={{ flex: 1 }}
                  value={scopedState.entity.value}
                  onChange={(event) => { scopedState.entity.set(event.target.value); setIsDirty(true); }}
                />
              </Group>}
          </Group>

          <SkillDebugJinjaModal value={selectedText?.text ?? ''} open={opened} onClose={onCloseDebugJinja} onSave={(value) => onSaveDebugJinja(value)} />
        </Stack>
      }
      {!edit &&
        <Stack gap="xs">
          <div>
            <Text fw={500}>{t("Call the following API")}</Text>
            <Code block
              style={{ fontSize: 16, whiteSpace: "break-spaces", wordBreak: "break-all" }}
              color="var(--mantine-primary-color-light)">
              {scopedState.method.value}: {scopedState.uri.value}
            </Code>
          </div>
          {scopedState.oauthService.value &&
            <div>
              <Text fw={500}>{t("With identity provider")}</Text>
            </div>
          }
          {scopedState.headers?.value?.length > 0 &&
            <div>
              <Text fw={500}>{t("With headers")}</Text>
              {scopedState.headers.map((header, index) =>
                <Code key={index} style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{header.key.value}: {header.value.value}</Code>
              )}
            </div>
          }
          {scopedState.contentType?.value?.length > 0 &&
            <div>
              <Text span fw={500}>{t("With content type")} </Text>
              <Code style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{scopedState.contentType.value}</Code>
            </div>
          }
          {scopedState.body?.value?.length > 0 &&
            <Container px={0} py={0} ml={0}>
              <Text fw={500}>{t("With body")}</Text>
              <Code block style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{scopedState?.body?.value ? JSON.parse(JSON.stringify(scopedState.body.value, null, 2)) : ''}</Code>
            </Container>
          }
          {useResponseTransformer &&
            <Container px={0} py={0} ml={0}>
              <Text fw={500}>{t("With Post-process API response")} {t("through")} <Text span fs="italic">{scopedState.transformerLanguage.value}</Text></Text>
              <Code block style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{scopedState?.transformer?.value ? JSON.parse(JSON.stringify(scopedState?.transformer?.value, null, 2)) : ''}</Code>
            </Container>
          }
          <div>
            {scopedState.target.value === 'Output' &&
              <Text span fw={500}>{t("Store as")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState.target.value}</Code> {t("as")} <Text span fs="italic">{scopedState.targetFormat.value}</Text></Text>
            }
            {scopedState.target.value === 'Entity' &&
              <Text span fw={500}>{t("Store as")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState.target.value}</Code> {t("as")} <Text span fs="italic">{scopedState.dataType.value}</Text> {t("with name")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)">{scopedState.entity.value}</Code> </Text>
            }
          </div>
        </Stack>
      }
    </SkillContentStepWrapper>
  );
};

export default SkillContentStepCallApi;
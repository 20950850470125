import { FC } from 'react'; import {
  Routes,
  Route
} from "react-router-dom";

import LoginPage from './login'
import LoginCallbackPage from './login-callback'
import UnauthorizedPage from './unauthorized';
import ForbiddenPage from './forbidden';

const LoginIndex: FC = () => {
  return <Routes>
    <Route path="" element={<LoginPage />} />
    <Route path="callback" element={<LoginCallbackPage />} />
    <Route path="401" element={<UnauthorizedPage />} />
    <Route path="403" element={<ForbiddenPage />} />
  </Routes>
}

export default LoginIndex

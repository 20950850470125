import { Button, Container, Group, Title, Text } from '@mantine/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from 'src/core/services/authentication-service';
import { Link } from 'react-router-dom';
import classes from './login.module.css';

const LoginPage: React.FC = () => {
  const [_, authenticate] = useAuthentication();
  const { t } = useTranslation();

  useEffect(() => {
    authenticate();
  })

  return (
    <Container className={classes.root}>
      <div className={classes.label}>...</div>
      <Title className={classes.title}>{t('Please wait while we authorize your account')}</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        {t('This might take only a couple of seconds. If this page does not change, their might be a problem with your account')}
      </Text>
      <Group justify="center">
        <Button component={Link} to={'/'} size="md">
          {t('Take me back to home page')}
        </Button>
      </Group>
    </Container>
  );
};

export default LoginPage;

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Title, Text, Button, Group } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import { IconHome2 } from '@tabler/icons-react';
import classes from './login.module.css';
import { useAuthentication } from 'src/core/services/authentication-service';

const ForbiddenPage: React.FC = () => {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const [, , getLogoutUrl] = useAuthentication();

  useEffect(() => {
    setHeader(t('Home'), 'Projects', <IconHome2 size={34} />, t('Home') as string);
    setRoutes([
      { path: ``, breadcrumbName: t('Home') },
      { path: `/403`, breadcrumbName: t('Forbidden') },
    ]);
  }, [])

  return (
    <Container className={classes.root}>
      <div className={classes.label}>403</div>
      <Title className={classes.title}>{t('Access denied')}</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        {t("You don't have permissions.")}
      </Text>
      <Group justify="center">
        <Button component='a' href={getLogoutUrl() || "/logout"} size="md">
          {t('Logout')}
        </Button>
      </Group>
    </Container>
  );
};

export default ForbiddenPage;

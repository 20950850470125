import { Alert, Anchor, Card, Group, Stack, Text, Tooltip } from '@mantine/core';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { formatMessage, sleep } from 'src/core/utils/object';
import moment from 'moment';
import { ServiceConnectionCategory, ServiceConnectionItemStore, ServiceConnectionSummary, ServiceConnectionSummaryStore, ServiceConnectionType } from 'src/stores/serviceconnections';
import EditServiceConnection from './edit-serviceconnection';
import TableListV2, { TableListV2Model } from 'src/core/ui/table-list/table-list-v2';
import { DataTableSortStatus } from 'mantine-datatable';
import { IconAlertCircle, IconPlugConnected } from '@tabler/icons-react';
import ContentWrapper from 'src/components/content-wrapper';
import HeaderContext from 'src/services/header-context';
import ServiceConnectionCategoryTypeSelector from './serviceconnection-category-type-selector';
import { HasRole } from 'src/utils/permissions';
import CreateServiceConnectionWizard from './create-serviceconnection-wizard';

const ServiceConnectionList: FC<{}> = () => {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const store = useMemo(() => container.get(ServiceConnectionSummaryStore), []);
  const state = store.state;
  const itemStore = useMemo(() => container.get(ServiceConnectionItemStore), []);
  const itemState = itemStore.state;
  const itemStateErrorMessage = itemState.errorMessage.value;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [typeFilter, setTypeFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [query, setQuery] = useState<Query>(undefined as any);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });

  useEffect(() => {
    setHeader(t('Service connections'), 'ServiceConnections', <IconPlugConnected size={34} />, t('Service connections') as string);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/serviceconnections`, breadcrumbName: t('Service connections') },
    ]);
  }, []);

  useEffect(() => {
    if (query) {
      load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const load = async (query: Query) => {
    store.load(query);
  };

  useEffect(() => {
    const newQuery = {
      ...query,
      //orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
      skip: 0,
      parameters: {
        typeFilter: typeFilter,
        categoryFilter: categoryFilter,
        orderByField: sortStatus.columnAccessor,
        orderByDirection: sortStatus.direction
      },
    } as Query;
    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilter, categoryFilter]);

  const onConfirmDelete = async (selectedItems: ServiceConnectionSummary[]) => {
    if (selectedItems && selectedItems.length > 0) {
      selectedItems.forEach((item) => { itemStore.delete(item.id); });
      await sleep(500);
      load(query);
    }
  };

  const model: TableListV2Model<ServiceConnectionSummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'name',
        title: t('Name'),
        sortable: true,
        render: (item: ServiceConnectionSummary) => (
          <Text>
            <Tooltip label={t("Edit item")}>
              <Anchor onClick={() => { setSelectedItem(item.id); setShowCreateModal(true); }}>{item.name}</Anchor>
            </Tooltip>
          </Text>
        )
      },
      {
        accessor: 'category',
        title: t('Category'),
        sortable: true,
        render: (item: ServiceConnectionSummary) => (
          <Text>{t(item.category)}</Text>
        )
      },
      {
        accessor: 'type',
        title: t('Type'),
        sortable: true,
        render: (item: ServiceConnectionSummary) => (
          <Text>{t(item.type)}</Text>
        )
      },
      {
        accessor: 'accessMode',
        title: t('Access mode'),
        sortable: true,
        render: (item: ServiceConnectionSummary) => (
          <Text>{item.accessMode === 'Everyone' ? t("Public access") as string : t("Members only") as string}</Text>
        )
      },
      {
        accessor: 'createdBy',
        title: t('Created by'),
        sortable: true,
        render: (item: ServiceConnectionSummary) => (
          <Text>{item.createdBy}</Text>
        )
      },
      {
        accessor: 'createdOn',
        title: t('Created on'),
        sortable: true,
        render: (item: ServiceConnectionSummary) => (
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.createdOn).toString()}</Text>}>
            <Text>{moment(item.createdOn).fromNow()}</Text>
          </Tooltip>
        )
      },
      {
        accessor: 'modifiedBy',
        title: t('Modified by'),
        sortable: true,
        render: (item: ServiceConnectionSummary) => (
          <Text>{item.modifiedBy}</Text>
        )
      },
      {
        accessor: 'modifiedOn',
        title: t('Modified on'),
        sortable: true,
        render: (item: ServiceConnectionSummary) => (
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.modifiedOn).toString()}</Text>}>
            <Text>{moment(item.modifiedOn).fromNow()}</Text>
          </Tooltip>
        )
      },
    ]
  };

  return (
    <ContentWrapper>
      <Card withBorder>
        <Stack gap="md">
          {itemStateErrorMessage && (
            <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => itemStore.clearError()}>
              <Text>{formatMessage(itemStateErrorMessage)}</Text>
            </Alert>
          )}
          <TableListV2
            model={model}
            onQueryChanged={setQuery}
            onRefresh={() => load(query)}
            striped
            highlightOnHover
            sortable
            sortStatus={sortStatus}
            onSortStatusChanged={setSortStatus}
            showNewIcon={HasRole('Creator')}
            onNewItemClick={() => { setSelectedItem(undefined); setShowCreateModal(true); }}
            selectable={HasRole('Creator')}
            onDeleteSelectedClick={(selectedItems: ServiceConnectionSummary[]) => onConfirmDelete(selectedItems)}
            rightToolBarRender={
              <Group gap="xs" align="center">
                <ServiceConnectionCategoryTypeSelector
                  category={categoryFilter as ServiceConnectionCategory ?? ''}
                  onChangeCategory={(value) => setCategoryFilter(value)}
                  type={typeFilter as ServiceConnectionType ?? ''}
                  onChangeType={(value) => setTypeFilter(value)}
                  categoryPlaceholder={t("Service category") as string}
                  typePlaceholder={t("Service type") as string}
                  clearable
                />
              </Group>
            }
          />
        </Stack>
        {showCreateModal && selectedItem &&
          <EditServiceConnection
            key="edit-service-connection-modal"
            opened={showCreateModal}
            onClose={() => { setShowCreateModal(false); setSelectedItem(undefined); }}
            id={selectedItem}
            onFinish={() => { setShowCreateModal(false); setSelectedItem(undefined); load(query); }}
          />
        }
        {showCreateModal && selectedItem === undefined &&
          <CreateServiceConnectionWizard
            key="create-service-connection-modal"
            opened={showCreateModal}
            onClose={() => { setShowCreateModal(false); setSelectedItem(undefined); }}
            onFinish={() => { setShowCreateModal(false); setSelectedItem(undefined); load(query); }}
          />
        }
      </Card>
    </ContentWrapper>
  );
};

export default ServiceConnectionList;

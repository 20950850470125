import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Anchor, Badge, Group, Stack, Text, Title, Tooltip } from '@mantine/core';
import { IconLockOpen as LockOpen, IconMessageChatbot as MessageChatbot, IconLock as Lock, IconClock } from '@tabler/icons-react';
import { useViewportSize } from '@mantine/hooks';
import HeaderContext from 'src/services/header-context';
import moment from 'moment';
import useBus from 'use-bus';
import StatusBadge from 'src/components/status-badge';
import { BotSummary, BotSummaryStore } from 'src/stores/bots';
import JobStatus from 'src/components/job-status';
import classes from 'src/pages/index.module.css';
import SimpleListV2, { SimpleListV2Model } from 'src/core/ui/simple-list/simple-list-v2';

const BotList: React.FC<{ showFilters: boolean }> = ({ showFilters }) => {
  const { t } = useTranslation();
  const { header, setHeader, setRoutes } = useContext(HeaderContext);
  const summaryStore = useMemo(() => container.get(BotSummaryStore), []);
  const summaryState = summaryStore.state;
  const { height } = useViewportSize();
  const [query, setQuery] = useState<Query>(undefined as any);
  const { botId } = useParams();

  useEffect(() => {
    if (botId) {
      setHeader(`${botId}`, 'Bots', <MessageChatbot size={34} />, `${botId} | ${t("Bots")}`, true, true);
    }
    else {
      setHeader(t('Bots'), 'Bots', <MessageChatbot size={34} />, t('Bots') as string, true, true);
    }
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/adminbots`, breadcrumbName: t('Bots') },
    ]);

    const newQuery = {
      ...query,
      orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
      skip: 0,
      take: 20,
    } as Query;
    setQuery(newQuery);
  }, []);

  useEffect(() => {
    if (query) {
      load(query);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [JSON.stringify(query)]);

  const load = async (query: Query) => {
    summaryStore.load(query);
  };

  const onReload = () => {
    load(query);
  };

  useBus(
    '@@ui/BOT_LIST_REFRESH',
    () => onReload(),
    [query],
  );

  const model: SimpleListV2Model<BotSummary> = {
    data: summaryStore.toListState(summaryState.value),
    query,
    columns: [
      {
        accessor: 'title',
        title: t('Title'),

        render: (item: BotSummary) => (
          <Anchor component={Link} to={`/admin/bots/${item.id}`} underline='never' variant="text">
            <Stack gap="xs" style={{ minHeight: 60 }}>
              <Group gap="xs" justify='space-between' wrap='nowrap' align='flex-start'>
                <Title order={5} className={classes.titleList}>{item.title}</Title>

                <Group gap={0}>
                  <Tooltip label={item.accessMode === 'Everyone' ? t("Public access") as string : t("Members only") as string}>
                    <ActionIcon variant='transparent' color="gray">
                      {item.accessMode === 'Everyone' ? <LockOpen size={16} /> : <Lock size={16} />}
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </Group>

              <Group gap="xs">
                {item.currentSnapshotDateTime &&
                  <Tooltip label={<Text size='sm'>{moment(item.currentSnapshotDateTime).toString()}</Text>}>
                    <Badge variant='light' leftSection={<IconClock size={16} />}>{moment(item.currentSnapshotDateTime).fromNow()}</Badge>
                  </Tooltip>
                }
                <StatusBadge status={item.status} />
                <JobStatus status={item.trainingStatus} jobReference={item.jobReference} />
              </Group>

              <Text c="dimmed" size="xs">
                {!item.description && t('(No description)')}
                {item.description[header.language]}
              </Text>
            </Stack>
          </Anchor>
        ),
      }
    ]
  };

  const getScrollAreaHeight = () => {
    return showFilters ? height - 135 : height - 90;
  }

  return (
    <SimpleListV2
      idAccessor={'id'}
      rowStyle={({ id }) => (id === botId ? { backgroundColor: 'var(--mantine-primary-color-light)' } : undefined)}
      model={model}
      onQueryChanged={setQuery}
      onRefresh={onReload}
      height={getScrollAreaHeight()}
      showFilters={showFilters}
    />
  );
};

export default BotList;

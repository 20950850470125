import React, { FC, useContext, useState } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActionIcon, AppShell, Burger, Group, Title, Tooltip, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useViewportSize, useWindowScroll } from '@mantine/hooks';
import HeaderContext from 'src/services/header-context';
import HomePage from './home';
import ProfilePage from './profile';
import NotFoundPage from '../not-found';
import { useAuthentication } from 'src/core/services/authentication-service';
import SettingsPage from './settings';
import SkillPage from './skills';
import SkillSideMenu from './skills/skill-side-menu';
import CollectionsPage from './collections';
import CollectionSideMenu from './collections/collection-side-menu';
import DocumentsPage from './documents';
import DocumentSideMenu from './documents/document-side-menu';
import BotsPage from './bots';
import BotSideMenu from './bots/bot-side-menu';
import ServiceConnectionPage from './serviceconnections';
import AppContext from 'src/services/app-context';
import LeavePageModal from 'src/components/leave-page-modal';
import { IconHome2, IconBook2, IconFileText, IconWand, IconMessageChatbot, IconSettings, IconArrowUp, IconPlugConnected } from '@tabler/icons-react';
import classes from 'src/pages/index.module.css';
import cx from 'clsx';
import { truncateText } from 'src/core/utils/object';

const AdminIndex: FC<{}> = () => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const [scroll, scrollTo] = useWindowScroll();
  const { height } = useViewportSize();
  const [authStatus] = useAuthentication();
  const { header, setHeader } = useContext(HeaderContext);
  const { isDirty } = useContext(AppContext);

  const getNavBarWidth = () => {
    if (!opened && !header.showSidebar) {
      return 60;
    }
    if (opened && !header.showSidebar) {
      return 200;
    }
    if (!opened && header.showSidebar) {
      return 460;
    }
    if (opened && header.showSidebar) {
      return 600;
    }
  };

  const renderNavBarItem = (label: string, key: string, url: string, icon: JSX.Element, showSidebar: boolean) => {
    const reopenSidebar = key === header.navItem && header.hasSidebar && !header.showSidebar;
    return (
      <Tooltip label={label} position="right" withArrow key={key}>
        <ActionIcon
          variant="subtle" color="gray"
          component={Link}
          to={!reopenSidebar ? url : '#'}
          className={cx(classes.mainLink, classes.navBarItem, { [classes.mainLinkExpanded]: opened }, { [classes.navBarItemSelected]: key === header.navItem })}
          classNames={{
            icon: opened ? classes.mainLinkExpandedIcon : undefined
          }}
          onClick={() => {
            setHeader(header.title, key, header.icon, header.documentTitle, header.hasSidebar, showSidebar);
          }}>
          <Group gap='xs'>
            {icon}
            {opened && <Title order={6}>{truncateText(label, 16)}</Title>}
          </Group>
        </ActionIcon>
      </Tooltip>
    );
  };

  return (
    <AppShell
      padding="md"
      navbar={{ width: getNavBarWidth() as number, breakpoint: 0 }}
      styles={(theme) => ({
        root: {
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          display: 'flex',
          boxSizing: 'border-box'
        },
        main: {
          flex: '1 1 0%',
          width: '100vw',
          boxSizing: 'border-box',
          minHeight: '100svh',
          padding: 0,
        }
      })}>
      <AppShell.Navbar style={{ height: 'auto', minHeight: height - 5, position: 'relative' }}>
        <AppShell.Section grow className={classes.wrapper}>
          <div
            className={cx(classes.aside, { [classes.asideExpanded]: opened })}
            style={{
              height: '100%',
              backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
              borderRight: `1px solid ${colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3]}`,
            }}>
            <Group align='center' justify='center' className={classes.logo}
              style={{
                borderBottom: `1px solid ${colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3]}`,
              }}>
              <Burger
                className={cx(classes.menuLink, { [classes.menuLinkExpanded]: opened })}
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="md"
              />
            </Group>
            {renderNavBarItem(t('Home'), 'Home', '/admin', <IconHome2 />, false)}
            {renderNavBarItem(t('Collections'), 'Collections', '/admin/collections', <IconBook2 />, true)}
            {renderNavBarItem(t('Documents'), 'Documents', '/admin/documents', <IconFileText />, true)}
            {renderNavBarItem(t('Skills'), 'Skills', '/admin/skills', <IconWand />, true)}
            {renderNavBarItem(t('Bots'), 'Bots', '/admin/bots', <IconMessageChatbot />, true)}
            {renderNavBarItem(t('Connections'), 'ServiceConnections', '/admin/serviceconnections', <IconPlugConnected />, false)}
            {authStatus?.user.value?.isOwner &&
              renderNavBarItem(t('Settings'), 'Settings', '/admin/settings', <IconSettings />, false)
            }
          </div>
          {header.showSidebar && (
            <div className={classes.sideMenu}>
              <Routes>
                <Route path="/skills/:skillId/*" element={<SkillSideMenu />} />
                <Route path="/skills/*" element={<SkillSideMenu />} />
                <Route path="/collections/:collectionId/documents/:documentId/*" element={<DocumentSideMenu />} />
                <Route path="/collections/:collectionId/documents/:documentId" element={<DocumentSideMenu />} />
                <Route path="/collections/:collectionId/documents/*" element={<DocumentSideMenu />} />
                <Route path="/collections/:collectionId/*" element={<CollectionSideMenu />} />
                <Route path="/collections/*" element={<CollectionSideMenu />} />
                <Route path="/documents/*" element={<DocumentSideMenu />} />
                <Route path="/bots/:botId/*" element={<BotSideMenu />} />
                <Route path="/bots/*" element={<BotSideMenu />} />
              </Routes>
            </div>
          )}
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>
        <div className={classes.body}>
          {authStatus.status.value !== 'Success' && <Navigate to={`/login?redirect_uri=${encodeURIComponent(window.location.href)}`} />}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/skills/*" element={<SkillPage />} />
            <Route path="/collections/:collectionId/documents/*" element={<DocumentsPage />} />
            <Route path="/collections/*" element={<CollectionsPage />} />
            <Route path="/documents/*" element={<DocumentsPage />} />
            <Route path="/bots/*" element={<BotsPage />} />
            <Route path="/serviceconnections/*" element={<ServiceConnectionPage />} />
            <Route path='/settings/*' element={<SettingsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <LeavePageModal when={isDirty} />
        {
          scroll.y > 300 &&
          <ActionIcon
            variant="filled"
            color={theme.colors[theme.primaryColor][6]}
            radius="lg"
            className={classes.floatingButton}
            onClick={() => scrollTo({ y: 0 })}>
            <IconArrowUp />
          </ActionIcon>
        }
      </AppShell.Main>
    </AppShell>
  );
};

export default AdminIndex;

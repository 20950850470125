import { none, State, useHookstate } from "@hookstate/core";
import { Group, Input, TextInput, Text, Stack, Code, Badge, Textarea, Tooltip, ActionIcon, Select } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";
import NumberInputWithControl from "src/components/number-input-with-control";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import { SkillContentStep, SkillContentStepDataType } from "src/stores/skills";
import SkillContentStepWrapper from "./skill-content-step-wrapper";

const SkillContentStepAsk: FC<{
  state: State<SkillContentStep>;
  edit?: boolean;
  index: number;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ state, edit, index, leftToolbar, rightToolbar }) => {
  const scopedState = useHookstate(state.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.questions?.length > 0 ? Object.keys(scopedState?.value?.questions?.[0]) : defaultAllowedLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value?.questions.length > 0 ? Object.keys(scopedState?.value?.questions?.[0])[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.questions?.length > 0 ? Object.keys(scopedState?.value?.questions?.[0]) : defaultAllowedLanguages);
  }, [scopedState.questions])

  const onAddQuestion = () => {
    const defaultLang = allowedLanguages[0];
    let defaultQuestions: { [key: string]: string } = {};
    defaultQuestions[defaultLang] = t("<your question>", { lng: defaultLang });
    scopedState.questions[scopedState.questions.length].set(defaultQuestions);
    setIsDirty(true);
  }

  const onAddOption = () => {
    if (scopedState.options?.value) {
      scopedState.options[scopedState.options.length].set('#<your option>');
    }
    else {
      scopedState.merge({ options: ['#<your option>'] });
    }
    setIsDirty(true);
  }

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.questions.length; index++) {
      (scopedState.questions[index])[language].set(none);
    }
    setIsDirty(true);
    if (scopedState?.value?.questions?.length > 0) {
      setSelectedLanguage(Object.keys(scopedState?.questions?.value?.[0])[0]);
    }
  }

  const onAddLanguage = (language: string) => {
    const defaultText = t("<your question>", { lng: language });
    for (let index = 0; index < scopedState.value.questions.length; index++) {
      (scopedState.questions[index])[language].set(defaultText);
    }
    setIsDirty(true);
  }

  return (
    <SkillContentStepWrapper
      index={index}
      state={state}
      edit={edit}
      leftToolbar={
        <Group align="center" gap={5}>
          {leftToolbar}
          <LanguageSegmentedControl
            size="sm"
            compact
            editable={edit}
            data={scopedState?.value?.questions?.length > 0 ? Object.keys(scopedState?.value?.questions?.[0]) : undefined}
            language={selectedLanguage}
            onChange={setSelectedLanguage}
            onDelete={onDeleleLanguage}
            onAdd={onAddLanguage}
          />
        </Group>
      }
      rightToolbar={rightToolbar}>
      {edit &&
        <Stack gap="xs">
          <Group align="center">
            <Input.Label w={120} required={edit}>{t("Ask")}:</Input.Label>
            <Group align="center" gap={5}>
              <Text size="sm">{t("Questions")}</Text>
              <Tooltip withinPortal label={t("Add question")}>
                <ActionIcon onClick={onAddQuestion} variant='subtle' color="gray">
                  <IconPlus size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
          {scopedState.questions.map((question, index) =>
            <Group align="flex-start" key={index}>
              <Input.Label w={120}></Input.Label>
              <Textarea
                autosize
                minRows={3}
                maxRows={10}
                required
                style={{ flex: 1 }}
                value={question[selectedLanguage]?.value ?? ''}
                onChange={(event) => { question[selectedLanguage].set(event.target.value); setIsDirty(true); }}
              />
              <ActionIcon onClick={() => { question.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                <IconTrash size={16} />
              </ActionIcon>
            </Group>
          )}

          <Group align="center">
            <Input.Label w={120}>{t("Response")}:</Input.Label>
            <Group align="flex-start" gap={5}>
              <Text size="sm">{t("Options")}</Text>
              <Tooltip withinPortal label={t("Add option")}>
                <ActionIcon onClick={onAddOption} variant='subtle' color="gray">
                  <IconPlus size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
          {scopedState.options?.value && scopedState.options.map((option, index) =>
            <Group align="center" key={index}>
              <Input.Label w={120}></Input.Label>
              <TextInput
                required
                style={{ flex: 1 }}
                value={option.value}
                onChange={(event) => { option.set(event.target.value); setIsDirty(true); }}
              />
              <ActionIcon onClick={() => { option.set(none); setIsDirty(true); }} variant='subtle' color="gray">
                <IconTrash size={16} />
              </ActionIcon>
            </Group>
          )}

          <Group align="center">
            <Input.Label w={120}>{t("MinScore")}:</Input.Label>
            <NumberInputWithControl
              precision={6}
              min={0}
              step={0.01}
              max={1}
              value={scopedState.minScore.value}
              onChange={(value) => { scopedState.minScore.set(value as number); setIsDirty(true); }}
            />
          </Group>

          <Group align="center">
            <Input.Label required={edit} w={120}>{t("Save in")}:</Input.Label>
            <TextInput
              required
              style={{ flex: 1 }}
              value={scopedState.entity.value}
              onChange={(event) => { scopedState.entity.set(event.target.value); setIsDirty(true); }}
            />
            <Group gap={10}>
              <Text size="sm">{t("as")}</Text>
              <Select
                w={100}
                allowDeselect={false}
                data={[
                  { value: 'String', label: t('String') as SkillContentStepDataType },
                  { value: 'Integer', label: t('Integer') as SkillContentStepDataType },
                  { value: 'Number', label: t('Number') as SkillContentStepDataType },
                  { value: 'Date', label: t('Date') as SkillContentStepDataType }
                ]}
                value={scopedState.dataType.value}
                onChange={(value) => { scopedState.dataType.set(value as SkillContentStepDataType); setIsDirty(true); }}
              />
            </Group>
          </Group>
        </Stack>
      }

      {!edit &&
        <Stack gap="xs">
          <div>
            <Text span fw={500}>{t("Ask")} </Text>
            {scopedState.questions?.value && scopedState.questions.map((question, index) =>
              <Text key={index} span fs="italic">{question[selectedLanguage]?.value ?? ''} </Text>
            )}
          </div>

          <Group align="center" gap="xs">
            {scopedState.options?.length > 0 && scopedState.options.map((option, index) =>
              <Badge key={index} size="md" color="indigo" variant="light" style={{ textTransform: 'none' }}>{option.value}</Badge>
            )}
          </Group>

          {scopedState.minScore?.value > 0 &&
            <div>
              <Text span fw={500}>{t("MinScore")} </Text>
              <Text span fs="italic">{scopedState.minScore.value}</Text>
            </div>
          }
          <div>
            <Text span fw={500}>{t("Save the answer in")} </Text>
            <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" >{scopedState.entity.value}</Code>
            {scopedState.dataType.value && scopedState.dataType.value.length > 0 &&
              <Text span> {t("as")} <Text span fs="italic">{scopedState.dataType.value}</Text></Text>
            }
          </div>
        </Stack>
      }
    </SkillContentStepWrapper>
  );
};

export default SkillContentStepAsk;
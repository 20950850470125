import { FC } from "react";
import { StepNodeProps } from "./steps";
import { Group, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import StepWrapper from "./step-wrapper";
import { IconArrowBack } from "@tabler/icons-react";

const StepEndNode: FC<StepNodeProps> = ({ id, data }) => {
  const { step, edit, index, rightToolbar, updateNode } = data;
  const { t } = useTranslation();

  const resume = (
    <Group align="center" justify="center" gap="xs" p="xs">
      <IconArrowBack />
      <Text ta="center">{t("Return to bot")}</Text>
    </Group>
  )

  return (
    <StepWrapper
      id={id}
      index={index}
      step={step}
      updateNode={updateNode}
      edit={edit}
      rightToolbar={rightToolbar}
      resume={resume}
      hideHeader
      hideSource />
  );
};

export default StepEndNode;
import { Text, Box, Paper, Stack, useMantineTheme, Anchor, Center, Loader } from '@mantine/core';
import { FC, useEffect, useMemo, useState } from 'react';
import { container } from 'src/inversify.config';
import { AppConfiguration, useAuthentication } from '../core/services/authentication-service';
import { GlobalConfigOptions, ConfigStore } from 'src/stores/options';
import StartSetup from 'src/components/start/start-setup';
import StartBots from 'src/components/start/start-bots';
import StartQuickStart from 'src/components/start/start-quickstart';
import { t } from 'i18next';
import classes from 'src/pages/start.module.css';
import { CanUsePersonalCollection } from 'src/utils/permissions';

const Start: FC<{}> = () => {
  const [authStatus] = useAuthentication();
  const theme = useMantineTheme();
  const isOwner = authStatus?.user.value?.isOwner;
  const config = container.get<AppConfiguration>("AppConfiguration");
  const brandName = config.brandName;
  const baseUri = config.serviceUrl || `${window.location.protocol}//${window.location.host}`;
  const configStore = useMemo(() => container.get(ConfigStore), []);
  const configState = configStore.state;
  const isBusy = configState.isBusy.value;
  const [showSetup, setShowSetup] = useState(false);
  const [quickStartAvailable, setQuickStartAvailable] = useState(false);
  const [showQuickStartWizard, setShowQuickStartWizard] = useState(false);
  const backgroundImage = `${baseUri}/api/config/images/startbackground.jpg`;

  useEffect(() => {
    loadConfig();
  }, [])

  const loadConfig = async () => {
    await configStore.load('global');
    const configItem = JSON.parse(JSON.stringify(configState?.item?.value)) as GlobalConfigOptions;
    const hasConfig = configItem && typeof configItem === 'object' && Object.keys(configItem).length > 0;
    const showSetupWizard = !isBusy && isOwner && (!hasConfig || (hasConfig && !!configState.errorMessage.value));
    setShowSetup(showSetupWizard);
    setQuickStartAvailable(!showSetupWizard && CanUsePersonalCollection());
  }

  return (
    <Box className="start_background" style={{backgroundImage: `url(${backgroundImage})`}} pos="relative">
      {isBusy ?
        <Paper withBorder shadow="md" p={30} radius="md" className='center' mih={350} pos="relative">
          <Stack gap="xs">
            <h1 className={classes.subtitle}>
              {t("Welcome to")} {' '}<br />
              <Text className={classes.title} component="span" variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][6], to: 'cyan' }} span inherit>
                {brandName}
              </Text>{' '}
            </h1>

            <Stack my="xl" justify='center' align='center'>
              <Loader size="xl" />
              <Text c="dimmed">{t("Please wait")}</Text>
            </Stack>

            <Center>
              <Anchor href={`${baseUri}/admin`} c='dimmed' size="xs">
                {t("Go to the administration center")}
              </Anchor>
              <Text c='dimmed' size="xs">
                &nbsp;|&nbsp;v{config.version}
              </Text>
            </Center>
          </Stack>
        </Paper>
        :
        <>
          {showQuickStartWizard ?
            <StartQuickStart />
            :
            <>
              {showSetup ?
                <StartSetup />
                :
                <StartBots quickStartAvailable={quickStartAvailable} onQuickStartClick={() => setShowQuickStartWizard(true)} />
              }
            </>
          }
        </>
      }
    </Box>
  );
}

export default Start;

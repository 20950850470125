import { State, none, useHookstate } from "@hookstate/core";
import { ActionIcon, Anchor, Box, Card, CopyButton, Group, Input, PasswordInput, Select, SimpleGrid, Stack, Switch, Text, TextInput, Tooltip } from "@mantine/core";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import SwitchButton from "src/components/switch-button";
import { BotContent, BotContentRateLimit, BotItem, BotRateLimit } from "src/stores/bots";
import { IconCheck as Check, IconCopy as Copy, IconPlugConnected as PlugConnected } from "@tabler/icons-react";
import AppContext from "src/services/app-context";
import BotLogApiKeys from "./bot-logs-apikeys";
import BotBlockedUserList from "./bot-blocked-user-list";
import { USERBLOCKED_PROPERTY_KEY } from "src/utils/const";
import { ServiceConnectionSelector } from "src/components/service-selector";
import { container } from "src/inversify.config";
import { AppConfiguration } from "src/core/services/authentication-service";
import classes from 'src/pages/index.module.css';
import { UploadImageBase64 } from "src/components/upload-image-base64";
import LocalizedTextArea from "src/components/localized-textarea";

const BotApiDetails: FC<{
  state: State<BotItem>;
  contentState: State<BotContent>;
  cardkey: string;
  edit?: boolean;
}> = ({ state, contentState, cardkey, edit = false }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const scopedContentState = useHookstate(contentState);
  const { setIsDirty } = useContext(AppContext);
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(contentState.options.alert?.value ? Object.keys(contentState.options.alert?.value)[0] : defaultAllowedLanguages[0]);

  const onDeleleLanguage = (lang: string) => {
    contentState.options.alert[lang].set(none);
    setIsDirty(true);
    setSelectedLanguage(Object.keys(contentState.options.alert?.value)[0]);
  }

  const onAddLanguage = (lang: string) => {
    contentState.options.alert[lang].set('');
    setIsDirty(true);
  }

  const onChangeAlert = (lang: string, value: string) => {
    if (contentState.options.alert?.value) {
      contentState.options.alert[lang].set(value);
    }
    else {
      let alert: { [key: string]: string } = {};
      alert[lang] = value;
      contentState.options.merge({ alert: alert })
    }

    setIsDirty(true);
  }

  if (!scopedContentState.integrations || !scopedContentState.integrations.value)
    return <div></div>

  const onChangeRateLimit = (value: BotRateLimit) => {
    if (!scopedContentState.limits?.rateLimit?.value) {
      scopedContentState.limits.set({ rateLimit: value } as BotContentRateLimit);
    }
    else {
      scopedContentState.limits.rateLimit.set(value);
    }
    setIsDirty(true);
  }

  const onChangeLogo = (value: string) => {
    if (!scopedContentState.personalization?.logo?.value) {
      scopedContentState.personalization.set({ logo: value });
    }
    else {
      scopedContentState.personalization.logo.set(value)
    }
    setIsDirty(true);
  }

  const onAnonimize = (value: boolean) => {
    scopedContentState.integrations.insights.anonymize.set(value);
    setIsDirty(true); 
  }

  return (
    <Stack>
      <CollapsibleCard
        title={<Text fw={500}>{t('API details')}</Text>}
        cardKey={cardkey}
        icon={<PlugConnected />}>
        <Stack gap="xs">
          <Text c="dimmed">{t("Review the access credentials to your bot and enable it.")}</Text>

          <SwitchButton
            className={classes.switchButton}
            label={t("Enable bot")}
            description={t("Allows you to enable the bot for use via api key and url or with the embed code for the web.") as string}
            checked={scopedState.enable.value}
            onChange={value => { scopedState.enable.set(value); setIsDirty(true); }}
            readOnly={!edit}>
            {scopedState.enable.value &&
              <Stack>
                <Group align="center">
                  <Text w={90}>{t("API URL")}:</Text>
                  <TextInput
                    style={{ flex: 1 }}
                    readOnly
                    value={scopedState?.apiUrl?.value ?? `${baseUri}/api/bots/${state.id.value}/ask`}
                  />
                  <CopyButton value={scopedState?.apiUrl?.value ?? ""} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? t('Copied') : t('Copy')} withArrow position="right">
                        <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy} variant='subtle'>
                          {copied ? <Check /> : <Copy />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Group>
                <Group align="center">
                  <Text w={90}>{t("Chat URL")}:</Text>
                  <Anchor href={`${baseUri}/bots/${state.id.value}`}>
                    {`${baseUri}/bots/${state.id.value}`}
                  </Anchor>
                </Group>
                <Group align="center">
                  <Text w={90}>{t("Logo")}:</Text>
                  <Box style={{ flex: 1 }}>
                    <UploadImageBase64
                      edit={edit}
                      value={contentState.personalization?.logo?.value ?? ''}
                      onChange={(value) => onChangeLogo(value)}
                      onDelete={() => contentState.personalization.logo.set(none)}
                      maxWidth={200}
                    />
                  </Box>
                </Group>
                {/* TODO: meter los usuarios que tienen los privilegios de ImpersonatedUser ?? */}
                <LocalizedTextArea
                  label={t("Alert message") as string}
                  edit={edit}
                  value={contentState.options.alert.value}
                  onChange={(value) => {contentState.options.alert.set(value); setIsDirty(true); }} />

              </Stack>
            }
          </SwitchButton>

          <SwitchButton
            className={classes.switchButton}
            label={t("Enable Microsoft bot framework endpoint")}
            description={t("Enables Microsoft bot framework compatible API.") as string}
            checked={scopedContentState.integrations.microsoft.enable.value ?? false}
            onChange={value => { scopedContentState.integrations.microsoft.enable.set(value); setIsDirty(true); }}
            readOnly={!edit}>
            {scopedContentState.integrations.microsoft.enable.value &&
              <Stack>
                <Group align="center">
                  <Text w={90}>{t("API URL")}:</Text>
                  <TextInput
                    style={{ flex: 1 }}
                    readOnly
                    value={scopedState?.apiUrl?.value ?? `${baseUri}/api/bots/${state.id.value}/endpoints/microsoft`}
                  />
                  <CopyButton value={scopedState?.apiUrl?.value ?? ""} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? t('Copied') : t('Copy')} withArrow position="right">
                        <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy} variant='subtle'>
                          {copied ? <Check /> : <Copy />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Group>
                <Group align="flex-start" grow>
                  <Select
                    allowDeselect={false}
                    readOnly={!edit}
                    variant={edit ? 'default' : 'unstyled'}
                    label={t("App type")}
                    description={t("Microsoft Bot Framework bot type")}
                    defaultValue='naive'
                    data={[
                      { value: 'MultiTenant', label: t("Multi-tenant") as string },
                      { value: 'SingleTenant', label: t("Single-tenant") as string },
                    ]}
                    value={scopedContentState.integrations?.microsoft?.appType?.value ?? 'MultiTenant'}
                    onChange={(value) => { scopedContentState.integrations?.microsoft?.appType?.set(value as string); setIsDirty(true); }}
                  />
                  <TextInput
                    required
                    label={t("App Id")}
                    description={t("Microsoft Bot Framework bot id.") as string}
                    value={scopedContentState.integrations?.microsoft?.appId?.value}
                    onChange={(e) => { scopedContentState.integrations.microsoft.appId.set(e.target.value); setIsDirty(true); }}
                    readOnly={!edit}
                  />
                  <PasswordInput
                    required
                    label={t("App password")}
                    description={t("Bot Framework bot secret.") as string}
                    value={scopedContentState.integrations?.microsoft?.appPassword?.value}
                    onChange={(e) => { scopedContentState.integrations.microsoft.appPassword.set(e.target.value); setIsDirty(true); }}
                    readOnly={!edit}
                  />
                </Group>
                <Group align="flex-start" grow>
                  <TextInput
                    required
                    label={t("TenantId")}
                    description={t("Active directory Id (Tenant id Guid).") as string}
                    value={scopedContentState.integrations?.microsoft?.tenantId?.value}
                    onChange={(e) => { scopedContentState.integrations.microsoft.tenantId.set(e.target.value); setIsDirty(true); }}
                    readOnly={!edit}
                  />
                  <TextInput
                    required
                    label={t("OAuth profile")}
                    description={t("OAuth authentication profile.") as string}
                    value={scopedContentState.integrations?.microsoft?.oAuthProfile?.value}
                    onChange={(e) => { scopedContentState.integrations.microsoft.oAuthProfile.set(e.target.value); setIsDirty(true); }}
                    readOnly={!edit}
                  />
                  <Input.Wrapper label={t("Settings")}>
                    <Switch
                      mt="xs"
                      label={t("Strip domain from username")}
                      checked={scopedContentState.integrations.microsoft.stripUsernameDomain.value ?? false}
                      onChange={e => scopedContentState.integrations.microsoft.stripUsernameDomain.set(e.target.checked)}
                      readOnly={!edit}
                    />
                  </Input.Wrapper>
                </Group>
              </Stack>
            }
          </SwitchButton>

          <SimpleGrid
            cols={{ base: 1, xl: 2 }}
            spacing="md">
            <SwitchButton
              className={classes.switchButton}
              label={t("Enable message logging")}
              description={t("Enable user message content logging. If you want to output messages to another destination, select the proper service below. Otherwise, built-in log system will be used.") as string}
              checked={scopedContentState.integrations.insights.enable.value ?? false}
              onChange={value => { scopedContentState.integrations.insights.enable.set(value); setIsDirty(true); }}
              readOnly={!edit}>
              {scopedContentState.integrations.insights.enable.value &&
                <Stack>
                  <ServiceConnectionSelector
                    label={t("Service connection") as string}
                    clearable
                    placeholder={t("Built-in service") as string}
                    width='100%'
                    readOnly={!edit}
                    categoryFilter='Insights'
                    value={scopedContentState.integrations.insights.service.value}
                    onChange={(value) => { scopedContentState.integrations.insights.service.set(value as string); setIsDirty(true); }}
                  />
                  <Switch
                    defaultChecked
                    label={t("Track username")}
                    description={t("When active, the log will include the real username.") as string}
                    checked={scopedContentState.integrations.insights.trackUsername.value ?? false}
                    onChange={e => { scopedContentState.integrations.insights.trackUsername.set(e.target.checked); setIsDirty(true); }}
                    readOnly={!edit}
                  />
                  <Switch
                    defaultChecked
                    label={t("Anonymize username when tracked")}
                    description={t("When active, a hash will be sent as username instead of the real username.") as string}
                    checked={scopedContentState.integrations.insights.anonymize.value ?? false}
                    onChange={e => edit && scopedContentState.integrations.insights.trackUsername.value ? onAnonimize(e.target.checked) : null}
                    readOnly={!edit || !scopedContentState.integrations.insights.trackUsername.value}
                  />
                </Stack>
              }
            </SwitchButton>

            <SwitchButton
              className={classes.switchButton}
              label={t("Enable rate limit")}
              description={t("Set a limit on the maximum number of messages allowed during a given time.") as string}
              checked={scopedContentState.limits?.rateLimit?.value && scopedContentState.limits?.rateLimit?.value !== 'None' ? true : false}
              onChange={value => { onChangeRateLimit(value ? 'Session' : 'None'); setIsDirty(true); }}
              readOnly={!edit}>
              {scopedContentState.limits?.rateLimit?.value && scopedContentState.limits?.rateLimit?.value !== 'None' &&
                <Stack>
                  <Select
                    readOnly={!edit}
                    variant={edit ? 'default' : 'unstyled'}
                    label={t("Rate limit type")}
                    description={t("Select the type of rate limit that will be applied.")}
                    data={[
                      { value: 'Session', label: t("Session") as string },
                      { value: 'User', label: t("User") as string },
                    ]}
                    value={scopedContentState.limits.rateLimit.value ?? 'Session'}
                    onChange={(value) => { onChangeRateLimit(value as BotRateLimit); setIsDirty(true); }}
                  />
                </Stack>
              }
            </SwitchButton>

          </SimpleGrid>

          {/*<Card*/}
          {/*  withBorder*/}
          {/*  shadow="xs"*/}
          {/*  radius="sm"*/}
          {/*  sx={(theme) => ({*/}
          {/*    border: `1px solid ${theme.colors.gray[3]}`,*/}
          {/*    '&:hover': {*/}
          {/*      border: `1px solid ${theme.colors[theme.primaryColor][3]}`,*/}
          {/*    },*/}
          {/*  })}>*/}
          {/*  <Card.Section withBorder inheritPadding py={5}>*/}
          {/*    <Group position="space-between">*/}
          {/*      <Text weight={500}>{t("Web chat code")}</Text>*/}
          {/*    </Group>*/}
          {/*  </Card.Section>*/}

          {/*  <Card.Section inheritPadding mt="xs" pb="xs">*/}
          {/*    <Prism language="javascript">YOUR-WEBCHAT-CODE</Prism>*/}
          {/*  </Card.Section>*/}
          {/*</Card>*/}

          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing="md">
            <Card
              withBorder
              shadow="xs"
              radius="sm"
              className={classes.stepCard}>
              <Card.Section withBorder inheritPadding py={5}>
                <Group justify="space-between">
                  <Text fw={500}>{t("Log API keys")}</Text>
                </Group>
              </Card.Section>
              <Card.Section inheritPadding mt="xs" pb="xs">
                <BotLogApiKeys
                  key={`botlogapikeys-${scopedState.id.value}`}
                  state={scopedState}
                  edit={edit}
                />
              </Card.Section>
            </Card>

            <Card
              withBorder
              shadow="xs"
              radius="sm"
              className={classes.stepCard}>
              <Card.Section withBorder inheritPadding py={5}>
                <Group justify="space-between">
                  <Text fw={500}>{t("Blocked users list")}</Text>
                </Group>
              </Card.Section>
              <Card.Section inheritPadding mt="xs" pb="xs">
                <BotBlockedUserList
                  key={`blockeduserlist-${scopedState.id.value}`}
                  state={scopedState}
                  propertyKey={USERBLOCKED_PROPERTY_KEY}
                  edit={edit}
                />
              </Card.Section>
            </Card>
          </SimpleGrid>
        </Stack>
      </CollapsibleCard>
    </Stack>
  );
};

export default BotApiDetails;
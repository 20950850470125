import { useMemo, useEffect, useState, FC } from 'react';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { Group, MantineStyleProp, Text } from '@mantine/core';
import { IconPlugConnected as PlugConnected } from '@tabler/icons-react';
import useBus from 'use-bus';
import { GenericSelector } from 'src/core/ui/generic-selector';
import { useClickOutside } from '@mantine/hooks';
import { ServiceConnectionCategory, ServiceConnectionSummary, ServiceConnectionSummaryStore } from 'src/stores/serviceconnections';
import { clone } from '../core/utils/object';

export const ServiceConnectionSelector: FC<{
  value?: string;
  categoryFilter: ServiceConnectionCategory;
  onChange?: (value: string) => void;
  width?: string | number;
  label?: string;
  clearable?: boolean;
  required?: boolean;
  creatable?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  style?: MantineStyleProp;
}> = ({ value, onChange, width, label, clearable, required, creatable, placeholder, readOnly, categoryFilter, style }) => {
  const store = useMemo(() => container.get(ServiceConnectionSummaryStore), []);
  const state = store.state;
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(!value);
  const refFocusOutside = useClickOutside(() => {
    if (value) {
      setEditMode(false);
    }
  });

  const [query, setQuery] = useState<Query>({
    searchQuery: '',
    parameters: {
      categoryFilter: categoryFilter,
      includeIds: [value]
    },
    orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: 100,
  } as Query);

  useEffect(() => {
    load(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  useEffect(() => {
    const newQuery = clone<Query>(query);
    if (newQuery.parameters == null)
      newQuery.parameters = {}
    newQuery.parameters.categoryFilter = categoryFilter;
    newQuery.parameters.includeIds = value;
    setQuery(newQuery);
  }, [categoryFilter, value]);

  useEffect(() => {
    if (value)
      setEditMode(false);
  }, [value])

  const load = async (query: Query) => {
    store.load(query);
  };

  useBus(
    '@@ui/SERVICE_CONNECTION_LIST_REFRESH',
    () => load(query),
    [],
  );

  const renderDataItem = (item: ServiceConnectionSummary) => {
    return {
      label: item.name,
      value: item.id,
    } as any;
  }

  const renderOption = (item: ServiceConnectionSummary) => (
    <Group gap="xs">
      <PlugConnected color="gray" size={20} /><Text size='sm'>{item.name}</Text>
    </Group>
  );

  return (
    <GenericSelector
      ref={refFocusOutside}
      label={label}
      required={required}
      data={store.toListState(state.value)}
      renderDataItem={(item) => renderDataItem(item)}
      query={query}
      onQueryChanged={setQuery}
      value={value}
      onChange={(value) => {
        if (onChange) {
          onChange(value);
          if (value) { setEditMode(false) }
        }
      }}
      width={width || 300}
      placeholder={placeholder ?? t('Select a service') as string}
      searchable
      searchField='name'
      clearable={clearable}
      renderOption={renderOption}
      creatable={creatable}
      withinPortal
      readOnly={readOnly}
      style={style}
    />
  );
};
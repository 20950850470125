import { AppConfiguration, authStatus } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import { IdentityRole, MemberItem } from "src/stores/identities";

export const GetUserGroups = () => {
    const userGroups = authStatus?.user.value?.group ? (Array.isArray(authStatus?.user.value?.group) ? authStatus?.user.value?.group : [authStatus?.user.value?.group]) : [];
    return userGroups as string[];
}

export const UserCanContribute = (members: MemberItem[]) => {
    const username = authStatus?.user.value?.sub;
    const userGroups = GetUserGroups();
    const canContribute = members?.some(m => (m?.identity?.toLowerCase() === username?.toLowerCase() || userGroups?.some(ug => ug?.toLowerCase() === m?.identity?.toLowerCase())) && (m?.privileges === 'Contributor' || m?.privileges === 'Owner'));
    return canContribute;
};

export const UserIsOwner = (members: MemberItem[]) => {
    const username = authStatus?.user.value?.sub;
    const userGroups = GetUserGroups();
    const canContribute = members?.some(m => (m?.identity?.toLowerCase() === username?.toLowerCase() || userGroups?.some(ug => ug?.toLowerCase() === m?.identity?.toLowerCase())) && m?.privileges === 'Owner');
    return canContribute;
};

export const HasRole = (role: IdentityRole) => {
    const identityRole = (authStatus?.user.value?.identityRole || "User") as IdentityRole;
    switch (role) {
        case "Administrator":
            return identityRole === "Administrator";
        case "Creator":
            return identityRole === "Administrator" || identityRole === "Creator";
        case "User":
            return identityRole === "Administrator" || identityRole === "Creator" || identityRole === "User";
        default:
            return false;
    }
}

export const CanUsePersonalCollection = () => {
    const allowPersonalCollection = container.get<AppConfiguration>("AppConfiguration").allowPersonalCollection;
    const personalCollectionLevel = container.get<AppConfiguration>("AppConfiguration").personalCollectionLevel;

    if (!allowPersonalCollection) return false;
    if (allowPersonalCollection && personalCollectionLevel === "AllUsers") return true;
    return authStatus?.user.value?.allowPersonalCollection ?? false;
}
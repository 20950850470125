import React, { useEffect, useState } from 'react';
import { ListState, Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { Box, CloseButton, Combobox, Input, InputBase, InputWrapper, Loader, MantineSize, MantineStyleProp, useCombobox, useMantineTheme } from '@mantine/core';
import { AsEnumerable } from 'linq-es5';
import { isNullOrWhitespace } from '../utils/object';
import { useDebouncedValue } from '@mantine/hooks';

export interface GenericSelectorProps<T> {
  data: ListState<T>;
  renderDataItem: (item: T) => any; //TODO: typar para que por lo menos llegue siempre label y value
  query: Query;
  onQueryChanged?: (query: Query) => void;
  value?: string;
  onChange?: (value: string) => void;
  width?: string | number;
  clearable?: boolean;
  searchable?: boolean;
  searchField?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  size?: MantineSize | undefined;
  icon?: React.ReactNode;
  rightSection?: React.ReactNode;
  rightSectionWidth?: string | number;
  exclude?: string[];
  creatable?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  withinPortal?: boolean;
  readOnly?: boolean;
  style?: MantineStyleProp;
  variant?: 'filled' | 'unstyled' | undefined;
  renderOption?: (item: T) => any;
  onOpen?: () => void;
  maxHeight?: number;
  className?: string;
}

export const GenericSelector = React.forwardRef<HTMLInputElement, GenericSelectorProps<any>>(({
  data,
  renderDataItem,
  query,
  value,
  onChange,
  width,
  clearable,
  searchable,
  searchField,
  label,
  placeholder,
  required,
  size,
  icon,
  rightSection,
  rightSectionWidth,
  exclude,
  style,
  creatable,
  onBlur,
  withinPortal,
  readOnly,
  variant,
  renderOption,
  onOpen,
  maxHeight = 300,
  className,
  ...props
}, ref) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [internalValue, setInternalValue] = useState(value);
  const [internalItems, setInternalItems] = useState<string[]>([]);
  const [internalData, setInternalData] = useState([] as { value: string, label: string }[]);
  const [searchDebounced] = useDebouncedValue(searchTerm, 300);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
  }, [])

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  useEffect(() => {
    setInternalValue(value);
    if (value) {
      if (!internalItems.includes(value) && internalData.filter(x => (x as any).value === value).length === 0) {
        setInternalItems([...internalItems, value]);
      }
    }
  }, [value]);

  useEffect(() => {
    if (searchDebounced) {
      onSearchChange(searchDebounced);
    }
    else if (initialized) {
      onSearchChange('');
    }
  }, [searchDebounced])

  useEffect(() => {
    setInternalData(getData() as any);
  }, [JSON.stringify(data), internalItems, exclude]);

  const getData = () => {
    let items = (data?.items?.length > 0) ? data.items.map((item) => renderDataItem(item)) : [];
    if (internalItems && internalItems.length > 0) {
      const itemsAdded = internalItems.map(item => ({ label: item, value: item }));
      items = [...items, ...itemsAdded];
      items = AsEnumerable(items).Distinct(x => x.value).ToArray();
    }
    items = exclude && exclude.length > 0 ? items.filter((x) => !exclude.includes(x.value)) : items;
    return items;
  };

  const onSearchChange = (searchKeyword: string) => {
    if (searchable) {
      if (searchField) {
        const nextP = { ...query?.parameters, $filter: `contains(${searchField},'${searchKeyword}')` };
        const nextQ = { ...query, parameters: nextP };
        if (props.onQueryChanged) props.onQueryChanged(nextQ as Query);
      } else {
        const nextQ = { ...query, searchQuery: searchKeyword };
        if (props.onQueryChanged) props.onQueryChanged(nextQ as Query);
      }
      setSearchTerm(searchKeyword);
    }
  }

  const onSelected = (newValue: string | null) => {
    setInternalValue(newValue as string);
    if (onChange) {
      onChange(newValue as string);
    }
  }

  const onCreate = (newValue: string) => {
    if (creatable && newValue) {
      setInternalItems([...internalItems, newValue]);
    }
  }

  const getRightSection = () => {
    return data && data.isBusy ? <Loader size={20} color={theme.colors.gray[5]} /> : rightSection ?? (clearable && internalValue) ? clearButton : <Combobox.Chevron />;
  }

  const clearButton = clearable && !!internalValue && !readOnly && (
    <CloseButton
      size="sm"
      onMouseDown={(event) => event.preventDefault()}
      onClick={() => {
        setSearchTerm(undefined);
        onSelected(null);
      }}
    />
  );

  const options = data?.items?.map((i) => {
    const item = renderDataItem(i);
    return (<Combobox.Option value={item.value} key={item.value}>
      {renderOption ? renderOption(i) : <>{item.label}</>}
    </Combobox.Option>);
  });

  const selectedOption = () => {
    for (const i of data?.items) {
      const item = renderDataItem(i);
      if (item.value === internalValue) {
        return (<Combobox.Option value={item.value} key={item.value}>
          {renderOption ? renderOption(i) : <>{item.label}</>}
        </Combobox.Option>);
      }
    }

    return !isNullOrWhitespace(internalValue) ? internalValue : <Input.Placeholder>{placeholder}</Input.Placeholder>;
  };

  return (
    <Box ref={ref} style={{ ...style }}>
      <InputWrapper
        label={label}
        style={style}
        w={width}
        required={required && !readOnly}
        className={className}>
        <Combobox
          readOnly={readOnly}
          variant={variant ? variant : !readOnly ? 'default' : 'unstyled'}
          store={combobox}
          withinPortal={withinPortal}
          onOpen={onOpen}
          onOptionSubmit={(val) => {
            if (val === '$create' && searchTerm) {
              const item = { value: searchTerm, label: searchTerm };
              setInternalData((current) => [...current, item]);
              onCreate(searchTerm);
              onSelected(searchTerm);
            } else {
              onSelected(val);
            }

            combobox.closeDropdown();
          }}>
          <Combobox.Target>
            <InputBase
              component="button"
              type="button"
              pointer
              leftSection={icon}
              rightSection={getRightSection()}
              onClick={() => combobox.toggleDropdown()}
              rightSectionPointerEvents={internalValue === undefined ? 'none' : 'all'}
              variant={variant ? variant : !readOnly ? 'default' : 'unstyled'}>
              {selectedOption()}
            </InputBase>
          </Combobox.Target>

          {!readOnly &&
            <Combobox.Dropdown>
              {searchable &&
                <Combobox.Search
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  placeholder={t("Search...") as string}
                />
              }
              <Combobox.Options mah={maxHeight} style={{ overflowY: 'auto' }}>
                {options}
                {searchTerm && searchTerm.trim().length > 0 && (
                  <>
                    {creatable && options.length === 0 &&
                      <Combobox.Option value="$create">+ {t("Add")} {searchTerm}</Combobox.Option>
                    }
                    {!creatable && options.length === 0 &&
                      <Combobox.Empty>{t("No items found")}</Combobox.Empty>
                    }
                  </>
                )}
              </Combobox.Options>
            </Combobox.Dropdown>
          }
        </Combobox>
      </InputWrapper>
    </Box>
  );
});

import { ActionIcon, Alert, Anchor, Button, Card, Group, Modal, Popover, Stack, Stepper, Switch, Text, TextInput, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatMessage } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { IconAlertCircle, IconPlus, IconArrowRight, IconArrowLeft, IconInfoCircle } from "@tabler/icons-react";
import stepperClasses from 'src/pages/stepper.module.css';
import { State, useHookstate } from "@hookstate/core";
import { CrawlerItemStore, CrawlerType, NewCrawlerItem } from "src/stores/crawlers";
import AppContext from "src/services/app-context";
import { CrawlerRef, getCrawlerTypeFields } from "./crawlers/crawlers";
import CrawlerSelector from "./crawlers/crawler-selector";
import TestCrawlerConnectionButton from "./test-crawler-connection-button";

const CreateCrawlerEditInfo: FC<{
  state: State<NewCrawlerItem>;
}> = ({ state }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const [hasSchedule, setHasSchedule] = useState(scopedState.schedule.value.length > 0);

  useEffect(() => {
    if (hasSchedule === false) {
      scopedState.schedule.set('');
    }
  }, [hasSchedule]);

  return (
    <Stack>
      <Group gap="md" justify="center">
        <TextInput
          data-autofocus
          style={{ flex: 1 }}
          required
          label={t('Title')}
          value={scopedState.title.value}
          onChange={(event) => scopedState.title.set(event.target.value)}
        />

        <TextInput
          w={200}
          label={
            <Group align="center" gap="xs">
              <Text>{t('Schedule')}</Text>
              <Switch
                offLabel={t("OFF")} onLabel={t("ON")}
                checked={hasSchedule}
                onChange={(event) => setHasSchedule(event.currentTarget.checked)} />
            </Group>
          }
          disabled={!hasSchedule}
          value={scopedState.schedule.value}
          onChange={(event) => scopedState.schedule.set(event.target.value)}
          rightSection={
            <Popover withArrow shadow="md" withinPortal>
              <Popover.Target>
                <ActionIcon variant='subtle' color="gray">
                  <IconInfoCircle size={16} />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Text size="sm">{t("View more info at")}: <Anchor target="_blank" href="https://en.wikipedia.org/wiki/Cron#CRON_expression">Wiki Cron expression</Anchor></Text>
              </Popover.Dropdown>
            </Popover>
          }
        />
      </Group>

      <CrawlerSelector
        label={t("Type") as string}
        value={scopedState.type.value}
        onChange={(value) => scopedState.type.set(value as CrawlerType)}
        withLogo
      />
    </Stack>
  );
}


const CreateCrawlerWizard: FC<{
  opened: boolean;
  onClose: () => void;
  collectionId?: string;
}> = ({ opened, onClose, collectionId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const { setIsDirty } = useContext(AppContext);
  const store = useMemo(() => container.get(CrawlerItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const crawlerRef = useRef<CrawlerRef>(null);

  const initialValues = {
    collectionId: collectionId,
    content: '{}',
    title: '',
    type: 'SharePoint',
    schedule: '* * * * *'
  } as NewCrawlerItem;

  const crawlerState = useHookstate(initialValues);

  useEffect(() => {
    if (collectionId) {
      crawlerState.collectionId.set(collectionId);
    }
  }, [collectionId])

  const validateStep1 = () => {
    return collectionId && crawlerState.type?.value?.length > 0 && crawlerState?.title?.value.length > 0;
  }

  const goToConfigure = () => {
    nextStep();
  }

  const validateStep2 = () => {
    return crawlerRef.current?.isValid() as boolean;
  }

  const goToValidateAndCreate = () => {
    if (validateStep2()) {
      crawlerState.content.set(crawlerRef.current?.getContent() as string);
      nextStep();
      setShowValidateError(false);
    }
    else {
      setShowValidateError(true);
    }
  }

  const onSubmit = async () => {
    store.setCollection(collectionId as string);
    setIsDirty(false);
    const response = await store.create(crawlerState.value);

    if (response) {
      onCloseModal();
    }
  }

  const onCloseModal = () => {
    store.clearError();
    onClose();
    setShowValidateError(false);
    setIsDirty(false);
  }

  return (
    <Modal
      opened={opened}
      onClose={onCloseModal}
      title={<Text>{t("Create a crawler")}</Text>}
      size="70%"
      closeOnClickOutside={false}
      styles={{
        content: {
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        },
        header: {
          alignItems: 'flex-start'
        }
      }}>
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}
      {showValidateError && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => setShowValidateError(false)}>
          <Text>{t("Please review and complete all required fields before continuing")}</Text>
        </Alert>
      )}
      <form>
        <Stepper
          active={active}
          onStepClick={setActive}
          classNames={stepperClasses}>
          <Stepper.Step
            label={`${t("Type")}`}
            description={t("Select crawler type")}
            allowStepSelect={active > 0}
            loading={active === 0 && isBusy}>
            <Card withBorder shadow="sm" mb="md" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
              <Stack>
                <CreateCrawlerEditInfo
                  state={crawlerState}
                />
              </Stack>
            </Card>

            <Group align="center" justify="space-between" mt="lg">
              <Group align="center" justify="flex-start" wrap="nowrap">
              </Group>
              <Group justify="flex-end">
                <Button
                  disabled={!validateStep1()}
                  rightSection={<IconArrowRight />}
                  onClick={goToConfigure}>{t("Next")}
                </Button>
              </Group>
            </Group>
          </Stepper.Step>

          <Stepper.Step
            label={`${t("Configure")}`}
            description={t("Configure your crawler")}
            allowStepSelect={active > 1}
            loading={active === 1 && isBusy}>

            <Card withBorder shadow="sm" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
              <Card.Section withBorder inheritPadding py="xs" mb="xs">
                <Text fw={500}>{t(crawlerState.type.value)}</Text>
              </Card.Section>
              {getCrawlerTypeFields(crawlerState.type.value, crawlerRef, undefined, true)}
            </Card>

            <Group align="center" justify="space-between" mt="lg">
              <Group align="center" justify="flex-start" wrap="nowrap">
              </Group>

              <Group justify="flex-end">
                <Button variant="default" onClick={() => { prevStep(); }} leftSection={<IconArrowLeft />}>{t("Back")}</Button>
                <Button onClick={() => goToValidateAndCreate()} leftSection={<IconArrowRight />}>{t("Next")}</Button>
              </Group>
            </Group>
          </Stepper.Step>

          <Stepper.Step
            label={`${t("Create")}`}
            description={t("Validate and create")}
            allowStepSelect={active > 1}
            loading={active === 2 && isBusy}>
            <Card withBorder shadow="sm" mb="md" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
              <Stack>
                <>
                  <Text>{t("This is the information provided to create the crawler. Please review before continuing.")}</Text>
                  {crawlerRef.current?.getResume()}
                </>
              </Stack>
            </Card>

            <Group align="center" justify="space-between" mt="lg">
              <Group align="center" justify="flex-start" wrap="nowrap">
              </Group>
              <Group justify="flex-end">
                <TestCrawlerConnectionButton
                  content={crawlerState.content.value}
                  type={crawlerState.type.value}
                  collectionId={collectionId as string}
                />
                <Button variant="default" onClick={prevStep} leftSection={<IconArrowLeft />}>{t("Back")}</Button>
                <Button
                  onClick={onSubmit}
                  loading={isBusy}
                  leftSection={<IconPlus />}>
                  {t("Create")}
                </Button>
              </Group>
            </Group>
          </Stepper.Step>
        </Stepper>
      </form>
    </Modal>
  );
};

export default CreateCrawlerWizard;
import { FC } from 'react';
import { Container, Text, Title } from '@mantine/core';
import classes from './not-found-component.module.css';

export const NotFoundInfo: FC<{
  title: string;
  description?: string;
  children?: JSX.Element;
  height?: string | number;
}> = ({ title, description, children, height }) => {

  return (
    <Container className={classes.root} h={height} >
      <div className={classes.label}>404</div>
      <Title className={classes.title}>{title}</Title>
      {description &&
        <Text c="dimmed" size="lg" ta="center" className={classes.description}>
          {description}
        </Text>
      }
      {children}
    </Container>
  );
};
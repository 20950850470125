import { useMemo, useEffect, FC } from 'react';
import { container } from 'src/inversify.config';
import { SkillItemStore } from 'src/stores/skills';
import { Loader, Text, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const SkillLabel: FC<{
  value?: string;
  lang?: string;
}> = ({ value, lang = 'en' }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(SkillItemStore), []);
  const state = store.state;

  useEffect(() => {
    if (value)
      load(value);
  }, [value])

  const load = async (skillId: string) => {
    if (skillId) {
      store.load(skillId);
    }
  };

  return (
    <>
      {state.isBusy.value && <Loader size="xs" type="dots" />}
      {state?.item?.value &&
        <Tooltip withinPortal label={state.item?.description && state.item.description[lang]?.value || t('(No description)')}>
          <Text span>{state.item.title.value}</Text>
        </Tooltip>
      }
      {!state.isBusy.value && !state?.item?.value &&
        <Text span>{t("No found")}</Text>
      }
    </>
  );
};
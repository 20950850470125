import { FC, useContext, useEffect, useState } from "react";
import { StepNodeProps } from "./steps";
import { useHookstate, none } from "@hookstate/core";
import { Group, Stack, Textarea, Text, Fieldset, Box, Code } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import AppContext from "src/services/app-context";
import StepWrapper from "./step-wrapper";
import { SkillSelector } from "src/components/skill-selector";
import { SkillLabel } from "src/components/skill-label";
import SwitchButton from "src/components/switch-button";
import classes from 'src/pages/index.module.css';
import { LanguageSegmentedControl } from "src/components/language-segmented-control";

const StepExternalSkillNode: FC<StepNodeProps> = ({ id, data }) => {
  const { step, edit, index, rightToolbar, updateNode } = data;
  const scopedState = useHookstate(step.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0])[0] : defaultAllowedLanguages[0]);

  // useEffect(() => {
  //   const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStepAction;
  //   const newAction = { ...step.action, ...changes };
  //   const newStep = { ...step, action: newAction };
  //   const newData = { index: index, step: newStep } as StepDataProps;
  //   updateNode(id, newData);
  // }, [JSON.stringify(scopedState.value)])

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : defaultAllowedLanguages);
  }, [scopedState.text])

  useEffect(() => {
    if (scopedState?.type?.value === 'CallSkill') {
      const defaultLang = allowedLanguages[0];
      let defaultUserText: { [key: string]: string } = {};
      defaultUserText[defaultLang] = t("<Text for the external skill>", { lng: defaultLang });

      if (scopedState?.forwardUserText?.value) {
        scopedState.text.set([]);
      }
      if (!scopedState?.forwardUserText?.value) {
        scopedState.text[scopedState?.text?.length].set(defaultUserText);
      }
    }
  }, [scopedState?.forwardUserText?.value])

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(none);
    }
    setIsDirty(true);
    if (scopedState?.value?.text?.length > 0) {
      setSelectedLanguage(Object.keys(scopedState?.text?.value?.[0])[0]);
    }
  }

  const onAddLanguage = (language: string) => {
    const defaultText = t("<Text for the external skill>", { lng: language });
    for (let index = 0; index < scopedState.value.text.length; index++) {
      (scopedState.text[index])[language].set(defaultText);
    }
    setIsDirty(true);
  }

  if (!scopedState?.value) return (<></>);

  const resume = (
    <>{t("Call an external skill")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" ><SkillLabel value={scopedState?.skillId?.value} lang={selectedLanguage} /></Code></>
  )

  return (
    <StepWrapper
      id={id}
      index={index}
      step={step}
      updateNode={updateNode}
      edit={edit}
      rightToolbar={rightToolbar}
      resume={resume}>
      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Skill")}</Text>
          </Group>
        }>
        <Stack gap="xs">
          {edit ?
            <Box style={{ flex: 1 }}>
              <SkillSelector
                width="100%"
                value={scopedState?.skillId?.value}
                onChange={(value) => { scopedState.skillId.set(value); setIsDirty(true); }}
              />
            </Box>
            :
            <Stack gap="xs">
              <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" ><SkillLabel value={scopedState.skillId.value} lang={selectedLanguage} /></Code>
            </Stack>
          }
        </Stack>
      </Fieldset>

      {edit ?
        <SwitchButton
          className={classes.switchButton}
          //className="nodrag"
          label={t("Forward user text")}
          checked={scopedState.forwardUserText?.value}
          onChange={value => { scopedState.forwardUserText.set(value); setIsDirty(true); }}>
          {!scopedState.forwardUserText?.value ?
            <Stack gap="xs">
              <LanguageSegmentedControl
                editable={edit}
                data={scopedState?.value?.text?.length > 0 ? Object.keys(scopedState?.value?.text?.[0]) : undefined}
                language={selectedLanguage}
                onChange={setSelectedLanguage}
                onDelete={onDeleleLanguage}
                onAdd={onAddLanguage}
              />
              <Textarea
                autosize
                minRows={3}
                maxRows={10}
                style={{ flex: 1, minWidth: 400 }}
                value={scopedState.text?.[0]?.[selectedLanguage as any]?.value as any}
                onChange={(event) => { (scopedState.text[0])[selectedLanguage as any].set(event.target.value as any); setIsDirty(true); }}
              />
            </Stack>
            :
            null
          }
        </SwitchButton>
        :
        <div>
          <Text span fw={500}>{t("Forward")} </Text>
          {!scopedState.forwardUserText?.value
            ?
            <Text span fs="italic">{t("user text")}</Text>
            :
            <Text span fs="italic">{scopedState.text?.[selectedLanguage as any]?.value as any}</Text>
          }
        </div>
      }

    </StepWrapper>
  );
};

export default StepExternalSkillNode;
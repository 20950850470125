/* eslint-disable */
import moment from 'moment';
import numbro from 'numbro';

export const nameof = <T>(name: keyof T) => name;

export function fallbackCopyTextToClipboard(text: string) {
  let textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand('copy');
    let msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

export function isNullOrWhitespace(str?: string) {
  if (str === undefined)
    return true;
  return str === null || str.match(/^ *$/) !== null;
}

export function isNumeric(str?: string) {
  if (str === undefined)
    return false;
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function validateEmail(email: string): boolean {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email)) {
    return true;
  }
  return false;
}

export function unixEpochToDate(epochTime: number): Date {
  return new Date(epochTime * 1000);
}

export const clone = <T>(object: any): T => {
  //if (typeof object === 'object')
  //    return Object.assign({}, object) as T;
  //return object as T;

  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null == object || 'object' != typeof object) return object;

  // Handle Date
  if (object instanceof Date) {
    copy = new Date();
    copy.setTime(object.getTime());
    return copy as any;
  }

  // Handle Array
  if (object instanceof Array) {
    copy = [];
    for (let i = 0, len = object.length; i < len; i++) {
      copy[i] = clone(object[i]);
    }
    return copy as any;
  }

  // Handle Object
  if (object instanceof Object) {
    copy = {};
    for (let attr in object) {
      if (object.hasOwnProperty(attr)) (copy as any)[attr] = clone(object[attr]);
    }
    return copy as any;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const formatDate = (date: Date, t?: (text: string) => string, format?: string) => {
  if (!t) t = (o) => o;
  if (!format) format = 'DD-MM-YYYY';
  if (!date) return `(${t('Not set')})`;
  return moment(date).format(format);
};

export const formatDateTime = (date: Date, t?: (text: string) => string, format?: string) => {
  if (!t) t = (o) => o;
  if (!format) format = 'DD-MM-YYYY HH:mm:ss';
  if (!date) return `(${t('Not set')})`;
  return moment(date).format(format);
};

export const formatDateUtc = (date: Date, t?: (text: string) => string, format?: string) => {
  if (!t) t = (o) => o;
  if (!format) format = 'DD-MM-YYYY';
  if (!date) return `(${t('Not set')})`;
  return moment(date).utc().format(format);
};

export const formatDecimal = (number: number, options?: any) => {
  return (number ? number : 0)
    .toFixed(2)
    .replace(/\./g, ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
export const formatNumber = (value: number, styleOptions?: any) => {
  return numbro(value).format(merge({
    average: false,
    thousandSeparated: true,
    mantissa: 2,
    optionalMantissa: true,
    spaceSeparated: true
  }, styleOptions));
}

export const formatCurrency = (number: number, symbol?: string) => {
  if (!symbol) symbol = '€';
  return `${formatDecimal(number, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${symbol}`;
};

export const formatPercent = (number: number) => {
  return `${formatDecimal(number)}%`;
};

export const formatBoolean = (value: boolean, t: (text: string) => string) => {
  return value ? t('Yes') : t('No');
};

export const getProperties = (o: any): { key: string; value: any }[] => {
  const result = [];
  for (const key in o) {
    if (o.hasOwnProperty(key)) {
      result.push({ key, value: o[key] });
    }
  }

  return result;
};

export function delay(ms: number) {
  return new Promise<void>(function (resolve) {
    setTimeout(resolve, ms);
  });
}

export function lightOrDark(color: any) {
  // Variables for red, green, blue values
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatLargeNumber(number: number, decimals = 2) {
  if (number === 0) return '0';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

  const i = Math.floor(Math.log(number) / Math.log(k));

  return parseFloat((number / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatMessage(result: any): string {
  let message: string = 'Unknown error';
  if (result && result.error) {
    return result.error;
  }
  if (result && result.message) {
    if (result.message.message) return result.message.message;
    return result.message;
  }
  if (result && result.messages && result.messages.length > 0) {
    if (result.messages[0].body) {
      if (result.messages[0].body.message) {
        return result.messages.map((o: any) => o.body.message).join('. ');
      }
      return result.messages.map((o: any) => o.body).join('. ');
    }
    return result.messages.join('. ');
  }
  if (result && result.response && result.response.data) {
    if (result.response.data.messages) {
      message = result.response.data.messages[0].body || result.response.data.messages[0].error;
    }
    if (result.response.data.error) {
      message = `${result.response.status} ${result.response.data.error}`;
    }
    message = `${result.response.status} ${result.response.message}`;
  }
  if (typeof result === 'string' || result instanceof String) return result as string;
  return message;
}

export function tryGetStatusCode(result: any): number | undefined {
  if (result && result.status) {
    return result.status;
  }
}

export function truncateText(text: string, lenght: number = 127) {
  if (!text) return '';
  if (lenght && lenght <= 0) lenght = 127;
  return text.length > lenght ? text.substring(0, lenght) + '...' : text;
}

export function getAvatarText(name: string) {
  if (name) {
    let text = name.toUpperCase();
    if (text.length > 1) {
      return text[0][0] + text[1][0];
    }
    return text[0][0];
  }
  return '';
}

export function isEmpty(str: string) {
  return !str || str.length === 0;
}

export function isBlank(str: string) {
  return !str || /^\s*$/.test(str);
}

export function formatDuration(duration: moment.Duration) {
  // convert 'P1D' to 'PT24H'
  const isoString = moment.duration(duration.asMilliseconds()).toISOString();

  const [, , h = '', , m = '', , s = '', , ms = ''] = isoString.match(/T((\d+)H)?((\d+)M)?(([\d]+)(\.(\d+))?S)?/) ?? [];

  return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}.${ms.padStart(3, '0')}`;
}

export const prettierBranchName = (name: string | undefined) => {
  if (!name) return '';
  return name.replaceAll('__', '/');
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function sql_escape_string(value: string) {
  return value.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char: string) {
    switch (char) {
      case "\0":
        return "\\0";
      case "\x08":
        return "\\b";
      case "\x09":
        return "\\t";
      case "\x1a":
        return "\\z";
      case "\n":
        return "\\n";
      case "\r":
        return "\\r";
      case "\"":
      case "'":
        return char + char; // prepends a backslash to backslash, percent,
      case "\\":
      case "%":
        return "\\" + char; // prepends a backslash to backslash, percent,
      // and double/single quotes
      default:
        return char;
    }
  });
}

export const inputHasWarning = (value: string, onlyStartAndEnd: boolean = false) => {
  if (typeof value !== 'string')
    return false;
  if (onlyStartAndEnd) {
    return value.startsWith(' ') || value.endsWith(' ');
  }
  else {
    return value && /\s/.test(value); // spaces, tabs and newlines
  }
}

export function validateCronExpression(cron: string): boolean {
  const cronregex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
  return cronregex.test(cron);
}

export function merge(base: { [key: string]: any }, from: { [key: string]: any }) {
  if (from) {
    for (const prop in from)
      base[prop] = from[prop];
  }
  return base;
}

export const normalizeName = (str: string, lowerCase: boolean = false) => {
  str = lowerCase ? str.toLowerCase() : str;
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/^[^a-z]*/, '') // Remove accents
    .replace(/([^\w]+|\s+)/g, '_') // Replace space and other characters by hyphen
    .replace(/\-\-+/g, '_')	// Replaces multiple hyphens by one hyphen
    .replace(/(^_+|_+$)/g, ''); // Remove extra hyphens from beginning or end of the string
}

export function validateName(name: string) {
  return /^[a-z][a-z0-9_]*$/.test(name);
}

export const getFileNameFromUri = (uri: string) => {
  return decodeURIComponent(uri.replace(/^.*[\\\/]/, ''));
}

export const getEntitiesInText = (value: string) => {
  let entities = [] as string[];
  let searching = false;
  let text = '';

  if (value.length > 0) {
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      if (searching) {
        text += element;
        if (element === ' ' || (index + 1) === value.length) {
          entities.push(text);
          searching = false;
        }
      }
      else {
        if (element === '@' && ((index > 0 && value[index - 1] === ' ') || index === 0)) {
          text = element;
          searching = true;
        }
      }
    }
  }

  return entities;
}

export const extractTagsFromText = (text: string, tags: string[]) => {
  const tagRegex = /(?<key>[^\s]+):(?<value>(["'][^"]+["']|[^\s"]+))/mg;
  let match: RegExpExecArray | null;
  let matched = false;
  while ((match = tagRegex.exec(text)) !== null) {
    matched = true;
    const tag = `${match!.groups!['key']}:${(match!.groups!['value'].replace(/^['"](.*)['"]$/, '$1'))}`;
    if (tags.indexOf(tag) < 0) {
      tags.push(tag);
    }
  }
  if (matched) {
    text = text.replace(tagRegex, '');
    text = text.split(' ').filter(o => o && o.length > 0).join(' ').trim();
  }
  return [text, tags];
}

export function getEmojiNumber(number: string) {
  switch (Number(number)) {
    case 1:
      return "1️⃣";
    case 2:
      return "2️⃣";
    case 3:
      return "3️⃣";
    case 4:
      return "4️⃣";
    case 5:
      return "5️⃣";
    case 6:
      return "6️⃣";
    case 7:
      return "7️⃣";
    case 8:
      return "8️⃣";
    case 9:
      return "9️⃣";
    default:
      return `[${number.toString()}]`;
  }
}

export const getRandomString = (length: number) => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charLength = chars.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

export const getTranslation = (dict: { [key: string]: string }, language: string): string => {
  if (!dict)
    return "";
  if (dict[language])
    return dict[language];
  if (dict["en"])
    return dict["en"];
  const keys = Object.keys(dict);
  if (keys.length > 0)
    return dict[keys[0]]
  return "";
}

export const hashCode = (text: string) => {
  let hash = 0, i, chr;
  if (text.length === 0) return hash;
  for (i = 0; i < text.length; i++) {
    chr = text.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function hex(buffer: ArrayBuffer) {
  let hexCodes = [];
  let view = new DataView(buffer);
  for (let i = 0; i < view.byteLength; i += 4) {
    const value = view.getUint32(i).toString(16);
    const padding = '00000000';
    const code = (padding + value).slice(-padding.length);
    hexCodes.push(code);
  }
  return hexCodes.join('');
}

export async function sha1(str: string) {
  const buffer = new TextEncoder().encode(str);
  const hash = await crypto.subtle.digest('SHA-1', buffer);
  return hex(hash);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

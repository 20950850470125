import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Grid, Card, Group, Button, Text, Alert, LoadingOverlay, Container, Tabs, Input, TextInput, List, Tooltip, ActionIcon, useMantineTheme, useMantineColorScheme, Popover } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import { container } from 'src/inversify.config';
import { formatMessage, getRandomString, normalizeName, validateName } from 'src/core/utils/object';
import Editor from '@monaco-editor/react';
import { GetSkillDefaultContent, SearchSkillEntitiesStore, SkillContent, SkillDialogContent, SkillItem, SkillItemStore, SkillSearchContent, SkillSnapshotItem, TagItem, UpsertSkillSnapshotItem } from 'src/stores/skills';
import MembersCard from 'src/components/members-card';
import TagsCard from 'src/components/tags-card';
import BootstrapIntentsCard from 'src/components/bootstrap-intents-card';
import { State, useHookstate } from '@hookstate/core';
import { LanguageSeletor } from 'src/components/language-selector';
import useBus, { dispatch } from 'use-bus';
import { tagsToArray, tagsToDict } from 'src/utils/tags-utils';
import VariablesCard from 'src/components/variables-card';
import SkillContentIntentsCard from './skill-content-intents';
import SkillContentStepsCard from './skill-content-steps';
import SkillContentSearchCard from './skill-content-search';
import { JSONError } from "json-schema-library";
import jsyaml from "js-yaml";
import AppContext from 'src/services/app-context';
import SkillContentEntitiesCard from './skill-content-entities';
import { IconWand, IconAlertCircle, IconSearch, IconMessages, IconMessageCircle, IconTags, IconFileCode, IconCode, IconArrowBackUp, IconDeviceFloppy, IconCodePlus, IconPlugConnected, IconAlertTriangle } from '@tabler/icons-react';
import SkillContentParametersCard from './skill-content-parameters';
import ContentWrapper from 'src/components/content-wrapper';
import SkillApiDetails from './skill-api-details';
import classes from 'src/pages/index.module.css';
import { MemberEditorState, MemberItem } from 'src/stores/identities';
import { monacoOptions } from 'src/configurations/editor-config-jinja';
import LocalizedTextArea from 'src/components/localized-textarea';
import { StepsRef } from './steps/steps';

const SkillEditBasicInfo: FC<{
  state: State<SkillItem>;
}> = ({ state }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);

  return (
    <Card withBorder>
      <Stack gap="xs">
        <TextInput
          required
          label={t('Title')}
          description={t("Provide a full name for your skill to help search and understand its purpose.")}
          value={scopedState.title.value}
          onChange={(event) => { scopedState.title.set(event.target.value); setIsDirty(true); }}
          rightSection={scopedState.title.value.length > 0 && !validateName(scopedState.title.value) ?
            <Popover position="bottom" width={300} withArrow shadow="md">
              <Popover.Target>
                <ActionIcon color="yellow" variant="transparent">
                  <IconAlertTriangle />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack gap={10}>
                  <Text size="sm">
                    {t("Use lowercase names, underscores or numbers, otherwise LLM-type bots using this skill might have problems.")}
                  </Text>
                  <Button onClick={() => scopedState.title.set((c) => normalizeName(c, true))}>{t("Normalize")}</Button>
                </Stack>
              </Popover.Dropdown>
            </Popover>
            :
            <></>}
        />

        <LocalizedTextArea
          label={t("Description") as string}
          edit
          value={scopedState.description.value}
          onChange={(value) => { scopedState.description.set(value); setIsDirty(true); }}
        />

        <LanguageSeletor
          required
          multiselect
          label={t('Languages') as string}
          value={scopedState.currentSnapshot?.languages?.value as string[]}
          onChange={(value) => { scopedState.currentSnapshot.languages.set(value as string[]); setIsDirty(true); }}
        />
      </Stack>
    </Card>
  );
}

const SkillEdit: React.FC = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { skillId } = useParams();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [activeTab, setActiveTab] = useState("conversation");
  const store = useMemo(() => container.get(SkillItemStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const tagsScopedState = useHookstate([] as TagItem[]);
  const variablesScopedState = useHookstate([] as TagItem[]);
  const contentScopedState = useHookstate({} as SkillContent);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const [contentErrors, setContentErrors] = useState<JSONError[]>([]);
  const [advancedEditor, setAdvancedEditor] = useState<boolean>(false);
  const [yamlContent, setYamlContent] = useState<string>('');
  const { setIsDirty } = useContext(AppContext);
  const searchEntitiesStore = useMemo(() => container.get(SearchSkillEntitiesStore), []);
  const stepsRef = useRef<StepsRef>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(t('Edit skill'), 'Skills', <IconWand size={34} />, `${t('Edit skill')} | ${t("Skills")}`, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/skills`, breadcrumbName: t('Skills') },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);

  useEffect(() => {
    if (skillId) {
      load(skillId);
    }
  }, [skillId]);

  const load = async (skillId: string) => {
    if (skillId) {
      await store.load(skillId);
      loadForm();
    }
  };

  useBus(
    '@@ui/LANGUAGE_SEGMENTED_CONTROL/SELECTED_LANGUAGE',
    (event) => setSelectedLanguage(event.payload),
    [selectedLanguage]
  )

  const loadForm = () => {
    if (state?.item?.value) {
      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      const variables = tagsToArray(state.item.currentSnapshot?.variables?.value ?? []);
      variablesScopedState.set(variables);

      const copy = JSON.parse(JSON.stringify(state.item.value)) as MemberEditorState;
      membersState.set({
        accessMode: copy.accessMode,
        members: copy.members
      });

      if (!state.item.currentSnapshot?.value) {
        state.item.currentSnapshot.set({
          bootstrapIntents: [] as string[],
          content: '', // template
          languages: [] as string[],
          skillId: skillId,
          variables: {}
        } as SkillSnapshotItem);
      }

      if (state.item.currentSnapshot?.content?.value) {
        contentScopedState.set(JSON.parse(state.item.currentSnapshot.content.value));

        if ((state.item.type.value === 'Dialog' || state.item.type.value === 'Function')) {
          if (!(contentScopedState as State<SkillDialogContent>) || !(contentScopedState as State<SkillDialogContent>).parameters.value) {
            (contentScopedState as State<SkillDialogContent>).parameters.set([]);
          }
          if (!(contentScopedState as State<SkillDialogContent>) || !(contentScopedState as State<SkillDialogContent>).options.value) {
            (contentScopedState as State<SkillDialogContent>).options.set({
              behavior: {},
              requestConfirmation: true
            });
          }
        }

        const doc = jsyaml.load(state.item.currentSnapshot.content.value);
        setYamlContent(jsyaml.dump(doc));
      }
      else {
        const defaultContent = GetSkillDefaultContent(state.item.type.value);
        contentScopedState.set(defaultContent);
        const doc = jsyaml.load(JSON.stringify(defaultContent, null, 2));
        setYamlContent(jsyaml.dump(doc));
      }
    }
  }

  const ensureStepTitles = () => {
    if (state.item.type.value === 'Dialog' || state.item.type.value === 'Function') {
      const content = JSON.parse(JSON.stringify(contentScopedState.value)) as SkillDialogContent;
      const duplicates = content.steps.filter((item, index) => content.steps.some((elem, idx) => elem.step === item.step && idx !== index)).map(o => o.step);

      if (duplicates.length > 0) {
        for (const step of content.steps) {
          if (duplicates.includes(step.step)) {
            step.step = `${step.step}_${getRandomString(6)}`;
          }
        }

        contentScopedState.set(content);
      }
    }
  }

  const onSubmit = async () => {
    const copy = JSON.parse(JSON.stringify(membersState.value)) as MemberEditorState;
    if (state.item.type.value === 'Dialog' || state.item.type.value === 'Function') {
      const steps = stepsRef.current?.getSteps();
      if (steps) {
        (contentScopedState as State<SkillDialogContent>).steps.set(steps);
      }
    }

    ensureStepTitles();
    const snapshotItem = {
      id: skillId,
      type: state.item.type.value,
      title: state.item.title.value,
      description: state.item.description.value,
      accessMode: copy.accessMode,
      bootstrapIntents: state.item.currentSnapshot.bootstrapIntents.value as string[],
      content: advancedEditor ? JSON.stringify(jsyaml.load(yamlContent), null, 2) : JSON.stringify(contentScopedState.value, null, 2),
      languages: state.item.currentSnapshot.languages.value as string[],
      members: copy.members,
      tags: tagsToDict(tagsScopedState.value as TagItem[]),
      variables: tagsToDict(variablesScopedState.value as TagItem[]),
      logApiKeys: state.item.logApiKeys.value
    } as UpsertSkillSnapshotItem;

    if (validateSkillContent()) {
      setIsDirty(false);
      let response = await store.saveSnapshot(skillId as string, snapshotItem);
      if (response) {
        navigate(`/admin/skills/${skillId}`);
        dispatch('@@ui/SKILL_LIST_REFRESH');
      }
    }
  }

  const validateSkillContent = () => {
    // const content = advancedEditor ? jsyaml.load(yamlContent) : contentScopedState.value;
    // const jsonSchema = new Draft07(SkillContentSchema);
    // const errors: JSONError[] = jsonSchema.validate(content);
    // setContentErrors(errors);
    //console.log(errors);

    //return errors.length === 0;

    //TODO: hacer un json schema correcto
    return true;
  }

  const onCancel = () => {
    if (skillId) {
      navigate(`/admin/skills/${skillId}`);
    }
    else {
      navigate(`/admin/skills`);
    }
  }

  return (
    <ContentWrapper>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        <form>
          {errorMessage && (
            <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
              <Text>{formatMessage(errorMessage)}</Text>
            </Alert>
          )}
          {contentErrors?.length > 0 && (
            <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
              <Text>{t("The following errors have occurred:")}</Text>
              <List size="sm" withPadding>
                {contentErrors.map((e, index) =>
                  <List.Item key={index}>{e.message}</List.Item>
                )}
              </List>
            </Alert>
          )}

          {state?.item?.value && state?.item?.currentSnapshot?.value && contentScopedState?.value &&
            <>
              <Grid align="stretch">
                <Grid.Col span="auto">
                  <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
                    <Tabs.List>
                      {!advancedEditor ?
                        <>
                          {state.item.type.value === 'Search' && <Tabs.Tab value="conversation" leftSection={<IconSearch size={20} />}>{t('Search')}</Tabs.Tab>}
                          {(state.item.type.value === 'Dialog' || state.item.type.value === 'Function') && <Tabs.Tab value="conversation" leftSection={<IconMessages size={20} />}>{t('Conversation')}</Tabs.Tab>}
                          {(state.item.type.value === 'Dialog' || state.item.type.value === 'Function') && <Tabs.Tab value="intents" leftSection={<IconMessageCircle size={20} />}>{t('Intents')}</Tabs.Tab>}
                          {(state.item.type.value === 'Dialog' || state.item.type.value === 'Function') && <Tabs.Tab value="parameters" leftSection={<IconCodePlus size={20} />}>{t('Parameters')}</Tabs.Tab>}
                          <Tabs.Tab value="entities" leftSection={<IconTags size={20} />}>{t('Entities')}</Tabs.Tab>
                          <Tabs.Tab value="api" leftSection={<IconPlugConnected size={20} />}>{t('API details')}</Tabs.Tab>
                        </>
                        :
                        <>
                          <Tabs.Tab value="api" leftSection={<IconPlugConnected size={20} />}>{t('API details')}</Tabs.Tab>
                          <Tabs.Tab value="source" leftSection={<IconFileCode size={20} />}>{t('Source code')}</Tabs.Tab>
                        </>
                      }
                    </Tabs.List>
                  </Tabs>
                </Grid.Col>
                <Grid.Col span="content">
                  <Group align="center" justify='right' wrap='nowrap'>
                    {!advancedEditor &&
                      <Tooltip withinPortal label={`${t("Advanced editor")} YAML`}>
                        <ActionIcon onClick={() => { setActiveTab('source'); setAdvancedEditor(true); }} variant="outline" color={theme.colors[theme.primaryColor][6]} size="lg">
                          <IconCode />
                        </ActionIcon>
                      </Tooltip>
                    }
                    <Button
                      variant='outline'
                      onClick={onCancel}
                      leftSection={<IconArrowBackUp />}>
                      {t("Cancel")}
                    </Button>
                    <Button
                      loading={isBusy}
                      onClick={onSubmit}
                      leftSection={<IconDeviceFloppy />}>
                      {t("Save")}
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
              <Container fluid px={0} py={0} mt="xs">
                {activeTab === "conversation" && !advancedEditor &&
                  <Grid gutter={'xs'}>
                    <Grid.Col span={{ base: 12, lg: 8 }}>
                      {!state.item.value.currentSnapshot && <Alert icon={<IconAlertCircle size={16} />} title={t("Skill misconfiguration!")} color="red">
                        {t('Your source code is corrupt and we cannot process it. Please edit it and correct the problems.')}
                      </Alert>}
                      <Stack gap="xs">
                        {(state.item.type.value === 'Dialog' || state.item.type.value === 'Function') && <SkillContentStepsCard
                          cardkey='skill-content-steps'
                          key={`skill-content-steps-edit-${skillId}`}
                          skillId={skillId as string}
                          skillType={state.item.type.value}
                          state={(contentScopedState as State<SkillDialogContent>).steps}
                          edit
                          ref={stepsRef}
                        />}
                        {state.item.type.value === 'Search' && <SkillContentSearchCard
                          cardkey='skill-content-steps'
                          key={`skill-content-search-edit-${skillId}`}
                          state={contentScopedState as State<SkillSearchContent>}
                          edit
                        />}
                      </Stack>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <Stack gap="xs">
                        <SkillEditBasicInfo
                          state={state.item}
                        />

                        <MembersCard
                          cardKey='skill-members-card-edit'
                          mode="edit"
                          state={membersState}
                          key={`skill-members-edit-${skillId}`}
                        />

                        {state.item.type.value !== 'Search' && <BootstrapIntentsCard
                          cardKey='skill-intents-card-edit'
                          mode="edit"
                          state={state.item.currentSnapshot.bootstrapIntents}
                          id={skillId as string}
                          searchEntitiesStore={searchEntitiesStore}
                          key={`skill-intents-edit-${skillId}`}
                        />}

                        <TagsCard
                          cardKey='skill-tags-card-edit'
                          mode="edit"
                          state={tagsScopedState}
                          key={`skill-tags-edit-${skillId}`}
                        />

                        <VariablesCard
                          cardKey='skill-variables-card-edit'
                          mode="edit"
                          state={variablesScopedState}
                          key={`skill-variables-edit-${skillId}`}
                        />
                      </Stack>
                    </Grid.Col>
                  </Grid>
                }
                {activeTab === "intents" && !advancedEditor && (state.item.type.value === 'Dialog' || state.item.type.value === 'Function') &&
                  <SkillContentIntentsCard
                    key={`skill-content-intents-edit-${skillId}`}
                    cardkey='skill-content-intents'
                    state={(contentScopedState as State<SkillDialogContent>).intents}
                    id={skillId as string}
                    searchEntitiesStore={searchEntitiesStore}
                    edit
                  />
                }
                {activeTab === "parameters" && !advancedEditor && (state.item.type.value === 'Dialog' || state.item.type.value === 'Function') &&
                  <SkillContentParametersCard
                    key={`skill-content-parameters-edit-${skillId}`}
                    cardkey='skill-content-parameters'
                    type={state.item.type.value}
                    state={(contentScopedState as State<SkillDialogContent>).parameters}
                    options={(contentScopedState as State<SkillDialogContent>).options}
                    edit
                  />
                }
                {activeTab === "entities" && !advancedEditor &&
                  <SkillContentEntitiesCard
                    key={`skill-content-entities-edit-${skillId}`}
                    cardkey='skill-content-entities'
                    state={(contentScopedState as State<SkillDialogContent>).entities}
                    edit
                  />
                }
                {activeTab === "api" && (
                  <SkillApiDetails
                    cardkey='skill-api-details-edit'
                    state={state.item}
                    edit
                    key={`skill-api-details-edit-${skillId}`}
                  />
                )}
                {activeTab === "source" && advancedEditor && (
                  <Grid gutter={'xs'}>
                    <Grid.Col span={{ base: 12, lg: 8 }}>
                      <Card withBorder>
                        <Input.Wrapper
                          required
                          style={{ width: '100%', height: 'calc(100svh - 120px)' }}>
                          <Editor
                            width="100%"
                            height="100%"
                            options={monacoOptions}
                            language="yaml"
                            value={yamlContent}
                            onChange={(value) => { setYamlContent(value as string); setIsDirty(true); }}
                            theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                          />
                        </Input.Wrapper>
                      </Card>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <Stack gap="xs">
                        <SkillEditBasicInfo
                          state={state.item}
                          key={`skill-basic-edit-${skillId}`}
                        />

                        <MembersCard
                          cardKey='skill-members-card-edit'
                          mode="edit"
                          state={membersState}
                          key={`skill-members-edit-${skillId}`}
                        />

                        <BootstrapIntentsCard
                          cardKey='skill-intents-card-edit'
                          mode="edit"
                          state={state.item.currentSnapshot.bootstrapIntents}
                          key={`skill-intents-edit-${skillId}`}
                        />

                        <TagsCard
                          cardKey='skill-tags-card-edit'
                          mode="edit"
                          state={tagsScopedState}
                          key={`skill-tags-edit-${skillId}`}
                        />

                        <VariablesCard
                          cardKey='skill-variables-card-edit'
                          mode="edit"
                          state={variablesScopedState}
                          key={`skill-variables-edit-${skillId}`}
                        />
                      </Stack>
                    </Grid.Col>
                  </Grid>
                )}
              </Container>
            </>
          }
        </form>
      </div>
    </ContentWrapper>
  );
};

export default SkillEdit;

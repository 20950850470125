import { RefObject } from "react"
import { CrawlerType } from "src/stores/crawlers"
import Microsoft365Crawler from "./microsoft365-crawler"
import ServiceNowCrawler from "./servicenow-crawler"
import SharepointCrawler from "./sharepoint-crawler"
import WebCrawler from "./web-crawler"

export type CrawlerRef = {
  getContent: () => string,
  isValid: () => boolean,
  getResume: () => React.ReactNode
}

export const getCrawlerTypeFields = (type: CrawlerType, crawlerRef: RefObject<CrawlerRef>, content?: string, canContribute?: boolean) => {
  switch (type) {
    case 'Microsoft365':
      return <Microsoft365Crawler ref={crawlerRef} key='crawler-m365' content={content} canContribute={canContribute} />
    case 'ServiceNow':
      return <ServiceNowCrawler ref={crawlerRef} key='crawler-servicenow' content={content} canContribute={canContribute} />
    case 'SharePoint':
      return <SharepointCrawler ref={crawlerRef} key='crawler-sharepoint' content={content} canContribute={canContribute} />
    case 'Web':
      return <WebCrawler ref={crawlerRef} key='crawler-web' content={content} canContribute={canContribute} />
    default:
      return <Microsoft365Crawler ref={crawlerRef} key='crawler-m365' content={content} canContribute={canContribute} />
  }
}
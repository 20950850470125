import { ActionIcon, Alert, Button, Checkbox, CopyButton, Group, Input, LoadingOverlay, Modal, SegmentedControl, Select, Stack, Switch, Text, TextInput, Tooltip } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { container } from "src/inversify.config";
import { formatMessage } from "src/core/utils/object";
import { IdentityForm, IdentityItem, IdentityItemStore, IdentityRole, IdentitySummaryStore } from "src/stores/identities";
import { Query } from "src/core/stores/data-store";
import { useModals } from "@mantine/modals";
import { IconCheck, IconCopy, IconAlertCircle, IconUser, IconUsers, IconDeviceFloppy } from "@tabler/icons-react";
import { TransferList, TransferListData, TransferListItem } from "src/components/transfer-list";
import { AppConfiguration } from "src/core/services/authentication-service";

function compareNames(a: TransferListItem, b: TransferListItem) {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
}

const EditIdentity: FC<{
  opened: boolean;
  identityName?: string;
  onClose: () => void;
  onFinish?: () => void;
}> = ({ opened, identityName, onClose, onFinish }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(IdentityItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const summaryStore = useMemo(() => container.get(IdentitySummaryStore), []);
  const summaryState = summaryStore.state;
  const initialValues: TransferListData = [[], []];
  const [transferListData, setTransferListData] = useState<TransferListData>(initialValues);
  const modals = useModals();
  const allowPersonalCollection = container.get<AppConfiguration>("AppConfiguration").allowPersonalCollection;
  const personalCollectionLevel = container.get<AppConfiguration>("AppConfiguration").personalCollectionLevel;

  const form = useForm<IdentityForm>({
    initialValues: {
      name: '',
      role: 'User',
      displayName: '',
      isGroup: false,
      assigned: [],
      generateApiKey: false,
      allowPersonalCollection: false
    },
    validateInputOnBlur: true,
    validate: {
      name: (value) => (value.length === 0 /*|| !validateName(value)*/ ? t('Invalid name') : null),
    },
  });

  useEffect(() => {
    if (identityName) {
      load(identityName);
    }
    else {
      form.reset();
      loadIdentities(query);
    }
  }, [identityName]);

  const load = async (identityName: string) => {
    await store.load(identityName);
    if (!state.errorMessage.value) {
      setFormValues();
    }
  }

  const setFormValues = () => {
    if (state?.item?.value) {
      form.setFieldValue('name', state.item.value.name);
      form.setFieldValue('displayName', state.item.value.displayName);
      form.setFieldValue('isGroup', state.item.value.isGroup);
      const assignedToForm = state.item.value.assigned.map((a) => a.name);
      form.setFieldValue('assigned', assignedToForm);
      form.setFieldValue('role', state.item.value.role);
      let canUsePersonalCollection = allowPersonalCollection && personalCollectionLevel === "AllUsers";
      if (allowPersonalCollection && personalCollectionLevel === "SelectedUsersOnly") {
        canUsePersonalCollection = state.item.value.metadata?.["allowPersonalCollection"] ?? false;
      }

      form.setFieldValue('allowPersonalCollection', canUsePersonalCollection)
    }
  }

  const [query, setQuery] = useState<Query>({
    searchQuery: '',
    orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: 5000,
    parameters: {
      identityTypeFilter: form.values.isGroup ? 'user' : 'group',
    },
  } as Query);

  useEffect(() => {
    loadIdentities(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const loadIdentities = async (query: Query) => {
    await summaryStore.load(query);
  };

  useEffect(() => {
    if (!summaryState.isBusy.value) {
      updateTransferList();
    }
  }, [summaryState?.items, state?.item?.assigned])

  const updateTransferList = () => {
    let identityList = [] as TransferListItem[];
    let assignedList = [] as TransferListItem[];

    if (identityName !== undefined) {
      const assigned = state?.item?.assigned?.value as IdentityItem[] ?? [] as IdentityItem[];

      if (!summaryState?.errorMessage?.value && summaryState?.items?.value) {
        identityList = summaryState.items.filter(i => assigned.every(a => a.name !== i.name.value)).map((item) => {
          return {
            label: item.name.value,
            value: item.name.value,
          } as TransferListItem
        }).sort(compareNames);
      }

      assignedList = assigned.map((item) => {
        return {
          label: item.name,
          value: item.name,
        } as TransferListItem
      }).sort(compareNames);
    }
    else {
      if (!summaryState?.errorMessage?.value && summaryState?.items?.value) {
        identityList = summaryState.items.map((item) => {
          return {
            label: item.name.value,
            value: item.name.value,
          } as TransferListItem
        }).sort(compareNames);
      }
    }

    const newTransferListData = {
      "0": identityList,
      "1": assignedList
    } as TransferListData;

    setTransferListData(newTransferListData);
  }

  const onChangeTransferListForm = (value: TransferListData) => {
    setTransferListData(value);
    form.setFieldValue('assigned', value[1].map((a) => a.value));
  }

  useEffect(() => {
    const newQuery = {
      ...query,
      skip: 0,
      parameters: {
        identityTypeFilter: form.values.isGroup ? 'user' : 'group',
      },
    } as Query;
    setQuery(newQuery);
  }, [form.values.isGroup]);

  const onSubmit = async (values: IdentityForm) => {
    if (form.isValid()) {
      let metadata = {} as { [key: string]: any };
      metadata['allowPersonalCollection'] = values.allowPersonalCollection;

      let identity = {
        name: values.name,
        displayName: values.displayName,
        isGroup: values.isGroup,
        role: values.role,
        generateApiKey: values.generateApiKey,
        assigned: values.assigned.map((name) => { return { name: name } }),
        metadata: metadata
      } as IdentityItem;

      const data = await store.save(values.name, identity);
      if (!state.errorMessage.value) {
        if (data?.apiKey && data?.apiKey !== "") {
          modals.openConfirmModal({
            title: <Text>{t('Grab your API key')}</Text>,
            children: (
              <Text size="sm">
                {t('Make sure you copy your API Key and save it because it will not be shown again.')}
                <br></br>
                <Group align="center">
                  <Text w={90}>{t("API Key")}:</Text>
                  <Input
                    style={{ flex: 1 }}
                    readOnly
                    value={data.apiKey ?? ""}
                  />
                  <CopyButton value={data.apiKey ?? ""} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? t('Copied') : t('Copy')} withArrow position="right">
                        <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy} variant='subtle'>
                          {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Group>
              </Text>
            ),
            labels: { confirm: t('I got it'), cancel: t('Cancel') },
            onConfirm: () => {
              onFinish?.();
              form.reset();
            }
          });
        }
        else {
          onFinish?.();
          form.reset();
        }
      }
    }
  }

  return (
    <Modal
      title={<span>{identityName ? <span>{t("Edit identity")} <b>{identityName}</b></span> : t("Create identity")} </span>}
      size='xl'
      withCloseButton
      closeOnClickOutside={false}
      onClose={onClose}
      opened={opened}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy || summaryState.isBusy.value} />
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values: IdentityForm) => onSubmit(values))}>
          <Stack>
            <Group align="flex-start">
              <TextInput
                required
                disabled={identityName !== undefined}
                data-autofocus
                label={t('Name')}
                {...form.getInputProps('name')}
              />

              <TextInput
                style={{ flex: 1 }}
                label={t('Full name')}
                {...form.getInputProps('displayName')}
              />
            </Group>

            <Select
              allowDeselect={false}
              label={t("Role")}
              description={t("Identity role type")}
              defaultValue='naive'
              data={[
                { value: 'User', label: t("User") as string },
                { value: 'Creator', label: t("Creator") as string },
                { value: 'Administrator', label: t("Administrator") as string },
              ]}
              value={form.values?.role ?? 'User'}
              onChange={value => form.setFieldValue('role', value as IdentityRole)}
            />

            <Stack gap={5}>
              <Input.Label required>{t("Identity type")}</Input.Label>
              <SegmentedControl
                value={form.values.isGroup ? 'group' : 'user'}
                onChange={value => form.setFieldValue('isGroup', value === 'group')}
                disabled={identityName !== undefined}
                data={[
                  {
                    value: 'user',
                    label: (
                      <Group gap="xs" align="center" justify="center">
                        <IconUser size={16} />
                        <Text>{t("User")}</Text>
                      </Group>
                    ),
                  },
                  {
                    value: 'group',
                    label: (
                      <Group gap="xs" align="center" justify="center">
                        <IconUsers size={16} />
                        <Text>{t("Group")}</Text>
                      </Group>
                    ),
                  },
                ]}
              />
            </Stack>

            <TransferList
              value={transferListData}
              onChange={onChangeTransferListForm}
              titles={[form.values.isGroup ? t('Users') : t('Groups'), t('Assigned')]}
              searchPlaceholder={t("Search...") as string}
              nothingFound={t("Nothing here") as string}
            />

            <Group justify="space-between" align="center">
              <Checkbox
                style={{ flex: 1 }}
                label={t('Generate API key for this identity')}
                {...form.getInputProps('generateApiKey', { type: "checkbox" })} />
              <Switch
                style={{ flex: 1 }}
                label={t("Allow personal collection")}
                disabled={!allowPersonalCollection || (allowPersonalCollection && personalCollectionLevel === "AllUsers")}
                {...form.getInputProps('allowPersonalCollection', { type: "checkbox" })}
              />
            </Group>
          </Stack>
          <Group justify="flex-end" mt="xl">
            <Button
              disabled={!form.isValid()}
              loading={isBusy}
              leftSection={<IconDeviceFloppy />}
              type="submit">
              {t("Save")}
            </Button>
          </Group>
        </form>
      </div>
    </Modal>
  );
};

export default EditIdentity;
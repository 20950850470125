import { Box, Group, Input, Stack, UnstyledButton, Text, Select } from "@mantine/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import webapiLogo from 'src/assets/images/webapi-logo.png';
import sharepointLogo from 'src/assets/images/sharepoint-logo.png';
import microsoft365Logo from 'src/assets/images/microsoft-365-logo.png';
import serviceNowLogo from 'src/assets/images/servicenow.png';
import { CrawlerType } from "src/stores/crawlers";
import crawlerClasses from '../create-crawler-wizard.module.css';
import cx from 'clsx';

const CrawlerSelector: FC<{
  label?: string,
  value?: CrawlerType,
  onChange?: (type: CrawlerType) => void,
  withLogo?: boolean,
  required?: boolean,
  disabled?: boolean,
  edit?: boolean;
}> = ({ label, value, onChange, withLogo = false, required = false, disabled = false, edit = true }) => {
  const { t } = useTranslation();

  const crawlerTypeData = [
    { value: 'SharePoint', label: t('Microsoft SharePoint') as string, icon: <img src={sharepointLogo} /> },
    { value: 'Web', label: t('Web') as string, icon: <img src={webapiLogo} /> },
    { value: 'ServiceNow', label: t('Service Now') as string, icon: <img src={serviceNowLogo} /> },
    { value: 'Microsoft365', label: t('Microsoft 365') as string, icon: <img src={microsoft365Logo} /> },
  ];

  return (
    <>
      {withLogo ?
        <Input.Wrapper
          mb="md"
          required
          label={label}>
          <Group gap="xl">
            {crawlerTypeData.map((c, index) => (
              <UnstyledButton key={c.value}
                className={cx(crawlerClasses.driverButton, { [crawlerClasses.driverButtonSelected]: value === c.value })}
                onClick={() => onChange?.(c.value as CrawlerType)}>
                <Stack justify="center" align="center">
                  <Box className={crawlerClasses.iconWrapper}>
                    {c.icon}
                  </Box>
                  <Text>{c.label}</Text>
                </Stack>
              </UnstyledButton>
            ))}
          </Group>
        </Input.Wrapper >
        :
        <Select
          allowDeselect={false}
          required={required}
          disabled={disabled}
          variant={edit ? 'default' : 'unstyled'}
          label={label}
          w={200}
          data={crawlerTypeData}
          value={value}
          onChange={(value) => onChange?.(value as CrawlerType)}
        />
      }
    </>
  );
};

export default CrawlerSelector;
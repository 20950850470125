import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { container } from "src/inversify.config";
import { useHookstate } from "@hookstate/core";
import { Alert, Button, Group, Modal, Select, ComboboxItem, Stack, Text, TextInput } from "@mantine/core";
import { formatMessage } from "src/core/utils/object";
import { CollectionEntityItemStore, NewColectionEntityItem } from "src/stores/collections";
import { EntityType } from "src/stores/entities";
import { LanguageSegmentedControl } from "src/components/language-segmented-control";
import { IconAlertCircle, IconPlus } from "@tabler/icons-react";
import { AppConfiguration } from "src/core/services/authentication-service";
import { BasicMultiselect } from "src/components/basic-multiselect";

const CreateCollectionEntity: FC<{
  opened: boolean;
  onClose: () => void;
  collectionId: string;
}> = ({ opened, onClose, collectionId }) => {
  const { t } = useTranslation();
  const store = useMemo(() => container.get(CollectionEntityItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const allowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const defaultSamples: { [key: string]: string[] } = {};
  defaultSamples[allowedLanguages[0]] = [];

  const scopedState = useHookstate({
    collectionId: collectionId,
    name: '',
    type: "Synonyms",
    samples: defaultSamples
  } as NewColectionEntityItem);

  const isValid = () => {
    return scopedState.name.value && scopedState.type.value && scopedState.samples.value;
  }

  const onSubmit = async () => {
    if (isValid()) {

      store.setCollection(collectionId);
      const response = await store.create(scopedState.value as NewColectionEntityItem);

      if (response) {
        onCloseModal();
      }
    }
  }

  const onCloseModal = () => {
    store.clearError();
    onClose();
  }

  const getSamples = (lang: string) => {
    let data = [] as ComboboxItem[];

    if (scopedState?.samples?.value && scopedState.samples[lang].value) {
      data = scopedState.samples[lang].value.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  return (
    <Modal
      opened={opened}
      size='xl'
      onClose={onCloseModal}
      title={<Text>{t("Create a entity")}</Text>}
      closeOnClickOutside={false}>
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}
      <form>
        <Stack>
          <Group>
            <TextInput
              required
              data-autofocus
              style={{ flex: 1 }}
              label={t('Name')}
              value={scopedState.name.value}
              onChange={(event) => scopedState.name.set(event.target.value)}
            />

            <Select
              allowDeselect={false}
              required
              label={t("Type")}
              placeholder={t("Entity type") as string}
              data={[
                { value: 'Synonyms', label: t('Synonyms') as string },
                { value: 'Regex', label: t('Regex') as string },
              ]}
              value={scopedState.type.value}
              onChange={(value) => scopedState.type.set(value as EntityType)}
            />
          </Group>

          <LanguageSegmentedControl
            language={selectedLanguage}
            onChange={setSelectedLanguage}
          />

          <BasicMultiselect
            label={t("Samples") as string}
            data={getSamples(selectedLanguage)}
            value={scopedState.samples[selectedLanguage].value as string[]}
            onChange={(value) => scopedState.samples[selectedLanguage].set(value)}
            creatable
          />

        </Stack>

        <Group justify="flex-end" mt="xl">
          <Button
            disabled={!isValid()}
            loading={isBusy}
            leftSection={<IconPlus />}
            onClick={onSubmit}>
            {t("Create")}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default CreateCollectionEntity;
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SideBarWrapper from 'src/components/sidebar-wrapper';
import ChatDocumentList from './chat-document-list';
import { dispatch } from 'use-bus';
import { ActionIcon, Group, Stack, Tooltip, em } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { CanUsePersonalCollection } from 'src/utils/permissions';
import { BotBasicInfoItem } from 'src/stores/bots';
import { useMediaQuery } from '@mantine/hooks';
import ChatUserPreferencesButton from './chat-userpreferences-button';

const ChatSideMenu: FC<{
  botInfo: BotBasicInfoItem;
  onCloseSideMenu?: () => void;
}> = ({ botInfo, onCloseSideMenu }) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = React.useState(true);
  const allowPersonalCollection = CanUsePersonalCollection();
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  return (
    <SideBarWrapper
      title={botInfo?.title ?? t('Documents')}
      showFiltersIcon
      showFiltersValue={showFilters}
      onChangeShowFiltersValue={(value: boolean) => setShowFilters(value)}
      onCloseMenu={onCloseSideMenu}
      moreIcons={
        <Group gap="xs">
          {allowPersonalCollection && botInfo?.enablePersonalCollection ?
            <Tooltip withinPortal label={t("Click to attach file")}>
              <ActionIcon onClick={() => dispatch('@@ui/CHAT_DOCUMENT_UPLOAD')} variant='subtle' color="gray">
                <IconPlus />
              </ActionIcon>
            </Tooltip>
            :
            <></>}
          {botInfo.userPreferences && isMobile ?
            <ChatUserPreferencesButton botInfo={botInfo} />
            :
            <></>
          }
        </Group>
      }>
      <Stack gap="xs">
        <ChatDocumentList showFilters={showFilters} />
      </Stack>
    </SideBarWrapper>
  );
};

export default ChatSideMenu;

import { none, State, useHookstate } from "@hookstate/core";
import { ActionIcon, Badge, Card, Center, Group, Highlight, Input, Menu, Stack, Text, TextInput, Tooltip, useMantineTheme } from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "src/components/collapsible-card";
import AppContext from "src/services/app-context";
import { SkillContentIntent } from "src/stores/skills";
import IntentInput from "src/components/intent-input";
import { ISearchEntitiesStore } from "src/stores/entities";
import { getEntitiesInText } from "src/core/utils/object";
import { IconPlus, IconTrash, IconMessageCircle, IconDotsVertical, IconCopy } from "@tabler/icons-react";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import classes from 'src/pages/index.module.css';
import { LanguageSegmentedControl } from "src/components/language-segmented-control";

const SkillContentIntentEditLabel: FC<{
  state: State<string>;
  edit?: boolean;
  label: string;
}> = ({ state, edit, label }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Group align="center">
      <Input.Label w={120} required={edit}>{label}:</Input.Label>
      <TextInput
        required
        placeholder={t("<your intent>") as string}
        style={{ flex: 1 }}
        value={scopedState.value}
        onChange={(event) => { scopedState.set(event.target.value); setIsDirty(true); }}
      />
    </Group>
  );
}

const SkillContentIntentEditValues: FC<{
  state: State<SkillContentIntent>;
  edit?: boolean;
  label: string;
  lang: string;
  id?: string;
  searchEntitiesStore?: ISearchEntitiesStore;
}> = ({ state, edit, label, lang, id, searchEntitiesStore }) => {
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const { t } = useTranslation();

  const onAddIntent = () => {
    scopedState.values[lang].merge(['']);
    setIsDirty(true);
  }

  return (
    <Stack gap={5}>
      <Group align="center">
        <Input.Label w={120} required={edit}>{label}:</Input.Label>
        <Group align="center" gap={5}>
          <Text size="sm">{t("Intents")}</Text>
          <Tooltip withinPortal label={t("Add intent")}>
            <ActionIcon onClick={onAddIntent} variant='subtle' color="gray">
              <IconPlus size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
      {scopedState.values[lang]?.value && scopedState.values[lang].map((intent, index) =>
        <Group align="center" key={index}>
          <Input.Label w={120}></Input.Label>
          <IntentInput
            key={`intent-input-${id}-${lang}-${index}`}
            required
            creatable
            width='100%'
            value={intent.value}
            onChange={(value) => { intent.set(value); setIsDirty(true); }}
            id={id}
            searchEntitiesStore={searchEntitiesStore}
          />
          <ActionIcon onClick={() => { intent.set(none); setIsDirty(true); }} variant='subtle' color="gray">
            <IconTrash size={16} />
          </ActionIcon>
        </Group>
      )}
    </Stack>
  );
}

const SkillContentIntentsCard: FC<{
  state: State<SkillContentIntent[]>;
  cardkey: string;
  edit?: boolean;
  id?: string;
  searchEntitiesStore?: ISearchEntitiesStore;
}> = ({ state, cardkey, edit = false, id, searchEntitiesStore }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const defaultAllowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const [allowedLanguages, setAllowedLanguages] = useState<string[]>(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].values) : defaultAllowedLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].values)[0] : defaultAllowedLanguages[0]);

  useEffect(() => {
    setAllowedLanguages(scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].values) : defaultAllowedLanguages);
  }, [scopedState])

  const onAddIntent = () => {
    let defaultSamples: { [key: string]: string[] } = {};
    defaultSamples[allowedLanguages[0]] = [];

    const newIntent = {
      intent: '',
      values: defaultSamples
    } as SkillContentIntent;
    scopedState.merge([newIntent]);
    setIsDirty(true);
  }

  const onClone = (item: SkillContentIntent) => {
    const copy = JSON.parse(JSON.stringify(item));
    scopedState[scopedState.length].set(copy);
    setIsDirty(true);
  }

  const onDeleleLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.length; index++) {
      scopedState[index].values[language].set(none);
    }
    setIsDirty(true);
    if (scopedState?.value?.length > 0) {
      setSelectedLanguage(Object.keys(scopedState?.value?.[0].values)[0]);
    }
  }

  const onAddLanguage = (language: string) => {
    for (let index = 0; index < scopedState.value.length; index++) {
      scopedState[index].values[language].set([]);
    }
    setIsDirty(true);
  }

  return (
    <CollapsibleCard
      title={
        <Group justify='space-between'>
          <Text fw={500}>{t('Intents')}</Text>
          {edit &&
            <ActionIcon onClick={onAddIntent} variant='subtle' color="gray">
              <IconPlus />
            </ActionIcon>
          }
        </Group>
      }
      cardKey={cardkey}
      icon={<IconMessageCircle />}
      rightToolbar={
        <LanguageSegmentedControl
          editable={edit}
          data={scopedState?.value?.length > 0 ? Object.keys(scopedState?.value?.[0].values) : undefined}
          language={selectedLanguage}
          onChange={setSelectedLanguage}
          onDelete={onDeleleLanguage}
          onAdd={onAddLanguage}
        />
      }
      collapseInfoRender={
        <Badge variant='light' color='gray'>{scopedState?.value?.length} {t("intents")}</Badge>
      }>
      <Stack>
        {scopedState?.value?.length > 0 && scopedState.map((intent, index) =>
          <Card
            withBorder
            shadow="xs"
            radius="sm"
            key={index}
            className={classes.stepCard}>
            <Card.Section withBorder inheritPadding py={5}>
              {edit ?
                <Group justify="space-between">
                  <Text fw={500}>{`Intent # ${index + 1}`}</Text>
                  <Menu withinPortal position="bottom-end" shadow="sm">
                    <Menu.Target>
                      <Tooltip label={t("More options")}>
                        <ActionIcon variant='subtle' color="gray">
                          <IconDotsVertical size={16} />
                        </ActionIcon>
                      </Tooltip>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item leftSection={<IconCopy size={16} />} onClick={() => onClone(intent.value as SkillContentIntent)}>{t("Clone")}</Menu.Item>
                      <Menu.Item leftSection={<IconTrash size={16} />} color="red" onClick={() => { intent.set(none); setIsDirty(true); }}>{t("Delete")}</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Group>
                :
                <Text fw={500}>{`# ${intent.intent.value}`}</Text>
              }
            </Card.Section>

            <Card.Section inheritPadding mt="xs" pb="xs">
              <Stack gap={5}>
                {edit ?
                  <>
                    <SkillContentIntentEditLabel
                      edit={edit}
                      label={t("Label")}
                      state={intent.intent}
                    />

                    <SkillContentIntentEditValues
                      key={`intent-edit-values-${id}-${selectedLanguage}`}
                      state={intent}
                      label={t("Values")}
                      edit={edit}
                      lang={selectedLanguage}
                      id={id}
                      searchEntitiesStore={searchEntitiesStore}
                    />
                  </>
                  :
                  <Group align="center" gap="xs">
                    {intent.values[selectedLanguage]?.value?.map((i, index) =>
                      <Badge size="xs" radius="sm" p="xs" key={index} className={classes.tag}>
                        <Highlight highlight={getEntitiesInText(i)} color={theme.colors[theme.primaryColor][6]} >{i}</Highlight>
                      </Badge>
                    )}
                  </Group>
                }
              </Stack>
            </Card.Section>
          </Card>
        )}
        {scopedState?.value?.length === 0 &&
          <Center>
            <Text c="dimmed" size="sm">{t('No intents')}</Text>
          </Center>
        }
      </Stack>
    </CollapsibleCard>
  );
};

export default SkillContentIntentsCard;
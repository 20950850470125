import { none, State, useHookstate } from "@hookstate/core";
import { ActionIcon, Alert, Button, Center, Group, LoadingOverlay, Modal, rem, ScrollArea, Stack, Table, Text, Tooltip } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { AsEnumerable } from "linq-es5";
import { CSSProperties, FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SkillSelector } from "src/components/skill-selector";
import { Query } from "src/core/stores/data-store";
import { formatMessage } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { BotContent, BotContentSkill } from "src/stores/bots";
import { SkillSummary, SkillSummaryStore } from "src/stores/skills";
import AppContext from "src/services/app-context";
import { IconWand, IconPlus, IconAlertTriangle, IconEraser, IconArrowUp, IconArrowDown, IconTrash, IconListSearch, IconAlertCircle, IconMessages, IconBinaryTree2, IconFileSearch, IconGripVertical, IconMathFunction } from "@tabler/icons-react";
import { DragDropContext, Draggable, DraggableLocation, Droppable } from "@hello-pangea/dnd";

const BotSkillAssignedList: FC<{
  state: State<BotContent>;
  scrollAreaHeight?: number;
  edit?: boolean;
  onSelectedItem: (skillId: string | undefined) => void;
}> = ({ state, scrollAreaHeight = undefined, edit = false, onSelectedItem }) => {
  const { t } = useTranslation();
  const modals = useModals();
  const [query, setQuery] = useState<Query>(undefined as any);
  const skillSummaryStore = useMemo(() => container.get(SkillSummaryStore), []);
  const skillSummaryState = skillSummaryStore.state;
  const isBusy = skillSummaryState.isBusy.value;
  const errorMessage = skillSummaryState.errorMessage.value;
  const [showAddSkillModal, setShowAddSkillModal] = useState(false);
  const [newSkillSelected, setNewSkillSelected] = useState<string | undefined>(undefined);
  const [skillSelected, setSkillSelected] = useState<string | undefined>(undefined);
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);
  const [hasSkillsDeleted, setHasSkillsDeleted] = useState(false);

  useEffect(() => {
    if (scopedState?.skills?.value?.length > 0) {
      const skillIdList = `${scopedState.skills.value.map(skill => `'${skill.skillId}'`).join(',')}`;
      const newQuery = {
        ...query,
        orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 100,
        parameters: {
          $filter: `id in (${skillIdList})`
        },
      } as Query;
      setQuery(newQuery);
    }
  }, [scopedState.skills])

  useEffect(() => {
    if (query) {
      loadSkills(query);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [JSON.stringify(query)]);

  const loadSkills = async (query: Query) => {
    skillSummaryStore.load(query);
  };

  useEffect(() => {
    if (scopedState.skills.value.length > 0 && skillSummaryState.items.value.length !== scopedState.skills.value.length) {
      setHasSkillsDeleted(AsEnumerable(scopedState.skills.value).Any(x => !AsEnumerable(skillSummaryState.items.value).Select(x => x.id).Contains(x.skillId)));
    }
    else {
      setHasSkillsDeleted(false);
    }
  }, [skillSummaryState.items])

  useEffect(() => {
    onSelectedItem(skillSelected);
  }, [skillSelected])

  const addNewSkill = () => {
    if (newSkillSelected) {
      if (!AsEnumerable(scopedState.skills.value).Any(x => x.skillId === newSkillSelected)) {
        let skillList = JSON.parse(JSON.stringify(scopedState.skills.value));
        const newSkillList = [...skillList, { audiences: [], skillId: newSkillSelected, confidence: 0.85 } as BotContentSkill];
        scopedState.skills.set(newSkillList);
        setIsDirty(true);
      }
      onCloseModal();
    }
  }

  const onCloseModal = () => {
    setNewSkillSelected(undefined);
    setShowAddSkillModal(false);
  }

  const getSelectedStyle = (item: SkillSummary): CSSProperties => {
    if (item?.id === skillSelected) {
      return { backgroundColor: 'var(--mantine-primary-color-light)', cursor: 'pointer' };
    }
    else {
      return { cursor: 'pointer' };
    }
  }

  const openDeleteModal = (skillId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Delete assigned skill')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete assigned skill'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(skillId),
    });

  const onConfirmDelete = async (skillId: string) => {
    if (skillId === skillSelected) {
      setSkillSelected(undefined);
    }
    scopedState.skills[scopedState.skills.findIndex(x => x.skillId.value === skillId)].set(none);
    if (scopedState.userPreferences?.searchSkills?.value) {
      scopedState.userPreferences.searchSkills[scopedState.userPreferences.searchSkills.findIndex(x => x.value === skillId)].set(none);
    }
    setIsDirty(true);
  };

  const onDragUp = (index: number) => {
    if (index > 0) {
      const to = (index - 1);
      const from = index;
      scopedState.skills.merge(p => ({ [from]: p[to], [to]: p[from] }));
      setIsDirty(true);
    }
  }

  const onDragDown = (index: number) => {
    if ((index + 1) < scopedState.skills.value.length) {
      const to = (index + 1);
      const from = index;
      scopedState.skills.merge(p => ({ [from]: p[to], [to]: p[from] }));
      setIsDirty(true);
    }
  }

  const onDragEnd = (source: DraggableLocation, destination: DraggableLocation | null) => {
    const to = destination?.index || 0;
    const from = source.index;
    scopedState.skills.merge(p => ({ [from]: p[to], [to]: p[from] }));
    setIsDirty(true);
  }

  const onClearDeletedSkills = () => {
    if (scopedState.skills.value.length > 0 && skillSummaryState.items.value.length !== scopedState.skills.value.length) {
      let newSkills = [] as BotContentSkill[];
      const db = AsEnumerable(skillSummaryState.items.value).Select(x => x.id);
      let skillList = JSON.parse(JSON.stringify(scopedState.skills.value)) as BotContentSkill[];
      skillList.forEach(skill => {
        if (db.Contains(skill.skillId)) {
          newSkills.push(skill);
        }
      });
      scopedState.skills.set(newSkills);
      setHasSkillsDeleted(false);
      setIsDirty(true);
    }
  }

  const getSkillIconType = (item: SkillSummary) => {
    let icon = <></>;
    switch (item.type) {
      case 'Function':
        icon = <IconMathFunction size={16} />;
        break;
      case 'Dialog':
        icon = <IconMessages size={16} />;
        break;
      case 'Search':
        icon = <IconFileSearch size={16} />;
        break;
      default:
        icon = <IconBinaryTree2 size={16} />;
        break;
    }

    return (
      <Tooltip label={t(item.type.valueOf())}>
        <ActionIcon variant='transparent' color="gray">
          {icon}
        </ActionIcon>
      </Tooltip>
    );
  }

  return (
    <Stack>
      <Group gap="xs">
        <IconWand />
        <Text fw={500}>{t("Assigned skills")}</Text>
        {edit &&
          <Tooltip withinPortal label={t("Add skill")}>
            <ActionIcon onClick={() => setShowAddSkillModal(true)} variant='subtle' color="gray">
              <IconPlus />
            </ActionIcon>
          </Tooltip>
        }
      </Group>
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => skillSummaryStore.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}
      {hasSkillsDeleted && !isBusy &&
        <Alert icon={<IconAlertTriangle size={16} />} title={t("Skill(s) deleted")} color="yellow">
          <Stack>
            {!edit ?
              <Text>{t("This bot has skills that have been removed.")}</Text>
              :
              <>
                <Text>{t("This bot has skills that have been removed. Click on the button below to remove them.")}</Text>
                <Button variant="outline" color="yellow" mb="xs"
                  leftSection={<IconEraser />}
                  onClick={onClearDeletedSkills}>
                  {t("Clear deleted skills")}
                </Button>
              </>
            }
          </Stack>
        </Alert>
      }
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        <ScrollArea offsetScrollbars style={{ height: scrollAreaHeight }}>
          <DragDropContext onDragEnd={({ destination, source }) => onDragEnd(source, destination)}>
            <Table striped highlightOnHover>
              <Droppable droppableId="dnd-list" direction="vertical">
                {(provided) => (
                  <Table.Tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {(skillSummaryState?.items.value?.length > 0 && scopedState?.skills?.value?.length > 0) && scopedState.skills.value.map((skill, idx) => {
                      const skillSummary = skillSummaryState.items.value.find(s => s.id === skill.skillId) as SkillSummary;
                      return (
                        <>
                          {skillSummary &&
                            <Draggable key={skill.skillId} index={idx} draggableId={skill.skillId}>
                              {(provided) => (
                                <Table.Tr key={`skill-item-${skill.skillId}`} style={getSelectedStyle(skillSummary)} ref={provided.innerRef} {...provided.draggableProps}>
                                  {edit &&
                                    <Table.Td style={{ width: 25 }}>
                                      <div {...provided.dragHandleProps}>
                                        <IconGripVertical style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                                      </div>
                                    </Table.Td>
                                  }

                                  <Table.Td onClick={() => setSkillSelected(skill.skillId)}>
                                    <Group align="center" gap="xs" style={{ wordBreak: 'break-all' }}>
                                      {getSkillIconType(skillSummary)}
                                      {skillSummary.title}
                                    </Group>
                                  </Table.Td>

                                  {edit &&
                                    <>
                                      {/* <Table.Td>
                                        {edit && idx > 0 &&
                                          <ActionIcon onClick={() => onDragUp(idx)} variant='subtle' color="gray">
                                            <IconArrowUp size={20} />
                                          </ActionIcon>
                                        }
                                      </Table.Td>
                                      <Table.Td>
                                        {edit && (idx + 1) < scopedState.skills.value.length &&
                                          <ActionIcon onClick={() => onDragDown(idx)} variant='subtle' color="gray">
                                            <IconArrowDown size={20} />
                                          </ActionIcon>
                                        }
                                      </Table.Td> */}
                                      <Table.Td style={{ width: 25 }}>
                                        <ActionIcon
                                          size="sm"
                                          variant='subtle'
                                          color="red"
                                          onClick={() => openDeleteModal(skill.skillId)}>
                                          <IconTrash />
                                        </ActionIcon>
                                      </Table.Td>
                                    </>
                                  }
                                </Table.Tr>
                              )}
                            </Draggable>
                          }
                          {!skillSummary &&
                            <Table.Tr key={`skill-item-${skill.skillId}`}>
                              <Table.Td colSpan={2}><Group gap={5} align="center"><IconAlertTriangle size={16} color="orange" /><Text c="dimmed">{t("Skill deleted")}</Text></Group></Table.Td>
                            </Table.Tr>
                          }
                        </>
                      )
                    })}
                    {provided.placeholder}
                  </Table.Tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>

          {(skillSummaryState?.items.value?.length === 0 || scopedState?.skills?.value?.length === 0) && (
            <Center my="md">
              <Group gap="xs" align="center">
                <IconListSearch />
                <Text>{t('No items found')}</Text>
              </Group>
            </Center>
          )}
        </ScrollArea>
      </div>
      {
        showAddSkillModal &&
        <Modal
          opened
          onClose={onCloseModal}
          title={<Text>{t("Add skill")}</Text>}
          closeOnClickOutside={false}>
          <Stack>
            <SkillSelector
              width="100%"
              onChange={skillId => setNewSkillSelected(skillId)}
            />
            <Button fullWidth onClick={addNewSkill}>
              {t("Add skill")}
            </Button>
          </Stack>
        </Modal>
      }
    </Stack >
  );
};

export default BotSkillAssignedList;
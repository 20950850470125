import HttpService from 'src/core/services/http-service';
// TODO: Solve this patch
import { container } from 'src/inversify.config';
import Store, { BaseState } from './store';
import { CommandResult } from './types';
import { createPatch } from 'rfc6902';

export interface FormState<T> extends BaseState {
  item: T;
}

class FormStore<TItem, TNewItem> extends Store<FormState<TItem>> {
  protected httpService!: HttpService;

  protected _baseUrl: string;
  protected _initialState?: TItem;

  public get baseUrl() {
    return this._baseUrl;
  }
  public set baseUrl(url: string) {
    this._baseUrl = url;
  }

  constructor(baseUrl: string, initialState?: TItem) {
    super({
      errorMessage: undefined,
      isBusy: false,
      item: initialState
    });
    this.httpService = container.get(HttpService);
    this._baseUrl = baseUrl;
  }

  public async load(name: string) {
    return await this.rawLoad(encodeURIComponent(name));
  }

  public async rawLoad(name: string) {
    return await this.handleCallAsync(async () => {
      const response = await this.httpService.get<TItem>(`${this.baseUrl}/${name}`);
      this._state.set((s) => {
        s.item = response.data;
        s.status = response.status;
        return s;
      });
      return response.data;
    });
  }

  public async create(item: TNewItem) {
    return await this.handleCallAsync(async () => {
      const result = await this.httpService.post<TNewItem, CommandResult<TItem>>(`${this.baseUrl}`, item);
      return result.data;
    });
  }

  public async save(id: string, item: TItem) {
    return await this.handleCallAsync(async () => {
      const result = await this.httpService.put(`${this.baseUrl}/${encodeURIComponent(id)}`, item);
      return result.data;
    });
  }

  public async patch(selector: (o: TItem) => boolean, path: string, partial: Partial<TItem>) {
    //TODO: actualizar para utilizar localStorage
    return await this.handleCallAsync(async () => {
      const item = this._state.get();
      const result = await this.httpService.patch(`${this.baseUrl}/${encodeURIComponent(path)}`, createPatch(item, partial));
      return result.data;
    });
  }

  public async delete(id: string, params?: any) {
    return await this.handleCallAsync(async () => {
      return await this.httpService.delete<any, CommandResult<TItem>>(`${this.baseUrl}/${encodeURIComponent(id)}`, params);
    });
  }

  public clear() {
    this._state.set((s) => {
      s.errorMessage = undefined;
      s.isBusy = false;
      s.status = undefined;
      if (this._initialState) {
        s.item = this._initialState;
      }
      return s;
    });
    return true;
  }

  public clearError() {
    this._state.set((s) => {
      s.errorMessage = undefined;
      s.isBusy = false;
      s.status = undefined;
      return s;
    });
    return true;
  }
}

export default FormStore;

import { ComboboxItem } from "@mantine/core";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Query } from "src/core/stores/data-store";
import { SkillSummaryStore } from "src/stores/skills";
import { container } from "src/inversify.config";
import { BasicMultiselect } from "./basic-multiselect";

const SkillAssignedSelector: FC<{
  assignedSkills: string[];
  value?: string[];
  onChange?: (value: string[]) => void;
  edit?: boolean;
}> = ({ assignedSkills, value, onChange, edit = false }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<Query>(undefined as any);
  const skillSummaryStore = useMemo(() => container.get(SkillSummaryStore), []);
  const skillSummaryState = skillSummaryStore.state;
  const [searchSkillsData, setSearchSkillsData] = useState<ComboboxItem[]>([]);

  useEffect(() => {
    if (assignedSkills?.length > 0) {
      const skillIdList = `${assignedSkills.map(skill => `'${skill}'`).join(',')}`;
      const newQuery = {
        ...query,
        orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 1000,
        parameters: {
          $filter: `id in (${skillIdList}) and type eq 'Search'`
        },
      } as Query;
      setQuery(newQuery);
    }
  }, [assignedSkills])

  useEffect(() => {
    if (query) {
      loadSkills(query);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [JSON.stringify(query)]);

  const loadSkills = async (query: Query) => {
    skillSummaryStore.load(query);
  };

  useEffect(() => {
    if (skillSummaryState.items.value) {
      let data = [] as ComboboxItem[];
      if (skillSummaryState.items.value.length > 0) {
        data = skillSummaryState.items.value.map(item => ({ label: item.title, value: item.id }));
      }
      setSearchSkillsData(data);
    }
  }, [skillSummaryState.items])

  const onChangeSearchSkills = (value: string[]) => {
    onChange?.(value);
  }

  return (
    <BasicMultiselect
      data={searchSkillsData}
      value={value}
      onChange={(value) => onChangeSearchSkills(value)}
      placeholder={t('Nothing here') as string}
      readOnly={!edit}
    />
  );
};

export default SkillAssignedSelector;
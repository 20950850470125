import React, { useState, useRef } from 'react';
import { Query, ListState, QueryParameters } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Group, TextInput, Text, Stack, Tooltip, Alert, Badge, Button, Popover } from '@mantine/core';
import { IconAlertCircle as AlertCircle, IconFilterPlus, IconTrash, IconInfoCircle as InfoCircle, IconPlus as Plus, IconRefresh as Refresh, IconSearch as Search, IconX as X } from '@tabler/icons-react';
import { useModals } from '@mantine/modals';
import { extractTagsFromText, formatMessage, isNullOrWhitespace } from 'src/core/utils/object';
import { DataTable, DataTableColumn, type DataTableSortStatus } from 'mantine-datatable';
import { AsEnumerable } from 'linq-es5';
import classes from './table-list-v2.module.css';

export interface TableListV2Model<T> {
  data: ListState<T>;
  query: Query;
  columns: DataTableColumn<T>[];
}

export interface TableListV2Props<T> {
  idAccessor?: string;
  model: TableListV2Model<T>;
  onQueryChanged?: (query: Query) => void;
  onRefresh?: () => void;
  onItemClick?: (item: T) => void;
  pageSize?: number;
  pageSizeOptions?: number[];
  striped?: boolean;
  highlightOnHover?: boolean;
  withBorder?: boolean;
  withColumnBorders?: boolean;
  minHeight?: number;
  hideToolbar?: boolean;
  hideSearch?: boolean;
  hidePagination?: boolean;
  showNewIcon?: boolean;
  onNewItemClick?: () => void;
  selectable?: boolean;
  onDeleteSelectedClick?: (selectedItems: T[]) => void;
  leftToolBarRender?: React.ReactNode;
  rightToolBarRender?: React.ReactNode;
  onSelectedChanged?: (selectedItems: T[]) => void;
  noShowLoading?: boolean;
  sortable?: boolean;
  sortStatus?: DataTableSortStatus;
  onSortStatusChanged?: (sortStatus: DataTableSortStatus) => void;
  searchByTag?: boolean;
}

const TableListV2: React.FC<TableListV2Props<any>> = <T,>({
  idAccessor,
  pageSize: pageSizeProps = 10,
  pageSizeOptions = [10, 30, 50, 100],
  striped,
  highlightOnHover,
  withBorder,
  withColumnBorders,
  minHeight,
  hideToolbar,
  hidePagination,
  hideSearch = false,
  showNewIcon,
  onNewItemClick,
  selectable,
  onDeleteSelectedClick,
  leftToolBarRender,
  rightToolBarRender,
  onSelectedChanged,
  noShowLoading,
  sortable,
  sortStatus,
  onSortStatusChanged,
  searchByTag = false,
  ...props
}: TableListV2Props<T>) => {
  const { model } = props;
  const { t } = useTranslation();
  const modals = useModals();
  const timerRef = useRef<NodeJS.Timeout>();
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [sortInfo, setSortInfo] = useState<DataTableSortStatus | undefined>((sortable && sortStatus) ? sortStatus : undefined);
  const [searchBoxValue, setSearchBoxValue] = useState<string>('');
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const [showFilterByTagModal, setShowFilterByTagModal] = useState(false);
  const [tagNameFilter, setTagNameFilter] = useState<string>('');
  const [tagValueFilter, setTagValueFilter] = useState<string>('');

  if (model == null) return null;

  const query = model.query || {};
  const pageSize = query && query.take ? query.take : pageSizeProps ? pageSizeProps : 10;

  let activePage = 1;
  const skip = query?.skip;
  const take = query?.take;
  const count = model?.data?.count;

  if (skip && take && count) {
    activePage = skip / take + 1;
  }

  const updateQuery = (searchQuery: string, tags: string[]) => {
    let newParameters = query.parameters ?? {} as QueryParameters;
    newParameters.tagsFilter = tags;
    const nextQ = { ...query, skip: 0, searchQuery: searchQuery, parameters: newParameters };
    if (props.onQueryChanged) props.onQueryChanged(nextQ as Query);
  }

  const extractTags = (text: string) => {
    const tags: string[] = query.parameters?.tagsFilter as string[] || [];
    const [newText, newTags] = extractTagsFromText(text, tags);

    setSearchBoxValue(newText as string);
    setTagsFilter(newTags as string[]);

    return [newText, newTags];
  }


  const onSearchKeyUp = (event: any) => {
    const value = event.target.value;
    setSearchBoxValue(value || '');
    if (event.key === 'Enter' || event.key === ' ') {
      if (timerRef?.current != null) clearTimeout(timerRef.current);
      const [query, tags] = extractTags(value);
      updateQuery(query as string, tags as string[]);
    }
  }

  const deleteTag = (tag: string) => {
    let newTags: string[] = [];
    if (tagsFilter && tagsFilter.length > 0 && tag) {
      newTags = AsEnumerable(tagsFilter).Where(t => t !== tag).ToArray();
    }
    updateQuery(searchBoxValue, newTags as string[]);
    setTagsFilter(newTags);
  }

  const onKeyUpApplyfilterByTag = (event: any) => {
    if (event.key === 'Enter') {
      onApplyFilterByTag();
    }
  }

  const onApplyFilterByTag = () => {
    if (!isNullOrWhitespace(tagNameFilter)) {
      const [query, tags] = extractTags(`${tagNameFilter}:${tagValueFilter}`);
      updateQuery(query as string, tags as string[]);
    }

    setTagNameFilter('');
    setTagValueFilter('');
    setShowFilterByTagModal(false);
  }

  const onChangePage = (page: number) => {
    const nextQ = { ...query, skip: (page - 1) * pageSize, take: pageSize };
    if (props.onQueryChanged) props.onQueryChanged?.(nextQ as Query);
  };

  const openDeleteModal = () => {
    if (selectedItems.length > 0 && onDeleteSelectedClick) {
      modals.openConfirmModal({
        title: <Text>{t('Delete selected items')}</Text>,
        children: (
          <Text size="sm">{t('Are you sure you want to delete the selected items?')}</Text>
        ),
        labels: { confirm: t('Delete'), cancel: t('Cancel') },
        confirmProps: { color: 'red' },
        onConfirm: () => {
          onDeleteSelectedClick(selectedItems);
          setSelectedItems([]);
        },
      });
    }
  }

  const onChangePageSizeOption = (option: number) => {
    if (option) {
      const nextQ = { ...query, skip: 0, take: option };
      if (props.onQueryChanged) props.onQueryChanged?.(nextQ as Query);
    }
  }

  const onSortChanged = (sortStatus: DataTableSortStatus) => {
    let newParameters = query.parameters ?? {} as QueryParameters;
    newParameters.orderByField = sortStatus.columnAccessor;
    newParameters.orderByDirection = sortStatus.direction;

    const nextQ = {
      ...query,
      skip: 0,
      parameters: newParameters
    } as Query;
    if (props.onQueryChanged) props.onQueryChanged?.(nextQ as Query);
    setSortInfo(sortStatus);
    if (onSortStatusChanged) onSortStatusChanged?.(sortStatus);
  }

  const onRefresh = () => {
    updateQuery(searchBoxValue, tagsFilter);
  }

  return (
    <Stack gap="xs">
      <div>
        {model?.data?.errorMessage &&
          <Alert p="md" mb="xs" icon={<AlertCircle size={16} />} title={t("Error")} color="red">
            <Text>{formatMessage(model?.data?.errorMessage)}</Text>
          </Alert>
        }

        {!hideToolbar && (
          <Group gap="xs" align="center" justify='space-between'>
            <Group gap="xs" align="center" justify="flex-start">
              {leftToolBarRender}
              {showNewIcon &&
                <Tooltip label={t("Create new item")}>
                  <ActionIcon onClick={onNewItemClick} variant='subtle' color="gray">
                    <Plus />
                  </ActionIcon>
                </Tooltip>
              }
              {onDeleteSelectedClick && selectable && selectedItems.length > 0 &&
                <Tooltip label={t("Delete selected items")}>
                  <ActionIcon onClick={openDeleteModal} color="red" variant='subtle'>
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              }
            </Group>

            <Stack gap="xs">
              <Group gap="xs" align="center" justify="flex-end">
                {rightToolBarRender}
                {!hideSearch &&
                  <TextInput
                    value={searchBoxValue}
                    onChange={(event) => setSearchBoxValue(event.target.value)}
                    className={classes.searchBox}
                    leftSection={<Search size={20} />}
                    onKeyUp={onSearchKeyUp}
                    placeholder={t('Search...') as string}
                    rightSection={
                      searchByTag ?
                        // <Tooltip
                        //   multiline
                        //   w={300}
                        //   label={<Text size="sm"><Text size="sm" fw={500}>{t("Search by tags")}:</Text>{t("You can search by tags by typing a tag like 'tag:value' and pressing the space bar to include it in your search.")}</Text>}>
                        //   <ActionIcon size="xs" variant='subtle' color="gray">
                        //     <InfoCircle />
                        //   </ActionIcon>
                        // </Tooltip>
                        <Popover width={300} trapFocus position="bottom" withArrow shadow="md" opened={showFilterByTagModal}>
                          <Popover.Target>
                            <Tooltip label={t("Search by tags")}>
                              <ActionIcon size="xs" variant='subtle' color="gray" onClick={() => setShowFilterByTagModal(true)}>
                                <IconFilterPlus />
                              </ActionIcon>
                            </Tooltip>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Stack gap={5}>
                              <Text fw={500}>{t("Search by tags")}</Text>
                              <TextInput label={t("Name")} placeholder="context" size="xs" value={tagNameFilter} onChange={(e) => setTagNameFilter(e.target.value)} onKeyUp={onKeyUpApplyfilterByTag} />
                              <TextInput label={t("Value")} placeholder="chat" size="xs" value={tagValueFilter} onChange={(e) => setTagValueFilter(e.target.value)} onKeyUp={onKeyUpApplyfilterByTag} />

                              <Group align='center' justify='flex-end' mt="md">
                                <Button size='xs' variant='default' onClick={() => setShowFilterByTagModal(false)}>{t("Cancel")}</Button>
                                <Button size='xs' onClick={onApplyFilterByTag}>{t("Apply")}</Button>
                              </Group>
                            </Stack>
                          </Popover.Dropdown>
                        </Popover>
                        :
                        null
                    }
                  />
                }
                <Tooltip label={t("Reload data")}>
                  <ActionIcon onClick={onRefresh} variant='subtle' color="gray">
                    <Refresh />
                  </ActionIcon>
                </Tooltip>
              </Group>
              {searchByTag && tagsFilter && tagsFilter.length > 0 &&
                <Group gap="xs">
                  <Text size="sm">{t("Search by tags")}:</Text>
                  {tagsFilter.map((tag) => (
                    <Badge key={tag} size="xs" radius="sm" p="xs" className={classes.tag}
                      rightSection={
                        <ActionIcon size="xs" variant="transparent" onClick={() => deleteTag(tag)} color="gray">
                          <X />
                        </ActionIcon>
                      }>{tag}</Badge>
                  ))}
                </Group>
              }
            </Stack>
          </Group>
        )
        }
      </div>

      <DataTable
        idAccessor={idAccessor}
        withTableBorder={withBorder}
        withColumnBorders={withColumnBorders}
        minHeight={minHeight ?? 200}
        striped={striped}
        highlightOnHover={highlightOnHover}
        columns={model.columns}
        records={model?.data?.items}
        fetching={!noShowLoading ? model?.data?.isBusy : false}
        totalRecords={model?.data?.count}
        recordsPerPage={pageSize}
        page={activePage}
        onPageChange={(p) => onChangePage(p)}
        paginationText={({ from, to, totalRecords }) => `${from} - ${to} | ${t("Total")}: ${totalRecords}`}
        recordsPerPageOptions={pageSizeOptions}
        recordsPerPageLabel={t("Records per page") as string}
        onRecordsPerPageChange={onChangePageSizeOption}
        selectedRecords={selectable ? selectedItems : undefined}
        onSelectedRecordsChange={(selectable ? setSelectedItems : undefined) as any}
        sortStatus={(sortable ? sortInfo : undefined) as any}
        onSortStatusChange={(sortable ? onSortChanged : undefined) as any}
        onRowClick={(item) => { props.onItemClick?.(item.record) }}
        noRecordsText={t("No records") as string}
      />
    </Stack >
  );
};

export default TableListV2;

import React, { useMemo, useState, useEffect, useContext, CSSProperties } from 'react';
import { Link, useParams } from 'react-router-dom';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Anchor, Group, Stack, Table, Text, Title, Tooltip } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import HeaderContext from 'src/services/header-context';
import useBus from 'use-bus';
import { CollectionSummary, CollectionSummaryStore } from 'src/stores/collections';
import { IconBook2, IconLockOpen, IconLock } from '@tabler/icons-react';
import classes from 'src/pages/index.module.css';
import SimpleListV2, { SimpleListV2Model } from 'src/core/ui/simple-list/simple-list-v2';

const CollectionList: React.FC<{ showFilters: boolean }> = ({ showFilters }) => {
  const { t } = useTranslation();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const summaryStore = useMemo(() => container.get(CollectionSummaryStore), []);
  const summaryState = summaryStore.state;
  const { height } = useViewportSize();
  const [query, setQuery] = useState<Query>(undefined as any);
  const { collectionId } = useParams();

  useEffect(() => {
    if (collectionId) {
      setHeader(`${collectionId}`, 'Collections', <IconBook2 size={34} />, `${collectionId} | ${t("Collections")}`, true, true);
    }
    else {
      setHeader(t('Collections'), 'Collections', <IconBook2 size={34} />, t('Collections') as string, true, true);
    }
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/collections`, breadcrumbName: t('Collections') },
    ]);

    const newQuery = {
      ...query,
      orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
      skip: 0,
      take: 20,
    } as Query;
    setQuery(newQuery);
  }, []);

  useEffect(() => {
    if (query) {
      load(query);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [JSON.stringify(query)]);

  const load = async (query: Query) => {
    summaryStore.load(query);
  };

  const onReload = () => {
    load(query);
  };

  useBus(
    '@@ui/COLLECTION_LIST_REFRESH',
    () => onReload(),
    [query],
  );

  const model: SimpleListV2Model<CollectionSummary> = {
    data: summaryStore.toListState(summaryState.value),
    query,
    columns: [
      {
        accessor: 'title',
        title: t('Title'),

        render: (item: CollectionSummary) => (
          <Anchor component={Link} to={`/admin/collections/${item.id}`} underline="never">
            <Stack gap="xs" style={{ minHeight: 60 }}>
              <Group gap="xs" justify='space-between' wrap='nowrap' align='flex-start'>
                <Title order={5} className={classes.titleList}>{item.title}</Title>

                <Group gap={0}>
                  <Tooltip label={item.accessMode === 'Everyone' ? t("Public access") as string : t("Members only") as string}>
                    <ActionIcon variant='transparent' color="gray">
                      {item.accessMode === 'Everyone' ? <IconLockOpen size={16} /> : <IconLock size={16} />}
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </Group>

              <Text c="dimmed" size="xs">
                {!item.description && t('(No description)')}
                {item.description}
              </Text>
            </Stack>
          </Anchor>
        )
      },
    ]
  };

  const getScrollAreaHeight = () => {
    return showFilters ? height - 140 : height - 90;
  }

  return (
    <SimpleListV2
      idAccessor={'id'}
      rowStyle={({ id }) => (id === collectionId ? { backgroundColor: 'var(--mantine-primary-color-light)' } : undefined)}
      model={model}
      onQueryChanged={setQuery}
      onRefresh={onReload}
      height={getScrollAreaHeight()}
      showFilters={showFilters}
    />
  );
};

export default CollectionList;

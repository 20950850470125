import { IconAsterisk, IconDeviceFloppy, IconHome2, IconMessagePlus } from "@tabler/icons-react";
import { Title, Text, Image, ActionIcon, Tooltip, Group, Burger, Drawer, em } from '@mantine/core';
import { FC, useContext, useEffect, useMemo, useState } from "react";
import ContentWrapper from "src/components/content-wrapper";
import HeaderContext from "src/services/header-context";
import { truncateText } from "src/core/utils/object";
import { ChatBotStore } from "src/stores/skills";
import { useTranslation } from "react-i18next";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useParams } from "react-router-dom";
import { container } from "src/inversify.config";
import { BotBasicInfoItem, BotBasicInfoItemStore } from "src/stores/bots";
import ChatComponent from "src/components/chat";
import { dispatch } from "use-bus";
import ChatSideMenu from "./chat-side-menu";
import ChatUserPreferencesButton from "./chat-userpreferences-button";

const ChatPage: FC<{}> = () => {
  const { t } = useTranslation();
  const { header, setHeader } = useContext(HeaderContext);
  const params = useParams();
  const botId = params.botId;
  const chatBotStore = useMemo(() => container.get(ChatBotStore), []);
  const botInfoStore = useMemo(() => container.get(BotBasicInfoItemStore), []);
  const botInfoState = botInfoStore.state;
  const [title, setTitle] = useState(t("New chat"));
  const [conversationId, setConversationId] = useState("");
  const [withChanges, setWithChanges] = useState<boolean>(false);
  const [opened, { toggle }] = useDisclosure();

  useEffect(() => {
    setHeader(t('Home'), 'Home', <IconHome2 size={34} />, t('Home') as string, false, false);
  }, []);

  useEffect(() => {
    if (botId && botId !== "") {
      loadBotInfo(botId);
    }
  }, [botId]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadBotInfo = async (botId: string) => {
    if (botId) {
      await botInfoStore.getInfo(botId);
    }
  };

  useEffect(() => {
    if (botInfoState?.item?.value) {
      setHeader(t('Home'), 'Home', <IconHome2 size={34} />, t('Home') as string, false, false, botInfoState.item.title.value);
    }
  }, [botInfoState.item])

  const onChangeHeaderChat = (title: string, conversationId: string) => {
    setTitle(title);
    setConversationId(conversationId);
  }

  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  return (
    <ContentWrapper
      withoutPadding
      leftIcons={botInfoState?.item?.enableDocumentManagement?.value ?
        <Group gap={5} justify="flex-start" align="center">
          <Burger
            opened={opened}
            onClick={toggle}
            size={isMobile ? 'sm' : 'md'}
          />
        </Group>
        : undefined}
      rightIcons={
        <>
          {botInfoState?.item?.value && !botInfoState?.item?.enableDocumentManagement?.value &&
            <ChatUserPreferencesButton botInfo={botInfoState?.item?.value as BotBasicInfoItem} color='var(--mantine-primary-color-6)' hiddenFrom='md' />
          }

          {botInfoState?.item?.enableSaveConversation?.value &&
            <Tooltip label={t("Save conversation")}>
              <ActionIcon
                size="lg"
                variant='subtle'
                color='var(--mantine-primary-color-6)'
                onClick={() => dispatch('@@ui/CHAT_SAVE_CHAT')}>
                {<IconDeviceFloppy />}
              </ActionIcon>
            </Tooltip>
          }
          <Tooltip label={t("Starts a new conversation deleting current history")}>
            <ActionIcon
              hiddenFrom="md"
              size="lg"
              variant='subtle'
              color='var(--mantine-primary-color-6)'
              onClick={() => dispatch('@@ui/CHAT_RESTART_CHAT')}>
              {<IconMessagePlus />}
            </ActionIcon>
          </Tooltip>
        </>
      }
      title={
        <Group gap="xs" align="center" justify="flex-start">
          <Title order={isMobile ? 4 : 3} title={title ?? ""}>{truncateText(title ?? "", isMobile ? 30 : 127)}</Title>
          {withChanges && botInfoState?.item?.enableSaveConversation?.value &&
            <Tooltip label={t("Unsaved changes")}>
              <ActionIcon color="gray" variant="transparent" size="xs">
                <IconAsterisk />
              </ActionIcon>
            </Tooltip>
          }
        </Group>
      }
      subTitle={
        <Text visibleFrom="lg" c="dimmed" size="xs" title={botInfoState?.item?.description?.[header.language]?.value ?? ""}>
          {botInfoState?.item?.value ? `[${botInfoState.item.title.value}] ` : ''} {truncateText(botInfoState?.item?.description?.[header.language]?.value ?? "", 256)}
        </Text>
      }
      logo={
        botInfoState?.item?.logo?.value ?
          <Image
            h={isMobile ? 25 : 50}
            w='auto'
            fit="contain"
            src={botInfoState.item.logo.value
            }
          /> : undefined
      }>
      <ChatComponent
        store={chatBotStore}
        id={botId}
        key={`chatpage-${botId}`}
        hideDebugPanel
        hideHeader
        onChangeHeaderChat={onChangeHeaderChat}
        onChatWithChanges={setWithChanges}
        allowSaveConversation={botInfoState?.item?.enableSaveConversation?.value}
        uploadFiles={botInfoState?.item?.enablePersonalCollection?.value}
      />
      {botInfoState?.item?.enableDocumentManagement?.value &&
        <Drawer opened={opened} onClose={toggle} styles={{ body: { padding: 0 } }} withCloseButton={false}>
          <ChatSideMenu botInfo={botInfoState?.item?.value as BotBasicInfoItem} onCloseSideMenu={toggle} />
        </Drawer>
      }
    </ContentWrapper>
  );
}

export default ChatPage;
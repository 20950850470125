import { State, useHookstate } from "@hookstate/core";
import { Group, Input, ActionIcon, Textarea, Text, Card, Grid, Menu, Stack, Badge, Box, Button, Alert, LoadingOverlay, Switch, Tooltip, useMantineTheme, useMantineColorScheme } from "@mantine/core";
import MDEditor from "@uiw/react-md-editor";
import { FC, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentSnapshotFragmentItem, DocumentSnapshotPassageItemStore } from "src/stores/documents";
import { container } from "src/inversify.config";
import { formatMessage } from "src/core/utils/object";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import AppContext from "src/services/app-context";
import DocumentSnapshotPassageIntents from "./snapshot-passage-intents";
import { IconAlertCircle, IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import classes from 'src/pages/index.module.css';

const MarkdownEditor: FC<{ content: State<string>, }> = ({ content }) => {
  const scopedState = useHookstate(content);
  const { setIsDirty } = useContext(AppContext);
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <div data-color-mode={colorScheme} style={{ height: 300 }}>
      <MDEditor
        preview='edit'
        value={scopedState.value}
        onChange={(value) => { scopedState.set(value as string); setIsDirty(true); }}
      />
    </div>
  );
}

const DocumentSnapshotPassage: FC<{
  collectionId: string;
  documentId: string;
  snapshotId: string;
  canEdit?: boolean;
  state: State<DocumentSnapshotFragmentItem>;
  onReload: () => void;
}> = ({ collectionId, documentId, snapshotId, canEdit, state, onReload }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const modals = useModals();
  const scopedState = useHookstate(state);
  const [edit, setEdit] = useState(false);
  const itemStore = useMemo(() => container.get(DocumentSnapshotPassageItemStore), []);
  const itemState = itemStore.state;
  const isBusy = itemState.isBusy.value;
  const errorMessage = itemState.errorMessage.value;
  const { setIsDirty } = useContext(AppContext);
  const [showIntents, setShowIntents] = useState(false);

  const setEditMode = () => {
    if (canEdit && !edit) {
      setEdit(true);
    }
  }

  const onCancel = () => {
    setEdit(false);
    onReload();
    setIsDirty(false);
  }

  const onSave = async () => {
    if (collectionId && documentId && snapshotId && canEdit && edit) {
      setIsDirty(false);
      itemStore.setSnapshot(collectionId, documentId, snapshotId);
      await itemStore.save(state.id.value, state.value as DocumentSnapshotFragmentItem);
      setEdit(false);
      onReload();
      setIsDirty(false);
    }
  }

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(itemState.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Delete passage')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete passage'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(),
    });

  const onConfirmDelete = async () => {
    if (collectionId && documentId && snapshotId && canEdit) {
      itemStore.setSnapshot(collectionId, documentId, snapshotId);
      await itemStore.delete(state.id.value);
      itemState.errorMessage.value ? showKoNotification() : showOkNotification();
      if (!itemState.errorMessage.value) {
        setEdit(false);
        onReload();
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={isBusy} />
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => itemStore.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}
      <Card
        withBorder
        shadow="xs"
        radius="sm"
        style={{
          cursor: canEdit ? 'pointer' : 'default',
          '&:hover': {
            border: `1px solid ${theme.colors[theme.primaryColor][3]}`,
          }
        }}>
        <Card.Section withBorder inheritPadding py={5}>
          <Grid justify="center" align="center">
            <Grid.Col span="auto">
              {(!canEdit || !edit) &&
                <Text onClick={setEditMode} fs="italic">{scopedState.title.value}</Text>
              }
            </Grid.Col>
            <Grid.Col span="content">
              <Group justify="flex-end">
                {edit &&
                  <Group align="center" gap={10}>
                    <Button
                      size="compact-xs"
                      variant='outline'
                      onClick={onCancel}>
                      {t("Cancel")}
                    </Button>
                    <Button
                      size="compact-xs"
                      loading={isBusy}
                      onClick={onSave}>
                      {t("Save")}
                    </Button>
                  </Group>
                }
                {canEdit &&
                  <Menu withinPortal position="bottom-end" shadow="sm">
                    <Menu.Target>
                      <Tooltip label={t("More options")}>
                        <ActionIcon variant='subtle' color="gray">
                          <IconDotsVertical size={20} />
                        </ActionIcon>
                      </Tooltip>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item disabled={edit} leftSection={<IconEdit size={16} />} onClick={setEditMode}>{t("Edit")}</Menu.Item>
                      <Menu.Item leftSection={<IconTrash size={16} />} color="red" onClick={openDeleteModal}>{t("Delete")}</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                }
              </Group>
            </Grid.Col>
          </Grid>
        </Card.Section>

        <Card.Section inheritPadding mt="xs" pb="xs">
          {edit ?
            <Stack gap={5}>
              <Group align="flex-start" wrap="nowrap">
                <Input.Label w={120} required={edit}> {t("Title")}</Input.Label>
                <Textarea
                  autosize
                  minRows={3}
                  maxRows={10}
                  required
                  style={{ flex: 1 }}
                  value={scopedState.title.value}
                  onChange={(event) => { scopedState.title.set(event.target.value); setIsDirty(true); }}
                />
              </Group>
              <Group align="flex-start" wrap="nowrap">
                <Input.Label w={120} required={edit}> {t("Text")}</Input.Label>
                <Box style={{ flex: 1 }}>
                  <MarkdownEditor content={scopedState.content} />
                </Box>
              </Group>
            </Stack>
            :
            <Stack gap={5}>
              <div data-color-mode={colorScheme} onClick={setEditMode}>
                <MDEditor.Markdown source={scopedState.content.value} />
              </div>
            </Stack>
          }
        </Card.Section>
        {scopedState.properties.value &&
          <Card.Section withBorder inheritPadding py={5}>
            <Group gap="xs" align="center">
              {Object.keys(scopedState.properties.value).map((t) => (
                <Badge size="xs" radius="sm" p="xs" key={t} className={classes.tag}>{t}: {scopedState.properties.value[t]}</Badge>
              ))}
            </Group>
          </Card.Section>
        }
        <Card.Section withBorder inheritPadding py={5}>
          <Stack>
            <Switch
              disabled={scopedState.intents.value.length === 0}
              label={t("Show intents")}
              checked={showIntents}
              onChange={e => setShowIntents(e.target.checked)}
            />
            {showIntents && <DocumentSnapshotPassageIntents state={scopedState.intents} />}
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};

export default DocumentSnapshotPassage;
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { Stack, Tabs, Grid, Card, Container, Group, Menu, ActionIcon, Text, Badge, Alert, SimpleGrid, Tooltip, useMantineColorScheme, Box, Loader } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import CollapsibleCard from 'src/components/collapsible-card';
import TagsCard from 'src/components/tags-card';
import MembersCard from 'src/components/members-card';
import { formatBytes, formatMessage, truncateText } from 'src/core/utils/object';
import { tagsToArray } from 'src/utils/tags-utils';
import { useHookstate } from '@hookstate/core';
import ListItem from 'src/components/listitem-component';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { dispatch } from 'use-bus';
import MDEditor from '@uiw/react-md-editor';
import { CollectionItemStore } from 'src/stores/collections';
import { TagItem } from 'src/stores/skills';
import { UserCanContribute } from 'src/utils/permissions';
import { MemberEditorState, MemberItem } from 'src/stores/identities';
import CollectionCrawlersCard from './collection-crawlers';
import CollectionEntitiesCard from './collection-entites';
import TrainButton from 'src/components/train-button';
import CollectionDocuments from './collection-documents';
import { IconBook2, IconAlertCircle, IconHome2, IconTags, IconFileStack, IconDotsVertical, IconEdit, IconTrash, IconNotes, IconInfoSquare, IconFileInfo, IconActivityHeartbeat, IconFileAnalytics, IconChartPie2, IconFileAlert } from '@tabler/icons-react';
import ContentWrapper from 'src/components/content-wrapper';
import classes from 'src/pages/index.module.css';
import { authStatus } from 'src/core/services/authentication-service';
import { NotFoundInfo } from 'src/core/ui/not-found-component';

const CollectionDetail: React.FC = () => {
  let navigate = useNavigate();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const { collectionId } = useParams();
  const modals = useModals();
  const [activeTab, setActiveTab] = useState("home");
  const store = useMemo(() => container.get(CollectionItemStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const tagsScopedState = useHookstate([] as TagItem[]);
  const isOwner = authStatus?.user.value?.isOwner;
  const canContribute = isOwner || UserCanContribute(state?.item?.members?.value as MemberItem[]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(`${t("Collection detail")}`, 'Collections', <IconBook2 size={34} />, `${t("Collection detail")} | ${t("Collections")}`, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/collections`, breadcrumbName: t('Collections') },
    ]);
    if (collectionId) {
      load(collectionId);
    }
  }, [collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const membersState = useHookstate({
    accessMode: "MembersOnly",
    members: [] as MemberItem[]
  } as MemberEditorState);

  const load = async (collectionId: string) => {
    if (collectionId) {
      store.load(collectionId);
    }
  };

  useEffect(() => {
    if (state.item.value) {
      setHeader(`${state.item.title.value}`, 'Collections', <IconBook2 size={34} />, `${state.item.title.value} | ${t("Collections")}`, true, true);
      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      const copy = JSON.parse(JSON.stringify(state.item.value)) as MemberEditorState;
      membersState.set({
        accessMode: copy.accessMode,
        members: copy.members
      });
    }
  }, [state.item])

  const navigateToEdit = () => {
    navigate(`/admin/collections/${collectionId}/edit`);
  };

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: <Text>{t('Delete collection')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete collection'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(),
    });

  const onConfirmDelete = async () => {
    await store.delete(collectionId as string);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      navigate(`/admin/collections`);
      dispatch('@@ui/COLLECTION_LIST_REFRESH');
    }
  };

  const openTrainModal = (failedOnly: boolean) =>
    modals.openConfirmModal({
      title: <Text>{t('Train documents')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to train all documents in this collection?')}
          <br></br>
          {t('The training process might take a while.')}
        </Text>
      ),
      labels: { confirm: t('Train documents'), cancel: t('Cancel') },
      onConfirm: () => onConfirmTrain(failedOnly),
    });

  const onConfirmTrain = async (failedOnly: boolean) => {
    await store.train(collectionId as string, failedOnly);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      dispatch('@@ui/DOCUMENT_LIST_REFRESH');
    }
  };

  const notFound = !isBusy && state.status?.value && state.status.value === 404;

  return (
    <ContentWrapper>
      <div>
        {isBusy ?
          <Stack my="xl" justify='center' align='center'>
            <Loader size="xl" />
            <Text c="dimmed">{t("Please wait")}</Text>
          </Stack>
          :
          <>
            {notFound &&
              <NotFoundInfo
                title={t('No item found')}
                description={t('No item found or you do not have permissions') as string} />
            }
            {state && state.item && state.item.value && !notFound && (
              <>
                {errorMessage && (
                  <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
                    <Text>{formatMessage(errorMessage)}</Text>
                  </Alert>
                )}
                <Grid align="stretch">
                  <Grid.Col span="auto">
                    <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
                      <Tabs.List>
                        <Tabs.Tab value="home" leftSection={<IconHome2 size={20} />}>{t('Home')}</Tabs.Tab>
                        <Tabs.Tab value="entities" leftSection={<IconTags size={20} />}>{t('Entities')}</Tabs.Tab>
                        <Tabs.Tab value="crawlers" leftSection={<IconFileStack size={20} />}>{t('Crawlers')}</Tabs.Tab>
                      </Tabs.List>
                    </Tabs>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Group justify='flex-end'>
                      {canContribute &&
                        <TrainButton
                          status={'Pending'}
                          jobReference={state.item.jobReference.value as string}
                          onClick={() => openTrainModal(false)}
                          options={[{ label: t("Only documents with errors"), icon: <IconFileAlert size={20} />, onClick: () => openTrainModal(true) }]}
                        />
                      }
                      {canContribute &&
                        <Menu withinPortal withArrow width={250} shadow="md" position="bottom-end">
                          <Menu.Target>
                            <Tooltip label={t("More options")}>
                              <ActionIcon variant='subtle' color="gray">
                                <IconDotsVertical />
                              </ActionIcon>
                            </Tooltip>
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Label>{t("Collection options")}</Menu.Label>
                            <Menu.Item leftSection={<IconEdit size={20} />} onClick={navigateToEdit}>{t("Edit collection")}</Menu.Item>
                            <Menu.Divider />
                            <Menu.Label>{t("Danger zone")}</Menu.Label>
                            <Menu.Item color="red" leftSection={<IconTrash size={20} />} onClick={openDeleteModal}>{t('Delete collection')}</Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      }
                    </Group>
                  </Grid.Col>
                </Grid>
                <Container fluid px={0} py={0} mt="xs">
                  {activeTab === "home" && (
                    <Grid gutter={'xs'}>
                      <Grid.Col span={{ base: 12, lg: 8 }}>
                        <Stack gap="xs">
                          <Card withBorder>
                            {collectionId &&
                              <CollectionDocuments
                                key={`collection-documents-${collectionId}`}
                                canContribute={canContribute}
                                collectionId={collectionId as string}
                              />
                            }
                          </Card>
                        </Stack>
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, lg: 4 }}>
                        <Stack gap="xs">
                          <CollapsibleCard
                            title={<Text fw={500}>{t('Info')}</Text>}
                            cardKey='collection-info-card'
                            icon={<IconInfoSquare />}
                            collapseInfoRender={
                              <Badge variant='light' color="gray">{truncateText(state.item.title.value, 20)}</Badge>
                            }>
                            <Stack align='stretch'>
                              <ListItem title={t("Title")} icon={<IconFileInfo size={20} />} description={state.item.title.value} />
                            </Stack>
                          </CollapsibleCard>

                          <CollapsibleCard
                            title={<Text fw={500}>{t('Description')}</Text>}
                            cardKey='collection-description-card'
                            icon={<IconNotes />}>
                            <div data-color-mode={colorScheme}>
                              <MDEditor.Markdown source={state.item.description.value ?? t('(No description)')} />
                            </div>
                          </CollapsibleCard>

                          <CollapsibleCard
                            title={<Text fw={500}>{t('Usage')}</Text>}
                            cardKey='collection-usage-card'
                            icon={<IconActivityHeartbeat />}>
                            <Stack align='stretch'>
                              <SimpleGrid
                                cols={{ base: 1, md: 2 }}
                                spacing="sm">
                                <ListItem
                                  title={t("Total documents")}
                                  icon={<IconFileAnalytics size={20} />}
                                  description={state.item.documentCount.value.toString()}
                                />
                                <ListItem
                                  title={t("Total documents used space")}
                                  icon={<IconChartPie2 size={20} />}
                                  description={formatBytes(state.item.documentsUsedSpace.value)}
                                />
                              </SimpleGrid>
                            </Stack>
                          </CollapsibleCard>

                          <MembersCard
                            cardKey='collection-members-card'
                            mode="view"
                            state={membersState}
                          />

                          <TagsCard
                            cardKey='collection-tags-card'
                            mode="view"
                            state={tagsScopedState}
                          />
                        </Stack>
                      </Grid.Col>
                    </Grid>
                  )}
                  {activeTab === "entities" && (
                    <Box pt={5}>
                      <CollectionEntitiesCard
                        canContribute={canContribute}
                        collectionId={collectionId as string}
                        edit={canContribute}
                      />
                    </Box>
                  )}
                  {activeTab === "crawlers" && (
                    <Box pt={5}>
                      <CollectionCrawlersCard
                        canContribute={canContribute}
                        collectionId={collectionId as string}
                        edit={canContribute}
                      />
                    </Box>
                  )}
                </Container>
              </>
            )}
          </>}
      </div>
    </ContentWrapper>
  );
};

export default CollectionDetail;

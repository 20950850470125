import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Grid, Card, Group, Button, Text, Alert, LoadingOverlay, Container, Tabs, Input, TextInput, Textarea, ComboboxItem, Switch, Anchor, ScrollArea, Select, Tooltip, ActionIcon, useMantineTheme, useMantineColorScheme, Box } from '@mantine/core';
import HeaderContext from 'src/services/header-context';
import { container } from 'src/inversify.config';
import { formatMessage, getFileNameFromUri, truncateText } from 'src/core/utils/object';
import TagsCard from 'src/components/tags-card';
import BootstrapIntentsCard from 'src/components/bootstrap-intents-card';
import SuggestionsCard from 'src/components/suggestions-card';
import { State, useHookstate } from '@hookstate/core';
import { LanguageSeletor } from 'src/components/language-selector';
import { dispatch } from 'use-bus';
import { tagsToArray, tagsToDict } from 'src/utils/tags-utils';
import { DocumentItemStore, DocumentSnapshotItem, SearchDocumentEntitiesStore, UpsertDocumentSnapshotItem } from 'src/stores/documents';
import { TagItem } from 'src/stores/skills';
import PropertiesCard from 'src/components/properties-card';
import jsyaml from "js-yaml";
import MDEditor from '@uiw/react-md-editor';
import { FileWithPath } from '@mantine/dropzone';
import DocumentInputFileZone from './document-input-file-zone';
import FileViewer from 'react-file-viewer';
import SnapshotPassages from './snapshot-passages';
import AppContext from 'src/services/app-context';
import NumberInputWithControl from 'src/components/number-input-with-control';
import Editor from '@monaco-editor/react';
import { IconFileText, IconBook2, IconFileSymlink, IconAlertCircle, IconFileCode, IconArticle, IconCode, IconArrowBackUp, IconDeviceFloppy } from '@tabler/icons-react';
import ContentWrapper from 'src/components/content-wrapper';
import classes from 'src/pages/index.module.css';
import { BasicMultiselect } from 'src/components/basic-multiselect';

const monacoOptions = {
  fontSize: 14,
  fontLigatures: true,
  readOnly: false,
}

const MarkdownEditor: FC<{ content: State<string> }> = ({ content }) => {
  const scopedState = useHookstate(content);
  const { setIsDirty } = useContext(AppContext);
  const { colorScheme } = useMantineColorScheme();

  return (
    <div data-color-mode={colorScheme} style={{ height: 'calc(100svh - 170px)' }}>
      <MDEditor
        preview='edit'
        value={scopedState.value}
        onChange={(value) => { scopedState.set(value as string); setIsDirty(true); }}
      />
    </div>
  );
}

const DocumentEdit: React.FC = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { documentId, collectionId } = useParams();
  const { setHeader, setRoutes } = useContext(HeaderContext);
  const { colorScheme } = useMantineColorScheme();
  const [activeTab, setActiveTab] = useState("source");
  const store = useMemo(() => container.get(DocumentItemStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const tagsScopedState = useHookstate([] as TagItem[]);
  const propertiesScopedState = useHookstate([] as TagItem[]);
  const [importFile, setImportFile] = useState<FileWithPath>();
  const [downloadUri, setDownloadUri] = useState<string>();
  const [fileExtension, setFileExtension] = useState<string>();
  const [advancedEditor, setAdvancedEditor] = useState<boolean>(false);
  const [yamlContent, setYamlContent] = useState<string>('');
  const [yamlError, setYamlError] = useState<string | undefined>(undefined);
  const { setIsDirty } = useContext(AppContext);
  const searchEntitiesStore = useMemo(() => container.get(SearchDocumentEntitiesStore), []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader(`${t("Edit document")}`, 'Documents', <IconFileText size={34} />, `${t("Edit document")} | ${t("Documents")}`, true, true);
    setRoutes([
      { path: `/admin`, breadcrumbName: t('Home') },
      { path: `/admin/documents`, breadcrumbName: t('Documents') },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
    store.setCollection(collectionId as string);
    load(documentId as string).then((item) => {
      if (item) {
        let extension = item.currentSnapshot.contentSource === 'External' ? item.currentSnapshot.fileName.split('.').pop() : 'pdf'
        if (!['docx', 'csv', 'xlsx'].includes(extension) || !extension || extension === '') {
          extension = 'pdf';
        }
        setFileExtension(extension);
        store.getDownloadDataUri(documentId as string, extension as string).then(uri => setDownloadUri(uri));
      }
    })
    searchEntitiesStore.setCollection(collectionId as string);
  }, [documentId, collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.item.value) {
      setHeader(`${state.item.collectionTitle.value}/${state.item.title.value} (${t("Edit mode")})`, 'Documents', <IconFileText size={34} />, `${state.item.collectionTitle.value}/${state.item.title.value} | ${t("Documents")}`, true, true);
      setRoutes([
        { path: `/admin`, breadcrumbName: t('Home') },
        { path: `/admin/collections`, breadcrumbName: t('Collections') },
        {
          path: `/admin/collections/${collectionId}`, breadcrumbName:
            <Group gap={5} align="center">
              <IconBook2 size={18} />
              <Text size="xs">
                {truncateText(state.item.collectionTitle.value, 60)}
              </Text>
            </Group>
        },
        { path: `/admin/collections/${collectionId}/documents`, breadcrumbName: t('Documents') },
      ]);

      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      const properties = tagsToArray(state.item.currentSnapshot?.properties?.value ?? []);
      propertiesScopedState.set(properties);
    }
  }, [state.item])

  const load = async (documentId: string) => {
    if (documentId) {
      var response = await store.load(documentId);
      loadForm();
      return response;
    }
  };

  const loadForm = () => {
    if (state?.item?.value) {
      const tags = tagsToArray(state.item.tags.value);
      tagsScopedState.set(tags);
      const properties = tagsToArray(state.item.currentSnapshot?.properties?.value ?? []);
      propertiesScopedState.set(properties);
      if (!state.item.currentSnapshot?.value) {
        state.item.currentSnapshot.set({
          bootstrapIntents: [] as string[],
          suggestions: [] as string[],
          content: '', // template
          language: '',
          properties: {},
          contentSource: 'Internal',
          contentType: '',
        } as DocumentSnapshotItem);
      }
      else {
        const snapshotItem = getDocumentSnapshotItem();
        setYamlContent(jsyaml.dump(snapshotItem).trimRight());
      }
    }
  }

  const getDocumentSnapshotItem = () => {
    return {
      id: documentId,
      title: state.item.title.value,
      description: state.item.description.value,
      reference: state.item.reference.value,
      boost: state.item.boost.value,
      bootstrapIntents: state.item.currentSnapshot.bootstrapIntents.value as string[],
      suggestions: state.item.currentSnapshot.suggestions.value as string[],
      content: state.item.currentSnapshot.contentSource.value === 'Internal' ? state.item.currentSnapshot.content.value : (importFile ? importFile?.name : state.item.currentSnapshot.content.value),
      contentSource: state.item.currentSnapshot.contentSource.value,
      contentType: state.item.currentSnapshot.contentType.value,
      language: state.item.currentSnapshot.language.value,
      splitMethod: state.item.currentSnapshot.splitMethod.value,
      audiences: state.item.audiences.value,
      tags: tagsToDict(tagsScopedState.value as TagItem[]),
      properties: tagsToDict(propertiesScopedState.value as TagItem[]),
      dontAdvertise: state.item.dontAdvertise.value
    } as UpsertDocumentSnapshotItem;
  }

  const onSubmit = async () => {
    let validateError = false;
    let snapshotItem = getDocumentSnapshotItem();

    if (advancedEditor) {
      try {
        const documentChanges = JSON.parse(JSON.stringify(jsyaml.load(yamlContent), null, 2)) as UpsertDocumentSnapshotItem;

        if (documentChanges.id !== snapshotItem.id) {
          setYamlError('The document id cannot be changed.');
          validateError = true;
        }
        else {
          snapshotItem = documentChanges;
        }
      }
      catch (ex) {
        setYamlError(ex as any);
        validateError = true;
      }
    }

    if (!validateError) {
      setIsDirty(false);

      let response = state.item.currentSnapshot.contentSource.value === 'External' && importFile
        ? await store.saveSnapshotWithFile(documentId as string, snapshotItem, importFile as File)
        : await store.saveSnapshot(documentId as string, snapshotItem);
      if (response) {
        navigate(`/admin/collections/${collectionId}/documents/${documentId}`);
        dispatch('@@ui/DOCUMENT_LIST_REFRESH');
      }
    }
  }

  const onCancel = () => {
    if (documentId) {
      navigate(`/admin/collections/${collectionId}/documents/${documentId}`);
    }
    else {
      navigate(`/admin/collections/${collectionId}/documents`);
    }
  }

  const getAudiencesData = () => {
    let data = [] as ComboboxItem[];

    if (state?.item?.audiences?.length > 0) {
      data = state.item.audiences.value.map(item => ({ label: item, value: item }));
    }

    return data;
  }

  const DocumentTitleRender = state?.item?.value?.currentSnapshot?.contentSource === 'External' && downloadUri
    ?
    <Group align="center" gap={5}>
      <IconFileSymlink />
      <Anchor href={downloadUri} target="_blank">{t("Download file")}</Anchor>
      <Text c="dimmed">{getFileNameFromUri(state.item.currentSnapshot.fileName.value)}</Text>
    </Group>
    :
    <Group></Group>
    ;

  return (
    <ContentWrapper>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        <form>
          {errorMessage && (
            <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
              <Text>{formatMessage(errorMessage)}</Text>
            </Alert>
          )}
          {yamlError && (
            <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => setYamlError(undefined)}>
              <Text>{formatMessage(yamlError)}</Text>
            </Alert>
          )}

          {state?.item?.value && state?.item?.currentSnapshot?.value &&
            <>
              <Grid align="stretch">
                <Grid.Col span="auto">
                  <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab as string)} classNames={{ list: classes.tabList }}>
                    <Tabs.List>
                      <Tabs.Tab value="source" leftSection={<IconFileCode size={20} />}>{t('Source')}</Tabs.Tab>
                      <Tabs.Tab value="passages" leftSection={<IconArticle size={20} />}>{t('Passages')}</Tabs.Tab>
                    </Tabs.List>
                  </Tabs>
                </Grid.Col>
                <Grid.Col span="content">
                  <Group align="center" justify='flex-end' wrap='nowrap'>
                    {!advancedEditor &&
                      <Tooltip withinPortal label={`${t("Advanced editor")} YAML`}>
                        <ActionIcon onClick={() => { setActiveTab('source'); setAdvancedEditor(true); }} variant="outline" color={theme.colors[theme.primaryColor][6]} size="lg">
                          <IconCode />
                        </ActionIcon>
                      </Tooltip>
                    }
                    <Button
                      variant='outline'
                      onClick={onCancel}
                      leftSection={<IconArrowBackUp />}>
                      {t("Cancel")}
                    </Button>
                    <Button
                      loading={isBusy}
                      onClick={onSubmit}
                      leftSection={<IconDeviceFloppy />}>
                      {t("Save")}
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
              <Container fluid px={0} py={0} mt="xs">
                {activeTab === "source" && (
                  <>
                    {!advancedEditor ?
                      <Grid gutter={'xs'}>
                        <Grid.Col span={{ base: 12, lg: 8 }}>
                          <Card withBorder>
                            {state.item.value.currentSnapshot?.contentSource === 'Internal' &&
                              <Input.Wrapper required>
                                <MarkdownEditor key={`text-editor-${state.item.currentSnapshot.id.value}`} content={state.item.currentSnapshot.content} />
                              </Input.Wrapper>
                            }
                            {state.item.value.currentSnapshot?.contentSource === 'External' &&
                              <Stack>
                                {DocumentTitleRender}

                                <Alert icon={<IconAlertCircle size={16} />} title={t("Change document")} variant="outline">
                                  {t("If you want to change the document, please upload it again.")}
                                </Alert>
                                <DocumentInputFileZone onSave={setImportFile} />
                                {state.item.currentSnapshot.trainingStatus.value !== 'Trained' && <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Document is not trained yet")} color="yellow">
                                  <Text>{t("Some functionality might not work until you train the document.")}</Text>
                                </Alert>}
                                {downloadUri &&
                                  <ScrollArea style={{ height: 'calc(100svh - 220px)' }} className={classes.customPrismContainer} offsetScrollbars>
                                    <FileViewer
                                      key={downloadUri}
                                      fileType={fileExtension}
                                      errorComponent={<div>{t("Cannot preview the document at this moment")}</div>}
                                      filePath={downloadUri} />
                                  </ScrollArea>
                                }
                              </Stack>
                            }
                          </Card>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, lg: 4 }}>
                          <Stack gap="xs">
                            <Card withBorder>
                              <Stack gap="xs">
                                <TextInput
                                  label={t('Title')}
                                  value={state.item.title.value}
                                  onChange={(event) => { state.item.title.set(event.target.value); setIsDirty(true); }}
                                />

                                <Group>
                                  <NumberInputWithControl
                                    width='100%'
                                    required
                                    label={t("Boost")}
                                    precision={6}
                                    min={0}
                                    step={0.01}
                                    value={state.item.boost.value}
                                    onChange={(value) => { state.item.boost.set(typeof value == "number" ? value : 1); setIsDirty(true); }}
                                  />

                                  <Switch
                                    mt="md"
                                    label={t("Don't advertise")}
                                    checked={state.item.dontAdvertise.value}
                                    onChange={e => { state.item.dontAdvertise.set(e.target.checked); setIsDirty(true); }}
                                  />
                                </Group>

                                <Textarea
                                  label={t('Description')}
                                  rows={3}
                                  value={state.item.value.description}
                                  onChange={(event) => { state.item.description.set(event.currentTarget.value); setIsDirty(true); }}
                                />

                                <BasicMultiselect
                                  label={t("Audiences") as string}
                                  data={getAudiencesData()}
                                  value={state.item.audiences.value as string[]}
                                  onChange={(value) => { state.item.audiences.set(value); setIsDirty(true); }}
                                  placeholder={t("Select audiences") as string}
                                  creatable
                                />

                                <LanguageSeletor
                                  label={t('Language') as string}
                                  value={state.item.currentSnapshot?.language?.value}
                                  onChange={(value) => { state.item.currentSnapshot.language.set(value as string); setIsDirty(true); }}
                                />

                                <Select
                                  allowDeselect={false}
                                  label={t("Split method")}
                                  defaultValue='Auto'
                                  data={[
                                    { value: 'Auto', label: t("Auto") as string },
                                    { value: 'None', label: t("None") as string },
                                    { value: 'Paragraph', label: t("Paragraph") as string },
                                    { value: 'Block', label: t("Block") as string }
                                  ]}
                                  value={state.item.currentSnapshot?.splitMethod.value}
                                  onChange={(value) => { state.item.currentSnapshot?.splitMethod.set(value as any); setIsDirty(true); }}
                                />
                              </Stack>
                            </Card>

                            <BootstrapIntentsCard
                              cardKey='document-intents-card-edit'
                              mode="edit"
                              state={state.item.currentSnapshot.bootstrapIntents}
                              id={documentId as string}
                              searchEntitiesStore={searchEntitiesStore}
                            />

                            <SuggestionsCard
                              cardKey='document-suggestions-card-edit'
                              mode="edit"
                              state={state.item.currentSnapshot.suggestions}
                              id={documentId as string}
                              searchEntitiesStore={searchEntitiesStore}
                            />

                            <TagsCard
                              cardKey='document-tags-card-edit'
                              mode="edit"
                              state={tagsScopedState}
                            />

                            <PropertiesCard
                              cardKey='document-variables-card-edit'
                              mode="edit"
                              state={propertiesScopedState}
                            />
                          </Stack>
                        </Grid.Col>
                      </Grid>
                      :
                      <Card withBorder>
                        <Input.Wrapper
                          required
                          style={{ width: '100%', height: 'calc(100svh - 180px)' }}>
                          <Editor
                            width="100%"
                            height="100%"
                            options={monacoOptions}
                            language="yaml"
                            value={yamlContent}
                            onChange={(value) => { setYamlContent(value as string); setIsDirty(true); }}
                            theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
                          />
                        </Input.Wrapper>
                      </Card>
                    }
                  </>
                )}
                {activeTab === "passages" && (
                  <Box pt={5}>
                    <SnapshotPassages
                      documentTitle={DocumentTitleRender}
                      collectionId={collectionId as string}
                      documentId={documentId as string}
                      snapshotId={state.item.currentSnapshot?.id?.value as string}
                      edit />
                  </Box>
                )}
              </Container>
            </>
          }
        </form>
      </div>
    </ContentWrapper>
  );
};

export default DocumentEdit;

import { State, useHookstate } from "@hookstate/core";
import { ActionIcon, Code, Group, Input, Select, Stack, Text, TextInput, Textarea, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import { SkillContentStep, SkillContentStepTargetType } from "src/stores/skills";
import SkillDebugJinjaModal from "./skill-debug-jinja-modal";
import { IconWindowMaximize } from "@tabler/icons-react";
import SkillContentStepWrapper from "./skill-content-step-wrapper";

const SkillContentStepAssign: FC<{
  state: State<SkillContentStep>;
  edit?: boolean;
  index: number;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}> = ({ state, edit, index, leftToolbar, rightToolbar }) => {
  const scopedState = useHookstate(state.action);
  const { t } = useTranslation();
  const { setIsDirty } = useContext(AppContext);
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <SkillContentStepWrapper
      index={index}
      state={state}
      edit={edit}
      leftToolbar={
        <Group align="center" gap={5}>
          {leftToolbar}
        </Group>
      }
      rightToolbar={rightToolbar}>
      {edit ?
        <Stack gap="xs">
          <Group align="flex-start">
            <Input.Label w={120} required={edit}>{t("Value")}:</Input.Label>
            <Textarea
              autosize
              minRows={3}
              maxRows={10}
              required
              style={{ flex: 1 }}
              value={scopedState.body.value}
              onChange={(event) => { scopedState.body.set(event.target.value); setIsDirty(true); }}
              styles={{ section: { marginRight: "var(--mantine-spacing-md)", alignItems: 'flex-start' } }}
              rightSection={<Tooltip label={t("More options")}>
                <ActionIcon variant='subtle' color="gray" onClick={open}>
                  <IconWindowMaximize size={20} />
                </ActionIcon>
              </Tooltip>}
            />
          </Group>
          <Group>
            <Input.Label w={120} required={edit}>{t("Store as")}:</Input.Label>
            <Select
              w={100}
              allowDeselect={false}
              data={[
                { value: 'Output', label: t('Output') as SkillContentStepTargetType },
                { value: 'Entity', label: t('Entity') as SkillContentStepTargetType }
              ]}
              value={scopedState.target.value}
              onChange={(value) => { scopedState.target.set(value as SkillContentStepTargetType); setIsDirty(true); }}
            />
            {scopedState.target.value && scopedState.target.value === 'Output' &&
              <>
                <Text span>{t("as")}{' '}</Text>
                <Select
                  w={200}
                  allowDeselect={false}
                  defaultValue='String'
                  data={[
                    { value: 'String', label: t('Plain text (String)') as string },
                    { value: 'Json', label: t('JSON Object') as string },
                    { value: 'SearchResult', label: t('Search result array') as string }
                  ]}
                  value={scopedState.targetFormat.value}
                  onChange={(value) => { scopedState.targetFormat.set(value as any); setIsDirty(true); }}
                />
              </>
            }
            {scopedState.target.value === 'Entity' &&
              <Group>
                <Text size="sm">{t("with name")}</Text>
                <TextInput
                  required
                  style={{ flex: 1 }}
                  value={scopedState.entity.value}
                  onChange={(event) => { scopedState.entity.set(event.target.value); setIsDirty(true); }}
                />
              </Group>}
          </Group>
          <SkillDebugJinjaModal value={scopedState.body.value} open={opened} onClose={close} onSave={(value) => scopedState.body.set(value)} />
        </Stack>
        :
        <Stack gap="xs">
          <div>
            {scopedState.target.value === 'Output' &&
              <Text span fw={500}>{t("Set")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-var(--mantine-color-grape-light)-light)">{scopedState.target.value}</Code> {t("as")} <Text span fs="italic">{scopedState.targetFormat.value}</Text> </Text>
            }
            {scopedState.target.value === 'Entity' &&
              <Text span fw={500}>{t("Set")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-var(--mantine-color-grape-light)-light)">{scopedState.target.value}</Code> {t("with name")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-var(--mantine-color-grape-light)-light)">{scopedState.entity.value}</Code> </Text>
            }
            <Text span fw={500}>{t("to value")}:</Text>
            <Stack>
              <Code style={{ fontSize: 16 }} color="var(--mantine-primary-color-light)">{scopedState.body.value}</Code>
            </Stack>
          </div>
        </Stack>
      }
    </SkillContentStepWrapper>
  );
};

export default SkillContentStepAssign;

import { ColorSchemeScript, MantineColorScheme, MantineProvider, MantineThemeOverride, useMantineColorScheme } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { FC, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { container } from 'src/inversify.config';
import AppContext from '../services/app-context';
import HeaderContext, { HeaderConfig } from '../services/header-context';
import StartPage from './start';
import LoginPages from './login';
import AccountPages from './account';
import { AppConfiguration, useAuthentication } from '../core/services/authentication-service';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle, useHotkeys } from '@mantine/hooks';
import { IconHome2 } from '@tabler/icons-react';
import { getLS, saveLS } from '../core/utils/local-storage';
import { globalPallettes } from 'src';
import ViewerPage from './viewer';
import ChatPage from './bots';
import moment from 'moment';
import AdminIndex from './admin'
import NotFoundPage from './not-found'
import { DatesProvider } from '@mantine/dates';

const AuthenticatedIndex: FC = () => {
  const [authStatus] = useAuthentication();
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  const toggleColorScheme = (value?: MantineColorScheme) => setColorScheme(value ?? (colorScheme === 'dark' ? 'light' : 'dark'));
  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  return <>
    {authStatus.status.value !== 'Success' && <Navigate to={`/login?redirect_uri=${encodeURIComponent(window.location.href)}`} />}
    <Routes>
      <Route path="/" element={<StartPage />} />
      <Route path="/start/*" element={<StartPage />} />
      <Route path="/bots/:botId/*" element={<ChatPage />} />
      <Route path="/admin/*" element={<AdminIndex />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </>
}

const Index: FC = () => {
  const configuredTheme = container.get<AppConfiguration>("AppConfiguration").theme;
  const brandName = container.get<AppConfiguration>("AppConfiguration").brandName;
  const [isDirty, setIsDirty] = useState(false);
  const { t, i18n } = useTranslation();

  const [headerState, setHeaderState] = useState<HeaderConfig>({
    documentTitle: t('Home'),
    brandName: `${brandName}`,
    title: t('Home'),
    navItem: 'Home',
    language: getLS('language') ?? 'en',
    icon: <IconHome2 size={34} />,
    hasSidebar: false,
    showSidebar: false
  });
  const [routesState, setRoutesState] = useState([{ path: '', breadcrumbName: '' } as { path: string; breadcrumbName: string | React.ReactNode }]);
  useDocumentTitle(`${headerState.documentTitle} - ${headerState.brandName}`);

  const setHeader = (title: string, navItem: string, icon?: JSX.Element, documentTitle?: string, hasSidebar?: boolean, showSidebar?: boolean, brandTitle?: string) => {
    setHeaderState((h: any) => {
      const nextH = {
        ...h,
        title: title,
        navItem: navItem,
        icon: icon ?? <IconHome2 size={34} />,
        documentTitle: documentTitle,
        brandName: brandTitle ?? headerState.brandName ?? brandName,
        hasSidebar: hasSidebar,
        showSidebar: showSidebar
      };
      return nextH;
    });
  };

  useEffect(() => {
    const lang = getLS('language') ?? 'en';
    i18n.changeLanguage(lang);
    moment.locale(lang);
    setHeaderState((h) => {
      const nextH = { ...h, language: lang, };
      return nextH;
    });
  }, []);

  const setLanguage = (lang: string) => {
    setHeaderState((h) => {
      const nextH = { ...h, language: lang, };
      return nextH;
    });
    saveLS('language', lang);
    i18n.changeLanguage(lang);
    moment.locale(lang);
  };

  const setRoutes = (routes: { path: string; breadcrumbName: string | React.ReactNode }[]) => {
    setRoutesState(routes);
  };

  const setSideMenuOpened = (opened: boolean) => {
    setHeaderState((h) => { const nextH = { ...h, showSidebar: opened }; return nextH; });
  }

  let appTheme: MantineThemeOverride = {
    autoContrast: true,
    luminanceThreshold: 0.5,
    components: {
      Input: {
        defaultProps: {
          inputWrapperOrder: ['label', 'input', 'description', 'error']
        }
      },
      TextInput: {
        defaultProps: {
          inputWrapperOrder: ['label', 'input', 'description', 'error']
        }
      },
      Textarea: {
        defaultProps: {
          inputWrapperOrder: ['label', 'input', 'description', 'error']
        }
      },
      NumberInput: {
        defaultProps: {
          inputWrapperOrder: ['label', 'input', 'description', 'error']
        }
      },
      Select: {
        defaultProps: {
          inputWrapperOrder: ['label', 'input', 'description', 'error']
        }
      },
      MultiSelect: {
        defaultProps: {
          inputWrapperOrder: ['label', 'input', 'description', 'error']
        }
      },
      PasswordInput: {
        defaultProps: {
          inputWrapperOrder: ['label', 'input', 'description', 'error']
        }
      }
    }
  };

  if (configuredTheme) {
    appTheme.colors = { brand: globalPallettes[configuredTheme] };
    appTheme.primaryColor = 'brand';
  }
  return (
    <>
      <ColorSchemeScript defaultColorScheme="auto" />
      <MantineProvider theme={appTheme}>
        <Notifications />
        <ModalsProvider>
          <AppContext.Provider value={{ isDirty: isDirty, setIsDirty: setIsDirty }}>
            <HeaderContext.Provider value={{ header: headerState, routes: routesState, setHeader, setRoutes, setSideMenuOpened, setLanguage }}>
              <DatesProvider settings={{ locale: headerState.language }}>
                <Routes>
                  <Route path="/login/*" element={<LoginPages />} />
                  <Route path="/account/*" element={<AccountPages />} />
                  <Route path="/viewer/*" element={<ViewerPage />} />
                  <Route path="*" element={<AuthenticatedIndex />} />
                </Routes>
              </DatesProvider>
            </HeaderContext.Provider>
          </AppContext.Provider>
        </ModalsProvider>
      </MantineProvider>
    </>
  )
}

export default Index;

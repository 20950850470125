import { Button, Center, Group, Paper, Text, Checkbox, Input, Stack, Radio, useMantineTheme, Anchor, Alert, ScrollArea, Loader } from '@mantine/core';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';
import { IconAlertCircle } from '@tabler/icons-react';
import { useQuery } from 'src/core/utils/hooks';
import classes from 'src/pages/start.module.css';
import cx from 'clsx';
import { BotSummaryStore } from 'src/stores/bots';
import { getTranslation } from 'src/core/utils/object';
import HeaderContext from 'src/services/header-context';
import { AppConfiguration } from 'src/core/services/authentication-service';
import { AsEnumerable } from 'linq-es5';

const StartBots: FC<{ quickStartAvailable?: boolean, onQuickStartClick?: () => void }> = ({ quickStartAvailable = false, onQuickStartClick }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const config = container.get<AppConfiguration>("AppConfiguration");
  const brandName = config.brandName;
  const summaryStore = useMemo(() => container.get(BotSummaryStore), []);
  const summaryState = summaryStore.state;
  const isBusy = summaryState.isBusy.value;
  const navigate = useNavigate();
  const [startPath, setStartPath] = useLocalStorage<string>({ key: 'start-path', defaultValue: '' });
  const { header } = useContext(HeaderContext);
  const [pathSelected, setPathSelected] = useState<string>(startPath);
  const [rememberSelection, setRememberSelection] = useState<boolean>(false);
  const [disableAutoRedirect] = useState<string | undefined>(useQuery().get('disable_auto_redirect') ?? undefined);
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    summaryStore.load({
      orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
      skip: 0,
      take: 20,
      filter: { enable: true }
    });
  }, [])

  useEffect(() => {
    if (startPath)
      setPathSelected(startPath)
  }, [startPath])

  useEffect(() => {
    if (summaryState?.items?.length > 0) {
      const botMatch = summaryState.items.value.filter(o => o.id === `/bots/${summaryState.items[0].id.value}`).length === 0;
      if (botMatch && (summaryState?.items?.length === 1 && (!disableAutoRedirect || disableAutoRedirect?.toLocaleLowerCase() !== "true"))) {
        navigate(`/bots/${summaryState.items[0].id.value}`);
      }
      else if (!startPath && botMatch) {
        setPathSelected(`/bots/${summaryState.items[0].id.value}`);
      }

      if (startPath && !AsEnumerable(summaryState.items.value).Select(b => b.id).Any(id => `/bots/${id}` === startPath)) {
        setStartPath('');
        setPathSelected('');
      }
    }
  }, [summaryState.items])

  const paths = useMemo(() => {
    return summaryState.items.map(o => ({
      path: `/bots/${o.id.value}`,
      title: o.title.value,
      description: getTranslation(o.description.value, header.language)
    }));
  }, [isBusy])

  const onContinue = () => {
    if (rememberSelection) {
      setStartPath(pathSelected)
    }
    navigate(pathSelected)
  }

  return (
    <Paper withBorder shadow="md" p={30} radius="md" className='center' mih={350} pos="relative">
      <Stack gap="xs">
        <h1 className={classes.subtitle}>
          {t("Welcome to")} {' '}<br />
          <Text className={classes.title} component="span" variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][6], to: 'cyan' }} span inherit>
            {brandName}
          </Text>{' '}
        </h1>

        {isBusy ?
          <Stack my="xl" justify='center' align='center'>
            <Loader size="xl" />
            <Text c="dimmed">{t("Please wait")}</Text>
          </Stack>
          :
          <>
            {summaryState?.items?.length > 0 &&
              <Input.Wrapper
                label={t("Where do you want to go?")}>
                <Stack gap={0} mt="md">
                  <ScrollArea.Autosize mah="50svh">
                    {paths.map(item => (
                      <Radio
                        key={item.path}
                        checked={item.path === pathSelected}
                        className={cx(classes.radio, { [classes.radioSelected]: item.path === pathSelected })}
                        label={<span><b>{item.title}</b><br />{item.description}</span>}
                        onChange={() => setPathSelected(item.path)}
                      />))}
                    {paths.length === 0 &&
                      <Alert icon={<IconAlertCircle size="1rem" />} color="yellow">
                        {t("You do not have access to any bot or no bot has been created yet. Go to the administration center.")}
                      </Alert>
                    }
                  </ScrollArea.Autosize>
                </Stack>
              </Input.Wrapper>
            }

            {summaryState?.items?.length > 1 &&
              <Checkbox
                mt="sm"
                defaultChecked={false}
                checked={rememberSelection}
                onChange={e => setRememberSelection(e.target.checked)}
                label={t("Remember my selection")}
              />
            }

            {(!quickStartAvailable || summaryState?.items?.length > 0) &&
              <Group mt="lg" grow align="flex-end">
                <Button onClick={onContinue} disabled={!pathSelected || summaryState?.items?.length === 0}>{t("Continue")}</Button>
              </Group>
            }

            {quickStartAvailable && summaryState?.items?.length === 0 &&
              <>
                <Alert variant="light" title={t("Quick start")}>
                  {t("Oh, it looks like you don't have any bot created yet. Try our welcome wizard to create your first virtual assistant!")}
                </Alert>
                <Group mt="lg" grow align="flex-end">
                  <Button onClick={onQuickStartClick}>{t("Start wizard")}</Button>
                </Group>
              </>
            }
          </>
        }
        <Center>
          <Anchor href={`${baseUri}/admin`} c='dimmed' size="xs">
            {t("Go to the administration center")}
          </Anchor>
          <Text c='dimmed' size="xs">
            &nbsp;|&nbsp;v{config.version}
          </Text>
        </Center>
      </Stack>
    </Paper>
  );
}

export default StartBots;

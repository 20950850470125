import { createContext } from "react";
import { getLS } from "src/core/utils/local-storage";
import { t } from "i18next";
import { IconHome2 } from "@tabler/icons-react";

export interface HeaderConfig {
  documentTitle: string;
  brandName: string;
  title: string;
  navItem: string;
  language: string;
  icon: JSX.Element;
  hasSidebar: boolean;
  showSidebar: boolean;
}

export interface RoutesConfig {
  routes: { path: string; breadcrumbName: string }[];
}

const HeaderContext = createContext({
  header: {
    documentTitle: t('Home'),
    brandName: 'Ainhoa platform',
    title: t('(Not set)'),
    navItem: 'Home',
    language: getLS('language') ?? 'en',
    icon: <IconHome2 size={34} />,
    hasSidebar: false,
    showSidebar: false,
  },
  routes: [{ path: '', breadcrumbName: '' } as { path: string; breadcrumbName: string | React.ReactNode }],
  setHeader: (title: string, navItem: string, icon?: JSX.Element, documentTitle?: string, hasSidebar?: boolean, showSidebar?: boolean, brandTitle?: string) => { },
  setRoutes: (routes: { path: string; breadcrumbName: string | React.ReactNode }[]) => { },
  setSideMenuOpened: (visible: boolean) => { },
  setLanguage: (lang: string) => {}
});

export default HeaderContext;
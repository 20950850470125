import { FC } from 'react'; import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import SettingPage from './settings-page';
import { useAuthentication } from 'src/core/services/authentication-service';

const SettingsIndex: FC = () => {
  const [authStatus] = useAuthentication();

  return (
    <>
      {!authStatus?.user.value?.isOwner
        ?
        <Navigate to="/login/403" />
        :
        <Routes>
          <Route index element={<SettingPage />} key={window.location.pathname} />
        </Routes>
      }
    </>
  );
}

export default SettingsIndex

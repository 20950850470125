/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AppConfiguration } from 'src/core/services/authentication-service';
import { container } from 'src/inversify.config';
import { useBlocker } from './useBlocker';
import AppContext from 'src/services/app-context';

export function useCallbackPrompt(when: boolean): (boolean | (() => void))[] {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const serviceUrl = container.get<AppConfiguration>("AppConfiguration").serviceUrl;
  const { setIsDirty } = useContext(AppContext);

  const getLocationWithoutPublicUrl = (location: any) => {
    //return location.replace(process.env.PUBLIC_URL, '');
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const appName = serviceUrl.replace(baseUrl, '');
    return location.replace(appName, '');
  }

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      const nextLocationWithoutPublicUrl = getLocationWithoutPublicUrl(nextLocation.location.pathname);
      // in if condition we are checking next location and current location are equals or not
      if (!confirmedNavigation && nextLocationWithoutPublicUrl !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location],
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
    setIsDirty(false);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      const navigateTo = getLocationWithoutPublicUrl(lastLocation.location.pathname);
      navigate(navigateTo);
      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
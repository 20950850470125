import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Alert, Box, Card, Container, Divider, Grid, Group, LoadingOverlay, NumberInput, ScrollArea, Select, Stack, Text, Title, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useQuery } from 'src/core/utils/hooks';
import { container } from 'src/inversify.config';
import { AskDocumentStore, DocumentViewerStore, DownloadDocumentContentWithInfo } from 'src/stores/documents';
import { useDocumentTitle, useElementSize } from '@mantine/hooks';
import { Document, PDFPageProxy, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { AppConfiguration } from 'src/core/services/authentication-service';
import { IconZoomOut, IconZoomIn, IconAlertCircle, IconCircleArrowUp, IconCircleArrowDown, IconExternalLink } from '@tabler/icons-react';
import MDEditor from '@uiw/react-md-editor';
import classes from '../index.module.css';
import ChatComponent from 'src/components/chat';
import DocumentChatHistory from '../admin/documents/document-chat-history';
import { NotFoundInfo } from 'src/core/ui/not-found-component';

const DocumentViewer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { documentId } = useParams();
  const viewport = useRef<HTMLDivElement>(null);
  const brandName = container.get<AppConfiguration>("AppConfiguration").brandName;
  useDocumentTitle(t("Document viewer") + ' - ' + brandName);
  const [token] = useState<string>(useQuery().get('sas_key') ?? '');
  const [page] = useState<string | undefined>(useQuery().get('page') ?? undefined);
  const [lang] = useState<string | undefined>(useQuery().get('lang') ?? "en");
  const [xmin] = useState<string | undefined>(useQuery().get('xmin') ?? undefined);
  const [xmax] = useState<string | undefined>(useQuery().get('xmax') ?? undefined);
  const [ymin] = useState<string | undefined>(useQuery().get('ymin') ?? undefined);
  const [ymax] = useState<string | undefined>(useQuery().get('ymax') ?? undefined);
  const [layoutWidth] = useState<string | undefined>(useQuery().get('layout_width') ?? undefined);
  const [layoutHeight] = useState<string | undefined>(useQuery().get('layout_height') ?? undefined);
  const chatParam = useQuery().get('chat') ?? 'true';
  const [showChat, setShowChat] = useState<boolean | undefined>(chatParam === 'true');
  const [download, setDownload] = useState<DownloadDocumentContentWithInfo>();
  const store = useMemo(() => container.get(DocumentViewerStore), []);
  const isBusy = store?.state?.isBusy.value;
  const askStore = useMemo(() => container.get(AskDocumentStore), []);
  const containerSize = useElementSize();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const canvas = useRef();
  const [errorDocumentMessage, setErrorDocumentMessage] = useState<string | undefined>();
  const [errorPageMessage, setErrorPageMessage] = useState<string | undefined>();
  const [textContent, setTextContent] = useState<string>('');
  const [show404, setShow404] = useState<boolean>(false);

  useEffect(() => {
    if (documentId) {
      store.getDownloadDataToBlob(documentId, token).then(response => {
        setDownload(response);
        setShow404(!response?.data);
      });
    }
    if (token) {
      askStore.setToken(token);
    }
    else {
      setShowChat(false);
    }
  }, [documentId, token])

  useEffect(() => {
    if (download?.data) {
      if (download.data?.type === "text/markdown") {
        let reader = new FileReader();
        reader.onload = () => {
          const contentWithInfo = JSON.parse(reader.result as string) as any;
          setTextContent(contentWithInfo.content);
        };
        reader.readAsText(download.data);
      }
      else if (download.data?.type !== 'application/pdf') {
        let reader = new FileReader();
        reader.onload = () => setTextContent(reader.result as string);
        reader.readAsText(download.data);
      }
    }
  }, [download?.data])

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
    setPageNumber(page ? parseInt(page) : 1);
    setErrorDocumentMessage(undefined);
  }

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  const onInputPageKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const value = parseInt(event.target.value);
      setPageNumber(value);
    }
  };

  const previousZoom = () => {
    if (scale <= 0.25) return;
    setScale(prev => prev - 0.25);
  }

  const nextZoom = () => {
    if (scale >= 2) return;
    setScale(prev => prev + 0.25);
  }

  const drawRectangle = (currentPage: PDFPageProxy) => {
    if (!canvas.current) {
      return;
    }

    if (currentPage.pageNumber == parseInt(page || "1") && xmin && ymin && xmax && ymax) {
      const viewport = currentPage.getViewport();
      const ratioX = (canvas.current as any).width / viewport.viewBox[2];
      const ratioY = (canvas.current as any).height / viewport.viewBox[3];

      const context = (canvas.current as any).getContext('2d');

      if (layoutWidth && layoutHeight && layoutWidth !== "0" && layoutHeight !== "0") {
        // Allows variable dpi
        const floatXmin = parseFloat(xmin) / parseFloat(layoutWidth) * viewport.viewBox[2]
        const floatXmax = parseFloat(xmax) / parseFloat(layoutWidth) * viewport.viewBox[2]
        const floatYmin = parseFloat(ymin) / parseFloat(layoutHeight) * viewport.viewBox[3]
        const floatYmax = parseFloat(ymax) / parseFloat(layoutHeight) * viewport.viewBox[3]
        const x = floatXmin * ratioX;
        const y = floatYmin * ratioY;
        const w = (floatXmax - floatXmin) * ratioX;
        const h = (floatYmax - floatYmin) * ratioY;
        context.globalAlpha = 0.3;
        context.fillStyle = "#ffff00";
        context.fillRect(x, y, w, h);
        scrollToRectangle(y, (canvas.current as any).height);
      }
      else {
        // Fixed to 72dpi
        const x = parseFloat(xmin) * ratioX;
        const y = parseFloat(ymin) * ratioY;
        const w = (parseFloat(xmax) - parseFloat(xmin)) * ratioX;
        const h = (parseFloat(ymax) - parseFloat(ymin)) * ratioY;
        context.globalAlpha = 0.3;
        context.fillStyle = "#ffff00";
        context.fillRect(x, y, w, h);
        scrollToRectangle(y, (canvas.current as any).height);
      }
    }
  }

  const scrollToRectangle = (position: number, canvasHeight: number) => {
    const scrollHeight = viewport?.current?.scrollHeight ?? canvasHeight;
    const ratio = canvasHeight / scrollHeight;
    const scrollTop = position / ratio;
    viewport?.current?.scrollTo({ top: scrollTop, behavior: 'smooth' });
  };

  const isPdf = download?.data?.type === 'application/pdf';
  const isChat = download?.data?.type === 'text/chat';

  return (
    <>
      <LoadingOverlay visible={isBusy} />
      {show404 ?
        <NotFoundInfo
          title={t('You have found a secret place')}
          description={t('Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.') as string} />
        :
        <Box p="xs" className={classes.fullHeightContainer} style={{ minHeight: '100svh', backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] }}>
          <Grid gutter="xs">
            <Grid.Col span={showChat && download?.data ? 9 : 12}>
              <Card withBorder ref={containerSize.ref}>
                <Card.Section withBorder inheritPadding py="xs">
                  <Group gap="xs" justify='center' align='center'>
                    {download?.title && <Title order={4}>{download?.title} {download?.url && <a href={download?.url} target="_blank" rel="noreferrer"><IconExternalLink size={16} /></a>}</Title>}
                    {isPdf &&
                      <>
                        <Divider orientation="vertical" />
                        <ActionIcon onClick={previousPage} disabled={pageNumber <= 1} color={theme.colors[theme.primaryColor][6]} variant='transparent'>
                          <IconCircleArrowUp />
                        </ActionIcon>
                        <ActionIcon onClick={nextPage} disabled={pageNumber >= numPages} color={theme.colors[theme.primaryColor][6]} variant='transparent'>
                          <IconCircleArrowDown />
                        </ActionIcon>
                        <Text>{t("Page")}</Text>
                        <NumberInput
                          w={40}
                          size='xs'
                          styles={{ input: { fontSize: theme.fontSizes.md } }}
                          variant='unstyled'
                          hideControls
                          defaultValue={1}
                          value={pageNumber}
                          min={1}
                          max={numPages}
                          onKeyDown={onInputPageKeyDown}
                        />
                        <Text>{t("of")} {numPages || '--'}</Text>
                        <Divider orientation="vertical" />
                        <ActionIcon onClick={previousZoom} disabled={scale <= 0.25} color={theme.colors[theme.primaryColor][6]} variant='transparent'>
                          <IconZoomOut />
                        </ActionIcon>
                        <ActionIcon onClick={nextZoom} disabled={scale >= 2} color={theme.colors[theme.primaryColor][6]} variant='transparent'>
                          <IconZoomIn />
                        </ActionIcon>
                        <Select
                          size='xs'
                          w={100}
                          allowDeselect={false}
                          data={[
                            { value: '0.25', label: '25%' },
                            { value: '0.5', label: '50%' },
                            { value: '0.75', label: '75%' },
                            { value: '1', label: '100%' },
                            { value: '1.25', label: '125%' },
                            { value: '1.5', label: '150%' },
                            { value: '1.75', label: '175%' },
                            { value: '2', label: '200%' },
                          ]}
                          value={scale.toString()}
                          onChange={(value) => setScale(parseFloat(value as string))}
                        />
                      </>
                    }
                  </Group>
                </Card.Section>
                {isPdf ?
                  <Container fluid>
                    <ScrollArea style={{ height: 'calc(100svh - 93px)' }} offsetScrollbars viewportRef={viewport}>
                      <Document
                        file={download?.data}
                        options={{ workerSrc: "pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onSourceSuccess={() => setErrorDocumentMessage(undefined)}
                        loading={<LoadingOverlay visible />}
                        error={<Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red">
                          <Text>{errorDocumentMessage}</Text>
                        </Alert>}
                        onLoadError={(error) => setErrorDocumentMessage('Error while loading document: ' + error.message)}
                        onSourceError={(error) => setErrorDocumentMessage('Error while retrieving document source: ' + error.message)}>
                        <Page
                          canvasRef={canvas as any}
                          onLoadSuccess={(page) => setErrorPageMessage(undefined)}
                          onRenderSuccess={(page) => { drawRectangle(page); setErrorPageMessage(undefined) }}
                          pageNumber={pageNumber}
                          loading={<LoadingOverlay visible />}
                          width={containerSize.width}
                          scale={scale}
                          error={<Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red">
                            <Text>{errorPageMessage}</Text>
                          </Alert>}
                          onLoadError={(error) => setErrorPageMessage('Error while loading page: ' + error.message)}
                          onRenderError={(error) => setErrorPageMessage('Error while render page: ' + error.message)}
                        />
                      </Document>
                    </ScrollArea>
                  </Container>
                  :
                  <>
                    {isChat ?
                      <DocumentChatHistory content={textContent} scrollAreaHeight='calc(100svh - 85px)' />
                      :
                      <Box py="xs">
                        <ScrollArea style={{ height: 'calc(100svh - 105px)' }} offsetScrollbars>
                          <div data-color-mode={colorScheme}>
                            <MDEditor.Markdown source={textContent} />
                          </div>
                        </ScrollArea>
                      </Box>
                    }
                  </>
                }
              </Card>
            </Grid.Col>
            {!errorDocumentMessage && showChat && download?.data &&
              <Grid.Col span="auto">
                <ChatComponent
                  id={documentId as string}
                  language={lang}
                  store={askStore}
                  description={t("Ask me questions about this document") as any}
                  hideHeader
                  hideDebugPanel
                  key={documentId as string}
                  hideTokens
                  sendInitialMessage={false}
                  hideUserPreferences
                />
              </Grid.Col>
            }
          </Grid>
        </Box>
      }
    </>
  );
};

export default DocumentViewer;

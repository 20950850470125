import { Alert, Button, Group, LoadingOverlay, Stack, Switch, Text } from '@mantine/core';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'src/pages/index.module.css';
import { container } from 'src/inversify.config';
import { ProfileIdentitiesStore } from 'src/stores/profile';
import { AppConfiguration, authStatus } from 'src/core/services/authentication-service';
import { Query } from 'src/core/stores/data-store';
import HeaderContext from 'src/services/header-context';
import { useQuery } from 'src/core/utils/hooks';
import useBus, { dispatch } from 'use-bus';

const ProfileIdentities: FC<{
  readOnly?: boolean;
}> = ({ readOnly = false }) => {
  const { t } = useTranslation();
  const { header } = useContext(HeaderContext);
  const [loginHintSuccess] = useState<string>(useQuery().get('success') ?? '');
  const identitiesStore = useMemo(() => container.get(ProfileIdentitiesStore), []);
  const identitiesState = identitiesStore.state;
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    identitiesStore.load({} as Query);
  }, []);

  const onReload = (payload: any) => {
    identitiesStore.load({} as Query);
  };

  useEffect(() => {
    if (loginHintSuccess && loginHintSuccess.length > 0) {
      dispatch({ type: '@@ui/PROFILE_LOGINHINT_SUCCESS', payload: loginHintSuccess });
    }
  }, [loginHintSuccess])

  useBus(
    '@@ui/PROFILE_LOGINHINT_SUCCESS',
    (event) => { onReload(event.payload) },
    [identitiesStore]
  )

  const onCheckedChange = async (value: boolean, serviceId: string, loginHint: string, scope: string) => {
    if (value) {
      requestToken(serviceId, loginHint, scope);
    }
    else {
      await identitiesStore.delete(`${serviceId}|${loginHint}|${scope}`, { language: header.language })
      identitiesState.items.filter(o => o.serviceId.value === serviceId)[0].active.set(false);
    }
  }

  const requestToken = (serviceId: string, loginHint: string, scope: string) => {
    window.open(`${baseUri}/api/oauth/${encodeURIComponent(serviceId)}/auth?access_token=${authStatus.accessToken?.value?.value as string}&loginHint=${loginHint}&scope=${scope}`);
  }

  return (
    <Stack pos="relative">
      <LoadingOverlay visible={identitiesState.isBusy.value} />
      {identitiesState.count && identitiesState.count.value === 0 && <Alert color="gray" >
        {t("There is no identity provider configured yet")}
      </Alert>}
      {identitiesState.items && identitiesState.items.value && identitiesState.items.map((item) => (
        <Group className={classes.item} wrap="nowrap" gap="xl" key={item.serviceId.value}>
          <Switch
            onLabel={t("ON")}
            offLabel={t("OFF")}
            className={classes.switch}
            size="lg"
            checked={item.active.value}
            readOnly={readOnly}
            onChange={e => !readOnly ? onCheckedChange(e.target.checked, item.serviceId.value, item.loginHint.value, item.scope.value) : undefined} />
          <div>
            <Text fw='700'>{item.loginHint.value}</Text>
            {item.expired.value && <Alert color="red" >
              {t("Your access token is expired")} <Button variant='transparent' component='a'
                href={`${baseUri}/api/oauth/${encodeURIComponent(item.serviceId.value)}/auth?access_token=${authStatus.accessToken?.value?.value as string}&loginHint=${item.loginHint.value}&scope=${item.scope.value}`}
              >{t("Click here to request a new access token")}</Button>
            </Alert>}
            <Text size="xs" c="dimmed">
              <Group><Text>{item.title.value} | {item.scope.value}</Text></Group>
              {item.description.value}
            </Text>
          </div>
        </Group>
      ))}
    </Stack>
  );
};

export default ProfileIdentities;
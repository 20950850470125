import { Tooltip, Text, Alert, Card, Stack, Menu, ActionIcon, Modal, LoadingOverlay, Group, SimpleGrid, Box, Select, useMantineColorScheme } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { DiffEditor } from "@monaco-editor/react";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import StatusBadge from "src/components/status-badge";
import { Query } from "src/core/stores/data-store";
import { formatMessage } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { BotSnapshotCompareStore, BotSnapshotHistoryStore, BotSnapshotSelectStore, BotSnapshotSummary } from "src/stores/bots";
import { IconAlertCircle as AlertCircle, IconCloudOff as CloudOff, IconCloudUpload as CloudUpload, IconDotsVertical as DotsVertical, IconFileCode2 as FileCode2, IconFileDiff as FileDiff, IconFileShredder as FileShredder, IconAdjustments, IconPlus as Plus, IconPrompt as Prompt, IconTrash as Trash } from "@tabler/icons-react";
import jsyaml from "js-yaml";
import TrainingStatusBadge from "src/components/training-status-badge";
import useBus, { dispatch } from "use-bus";
import { ChatBotStore } from "src/stores/skills";
import TableListV2, { TableListV2Model } from "src/core/ui/table-list/table-list-v2";
import { DataTableSortStatus } from "mantine-datatable";
import ChatComponent from "src/components/chat";
import { monacoOptions } from "src/configurations/editor-config-jinja";

const BotContentComparer: FC<{
  comparerItem: { botId: string, snapshotId: string, compareWithPrevious: boolean };
  onClose?: () => void;
}> = ({ comparerItem, onClose }) => {
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const store = useMemo(() => container.get(BotSnapshotCompareStore), []);
  const state = store.state;
  const isBusy = state.isBusy.value;
  const errorMessage = state.errorMessage.value;
  const [yamlContentOriginal, setYamlContentOriginal] = useState<string>('');
  const [yamlContentModified, setYamlContentModified] = useState<string>('');

  useEffect(() => {
    if (comparerItem) {
      store.compare(comparerItem.botId, comparerItem.snapshotId, comparerItem.compareWithPrevious);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparerItem]);

  useEffect(() => {
    if (state?.item?.value) {
      setYamlContentOriginal(jsyaml.dump(JSON.parse(state.item.original?.content?.value ?? "{}")));
      setYamlContentModified(jsyaml.dump(JSON.parse(state.item.modified?.content?.value ?? "{}")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.item]);

  return (
    <Modal
      opened={true}
      size="90%"
      onClose={() => onClose?.()}
      title={comparerItem.compareWithPrevious ? t("Compare with previous") : t("Compare with current")}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isBusy} />
        {errorMessage && (
          <Alert p="md" mb="xs" icon={<AlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
            <Text>{formatMessage(errorMessage)}</Text>
          </Alert>
        )}
        {state?.item?.value &&
          <Stack>
            <SimpleGrid cols={2}>
              <Group align="center" gap="xs">
                <FileCode2 />
                {state.item.original.createdBy.value ?
                  <Text>{moment(state.item.original.createdOn.value).format('lll')} ({state.item.original.createdBy.value})</Text>
                  :
                  <Text>{t("There is no previous version")}</Text>
                }
              </Group>
              <Group align="center" gap="xs">
                <FileCode2 />
                <Text>{moment(state.item.modified.createdOn.value).format('lll')} ({state.item.modified.createdBy.value})</Text>
                {!comparerItem.compareWithPrevious &&
                  <Text fw={500}>[{t("CURRENT")}]</Text>
                }
              </Group>
            </SimpleGrid>

            <div style={{ width: '100%', height: 'calc(100svh - 225px)' }}>
              <DiffEditor
                width="100%"
                height="100%"
                options={monacoOptions}
                language="yaml"
                original={yamlContentOriginal}
                modified={yamlContentModified}
                theme={colorScheme === 'dark' ? 'vs-dark' : 'light'}
              />
            </div>
          </Stack>
        }
      </div>
    </Modal>
  );
};

const TestBotHistory: FC<{
  botInfo: { botId: string, snapshotId: string, title: string };
  onClose?: () => void;
}> = ({ botInfo, onClose }) => {
  const testBotStore = useMemo(() => container.get(ChatBotStore), []);
  const snapShotSelectStore = useMemo(() => container.get(BotSnapshotSelectStore), []);

  useEffect(() => {
    snapShotSelectStore.setBotId(botInfo.botId);
  }, [botInfo])

  return (
    <Modal
      opened={true}
      size="90%"
      onClose={() => onClose?.()}
      title={botInfo.title}>
      <Box h={"72svh"}>
        <ChatComponent
          store={testBotStore}
          id={botInfo.botId as string}
          snapshotId={botInfo.snapshotId}
          snapshotSelectStore={snapShotSelectStore}
          key={`test-chat-bot-history-${botInfo.snapshotId as string}`}
          hideTokens
        />
      </Box>
    </Modal>
  );
};

const BotHistory: FC<{
  botId: string;
  canContribute: boolean;
}> = ({ botId, canContribute }) => {
  const { t } = useTranslation();
  const modals = useModals();
  const store = useMemo(() => container.get(BotSnapshotHistoryStore), []);
  const state = store.state;
  const [query, setQuery] = useState<Query>(undefined as any);
  const [comparerItem, setComparerItem] = useState<{ botId: string, snapshotId: string, compareWithPrevious: boolean }>(undefined as any);
  const [testHistoryBot, setTestHistoryBot] = useState<{ botId: string, snapshotId: string, title: string }>(undefined as any);
  const [statusFilter, setStatusFilter] = useState('');
  const [trainingStatusFilter, setTrainingStatusFilter] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'createdOn', direction: 'desc' });

  useEffect(() => {
    if (botId) {
      store.setBotId(botId)
      const newQuery = {
        ...query,
        //orderBy: [{ field: 'createdOn', direction: 'Descending', useProfile: false }],
        skip: 0,
        parameters: {
          statusFilter: statusFilter,
          trainingStatusFilter: trainingStatusFilter,
          orderByField: sortStatus.columnAccessor,
          orderByDirection: sortStatus.direction
        }
      } as Query;
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId, statusFilter, trainingStatusFilter]);

  useEffect(() => {
    if (query) {
      load(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const load = async (query: Query) => {
    store.load(query);
  };

  useBus(
    '@@ui/BOT_HISTORY_LIST_REFRESH',
    () => load(query),
    [query],
  );

  const openDeleteModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Delete snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to delete this item?')}
          <br></br>
          {t('All associated data will be deleted')}
        </Text>
      ),
      labels: { confirm: t('Delete snapshot'), cancel: t('Cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmDelete(snapshotId),
    });

  const showOkNotification = () => {
    showNotification({
      title: t('Completed'),
      message: <Text>{t('The operation has been successful')}</Text>,
      color: 'green'
    });
  }

  const showKoNotification = () => {
    showNotification({
      title: t('Error'),
      message: <Text>{formatMessage(state.errorMessage.value)}.</Text>,
      color: 'red'
    });
  }

  const onConfirmDelete = async (snapshotId: string) => {
    await store.deleteSnapshot(botId as string, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
    }
  };

  const onCompare = (snapshotId: string, compareWithPrevious: boolean = false) => {
    setComparerItem({
      botId: botId,
      snapshotId: snapshotId,
      compareWithPrevious: compareWithPrevious
    });
  }

  const onTestHistoryBot = (snapshot: BotSnapshotSummary) => {
    setTestHistoryBot({
      botId: botId,
      snapshotId: snapshot.id,
      title: `${t("Test bot version: ")} ${moment(snapshot.createdOn).format('lll')}`
    });
  }

  const openTrainModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Train snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to train this item?')}
          <br></br>
          {t('The training process might take a while.')}
        </Text>
      ),
      labels: { confirm: t('Train snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmTrain(snapshotId),
    });

  const onConfirmTrain = async (snapshotId: string) => {
    await store.trainSnapshot(botId as string, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
      dispatch('@@ui/BOT_LIST_REFRESH');
    }
  };

  const openRetireModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Retire snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to retire this item?')}
        </Text>
      ),
      labels: { confirm: t('Retire snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmRetire(snapshotId),
    });

  const onConfirmRetire = async (snapshotId: string) => {
    await store.retireSnapshot(botId as string, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
      dispatch('@@ui/BOT_LIST_REFRESH');
    }
  };

  const openPublishModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Publish snapshot')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to publish this item?')}
        </Text>
      ),
      labels: { confirm: t('Publish snapshot'), cancel: t('Cancel') },
      onConfirm: () => onConfirmPublish(snapshotId),
    });

  const onConfirmPublish = async (snapshotId: string) => {
    await store.publishSnapshot(botId as string, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
      dispatch('@@ui/BOT_LIST_REFRESH');
    }
  };

  const openCreateNewVersionModal = (snapshotId: string) =>
    modals.openConfirmModal({
      title: <Text>{t('Create new version')}</Text>,
      children: (
        <Text size="sm">
          {t('Are you sure you want to create new version from this snapshot?')}
        </Text>
      ),
      labels: { confirm: t('Create new version'), cancel: t('Cancel') },
      onConfirm: () => onConfirmCreateNewVersion(snapshotId),
    });

  const onConfirmCreateNewVersion = async (snapshotId: string) => {
    await store.createNewVersionFromSnapshot(botId, snapshotId);
    state.errorMessage.value ? showKoNotification() : showOkNotification();
    if (!state.errorMessage.value) {
      load(query);
      dispatch('@@ui/BOT_LIST_REFRESH');
      dispatch('@@ui/BOT_DETAIL_REFRESH');
    }
  };

  const model: TableListV2Model<BotSnapshotSummary> = {
    data: store.toListState(state.value),
    query,
    columns: [
      {
        accessor: 'createdOn',
        title: t('Version'),
        sortable: true,
        render: (item: BotSnapshotSummary) => (
          <Text>{moment(item.createdOn).format('lll')}</Text>
        )
      },
      {
        accessor: 'hash',
        title: t('Hash'),
        render: (item: BotSnapshotSummary) => (
          <Text style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{item.hash}</Text>
        )
      },
      {
        accessor: 'status',
        title: t('Status'),
        render: (item: BotSnapshotSummary) => (
          <StatusBadge status={item.status} />
        )
      },
      {
        accessor: 'trainingStatus',
        title: t('Training status'),
        render: (item: BotSnapshotSummary) => (
          <TrainingStatusBadge status={item.trainingStatus} />
        )
      },
      {
        accessor: 'createdOn',
        title: t('Modified on'),
        sortable: true,
        render: (item: BotSnapshotSummary) => (
          <Tooltip withinPortal label={<Text size='xs'>{moment(item.createdOn).toString()}</Text>}>
            <Text>{moment(item.createdOn).fromNow()}</Text>
          </Tooltip>
        )
      },
      {
        accessor: 'createdBy',
        title: t('Modified by'),
        sortable: true,
        render: (item: BotSnapshotSummary) => (
          <Text>{item.createdBy}</Text>
        )
      },
      {
        accessor: 'actions',
        title: t('Actions'),
        render: (item: BotSnapshotSummary) => (
          <div style={{ float: 'right' }}>
            <Menu withinPortal withArrow width={250} shadow="md" position="bottom-end">
              <Menu.Target>
                <Tooltip label={t("More options")}>
                  <ActionIcon variant='subtle' size="sm" color="gray">
                    <DotsVertical />
                  </ActionIcon>
                </Tooltip>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{t("Snapshot options")}</Menu.Label>
                <Menu.Item disabled={!canContribute} leftSection={<FileShredder size={20} />} onClick={() => openTrainModal(item.id)}>{t('Train snapshot')}</Menu.Item>
                <Menu.Item disabled={!canContribute || item.status !== 'Retired'} leftSection={<CloudUpload size={20} />} onClick={() => openPublishModal(item.id)}>{t('Publish snapshot')}</Menu.Item>
                <Menu.Item disabled={!canContribute || item.isCurrent} leftSection={<Plus size={20} />} onClick={() => openCreateNewVersionModal(item.id)}>{t('Create new version')}</Menu.Item>
                <Menu.Label>{t("Compare")}</Menu.Label>
                <Menu.Item disabled={item.isCurrent} leftSection={<FileDiff size={20} />} onClick={() => onCompare(item.id, false)}>{t("Compare with current")}</Menu.Item>
                <Menu.Item leftSection={<FileDiff size={20} />} onClick={() => onCompare(item.id, true)}>{t("Compare with previous")}</Menu.Item>
                <Menu.Label>{t("Test")}</Menu.Label>
                <Menu.Item leftSection={<Prompt size={20} />} onClick={() => onTestHistoryBot(item)}>{t("Test bot")}</Menu.Item>
                <Menu.Divider />
                <Menu.Label>{t("Danger zone")}</Menu.Label>
                <Menu.Item disabled={!canContribute || item.status !== 'Published'} color="red" leftSection={<CloudOff size={20} />} onClick={() => openRetireModal(item.id)}>{t('Retire snapshot')}</Menu.Item>
                <Menu.Item disabled={!canContribute || item.isCurrent} color="red" leftSection={<Trash size={20} />} onClick={() => openDeleteModal(item.id)}>{t('Delete snapshot')}</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        )
      }
    ]
  };

  return (
    <Card withBorder>
      <Stack gap="md">
        <TableListV2
          idAccessor="createdOn"
          model={model}
          onQueryChanged={setQuery}
          onRefresh={() => load(query)}
          striped
          highlightOnHover
          hideSearch
          sortable
          sortStatus={sortStatus}
          onSortStatusChanged={setSortStatus}
          rightToolBarRender={
            <Group gap="xs" align="center">
              <Select
                clearable
                style={{ width: 160 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Status') as string}
                data={[
                  { value: 'Draft', label: t('Draft') as string },
                  { value: 'Published', label: t('Published') as string },
                  { value: 'Retired', label: t('Retired') as string },
                ]}
                onChange={(value) => setStatusFilter(value as string)}
                value={statusFilter}
              />
              <Select
                clearable
                style={{ width: 160 }}
                leftSection={<IconAdjustments size={20} />}
                placeholder={t('Training status') as string}
                data={[
                  { value: 'Pending', label: t('Pending') as string },
                  { value: 'Training', label: t('Training') as string },
                  { value: 'Trained', label: t('Trained') as string },
                  { value: 'Error', label: t('Error') as string },
                ]}
                onChange={(value) => setTrainingStatusFilter(value as string)}
                value={trainingStatusFilter}
              />
            </Group>
          }
        />
      </Stack>

      {comparerItem &&
        <BotContentComparer
          comparerItem={comparerItem}
          onClose={() => setComparerItem(undefined as any)}
        />
      }
      {testHistoryBot &&
        <TestBotHistory
          botInfo={testHistoryBot}
          onClose={() => setTestHistoryBot(undefined as any)}
        />
      }

    </Card>
  );
};

export default BotHistory;
import DataStore from 'src/core/stores/data-store';
import FormStore from 'src/core/stores/form-store';

export type AccessMode = 'Everyone' | 'MembersOnly';
export type MemberPrivileges = 'Reader' | 'Owner' | 'Contributor';

export interface MemberEditorState {
  accessMode: AccessMode;
  members: MemberItem[]
};

export interface MemberItem {
  identity: string;
  privileges: string;
}

export interface IdentitySummary {
  name: string;
  displayName: string;
  role: string;
  isGroup: boolean;
  hasApiKey?: boolean;
  modifiedOn: Date;
}

export type IdentityRole = 'User' | 'Creator' | 'Administrator';

export interface IdentityItem {
  name: string;
  displayName: string;
  isGroup: boolean;
  role: IdentityRole;
  modifiedOn: Date;
  generateApiKey?: boolean;
  hasApiKey?: boolean;
  apiKey?: string;
  assigned: IdentityItem[];
  metadata: { [key: string]: any };
}

export interface IdentityForm {
  name: string;
  displayName: string;
  isGroup: boolean;
  role: IdentityRole;
  assigned: string[];
  generateApiKey: boolean;
  allowPersonalCollection: boolean;
}

export class IdentitySummaryStore extends DataStore<IdentitySummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/identity`, []);
  }
}

export class IdentityItemStore extends FormStore<IdentityItem, IdentityItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/identity`);
  }
}
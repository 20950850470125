import { MantineSize, Button, Tooltip } from "@mantine/core";
import { t } from "i18next";
import { FC } from "react";

const TemperatureSelector: FC<{
  value: string;
  onChange?: (value: string) => void;
  edit?: boolean;
  size?: MantineSize;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
}> = ({ value, onChange, edit = false, size = "xs", fullWidth = false, className, disabled = false }) => {

  const onChangeTemperature = (value: string) => {
    if (edit) {
      onChange?.(value);
    }
  }

  const data = [
    { label: t("Creative"), value: '1', info: t("Original and imaginative answers.") },
    { label: t("Balanced"), value: '0.5', info: t("Informative and descriptive answers.") },
    { label: t("Accurate"), value: '0.2', info: t("Concise and simple answers.") },
    { label: t("Deterministic"), value: '0', info: t("Always the same answer for the same question.") },
  ];

  return (
    <Button.Group className={className}>
      {data.map((temp) =>
        <Tooltip key={temp.value} withinPortal withArrow position='bottom' offset={15} label={temp.info}>
          <Button
            color="var(--mantine-primary-color-filled)"
            variant={value === temp.value ? "filled" : "default"}
            size={size}
            fullWidth={fullWidth}
            style={disabled ? { cursor: 'not-allowed' } : undefined}
            onClick={() => onChangeTemperature?.(temp.value)}>
            {temp.label}
          </Button>
        </Tooltip>
      )}
    </Button.Group>
  );
};

export default TemperatureSelector;
import { Button, MantineStyleProp, NumberInput, NumberInputHandlers, TextInput, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { FC, ReactNode, useRef } from 'react';
import { IconMinus as Minus, IconPlus as Plus } from '@tabler/icons-react';

const NumberInputWithControl: FC<{
  label?: ReactNode;
  description?: string;
  required?: boolean;
  precision?: number;
  min?: number;
  step?: number;
  max?: number;
  value: number;
  width?: string | number;
  onChange?: (value: number | string) => void;
  readOnly?: boolean;
  style?: MantineStyleProp;
  className?: string;
}> = ({ label, description, required = false, precision, min, step, max, value, width, onChange, readOnly = false, style, className }) => {
  const confidenceHandlers = useRef<NumberInputHandlers>();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  return (
    <>
      {!readOnly ?
        <NumberInput
          w={width ?? 120}
          style={style}
          hideControls
          handlersRef={confidenceHandlers}
          required={required}
          label={label}
          description={description}
          decimalScale={precision}
          min={min}
          step={step}
          max={max}
          value={value}
          onChange={onChange}
          rightSectionWidth={60}
          rightSection={
            <Button.Group>
              <Button size='xs' p={5} variant="subtle" c={colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[5]} onClick={() => confidenceHandlers?.current?.decrement()}><Minus size={18} /></Button>
              <Button size='xs' p={5} variant="subtle" c={colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[5]} onClick={() => confidenceHandlers?.current?.increment()}><Plus size={18} /></Button>
            </Button.Group>
          }
          // formatter={(value) =>
          //   !Number.isNaN(parseFloat(value as string))
          //     ? `${value}`.replace(/(\.\d*?[0-9])0+$/g, "$1")
          //     : ''
          // }
          className={className}
        />
        :
        <TextInput
          w={width ?? 120}
          label={label}
          description={description}
          styles={{ input: { paddingLeft: 0 } }}
          variant="unstyled"
          value={value}
          readOnly
          className={className}
        />
      }
    </>
  );
};

export default NumberInputWithControl;

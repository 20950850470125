import { useMemo, useEffect, useState, FC } from 'react';
import { container } from 'src/inversify.config';
import { Query } from 'src/core/stores/data-store';
import { useTranslation } from 'react-i18next';
import { Group, MantineStyleProp, Text } from '@mantine/core';
import { IconApi as Api, IconUser as User, IconUsers as Users } from '@tabler/icons-react';
import { GenericSelector } from 'src/core/ui/generic-selector';
import { IdentitySummary, IdentitySummaryStore } from 'src/stores/identities';

export const IdentitySelector: FC<{
  mode?: "User" | "Group";
  value?: string,
  onChange?: (value: string) => void,
  width?: string | number,
  label?: string,
  required?: boolean,
  clearable?: boolean,
  creatable?: boolean
  exclude?: string[];
  style?: MantineStyleProp;
  readOnly?: boolean;
}> = ({ mode, value, onChange, width, label, required, clearable, creatable, exclude, style, readOnly }) => {
  const store = useMemo(() => container.get(IdentitySummaryStore), []);
  const state = store.state;
  const { t } = useTranslation();
  const [query, setQuery] = useState<Query>(undefined as any);

  useEffect(() => {
    //if (!value) {
    load(query);
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  useEffect(() => {
    const newQuery = {
      ...query,
      orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
      skip: 0,
      take: 100,
      filter: { type: mode },
      parameters: {
        includeIds: [value]
      }
    } as Query;
    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, value]);

  const load = async (query: Query) => {
    if (query) {
      store.load(query);
    }
  };

  const renderDataItem = (item: IdentitySummary) => {
    return {
      label: item.name,
      value: item.name,
    } as any;
  }

  const renderOption = (item: IdentitySummary) => (
    <Group gap="xs">
      {
        item.hasApiKey ? <><Api size={20} /><Text size='sm'>{item.name}</Text></> :
          (item.isGroup
            ?
            <><Users size={20} color="gray" /><Text size='sm'>{item.name}</Text></>
            :
            <><User size={20} color="gray" /><Text size='sm'>{item.name}</Text></>)
      }
    </Group>
  );

  return (
    <GenericSelector
      label={label}
      data={store.toListState(state.value)}
      renderDataItem={(item) => renderDataItem(item)}
      query={query}
      onQueryChanged={setQuery}
      value={value}
      onChange={onChange}
      width={width || 300}
      placeholder={t('Select an identity') as string}
      searchable
      searchField='name'
      clearable={clearable}
      renderOption={renderOption}
      required={required}
      creatable={creatable}
      exclude={exclude}
      withinPortal
      style={style}
      readOnly={readOnly}
    />
  );
};

import { Box, Group, Stack, Switch, Text } from '@mantine/core';
import { FC, ReactNode } from 'react';

const SwitchButton: FC<{
  label: string;
  description?: string;
  children?: ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  width?: string | number;
  readOnly?: boolean;
  className?: string;
  disabled?: boolean;
}
> = ({ label, description, children, checked, onChange, width, readOnly, className, disabled }) => {
  return (
    <Box className={className} w={width}>
      <Stack gap="xs" style={{ width: '100%' }}>
        <Group>
          <Switch
            checked={checked}
            onChange={e => !readOnly && !disabled ? onChange(e.target.checked) : null}
            disabled={disabled}
          />
          <Text size="md" fw={500}>{label}</Text>
        </Group>
        <Text fz="sm" c="dimmed">
          {description}
        </Text>
        {children &&
          <Group grow>
            {children}
          </Group>
        }
      </Stack>
    </Box>
  );
};

export default SwitchButton;

import { State, useHookstate } from "@hookstate/core";
import { Group, Input, Text, Select } from "@mantine/core";
import { IconArrowNarrowDown, IconDoorExit, IconClock } from "@tabler/icons-react";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import { SkillContentStepTurnType } from "src/stores/skills";

const SkillContentStepTurn: FC<{
  edit?: boolean;
  state: State<SkillContentStepTurnType>;
}> = ({ edit, state }) => {
  const { t } = useTranslation();
  const scopedState = useHookstate(state);
  const { setIsDirty } = useContext(AppContext);

  const getIcon = (turn: SkillContentStepTurnType) => {
    switch (turn) {
      case 'Continue':
        return <IconArrowNarrowDown size={20} />;
      case 'Wait':
        return <IconClock size={20} />;
      case 'End':
        return <IconDoorExit size={20} />;
      default:
        return;
    }
  }

  return (
    <>
      {edit ?
        <Group align="center">
          <Input.Label w={120} required={edit}>{t("Next step")}:</Input.Label>
          <Select
          allowDeselect={false}
            data={[
              { value: 'Continue', label: t('Continue') as SkillContentStepTurnType },
              { value: 'End', label: t('End') as SkillContentStepTurnType },
              { value: 'Wait', label: t('Wait') as SkillContentStepTurnType }
            ]}
            leftSection={getIcon(scopedState.value)}
            value={scopedState.value}
            onChange={(value) => { scopedState.set(value as SkillContentStepTurnType); setIsDirty(true); }}
          />
        </Group>
        :
        <>
          {scopedState.value === 'Continue' &&
            <Group align="center" gap="xs"><IconArrowNarrowDown size={20} /><Text fs="italic">{t("Continue to next step")}</Text></Group>
          }
          {scopedState.value === 'End' &&
            <Group align="center" gap="xs"><IconDoorExit size={20} /><Text fs="italic">{t("End")}</Text></Group>
          }
          {scopedState.value === 'Wait' &&
            <Group align="center" gap="xs"><IconClock size={20} /><Text fs="italic">{t("Wait")}</Text></Group>
          }
        </>
      }
    </>
  );
};

export default SkillContentStepTurn;
import { Alert, Button, Card, ComboboxItem, Group, Input, Loader, Modal, Radio, Select, SimpleGrid, Stack, Stepper, Switch, Table, Text, Textarea, TextInput, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CollectionSelector } from "src/components/collection-selector";
import { formatBytes, formatMessage } from "src/core/utils/object";
import { container } from "src/inversify.config";
import { CreateDocumentItem, DocumentItemStore, NewDocumentFromWizard, NewDocumentsFromWizard } from "src/stores/documents";
import { tagsToDict } from "src/utils/tags-utils";
import { dispatch } from "use-bus";
import slugify from "slugify";
import { FileWithPath } from "@mantine/dropzone";
import { LanguageSeletor } from "src/components/language-selector";
import { IconAlertCircle, IconPlus, IconArrowRight, IconArrowLeft, IconCircleCheck, IconX, IconCircleX } from "@tabler/icons-react";
import { AppConfiguration } from "src/core/services/authentication-service";
import DocumentsInputFileZone from "./documents-input-file-zone";
import { AsEnumerable } from "linq-es5";
import classes from 'src/pages/index.module.css';
import stepperClasses from 'src/pages/stepper.module.css';
import { BasicMultiselect } from "src/components/basic-multiselect";
import cx from 'clsx';
import { TagItem } from "src/stores/skills";
import { useHookstate } from "@hookstate/core";
import TagsEditor from "src/components/tags-editor";

const AudienceMultiSelect: FC<{
  value: string[] | undefined;
  onChange: (value: string[]) => void;
  label?: string;
}> = ({ value, onChange, label }) => {
  const { t } = useTranslation();
  const defaultAudience = container.get<AppConfiguration>("AppConfiguration").defaultAudience;

  const getAudiencesData = () => {
    let data = [{ value: defaultAudience, label: defaultAudience }] as ComboboxItem[];
    if (value) {
      data = data.concat(value.map(item => ({ label: item, value: item })));
    }

    data = AsEnumerable(data).Distinct(data => data.value).ToArray();

    return data;
  }

  return <BasicMultiselect
    label={label}
    data={getAudiencesData()}
    value={value}
    onChange={onChange}
    placeholder={t("Select audiences") as string}
    creatable
  />
}

const EditableFileRow: FC<{
  index: number;
  form: any;
}> = ({ index, form }) => {
  const { t } = useTranslation();

  return (
    <Table.Tr key={index}>
      <Table.Td>
        <TextInput
          style={{ flex: 1 }}
          required
          rightSection={<Text size="xs">{formatBytes(form.values.documents[index].fileSize)}</Text>}
          rightSectionWidth={100}
          {...form.getInputProps(`documents.${index}.title`)}
        />
      </Table.Td>
      <Table.Td>
        <LanguageSeletor
          {...form.getInputProps(`documents.${index}.language`)}
        />
      </Table.Td>
      <Table.Td>
        <Select
          withinPortal
          defaultValue='Auto'
          data={[
            { value: 'Auto', label: t("Auto") as string },
            { value: 'None', label: t("None") as string },
            { value: 'Paragraph', label: t("Paragraph") as string },
            { value: 'Block', label: t("Block") as string }
          ]}
          {...form.getInputProps(`documents.${index}.splitMethod`)}
        />
      </Table.Td>
      <Table.Td>
        <AudienceMultiSelect
          {...form.getInputProps(`documents.${index}.audiences`)}
        />
      </Table.Td>
    </Table.Tr>
  );
}

const CreateDocumentWizard: FC<{
  opened: boolean;
  onClose: () => void;
  collectionId?: string;
}> = ({ opened, onClose, collectionId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const store = useMemo(() => container.get(DocumentItemStore), []);
  const state = store.state;
  const errorMessage = state.errorMessage.value;
  const isBusy = state.isBusy.value;
  const defaultAudience = container.get<AppConfiguration>("AppConfiguration").defaultAudience;
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [importFiles, setImportFiles] = useState<FileWithPath[] | undefined>(undefined);
  const [showFinishStep, setShowFinishStep] = useState<boolean>(false);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);

  const form = useForm<NewDocumentsFromWizard>({
    initialValues: {
      collectionId: '',
      contentSource: "Internal",
      tags: [],
      audiences: [defaultAudience],
      language: 'en',
      dontAdvertise: false,
      splitMethod: 'Auto',
      documents: [],
      train: false,
      publish: false
    }
  });

  const tagsState = useHookstate({
    tags: [] as TagItem[]
  });

  useEffect(() => {
    if (collectionId) {
      form.setFieldValue('collectionId', collectionId as string);
    }
  }, [collectionId])

  useEffect(() => {
    if (form.values.contentSource === 'Internal') {
      const internalDocument = {
        title: '',
        description: '',
        tags: tagsState.tags.value as TagItem[] ?? [],
        audiences: form.values.audiences ?? [defaultAudience],
        language: form.values.language ?? 'en',
        boost: 1,
        dontAdvertise: form.values.dontAdvertise ?? false,
        reference: '',
        splitMethod: form.values.splitMethod ?? 'Auto',
        fileSize: 0,
        operationStatus: "Pending",
        operationResult: ''
      } as NewDocumentFromWizard;
      form.setFieldValue('documents', [internalDocument]);
      form.setFieldValue('train', false);
      form.setFieldValue('publish', false);
    }
    else {
      form.setFieldValue('documents', []);
      form.setFieldValue('train', true);
      form.setFieldValue('publish', true);
    }
  }, [form.values.contentSource])

  const goToConfigureDocuments = () => {
    if (form.values.contentSource === 'Internal') {
      const internalDocument = {
        title: '',
        description: '',
        tags: tagsState.tags.value as TagItem[] ?? [],
        audiences: form.values.audiences ?? [defaultAudience],
        language: form.values.language ?? 'en',
        boost: 1,
        dontAdvertise: form.values.dontAdvertise ?? false,
        reference: '',
        splitMethod: form.values.splitMethod ?? 'Auto',
        fileSize: 0,
        operationStatus: "Pending",
        operationResult: ''
      } as NewDocumentFromWizard;
      form.setFieldValue('documents', [internalDocument]);
    }

    nextStep();
  }

  const onLoadImportFiles = (files: FileWithPath[] | undefined) => {
    if (files && files.length > 0) {
      let uploadedFiles = [] as NewDocumentFromWizard[];
      files.forEach(file => {
        uploadedFiles.push({
          title: file.name,
          description: '',
          tags: tagsState.tags.value as TagItem[] ?? [],
          audiences: form.values.audiences ?? [defaultAudience],
          language: form.values.language ?? 'en',
          boost: 1,
          dontAdvertise: form.values.dontAdvertise ?? false,
          reference: '',
          splitMethod: form.values.splitMethod ?? 'Auto',
          fileSize: file.size,
          operationStatus: "Pending",
          operationResult: ''
        } as NewDocumentFromWizard);
      });

      form.setFieldValue('documents', uploadedFiles);
      setImportFiles(files);
    }
  }

  const onDeleteImportFiles = () => {
    form.setFieldValue('documents', []);
    setImportFiles(undefined);
  }

  const validateStep2 = () => {
    let valid = false;
    if (form.values.contentSource == "Internal") {
      valid = form.values.documents.length > 0 && form.values.documents[0].title.length >= 3;
    }
    else {
      valid = form.values.documents.length > 0 && AsEnumerable(form.values.documents).All(doc => doc.title.length >= 3);
    }

    return valid;
  }

  const goToCreateDocuments = () => {
    if (validateStep2()) {
      for (let index = 0; index < form.values.documents.length; index++) {
        let documents = form.values.documents;
        documents[index].operationStatus = "Pending";
        documents[index].operationResult = "";
        form.setFieldValue("documents", documents);
      }
      nextStep();
      setShowValidateError(false);
    }
    else {
      setShowValidateError(true);
    }
  }

  const onSubmit = async () => {
    store.setCollection(form.values.collectionId);
    if (form.values.contentSource == "Internal" && form.values.documents.length > 0) {
      const newItem = {
        collectionId: form.values.collectionId,
        title: form.values.documents[0].title,
        description: form.values.documents[0].description,
        tags: tagsToDict(form.values.documents[0].tags),
        audiences: form.values.documents[0].audiences,
        language: form.values.documents[0].language,
        boost: form.values.documents[0].boost,
        dontAdvertise: form.values.documents[0].dontAdvertise,
        reference: slugify(form.values.documents[0].title, { lower: true }),
        contentSource: form.values.contentSource,
        content: '',
        splitMethod: form.values.documents[0].splitMethod,
        train: form.values.train,
        publish: form.values.publish
      } as CreateDocumentItem;

      let response = await store.createWithoutFile(newItem);
      if (response) {
        onFinishWizard();
      }
    }
    else if (importFiles && importFiles.length > 0) {
      const requests = form.values.documents.map(doc => ({
        collectionId: form.values.collectionId,
        title: doc.title,
        description: doc.description,
        tags: tagsToDict(doc.tags),
        audiences: doc.audiences,
        language: doc.language,
        boost: doc.boost,
        dontAdvertise: doc.dontAdvertise,
        reference: slugify(doc.title, { lower: true }),
        contentSource: form.values.contentSource,
        content: '',
        splitMethod: doc.splitMethod,
        train: form.values.train,
        publish: form.values.publish
      } as CreateDocumentItem));

      for (let index = 0; index < requests.length; index++) {
        let documents = form.values.documents;
        documents[index].operationStatus = "Creating";
        form.setFieldValue("documents", documents);
        const request = requests[index];
        const response = await store.createWithFile(request, importFiles[index] as File);

        documents[index].operationStatus = response && !state.errorMessage.value ? "Success" : "Error";
        documents[index].operationResult = state.errorMessage.value ? state.errorMessage.value : '';
        form.setFieldValue("documents", documents);
      }

      setShowFinishStep(true);
    }
  }

  const onFinishWizard = () => {
    if (form.values.contentSource === 'Internal') {
      if (state.item.id.value) {
        navigate(`/admin/collections/${state.item.collectionId.value}/documents/${state.item.id.value}/edit`);
      }
    }
    else {
      navigate(`/admin/collections/${form.values.collectionId}`);
    }

    dispatch('@@ui/DOCUMENT_LIST_REFRESH');
    form.reset();
    onClose();
  }

  const onCloseModal = () => {
    form.reset();
    store.clearError();
    onClose();
  }

  const getStatusIcon = (doc: NewDocumentFromWizard) => {
    switch (doc.operationStatus) {
      case 'Success':
        return <IconCircleCheck color="green" size={22} />;
      case 'Error':
        return <IconCircleX color="red" size={22} />;
      // case 'Pending':
      //   return <AlertTriangle color={theme.colors.yellow[5]} size={22} />;
      case 'Creating':
        return <Loader size="sm" />;
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={onCloseModal}
      title={<Text>{t("Create documents")}</Text>}
      size="70%"
      closeOnClickOutside={false}
      styles={{
        content: {
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        },
        header: {
          alignItems: 'flex-start'
        }
      }}>
      {errorMessage && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
          <Text>{formatMessage(errorMessage)}</Text>
        </Alert>
      )}
      {showValidateError && (
        <Alert p="md" mb="xs" icon={<IconAlertCircle size={16} />} title={t("Error")} color="red" withCloseButton onClose={() => store.clearError()}>
          <Text>{t("Please review and complete all required fields before continuing")}</Text>
        </Alert>
      )}
      <form>
        <Stepper
          active={active}
          onStepClick={setActive}
          classNames={stepperClasses}>
          <Stepper.Step
            label={`${t("Source")}`}
            description={t("Document source and options")}
            allowStepSelect={active > 0}
            loading={active === 0 && isBusy}>
            <Card withBorder shadow="sm" mb="md" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
              <Stack>
                <CollectionSelector
                  required
                  label={t("Collection") as string}
                  clearable
                  width="100%"
                  value={form.values.collectionId}
                  onChange={(value) => form.setFieldValue('collectionId', value)}
                />

                <Input.Wrapper
                  label={t("Select the document source")}
                  withAsterisk>
                  <Group grow>
                    <Radio
                      className={cx(classes.radio, { [classes.radioSelected]: form.values.contentSource === 'Internal' })}
                      checked={form.values.contentSource === 'Internal'}
                      onChange={(event) => form.setFieldValue('contentSource', 'Internal')}
                      label={<span><b>{t("Markdown editor")}</b><br />{t("Create your own document using a markdown editor.")}</span>}
                    />
                    <Radio
                      className={cx(classes.radio, { [classes.radioSelected]: form.values.contentSource === 'External' })}
                      checked={form.values.contentSource === 'External'}
                      onChange={(event) => form.setFieldValue('contentSource', 'External')}
                      label={<span><b>{t("Existing files")}</b><br />{t("Import files from your computer.")}</span>}
                    />
                  </Group>
                </Input.Wrapper>

                <SimpleGrid
                  cols={{ base: 1, md: 2 }}
                  spacing="lg">
                  <Stack>
                    <AudienceMultiSelect
                      label={t("Audiences") as string}
                      value={form.values.audiences}
                      onChange={(value) => form.setFieldValue("audiences", value)}
                    />
                    <Group grow>
                      <LanguageSeletor
                        label={t('Language') as string}
                        {...form.getInputProps('language')}
                      />
                      <Select
                        label={t("Split method")}
                        defaultValue='Auto'
                        data={[
                          { value: 'Auto', label: t("Auto") as string },
                          { value: 'None', label: t("None") as string },
                          { value: 'Paragraph', label: t("Paragraph") as string },
                          { value: 'Block', label: t("Block") as string }
                        ]}
                        {...form.getInputProps('splitMethod')}
                      />
                    </Group>
                    <Group grow align="center" justify="space-between">
                      <Switch
                        label={t("Don't advertise")}
                        {...form.getInputProps('dontAdvertise')}
                      />
                      <Switch
                        styles={{ body: { justifyContent: 'center' } }}
                        label={t("Train")}
                        checked={form.values.train}
                        onChange={(event) => form.setFieldValue('train', event.currentTarget.checked)}
                        disabled={form.values.contentSource === 'Internal'}
                      />
                      <Switch
                        styles={{ body: { justifyContent: 'flex-end' } }}
                        label={t("Publish")}
                        checked={form.values.publish}
                        onChange={(event) => form.setFieldValue('publish', event.currentTarget.checked)}
                        disabled={form.values.contentSource === 'Internal'}
                      />
                    </Group>
                  </Stack>
                  <TagsEditor state={tagsState.tags} />
                </SimpleGrid>
              </Stack>
            </Card>

            <Group align="center" justify="space-between" mt="lg">
              <Group align="center" justify="flex-start" wrap="nowrap">
              </Group>
              <Group justify="flex-end">
                <Button
                  disabled={!form.values?.collectionId}
                  rightSection={<IconArrowRight />}
                  onClick={goToConfigureDocuments}>{t("Next")}
                </Button>
              </Group>
            </Group>
          </Stepper.Step>

          <Stepper.Step
            label={`${t("Information")}`}
            description={t("Information associated with documents")}
            allowStepSelect={active > 1}
            loading={active === 1 && isBusy}>

            <Card withBorder shadow="sm" mb="md" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
              {form.values.contentSource === 'Internal' && form.values.documents.length > 0 &&
                <Stack>
                  <TextInput
                    style={{ flex: 1 }}
                    required
                    label={t('Title')}
                    {...form.getInputProps('documents.0.title')}
                  />

                  <Textarea
                    label={t('Description')}
                    rows={3}
                    {...form.getInputProps('documents.0.description', { type: 'input' })}
                  />
                </Stack>
              }

              {form.values.contentSource === 'External' &&
                <Stack>
                  <DocumentsInputFileZone onSave={onLoadImportFiles} onDelete={onDeleteImportFiles} />

                  {form.values.documents.length > 0 &&
                    <Table highlightOnHover>
                      <Table.Thead>
                        <Table.Th style={{ width: '40%', textAlign: 'left', paddingLeft: 10 }}><Input.Label required>{t("Title")}</Input.Label></Table.Th>
                        <Table.Th style={{ width: '15%', textAlign: 'left', paddingLeft: 10 }}><Input.Label>{t("Language")}</Input.Label></Table.Th>
                        <Table.Th style={{ width: '15%', textAlign: 'left', paddingLeft: 10 }}><Input.Label>{t("Split method")}</Input.Label></Table.Th>
                        <Table.Th style={{ width: '30%', textAlign: 'left', paddingLeft: 10 }}><Input.Label>{t("Audiences")}</Input.Label></Table.Th>
                      </Table.Thead>
                      <Table.Tbody>
                        {form.values.documents.map((_, index) =>
                          <EditableFileRow key={index} index={index} form={form} />
                        )}
                      </Table.Tbody>
                    </Table>
                  }
                </Stack>
              }
            </Card>

            <Group align="center" justify="space-between" mt="lg">
              <Group align="center" justify="flex-start" wrap="nowrap">
              </Group>

              <Group justify="flex-end">
                <Button variant="default" onClick={() => { prevStep(); }} leftSection={<IconArrowLeft />}>{t("Back")}</Button>
                <Button onClick={() => goToCreateDocuments()} leftSection={<IconArrowRight />}>{t("Next")}</Button>
              </Group>
            </Group>
          </Stepper.Step>

          <Stepper.Step
            label={`${t("Create")}`}
            description={t("Validate and create")}
            allowStepSelect={active > 1}
            loading={active === 2 && isBusy}>
            <Card withBorder shadow="sm" mb="md" style={{ backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.white }}>
              <Stack>
                {form.values.contentSource === "Internal" && form.values.documents.length > 0 &&
                  <>
                    <Group justify="space-between">
                      <Text mb="md">{t("This is a summary of the information in your document. Please review it before continuing.")}</Text>
                    </Group>
                    <Table highlightOnHover>
                      <Table.Thead>
                        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Field")}</Table.Th>
                        <Table.Th style={{ width: '50%', textAlign: 'left', paddingLeft: 10 }}>{t("Value")}</Table.Th>
                      </Table.Thead>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td>{t("Title")}</Table.Td>
                          <Table.Td>{form.values.documents[0].title}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>{t("Description")}</Table.Td>
                          <Table.Td>{form.values.documents[0].description}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>{t("Audiences")}</Table.Td>
                          <Table.Td>{form.values.documents[0].audiences.toString()}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>{t("Language")}</Table.Td>
                          <Table.Td>{form.values.documents[0].language}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>{t("Split method")}</Table.Td>
                          <Table.Td>{form.values.documents[0].splitMethod}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>{t("Don't advertise")}</Table.Td>
                          <Table.Td>{form.values.documents[0].dontAdvertise.toString()}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>{t("Tags")}</Table.Td>
                          <Table.Td><Group>{form.values.documents[0].tags.map((t) => (<Text key={t.name}>{t.name}:{t.value}</Text>))}</Group></Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </>
                }

                {form.values.contentSource === 'External' && importFiles &&
                  <>
                    <Group justify="space-between">
                      <Text mb="md">{t("The following documents will be created. Please review them before continuing.")}</Text>
                    </Group>

                    <Table highlightOnHover>
                      <Table.Thead>
                        <Table.Th style={{ width: '25%', textAlign: 'left', paddingLeft: 10 }}>{t("File")}</Table.Th>
                        <Table.Th style={{ width: '25%', textAlign: 'left', paddingLeft: 10 }}>{t("Size")}</Table.Th>
                        <Table.Th style={{ width: '25%', textAlign: 'left', paddingLeft: 10 }}>{t("Title")}</Table.Th>
                        <Table.Th style={{ width: '25%', textAlign: 'left', paddingLeft: 10 }}>{t("Status")}</Table.Th>
                      </Table.Thead>
                      <Table.Tbody>
                        {form.values.documents.map((document, index) => (
                          <Table.Tr key={index}>
                            <Table.Td>{importFiles[index].name}</Table.Td>
                            <Table.Td>{formatBytes(document.fileSize)}</Table.Td>
                            <Table.Td>{document.title}</Table.Td>
                            <Table.Td><Group>{getStatusIcon(document)} {document.operationResult}</Group></Table.Td>
                          </Table.Tr>
                        ))}
                      </Table.Tbody>
                    </Table>
                  </>
                }
              </Stack>
            </Card>

            <Group align="center" justify="space-between" mt="lg">
              <Group align="center" justify="flex-start" wrap="nowrap">
              </Group>
              <Group justify="flex-end">
                <Button variant="default" onClick={prevStep} leftSection={<IconArrowLeft />}>{t("Back")}</Button>
                {form.values.contentSource === "Internal" &&
                  <Button
                    onClick={onSubmit}
                    loading={isBusy}
                    leftSection={<IconPlus />}>
                    {t("Create")}
                  </Button>
                }
                {form.values.contentSource === "External" && !showFinishStep &&
                  <Button
                    onClick={onSubmit}
                    loading={isBusy}
                    leftSection={<IconPlus />}>
                    {t("Create")}
                  </Button>
                }
                {form.values.contentSource === "External" && showFinishStep &&
                  <Button loading={isBusy} onClick={() => onFinishWizard()} leftSection={<IconX />}>{t("Close")}</Button>
                }
              </Group>
            </Group>
          </Stepper.Step>
        </Stepper>
      </form>
    </Modal>
  );
};

export default CreateDocumentWizard;
import { FC, useContext, useEffect, useState } from "react";
import { StepNodeProps } from "./steps";
import { useHookstate } from "@hookstate/core";
import { Group, Stack, Text, Fieldset, ComboboxItem, Select, Code } from "@mantine/core";
import { useTranslation } from "react-i18next";
import AppContext from "src/services/app-context";
import StepWrapper from "./step-wrapper";
import { AsEnumerable } from "linq-es5";

const StepGotoNode: FC<StepNodeProps> = ({ id, data }) => {
  const { step, edit, index, rightToolbar, updateNode, steps } = data;
  const scopedState = useHookstate(step.action);
  const { t } = useTranslation();
  const [selectStepsData, setSelectStepsData] = useState<ComboboxItem[]>([]);
  const { setIsDirty } = useContext(AppContext);

  // useEffect(() => {
  //   const changes = JSON.parse(JSON.stringify(scopedState.value)) as SkillContentStepAction;
  //   const newAction = { ...step.action, ...changes };
  //   const newStep = { ...step, action: newAction };
  //   const newData = { index: index, step: newStep } as StepDataProps;
  //   updateNode(id, newData);
  // }, [JSON.stringify(scopedState.value)])

  useEffect(() => {
    if (steps && steps?.length > 0) {
      const data = AsEnumerable(steps).Distinct().ToArray().map((s) => ({ value: s, label: s } as ComboboxItem));
      setSelectStepsData(data);
    }
    else {
      setSelectStepsData([]);
    }
  }, [steps])

  const resume = (
    <>{t("Go to step")} <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" >{scopedState?.stepName?.value}</Code></>
  )

  if (!scopedState?.value) return (<></>);

  return (
    <StepWrapper
      id={id}
      index={index}
      step={step}
      updateNode={updateNode}
      edit={edit}
      rightToolbar={rightToolbar}
      resume={resume}>
      <Fieldset p="xs" variant="filled"
        legend={
          <Group align="center" gap={5}>
            <Text size="sm">{t("Go to step")}</Text>
          </Group>
        }>
        <Stack gap="xs">
          {edit ?
            <Stack gap="xs">
              <Select
                allowDeselect={false}
                w={'100%'}
                style={{ flex: 1 }}
                data={selectStepsData}
                value={scopedState.stepName.value}
                onChange={(value) => { scopedState.stepName.set(value as string); setIsDirty(true); }}
              />
            </Stack>
            :
            <Stack gap="xs">
              <Code style={{ fontSize: 16 }} color="var(--mantine-color-grape-light)" >{scopedState.stepName.value}</Code>
            </Stack>
          }
        </Stack>
      </Fieldset>
    </StepWrapper>
  );
};

export default StepGotoNode;